<fuse-card *ngIf="size!=='xs'" class="flex flex-col" [ngClass]="{'sm': size==='sm', 'sm:w-[512px] sm:h-[394px] w-[252px]': size==='medium'}">
    <!-- HEADER -->
    <div class="flex flex-row justify-between items-center sensor-header widget-header pl-4 pr-2 pt-2 pb-1">

        <!-- NAME & STATUS -->
        <div class="flex flex-col w-full">
            <span class="font-semibold leading-tight max-w-[180px] truncate">{{sensor.sensorName}}</span>
            <div class="text-xs text-secondary font-semibold leading-tight">{{devices? getDeviceNameFromId(sensor.deviceId) : ''}}</div>
        </div>
        <!-- END NAME & STATUS -->

        <!-- ACTIONS -->
        <ng-container *ngTemplateOutlet="menuBtnTemplate"></ng-container>
        <!-- END ACTIONS -->
    </div>

    <!-- DISPLAY -->
    <div class="sensor-display flex flex-col justify-start items-center">
        <ng-container *ngTemplateOutlet="sensorDisplayTemplate"></ng-container>
    </div>
    <!-- END DISPLAY -->

    <!-- WARNING DIV -->
    <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    <!-- END WARNING DIV -->

</fuse-card>

<fuse-card *ngIf="size==='xs'" class="flex items-center max-w-full w-full px-6 py-3 relative">

    <!-- DISPLAY -->
    <div class="sensor-display max-w-20 w-20 h-20 mr-6 rounded-full flex flex-col justify-center items-center" [ngClass]="size">
        <ng-container *ngTemplateOutlet="sensorDisplayTemplate"></ng-container>
    </div>
    <!-- END DISPLAY -->

    <!-- NAME & STATUS -->
    <div class="flex flex-col max-w-[calc(100%-6.5rem)] pt-2">
        <span class="font-semibold leading-none truncate">{{sensor.sensorName}}</span>
        <div class="text-xs text-secondary leading-tight">{{devices? getDeviceNameFromId(sensor.deviceId) : ''}}</div>
        <div class="flex flex-row text-sm text-secondary leading-none mt-1">
                <span class="flex items-center w-[10px] h-[10px] rounded-full mr-[4px]"
                      [ngClass]="{'bg-green-700': sensor.status===1, 'bg-red-500': sensor.status<=0}"></span>
            <div>{{sensor.getStatusStr()}}</div>
        </div>
    </div>
    <!-- END NAME & STATUS -->

    <!-- ACTIONS -->
    <div class="absolute top-0 right-0 flex flex-row ">
        <button mat-icon-button class="widget-header-content">
            <mat-icon svgIcon="heroicons_outline:switch-vertical"></mat-icon>
        </button>
        <ng-container *ngTemplateOutlet="menuBtnTemplate"></ng-container>
    </div>
    <!-- END ACTIONS -->

    <!-- WARNING DIV -->
    <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    <!-- END WARNING DIV -->

</fuse-card>

<!-- SENSOR DISPLAY TEMPLATE -->
<ng-template #sensorDisplayTemplate>
    <ng-container [ngSwitch]="sensor.type">

        <div *ngIf="size!=='xs'" class="flex flex-row text-sm text-secondary leading-none pl-4 w-full">
            <span class="flex items-center w-[10px] h-[10px] rounded-full mr-[4px]"
                  [ngClass]="{'bg-green-700': sensor.status===1, 'bg-red-500': sensor.status<=0}"></span>
            <div>{{sensor.getStatusStr()}}</div>
        </div>
        <!-- Relay -->
        <div *ngSwitchCase="'relay'" class="max-w-full mb-auto mt-auto">
            <div class="relay-display flex flex-col justify-center items-center">

                <sb-relay-display class="max-w-full" [sensor]="sensor" [timers]="timers" [activeTimer]="activeTimer"
                                  [size]="size">
                </sb-relay-display>

                <div *ngIf="timers.length" class="timers-drawer flex flex-row rounded-l-lg w-full absolute bottom-1 max-h-14 shadow-inner bg-card"
                     [ngClass]="{'opened':timersOpened}" [matBadge]="hasActiveTimers()"
                     matBadgePosition="before" [matBadgeHidden]="!hasActiveTimers()" matBadgeColor="warn">

                    <div matRipple class="timers-drawer-toggle flex flex-col justify-center items-center bg-accent-500 text-on-primary rounded-l-lg"
                         (click)="toggleTimersDrawer();">
                        <div *ngIf="timersOpened; then ChevronRight else ChevronLeft">
                        </div>
                        <ng-template #ChevronLeft>
                            <mat-icon class="text-on-primary" svgIcon="chevron_left">
                            </mat-icon>
                        </ng-template>
                        <ng-template #ChevronRight>
                            <mat-icon  class="text-on-primary" svgIcon="chevron_right">
                            </mat-icon>
                        </ng-template>
                    </div>
                    <div class="timer-shortcut-container flex flex-row flex-wrap border border w-full">
                        <div class="timer-shortcut flex flex-col justify-center items-center"
                             *ngFor="let timer of timers" [matTooltip]="getTimerTooltip(timer)"
                             matTooltipPosition="above">
                            <button mat-mini-fab class="timer-shortcut-button"
                                    [ngClass]="{'bg-green-600':timer.active, 'bg-red-700':!timer.active}"
                                    (click)="toggleTimer(timer)">
                                <mat-icon svgIcon="timer">
                                </mat-icon>
                                <mat-progress-spinner *ngIf="timer.active" class="timer-shortcut-progress"
                                                      [mode]="!timer.completed?'determinate':'indeterminate'"
                                                      [value]="timer.completionPercentage" diameter="35">
                                </mat-progress-spinner>
                            </button>
                            <div class="timer-shortcut-name">
                                {{timer.name}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Temperature -->
        <ng-container *ngSwitchCase="'temp'">
            <div class="temperature-display flex flex-col justify-center items-center">
                <sb-temperature-display [sensor]="sensor" [size]="size" type="eCharts"
                                        (hasWarning)="onWarning($event)"></sb-temperature-display>
            </div>
        </ng-container>

        <!-- Humidity -->
        <div *ngSwitchCase="'humid'">
            <div class="humidity-display flex flex-col justify-center items-center content-center">
                <sb-humidity-display [sensor]="sensor" [size]="size" type="ngx-gauge"
                                     (hasWarning)="onWarning($event)"></sb-humidity-display>
            </div>
        </div>

        <!-- Current -->
        <ng-container *ngSwitchCase="'current'">
            <div class="current-display flex flex-col justify-center items-center">
                <sb-current-display [sensor]="sensor" [size]="size" type="eCharts"
                                    (hasWarning)="onWarning($event)"></sb-current-display>
            </div>
        </ng-container>

        <!-- Voltage -->
        <ng-container *ngSwitchCase="'volt'">
            <div class="voltage-display flex flex-col justify-center items-center">
                <sb-voltage-display [sensor]="sensor" [size]="size" type="eCharts"
                                    (hasWarning)="onWarning($event)"></sb-voltage-display>
            </div>
        </ng-container>

        <!-- Power -->
        <ng-container *ngSwitchCase="'power'">
            <div class="power-display flex flex-col justify-center items-center">
                <sb-power-display [sensor]="sensor" [size]="size" type="eCharts"
                                    (hasWarning)="onWarning($event)"></sb-power-display>
            </div>
        </ng-container>

        <!-- Fan -->
        <div *ngSwitchCase="'pwm'">
            <div class="fan-display flex flex-col justify-center items-center">
                <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="humidThresholdConfig" thick="8"
                           min=0 max=100 size="130" cap="round" label="Ταχύτητα" append="%">
                </ngx-gauge>
                <mat-slider></mat-slider>
            </div>
        </div>

        <!-- Door -->
        <div *ngSwitchCase="'door'">
            <div class="door-display flex flex-col justify-center items-center text-secondary">
                <sb-door-display [sensor]="sensor" [size]="size"></sb-door-display>
            </div>
        </div>

<!--        &lt;!&ndash; Alarm&ndash;&gt;-->
<!--        &lt;!&ndash; TODO: Make it specific to door bells &ndash;&gt;-->
<!--        <div *ngSwitchCase="'button'">-->
<!--            <div class="alarm-display text-secondary">-->
<!--                <mat-icon [ngClass]="{'text-on-warn-500':sensor.displayData===1}"-->
<!--                          [svgIcon]="sensor.displayData===1?'mat_solid:volume_up':'mat_solid:volume_off'">-->
<!--                </mat-icon>-->
<!--            </div>-->
<!--        </div>-->

        <div *ngSwitchCase="'rssi'">
            <div class="rssi-display text-secondary">
                <sb-rssi-display [sensor]="sensor" [size]="size"></sb-rssi-display>
            </div>
        </div>

        <ng-container *ngSwitchCase="'button'">
            <div class="max-w-full mb-auto flex flex-col justify-center items-center text-secondary">
                <sb-button-display class="max-w-full" [sensor]="sensor"></sb-button-display>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
<!-- END SENSOR DISPLAY TEMPLATE -->

<!-- SENSOR WARNING TEMPLATE -->
<ng-template #warningTemplate>
    <div class="absolute bottom-0 right-0 w-20 h-20 -m-5" *ngIf="hasWarning">
        <mat-icon class="warning-icon text-amber-400 icon-size-20 opacity-50" svgIcon="heroicons_outline:exclamation-circle"
                  [matTooltip]="warning['message']" matTooltipClass="bg-amber-400 text-white text-base rounded-lg" matTooltipPosition="left"></mat-icon>
    </div>
</ng-template>
<!-- END SENSOR WARNING TEMPLATE -->

<!-- SENSOR MENU BUTTON TEMPLATE -->
<ng-template #menuBtnTemplate>
    <!-- BUTTON -->
    <button mat-icon-button [matMenuTriggerFor]="menu" matMenuTriggerRestoreFocus="false">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" backdropClass="widget-menu-backdrop">
        <button mat-menu-item (click)="openChart()">
            <mat-icon svgIcon="insert_chart_outlined"></mat-icon>
            <span>{{'SENSOR_WIDGET.CHART.SHOW' |transloco}}</span>
        </button>
        <button mat-menu-item (click)="openBoards()">
            <mat-icon svgIcon="dashboard"></mat-icon>
            <span>{{'SENSOR_WIDGET.EDIT_BOARDS' |transloco}}</span>
        </button>
        <button mat-menu-item (click)="toogleNotifications()">
            <mat-icon [svgIcon]="sensor.notifications ? 'notifications_off' :'notifications'">
            </mat-icon>
            <span>{{(sensor.notifications ? 'SENSOR_WIDGET.NOTIFICATIONS.TURN_OFF' : 'SENSOR_WIDGET.NOTIFICATIONS.TURN_ON') |transloco}}</span>
        </button>
        <button mat-menu-item (click)="onDeleteButtonClicked()">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Διαγραφή Αισθητήρα</span>
        </button>
        <button mat-menu-item *ngIf="deletable" class="widget-close-btn" (click)="deleted.emit('dashboard')">
            <mat-icon svgIcon="close"></mat-icon>
            <span>{{'SENSOR_WIDGET.REMOVE_FROM_DASHBOARD' |transloco}}</span>
        </button>
        <button mat-menu-item (click)="openSettings()">
            <mat-icon svgIcon="settings"></mat-icon>
            <span>{{'SENSOR_WIDGET.SETTINGS' |transloco}}</span>
        </button>
        <button mat-menu-item (click)="openInformation()">
            <mat-icon svgIcon="help_outline"></mat-icon>
            <span>{{'GENERIC.Info' | transloco}}</span>
        </button>
    </mat-menu>
</ng-template>
<!-- END SENSOR MENU BUTTON TEMPLATE -->
