import { Directive, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[cdkDetailRow]'
})
export class CdkDetailRowDirective {
    private _row: any;
    private _tRef: TemplateRef<any>;
    private _opened = false;
    private _clickEnabled = false;

    @HostBinding('class.expanded')
    get expanded(): boolean {
        return this._opened;
    }

    @Input()
    set cdkDetailRow(value: any) {
        if (value !== this._row) {
            this._row = value;
        }
    }

    @Input('cdkDetailRowTpl')
    set template(value: TemplateRef<any>) {
        if (value !== this._tRef) {
            this._tRef = value;
        }
    }

    @Input('cdkDetailRowIsExpanded')
    set isExpanded(value: boolean) {
        console.log('Running');
        if (value) {
            this.render();
            // this.render();
        }
        else {
            this.vcRef.clear();
        }
        this._opened = this.vcRef.length > 0;
    }

    @Input('cdkDetailRowEnableClick')
    set clickEnabled(value: boolean) {
        this._clickEnabled = value;
    }

    constructor(public vcRef: ViewContainerRef) { }

    @HostListener('click')
    onClick(): void {
        if (this._clickEnabled) {
            this.toggle();
        }
    }

    toggle(): void {
        if (this._opened) {
            this.vcRef.clear();
        } else {
            this.render();
        }
        this._opened = this.vcRef.length > 0;
    }

    private render(): void {
        this.vcRef.clear();
        if (this._tRef && this._row) {
            this.vcRef.createEmbeddedView(this._tRef, { $implicit: this._row });
        }
    }
}
