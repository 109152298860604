import {Pipe, PipeTransform} from '@angular/core';

import {Sensor} from '@sensorbase/models';

@Pipe({name: 'tagSelection'})
export class TagSelection implements PipeTransform {

    transform(allSensors: Sensor[], tags: string[] = []): Sensor[] {
        const newSensors = [];
        if (tags.length) {
            // console.log("tag: " + tags);
            allSensors.forEach((sensor) => {
                    // console.log("tags: " + sensor.tags);
                    if (tags != null) {
                        for (const tag of sensor.tags) {
                            if (tags.includes(tag)) {
                                if (!newSensors.includes(sensor)) {
                                    newSensors.push(sensor);
                                }
                                break;
                            }
                        }
                    }
                }
            );

            return newSensors;
        } else {
            return allSensors;
        }
    }
}
