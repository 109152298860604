import {MqttService, IPublishOptions, IMqttMessage} from 'ngx-mqtt';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
// import {Device} from '@ionic-native/device/ngx';
// import {Network} from '@ionic-native/network/ngx';
// import {Autostart} from '@ionic-native/autostart/ngx';
// import {BackgroundMode} from '@ionic-native/background-mode/ngx';
// import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {HttpClient} from '@angular/common/http';

import {AuthenticationService} from '@sensorbase/services';
// import { IClientSubscribeOptions, IClientPublishOptions, QoS } from 'ngx-mqtt/src/mqtt-types';
import {API_BASE_URL} from '../../../environments/environment';
import {environment} from 'environments/environment';
import {AlertService} from '../alert';
import {switchMap, first, shareReplay, filter} from 'rxjs/operators';

@Injectable()
export class SensorbaseMqttService implements OnDestroy {
    private _connected: Subject<boolean> = new Subject();
    public onConnect: Observable<boolean> = this._connected.pipe(filter(c => c === true), shareReplay(1));
    public onDisconnect: Observable<boolean> = this._connected.pipe(filter(c => c === false), shareReplay(1));
    private clientId: string = this.getClientType() + '-' + this.randomString(20, '1234567890abcdefghijklmnopqrstuvwxyz');
    private reconnectPeriod: number = 5 * 1000;
    private backgroundWakeCount = 0;
    user: any;
    public connected = false;
    private reconnectAttempt = 0;

    constructor(private _mqttService: MqttService,
                private _authService: AuthenticationService,
                // private _backgroundMode: BackgroundMode,
                // private _autostart: Autostart,
                // private _device: Device,
                // private _network: Network,
                // private _androidPermissions: AndroidPermissions,
                private _alertService: AlertService,
                private _httpClient: HttpClient) {

        if (environment.capacitor) {
            this.reconnectPeriod = 10 * 1000;
            // this._network.onDisconnect().subscribe(() => {
            //     console.log('Network disconnect');
            // });
            // this._network.onConnect().subscribe(() => {
            //     console.log('Network Connect');
            //     if (!this.connected) {
            //
            //         if (this._authService.loggedIn()) {
            //             const mqttToken = AuthenticationService.getMqttToken();
            //             if (mqttToken) {
            //                 console.log('connecting to mqtt on network connect ' + this.clientId);
            //                 this._mqttService.connect({
            //                     username: mqttToken,
            //                     password: '12345',
            //                     clientId: this.clientId,
            //                     reconnectPeriod: this.reconnectPeriod
            //                 });
            //             }
            //         }
            //     }
            // });
            // this._backgroundMode.setDefaults({title: 'Sensorbase Background Process', silent: true});
            //
            // this._backgroundMode.on('enable').subscribe(() => {
            //     console.log('Background Enable ');
            //     this._alertService.info('Background mode Enabled');
            //     this._backgroundMode.overrideBackButton();
            //     this._autostart.enable();
            //
            // });
            // this._androidPermissions.checkPermission(this._androidPermissions.PERMISSION.WAKE_LOCK).then((result) => {
            //     console.log('Wake Lock permission ', result);
            //     if (!result.hasPermission) {
            //         this._androidPermissions.requestPermission(this._androidPermissions.PERMISSION.WAKE_LOCK);
            //     }
            // });
            // this._androidPermissions.checkPermission(this._androidPermissions.PERMISSION.PERSISTENT_ACTIVITY).then((result) => {
            //     console.log('Persistent activity permission ', result);
            //     if (!result.hasPermission) {
            //         this._androidPermissions.requestPermission(this._androidPermissions.PERMISSION.PERSISTENT_ACTIVITY);
            //     }
            // });
            // this._backgroundMode.on('activate').subscribe(() => {
            //     console.log('Background Active ');
            //     this._backgroundMode.disableWebViewOptimizations();
            //     this.backgroundWakeCount = 0;
            // });
            //
            // this._backgroundMode.on('deactivate').subscribe(() => {
            //     console.log('Background Deactive ');
            //     if (!this.connected) {
            //         if (this._authService.loggedIn()) {
            //             const mqttToken = AuthenticationService.getMqttToken();
            //             if (mqttToken) {
            //                 console.log('connecting to mqtt on app activate ' + this.clientId);
            //
            //                 this._mqttService.connect({
            //                     username: mqttToken,
            //                     password: '12345',
            //                     clientId: this.clientId,
            //                     reconnectPeriod: this.reconnectPeriod
            //                 });
            //                 // this.connected = true;
            //             }
            //         }
            //     }
            // });
            // this._backgroundMode.on('failure').subscribe((error) => {
            //     console.log('Background Error: ', error);
            //     this._alertService.error('Background Error');
            // });

        }


        this._mqttService.onOffline.subscribe(() => {
            console.log('Mqtt Offline');
            if (this.connected) {
                this.connected = false;
                this._connected.next(false);
            }
        });

        this._mqttService.onError.subscribe((error) => {
            this._alertService.error({body: 'MQTT Error: ' + JSON.stringify(error)});
            console.error('Mqtt Error: ', error);
        });

        this._mqttService.onReconnect.subscribe(() => {

            console.log('Reconnecting to MQTT');
            if (this.connected) {
                this.connected = false;
                this._connected.next(false);
            }
            this.reconnectAttempt++;
            if (this.reconnectAttempt > 2) {
                this._mqttService.disconnect();
                this.connectToBroker(30000);
            }
            this._alertService.info({body: 'Reconnecting to MQTT'});
        });

        this._mqttService.onConnect.subscribe((event) => {
            this.reconnectAttempt = 0;
            try {
                this.connected = event.cmd === 'connack';
                if (this.connected) {
                    this._alertService.success({body: 'Connected to MQTT'});
                    if (environment.capacitor) {
                        // this._backgroundMode.enable();
                    }
                    console.log('Connected to Mqtt Broker: ', event);
                    this._connected.next(true);
                }

            } catch (ex) {
                console.error('Error mqtt setConnected: ', ex);
            }

        });
        this.connectToBroker(100);
    }

    public connectToBroker(delay: number): void {
        setTimeout(() => {
            try {
                if (this._authService.loggedIn()) {
                    this.getUser().then((user) => {
                        if (!user.verified) {
                            // this.alert("Verify Account Email For Live Updates");
                            this._alertService.warning({body: 'Verify Account Email For Live Updates'});
                            this.connectToBroker(120000);
                            throw new Error('Account Not Verified');
                        }
                        const mqttToken = AuthenticationService.getMqttToken();
                        if (mqttToken) {
                            console.log('connecting to mqtt ' + this.clientId);
                            this._mqttService.connect({
                                username: mqttToken,
                                password: '12345',
                                clientId: this.clientId,
                                reconnectPeriod: this.reconnectPeriod,
                                resubscribe: true
                            });
                            // this.connected = true;
                        } else {
                            this.connectToBroker(delay + 10000);
                        }
                    });


                } else {
                    // console.log("Mqtt waiting for login");
                    this._authService.onAuthenticated.pipe(first()).subscribe(() => {
                        // console.log("Authent Event");
                        if (!this.connected) {
                            this.getUser().then((user) => {
                                if (!user.verified) {
                                    // this.alert("Verify Account Email For Live Updates");
                                    this._alertService.warning({body: 'Verify Account Email For Live Updates'});
                                    this.connectToBroker(120000);
                                    throw new Error('Account Not Verified');
                                }
                                const mqttToken = AuthenticationService.getMqttToken();
                                if (mqttToken) {
                                    console.log('connecting to mqtt ', this.clientId);
                                    this._mqttService.connect({
                                        username: mqttToken,
                                        password: '12345',
                                        clientId: this.clientId,
                                        reconnectPeriod: this.reconnectPeriod,
                                        resubscribe: true
                                    });
                                    // this.connected = true;
                                } else {
                                    this.connectToBroker(delay + 10000);
                                }

                            });
                        }
                    });
                }
            } catch (error) {
                console.log('Error connecting to mqtt: ' + error);
            }
        }, delay);
    }

    observe(topic: string, options?): Observable<IMqttMessage> {
        // console.log("observe " + topic);
        if (!options) {
            options = {qos: 1};
        }
        if (!this.connected) {
            return this.onConnect.pipe(first(), switchMap(() => this._mqttService.observe(topic, options)));
        } else {
            return this._mqttService.observe(topic, options);
        }

    }

    disconnect(): void {
        if (this.connected) {
            this.connected = false;
            this._connected.next(false);
            this._mqttService.disconnect();
        }
    }

    /**
     * Publish without error handling
     *
     * @param topic The mqtt topic
     * @param message the mqtt message payload
     * @param qos message qos
     * @param retain
     */
    unsafePublish(topic: string, message: string, qos?: any, retain: boolean = false): void {
        if (this.connected) {
            this._mqttService.unsafePublish(topic, message, {qos: qos || 0, retain: retain});
        } else {
            this.onConnect.pipe(first()).subscribe(() => {
                this._mqttService.unsafePublish(topic, message, {qos: qos || 0, retain: retain});

            });
        }

    }

    /**
     * Returns observable for error handling
     *
     * @param topic The mqtt topic
     * @param message the mqtt message payload
     * @param opts publish options
     */
    publish(topic: string, message: any, opts?: IPublishOptions): Observable<void> {
        if (this.connected) {
            console.log('publish mqtt ', message);
            return this._mqttService.publish(topic, message, opts);
        } else {
            return this.onConnect.pipe(first(), switchMap(() => {
                console.log('publish mqtt ' + message);
                return this._mqttService.publish(topic, message, opts);
            }));
        }

    }

    ngOnDestroy(): void {
        this.disconnect();
    }

    public setControl(user: string, sensor: string, state: number, retain: boolean = true): void {
        const mac = sensor.substr(0, 17);
        // console.log("mac " + mac);
        const sensorId = sensor.substr(18, sensor.length);
        // console.log("sensor " + sensorId);
        const topic = '/' + user + '/devices//' + mac + '/sensors/' + sensorId + '/input/value';
        this.publish(topic, state.toString(), {qos: 2, retain: retain}).subscribe(() => {

        }, (error) => {
            console.error('Error setting control ', error);
        });
    }

    randomString(length, chars): string {
        let result = '';
        for (let i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }

    getClientType(): string {
        if (environment.capacitor) {
            // if (this._device.platform === 'Android') {
            //     return 'Android';
            // } else {
            //     return 'Unknown';
            // }
        } else {
            return 'Webapp';
        }

    }

    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this._authService.loggedIn()) {
                const userID = this._authService.getUserId();
                this._httpClient.get(API_BASE_URL + 'users/' + userID)
                    .subscribe((response: any) => {
                        resolve(response);
                    });
            } else {
                reject('Not authenticated');
            }
        });
    }
}
