import {Component, OnInit, Input, EventEmitter, Output, ViewChild, Inject, ViewEncapsulation} from '@angular/core';

import {Sensor, Device} from '@sensorbase/models';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';

import {SensorsService, DevicesService} from '@sensorbase/services';
import {FuseConfirmationConfig, FuseConfirmationService} from '../../../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
// import {SensorChartDialog, SensorBoardsDialog} from '..';
// import {SensorbaseConfirmDialog, SensorSettingsListDialog} from '@sensorbase/components';

@Component({
    selector: 'sb-sensor-information-dialog',
    templateUrl: './sensor-information-dialog.component.html',
    styleUrls: ['./sensor-information-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SensorInformationDialog implements OnInit {
    device: Device;
    sensor: Sensor;
    @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @ViewChild('cchart', {static: false}) cchart;

    constructor(public dialogRef: MatDialogRef<SensorInformationDialog>,
                private _sensorsService: SensorsService,
                private _devicesService: DevicesService,
                public _matDialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) data: any,
                private _translateService: TranslocoService,
                private _fuseConfirmationService: FuseConfirmationService) {
        this.sensor = data.sensor;
        this.device = data.device;
        console.log(this.sensor);

    }

    ngOnInit(): void {

    }

    openBoards(): void {
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = false;
        // dialogConfig.autoFocus = false;
        // // dialogConfig.maxWidth = '100%';
        // // dialogConfig.minWidth = '50%';
        // dialogConfig.panelClass = 'sensor-board-dialog';
        // dialogConfig.data = this.sensor.sensorId;
        //
        // let dialogRef;
        // this._devicesService.getDevice(this.sensor.deviceId)
        //     .then((response) => {
        //         const device = new Device(response);
        //         dialogConfig.data = {
        //             sensor: this.sensor,
        //             device: device
        //         };
        //         dialogRef = this._matDialog.open(SensorBoardsDialog, dialogConfig);
        //
        //         dialogRef.afterClosed().subscribe(result => {
        //             if (result) {
        //                 // console.log('The dialog was closed');
        //                 this.sensor = result;
        //                 this._sensorsService.updateSensor(this.sensor);
        //             }
        //         });
        //     });
        // this.dialogRef.close();
    }


    openChart(): void {
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = false;
        // dialogConfig.autoFocus = false;
        // // dialogConfig.maxWidth = '100%';
        // // dialogConfig.minWidth = '50%';
        // dialogConfig.panelClass = 'sensor-chart-dialog';
        // dialogConfig.data = this.sensor.sensorId;
        //
        // let dialogRef;
        // this._devicesService.getDevice(this.sensor.deviceId)
        //     .then((response) => {
        //         const device = new Device(response);
        //         dialogConfig.data = {
        //             sensor: this.sensor,
        //             device: device
        //         };
        //         dialogRef = this._matDialog.open(SensorChartDialog, dialogConfig);
        //
        //         dialogRef.afterClosed().subscribe(result => {
        //             if (result) {
        //                 // console.log('The dialog was closed');
        //                 this.sensor = result;
        //                 this._sensorsService.updateSensor(this.sensor);
        //             }
        //         });
        //     });
        // this.dialogRef.close();
    }

    openSettings(): void {
        const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'sensor-settings-list-dialog';

        let dialogRef;
        // this._devicesService.getDevice(this.sensor.deviceId)
        //     .then((response) => {
        //         const device = new Device(response);
        //         dialogConfig.data = {
        //             sensor: this.sensor,
        //             device: device
        //         };
        //         dialogRef = this._matDialog.open(SensorSettingsListDialog, dialogConfig);
        //
        //         dialogRef.afterClosed().subscribe(result => {
        //             if (result) {
        //                 // console.log('The dialog was closed');
        //                 this.sensor = result;
        //                 this._sensorsService.updateSensor(this.sensor);
        //             }
        //         });
        //     });
        // this.dialogRef.close();
    }

    toggleNotifications(): void {
        this.sensor.notifications = !this.sensor.notifications;
        this._sensorsService.updateSensor(this.sensor);
    }

    onDeleteButtonClicked(): void{
        const dialogConfig: FuseConfirmationConfig = {
            title: this._translateService.translate('SENSOR_WIDGET.DELETE_SENSOR_DIALOG.TITLE'),
            message: this._translateService.translate('SENSOR_WIDGET.DELETE_SENSOR_DIALOG.MESSAGE', this.sensor),
            icon: {
                name: 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CONFIRM'),
                    color: 'warn'
                },
                cancel: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CANCEL')
                }
            },
            dismissible: true
        };

        const dialogRef = this._fuseConfirmationService.open(dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirmed') {
                this._sensorsService.deleteSensor(this.sensor.sensorId)
                    .subscribe(response => {
                        // this.deleted.next('server');
                    });
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}
