import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    )
    {
        const customIconsPath = '../assets/icons/custom/';
        const customIcons = [
            {
                name: 'light_on',
                file: 'light_on.svg'
            },
            {
                name: 'light_off',
                file: 'light_off.svg'
            },
            {
                name: 'power_on',
                file: 'power_on.svg'
            },
            {
                name: 'power_off',
                file: 'power_off.svg'
            },
            {
                name: 'motor_on',
                file: 'motor_on.svg'
            },
            {
                name: 'motor_off',
                file: 'motor_off.svg'
            },
            {
                name: 'switch_open',
                file: 'switch_open.svg'
            },
            {
                name: 'switch_closed',
                file: 'switch_closed.svg'
            },
            {
                name: 'battery_full',
                file: 'battery_full.svg'
            },
            {
                name: 'battery_empty',
                file: 'battery_empty.svg'
            },
            {
                name: 'faucet_open',
                file: 'faucet_open.svg'
            },
            {
                name: 'faucet_open_1',
                file: 'faucet_open_1.svg'
            },
            {
                name: 'faucet_open_2',
                file: 'faucet_open_2.svg'
            },
            {
                name: 'faucet_open_3',
                file: 'faucet_open_3.svg'
            },
            {
                name: 'faucet_open_4',
                file: 'faucet_open_4.svg'
            },
            {
                name: 'faucet_closed',
                file: 'faucet_closed.svg'
            },
            {
                name: 'heater_on',
                file: 'heater_on.svg'
            },
            {
                name: 'heater_off',
                file: 'heater_off.svg'
            },
            {
                name: 'electric_car',
                file: 'electric_car.svg'
            },
            {
                name: 'electric_tower',
                file: 'electric_tower.svg'
            },
            {
                name: 'electric_tower_1',
                file: 'electric_tower_1.svg'
            },
            {
                name: 'electric_tower_2',
                file: 'electric_tower_2.svg'
            },
            {
                name: 'fan_on',
                file: 'fan_on.svg'
            },
            {
                name: 'padlock_closed',
                file: 'padlock_closed.svg'
            },
            {
                name: 'padlock_closed_1',
                file: 'padlock_closed_1.svg'
            },
            {
                name: 'padlock_closed_2',
                file: 'padlock_closed_2.svg'
            },
            {
                name: 'padlock_closed_3',
                file: 'padlock_closed_3.svg'
            },
            {
                name: 'padlock_open',
                file: 'padlock_open.svg'
            },
            {
                name: 'panel_solar',
                file: 'panel_solar.svg'
            },
            {
                name: 'pipe_on',
                file: 'pipe_on.svg'
            },
            {
                name: 'snowflake',
                file: 'snowflake.svg'
            },
            {
                name: 'snowflake_1',
                file: 'snowflake_1.svg'
            },
            {
                name: 'socket_electrical',
                file: 'socket_electrical.svg'
            },
            {
                name: 'socket_electrical_1',
                file: 'socket_electrical_1.svg'
            },
            {
                name: 'socket_electrical_2',
                file: 'socket_electrical_2.svg'
            },
            {
                name: 'water_drop',
                file: 'water_drop.svg'
            },
            {
                name: 'windmill_blue',
                file: 'windmill_blue.svg'
            },
            {
                name: 'windmill_green',
                file: 'windmill_green.svg'
            },
            {
                name: 'windmill_orange',
                file: 'windmill_orange.svg'
            },
            {
                name: 'windmill_red',
                file: 'windmill_red.svg'
            },
            {
                name: 'windmill_yellow',
                file: 'windmill_yellow.svg'
            },
            {
                name: 'windmill_on',
                file: 'windmill_on.svg'
            },
            {
                name: 'windmill_on_1',
                file: 'windmill_on_1.svg'
            },
            {
                name: 'windmill_on_2',
                file: 'windmill_on_2.svg'
            },
            {
                name: 'windmill_off',
                file: 'windmill_off.svg'
            },
            {
                name: 'windmill_off_1',
                file: 'windmill_off_1.svg'
            },
            {
                name: 'windmill_off_2',
                file: 'windmill_off_2.svg'
            },
            {
                name: 'solar_panels_DTS',
                file: 'solar_panels_DTS.svg'
            },
            {
                name: 'solar_panels_DTS_ENERGY',
                file: 'solar_panels_DTS_ENERGY.svg'
            },
            {
                name: 'campfire',
                file: 'campfire.svg'
            },
            {
                name: 'campfire_1',
                file: 'campfire_1.svg'
            },
            {
                name: 'fire',
                file: 'fire.svg'
            },
            {
                name: 'fire_1',
                file: 'fire_1.svg'
            },
            {
                name: 'fire_danger',
                file: 'fire_danger.svg'
            },
            {
                name: 'fire_extinguisher',
                file: 'fire_extinguisher.svg'
            },
            {
                name: 'wind_2',
                file: 'wind_2.svg'
            },
            {
                name: 'wind_1',
                file: 'wind_1.svg'
            },
            {
                name: 'wind',
                file: 'wind.svg'
            },
            {
                name: 'water_tank',
                file: 'water_tank.svg'
            },
            {
                name: 'water_level',
                file: 'water_level.svg'
            },
            {
                name: 'sea_level',
                file: 'sea_level.svg'
            },
            {
                name: 'fire_extinguisher',
                file: 'fire_extinguisher.svg'
            },
            {
                name: 'ph_meter',
                file: 'ph_meter.svg'
            },
            {
                name: 'oil_tanks',
                file: 'oil_tanks.svg'
            },
            {
                name: 'no_fire',
                file: 'no_fire.svg'
            },
            {
                name: 'man_on_fire',
                file: 'man_on_fire.svg'
            },
            {
                name: 'liquid',
                file: 'liquid.svg'
            },
            {
                name: 'level_2',
                file: 'level_2.svg'
            },
            {
                name: 'level_1',
                file: 'level_1.svg'
            },
            {
                name: 'level',
                file: 'level.svg'
            },
            {
                name: 'humidity',
                file: 'humidity.svg'
            },
            {
                name: 'forest_fire',
                file: 'forest_fire.svg'
            },
            {
                name: 'fire_hydrant_2',
                file: 'fire_hydrant_2.svg'
            },
            {
                name: 'fire_hydrant_1',
                file: 'fire_hydrant_1.svg'
            },
            {
                name: 'fire_hydrant',
                file: 'fire_hydrant.svg'
            },
            {
                name: 'earthquake_1',
                file: 'earthquake_1.svg'
            },
            {
                name: 'earthquake',
                file: 'earthquake.svg'
            },
            {
                name: 'celsius_2',
                file: 'celsius_2.svg'
            },
            {
                name: 'celsius_1',
                file: 'celsius_1.svg'
            },
            {
                name: 'celsius',
                file: 'celsius.svg'
            },
            {
                name: 'battery_level_2',
                file: 'battery_level_2.svg'
            },
            {
                name: 'battery_level_1',
                file: 'battery_level_1.svg'
            },
            {
                name: 'battery_level',
                file: 'battery_level.svg'
            },
            {
                name: 'attention',
                file: 'attention.svg'
            },
            {
                name: 'alarm',
                file: 'alarm.svg'
            },
            {
                name: 'sms_message',
                file: 'sms_message.svg'
            },
            {
                name: 'shield_no',
                file: 'shield_no.svg'
            },
            {
                name: 'message_mails',
                file: 'message_mails.svg'
            },
            {
                name: 'google_mail',
                file: 'google_mail.svg'
            },
            {
                name: 'gmail',
                file: 'gmail.svg'
            },
            {
                name: 'room_clean',
                file: 'room_clean.svg'
            },
            {
                name: 'room_clean1',
                file: 'room_clean1.svg'
            },
            {
                name: 'room_clean2',
                file: 'room_clean2.svg'
            },
            {
                name: 'room_dnd',
                file: 'room_dnd.svg'
            },
            {
                name: 'room_dnd1',
                file: 'room_dnd1.svg'
            },
            {
                name: 'room_dnd2',
                file: 'room_dnd2.svg'
            },
            {
                name: 'room_dnd3',
                file: 'room_dnd3.svg'
            },
            {
                name: 'room_thumbs',
                file: 'room_thumbs.svg'
            },
            {
                name: 'Flat_tick_icon',
                file: 'Flat_tick_icon.svg'
            }
        ];
        // Material
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/material-twotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/material-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/material-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_round',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/material-round.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_sharp',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/material-sharp.svg')
        );

        // Heroicons
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
        );

        // Font Awesome
        this._matIconRegistry.addSvgIconSetInNamespace(
            'fa_regular',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/font-awesome/regular.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'fa_duotone',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/font-awesome/duotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'fa_brands',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/font-awesome/brands.svg')
        );

        // Feather
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
        );

        // Custom icons
        customIcons.forEach(icon => {
            this._matIconRegistry.addSvgIconInNamespace('custom', icon.name, this._domSanitizer.bypassSecurityTrustResourceUrl(customIconsPath + icon.file));
        });
    }
}
