import {Component, OnInit, Inject, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Device, SensorChartOptions} from '@sensorbase/models';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {HttpProgressDialog, SensorbaseConfirmDialog} from '@sensorbase/components';
import {SensorsService, DevicesService} from '@sensorbase/services';


import {IMqttMessage} from 'ngx-mqtt';
import {MatDialog} from '@angular/material/dialog';

import {Sensor} from '@sensorbase/models';

import {formatDate} from '@angular/common';
import {TranslocoService} from '@ngneat/transloco';
import {sbAnimations} from '../../../../animations';

@Component({
    selector: 'app-device-settings-dialog',
    templateUrl: './device-settings-dialog.component.html',
    styleUrls: ['./device-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [sbAnimations],

})
export class DeviceSettingsDialog implements OnInit, OnDestroy {
    @ViewChild('cchart', {static: false}) cchart;

    device: Device;
    settingsForm: FormGroup;

    selected: any;
    sensors: any;
    state = 'view';

    manualCatergory = (0);
    public show = false;

    rssiSensor: Sensor;
    sensorsLoaded: boolean = false;


    chartOptions: SensorChartOptions = {
        showDatePicker: false,
        showTitle: true
    };

    // Chart options end

    humidThresholdConfig = {
        '0': {color: 'blue'},
        '20': {color: 'lightskyblue'},
        '40': {color: 'green'},
        '60': {color: 'orange'},
        '80': {color: 'red'}
    };

    private _onSaveSubject: Subject<any>;

    get onSaveObservable(): Observable<any> {
        return this._onSaveSubject.asObservable();
    }

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param dialogRef
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param {DevicesService} _devicesService
     * @param _sensorsService
     * @param _translateService
     * @param _matDialog
     * @param data
     */


    constructor(
        public dialogRef: MatDialogRef<DeviceSettingsDialog>,
        private _formBuilder: FormBuilder,
        private _devicesService: DevicesService,
        private _sensorsService: SensorsService,
        private _translateService: TranslocoService,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: Device
    ) {
        this.device = data;
        this._unsubscribeAll = new Subject();
    }


    onSave(): void {
        const device: Device = Object.assign(new Device(), this.device);
        device.deviceName = this.settingsForm.value.deviceName;
        device.notifications = this.settingsForm.value.notifications;
        device.statusChangeDelay = this.settingsForm.value.alarm_delay;
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        this._devicesService.updateDevice(device)
            .subscribe({
                next: () => {
                    this.device.deviceName = this.settingsForm.value.deviceName;
                    this.device.notifications = this.settingsForm.value.notifications;
                    this.device.statusChangeDelay = this.settingsForm.value.alarm_delay;
                    httpProgressDialog.componentInstance.state = 'success';
                    httpProgressDialog.afterClosed().subscribe(() => {
                        this.dialogRef.close(this.device);
                    });

                },
                error: (err) => {
                    httpProgressDialog.componentInstance.state = 'error';
                }
            });
        this._onSaveSubject.next(null);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this._onSaveSubject = new Subject();
        this._devicesService.device$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                this.selected = selected;
                this._sensorsService.getSensorsForDevice(this.selected.deviceId)
                    .subscribe(sensors => {
                        console.log(sensors);
                        this.sensors = sensors;
                        this.sensors.forEach((sensor, index) => {
                            if (sensor.type === 'relay') {
                                this.sensors[index] = this.fixRelaySensor(sensor);
                            }
                        });
                        this.sensorsLoaded = true;
                        this.rssiSensor = this.sensors.find(sensor => sensor.type === 'rssi');
                    });
                console.log(this.selected);
            });


        this.settingsForm = this._formBuilder.group({
            deviceName: [this.device.deviceName, Validators.required],
            alarm_delay: [this.device.statusChangeDelay],
            notifications: [this.device.notifications]
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toogle(): void {
        if (this.manualCatergory[0]) {
            this.show = !this.show;
        }
        this.show = !this.show;
    }

    logChange(index): void {
        if (index === 1) {
            this.manualCatergory = index;
            // this.show = false;
        } else if (index === 2) {
            this.manualCatergory = index;
            // this.show = false;
        } else if (index === 0) {
            this.manualCatergory = index;
            // this.show = false;
        }
    }

    fixRelaySensor(sensor: Sensor): Sensor {
        if (sensor.data['status'] === 'COMPLETE' && sensor.data['target'] !== sensor.data['current']) {
            // console.log("Out of Sync - Refreshing Sensor");
            this._sensorsService.getSensor(sensor.sensorId).subscribe((newSensor) => {
                // console.log("data: ", data);
                sensor = newSensor;
            });
        } else {
            if (sensor.data['status'] === 'PENDING' && sensor.data['target'] === sensor.data['current']) {
                sensor.data['status'] = 'COMPLETE';
            }
        }
        return sensor;
    }
}
