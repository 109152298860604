import {BaseModel} from './base.model';
import {SbUtils as utils} from '../utils';

export interface IDevice {
    deviceId: string;
    deviceName: string;
    type: string;
    notifications: boolean;
    model: string;
    status: number;
    hwVersion: string;
    fwVersion: string;
    lastUpdate: Date;
    gatewayIp: string;
    publicIp: string;
    localIp: string;
    statusChangeDelay: number;
    location: object;
    numPorts: number;
}

export class Device extends BaseModel implements IDevice {
    public deviceId: string;
    public deviceName: string;
    public type: string;
    public notifications: boolean;
    public model: string;
    public status: number;
    public hwVersion: string;
    public fwVersion: string;
    public lastUpdate: Date;
    public gatewayIp: string;
    public publicIp: string;
    public localIp: string;
    public statusChangeDelay: number;
    public location: object;
    public numPorts: number;

    constructor(data?) {
        super();
        data = data || {};
        this.deviceId = data.deviceId || '';
        this.deviceName = data.deviceName || '';
        this.type = data.type || '';
        this.notifications = Boolean(data.notifications || false);
        this.model = data.model || '';
        this.status = Number(data.status || 0);
        this.hwVersion = data.hwVersion || '';
        this.fwVersion = data.fwVersion || '';
        this.lastUpdate = new Date(data.lastUpdate) || new Date();
        this.gatewayIp = data.gatewayIp;
        this.localIp = data.localIp;
        this.publicIp = data.publicIp;
        this.statusChangeDelay = Number(data.statusChangeDelay || 0);
        this.location = data.location || {};
        this.numPorts = Number(data.numPorts || 1);
    }

    static fromApi(dataApi): any {
        const data = dataApi;
        data['location'] = JSON.parse(data['location']);
        // @ts-ignore
        return new this(data);
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            if (key === 'location') {
                dataApi[key] = JSON.stringify(value);
            } else {
                dataApi[key] = value;
            }
        });
        return dataApi;
    }
}
