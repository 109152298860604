<fuse-card class="flex flex-col">
    <form [formGroup]="form" class="flex flex-col justify-start items-center w-full">
        <!-- Sensor Select -->
        <div class="w-full p-6 bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <div class="text-xl" [transloco]="'EXPORT.SENSOR_SELECT.TITLE'"></div>
            <div class="text-secondary" [transloco]="'EXPORT.SENSOR_SELECT.DESCRIPTION'"></div>
        </div>
        <div class="w-full mt-4 p-6">
            <div *ngIf="dataSource && dataSource.filteredData.length > 0"
                 class="rounded border-2" [ngClass]="{'border-warn': selection.isEmpty()}">
                <mat-table #table [dataSource]="dataSource" matSort>
                    <!-- Checkbox -->
                    <ng-container matColumnDef="checkbox">
                        <mat-header-cell *matHeaderCellDef fxFlex="70px">
                            <mat-checkbox color="primary"
                                          (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [disabled]="dataSource && dataSource.filteredData.length ===0"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="70px">
                            <mat-checkbox color="primary"
                                          (click)="$event.stopPropagation()"
                                          (change)="$event ? selection.toggle(row) : null"
                                          [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>
                    <!--/ Checkbox -->

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef fxFlex='50px'>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex='50px'>
                            <div class="h5 text-secondary status" fxLayout="row"
                                 [matTooltip]="row.getStatusStr()">
                                <mat-icon
                                    [ngClass]="{'text-green-700': row.status === 1, 'text-red-500': row.status<=0}"
                                    [svgIcon]="row.status>0 ? 'wifi': 'wifi_off'">
                                </mat-icon>
                                <!-- <span class="status-badge"
                                      [ngClass]="{'light-green-A700-bg': row.status==1, 'red-500-bg': row.status<=0}"></span> -->
                            </div>
                        </mat-cell>
                    </ng-container>
                    <!--/ Status -->

                    <!-- Name -->
                    <ng-container matColumnDef="sensorName">
                        <mat-header-cell mat-sort-header="sensorName" *matHeaderCellDef>
                            {{'GENERIC.Name' | transloco}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.sensorName}}
                        </mat-cell>
                    </ng-container>
                    <!--/ Name -->

                    <!-- Type -->
                    <ng-container matColumnDef="type">
                        <mat-header-cell mat-sort-header *matHeaderCellDef fxHide.lt-sm>
                            {{'GENERIC.Type' | transloco}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" fxHide.lt-sm>
                            {{getTypeStr(row.type) | transloco}}
                        </mat-cell>
                    </ng-container>
                    <!--/ Type -->

                    <!-- Device Name -->
                    <ng-container matColumnDef="deviceName">
                        <mat-header-cell mat-sort-header *matHeaderCellDef fxHide.lt-md>
                            {{'GENERIC.DEVICE.Name' | transloco}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" fxHide.lt-md>
                            {{getDeviceName(row.deviceId)}}
                        </mat-cell>
                    </ng-container>
                    <!--/ Device Name -->

                    <mat-header-row class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                    *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                             matRipple
                             (click)="selection.toggle(row)">
                    </mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                               showFirstLastButtons
                               aria-label="Select page of periodic elements">
                </mat-paginator>
            </div>
            <div *ngIf="dataSource && dataSource.filteredData.length > 0 && selection.isEmpty()"
                 class="w-full text-warn text-sm"
                 [transloco]="'EXPORT.SENSOR_SELECT.ERROR'">
            </div>

            <div class="empty-table-message" fxLayout="row" fxLayoutAlign="center center"
                 *ngIf="dataSource && dataSource.filteredData.length === 0">
                <p [transloco]="'EXPORT.SENSOR_SELECT.NONE_FOUND'"></p>
            </div>
        </div>

        <!-- Daterange select -->
        <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
            <div class="text-xl" [transloco]="'EXPORT.DATERANGE_SELECT.TITLE'"></div>
            <div class="text-secondary" [transloco]="'EXPORT.DATERANGE_SELECT.DESCRIPTION'"></div>
        </div>

        <div class="flex flex-col w-full p-6">
            <mat-form-field class="flex-auto">
                <mat-label [transloco]="'GENERIC.Date range'"></mat-label>
                <mat-date-range-input [max]="today" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date" >
                    <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="form.controls.start.invalid"
                           [transloco]="'EXPORT.DATERANGE_SELECT.ERROR.START'"></mat-error>
                <mat-error *ngIf="form.controls.end.invalid"
                           [transloco]="'EXPORT.DATERANGE_SELECT.ERROR.END'"></mat-error>
            </mat-form-field>
        </div>

        <!-- Daterange select -->
        <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
            <div class="text-xl" [transloco]="'EXPORT.DOWNSAMPLING.TITLE'"></div>
            <div class="text-secondary" [transloco]="'EXPORT.DOWNSAMPLING.DESCRIPTION'"></div>
        </div>

        <div class="grid sm:grid-cols-3 gap-6 w-full p-6">
            <div class="sm:col-span-1">
                <mat-form-field class="fuse-mat-no-subscript w-full">
                    <mat-label [transloco]="'GENERIC.Days'"></mat-label>
                    <mat-select formControlName="days"
                                [disabled]="form.value.selectAll">
                        <mat-option *ngFor="let day of days" [value]="day">
                            {{day}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="fuse-mat-no-subscript w-full">
                    <mat-label [transloco]="'GENERIC.Hours'"></mat-label>
                    <mat-select formControlName="hours"
                                [disabled]="form.value.selectAll">
                        <mat-option *ngFor="let hour of hours" [value]="hour">
                            {{hour}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="fuse-mat-no-subscript w-full">
                    <mat-label [transloco]="'GENERIC.Minutes'"></mat-label>
                    <mat-select formControlName="minutes"
                                [disabled]="form.value.selectAll">
                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                            {{minute}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sm:col-span-3 flex justify-center">
                <mat-checkbox color="primary" formControlName="selectAll">
                    {{'EXPORT.DOWNSAMPLING.EXPORT_ALL' | transloco}}
                </mat-checkbox>
            </div>
        </div>

        <mat-divider class="w-full"></mat-divider>

        <div class="flex justify-center items-center p-8">
            <button class="mr-4 sm:mr-8" mat-flat-button color="primary" (click)="onExport()"
                    [disabled]="selection.isEmpty() || form.invalid">
                <mat-icon class="mr-2" svgIcon="download"></mat-icon>
                {{'GENERIC.Export' | transloco}}
            </button>
            <button class="ml-4 sm:mr-4" mat-flat-button color="primary" (click)="onPrint()"
                    [disabled]="selection.isEmpty() || form.invalid">
                <mat-icon class="mr-2" svgIcon="print"></mat-icon>
                {{'GENERIC.Print' | transloco}}
            </button>
        </div>
    </form>
</fuse-card>
