<div class="flex flex-col justify-between items-center relative h-full w-full">
    <button mat-stroked-button type="button"  class="absolute top-0 right-0 p-2 min-w-0" [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="cloud_download"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadImage()">
            <mat-icon svgIcon="mat_outline:image"></mat-icon>
            <span>{{'SENSOR_CHART.EXPORT_SELECTOR.IMAGE' | transloco}}</span>
        </button>
        <button mat-menu-item (click)="downloadCsv()">
            <mat-icon svgIcon="fa_regular:file-spreadsheet"></mat-icon>
            <span>{{'SENSOR_CHART.EXPORT_SELECTOR.SPREADSHEET' | transloco}}</span>
        </button>
        <button mat-menu-item (click)="print()">
            <mat-icon svgIcon="mat_outline:print"></mat-icon>
            <span>{{'SENSOR_CHART.EXPORT_SELECTOR.PRINT' | transloco}}</span>
        </button>
    </mat-menu>
    <div *ngIf="loaded" class="w-full h-full">
        <!-- <button type="button" mat-raised-button (click)="getNextMonth()">Next Month</button> -->
        <div echarts [options]="chartOption" [merge]="updateOptions" [theme]="theme" (chartInit)="onChartInit($event)" class="demo-chart w-100-p min-h-80 sm:h-full"></div>
    </div>
    <div *ngIf="!loaded" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-spinner class="mb-20" [diameter]="50"></mat-spinner>
        {{loadingText}}
        <!-- <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar> -->
    </div>
    <div *ngIf="options.showDatePicker" class="flex flex-row justify-center items-center mt-2">
        <mat-form-field class="w-64">
            <mat-label [transloco]="'GENERIC.Date range'"></mat-label>
            <mat-date-range-input [max]="today" [rangePicker]="picker">
                <input matStartDate [value]="startDate" (dateChange)="onDateChanged('start', $event)"
                       placeholder="Start date">
                <input matEndDate [value]="endDate" (dateChange)="onDateChanged('end', $event)" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (opened)="datePickerOpen=true"
                                   (closed)="onDatePickerClosed($event);">
            </mat-date-range-picker>
        </mat-form-field>
    </div>
</div>
