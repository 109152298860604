import {Component, OnInit, Inject, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Sensor, Device} from '@sensorbase/models';
import {AuthenticationService} from '@sensorbase/services';
import {HttpProgressDialog, MultiSelectionDialog} from '@sensorbase/components';
import {SensorTimersService, Timer} from '@sensorbase/services';
import {DatePipe} from '@angular/common';


@Component({
    selector: 'sensor-timer-settings-dialog',
    templateUrl: './sensor-timer-settings-dialog.component.html',
    styleUrls: ['./sensor-timer-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SensorTimerSettingsDialog implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, {static: false})
    paginator: MatPaginator;

    user: any;
    sensor: Sensor;
    device: Device;
    timers: Timer[];

    defaultPosition: boolean;

    displayedColumns = ['on-off', 'name', 'duration', 'state', 'start-time', 'delete'];
    dataSource: MatTableDataSource<Timer>;
    public show = false;
    loaded = false;

    multiSelectionDialogRef: MatDialogRef<MultiSelectionDialog>;

    mainChecked = true;

    isSmallScreen: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<SensorTimerSettingsDialog>,
        private _timersService: SensorTimersService,
        private _breakpointObserver: BreakpointObserver,
        private _datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.sensor = data.sensor;
        this.device = data.device;
        console.log(this.sensor);
        this._unsubscribeAll = new Subject();

        this._timersService.onTimersChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(timers => {
                this.timers = timers[this.sensor.sensorId];
                // Assign the data to the data source for the table to render.
                this.dataSource = new MatTableDataSource(this.timers.filter(timer => timer.action !== 'remove'));
            });
        this._timersService.getSensorTimers(this.sensor)
            .then(() => {
                this.loaded = true;
            });

        // this._actionsService.getSensorActions(this.sensor.sensorId)
        //     .subscribe(actions => {
        //         this.actions = [];
        //         actions.forEach(action => {
        //             this.actions.push(new Action(action));
        //         })
        //     });

        this.isSmallScreen = this._breakpointObserver.isMatched('(max-width: 599px)');
        this._breakpointObserver.observe([
            '(max-width: 599px)',
        ]).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.isSmallScreen = result.matches;
                if (this.isSmallScreen) {
                    this.displayedColumns = ['name', 'on-off', 'duration', 'state', 'start-time', 'delete'];
                } else {
                    this.displayedColumns = ['on-off', 'name', 'duration', 'state', 'start-time', 'delete'];
                }
            });

    }

    ngOnInit(): void {
        this.defaultPosition = this.sensor.options.default_value !== 'OFF';

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // Creates new user.
    createNewTimer(): Timer {
        const timerCgf = {
            id: this.getNewId(),
            name: 'New Timer',
            action: 'add',
            objectId: this.sensor.sensorId,
            userId: AuthenticationService.getUserId(),
        };
        return new Timer(timerCgf);
    }

    async toggleChange(event, row: Timer): Promise<any>{
        console.log(row.id, event);
        const newState = row.active;

        row.runAt = new Date();
        // if (row.action == "add") {
        //     row = new Timer(await this._timersService.addTimer(row).toPromise());
        // }
        if (newState === true) {
            // this.timers.filter(t => t.active == true && t.id != row.id).forEach((timer) => {
            //     this._timersService.stopTimer(this.sensor, timer).subscribe((result) => {
            //         console.log("Other Timer Stopped", result);
            //     });
            // });
            this._timersService.startTimer(this.sensor, row).subscribe(() => {
                // console.log(result);

            });

        } else {
            this._timersService.stopTimer(this.sensor, row).subscribe(() => {
                // console.log(result);
            });
        }


    }

    // Adds new user.
    addRow(): void {
        this.timers.push(this.createNewTimer());
        this.dataSource = new MatTableDataSource(this.timers.filter(timer => timer.action !== 'remove'));
        const id = 'timer' + (this.dataSource.data.length - 1);
        console.log(id);
        setTimeout(() => {
            const elmnt = document.getElementById(id);
            console.log(elmnt);
            elmnt.scrollIntoView({behavior: 'smooth'});
        });
    }

    deleteRow(row, index): void {
        const idx = this.timers.findIndex(t => t.id === row.id);
        if (this.timers[idx].action === 'add') {
            this.timers.splice(idx, 1);
        } else {
            this.timers[idx].action = 'remove';
        }
        this.dataSource = new MatTableDataSource(this.timers.filter(timer => timer.action !== 'remove'));
    }

    toogle(): void {
        this.show = !this.show;
    }

    onSave(): void {
        console.log('Saving...');
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        this._timersService.saveSensorTimers(this.sensor).then(() => {
            httpProgressDialog.componentInstance.state = 'success';
            httpProgressDialog.afterClosed().subscribe(() => {
                this.dialogRef.close();
            });
        }).catch((err) => {
            httpProgressDialog.componentInstance.state = 'error';
            console.log('Error saving', err);
        });

    }

    onCancel(): void {
        console.log('Cancelling');
        this._timersService.resetSensorTimers(this.sensor);
        this.dialogRef.close();
    }

    getNewId(): number {
        let id = 1;
        const ids = this.timers.map((t: Timer) => t.id).sort((n1, n2) => n1 - n2);
        for (const i in ids) {
            if (ids[i] === id) {
                id = id + 1;
            }
        }
        return id;
    }

    getSecondsRemaining(timer): number {
        const startTime = new Date(this._datePipe.transform(timer.runAt));
        const now = new Date();
        const time = (now.getTime() - startTime.getTime()) / 1000;

        const hours = +timer.duration.substring(0, 2);
        const minutes = +timer.duration.substring(3, 5);
        const totalTime = hours * 60 * minutes * 60;
        console.log(time / totalTime);
        return time / totalTime;
    }

    updateRowState(row): void {
        console.log('update state', row);
        const index = this.timers.findIndex(t => t.id === row.id);
        if (!this.timers[index].action){
            this.timers[index].action = 'update';
        }
    }

}
