<mat-card>
    <div class='month-container' [ngClass]='{"disabled": ngDisabled===true}' fxLayout="column">

        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
                <div *ngFor="let i of numbers"
                     class='month-circle' [ngClass]='{"is-selected": months[i].isSelected}'
                     (click)="onMonthClicked(i)">
                    <span [transloco]="months[i].name"></span>
                </div>
            </div>
            <mat-slide-toggle class="ml-6" aria-label="All day" color="primary"
                              [(ngModel)]="allSelected" (change)="toggleAll($event)">
                {{'MONTH_PICKER.ALL_MONTHS' | transloco}}
            </mat-slide-toggle>
        </div>

    </div>


</mat-card>
