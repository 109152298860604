import {notificationTypes, sensorsConfigMap, SensorType} from '../config';
import {Sensor} from '../models';

export class SbNotificationUtils {
    constructor() {
    }
    public static getSensorTypeNotificationTypes(sensorType: SensorType): any[] {
        const sensorTypeGroup = sensorsConfigMap[sensorType].minMax ? 'MIN_MAX' : 'OPEN_CLOSED';
        return notificationTypes['sensor'][sensorTypeGroup];
    }

    public static getSensorNotificationTypes(sensor: Sensor): any[] {
        const sensorTypeGroup = sensorsConfigMap[sensor.type].minMax ? 'MIN_MAX' : 'OPEN_CLOSED';
        return notificationTypes['sensor'][sensorTypeGroup];
    }

    public static getDeviceNotificationTypes(): any[] {
        return notificationTypes['device'];
    }
}
