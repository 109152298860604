<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SENSOR_WIDGET.SETTINGS' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
        'success': sensor.status>0,
        'error': sensor.status<=0
         }">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>
        <div class="function-list m-6">
            <mat-table #table [dataSource]="dataSource">

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef fxFlex="72px" class="primary"> ID </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" fxFlex="72px">
                        {{i+1}}
                        <button type="button" mat-icon-button color="warn" (click)="deleteRow(row, i)"
                                matTooltip="Διαγραφή Συνάρτησης">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="checkbox">
                    <mat-header-cell class="primary" *matHeaderCellDef fxFlex="72px">
                        ON-OFF
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="72px">
                        <mat-slide-toggle [(ngModel)]="row.enabled"
                                          (click)="$event.stopPropagation()"
                                          (change)="onFunctionEnableToggled($event, row)">
                        </mat-slide-toggle>
                    </mat-cell>
                </ng-container>

                <!-- Delay Column -->
                <ng-container matColumnDef="description">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        ΟΝΟΜΑ
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.name}}
                    </mat-cell>
                </ng-container>

                <!-- Delay Column -->
                <ng-container matColumnDef="settings">
                    <mat-header-cell class="primary" *matHeaderCellDef fxFlex="92px">
                        ΔΙΑΓΡΑΦΗ
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i=index;" fxFlex="82px">
                        <button type="button" mat-button mat-icon-button
                                (click)="deleteRow($event, row, i)">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
                         (click)="openFunctionDialog(row)">
                </mat-row>
            </mat-table>
        </div>

        <div *ngIf="showHelp" class="message-box info mb-5" fxLayout="row wrap" fxLayout.lt-lg="column"
             fxLayoutAlign="space-between start" fxLayoutAlign.lt-lg="center center">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    {{'HELP.SENSOR.AUTOMATIONS_DIALOG.INFO' | transloco }}
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-center m-6">
            <button
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="addRow()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 hidden sm:inline-flex" [transloco]="'GENERIC.Add'"></span>
            </button>
        </div>

        <div class="absolute bottom-0 right-0 sm:relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5"
                    color="primary" (click)="toggleHelp()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>
    </div>
</div>
