import {SbUtils as utils} from '../utils';
import {BaseModel} from './base.model';

export class ContactLinkDetail extends BaseModel{
    id: string;
    notificationMedium: string;
    notificationType: string;
    parentId: number;
    apiAction: string;

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data) {
        super();
        this.id = data.id || null;
        this.notificationMedium = data.notificationMedium;
        this.notificationType = data.notificationType;
        this.parentId = data.parentId;
        this.apiAction = data.apiAction || null;
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            if (key !== 'apiAction'){
                dataApi[utils.snakeCase(key)] = value;
            }
        });
        return dataApi;
    }
}

export class ContactLink extends BaseModel {
    id: string;
    name: string;
    contactId: string;
    contactEnabled: boolean;
    smsEnabled: boolean;
    emailEnabled: boolean;
    callEnabled: boolean;
    objectId: string;
    delay: number;
    emailVerified: boolean;
    smsVerified: boolean;
    details: ContactLinkDetail[];
    apiAction: string;
    changed = false;

    constructor(data) {
        super();
        this.id = data.id || null;
        this.name = data.name || null;
        this.contactId = data.contactId;
        this.contactEnabled = data.contactEnabled || false;
        this.smsEnabled = data.smsEnabled || false;
        this.emailEnabled = data.emailEnabled || false;
        this.objectId = data.objectId;
        this.delay = data.delay || 0;
        this.emailVerified = data.emailVerified || false;
        this.smsVerified = data.smsVerified || false;
        this.details = data.details || [];
        this.apiAction = data.apiAction || null;
    }

    static fromApi(dataApi): ContactLink {
        const data = {};
        Object.entries(dataApi).forEach(([key, value]) => {
            if (key === 'details') {
                const details = [];
                if (value){
                    (value as Array<any>).forEach(detail => {
                        details.push(ContactLinkDetail.fromApi(detail));
                    });
                }
                data[utils.camelCase(key)] = details;
            } else {
                data[utils.camelCase(key)] = value;
            }
        });
        return new ContactLink(data);
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            if (key !== 'details') {
                dataApi[utils.snakeCase(key)] = value;
            }
        });
        return dataApi;
    }
}
