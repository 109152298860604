import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {MatButton} from '@angular/material/button';
import {Subject, takeUntil} from 'rxjs';
import {Notification, Sensor} from '@sensorbase/models';
import {LanguageService, NotificationsService, SensorsService} from '@sensorbase/services';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    sensors: Sensor[] = [];
    notifications: Notification[] = [];
    filteredNotifications: Notification[] = [];
    numNotificationDays = 1;
    unreadCount: number = 0;

    goodNotifications = ['IN_RANGE', 'STATUS_ON', 'STATUS_NEW', 'CLOSED'];

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _sensorsService: SensorsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _languageService: LanguageService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to notification changes
        // this._notificationsService.notifications$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((notifications: Notification[]) => {
        //
        //         // Load the notifications
        //         this.notifications = notifications;
        //
        //         // Calculate the unread count
        //         this._calculateUnreadCount();
        //
        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });

        this._sensorsService.sensors$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(sensors => {
                this.sensors = sensors;
            });

        this._notificationsService.onUnreadNotificationCountChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((unreadNotificationsCount) => {
                this.unreadCount = unreadNotificationsCount;
                this._changeDetectorRef.markForCheck();
            });

        this._notificationsService.onNewNotification
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notification: Notification) => {
                if (notification) {
                    const isGoodNotification = this.goodNotifications.includes(notification.typeDescription);
                    notification.cssClass = isGoodNotification ? 'text-green-500' : 'text-red-500';
                    notification.icon = isGoodNotification ? 'check_circle' : 'error';
                    notification.description = this.getNotificationDescription(notification);
                    this.notifications.unshift(notification);
                    this.filteredNotifications.unshift(notification);

                    // Calculate the unread count
                    this._calculateUnreadCount();

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }
            });

        this._notificationsService.getNotifications(this.numNotificationDays).then(notifications => {
            for (const notification of notifications) {
                if (this.notifications.find(x => x.id === notification.id)) {
                    continue;
                }
                const isGoodNotification = this.goodNotifications.includes(notification.typeDescription);
                notification.cssClass = isGoodNotification ? 'text-green-500' : 'text-red-500';
                notification.icon = isGoodNotification ? 'check_circle' : 'error';
                notification.description = this.getNotificationDescription(notification);
                this.notifications.push(notification);
            }
            if (this.notifications.length > this.filteredNotifications.length) {
                this.filteredNotifications = this.notifications.slice(0, 30);
            } else {
                this.filteredNotifications = this.notifications.slice(0, this.notifications.length);
            }

            // Calculate the unread count
            this._calculateUnreadCount();

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        // this._notificationsService.markAllAsRead().subscribe();
        const ids = this.notifications.filter(n => n.viewed === false).map(n => n.id);
        if (ids) {
            this._notificationsService.updateMany(ids, true);
            this.notifications.filter(n => n.viewed === false).forEach(notification => {
                notification.viewed = true;
            });
        }
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        this._notificationsService.toggleNotificationViewed(notification);
        // // Toggle the read status
        // notification.viewed = !notification.viewed;
        //
        // // Update the notification
        // this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        // this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.viewed).length;
        }

        this.unreadCount = count;
    }

    getNotificationDescription(notification: Notification): string {
       return notification.getDescription(this.sensors);
    }

    loadMore(): void {
        let lastVisible = this.filteredNotifications.length - 1;
        let newlastVisible = this.notifications.length - (lastVisible + 30) <= 0 ? this.notifications.length - 1 : lastVisible + 30;

        // Pull more notifications if necessary
        if (this.notifications.length - (lastVisible + 30) <= 0){
            this.numNotificationDays += 1;
            this._notificationsService.getNotifications(this.numNotificationDays).then(notifications => {
                for (const notificationApi of notifications) {
                    const notification = Notification.fromApi(notificationApi);
                    if (this.notifications.find(x => x.id === notification.id)){
                        continue;
                    }
                    const isGoodNotification = this.goodNotifications.includes(notification.typeDescription);
                    notification.cssClass = isGoodNotification ? 'text-green-500' : 'text-red-500';
                    notification.icon = isGoodNotification ? 'check_circle' : 'error';
                    notification.description = this.getNotificationDescription(notification);
                    this.notifications.push(notification);
                }
                lastVisible = newlastVisible;
                newlastVisible = this.notifications.length - (lastVisible + 30) <= 0 ? this.notifications.length - 1 : lastVisible + 30;
                for (let i = lastVisible + 1; i <= newlastVisible; i++) {
                    this.filteredNotifications.push(this.notifications[i]);
                }
            });
        }

        for (let i = lastVisible + 1; i <= newlastVisible; i++) {
            this.filteredNotifications.push(this.notifications[i]);
        }
    }

    getDateLocale(): string {
        return this._languageService.getLanguage();
    }
}
