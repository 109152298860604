import {Component, OnInit, Inject, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Sensor, Device} from '@sensorbase/models';
import {DevicesService, SensorsService} from '@sensorbase/services';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {forkJoin, Subject} from 'rxjs';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';

import {HttpProgressDialog, IconSelectionDialogComponent} from '@sensorbase/components';
import {SbSensorUtils} from '@sensorbase/utils';

@Component({
    selector: 'sensor-general-settings-dialog',
    templateUrl: './sensor-general-settings-dialog.component.html',
    styleUrls: ['./sensor-general-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SensorGeneralSettingsDialog implements OnInit, OnDestroy {

    device: Device;
    sensor: Sensor;
    @ViewChild('cchart', {static: false}) cchart;

    settingsForm: FormGroup;
    sensorConditions: any;

    // For Fan Display //
    humidThresholdConfig = {
        '0': {color: 'blue'},
        '20': {color: 'lightskyblue'},
        '40': {color: 'green'},
        '60': {color: 'orange'},
        '80': {color: 'red'}
    };

    manualCatergory = (0);
    selectedTabIndex = 0;
    public show = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _devicesService: DevicesService,
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<SensorGeneralSettingsDialog>,
        private _sensorsService: SensorsService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.sensor = data.sensor;
        this.device = data.device;

        console.log(this.sensor);
        console.log(this.device);
    }

    ngOnInit(): void {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        if (SbSensorUtils.isMinMaxSensor(this.sensor))
        {
            this.settingsForm = this._formBuilder.group(
                    {
                        sensorName: [this.sensor.sensorName, Validators.required],
                        alarmMin: [this.sensor.displayData - 2],
                        alarmMax: [this.sensor.displayData + 2],
                        offset: [this.sensor.options['offset'], Validators.required]
                    });
                this._sensorsService.getSensorConditions(this.sensor.sensorId)
                    .subscribe(response => {
                        this.sensorConditions = response;
                        const minMaxConditions = this.sensorConditions.find((element) => element.conditionType === 'MIN_MAX').condition;
                        this.settingsForm.patchValue(
                            {
                                alarmMin: minMaxConditions.min,
                                alarmMax: minMaxConditions.max,
                                alarm_delay: minMaxConditions.notify_delay,
                            });
                    });
        }
        else {
            switch (this.sensor.type) {
                // Doors
                case 'door': {
                    const openText = this.sensor.options.openText || '';
                    const openIcon = this.sensor.options.openIcon || 'fa_duotone:door-open';
                    const closedText = this.sensor.options.closedText || '';
                    const closedIcon = this.sensor.options.closedIcon || 'fa_duotone:door-closed';
                    this.settingsForm = this._formBuilder.group(
                        {
                            sensorName: [this.sensor.sensorName, Validators.required],
                            openText: [openText],
                            openIcon: [openIcon],
                            closedText: [closedText],
                            closedIcon: [closedIcon],
                        });
                    break;
                }
                // Relays
                case 'relay': {
                    this.settingsForm = this._formBuilder.group(
                        {
                            sensorName: [this.sensor.sensorName, Validators.required],
                            default_value: [this.sensor.options.default_value !== 'OFF']
                        });

                    this._sensorsService.subSensorData(this.sensor.sensorId)
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((message: IMqttMessage) => {});
                    break;
                }
                default: {
                    this.settingsForm = this._formBuilder.group(
                        {
                            sensorName: [this.sensor.sensorName, Validators.required],
                        });
                }
            }
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNotifications(): void {
        this.sensor.notifications = !this.sensor.notifications;
    }

    getRelayDisplayInfo(): string {
        if (this.sensor.displayData === true) {
            return 'Διακόπτης ανοιχτός';
        }
        return 'Διακόπτης κλειστός';
    }

    updateControl(state): void {
        try {
            if (this.sensor.data['current'] !== +state) {
                this.sensor.data['target'] = +state;
                this.sensor.data['status'] = 'PENDING';
                this._sensorsService.setRelaySate(this.sensor.sensorId, +state);
            }
        } catch (ex) {
            // console.log('Error updating control: ' + ex);
        }
    }

    logChange(event, index): void {
        this.selectedTabIndex = event.index;
        if (index === 1) {
            this.manualCatergory = index;
            // this.show = false;
        } else if (index === 2) {
            this.manualCatergory = index;
            // this.show = false;
        } else if (index === 0) {
            this.manualCatergory = index;
            // this.show = false;
        }
    }

    onSave(): void {
        console.log('Saving...');
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        this.sensor.sensorName = this.settingsForm.value.sensorName;
        const obs = [];
        if (SbSensorUtils.isMinMaxSensor(this.sensor)) {
            this.sensor.options['offset'] = this.settingsForm.value.offset;
            const minMaxCondition = this.sensorConditions.find((element) => element.conditionType === 'MIN_MAX');
            minMaxCondition.condition.min = this.settingsForm.value.alarmMin;
            minMaxCondition.condition.max = this.settingsForm.value.alarmMax;
            obs.push(this._sensorsService.updateSensorConditions(this.sensor.sensorId, minMaxCondition));
        }
        else {
            switch (this.sensor.type) {
                case 'door': {
                    this.sensor.options['openText'] = this.settingsForm.value.openText;
                    this.sensor.options['closedText'] = this.settingsForm.value.closedText;
                    this.sensor.options['openIcon'] = this.settingsForm.value.openIcon;
                    this.sensor.options['closedIcon'] = this.settingsForm.value.closedIcon;
                    break;
                }
                case 'relay': {
                    this.sensor.options.default_value = this.settingsForm.value.default_value ? 'ON' : 'OFF';
                    break;
                }
            }
        }
        obs.push(this._sensorsService.updateSensor(this.sensor));
        forkJoin(obs).subscribe({
            next: (response) => {
                httpProgressDialog.componentInstance.state = 'success';
                httpProgressDialog.afterClosed().subscribe(() => {
                    this.dialogRef.close();
                });
            },
            error: (error) => {
                httpProgressDialog.componentInstance.state = 'error';
            }
        });
    }

    onCancel(): void {
        console.log('Cancelling');
        this.dialogRef.close();
    }

    toogle(): void {
        if (this.manualCatergory[0]) {
            this.show = !this.show;
        }
        this.show = !this.show;
    }

    openIconSelectionDialog(type: string): void {
        const dialogRef = this._matDialog.open(IconSelectionDialogComponent, {
            panelClass: 'icon-selection-dialog',
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                if (type === 'open') {
                    this.settingsForm.get('openIcon').setValue(response);
                } else {
                    this.settingsForm.get('closedIcon').setValue(response);
                }
            });
    }

    getDoorIcon(type: string): string {
        return type === 'open' ? this.settingsForm.value.openIcon : this.settingsForm.value.closedIcon;
    }
}
