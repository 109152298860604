import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SensorbaseConfirmDialog implements OnInit {
    title = 'CONFIRM_DIALOG.TITLE';
    message = 'CONFIRM_DIALOG.MESSAGE';
    confirmText = 'CONFIRM_DIALOG.BUTTONS.CONFIRM';
    cancelText = 'CONFIRM_DIALOG.BUTTONS.CANCEL';
    translateParams: any = {};
    toolbarClass = 'primary';
    confirmBtnClass = 'primary';
    cancelBtnClass = '';
    warn = false;

    /**
     * Constructor
     *
     * @param dialogRef
     * @param _data
     */
    constructor(
        public dialogRef: MatDialogRef<SensorbaseConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.translateParams = _data.translateParams || {};
        this.warn = _data.warn || false;
        this.toolbarClass = _data.toolbarClass || 'primary';
        this.confirmBtnClass = _data.confirmBtnClass || 'primary';
        this.cancelBtnClass = _data.cancelBtnClass || '';
    }

    ngOnInit(): void {
    }

}
