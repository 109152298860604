import { Component, OnInit } from '@angular/core';

import { latLng, tileLayer, Map } from 'leaflet';
import 'leaflet-easybutton';

import {
    GeoSearchControl,
    OpenStreetMapProvider,
} from 'leaflet-geosearch';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    satelliteLayerName = '';
    streetLayerName = '';

    streetLayer = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' });
    satelliteLayer = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri'
    });

    // Leaflet-GeoSearch
    provider = new OpenStreetMapProvider();
    searchControl = new GeoSearchControl({
        provider: this.provider,
    });

    options = {
        layers: [
            this.streetLayer
        ],
        zoom: 5,
        center: latLng(53.409615, -2.957097)
    };

    layersControl = {
        baseLayers: {

        },
        overlays: {
            // 'Big Circle': circle([53.409615, -2.957097], { radius: 100 })
        }
    };

    constructor(
        public translateService: TranslocoService,
    ) {
        this.translateService.langChanges$.subscribe({
            next: () => {
                this.satelliteLayerName = this.translateService.translate('MAPS.SATELLITE');
                this.streetLayerName = this.translateService.translate('MAPS.STREET');
                this.layersControl.baseLayers = {};
                this.layersControl.baseLayers[this.streetLayerName] = this.streetLayer;
                this.layersControl.baseLayers[this.satelliteLayerName] = this.satelliteLayer;
            }
        });
    }

    ngOnInit(): void {
    }

    onMapReady(map: Map): void {
        // map.addControl(this.searchControl);
        // /* Display history & last location */
        // this.drawGpsData("30:ae:a4:13:d4:84");
        // console.log(map);
        // this.button.addTo(map);
    }

}
