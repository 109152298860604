import {SensorConfig, sensorsConfig, sensorsConfigMap, SensorType} from '../config';
import {Sensor} from '../models';

export class SbSensorUtils {

    constructor() {
    }

    public static getSensorTypeConfig(type: SensorType): SensorConfig {
        return sensorsConfigMap[type];
    }

    public static getMinMaxSensorTypes(): SensorType[] {
        return sensorsConfig.filter((sensorCfg) => sensorCfg.minMax)
            .map((sensorCfg) => sensorCfg.type);
    }

    public static getSensorTypeFromLabel(label: any): SensorType {
        return SensorType[Object.keys(SensorType)[Object.values(SensorType).indexOf(label)]];
    }

    public static getSensorLabelFromType(type: SensorType): string {
        return SensorType[type];
    }

    public static getSensorTypeDefaultUnit(type: SensorType): string {
        return sensorsConfig.find((sensorCfg) => sensorCfg.type === type).units[0];
    }

    public static isMinMaxSensor(sensor: Sensor): boolean {
        return this.isMinMaxSensorType(sensor.type);
    }

    public static isMinMaxSensorType(sensorType: SensorType): boolean {
        return sensorsConfigMap[sensorType].minMax;
    }

    public static getSensorValue(sensor: Sensor, withUnit: boolean = true): string {
        if (this.isMinMaxSensor(sensor)) {
            const value = sensor.displayData;
            if (withUnit) {
                return value + ' ' + this.getSensorTypeDefaultUnit(sensor.type);
            } else {
                return value.toString();
            }
        } else {
            if (sensor.type === SensorType.Switch) {
                return sensor.displayData;
            }
            return sensor.data['current'];
        }
    }

    public static getSensorTypeName(type: SensorType): string {
        return sensorsConfigMap[type].name;
    }
}

