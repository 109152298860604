<!-- <multi-selection-list [selectionItems]="selectionItems"></multi-selection-list> -->

<div class="flex flex-col max-h-screen -m-6">

    <mat-table #table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="checked">

            <mat-header-cell *matHeaderCellDef fxFlex="64px">
                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>

            <mat-cell *matCellDef="let element" fxFlex="64px">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(element) : null"
                              [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                </mat-checkbox>
            </mat-cell>

        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.value | transloco}} </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="hover:bg-gray-100 dark:hover:bg-hover cursor-pointer" *matRowDef="let row; columns: displayedColumns;" (click)="processChange(row);"></mat-row>

    </mat-table>

    <div class="flex flex-row items-center justify-center m-4">
        <button type="button" class="mr-2" mat-flat-button color="primary" (click)="onSave()">OK</button>
        <button type="button" class="ml-2" mat-stroked-button color="warn" (click)="onCancel()">Ακύρωση</button>
    </div>
</div>
