import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {IMqttMessage} from 'ngx-mqtt';
import {LocalNotifications as _localNotifications} from '@capacitor/local-notifications';

import {AuthenticationService} from '@sensorbase/services';
import {environment, API_BASE_URL} from 'environments/environment';
import {SensorbaseMqttService} from '../mqtt';
import {AlertService} from '../alert';
import {TranslocoService} from '@ngneat/transloco';
import {Notification} from '../../models';

@Injectable()
export class NotificationsService {
    private pollerInterval = 30;
    user: any;
    notifications: any[] = [];
    unreadNotificationsCount: number;
    hasPermission = false;
    onNotificationsChanged: BehaviorSubject<Notification[]>;
    onNewNotification: BehaviorSubject<Notification>;
    onUnreadNotificationCountChanged: BehaviorSubject<any>;

    constructor(private _httpClient: HttpClient,
                private _authService: AuthenticationService,
                private _mqttService: SensorbaseMqttService,
                private _alertService: AlertService,
                private _translateService: TranslocoService) {
        this.onNotificationsChanged = new BehaviorSubject<Notification[]>([]);
        this.onUnreadNotificationCountChanged = new BehaviorSubject([]);
        this.onNewNotification = new BehaviorSubject<Notification>(null);

        if (environment.capacitor) {
            _localNotifications.checkPermissions().then((res) => {
                this.hasPermission = res.display === 'granted';
                _localNotifications.schedule({
                    notifications: [{
                        id: 1,
                        title: 'Sensorbase Started',
                        body: 'New Notification',
                        largeIcon: 'ic_stat_icon',
                    }]
                });
                if (!this.hasPermission) {
                    _localNotifications.requestPermissions().then((res1) => {
                        this.hasPermission = res1.display === 'granted';
                    });
                }
            });
        }


        this._mqttService.onConnect.subscribe((connected) => {
            if (connected) {
                const userID = this._authService.getUserId();
                this._mqttService.observe('/' + userID + '/notification', {qos: 2})
                    .subscribe((message: IMqttMessage) => {

                        const newNotification = Notification.fromApi(JSON.parse(message.payload.toString()));
                        let noteFound = false;
                        console.log('Recieved Notification: ', newNotification);
                        this.notifications.forEach((notification, i) => {
                            if (notification.id === newNotification.id) {
                                if (!newNotification.viewed && notification.viewed) {
                                    if (environment.capacitor) {
                                        if (this.hasPermission || !environment.production) {
                                            _localNotifications.schedule({
                                                notifications: [newNotification.toNativeNotification(this._translateService)]
                                            });
                                        }

                                    }
                                } else if (newNotification.viewed) {
                                    if (environment.capacitor) {
                                        if (this.hasPermission || !environment.production) {
                                            _localNotifications.cancel({notifications: [newNotification]});
                                        }
                                    }
                                }
                                this.notifications[i].viewed = newNotification.viewed;
                                noteFound = true;
                            }
                        });

                        if (!noteFound) {
                            const tmpArray = [];
                            tmpArray.push(newNotification);
                            this.notifications = tmpArray.concat(this.notifications);
                            // this.notifications.push(newNotification);
                            if (!newNotification.viewed) {
                                if (environment.capacitor) {
                                    if (this.hasPermission || !environment.production) {
                                        _localNotifications.schedule({
                                            notifications: [newNotification.toNativeNotification(this._translateService)]
                                        });
                                    }
                                }
                                const alert = newNotification.toAlert();
                                this._alertService.alert(alert);

                            }
                        }
                        const unreadNotifications = this.notifications.filter((notification) => !notification.viewed);
                        if (unreadNotifications.length === 0 && this.hasPermission) {
                            _localNotifications.removeAllDeliveredNotifications();
                        }


                        this.unreadNotificationsCount = unreadNotifications.length;
                        this.onNotificationsChanged.next(this.notifications);
                        this.onUnreadNotificationCountChanged.next(this.unreadNotificationsCount);
                        if (!noteFound) {
                            this.onNewNotification.next(newNotification);
                        }
                    });
            }
        });
        this.poller(this.pollerInterval);

    }

    poller(minutes: number): void {
        setTimeout(() => {
            if (this._authService.loggedIn()) {
                console.log('Polling notifications');
                const userID = this._authService.getUserId();
                this._httpClient.get(API_BASE_URL + 'users/' + userID + '/notifications?days=1')
                    .subscribe((response: any) => {
                        this.notifications = response.map((notification) => Notification.fromApi(notification));
                        const unreadNotifications = this.notifications.filter((notification) => !notification.viewed);
                        if (environment.capacitor) {
                            if (unreadNotifications.length === 0 && this.hasPermission) {
                                _localNotifications.removeAllDeliveredNotifications();
                            }
                        }
                        if (unreadNotifications.length > 0) {
                            this._alertService.info({body: 'Unread Notifications'});
                        }
                        this.unreadNotificationsCount = unreadNotifications.length;
                        this.onNotificationsChanged.next(this.notifications);
                        this.onUnreadNotificationCountChanged.next(this.unreadNotificationsCount);
                        this.poller(this.pollerInterval);

                    }, (error) => {
                        console.log('Poller error:', error);
                        this.poller(this.pollerInterval);
                    });
            }

        }, 60000 * minutes);
    }

    getNotifications(days: number, sensorType?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this._authService.loggedIn()) {
                const userID = this._authService.getUserId();
                let params = '?days=' + days;
                if (sensorType) {
                    params += '&sensor_type=' + sensorType;
                }
                this._httpClient.get(API_BASE_URL + 'users/' + userID + '/notifications' + params).toPromise()
                    .then((response: any) => {
                            this.notifications = response.map((notification) => Notification.fromApi(notification));
                            const unreadNotifications = this.notifications.filter((notification) => !notification.viewed);
                            if (environment.capacitor) {
                                if (unreadNotifications.length === 0 && this.hasPermission) {
                                    _localNotifications.removeAllDeliveredNotifications();
                                }
                            }
                            if (unreadNotifications.length > 0) {
                                this._alertService.info({body: 'New Notifications'});
                            }
                            this.unreadNotificationsCount = unreadNotifications.length;
                            this.onNotificationsChanged.next(this.notifications);
                            this.onUnreadNotificationCountChanged.next(this.unreadNotificationsCount);
                            resolve(this.notifications);
                        },
                        error => {
                            reject(error);
                        });
            } else {
                reject('Not Authenticated');
            }
        });
    }

    updateNotification(notification): void {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            this._httpClient.put(API_BASE_URL + 'users/' + userID + '/notifications/' + notification.id, {viewed: notification.viewed})
                .subscribe((res) => {

                    this.notifications.forEach((notificationI, i) => {
                        if (notification.id === this.notifications[i].id) {
                            this.notifications[i].viewed = notification.viewed;
                            this._mqttService.unsafePublish('/' + userID + '/notification', JSON.stringify(notification), 0, false);
                        }

                    });
                    const unreadNotifications = this.notifications.filter((notificationI) => !notificationI.viewed);
                    if (environment.capacitor) {
                        if (unreadNotifications.length === 0 && this.hasPermission) {
                            _localNotifications.removeAllDeliveredNotifications();
                        }
                    }
                    this.unreadNotificationsCount = unreadNotifications.length;
                    this.onNotificationsChanged.next(this.notifications);
                    this.onUnreadNotificationCountChanged.next(this.unreadNotificationsCount);
                    // eslint-disable-next-line no-console
                    console.debug('notification update complete');
                });
        }
    }

    updateMany(notificationIds: number[], viewed: boolean = true): void {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            this._httpClient.put(API_BASE_URL + 'users/' + userID + '/notifications', {
                notification_id: notificationIds,
                viewed: viewed
            }).subscribe((res) => {
                this.notifications.forEach((notification, i) => {
                    if (notificationIds.includes(notification.id)) {
                        this.notifications[i].viewed = viewed;
                        this._mqttService.unsafePublish('/' + userID + '/notification', JSON.stringify(this.notifications[i]), 0, false);
                    }

                });
                const unreadNotifications = this.notifications.filter((notification) => !notification.viewed);
                if (environment.capacitor) {
                    if (unreadNotifications.length === 0 && this.hasPermission) {
                        _localNotifications.removeAllDeliveredNotifications();
                    }
                }
                this.unreadNotificationsCount = unreadNotifications.length;
                this.onNotificationsChanged.next(this.notifications);
                this.onUnreadNotificationCountChanged.next(this.unreadNotificationsCount);
                // eslint-disable-next-line no-console
                console.debug('notification update complete');
            });
        }
    }

    toggleNotificationViewed(notification: any): void {
        notification.viewed = !notification.viewed;
        this.updateNotification(notification);
    }

    setNotificationViewed(notification: any): void {
        notification.viewed = true;
        this.updateNotification(notification);
    }

}
