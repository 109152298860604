import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, ReplaySubject, take, tap} from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import {DashboardsService} from '../../../@sensorbase/services';
import {FuseNavigationItem} from '../../../@fuse/components/navigation';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private _dashboardsService: DashboardsService,
                private _translocoService: TranslocoService)
    {
        this._dashboardsService.dashboards$.subscribe((dashboards: any) => {
            console.log(dashboards);
            const dashboardItems: FuseNavigationItem[] = [];

            // Add the dashboards
            for (const dashboard of dashboards) {
                // console.log(data.sensorId);
                dashboardItems.push({
                    id: dashboard.id,
                    title: dashboard.name,
                    type: 'basic',
                    icon: dashboard.settings.icon,
                    link: '/admin/dashboards/' + dashboard.id,
                });
            }

            // Add the divider
            dashboardItems.push(
                {
                    id  : 'divider-1',
                    type: 'divider',
                },
            );

            // Add the dashboards home and add new dashboard
            dashboardItems.push(
                {
                    id: 'dashboards_home',
                    title: 'Dashboards Home',
                    translate: 'NAV.MAINMENU.DASHBOARDS.HOME',
                    type: 'basic',
                    icon: 'home',
                    link: '/admin/dashboards/home',
                }
            );
            dashboardItems.push(
                {
                    id: 'add_dashboard',
                    title: 'New Dashboard',
                    translate: 'NAV.MAINMENU.DASHBOARDS.ADD',
                    type: 'basic',
                    icon: 'add',
                    link: '/admin/dashboards/home',
                    queryParams: {new: true}
                }
            );

            // Ensure that the translation is set for each item
            dashboardItems.forEach(item => {
                this._fixTranslation(item);
            });

            this._navigation
                .pipe(take(1))
                .subscribe((navigation) => {
                    navigation.default[0].children[1].children = dashboardItems;
                    navigation.compact[0].children[1].children = dashboardItems;
                    navigation.futuristic[0].children[1].children = dashboardItems;
                    navigation.horizontal[0].children[1].children = dashboardItems;
                    this._navigation.next(navigation);
                });
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    /**
     * Fix the translation for the given item. The translation is performed by updating the item's
     * title, since the navigation components have not been set to use transloco.
     */
    private _fixTranslation(item: any): void {
        if (item.translate){
            this._translocoService.selectTranslate(item.translate).pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    item.title = translation;
                });

            if (item.children){
                item.children.forEach(childItem => {
                    this._fixTranslation(childItem);
                });
            }
        }
    }
}
