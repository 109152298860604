import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {fuseAnimations} from '@fuse/animations';
import {Dashboard} from '@sensorbase/models';
import {DashboardsService} from '@sensorbase/services';

@Component({
    selector: 'dashboards-new-board-dialog',
    templateUrl: './new-board-dialog.component.html',
    styleUrls: ['./new-board-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class DashboardsNewDashboardDialog {
    action: string;
    dashboard: Dashboard;
    dashboardForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param matDialogRef
     * @param _data
     * @param _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _dashboardsService
     */
    constructor(
        public matDialogRef: MatDialogRef<DashboardsNewDashboardDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _dashboardsService: DashboardsService,
    ) {

        this.dashboard = new Dashboard({});

        this.dashboardForm = this.createDashboardForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns
     */
    createDashboardForm(): FormGroup {

        return this._formBuilder.group({
            name: [this.dashboard.name, Validators.required],
        });
    }

    onAdd(): void {
        console.log('Adding...');
        this.matDialogRef.close(['add', this._formToDashboard(this.dashboardForm)]);
    }

    private _formToDashboard(form: FormGroup): Dashboard {

        this.dashboard.name = this.dashboardForm.value.name;

        return this.dashboard;
    }
}
