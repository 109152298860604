import {
    Directive,
    Output,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    ElementRef
} from '@angular/core';

@Directive({
    selector: '[longPress]'
})
export class LongPressDirective {
    pressing: boolean;
    longPressing: boolean;
    timeout: any;
    interval: any;
    observer: MutationObserver;

    @Input()
    disabled = false;

    // tslint:disable-next-line:no-output-on-prefix
    @Output()
        // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    onLongPress = new EventEmitter();

    // tslint:disable-next-line:no-output-on-prefix
    @Output()
        // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    onLongPressing = new EventEmitter();

    @HostBinding('class.press')
    get press(): boolean {
        return this.pressing;
    }

    @HostBinding('class.longpress')
    get longPress(): boolean {
        return this.longPressing;
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onMouseDown(event): void {
        this.pressing = true;
        this.longPressing = false;
        this.timeout = setTimeout(() => {
            this.longPressing = true;
            this.onLongPress.emit(event);
            this.interval = setInterval(() => {
                if (this.disabled) {
                    clearInterval(this.interval);
                    this.endPress();
                } else {
                    this.onLongPressing.emit(event);
                }
            }, 50);
        }, 500);
    }

    @HostListener('touchend')
    @HostListener('mouseup')
    @HostListener('mouseleave')
    endPress(): void {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
        this.longPressing = false;
        this.pressing = false;
    }
}
