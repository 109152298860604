import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';

import { AuthenticationService } from '@sensorbase/services';
import { AuthUtils } from '@sensorbase/utils';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    private authenticated: boolean;

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthenticationService,
        private _router: Router
    )
    {
        this._authService.onAuthenticationEvent.subscribe((authenticate) => {
            this.authenticated = authenticate;
        });
    }

    addAuthenticationToken(request): any {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this._authService.accessToken}`
            }
        });
        return request;
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // TODO: Handle Whitelisted URLs!!

        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
        {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                if (error.status === 401 && this._authService.accessToken && !newReq.url.includes('auth/')) {
                    // Catch 401 when logged in (roken expired)
                    console.log('Attempt to Refresh token');
                    return this._authService.refreshToken(true).pipe(switchMap(() => {
                        return next.handle(this.addAuthenticationToken(newReq));
                    }), catchError((err) => {
                        console.log('Failed to refresh token', err);
                        this._authService.signOut();
                        return throwError(() => err);
                    }));
                } else if (error.status === 401 && this._router.url.includes('admin')) {
                    // auto logout if 401 response returned from api
                    this._authService.signOut();
                    this._router.navigate(['/auth/sign-in']);
                    return throwError(() => error);
                } else if (error.status === 401 && error.error !== 'Wrong Password') {
                    this._authService.signOut();
                    return throwError(() => error);
                }

                // // Catch "401 Unauthorized" responses
                // if ( error instanceof HttpErrorResponse && error.status === 401 )
                // {
                //     // Sign out
                //     this._authService.signOut();
                //
                //     // Reload the app
                //     location.reload();
                // }

                return throwError(() => error);


            })
        );
    }
}
