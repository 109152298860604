<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">

    <!-- Header & Cards -->
    <div class="relative pt-8 pb-12 sm:pt-20 sm:pb-24 px-6 sm:px-16 overflow-hidden">
        <!-- Background - @formatter:off -->
        <svg class="-z-1 absolute inset-0"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
            <g class="opacity-40 text-gray-200 dark:text-gray-800" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- @formatter:on -->
        <!-- Header -->
        <div class="flex flex-col items-center">
            <h2 class="text-xl font-semibold">SMS</h2>
            <div class="mt-1 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
                Διαθέσιμα Πακέτα
            </div>
            <div class="mt-3 sm:text-2xl text-center tracking-tight text-secondary">
                <div class="">Επέλεξε κάποιο από τα παρακάτω πακέτα, για να μπορείς να λαμβάνεις ειδοποιήσεις με sms.</div>
<!--                <div class="">Take control of everything.</div>-->
            </div>
            <!-- Recurring selector -->
<!--            <div class="flex items-center mt-8 sm:mt-16 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">-->
<!--                <button-->
<!--                    class="px-6"-->
<!--                    [ngClass]="{'bg-card pointer-events-none shadow': yearlyBilling,-->
<!--                                'bg-transparent': !yearlyBilling}"-->
<!--                    mat-flat-button-->
<!--                    (click)="yearlyBilling = true"-->
<!--                    disableRipple>-->
<!--                    Yearly billing-->
<!--                </button>-->
<!--                <button-->
<!--                    class="px-6"-->
<!--                    [ngClass]="{'bg-card pointer-events-none shadow': !yearlyBilling,-->
<!--                                'bg-transparent': yearlyBilling}"-->
<!--                    mat-flat-button-->
<!--                    (click)="yearlyBilling = false"-->
<!--                    disableRipple>-->
<!--                    Monthly billing-->
<!--                </button>-->
<!--            </div>-->
        </div>
        <!-- Cards -->
        <div class="flex justify-center mt-10 sm:mt-20">
            <div class="w-full max-w-sm md:max-w-7xl">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 lg:gap-y-0 md:gap-x-6">
                    <!-- Card 1 -->
                    <fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10">
                        <div class="text-4xl font-bold tracking-tight leading-tight">50 SMS</div>
                        <div class="mt-2 text-lg font-medium tracking-tight text-secondary">Καλό ξεκίνημα για λίγα alarms</div>
                        <div class="w-8 h-1 my-10 rounded bg-accent"></div>
                        <!-- Price -->
                        <div class="flex items-baseline whitespace-nowrap">
<!--                            <div class="mr-2 text-2xl">USD</div>-->
                            <div class="text-6xl font-semibold leading-tight tracking-tight">
                                <ng-container *ngIf="yearlyBilling">6.00€</ng-container>
<!--                                <ng-container *ngIf="!yearlyBilling">$9.00</ng-container>-->
                            </div>
                        </div>
                        <!-- Price details -->
<!--                        <div class="flex flex-col mt-2 text-secondary">-->
<!--                            <ng-container *ngIf="yearlyBilling">-->
<!--                                <div>billed yearly</div>-->
<!--                                <div><b>$9.00</b> billed monthly</div>-->
<!--                            </ng-container>-->
<!--                            <ng-container *ngIf="!yearlyBilling">-->
<!--                                <div>billed monthly</div>-->
<!--                                <div><b>$6.00</b> billed yearly</div>-->
<!--                            </ng-container>-->
<!--                        </div>-->
                        <!-- CTA -->
                        <button
                            class="fuse-mat-button-large mt-10"
                            mat-stroked-button
                            [color]="'primary'">
                            Απέκτησε το
                        </button>
                        <!-- Features -->
<!--                        <div class="flex flex-col mt-12">-->
<!--                            <div class="font-semibold">Core features, including:</div>-->
<!--                            <div class="mt-4 space-y-2">-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>10</b> projects</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>5GB</b> storage</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Analytics</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Free mobile app</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Access to forums</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </fuse-card>
                    <!-- Card 2 -->
                    <fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10 ring-2 ring-primary overflow-visible">
                        <div class="absolute inset-x-0 -top-4 flex items-center justify-center">
                            <div class="flex items-center h-8 px-8 rounded-full font-medium text-center leading-none bg-primary text-on-primary">Προτεινόμενο</div>
                        </div>
                        <div class="text-4xl font-bold tracking-tight leading-tight">100 SMS</div>
                        <div class="mt-2 text-lg font-medium tracking-tight text-secondary">Η πιο συνηθησμένη επιλογή</div>
                        <div class="w-8 h-1 my-10 rounded bg-accent"></div>
                        <!-- Price -->
                        <div class="flex items-baseline whitespace-nowrap">
<!--                            <div class="mr-2 text-2xl">USD</div>-->
                            <div class="text-6xl font-semibold leading-tight tracking-tight">
                                <ng-container *ngIf="yearlyBilling">12.00€</ng-container>
<!--                                <ng-container *ngIf="!yearlyBilling">$15.00</ng-container>-->
                            </div>
                        </div>
                        <!-- Price details -->
<!--                        <div class="flex flex-col mt-2 text-secondary">-->
<!--                            <ng-container *ngIf="yearlyBilling">-->
<!--                                <div>billed yearly</div>-->
<!--                                <div><b>$15.00</b> billed monthly</div>-->
<!--                            </ng-container>-->
<!--                            <ng-container *ngIf="!yearlyBilling">-->
<!--                                <div>billed monthly</div>-->
<!--                                <div><b>$12.00</b> billed yearly</div>-->
<!--                            </ng-container>-->
<!--                        </div>-->
                        <!-- CTA -->
                        <button
                            class="fuse-mat-button-large mt-10"
                            mat-flat-button
                            [color]="'primary'">
                            Απέκτησε το
                        </button>
                        <!-- Features -->
<!--                        <div class="flex flex-col mt-12">-->
<!--                            <div class="font-semibold">Personal features, plus:</div>-->
<!--                            <div class="mt-4 space-y-2">-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>Unlimited</b> projects</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>Unlimited</b> storage</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Custom domains</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Bulk editing</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">12 / 5 support</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </fuse-card>
                    <!-- Card 3 -->
                     <fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10">
                        <div class="text-4xl font-bold tracking-tight leading-tight">200 SMS</div>
                        <div class="mt-2 text-lg font-medium tracking-tight text-secondary">Η επιλογή για να έχεις το κεφάλι σου ήσυχο</div>
                        <div class="w-8 h-1 my-10 rounded bg-accent"></div>
                        <!-- Price -->
                        <div class="flex items-baseline whitespace-nowrap">
<!--                            <div class="mr-2 text-2xl">USD</div>-->
                            <div class="text-6xl font-semibold leading-tight tracking-tight">
                                <ng-container *ngIf="yearlyBilling">18.00€</ng-container>
<!--                                <ng-container *ngIf="!yearlyBilling">$9.00</ng-container>-->
                            </div>
                        </div>
                        <!-- Price details -->
<!--                        <div class="flex flex-col mt-2 text-secondary">-->
<!--                            <ng-container *ngIf="yearlyBilling">-->
<!--                                <div>billed yearly</div>-->
<!--                                <div><b>$9.00</b> billed monthly</div>-->
<!--                            </ng-container>-->
<!--                            <ng-container *ngIf="!yearlyBilling">-->
<!--                                <div>billed monthly</div>-->
<!--                                <div><b>$6.00</b> billed yearly</div>-->
<!--                            </ng-container>-->
<!--                        </div>-->
                        <!-- CTA -->
                        <button
                            class="fuse-mat-button-large mt-10"
                            mat-stroked-button
                            [color]="'primary'">
                            Απέκτησε το
                        </button>
                        <!-- Features -->
<!--                        <div class="flex flex-col mt-12">-->
<!--                            <div class="font-semibold">Core features, including:</div>-->
<!--                            <div class="mt-4 space-y-2">-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>10</b> projects</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5"><b>5GB</b> storage</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Analytics</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Free mobile app</div>-->
<!--                                </div>-->
<!--                                <div class="flex">-->
<!--                                    <mat-icon-->
<!--                                        class="icon-size-5 text-green-600"-->
<!--                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>-->
<!--                                    <div class="ml-2 leading-5">Access to forums</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </fuse-card>
                </div>
            </div>
        </div>
    </div>

    <!-- Features -->
<!--    <div class="flex flex-col items-center px-6 py-10 sm:px-16 sm:pt-18 sm:pb-20 bg-white dark:bg-gray-800">-->
<!--        <div class="w-full max-w-7xl">-->
<!--            <div>-->
<!--                <div class="text-4xl font-extrabold tracking-tight leading-tight">-->
<!--                    Everything you need to build efficiently-->
<!--                </div>-->
<!--                <div class="max-w-xl mt-2 text-xl text-secondary">-->
<!--                    Start building your app using our tools, be efficient, spend less time with details more time with your business-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Features grid &ndash;&gt;-->
<!--            <div class="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 lg:gap-16 w-full mt-12 sm:mt-16">-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:pencil-alt'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Create and Edit Projects</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Create and edit projects, upload images via drag drop, add categories, add custom fields, create interactive forms and more.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:filter'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Search and Filter</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Search and filter within the projects, categories and custom fields. Save search and filter details for easy access.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:refresh'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Real Time Updates</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Real time updates that doesn't require page reload. Lean back and watch the changes happen in real time.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:tag'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Meta Information</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Add and remove meta information to custom fields to differentiate them in reports and analytics results, use them for detailed reports.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:document-text'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Pre-rendered Results</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Pre-render results to make reports and analytics more accessible by screen readers and other accessibility tools.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <span class="flex items-center justify-center w-12 h-12 rounded bg-primary">-->
<!--                        <mat-icon-->
<!--                            class="text-white"-->
<!--                            [svgIcon]="'heroicons_outline:chart-square-bar'"></mat-icon>-->
<!--                    </span>-->
<!--                    <div class="mt-4 text-xl font-medium">Simple Analytics</div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        Simple analytics with no unnecessary data flow or weight. More readable results with less data consumption.-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <!-- CTA -->
<!--    <div class="px-6 py-10 sm:py-12 sm:px-16 bg-primary-600 text-on-primary-600">-->
<!--        <div class="flex flex-col items-center w-full max-w-7xl mx-auto text-center">-->
<!--            <div class="text-3xl sm:text-4xl sm:text-5xl font-extrabold leading-6 sm:leading-10">Boost your productivity.</div>-->
<!--            <div class="mt-2 text-3xl sm:text-4xl sm:text-5xl font-extrabold leading-6 sm:leading-10 text-black text-opacity-70">Start using Fuse today.</div>-->
<!--            <button-->
<!--                mat-flat-button-->
<!--                class="fuse-mat-button-large mt-8 px-12 bg-white text-primary-800">-->
<!--                <span class="text-lg">Sign up for free</span>-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->

    <!-- Frequently asked questions -->
<!--    <div class="flex flex-col items-center pt-12 sm:pt-18 pb-8 sm:pb-20 px-6 sm:px-16 bg-gray-50 dark:bg-gray-800">-->
<!--        <div class="w-full max-w-7xl">-->
<!--            <div>-->
<!--                <div class="text-4xl font-extrabold tracking-tight leading-tight">-->
<!--                    Frequently asked questions-->
<!--                </div>-->
<!--                <div class="max-w-xl mt-2 text-xl text-secondary">-->
<!--                    Here are the most frequently asked questions you may check before getting started-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; FAQs grid &ndash;&gt;-->
<!--            <div class="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:gap-x-16 w-full mt-12 sm:mt-16">-->
<!--                <div>-->
<!--                    <div class="text-xl font-semibold">-->
<!--                        What is the duration of the free trial?-->
<!--                    </div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        <p>-->
<!--                            Our app is free to try for 14 days, if you want more, you can provide-->
<!--                            payment details which will extend your trial to 30 days providing you-->
<!--                            an extra 16 more days to try our app.-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="text-xl font-semibold">-->
<!--                        Are there discounts for non-profits or educational use?-->
<!--                    </div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        <p>-->
<!--                            Yes, our Personal and Premium packages are free for non-profits and-->
<!--                            educational use. E-mail your details to us after starting your Free-->
<!--                            Trial and we will upgrade your account if you qualify.-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="text-xl font-semibold">-->
<!--                        What is the storage is for?-->
<!--                    </div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        <p>-->
<!--                            Since we provide an extremely detailed reporting and analytics-->
<!--                            tool, they require quite a bit storage space. For average use, you-->
<!--                            don’t have to worry about running out of space since the-->
<!--                            Personal package limits the projects you can have.-->
<!--                        </p>-->
<!--                        <p class="mt-2">-->
<!--                            For some reason if you run out of space, contact us and we will-->
<!--                            see what can be done about it and make sure you are not-->
<!--                            generating unnecessary reports and/or analytics data.-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="text-xl font-semibold">-->
<!--                        What happens if I’m not satisfied?-->
<!--                    </div>-->
<!--                    <div class="mt-2 leading-6 text-secondary">-->
<!--                        <p>-->
<!--                            If you are still in your free trial period, you can cancel your-->
<!--                            account at anytime with a single click of a button. If you already-->
<!--                            paid for your first month, we also offer 30-day money-back-->
<!--                            guarantee with no questions asked.-->
<!--                        </p>-->
<!--                        <p class="mt-2">-->
<!--                            After first month, you can still cancel your account at any time-->
<!--                            but we will calculate the amount that corresponds to days you-->
<!--                            have been using our app for that month and refund only the-->
<!--                            remaining amount.-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
