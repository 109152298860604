import {Component, Input, IterableDiffers, OnDestroy, OnInit} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {filter, map, pluck, retry, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Sensor, SensorChartOptions} from '@sensorbase/models';
import {ExcelExportService, SensorsService} from '@sensorbase/services';
import {HttpProgressDialog, DownsamplingSelectionDialog} from '@sensorbase/components';
import {ExportSelectionDialog} from '../sensor-chart';
import {TranslocoService} from '@ngneat/transloco';
import {sbAnimations} from '../../../animations';
import {fuseAnimations} from '../../../../@fuse/animations';
import {FuseConfigService} from '../../../../@fuse/services/config';
import {AppConfig} from '../../../../app/core/config/app.config';
import {EChartsOption} from 'echarts';
import {SbSensorUtils} from '../../../utils';
import {SensorType} from '../../../config';

@Component({
    selector: 'sb-multi-sensor-chart',
    templateUrl: './multi-sensor-chart.component.html',
    styleUrls: ['./multi-sensor-chart.component.scss'],
    animations: [sbAnimations, fuseAnimations],
})
export class MultiSensorChartComponent implements OnInit, OnDestroy {

    private _sensors: Sensor[];
    private _options: SensorChartOptions;

    @Input() set sensors(value: Sensor[]) {
        this._sensors = value;
        this.init();
    }

    get sensors(): Sensor[] {
        return this._sensors;
    }

    @Input() set dateRange(value: Date[]) {
        this.startDate = value[0];
        this.endDate = value[1];
        this.init();
        // if (this.initialised) {
        //     this.init();
        // }
    }

    @Input() set options(opts: SensorChartOptions) {
        this._options = opts;
        this.chartOption.grid.left = this._options.leftMargin || this.chartOption.grid.left;
        this.chartOption.grid.bottom = this._options.bottomMargin || this.chartOption.grid.bottom;
    }

    get options(): SensorChartOptions {
        return this._options;
    }

    initialised = false;
    loadingText = 'Loading...';
    loaded = true;
    progress = 0;
    year = new Date().getFullYear();
    isMobile = false;

    theme = 'light';
    eChartData: any;
    initOpts: any = {
        renderer: 'svg'
    };
    chartOption: any = {
        backgroundColor: 'transparent',
        title: {
            left: 'center',
            text: 'Test Chart',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                axis: 'x'
            },
            valueFormatter: (val) => {
                if (Array.isArray(val)) {
                    return val[1];
                }
                return val;
            }
        },
        grid: {
            left: 50,
            right: 50,
            bottom: 100,
            containLabel: false
        },
        toolbox: {
            // top: '25px',
            left: 'center',
            feature: {
                dataZoom: {
                    // yAxisIndex: 'none',
                    title: {
                        zoom: 'Zoom Tool',
                        back: 'Reset Zoom'
                    }
                },
                restore: {
                    title: 'Reload'
                },
            }
        },
        legend: {
            data: [],
            type: 'scroll',
            bottom: 50
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                // formatter: '{HH}:{mm} {dd}/{MM}/{yyyy}'
                formatter: {
                    day: '{d} {MMM}', // 1, 2, ...
                    year: '{yyyy}'
                },
                rich: {
                    monthStyle: {
                        fontWeight: 'bold',
                    }
                },
                hideOverlap: true
            }
        },
        yAxis: {},
        dataZoom: [],
        series: [
        ]
    };
    updateOptions: EChartsOption = {
        series: [],
        legend: {
            data: []
        }
    };

    datePickerOpen = false;
    startDate: Date;
    endDate: Date;
    today: Date;

    iterableDiffer: any;
    sensorTypes: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _unsubscribeMqtt: Subject<any> = new Subject();
    _series = {};

    constructor(
        private _sensorsService: SensorsService,
        private _excelExportService: ExcelExportService,
        private _translateService: TranslocoService,
        private _fuseConfigService: FuseConfigService,
        private iterableDiffers: IterableDiffers,
        private _matDialog: MatDialog,
        private _platform: Platform) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.iterableDiffer = iterableDiffers.find([]).create(null);

    }

    ngOnInit(): void {

        if (this.options === undefined) {
            this.options = new SensorChartOptions();
        }

        this.today = new Date();
        // End date is today
        if (!this.endDate) {
            this.endDate = new Date();
            // Set to beginning of the day
            this.endDate.setHours(23, 59, 59, 0);
        }

        // Start date is yesterday
        if (!this.startDate) {
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - 1);
            this.startDate.setHours(0, 0, 0, 0);
        }

        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
            this.chartOption.grid.right = '15%';
        }

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                this.theme = config.scheme;
            });

        this._translateService.events$.pipe(
            takeUntil(this._unsubscribeAll),
            filter(e => e.type === 'langChanged'),
            pluck('payload')
        ).subscribe(() => {
                this.chartOption.title['text'] = this._translateService.translate('CHARTS.HISTORY.sensors');
                this.sensorTypes.forEach((type, idx) => {
                    this.chartOption.yAxis[idx]['name'] = this._translateService.translate('CHARTS.AXIS_LABELS.' + type);
                    this.chartOption.yAxis[idx]['name'] += ' (' + SbSensorUtils.getSensorTypeDefaultUnit(type) + ')';
                });
                this.updateChart();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    init(): void {
        this.chartOption.title['text'] = this._translateService.translate('CHARTS.HISTORY.sensors');
        this.chartOption.grid.right = 50;
        this.chartOption.grid.left = 70;

        this.sensorTypes = new Set();

        this.sensors.forEach(sensor => {
            this.sensorTypes.add(sensor.type);
        });

        this.sensorTypes = Array.from(this.sensorTypes);

        this.chartOption.yAxis = [];
        this.chartOption.series = [];

        // Setup yAxis options
        if (this.sensorTypes.length > 0){
            this.chartOption.yAxis = [];
        }
        this.sensorTypes.forEach((type, idx) => {
            const option = this.getYAxisOption(type, idx);
            this.chartOption.yAxis.push(option);
        });

        // Set up datazoom tool
        this.setupDatazoom();

        const promises = this.getDateRanges(this.startDate, this.endDate);
        Promise.all(promises).then(() => {
            this.loaded = true;
            setTimeout(() => {
                this.setupMargins();
                this.updateChart();
            }, 1000);
        })
        .catch((error) => {
            this.eChartData.showLoading({
                text: 'Oops! Something went wrong...\nPlease try again',
                textColor: 'red',
                showSpinner: false,
                zlevel: 0
            });
            console.log(error);
        });
    }

    onChartInit(chartInstance): void {
        console.log('Chart initialised');
        this.eChartData = chartInstance;
        this.eChartData.showLoading();
        // this.updateChart();
    }

    updateChart(): void {
        this.loaded = true;
        if (this.eChartData) {
            this.eChartData.setOption(this.chartOption, true);
            const series = [];
            Object.keys(this._series).forEach(key => {
                series.push(this._series[key]);
            });
            this.updateOptions = {
                series: series,
            };
            this.eChartData.hideLoading();
        }
    }

    setupMargins(): void {
        if (this.options.showTitle) {
            this.chartOption.grid.top = '80px';
            this.chartOption.toolbox.top = '20px';
            if (this.chartOption.visualMap) {
                this.chartOption.visualMap.top = '45px';
            }
        } else {
            this.chartOption.grid.top = '60px';
            this.chartOption.toolbox.top = '0px';
            if (this.chartOption.visualMap) {
                this.chartOption.visualMap.top = '30px';
            }
        }
    }

    getDateRanges(fromDate: Date, toDate: Date): Promise<any>[] {
        // this.loaded = false;
        if (this.eChartData){
            this.eChartData.showLoading();
        }
        const promises = [];
        this.chartOption.series = [];
        this.chartOption.legend.data = [];
        this._series = {};
        this.sensors.forEach(sensor => {
            promises.push(this.getDateRange(sensor, this.startDate, this.endDate));
        });
        return promises;
    }

    getDateRange(sensor: Sensor, fromDate: Date, toDate: Date): Promise<any> {
        return new Promise((resolve, reject) => {
            // this.loaded = false;
            this.loadingText = 'Downloading data...';
            // let tooltipLabel = '';
            let sampling: any = 'lttb';
            let step: any = 'start';
            const series = {
                name: sensor.sensorName,
                type: 'line',
                smooth: true,
                showSymbol: false,
                // itemStyle: {
                //     color: 'rgb(70, 172, 255)'
                // },
                // clip: false,
                // step: 'start',
                data: []
            };
            series['yAxisIndex'] = this.sensorTypes.findIndex(x => x === sensor.type);
            const diffTime = toDate.getTime() - fromDate.getTime();
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays < 3) {
                sampling = false;
            }
            this._sensorsService.getSensorData(sensor.sensorId, fromDate, toDate)
                .pipe(
                    // Add retry logic to handle failed requests
                    retry(3)
                )
                .subscribe({
                    next: (data: Array<any>) => {
                        switch (sensor.type) {
                            case ('relay'): {
                                step = 'start';
                                sampling = false;
                                data = data.map(x => {
                                    const val = x[1] === 0 ? 'OFF' : 'ON';
                                    return [x[0], val];
                                });
                                break;
                            }
                            case ('door'): {
                                step = 'start';
                                sampling = false;
                                data = data.map(x => {
                                    const val = x[1] === 0 ? 'CLOSED' : 'OPEN';
                                    return [x[0], val];
                                });
                                break;
                            }
                            case ('rssi'): {
                                break;
                            }
                        }
                        series['name'] = sensor.sensorName;
                        series['sampling'] = sampling;
                        data = data.map(x => {
                            const datetime = new Date(x[0]);
                            datetime.setMilliseconds(0);
                            return [datetime, x[1]];
                        });
                        data.sort((a, b) => {
                            return a[0].getTime() - b[0].getTime();
                        });
                        const nullData = [];
                        data.forEach((current, i) => {
                            if (i === data.length - 1) {
                                return;
                            }
                            const next = data[i + 1];
                            if ((next[0].getTime() - current[0].getTime()) / 1000 > 300) {
                                nullData.push([new Date(current[0].getTime() + 1), null]);
                            }
                        });
                        if (nullData.length) {
                            data = data.concat(nullData);
                            data.sort((a, b) => {
                                return a[0].getTime() - b[0].getTime();
                            });
                        }
                        series.data = data;
                        this._series[sensor.sensorId] = series;
                        this.chartOption.series.push(series);
                        this.chartOption.legend.data.push(series.name);
                        resolve(null);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
        });
    }

    onDateChanged(type, event): void {
        if (event.value) {
            if (type === 'start') {
                this.startDate = event.value.toDate();
            } else {
                this.endDate = event.value.toDate();
                this.endDate.setHours(23, 59, 59, 0);
            }
            if (!this.datePickerOpen) {
                console.log(event.value);
            }
        }
    }

    onDatePickerClosed(event): void {
        this.datePickerOpen = false;
        const promises = this.getDateRanges(this.startDate, this.endDate);
        Promise.all(promises).then(() => {
            this.loaded = true;
            // this.eChartData.hideLoading();
            setTimeout(() => {
                this.updateChart();
            }, 1000);
        });
        console.log(this.startDate);
        console.log(this.endDate);
    }

    getYAxisOption(type, idx): any {
        let position = idx % 2 === 0 ? 'left' : 'right';
        const offset = idx > 1 ? Math.floor(idx / 2) * 80 : 0;
        if (idx > 1) {
            position = idx % 2 === 0 ? 'right' : 'left';
        }
        const option = {
            type: 'value',
            name: '',
            // boundaryGap: ['20%', '20%'],
            // minInterval: 1,
            axisLine: {
                show: true,
            },
            axisLabel: {
                width: 50,
                overflow: 'truncate'
            },
            axisTick: {
                alignWithLabel: true
            },
            axisPointer: {
                label: {
                    precision: 1
                }
            },
            alignTicks: true,
            offset: offset,
            position: position,
        };

        if (position === 'right'){
            this.chartOption.grid.right += 60;
        }
        else if (idx > 0 && position === 'left'){
            this.chartOption.grid.left += 70;
        }

        option.name = this._translateService.translate('CHARTS.AXIS_LABELS.' + type);
        if (SbSensorUtils.isMinMaxSensorType(type)){
            const unit = SbSensorUtils.getSensorTypeDefaultUnit(type);
            option.name += ' (' + unit + ')';
            option.axisLabel['formatter'] = (value): string => {
                return value.toFixed(1) + unit;
            };
        } else {
            option.type = 'category';
            switch (type) {
                case (SensorType.Relay): {
                    option['data'] = ['OFF', 'ON'];
                    break;
                }
                case (SensorType.Switch): {
                    option['data'] = ['CLOSED', 'OPEN'];
                    break;
                }
            }
        }

        return option;
    }

    setupDatazoom(): void {
        // const yDataZoomTypes = new Set(['humid', 'temp', 'rssi']);
        // const intersection = new Set( this.sensorTypes.filter((x: string) => yDataZoomTypes.has(x)));
        const yAxisInds = [];
        for (let i = 0; i < this.sensorTypes.length; i++){
            yAxisInds.push(i);
        }
        this.chartOption.dataZoom = [
            {
                type: 'inside',
                start: 80,
                end: 100,
                xAxisIndex: [0],
                filterMode: 'none'
            },
            {
                type: 'slider',
                start: 80,
                end: 100,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                    color: '#fff',
                },
                xAxisIndex: [0],
                filterMode: 'none'
            },
            {
                type: 'inside',
                start: 0,
                end: 100,
                yAxisIndex: yAxisInds,
                filterMode: 'none'
            },
            {
                type: 'slider',
                start: 0,
                end: 100,
                width: 20,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                    color: '#fff',
                },
                yAxisIndex: yAxisInds,
                filterMode: 'none'
            }
        ];
    }

    onExport(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'downsampling-selection-dialog';

        const dialogRef = this._matDialog.open(DownsamplingSelectionDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
                    data: {
                        state: 'loading',
                    }
                });
                const days = result['days'];
                const hours = result['hours'];
                const minutes = result['minutes'];
                const selectAll = result['allData'];
                const millis = this.toMillis(days, hours, minutes);
                const promises: Promise<any>[] = [];
                this.sensors.forEach(sensor => {
                    promises.push(new Promise<any>((resolve, reject) => {
                        this._sensorsService.getSensorData(sensor.sensorId, this.startDate, this.endDate).subscribe(
                            results => {
                                resolve([sensor, results]);
                            },
                            reject);
                    }));
                });
                const headers = [this._translateService.translate('GENERIC.Datetime'),
                    this._translateService.translate('GENERIC.SENSOR.Name'), this._translateService.translate('GENERIC.Type'), this._translateService.translate('GENERIC.Value')];
                Promise.all(promises).then(responses => {
                    let data = [];
                    responses.forEach(response => {
                        const sensor = response[0];
                        let sensorData = response[1];
                        if (!selectAll && sensorData.length > 0 && millis !== 0) {
                            let startTime = sensorData[0][0];
                            const datatmp = [sensorData[0]];
                            sensorData.forEach(value => {
                                if (value[0] >= startTime + millis && value[1] != null) {
                                    datatmp.push(value);
                                    startTime = value[0];
                                }
                            });
                            sensorData = datatmp;
                        }
                        const type = this._translateService.translate('GENERIC.SENSOR.VALUE_TYPES.' + sensor.type);
                        const name = sensor.sensorName;
                        switch (sensor.type) {
                            case 'door':
                                sensorData = sensorData.map(x => {
                                    const val = x[1] ? 'GENERIC.SENSOR.STATE.DOOR.OPEN' : 'GENERIC.SENSOR.STATE.DOOR.CLOSED';
                                    const translatedVal = this._translateService.translate(val);
                                    return [x[0], name, type, translatedVal];
                                });
                                break;
                            case 'relay':
                                sensorData = sensorData.map(x => {
                                    const val = x[1] ? 'GENERIC.SENSOR.STATE.RELAY.CLOSED' : 'GENERIC.SENSOR.STATE.RELAY.OPEN';
                                    const translatedVal = this._translateService.translate(val);
                                    return [x[0], name, type, translatedVal];
                                });
                                break;
                            default:
                                sensorData = sensorData.map(x => {
                                    return [x[0], name, type, x[1]];
                                });
                                break;
                        }
                        data = data.concat(sensorData);
                    });
                    data.sort((a, b) => a[0] - b[0]);
                    data = data.map(x => [new Date(x[0]).toLocaleString().replace(',', ''), x[1], x[2], x[3]]);
                    data.unshift(headers);
                    const wb = this._excelExportService.converToXlsx(data);
                    this._excelExportService.downloadXlsx(wb, 'Data');
                    httpProgressDialog.componentInstance.state = 'success';
                },
                error => {
                    httpProgressDialog.componentInstance.state = 'error';
                });
            }
        });
    }

    toMillis(days, hours, minutes): number {
        return (days * 24 * 60 + hours * 60 + minutes) * 60 * 1000;
    }

    downloadImage(): void {
        const data = this.eChartData.getDataURL();
        this.downloadURI(data, this.chartOption.title.text+'.png');

        this.eChartData.dispatchAction({
            type: 'saveAsImage',
        });
    }

    downloadURI(uri, name): void {
        const link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    onPrint(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'export-selection-dialog';
        dialogConfig.data = {
            title: 'SENSOR_CHART.EXPORT_SELECTOR.TITLE.PRINT'
        };
        const dialogRef = this._matDialog.open(ExportSelectionDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(resp => {
            if (resp) {
                const printType = resp.type;
                if (printType === 'image') {
                    this.printImage();
                } else {
                    const dialogConfig2 = new MatDialogConfig();
                    dialogConfig2.disableClose = false;
                    dialogConfig2.autoFocus = false;
                    // dialogConfig.minHeight = '50%';
                    // dialogConfig.minWidth = '80%';
                    dialogConfig2.panelClass = 'downsampling-selection-dialog';

                    const dialogRef2 = this._matDialog.open(DownsamplingSelectionDialog, dialogConfig2);

                    dialogRef2.afterClosed().subscribe(result => {
                        if (result) {
                            const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
                                data: {
                                    state: 'loading',
                                }
                            });
                            const days = result['days'];
                            const hours = result['hours'];
                            const minutes = result['minutes'];
                            const selectAll = result['allData'];
                            const millis = this.toMillis(days, hours, minutes);
                            const promises: Promise<any>[] = [];
                            this.sensors.forEach(sensor => {
                                promises.push(new Promise<any>((resolve, reject) => {
                                    this._sensorsService.getSensorData(sensor.sensorId, this.startDate, this.endDate)
                                        .subscribe(
                                        results => {
                                            resolve([sensor, results]);
                                        },
                                        reject);
                                }));
                            });
                            const headers = [
                                this._translateService.translate('GENERIC.Datetime'),
                                this._translateService.translate('GENERIC.SENSOR.Name'),
                                this._translateService.translate('GENERIC.Type'),
                                this._translateService.translate('GENERIC.Value')
                            ];
                            Promise.all(promises).then(responses => {
                                let data = [];
                                responses.forEach(response => {
                                    const sensor = response[0];
                                    let sensorData = response[1];
                                    if (!selectAll && sensorData.length > 0 && millis !== 0) {
                                        let startTime = sensorData[0][0];
                                        const datatmp = [sensorData[0]];
                                        sensorData.forEach(value => {
                                            if (value[0] >= startTime + millis && value[1] != null) {
                                                datatmp.push(value);
                                                startTime = value[0];
                                            }
                                        });
                                        sensorData = datatmp;
                                    }
                                    const type = this._translateService.translate('GENERIC.SENSOR.VALUE_TYPES.' + sensor.type);
                                    const name = sensor.sensorName;
                                    switch (sensor.type) {
                                        case 'door':
                                            sensorData = sensorData.map(x => {
                                                const val = x[1] ? 'GENERIC.SENSOR.STATE.DOOR.OPEN' : 'GENERIC.SENSOR.STATE.DOOR.CLOSED';
                                                const translatedVal = this._translateService.translate(val);
                                                return [x[0], name, type, translatedVal];
                                            });
                                            break;
                                        case 'relay':
                                            sensorData = sensorData.map(x => {
                                                const val = x[1] ? 'GENERIC.SENSOR.STATE.RELAY.CLOSED' : 'GENERIC.SENSOR.STATE.RELAY.OPEN';
                                                const translatedVal = this._translateService.translate(val);
                                                return [x[0], name, type, translatedVal];
                                            });
                                            break;
                                        default:
                                            sensorData = sensorData.map(x => {
                                                return [x[0], name, type, x[1]];
                                            });
                                            break;
                                    }
                                    data = data.concat(sensorData);
                                });
                                data.sort((a, b) => a[0] - b[0]);
                                data = data.map(x => [new Date(x[0]).toLocaleString().replace(',', ''), x[1], x[2], x[3]]);
                                data.unshift(headers);
                                const wb = this._excelExportService.converToXlsx(data);
                                const ws = wb.Sheets['Sheet1'];
                                this._excelExportService.printSheet(ws);
                                httpProgressDialog.componentInstance.state = 'success';
                            },
                            error => {
                                httpProgressDialog.componentInstance.state = 'error';
                            });
                        }
                    //         const millis = this.toMillis(result['days'], result['hours'], result['minutes']);
                    //         if (result['allData'] || !this.data.length || millis === 0) {
                    //             this.printExcel(this.data);
                    //         } else {
                    //             let startTime = this.data[0][0];
                    //             const data = [this.data[0]];
                    //             this.data.forEach(value => {
                    //                 if (value[0].getTime() >= startTime.getTime() + millis && value[1] != null) {
                    //                     data.push(value);
                    //                     startTime = value[0];
                    //                 }
                    //             });
                    //             this.printExcel(data);
                    //         }
                    //     }
                    });
                }
            }
        });

    }

    printImage(): void {
        const img = this.eChartData.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff',
            excludeComponents: ['toolbox', 'dataZoom']
        });
        let windowContent = '<!DOCTYPE html>';
        windowContent += '<html>' +
            '<head>' +
            '<meta charset="utf-8">' +
            '<meta http-equiv="X-UA-Compatible" content="IE=edge">' +
            '<meta name="viewport" content="width=device-width, initial-scale=1">' +
            '<meta name="description" content="">' +
            '<meta name="author" content="">' +
            '<title>' + ' πηγή: Sensorbase.io</title></head>' +
            '<body onload="window.print()">' +
            '<img style="width: 100%" src="' + img + '">' +
            '</body>' +
            '</html>';
        const printWin = window.open('', '', 'width=1500,height=700');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
    }


}
