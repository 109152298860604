<div class="flex flex-col justify-center items-center">
    <ui-switch [checked]="sensor.displayData" (valueChange)="updateControl($event)" size="large"
               checkedLabel="ON " uncheckedLabel="OFF" [disabled]="is_pending"
               defaultBgColor="#c5c6cb7">
        <mat-spinner *ngIf="size === 'xs' && is_pending" [diameter]="20"></mat-spinner>
        <div class="flex flex-col justify-center items-center" *ngIf="activeTimer" [matTooltip]="getTimerTooltip(activeTimer)">
            <mat-icon *ngIf="(!is_pending && size === 'xs') || size!=='xs'" svgIcon="timer">
            </mat-icon>
            <mat-progress-spinner class="timer-shortcut-progress"
                                  [mode]="!activeTimer.completed?'determinate':'indeterminate'"
                                  [value]="activeTimer.completionPercentage" diameter="36">
            </mat-progress-spinner>
        </div>
    </ui-switch>

    <div *ngIf="size !== 'xs'">
        <ng-container *ngIf="is_pending; then Pending else Complete"></ng-container>
    </div>
    <ng-template #Pending>
        <div class="sensor-info h5 text-secondary status">
            <mat-spinner [diameter]="size === 'xs' ? 15 : 20"></mat-spinner>
        </div>
    </ng-template>
    <ng-template #Complete>
        <div class="h5 text-secondary status pt-2.5 max-w-full text-center" [ngClass]="{'text-sm': size === 'xs'}">{{getRelayDisplayInfo()}}</div>
    </ng-template>
</div>
