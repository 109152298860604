<form [formGroup]="selectionItemsForm" fxFlex fxLayout="column" autocomplete="off" style="margin: 30px">
    <mat-form-field>
        <mat-select placeholder="Επέλεξε" formControlName="userType" multiple>
            <mat-select-trigger>
                <div class="example-additional-selection">
                    Επιλέχθηκαν
                    {{selectionItemsForm.value.userType[selectionItemsForm.value.userType.length-1] == 0 ? selectionItemsForm.value.userType.length-1 : selectionItemsForm.value.userType.length}}
                </div>
            </mat-select-trigger>
            <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
            <mat-option *ngFor="let filters of selectionItems" [value]="filters.key"
                        (click)="togglePerOne()">
                {{filters.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>