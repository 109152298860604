<div class="dialog-content-wrapper">
    <!-- DIALOG TITLE -->
    <mat-toolbar class="m-0" [ngClass]="toolbarClass">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" [transloco]="title" [translocoParams]="translateParams">
            </span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="message-container" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="warn" svgIcon="warning"></mat-icon>
        <div class="message" [innerHTML]="message | transloco:translateParams"></div>
    </div>

    <div class="btn-group" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button [ngClass]="confirmBtnClass" (click)="dialogRef.close(true)" aria-label="Confirm">
            {{confirmText | transloco:translateParams}}
        </button>
        <button mat-button [ngClass]="cancelBtnClass" (click)="dialogRef.close()" aria-label="Close dialog">
            {{cancelText | transloco:translateParams}}
        </button>
    </div>

</div>
