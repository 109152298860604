import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';

import {NgxGaugeModule} from 'ngx-gauge';
import {UiSwitchModule} from 'ngx-ui-switch';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxEchartsModule} from 'ngx-echarts';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {FuseAlertModule} from '@fuse/components/alert';
import {FuseCardModule} from '@fuse/components/card';
import {FuseConfirmationModule} from '@fuse/services/confirmation';


// Pipes
import {
    FavouriteSensors,
    TagSelection,
    FilterByPropertyNeqPipe,
    FilterFormArrayByPropertyNeqPipe,
    SbUpperCasePipe
} from './pipes';

// Components
import {
    // Dialogs
    SensorbaseConfirmDialog,
    SensorbaseSmsDialog,
    DashboardsNewDashboardDialog,
    HttpProgressDialog,
    SensorBoardsDialog,
    ExportSelectionDialog,
    SensorSettingsListDialog,
    SensorChartDialog,
    SensorInformationDialog,
    RelaySchedulesDialogComponent,
    SensorAutomationsSettingDialog,
    SensorGeneralSettingsDialog,
    SensorNotificationSettingsDialog,
    SensorTimerSettingsDialog,
    DownsamplingSelectionDialog,
    IconSelectionDialogComponent,
    ContactLinksListMiniDialogComponent,
    MultiSelectionDialog,
    DeviceSettingsDialog,
    ContactVerificationDialog,

    // Core
    SensorsContainerComponent,
    WidgetsContainerComponent,
    SensorWidgetComponent,
    ToastComponent,
    DeviceManagerDeviceListComponent,
    DeviceDetailsSidebarComponent,
    DeviceLocationMapComponent,
    MapComponent,
    SensorChartComponent,
    MultiSensorChartComponent,
    IncrementInputComponent,
    ContactLinksListComponent,
    InlineEditableInputComponent,
    MonthPickerComponent,
    WeekDayPickerComponent,
    MultiSelectionListComponent,
    ExportDataComponent,

    // Displays
    TemperatureDisplayComponent,
    HumidityDisplayComponent,
    RssiDisplayComponent,
    DoorDisplayComponent,
    RelayDisplayComponent,
    CurrentDisplayComponent,
    VoltageDisplayComponent,
    PowerDisplayComponent,
    ButtonDisplayComponent

} from './components';
import {CdkDetailRowDirective, LongPressDirective} from './directives';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
    declarations: [

        // Components
        SensorsContainerComponent,
        WidgetsContainerComponent,
        SensorWidgetComponent,
        ToastComponent,
        DeviceManagerDeviceListComponent,
        DeviceDetailsSidebarComponent,
        SensorChartComponent,
        MultiSensorChartComponent,
        DeviceLocationMapComponent,
        MapComponent,
        IncrementInputComponent,
        ContactLinksListComponent,
        InlineEditableInputComponent,
        MonthPickerComponent,
        WeekDayPickerComponent,
        MultiSelectionListComponent,
        ExportDataComponent,

        // Dialogs
        SensorbaseConfirmDialog,
        SensorbaseSmsDialog,
        DashboardsNewDashboardDialog,
        HttpProgressDialog,
        SensorBoardsDialog,
        ExportSelectionDialog,
        SensorSettingsListDialog,
        SensorChartDialog,
        SensorInformationDialog,
        RelaySchedulesDialogComponent,
        SensorAutomationsSettingDialog,
        SensorGeneralSettingsDialog,
        SensorNotificationSettingsDialog,
        SensorTimerSettingsDialog,
        DownsamplingSelectionDialog,
        IconSelectionDialogComponent,
        ContactLinksListMiniDialogComponent,
        MultiSelectionDialog,
        DeviceSettingsDialog,
        ContactVerificationDialog,

        // Displays
        TemperatureDisplayComponent,
        HumidityDisplayComponent,
        RssiDisplayComponent,
        DoorDisplayComponent,
        RelayDisplayComponent,
        CurrentDisplayComponent,
        VoltageDisplayComponent,
        PowerDisplayComponent,
        ButtonDisplayComponent,

        // Pipes
        FilterByPropertyNeqPipe,
        FilterFormArrayByPropertyNeqPipe,
        SbUpperCasePipe,
        FavouriteSensors,
        TagSelection,

        //Directives
        CdkDetailRowDirective,
        LongPressDirective,
    ],
    imports: [
        CommonModule,

        MatListModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatBadgeModule,
        MatDialogModule,
        MatFormFieldModule,
        MatRippleModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSelectModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatRadioModule,
        MatCardModule,
        MatGridListModule,
        FlexLayoutModule,

        TranslocoModule,
        UiSwitchModule,
        NgxGaugeModule,
        LeafletModule,
        NgxMaterialTimepickerModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'),
        }),

        FuseAlertModule,
        FuseCardModule,
        FuseConfirmationModule,
    ],
    exports: [

        MatIconModule,

        TranslocoModule,

        // Components
        SensorsContainerComponent,
        WidgetsContainerComponent,
        SensorWidgetComponent,
        ToastComponent,
        SensorChartComponent,
        MultiSensorChartComponent,
        ExportDataComponent,
        DeviceManagerDeviceListComponent,
        DeviceDetailsSidebarComponent,

        // Pipes
        FilterByPropertyNeqPipe,
        FilterFormArrayByPropertyNeqPipe,
        SbUpperCasePipe,
        FavouriteSensors,
        TagSelection,
    ],
    providers: [
        FavouriteSensors,
    ]
})
export class SensorbaseModule
{
}
