import {Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'month-picker',
    templateUrl: './month-picker.component.html',
    styleUrls: ['month-picker.component.scss']
})

export class MonthPickerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() monthOfyear: number[] = [];
    @Output() updated = new EventEmitter<boolean>();

    ngDisabled = false;
    displayStartIndex = 0;
    arrayStartIndex = 1;
    months = [{
        name: 'MONTH_PICKER.JAN',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.FEB',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.MAR',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.APR',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.MAY',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.JUN',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.JUL',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.AUG',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.SEP',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.OCT',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.NOV',
        isSelected: false
    }, {
        name: 'MONTH_PICKER.DEC',
        isSelected: false
    }
    ];
    allSelected = false;
    numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    constructor(
    ) {
    }

    ngOnInit(): void {
        this.monthOfyear.forEach((month) => {
            this.months[(month - this.arrayStartIndex) % 12].isSelected = true;
        });
        this.areAllSelected();
    }

    ngOnDestroy(): void {

    }

    ngOnChanges(): void {
        this.ngOnInit();
    }

    onMonthClicked(month): void {
        const tmpMonth = this.months[month];
        if (!tmpMonth.isSelected) {
            this.selectMonth(month);
        } else {
            this.unselectMonth(month);
        }
        this.areAllSelected();
        this.updated.emit(true);
    }

    selectMonth(month): void {
        if (this.monthOfyear.indexOf(month + this.arrayStartIndex) < 0) {
            this.monthOfyear.push(month + this.arrayStartIndex);
            this.months[month].isSelected = true;
        }
    }

    unselectMonth(month): void {
        if (this.monthOfyear.indexOf(month + this.arrayStartIndex) >= 0) {
            this.monthOfyear.splice(this.monthOfyear.indexOf(month + this.arrayStartIndex), 1);
            this.months[month].isSelected = false;
        }
    }

    toggleAll(event): void {
        for (const i of this.numbers) {
            if (event.checked) {
                this.selectMonth(i);
            } else {
                this.unselectMonth(i);
            }
        }
        this.updated.emit(true);
    }

    areAllSelected(): void {
        this.allSelected = (this.monthOfyear.length === 12);
    }
}
