import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    OnDestroy,
    ViewEncapsulation,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {IMqttMessage} from 'ngx-mqtt';

import {fuseAnimations} from '@fuse/animations';

// import {SensorBoardsDialog, SensorSettingsListDialog, SensorChartDialog, SensorInformationDialog} from './dialogs';

import {SensorInformationDialog, SensorBoardsDialog, SensorChartDialog, SensorSettingsListDialog} from './dialogs';
import {SensorsService, DevicesService, SensorTimersService, Timer} from '@sensorbase/services';
import {Sensor, Device} from '@sensorbase/models';
import {formatDate, DatePipe} from '@angular/common';
import {TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationConfig, FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';

@Component({
    selector: 'sensor-widget',
    templateUrl: './sensor-widget.component.html',
    styleUrls: ['./sensor-widget.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SensorWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() sensor: Sensor;
    @Input() deletable = false;

    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        if (val === null) {
            this._size = 'sm';
            return;
        }
        this._size = val;
    }


    @ViewChild('cchart', {static: false}) cchart;
    @Output() ready: EventEmitter<any> = new EventEmitter();
    @Output() favouriteToggled: EventEmitter<boolean> = new EventEmitter();
    @Output() deleted: EventEmitter<any> = new EventEmitter();
    @Output() resized: EventEmitter<any> = new EventEmitter();

    // Timers
    timers: Timer[] = [];
    timersLoaded = false;
    timersOpened = false;
    activeTimer: Timer = null;
    hasWarning = false;
    warning: any = null;
    selected: any;
    devices: Device[] = [];

    humidThresholdConfig = {
        '0': {color: 'blue'},
        '20': {color: 'lightskyblue'},
        '40': {color: 'green'},
        '60': {color: 'orange'},
        '80': {color: 'red'}
    };

    // Chart options end

    // Private
    private _size = 'sm';
    private _unsubscribeAll: Subject<any>;
    private _viewLoaded = false;
    private _disconnect: string;

    constructor(
        private _datePipe: DatePipe,
        public _matDialog: MatDialog,
        private _sensorsService: SensorsService,
        private _devicesService: DevicesService,
        private _timersService: SensorTimersService,
        private _translateService: TranslocoService,
        private _fuseConfirmationService: FuseConfirmationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._devicesService.devices$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(devices => {
                this.devices = devices;
            });

        this._disconnect = (this.sensor.status > 0 ? 0 : 1).toString();

        this._sensorsService.onSensorUpdated.subscribe(sensor => {
            if (this.sensor.sensorId === sensor.sensorId) {
                this.sensor = sensor;
            }
        });
        this._sensorsService.onMqttConnect.subscribe(() => {
            this._sensorsService.subSensorDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.disconnectCallback(message);
                });
            this._sensorsService.subDeviceDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.disconnectCallback(message);
                });
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {});
        });

        if (this.sensor.type === 'relay') {
            this._timersService.getSensorTimers(this.sensor);
            this._timersService.onTimersChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(timers => {
                    this.activeTimer = null;
                    if (this.sensor.sensorId in timers) {
                        this.timers = timers[this.sensor.sensorId].sort((one, two) => (one.id > two.id ? -1 : 1));
                        this.timersLoaded = true;
                        this.timers.forEach(timer => {
                            if (timer.active) {
                                if (!this.activeTimer) {
                                    this.activeTimer = timer;
                                } else if (this.activeTimer.secondsRemaining > timer.secondsRemaining) {
                                    this.activeTimer = timer;
                                }
                            }
                        });
                    }
                });
        }

        this.ready.emit();
    }

    getTimerTooltip(timer: Timer): string {
        let text = '';
        if (timer.value) {
            text += 'ON: ';
        } else {
            text += 'OFF: ';
        }
        text += timer.timeRemaining;
        return text;
    }

    disconnectCallback(msg: IMqttMessage): void {
        if (this._disconnect != null && this._disconnect !== msg.payload.toString()) {
            console.log('Disconnect mismatch! this: ' + this._disconnect + ' | Received: ' + msg.payload.toString());
            setTimeout(() => {
                this._sensorsService.getSensor(this.sensor.sensorId).subscribe((sensor) => {
                    if (sensor) {
                        this.sensor = sensor;
                    }
                });
            }, 2000);
        }
        this._disconnect = msg.payload.toString();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        // clearInterval(this.intervalHandle);
    }

    ngAfterViewInit(): void {
        this._viewLoaded = true;
    }

    toogleNotifications(): void {
        this.sensor.notifications = !this.sensor.notifications;
        this._sensorsService.updateSensor(this.sensor);
    }

    updateControl(state): void {
        try {

            if (this.sensor.data['current'] !== +state) {
                // console.log("Update control " + this.sensor.sensorId + " " + state);
                // console.log("target " + this.sensor.data["target"]);
                this.sensor.data['target'] = +state;
                this.sensor.data['status'] = 'PENDING';
                this._sensorsService.setRelaySate(this.sensor.sensorId, +state);
            }
        } catch (ex) {
            // console.log("Error updating control: " + ex);
        }
    }

    onResize(): void {
        if (this.size === 'sm') {
            this.size = 'medium';
        } else {
            this.size = 'sm';
        }
        this.resized.next(null);
    }

    openSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'sensor-settings-list-dialog';
        dialogConfig.restoreFocus = false;

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((device) => {
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorSettingsListDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openBoards(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.maxWidth = '100%';
        // dialogConfig.minWidth = '70%';
        dialogConfig.data = this.sensor;
        dialogConfig.panelClass = 'sensor-board-dialog';
        dialogConfig.restoreFocus = false;


        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId).subscribe(
            device => {
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorBoardsDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openChart(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'sensor-chart-dialog';
        dialogConfig.data = this.sensor.sensorId;
        dialogConfig.restoreFocus = false;

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe(device => {
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorChartDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openInformation(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.maxWidth = '100%';
        // dialogConfig.minWidth = '70%';
        dialogConfig.data = this.sensor;
        dialogConfig.panelClass = 'sensor-information-dialog';

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((device) => {
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorInformationDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    onDeleteButtonClicked(): void {

        const dialogConfig: FuseConfirmationConfig = {
            title: this._translateService.translate('SENSOR_WIDGET.DELETE_SENSOR_DIALOG.TITLE'),
            message: this._translateService.translate('SENSOR_WIDGET.DELETE_SENSOR_DIALOG.MESSAGE', this.sensor),
            icon: {
                name: 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CONFIRM'),
                    color: 'warn'
                },
                cancel: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CANCEL')
                }
            }
        };

        const dialogRef = this._fuseConfirmationService.open(dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirmed') {
                this._sensorsService.deleteSensor(this.sensor.sensorId)
                    .subscribe(response => {
                        this.deleted.next('server');
                    });
            }
        });
    }

    toggleTimer(timer): void {
        const newState = !timer.active;
        const promises: Promise<any>[] = [];
        const index = this.timers.findIndex(t => t.id === timer.id);
        this.timers[index].runAt = new Date();
        if (newState === true) {
            // this.timers.filter(t => t.active == true && t.id != timer.id).forEach((timer) => {
            // 	promises.push(this._timersService.stopTimer(this.sensor, timer).toPromise());
            // });
            promises.push(this._timersService.startTimer(this.sensor, timer).toPromise());

        } else {
            promises.push(this._timersService.stopTimer(this.sensor, timer).toPromise());
        }
        Promise.all(promises)
            .then(() => {
                this._timersService.getSensorTimers(this.sensor);
            });
    }

    toggleTimersDrawer(): void {
        this.timersOpened = !this.timersOpened;
    }

    secondsToHms(d): string {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
        const sDisplay = s >= 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
        return hDisplay + mDisplay + sDisplay;
    }

    hasActiveTimers(): number {
        let active = 0;
        this.timers.forEach(timer => {
            if (timer.active) {
                active += 1;
            }
        });
        return active;
    }

    onWarning(event): void {
        this.hasWarning = !!event;
        this.warning = event;
    }

    getDeviceNameFromId(deviceId): string {
        const device = this.devices.find(x => x.deviceId === deviceId);
        return device.deviceName;
    }
}
