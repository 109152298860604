export interface IAuthResponseUser {
    id: string;
    email: string;
}

export interface IAuthResponse {
    success: boolean;
    mqttToken?: string;
    token?: string;
    user?: IAuthResponseUser;
    timestamp?: number;
}

export interface IAuthUser {
    email: string;
    password: string;
}

export interface IRegisterUser {
    email: string;
    name: string;
    password: string;
}
