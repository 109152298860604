import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {trigger, animate, style, transition, state} from '@angular/animations';

import {AlertService} from '@sensorbase/services';
import {SbUtils} from '@sensorbase/utils';
import {FuseAlertService} from '@fuse/components/alert';
import {Alert} from '../../../models/alert.model';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('true', style({
                opacity: 1
            })),
            state('false', style({
                opacity: 0
            })),
            transition('false <=> true', [
                animate('0.3s')
            ]),
            transition('* => false', [
                animate('0.5s')
            ]),
            transition('* => true', [
                animate('0.3s')
            ]),
        ])
    ]
})

export class ToastComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    alerts: Alert[] = [];

    constructor(
        private _alertService: AlertService,
        private _fuseAlertService: FuseAlertService
    ) {
    }

    ngOnInit(): void {
        this.subscription = this._alertService.alert$.subscribe(alert => {
            if (alert) {
                console.log('New Message', alert);
                alert.guid = SbUtils.generateGUID();
                this.alerts.push(alert);
                setTimeout(() => {
                    this.removeToast(alert);
                }, alert.delay);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    removeToast(message): void {
        console.log('Removing toast');
        this._fuseAlertService.dismiss(message.guid);
    }

    onDismiss(dismissed, message): void{
        const i = this.alerts.indexOf(message);
        if (dismissed){
            setTimeout(() => {
                this.alerts.splice(i, 1);
            }, 1000);
        }
    }
}
