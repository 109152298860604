import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'multi-selection-list',
    templateUrl: './multi-selection-list.component.html',
    styleUrls: ['./multi-selection-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiSelectionListComponent implements OnInit {

    @Input() selectionItems: any;

    selectionItemsForm: FormGroup;

    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        // console.log(this.selectionItems);
        this.selectionItemsForm = this.fb.group({
            userType: new FormControl('')
        });
    }

    togglePerOne(): void {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
        }
        if (this.selectionItemsForm.controls.userType.value.length === this.selectionItems.length){
            this.allSelected.select();
        }
    }

    toggleAllSelection(): void {
        if (this.allSelected.selected) {
            this.selectionItemsForm.controls.userType
                .patchValue([...this.selectionItems.map(item => item.key), 0]);
        } else {
            this.selectionItemsForm.controls.userType.patchValue([]);
        }
    }

    // getSelectedItems() {
    //     return
    // }
}
