import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIV2_BASE_URL} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LatestFwVersionService {

    constructor(
        private _httpClient: HttpClient,
    ) {
    }

    getLatestFwVersionMulti(models: string[] = []): Promise<any> {
        return new Promise((resolve, reject) => {
            const options = models.length ? {'params': {'models': models}} : {};
            this._httpClient.get(APIV2_BASE_URL + 'latestFwVersion', options)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
