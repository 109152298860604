<div class="flex flex-row justify-center items-center w-10 h-10"
     [ngClass]="mqttConnected ? 'cursor-auto' : 'cursor-pointer'"
     (click)="connect()">
    <span class="flex h-3 w-3 relative">
        <ng-container *ngIf="!mqttConnected">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400"></span>
        </ng-container>
      <span class="relative inline-flex rounded-full h-3 w-3" [ngClass]="mqttConnected ? 'bg-green-500' : 'bg-red-500'"
            [matTooltip]="mqttStatusText" [matTooltipClass]="mqttConnected ? 'bg-green-500' : 'bg-red-500'"></span>
    </span>
</div>
