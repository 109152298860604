import {BaseModel} from './base.model';
import {SbUtils as utils} from '../utils';

export interface IUser {
    userId: string;
    name: string;
    email: string;
    company: string;
    occupation: string;
    city: string;
    street: string;
    zip: string;
    vatNum: string;
    doy: string;
    languageId: string;
    accountType: number;
    country: string;
    headerLanguage: string;
    headerLocale: string;
    webappSettings: object;
}

export class User extends BaseModel implements IUser {
    public userId = '';
    public name = '';
    public email = '';
    public company = '';
    public occupation = '';
    public city = '';
    public street = '';
    public zip = '';
    public vatNum = '';
    public doy = '';
    public languageId = 'el-GR';
    public accountType = 0;
    public country = 'Ελλάδα';
    public headerLanguage: 'el-GR';
    public headerLocale: 'el-GR';
    public webappSettings: object;

    constructor(data = null) {
        super();
        if (data != null) {
            this.userId = data.userId;
            this.name = data.name;
            this.email = data.email;
            this.company = data.company;
            this.occupation = data.occupation;
            this.city = data.city;
            this.street = data.street;
            this.zip = data.zip;
            this.vatNum = data.vatNum;
            this.doy = data.doy;
            this.languageId = data.languageId;
            this.accountType = data.accountType;
            this.headerLanguage = data.headerLanguage;
            this.headerLocale = data.headerLocale;
            this.webappSettings = data.webappSettings;
        }
    }

    static fromApi(dataApi): any {
        const data = dataApi;
        // Need to parse webappSettings to an object, since .NET sends a string
        data['webappSettings'] = JSON.parse(data['webappSettings']);
        // @ts-ignore
        return new this(data);
    }
}
