import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'export-selection-dialog',
    templateUrl: './export-selection-dialog.component.html',
    styleUrls: ['./export-selection-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExportSelectionDialog implements OnInit {

    title = '';

    constructor(
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<ExportSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.title = data.title;
    }

    ngOnInit(): void {
    }

    select(type: string): void {
        this.dialogRef.close({
            type: type
        });
    }
}
