import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';

import {Sensor} from '@sensorbase/models';
import {SensorsService} from '@sensorbase/services';

@Component({
    selector: 'sb-door-display',
    templateUrl: './door-display.component.html'
})
export class DoorDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;

    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        this._size = val;
    }

    icon = 'fa_duotone:signal-slash';

    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    constructor(private _sensorsService: SensorsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.processDoorIcon();
        this._sensorsService.onSensorUpdated.subscribe(sensor => {
            if (sensor.sensorId === this.sensor.sensorId){
                this.sensor = sensor;
                this.processDoorIcon();
            }
        });
        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.processDoorIcon();
                });

            this._sensorsService.subSensorDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    // console.log("Data Received: "+this.sensor.sensorId , message.payload.toString());
                    if (message.payload.toString() === '1'){
                        this.sensor.status = 0;
                    }
                    this.processDoorIcon();
                });
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    processDoorIcon(): void {
        const openIcon = this.sensor.options['openIcon'] || 'fa_duotone:door-open';
        const closedIcon = this.sensor.options['closedIcon'] || 'fa_duotone:door-closed';
        this.icon = this.sensor.data['current'] === 1 ? openIcon : closedIcon;
    }
}
