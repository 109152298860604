import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService} from '../auth';
import {ContactLink, ContactLinkDetail} from '../../models';
import { API_BASE_URL } from 'environments/environment';
import {SbNotificationUtils} from '@sensorbase/utils';


@Injectable({
    providedIn: 'root',
})
export class ContactLinksService {
    onContactLinksChanged: BehaviorSubject<ContactLink[]>;
    contactLinks: ContactLink[] = [];

    /**
     * Constructor
     *
     * @param _httpClient
     * @param _authService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    ) {
        // Set the defaults
        this.onContactLinksChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contactLinks
     *
     * @returns
     */
    getContactLinks(sensorId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this._authService.loggedIn()) {
                const userID = this._authService.getUserId();
                this._httpClient.get(API_BASE_URL + 'users/' + userID + '/sensors/' + sensorId + '/contacts')
                    .subscribe((response: any) => {
                        // console.log(response);
                        this.contactLinks = [];
                        response.forEach(contactLink => {
                            contactLink.objectId = sensorId;
                            this.contactLinks.push(ContactLink.fromApi(contactLink));
                        });
                        console.log(this.contactLinks);
                        this.onContactLinksChanged.next(this.contactLinks);
                        resolve(this.contactLinks);
                    });
            }
            else {
                reject('Not Authenticated!');
            }
        });
    }

    addContactLink(sensorId: string, contactLink: ContactLink): Promise<any> {
        const userID = this._authService.getUserId();
        return this._httpClient.post(API_BASE_URL + 'users/' + userID + '/sensors/' + sensorId + '/contacts', contactLink.toApi()).toPromise();
    }

    updateContactLink(contactLink: ContactLink): Promise<any> {
        const userID = this._authService.getUserId();
        return this._httpClient.put(API_BASE_URL + 'users/' + userID + '/contactlinks/' + contactLink.id, contactLink.toApi()).toPromise();
    }

    addContactLinkDetails(contactLinkId: string, contactLinkDetails: ContactLinkDetail[]): Promise<any> {
        const userID = this._authService.getUserId();
        const contactLinkDetailsApi = [];
        contactLinkDetails.forEach(cld => {
            contactLinkDetailsApi.push(cld.toApi());
        });
        return this._httpClient.post(API_BASE_URL + 'users/' + userID + '/contactlinks/' + contactLinkId + '/details', contactLinkDetailsApi).toPromise();
    }

    removeContactLinkDetails(contactLinkId: string, contactLinkDetailIds: string[]): Promise<any> {
        const promises: Promise<any>[] = [];
        const userID = this._authService.getUserId();
        contactLinkDetailIds.forEach(contactLinkDetailId => {
            promises.push(
                this._httpClient.delete(API_BASE_URL + 'users/' + userID + '/contactlinks/' + contactLinkId + '/details/' + contactLinkDetailId).toPromise()
            );
        });
        return Promise.all(promises);
    }

    updateLocalContactLinkDetails(newSelectedItems, oldSelectedItems, contactLink, notificationMedium, objectType): void {
        // let contact = this.contacts.find(element => element.id == contactId);
        const details = this.getDetails(contactLink, notificationMedium);

        console.log(contactLink);
        newSelectedItems.forEach(selection => {
            if (!oldSelectedItems.includes(selection)) {
                let notificationType: string;
                if (objectType === 'device'){
                    notificationType = SbNotificationUtils.getDeviceNotificationTypes().find((element) => element.key === selection).value;
                } else {
                    const objectNotificationTypes = SbNotificationUtils.getSensorTypeNotificationTypes(objectType);
                    notificationType =  objectNotificationTypes.find((element) => element.key === selection).value;
                }
                const cld = {
                    notificationType: notificationType,
                    parentId: contactLink.id,
                    notificationMedium: notificationMedium
                };
                const a = details.find((element) => element.notificationType === cld.notificationType);
                // If the detail does not exist then add it
                if (!a) {
                    cld['apiAction'] = 'add';
                    contactLink.details.push(new ContactLinkDetail(cld));
                }
                // Else if it exists and has an action of "remove"
                else if (a && a.apiAction === 'remove') {
                    a.apiAction = null;
                }
            }
        });

        oldSelectedItems.forEach(selection => {
            if (!newSelectedItems.includes(selection)) {
                let notificationType: string;
                if (objectType === 'device'){
                    notificationType = SbNotificationUtils.getDeviceNotificationTypes().find((element) => element.key === selection).value;
                } else {
                    notificationType =  SbNotificationUtils.getSensorTypeNotificationTypes(objectType).find((element) => element.key === selection).value;
                }
                const a = details.find((element) => element.notificationType === notificationType);
                // If the detail exists here and has an id (i.e. exist in the database)
                if (a && a.id) {
                    a.apiAction = 'remove';
                }
                // Else if it only exists here, remove it completely
                else if (a) {
                    const i = details.indexOf(a);
                    contactLink.details.splice(i, 1);
                }
            }
        });
        contactLink.changed = true;
        // console.log(contact);
        // console.log(details);
        // this.onContactsChanged.next(this.contacts);
    }

    getDetails(contactLink: ContactLink, notificationMedium: string): ContactLinkDetail[] {
        return contactLink.details.filter((detail) => detail.notificationMedium === notificationMedium);
    }

    saveStuff(): Promise<any> {
        console.log(this.contactLinks);
        const promises: Promise<any>[] = [];
        this.contactLinks.forEach(contactLink => {
            if (!contactLink.id && contactLink.changed) {
                this.addContactLink(contactLink.objectId, contactLink)
                    .then((response) => {
                        const newClds = contactLink.details.filter(detail => detail.apiAction === 'add');
                        if (newClds.length) {
                            promises.push(this.addContactLinkDetails(response.id, newClds));
                        }
                        const removedClds = contactLink.details.filter(detail => detail.apiAction === 'remove');
                        if (removedClds.length) {
                            const removedCldIds = [];
                            removedClds.forEach(cld => {
                                removedCldIds.push(cld.id);
                            });
                            promises.push(this.removeContactLinkDetails(response.id, removedCldIds));
                        }
                    });
            }
            else if (contactLink.changed) {
                const newClds = contactLink.details.filter(detail => detail.apiAction === 'add');
                if (newClds.length) {
                    promises.push(this.addContactLinkDetails(contactLink.id, newClds));
                }
                const removedClds = contactLink.details.filter(detail => detail.apiAction === 'remove');
                if (removedClds.length) {
                    const removedCldIds = [];
                    removedClds.forEach(cld => {
                        removedCldIds.push(cld.id);
                    });
                    promises.push(this.removeContactLinkDetails(contactLink.id, removedCldIds));
                }
                this.updateContactLink(contactLink);
            }
        });
        return Promise.all(promises);
    }
}
