import {Component, OnInit, ViewEncapsulation, Inject, OnDestroy} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Contact, Sensor, ContactLink, ContactLinkDetail} from '@sensorbase/models';
import {
    ContactVerificationDialog,
    MultiSelectionDialog,
//    ContactVerificationDialog
} from '@sensorbase/components';
import {ContactsService, ContactLinksService} from '@sensorbase/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SbNotificationUtils} from '../../../../utils';

@Component({
    selector: 'contact-links-list-mini-dialog',
    templateUrl: './contact-links-list-mini-dialog.component.html',
    styleUrls: ['./contact-links-list-mini-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactLinksListMiniDialogComponent implements OnInit, OnDestroy {

    sensor: Sensor;
    contactLink: ContactLink;
    notificationTypes: any;

    // multiSelectionDialogRef: MatDialogRef<MultiSelectionDialog>;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _coreContactsService: ContactsService,
        private _contactLinksService: ContactLinksService,
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<ContactLinksListMiniDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.sensor = data.sensor;
        this.contactLink = data.contactLink;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // Read the notification types from the environment file and add them to the notificationTypes array
        // with the correct translation value.
        this.notificationTypes = [];
        SbNotificationUtils.getSensorNotificationTypes(this.sensor).forEach((notificationType) => {
            const key = notificationType.key;
            let value = notificationType.value;
            if (key !== 1 && key !== 2) {
                value = 'NOTIFICATIONS.DESCRIPTIONS.' + this.sensor.type.toUpperCase() +'.'+ value;
            }
            else {
                value = 'NOTIFICATIONS.DESCRIPTIONS.SENSOR.' + value;
            }
            this.notificationTypes.push({
                key: key,
                value: value
            });
        });

        this._contactLinksService.onContactLinksChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contactLinks => {
                contactLinks.forEach(contactLink => {
                    if (contactLink.contactId === this.contactLink.contactId) {
                        this.contactLink = contactLink;
                    }
                });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    editMultiSelection(contactLink: ContactLink, type: string): void {
        const details = this.getActiveDetails(contactLink, type);
        console.log(details);
        const selectedItems = [];
        details.forEach(detail => {
            // console.log(this.getKeyByValue(NOTIFICATION_TYPES.sensor[this.sensor.type], detail.notification_type));
            selectedItems.push(
                this.getKeyByValue(SbNotificationUtils.getSensorNotificationTypes(this.sensor), detail.notificationType)
            );
        });
        console.log(selectedItems);
        const multiSelectionDialogRef = this._matDialog.open(MultiSelectionDialog, {
            panelClass: 'multi-selection-dialog',
            autoFocus: false,
            restoreFocus: false,
            // maxWidth: '30%',
            data: {
                selectionItems: this.notificationTypes,
                selectedItems: selectedItems
            }
        });

        multiSelectionDialogRef.afterClosed()
            .subscribe(newSelectedItems => {
                console.log(selectedItems);
                console.log(newSelectedItems);
                if (newSelectedItems) {
                    this._contactLinksService.updateLocalContactLinkDetails(newSelectedItems, selectedItems, contactLink, type, this.sensor.type);
                }
            });
    }

    getDetails(contactLink: ContactLink, type: string): ContactLinkDetail[] {
        // console.log(details);
        return contactLink.details.filter((detail) => detail.notificationMedium === type);
    }

    getActiveDetails(contactLink: ContactLink, type: string): ContactLinkDetail[] {
        return contactLink.details.filter((detail) => {
            return detail.notificationMedium === type && (!detail.apiAction || detail.apiAction !== 'remove');
        });
    }

    getKeyByValue(object, value): string {
        return object.find((element) => element.value === value).key;
    }

    openContactVerifyDialog(contactLink: ContactLink, type: string): void {
        this._coreContactsService.getContact(contactLink.contactId)
            .subscribe((coreContact: Contact) => {

                const contactDetail = coreContact.contactDetails.find(detail => detail.type === type);

               const dialogRef = this._matDialog.open(ContactVerificationDialog, {
                    panelClass: 'contact-verification-dialog',
                    autoFocus: false,
                    restoreFocus: false,
                    data: {
                        contactDetail: contactDetail,
                        type: contactDetail.type
                    }
                });

            });
    }

    onSave(): void {
        this.dialogRef.close(this.contactLink);
    }

    onCancel(): void {
        this.dialogRef.close(this.contactLink);
    }
}
