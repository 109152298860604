import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2, TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subject, takeUntil} from 'rxjs';

import {AuthenticationService, UsersService} from '@sensorbase/services';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {MatButton} from '@angular/material/button';
import {User} from '../../../../@sensorbase/models';
import {UntypedFormControl} from '@angular/forms';
import {fuseAnimations} from '../../../../@fuse/animations';

@Component({
    selector: 'impersonate-user',
    templateUrl: './impersonate-user.component.html',
    styleUrls: ['./impersonate-user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ImpersonateUserComponent implements OnInit, OnDestroy {
    @ViewChild('impersonationOrigin') private _impersonationOrigin: MatButton;
    @ViewChild('impersonationPanel') private _impersonationPanel: TemplateRef<any>;
    @ViewChild('searchInputField') searchInputField: ElementRef;

    showSearch = false;
    searchInput: UntypedFormControl = new UntypedFormControl('');
    users: User[] = [];
    filteredUsers: User[] = [];
    @ViewChild('users', {static: false})
    shortcutsEl: ElementRef;

    impersonating = false;

    // Private
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _overlay
     * @param _viewContainerRef
     * @param _userService
     * @param _authService
     */
    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _userService: UsersService,
        private _authService: AuthenticationService,
    ) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.users$.subscribe({
            next: (users: any[]) => {
                console.log(users);
                this.users = users;
                this.filteredUsers = this.users.filter(user => user.email.includes(this.searchInput.value));
            },
            error: (error) => {
                console.log('Error getting users', error);
            }
        });

        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this.filteredUsers = this.users.filter(user => user.email.includes(searchText));
            });

        this.impersonating = sessionStorage.getItem('ImpersonationUserId') != null;

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open the impersonation panel
     */
    openPanel(): void {
        // Return if the impersonation panel or its origin is not defined
        if (!this._impersonationPanel || !this._impersonationOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._impersonationPanel, this._viewContainerRef));
    }

    /**
     * Close the impersonation panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    onSelectUser(user: User): void {
        console.log('Impersonate', user);
        this._authService.impersonateUser(user.userId);
        window.location.reload();
    }

    onStopImpersonation(): void {
        this._authService.stopImpersonation();
        window.location.reload();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._impersonationOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    toggleSearch(): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch) {
            setTimeout(() => {
                this.searchInputField.nativeElement.focus();
            }, 100);
        }
    }
}
