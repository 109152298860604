import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges,
    ViewChild,
    OnDestroy,
    Output,
    EventEmitter
} from '@angular/core';
import {Sensor} from '@sensorbase/models';
import {EChartsOption} from 'echarts';
import {SensorsService} from '@sensorbase/services';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';
import {AppConfig} from '../../../../app/core/config/app.config';
import {FuseConfigService} from '../../../../@fuse/services/config';

@Component({
    selector: 'sb-temperature-display',
    templateUrl: './temperature-display.component.html',
    styleUrls: ['./temperature-display.component.scss']
})
export class TemperatureDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;
    @Input() min = -50;
    @Input() max = 50;
    @Input() type = 'ngx-gauge';
    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        this._size = val;
        if(val === 'xs') {
            this.eChartsGaugeOptions.series[0].axisLine.lineStyle.width = 2;
            this.eChartsGaugeOptions.series[0].axisTick.show = false;
            this.eChartsGaugeOptions.series[0].axisLabel.show = false;
            this.eChartsGaugeOptions.series[0].splitLine.length = 1;
            this.eChartsGaugeOptions.series[0].title.fontSize = 3;
            this.eChartsGaugeOptions.series[0].detail.rich.value.fontSize = 25;
            this.eChartsGaugeOptions.series[0].detail.offsetCenter[1] = '40%';
        } else {
            this.eChartsGaugeOptions.series[0].axisLine.lineStyle.width = 4;
            this.eChartsGaugeOptions.series[0].axisTick.show = true;
            this.eChartsGaugeOptions.series[0].axisLabel.show = true;
            this.eChartsGaugeOptions.series[0].splitLine.length = 5;
            this.eChartsGaugeOptions.series[0].title.fontSize = 10;
            this.eChartsGaugeOptions.series[0].detail.rich.value.fontSize = 30;
            this.eChartsGaugeOptions.series[0].detail.offsetCenter[1] = '20%';
        }
        this.updateConfig();
    }

    @Output() hasWarning: EventEmitter<any> = new EventEmitter();

    conditions: any;
    minMaxConditions: any;

    loaded = false;
    eChartsGauge: any;
    eChartsGauseInitOpts: any = {
        renderer: 'svg'
    };
    eChartsGaugeOptions: any = {
        tooltip: {
            show: false
        },
        series: [
            {
                name: 'temperature',
                type: 'gauge',
                min: -50,
                max: 50,
                z: 3,
                splitNumber: 5,
                radius: '90%',
                animationDuration: 1000,
                axisLine: {
                    roundCap: true,
                    lineStyle: {
                        width: 8,
                        color:
                            [[0.1, '#4285f4'],
                                [0.11, '#4285f4'],
                                [0.12, '#4187ec'],
                                [0.13, '#4188e4'],
                                [0.14, '#408adc'],
                                [0.15, '#3f8cd4'],
                                [0.16, '#3e8ecc'],
                                [0.17, '#3e90c4'],
                                [0.18, '#3d91bc'],
                                [0.19, '#3c93b4'],
                                [0.20, '#3c95ac'],
                                [0.21, '#3b96a4'],
                                [0.22, '#3a989b'],
                                [0.23, '#3a9a93'],
                                [0.24, '#399c8b'],
                                [0.25, '#389e83'],
                                [0.26, '#389f7b'],
                                [0.27, '#37a173'],
                                [0.28, '#36a36b'],
                                [0.29, '#35a563'],
                                [0.30, '#35a65b'],
                                [0.31, '#34a853'],
                                [0.32, '#34A853'],
                                [0.69, '#34A853'],
                                [0.70, '#48ac4b'],
                                [0.71, '#5daf42'],
                                [0.72, '#71b33a'],
                                [0.73, '#85b632'],
                                [0.74, '#9aba2a'],
                                [0.75, '#aebe21'],
                                [0.76, '#c2c119'],
                                [0.77, '#d6c511'],
                                [0.78, '#ebc808'],
                                [0.79, '#ffcc00'],
                                [0.80, '#ffcc00'],
                                [0.81, '#fdba02'],
                                [0.82, '#fba904'],
                                [0.83, '#fa9707'],
                                [0.84, '#f88609'],
                                [0.85, '#f6740b'],
                                [0.86, '#f4620d'],
                                [0.87, '#f2510f'],
                                [0.88, '#f13f12'],
                                [0.89, '#ef2e14'],
                                [0.9, '#ed1c16'],
                                [1, '#ed1c16']]
                    }
                },
                axisTick: {
                    show: true,
                    splitNumber: 5,
                    length: 2,
                    distance: 1,
                    lineStyle: {
                        color: 'auto'
                    }
                },
                axisLabel: {
                    show: true,
                    color: 'auto',
                    fontSize: 10,
                    formatter: (value): string => {
                        return Math.round(value).toString() + '°';
                    }
                },
                splitLine: {
                    show: false,
                    distance: -7,
                    length: 5,
                    lineStyle: {
                        color: 'auto'
                    }
                },
                title: {
                    fontWeight: 'bolder',
                    fontSize: 10,
                    offsetCenter: [0, '+75%']
                },
                pointer: {
                    show: true,
                    showAbove: false,
                    itemStyle: {
                        color: '#fff',
                        borderColor: '#000',
                    },
                    // Triangle
                    width: 8,
                    length: '-20%',
                    icon: 'triangle',
                    offsetCenter: [0, '-105%']
                },
                detail: {
                    valueAnimation: true,
                    formatter: (value): string => {
                        const valueStr = String(value.toFixed(1));
                        if (valueStr.length > 4){
                            if (value < 0){
                                return '{value|' + Math.ceil(value) + '}\n{unit|°C}';
                            }
                            return '{value|' + Math.floor(value) + '}\n{unit|°C}';
                        }
                        return '{value|' + value.toFixed(1) + '}\n{unit|°C}';
                    },
                    rich: {
                        value: {
                            fontSize: 30,
                            fontWeight: 'normal',
                        },
                        unit: {
                            fontSize: 15,
                            color: '#0',
                            padding: [10, 0, 0, -5]
                        }
                    },
                    offsetCenter: [0, '20%'],
                },
                data: [
                    {
                        value: 0
                    }
                ]
            },

        ]
    };

    ngxGaugeOptions = {
        '-50': {color: 'blue'},
        '-10': {color: 'lightskyblue'},
        '5': {color: 'green'},
        '20': {color: 'orange'},
        '50': {color: 'red'}
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    constructor(
        private _sensorsService: SensorsService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.eChartsGaugeOptions.series[0]['data'][0]['value'] = this.sensor.displayData;

        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.checkConditions(this.sensor.displayData);
                    if (this.type === 'eCharts') {
                        this.updateChart(this.sensor.displayData);
                    }
                });
        });
        this._sensorsService.getSensorConditions(this.sensor.sensorId).subscribe();
        this._sensorsService.onSensorConditionsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                const sensorId = data.sensorId;
                const conditions = data.conditions;
                if (sensorId === this.sensor.sensorId){
                    this.loaded = false;
                    this.conditions = conditions;
                    const minMaxConditions = this.conditions.find(
                        (element) => element.conditionType === 'MIN_MAX'
                    ).condition;
                    this.minMaxConditions = minMaxConditions;
                    const diff = minMaxConditions.max - minMaxConditions.min;
                    this.eChartsGaugeOptions.series[0].min = Math.round(minMaxConditions.min - 0.1 * diff);
                    this.eChartsGaugeOptions.series[0].max = Math.round(minMaxConditions.max + 0.1 * diff);
                    this.updateConfig();
                    this.checkConditions(this.sensor.displayData);
                    this.loaded = true;
                }
            });

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                if (config.scheme === 'dark'){
                    this.eChartsGaugeOptions.series[0].detail.rich.unit.color = '#fff';
                } else {
                    this.eChartsGaugeOptions.series[0].detail.rich.unit.color = '#000';
                }
                this.updateConfig();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onChartInit(chartInstance): void {
        this.eChartsGauge = chartInstance;
        // this.updateChart(this.sensor.displayData);
    }

    updateChart(value: any): void {
        this.eChartsGaugeOptions.series[0]['data'][0]['value'] = value;
        this.updateConfig();
    }

    updateConfig(): void {
        if (this.eChartsGauge){
            setTimeout(() => {
                this.eChartsGauge.setOption(this.eChartsGaugeOptions, true);
            });
        }
    }

    checkConditions(value: any): void {
        if (this.minMaxConditions){
            if (value > this.minMaxConditions.max){
                this.hasWarning.next({
                    type: 'max',
                    message: 'Υψηλή θερμοκρασία'
                });
            } else if (value < this.minMaxConditions.min){
                this.hasWarning.next({
                    type: 'min',
                    message: 'Χαμηλή θερμοκρασία'
                });
            } else {
                this.hasWarning.next(null);
            }
        }
    }
}
