/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'main-menu',
        title: 'MAIN MENU',
        translate: 'NAV.MAINMENU.TITLE',
        type: 'group',
        children: [
            {
                id: 'home',
                title: 'Home',
                translate: 'NAV.MAINMENU.HOME',
                type: 'basic',
                icon: 'home',
                link: '/admin/home',
            },
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.MAINMENU.DASHBOARDS.TITLE',
                type: 'collapsable',
                icon: 'dashboard',
                // link: '/admin/dashboards/home',
                children: [],
            },
            {
                id: 'devices',
                title: 'Devices',
                translate: 'NAV.MAINMENU.DEVICES',
                type: 'basic',
                icon: 'router',
                link: '/admin/devices',
            },
            {
                id: 'sensors',
                title: 'Sensors',
                translate: 'NAV.MAINMENU.SENSORS',
                type: 'basic',
                icon: 'sensors',
                link: '/admin/sensors',
            },
            {
                id: 'settings',
                title: 'Settings',
                translate: 'NAV.MAINMENU.SETTINGS',
                type: 'basic',
                icon: 'settings',
                link: '/admin/settings',
            },
            {
                id: 'addDevice',
                title: 'Add Device',
                translate: 'NAV.FUNCTIONS.ADD_DEVICE',
                type: 'basic',
                icon: 'add_box',
                link: '/admin/addDevice'
            }
        ]
    },
    {
        id: 'apps',
        title: 'APPS',
        translate: 'NAV.APPS.TITLE',
        type: 'group',
        children: [
            {
                id: 'charts',
                title: 'Charts',
                translate: 'NAV.APPS.CHARTS',
                type: 'collapsable',
                icon: 'insert_chart',
                children: [
                    {
                        id: 'simple-charts',
                        title: 'Simple',
                        translate: 'NAV.APPS.SIMPLECHARTS',
                        type: 'basic',
                        icon: 'show_chart',
                        link: '/admin/charts',
                    },
                    {
                        id: 'multi-charts',
                        title: 'Multi-Charts',
                        translate: 'NAV.APPS.MULTICHARTS',
                        type: 'basic',
                        icon: 'stacked_line_chart',
                        link: '/admin/multi-charts',
                    },

                ]
            },
            {
                id: 'export',
                title: 'Export Data',
                translate: 'NAV.APPS.EXPORT',
                type: 'basic',
                icon: 'cloud_download',
                link: '/admin/export-data',
            },
            {
                id: 'contacts',
                title: 'Contacts',
                translate: 'NAV.APPS.CONTACTS',
                type: 'basic',
                icon: 'account_box',
                link: '/admin/contacts',
            },
            {
                id: 'maps',
                title: 'Maps',
                translate: 'NAV.APPS.MAPS',
                type: 'basic',
                icon: 'map',
                link: '/admin/maps',
            },
            // {
            //     id: 'calendar',
            //     title: 'Calendar',
            //     translate: 'NAV.APPS.CALENDAR',
            //     type: 'basic',
            //     icon: 'today',
            //     link: '/admin/calendar',
            // },
            // {
            //     id: 'tutorials',
            //     title: 'Tutorials',
            //     translate: 'NAV.APPS.TUTORIALS',
            //     type: 'basic',
            //     icon: 'school',
            //     link: '/admin/tutorials',
            // }
        ]
    },
    {
        id: 'pro',
        title: 'ADVANCED',
        translate: 'NAV.ADVANCED.TITLE',
        type: 'group',
        badge: {
            title: 'PRO',
            classes: 'px-2.5 bg-red-400 text-white rounded-full'
        },
        children: [
            {
                id: 'functions',
                title: 'Functions',
                translate: 'NAV.ADVANCED.FUNCTIONS',
                type: 'basic',
                icon: 'developer_mode',
                link: '/admin/functions',
            },
            {
                id: 'alexa',
                title: 'Link Alexa',
                translate: 'NAV.ADVANCED.ALEXA',
                type: 'basic',
                icon: 'developer_mode',
                link: '/admin/alexa/link'
            }
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'main-menu',
        title: 'MAIN MENU',
        translate: 'NAV.MAINMENU.TITLE',
        type: 'aside',
        icon    : 'apps',
        children: []
    },
    {
        id: 'apps',
        title: 'APPS',
        translate: 'NAV.APPS.TITLE',
        type: 'aside',
        icon    : 'widgets',
        children: []
    },
    {
        id: 'pro',
        title: 'ADVANCED',
        translate: 'NAV.ADVANCED.TITLE',
        type: 'aside',
        icon    : 'engineering',
        children: []
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'main-menu',
        title: 'MAIN MENU',
        translate: 'NAV.MAINMENU.TITLE',
        type: 'aside',
        icon    : 'apps',
        children: []
    },
    {
        id: 'apps',
        title: 'APPS',
        translate: 'NAV.APPS.TITLE',
        type: 'aside',
        icon    : 'widgets',
        children: []
    },
    {
        id: 'pro',
        title: 'ADVANCED',
        translate: 'NAV.ADVANCED.TITLE',
        type: 'aside',
        icon    : 'engineering',
        children: []
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'main-menu',
        title: 'MAIN MENU',
        translate: 'NAV.MAINMENU.TITLE',
        type: 'group',
        icon    : 'apps',
        children: []
    },
    {
        id: 'apps',
        title: 'APPS',
        translate: 'NAV.APPS.TITLE',
        type: 'group',
        icon    : 'widgets',
        children: []
    },
    {
        id: 'pro',
        title: 'ADVANCED',
        translate: 'NAV.ADVANCED.TITLE',
        type: 'group',
        icon    : 'engineering',
        children: []
    }
];
