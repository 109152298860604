<ng-container *ngIf="!selected">
    <div class="flex flex-col flex-auto overflow-y-auto lg:overflow-hidden bg-gray-100 dark:bg-transparent p-4">

        <!-- Select mail to read -->
        <div class="flex flex-col flex-auto items-center justify-center border-2 border-dashed border-gray-300 rounded-2xl">
            <mat-icon
                class="icon-size-24"
                [svgIcon]="'touch_app'"></mat-icon>
            <div class="mt-4 px-4 text-2xl font-semibold tracking-tight text-secondary text-center"
                 [transloco]="'DEVICES.SELECT_DEVICE'"></div>
        </div>

    </div>
</ng-container>

<ng-container *ngIf="selected">
    <!-- SIDEBAR HEADER -->
    <div class="flex-initial accent p-6 relative" fxLayout="column" fxLayoutAlign="space-between">

        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
            <div>{{selected.deviceName}}</div>
            <button mat-icon-button class="absolute top-3 right-3" [ngClass]="{'sm:hidden': !showClose}"
                    (click)="onClose()">
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </div>

        <ng-container *ngIf="selected.status>0; then onlineTemplate; else offlineTemplate"></ng-container>
        <ng-template #onlineTemplate>
            <div class="subtitle text-secondary text-sm min-h-8" fxLayout="row" fxLayoutAlign="space-between end">
                <span>Online </span>
                <mat-icon class="mr-2" [svgIcon]="rssiIcon"></mat-icon>
            </div>
        </ng-template>
        <ng-template #offlineTemplate>
            <div class="subtitle text-secondary text-sm min-h-8" fxLayout="row" fxLayoutAlign="space-between end">
                <span>Last seen: {{selected.lastUpdate.toLocaleString()}}</span>
                <mat-icon class="mr-2" [svgIcon]="rssiIcon"></mat-icon>
            </div>
        </ng-template>

    </div>

    <!-- DIVIDER -->
    <mat-divider class="mt-0 mb-4 mx-4"></mat-divider>

    <!-- SIDEBAR CONTENT -->
    <div class="content p-6" fusePerfectScrollbar>

        <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">

            <div class="h-60 w-full mb-6" fxLayout="row" fxLayoutAlign="center center">
                <device-location-map class="w-full h-full" [device]='selected'
                                     (locationChanged)="onLocationChanged($event)">
                </device-location-map>
            </div>

            <div class="mb-6" fxLayout="row" fxLayoutAlign="center center">
                <div class="flex flex-row justify-between items-center w-full">
                    <button mat-stroked-button class="px-2 min-w-10" (click)="onRestartButtonClicked()" [disabled]="selected.status<=0"
                            matTooltip="{{'GENERIC.Restart_Device' | transloco}}">
                        <mat-icon svgIcon="refresh"></mat-icon>
                    </button>
                    <button mat-stroked-button class="px-2 min-w-10" (click)="onUpdateButtonClicked()" [disabled]="selected.status<=0"
                            matTooltip="{{'GENERIC.Update' | transloco}} Firmware">
                        <mat-icon svgIcon="system_update"></mat-icon>
                    </button>
                    <button mat-stroked-button class="px-2 min-w-10" (click)="onDeleteButtonClicked()"
                            matTooltip="{{'GENERIC.Delete_Device' | transloco}}">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                    <button mat-stroked-button class="px-2 min-w-10" (click)="toggleNotifications()"
                            matTooltip="{{(selected.notifications? 'SENSOR_WIDGET.NOTIFICATIONS.TURN_OFF': 'SENSOR_WIDGET.NOTIFICATIONS.TURN_ON') |transloco}}"
                            matTooltipPosition="below" aria-label="Toggle notifications">
                        <mat-icon [ngClass]="{'text-green-700': selected.notifications, 'text-red-500': !selected.notifications}"
                                  [svgIcon]="selected.notifications ? 'notifications' :'notifications_off'">
                        </mat-icon>
                    </button>
                    <button mat-stroked-button class="px-2 min-w-10" (click)="onSettingsButtonClicked()" matTooltip="{{'GENERIC.Settings' | transloco}}">
                        <mat-icon svgIcon="settings"></mat-icon>
                    </button>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="py-4 font-bold">{{'GENERIC.Info' | transloco}}</div>
            </div>

            <table>
                <tr class="name">
                    <th>{{'GENERIC.Name' | transloco}}</th>
                    <td>{{selected.deviceName}}</td>
                </tr>

                <tr class="id">
                    <th>ID</th>
                    <td>{{selected.deviceId}}</td>
                </tr>

                <tr class="model">
                    <th>{{'GENERIC.Model' | transloco}}</th>
                    <td>{{selected.model}}</td>
                </tr>

                <tr class="hwVersion">
                    <th>{{'GENERIC.Hardware_version' | transloco}}</th>
                    <td>{{selected.hwVersion}}</td>
                </tr>

                <tr class="fwVersion">
                    <th>{{'GENERIC.Firmware_version' | transloco}}</th>
                    <td>{{selected.fwVersion}}</td>
                </tr>

                <tr class="localIp">
                    <th>{{'GENERIC.Local IP' | transloco}}</th>
                    <td>{{selected.localIp}}</td>
                </tr>
            </table>


            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="py-4 font-bold">{{'GENERIC.Connected Sensors' | transloco}}</div>
            </div>
            <div class="sensor-table">
                <table>
                    <tr *ngFor="let sensor of sensors">
                        <th>{{sensor.sensorName}}</th>
                        <td class="flex flex-row justify-between items-center">
                            <div class="flex">
                                {{getSensorValue(sensor)}}
                            </div>
                            <button mat-icon-button (click)="openSensorSettings(sensor)"
                                    matTooltip="{{'SENSOR_WIDGET.SETTINGS' | transloco}}"
                                    matTooltipPosition="below">
                                <mat-icon svgIcon="settings"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</ng-container>
