<div *ngIf="!formActive" class="inline-editable-field" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
    <span>{{value}}</span>
    <button mat-icon-button (click)="openForm()">
        <mat-icon class="s-16" svgIcon="edit"></mat-icon>
    </button>
</div>


<form [formGroup]="form" (ngSubmit)="onFormSubmit()"
      class="inline-editable-field-form" fxLayout="row wrap" fxFlex="1 0 auto" *ngIf="formActive">

    <input formControlName="inputValue" #inputField fxFlex="1 0 auto" placeholder="Write a value">

    <button mat-icon-button fxFlex="0 1 auto" [disabled]="required && form.invalid">
        <mat-icon svgIcon="check"></mat-icon>
    </button>
    <button mat-icon-button fxFlex="0 1 auto" (click)="closeForm()" type="button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</form>