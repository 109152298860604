<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SENSOR_WIDGET.SETTINGS' | transloco }}</div>
        <button
            mat-icon-button
            (click)="dialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <mat-action-list class="pt-0">
            <!-- <mat-list-item> -->
            <button mat-list-item (click)="openGeneralSettings()">
                <mat-icon class="mx-2" svgIcon="settings">
                </mat-icon>
                {{'SENSOR_WIDGET.SETTINGS_TYPE.GENERAL' | transloco}}
            </button>
            <!-- </mat-list-item> -->
            <mat-divider></mat-divider>
            <!-- <mat-list-item> -->
            <button mat-list-item (click)="openNotificationSettings()">
                <mat-icon class="mx-2" svgIcon="notifications">
                </mat-icon>
                {{'SENSOR_WIDGET.SETTINGS_TYPE.NOTIFICATION' | transloco}}
            </button>
            <ng-container *ngIf="sensor.type === 'relay'">
                <mat-divider></mat-divider>
                <!-- <mat-list-item> -->
                <button mat-list-item (click)="openTimerSettings()">
                    <mat-icon class="mx-2" svgIcon="timer">
                    </mat-icon>
                    {{'SENSOR_WIDGET.SETTINGS_TYPE.TIMERS' | transloco}}
                </button>
                <!-- </mat-list-item> -->
                <mat-divider></mat-divider>
                <!-- <mat-list-item> -->
                <button mat-list-item (click)="openAutomationsSettings()">
                    <mat-icon class="mx-2" svgIcon="settings_ethernet">
                    </mat-icon>
                    {{'SENSOR_WIDGET.SETTINGS_TYPE.FUNCTIONS' | transloco}}
                </button>
            </ng-container>
            <!-- </mat-list-item>  -->

            <!-- </mat-list-item> -->

        </mat-action-list>
    </div>
</div>
