import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Device, Sensor} from '@sensorbase/models';
import {DevicesService, ExcelExportService, SensorsService} from '@sensorbase/services';
import {forkJoin, map, Observable, retry, Subject, take} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {HttpProgressDialog} from '../../dialogs';
import {TranslocoService} from '@ngneat/transloco';
import {MatDialog} from '@angular/material/dialog';
import {DateTime} from 'luxon';

@Component({
    selector: 'export-data',
    templateUrl: './export-data.component.html',
    styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit, OnDestroy {

    @Input('sensors')
    get sensors(): Sensor[] {
        return this._sensors;
    }
    set sensors(val) {
        this._sensors = val;
        this.dataSource= new MatTableDataSource(this.sensors);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property): string => {
            switch(property) {
                case 'deviceName': return this.getDeviceName(item.deviceId);
                default: return item[property];
            }
        };
        this.dataSource.sort = this.sort;
        this.masterToggle();
        this._devicesService.devices$
            .pipe(take(1))
            .subscribe(devices => {
                this.devices = devices;
            });
    }
    @ViewChild(MatPaginator) get paginator(): MatPaginator {
        return this._paginator;
    }
    set paginator(val) {
        this._paginator = val;
        if (this.dataSource) {
            this.dataSource.paginator = this.paginator;
        }
    }
    @ViewChild(MatSort) get sort(): MatSort {
        return this._sort;
    }
    set sort(val) {
        this._sort = val;
        if (this.dataSource) {
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (item, property): string => {
                switch(property) {
                    case 'type': return this._translateService.translate(this.getTypeStr(item.type));
                    case 'deviceName': return this.getDeviceName(item.deviceId);
                    default: return item[property];
                }
            };
        }
    }


    devices: Device[] = [];
    dataSource: MatTableDataSource<Sensor>;
    selection = new SelectionModel<Sensor>(true, []);
    displayedColumns = ['checkbox', 'status', 'sensorName', 'type', 'deviceName'];
    datePickerOpen = false;
    today: DateTime;
    form: FormGroup;
    days: number[] = [];
    hours: number[] = [];
    minutes: number[] = [];

    // Private
    private _sort: MatSort;
    private _paginator: MatPaginator;
    private _sensors: Sensor[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _sensorsService: SensorsService,
        private _devicesService: DevicesService,
        private _formBuilder: FormBuilder,
        private _translateService: TranslocoService,
        public _matDialog: MatDialog,
        private _excelExportService: ExcelExportService
    ) {
        this.today = DateTime.now();
        // End date is today
        const endDate = DateTime.now().set({hour: 23, minute: 59, second:59, millisecond: 0});
        // Start date is yesterday
        const startDate = DateTime.now().minus({days: 1}).set({hour: 0, minute: 0, second: 0, millisecond: 0});

        this.form = new FormGroup({
            days: new FormControl<number>(0),
            hours: new FormControl<number>(0),
            minutes: new FormControl<number>(0),
            selectAll: new FormControl<boolean>(true),
            start: new FormControl<DateTime | null>(startDate),
            end: new FormControl<DateTime | null>(endDate),
        });

        for (let i = 0; i < 32; i++) {
            this.days.push(i);
        }

        for (let i = 0; i < 25; i++) {
            this.hours.push(i);
        }

        for (let i = 0; i < 61; i++) {
            this.minutes.push(i);
        }
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.sensors);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.filteredData.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }

    getDeviceName(deviceId): string {
        const dev = this.devices.find(device => device.deviceId === deviceId);
        if (dev) {
            return dev.deviceName;
        }
        return '';
    }

    getTypeStr(type): string {
        return 'SENSOR.TYPES.' + type;
    }

    onExport(): void {
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        const formObj = this.form.getRawValue();
        const startDate = formObj.start;
        const endDate = formObj.end.set({hour: 23, minute: 59, second: 59, millisecond: 0});
        const obs: Observable<any>[] = [];
        this.selection.selected.forEach(sensor => {
            obs.push(this._sensorsService.getSensorData(sensor.sensorId, startDate.toUTC().toJSDate(), endDate.toUTC().toJSDate()).pipe(
                take(1),
                retry(3),
                map(results => [sensor, results])
            ));
        });
        const headers = [
            this._translateService.translate('GENERIC.Datetime'),
            this._translateService.translate('GENERIC.SENSOR.Name'),
            this._translateService.translate('GENERIC.Type'),
            this._translateService.translate('GENERIC.Value')
        ];
        forkJoin(obs).subscribe({
            next: responses => {
                let data = [];
                responses.forEach(response => {
                    const sensor = response[0];
                    let sensorData = response[1];
                    const days = formObj.days;
                    const hours = formObj.hours;
                    const minutes = formObj.minutes;
                    const selectAll = formObj.selectAll;
                    const millis = this.toMillis(days, hours, minutes);
                    if (!selectAll && sensorData.length > 0 && millis !== 0) {
                        let startTime = sensorData[0][0];
                        const datatmp = [sensorData[0]];
                        sensorData.forEach(value => {
                            if (value[0] >= startTime + millis && value[1] != null) {
                                datatmp.push(value);
                                startTime = value[0];
                            }
                        });
                        sensorData = datatmp;
                    }
                    const type = this._translateService.translate('GENERIC.SENSOR.VALUE_TYPES.' + sensor.type);
                    const name = sensor.sensorName;
                    switch (sensor.type) {
                        case 'door':
                            sensorData = sensorData.map(x => {
                                const val = x[1] ? 'GENERIC.SENSOR.STATE.DOOR.OPEN' : 'GENERIC.SENSOR.STATE.DOOR.CLOSED';
                                const translatedVal = this._translateService.translate(val);
                                return [x[0], name, type, translatedVal];
                            });
                            break;
                        case 'relay':
                            sensorData = sensorData.map(x => {
                                const val = x[1] ? 'GENERIC.SENSOR.STATE.RELAY.CLOSED' : 'GENERIC.SENSOR.STATE.RELAY.OPEN';
                                const translatedVal = this._translateService.translate(val);
                                return [x[0], name, type, translatedVal];
                            });
                            break;
                        default:
                            sensorData = sensorData.map(x => {
                                return [x[0], name, type, x[1]];
                            });
                            break;
                    }
                    data = data.concat(sensorData);
                });
                data.sort((a, b) => a[0] - b[0]);
                data = data.map(x => [new Date(x[0]).toLocaleString().replace(',', ''), x[1], x[2], x[3]]);
                data.unshift(headers);
                const wb = this._excelExportService.converToXlsx(data);
                this._excelExportService.downloadXlsx(wb, 'Data');
                httpProgressDialog.componentInstance.state = 'success';
            },
            error: error => {
                httpProgressDialog.componentInstance.state = 'error';
            }
        });
    }

    onPrint(): void {
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        const formObj = this.form.getRawValue();
        const startDate = formObj.start;
        const endDate = formObj.end.set({hour: 23, minute: 59, second: 59, millisecond: 0});
        const obs: Observable<any>[] = [];
        this.selection.selected.forEach(sensor => {
            obs.push(this._sensorsService.getSensorData(sensor.sensorId, startDate.toUTC().toJSDate(), endDate.toUTC().toJSDate()).pipe(
                take(1),
                retry(3),
                map(results => [sensor, results])
            ));
        });
        const headers = [this._translateService.translate('GENERIC.Datetime'),
            this._translateService.translate('GENERIC.SENSOR.Name'), this._translateService.translate('GENERIC.Type'), this._translateService.translate('GENERIC.Value')];
        forkJoin(obs).subscribe({
            next: responses => {
                let data = [];
                responses.forEach(response => {
                    const sensor = response[0];
                    let sensorData = response[1];
                    const days = formObj.days;
                    const hours = formObj.hours;
                    const minutes = formObj.minutes;
                    const selectAll = formObj.selectAll;
                    const millis = this.toMillis(days, hours, minutes);
                    if (!selectAll && sensorData.length > 0 && millis !== 0) {
                        let startTime = sensorData[0][0];
                        const datatmp = [sensorData[0]];
                        sensorData.forEach(value => {
                            if (value[0] >= startTime + millis && value[1] != null) {
                                datatmp.push(value);
                                startTime = value[0];
                            }
                        });
                        sensorData = datatmp;
                    }
                    const type = this._translateService.translate('GENERIC.SENSOR.VALUE_TYPES.' + sensor.type);
                    const name = sensor.sensorName;
                    switch (sensor.type) {
                        case 'door':
                            sensorData = sensorData.map(x => {
                                const val = x[1] ? 'GENERIC.SENSOR.STATE.DOOR.OPEN' : 'GENERIC.SENSOR.STATE.DOOR.CLOSED';
                                const translatedVal = this._translateService.translate(val);
                                return [x[0], name, type, translatedVal];
                            });
                            break;
                        case 'relay':
                            sensorData = sensorData.map(x => {
                                const val = x[1] ? 'GENERIC.SENSOR.STATE.RELAY.CLOSED' : 'GENERIC.SENSOR.STATE.RELAY.OPEN';
                                const translatedVal = this._translateService.translate(val);
                                return [x[0], name, type, translatedVal];
                            });
                            break;
                        default:
                            sensorData = sensorData.map(x => {
                                return [x[0], name, type, x[1]];
                            });
                            break;
                    }
                    data = data.concat(sensorData);
                });
                data.sort((a, b) => a[0] - b[0]);
                data = data.map(x => [new Date(x[0]).toLocaleString().replace(',', ''), x[1], x[2], x[3]]);
                data.unshift(headers);
                const wb = this._excelExportService.converToXlsx(data);
                const ws = wb.Sheets['Sheet1'];
                this._excelExportService.printSheet(ws);
                httpProgressDialog.componentInstance.state = 'success';
            },
            error: error => {
                httpProgressDialog.componentInstance.state = 'error';
            }
        });
    }

    toMillis(days, hours, minutes): number {
        return (days * 24 * 60 + hours * 60 + minutes) * 60 * 1000;
    }
}
