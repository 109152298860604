import {Component, Input, OnInit} from '@angular/core';
import {Sensor} from '../../../models';
import {Subject} from 'rxjs';
import {AuthenticationService, SensorbaseMqttService, SensorsService} from '../../../services';
import {FuseConfigService} from '../../../../@fuse/services/config';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';

@Component({
    selector: 'sb-button-display',
    templateUrl: './button-display.component.html',
    styleUrls: ['./button-display.component.scss']
})
export class ButtonDisplayComponent implements OnInit {
    @Input() sensor: Sensor;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _sensorsService: SensorsService,
        private _fuseConfigService: FuseConfigService,
        private _mqttService: SensorbaseMqttService,
        private _authService: AuthenticationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {});
        });
        this._sensorsService.subSensorDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    if (message.payload.toString() === '1'){
                        this.sensor.status = 0;
                    }
                });
    }

    toggleButton(): void {
        this._sensorsService.toggleButton(this.sensor.sensorId);
    }

}
