import {Component, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';

import {SensorbaseMqttService} from '@sensorbase/services';

@Component({
    selector: 'mqtt-indicator',
    templateUrl: './mqtt-indicator.component.html',
    styleUrls: ['./mqtt-indicator.component.scss']
})
export class MqttIndicatorComponent implements OnInit {

    mqttConnected = false;
    mqttStatusText = 'Your connection to MQTT seems to have dropped!';

    // Private
    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private _mqttService: SensorbaseMqttService,
    ) { }

    ngOnInit(): void {
        this._mqttService.onConnect
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.mqttConnected = true;
                this.mqttStatusText = 'You are connected to MQTT';
            });

        this._mqttService.onDisconnect
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.mqttConnected = false;
                this.mqttStatusText = 'Your connnection to MQTT seems to have dropped!';
            });
    }

    connect(): void {
        if(!this.mqttConnected) {
            this._mqttService.disconnect();
            this._mqttService.connectToBroker(0);
        }
    }

}
