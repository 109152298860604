import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, filter, forkJoin, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {Country} from '@sensorbase/models';
import {API_BASE_URL, APIV2_BASE_URL, COUNTRY_CODES} from 'environments/environment';

import {AuthenticationService} from '@sensorbase/services';
import {Contact, IContactDetail, Sensor} from '@sensorbase/models';
import {FuseMockApiUtils} from '../../../@fuse/lib/mock-api';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    // Private
    private _userContact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
    private _contact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
    private _allContacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
    private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private _authService: AuthenticationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for user contact
     */
    get userContact$(): Observable<Contact> {
        return this._userContact.asObservable();
    }

    /**
     * Getter for contact
     */
    get contact$(): Observable<Contact> {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]> {
        return this._contacts.asObservable();
    }

    /**
     * Getter for contacts
     */
    get allContacts$(): Observable<Contact[]> {
        return this._allContacts.asObservable();
    }

    /**
     * Getter for countries
     */
    get countries$(): Observable<Country[]> {
        return this._countries.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */
    getContacts(): Observable<Contact[]> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            return this._httpClient.get(API_BASE_URL + 'users/' + userID + '/contacts').pipe(
                map((response: any[]) => response.map(contact => Contact.fromApi(contact))),
                tap((allContacts: Contact[]) => {
                    const userContact = allContacts.filter(contact => contact.type === 'PRIMARY')[0];
                    const contacts = allContacts.filter(contact => contact.type === 'SECONDARY');
                    this._userContact.next(userContact);
                    this._contacts.next(contacts);
                    this._allContacts.next(allContacts);
                })
            );
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    getContact(contactId): Observable<Contact> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            return this._httpClient.get(API_BASE_URL + 'users/' + userID + '/contacts/' + contactId).pipe(
                map((response: any) => Contact.fromApi(response)),
            );
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    /**
     * Search contacts with given query
     *
     * @param query
     */
    searchContacts(query: string): Observable<Contact[]> {
        return this._httpClient.get<Contact[]>('api/apps/contacts/search', {
            params: {query}
        }).pipe(
            tap((contacts) => {
                this._contacts.next(contacts);
            })
        );
    }

    /**
     * Get contact by id
     */
    getContactById(id: string): Observable<Contact> {
        return this._allContacts.pipe(
            take(1),
            map((contacts) => {

                // Find the contact
                const contact = contacts.find(item => item.id === id) || null;

                // Update the contact
                this._contact.next(contact);

                // Return the contact
                return contact;
            }),
            switchMap((contact) => {

                if (!contact) {
                    return throwError(() => 'Could not found contact with id of ' + id + '!');
                }

                return of(contact);
            })
        );
    }

    /**
     * Create contact
     */
    createContact(): Observable<Contact> {
        return forkJoin([this.contacts$.pipe(take(1)), this.allContacts$.pipe(take(1))]).pipe(
            take(1),
            switchMap(response => {

                const newContact = new Contact({
                    // Assign random negative number to new contacts
                    id: (-Math.floor(Math.random() * 10000000)).toString(),
                    name: 'New Contact'
                });

                const contacts = response[0];
                const allContacts = response[1];
                this._contacts.next([newContact, ...contacts]);
                this._allContacts.next([newContact, ...allContacts]);

                // Return the new contact
                return of(newContact);
            })
        );
    }

    /**
     * Update contact
     *
     * @param contact
     * @returns
     */
    addContact(contact: Contact): Observable<Contact> {
        console.log(contact);
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            return this._httpClient.post(API_BASE_URL + 'users/' + userID + '/contacts', contact.toApi()).pipe(
                map(response => Contact.fromApi(response)),
                tap((response) => {
                    this.getContacts().subscribe();
                })
            );
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    /**
     * Update contact
     *
     * @param contact
     */
    updateContact(contact: Contact): Observable<Contact> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();

            // Start by updating the contact details
            const obs: Observable<any>[] = [];
            for (const contactDetail of contact.contactDetails) {
                // @ts-ignore
                if (contactDetail.id < 0) {
                    const url = API_BASE_URL + 'users/' + userID + '/contacts/' + contact.id + '/contact_detail';
                    obs.push(this._httpClient.post(url, contactDetail));
                } else if (contactDetail.value === '') {
                    const url = API_BASE_URL + 'users/' + userID + '/contacts/' + contact.id + '/contact_detail/' + contactDetail.id;
                    obs.push(this._httpClient.delete(url));
                } else {
                    const url = API_BASE_URL + 'users/' + userID + '/contacts/' + contact.id + '/contact_detail/' + contactDetail.id;
                    obs.push(this._httpClient.put(url, contactDetail));
                }
            }
            return forkJoin(obs).pipe(
                // Then update the contact
                switchMap(() => {
                    return this._httpClient.put<Contact>(API_BASE_URL + 'users/' + userID + '/contacts/' + contact.id, contact.toApi())
                        .pipe(
                            switchMap(() => this.getContact(contact.id)),
                            // Then update the contacts observables
                            switchMap((updatedContact: Contact) => {
                                const innerObs = [
                                    this.contacts$.pipe(take(1)),
                                    this.allContacts$.pipe(take(1)),
                                    this.contact$.pipe(take(1)),
                                    this.userContact$.pipe(take(1))
                                ];
                                return forkJoin(innerObs)
                                    .pipe(
                                        take(1),
                                        map((response: [Contact[], Contact[], Contact, Contact]) => {
                                            const contacts = response[0];
                                            const allContacts = response[1];
                                            const selContact = response[2];
                                            const userContact = response[3];

                                            // Find the index of the deleted contact
                                            let index = contacts.findIndex(item => item.id === updatedContact.id);

                                            // Delete the contact
                                            contacts[index] = updatedContact;

                                            // Update the contacts
                                            this._contacts.next(contacts);

                                            // Find the index of the deleted contact
                                            index = allContacts.findIndex(item => item.id === updatedContact.id);

                                            // Delete the contact
                                            allContacts[index] = updatedContact;

                                            // Update the contacts
                                            this._allContacts.next(allContacts);

                                            if(selContact && updatedContact.id === selContact.id){
                                                // Update the contact if it's selected
                                                this._contact.next(updatedContact);
                                            }

                                            if(updatedContact.id === userContact.id){
                                                // Update the userContact
                                                this._userContact.next(updatedContact);
                                            }

                                            return updatedContact;
                                        }),
                                        // Then update the selected contact
                                        // switchMap(updatedContact => this.contact$.pipe(
                                        //     take(1),
                                        //     filter(item => item && item.id === contact.id),
                                        //     tap(() => {
                                        //
                                        //         // Update the contact if it's selected
                                        //         this._contact.next(updatedContact);
                                        //
                                        //         // Return the updated contact
                                        //         return updatedContact;
                                        //     })
                                        // )),
                                        // // Then update the user contact
                                        // switchMap(updatedContact => this.userContact$.pipe(
                                        //     take(1),
                                        //     filter(item => item && item.id === contact.id),
                                        //     tap(() => {
                                        //
                                        //         // Update the userContact
                                        //         this._userContact.next(updatedContact);
                                        //
                                        //         // Return the updated contact
                                        //         return updatedContact;
                                        //     })
                                        // )),
                                    );
                            })
                        );
                })
            );
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    /**
     * Delete the contact
     *
     * @param contact
     */
    deleteContact(contact: Contact): Observable<any> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            return forkJoin([this.contacts$.pipe(take(1)), this.allContacts$.pipe(take(1))]).pipe(
                take(1),
                switchMap(response => {

                    const contacts = response[0];
                    const allContacts = response[1];

                    return this._httpClient.delete(API_BASE_URL + 'users/' + userID + '/contacts/' + contact.id)
                        .pipe(
                            tap(() => {
                                // Find the index of the deleted contact
                                let index = contacts.findIndex(item => item.id === contact.id);

                                // Delete the contact
                                contacts.splice(index, 1);

                                // Update the contacts
                                this._contacts.next(contacts);

                                // Find the index of the deleted contact
                                index = allContacts.findIndex(item => item.id === contact.id);

                                // Delete the contact
                                allContacts.splice(index, 1);

                                // Update the contacts
                                this._allContacts.next(allContacts);
                            })
                        );

                }));
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    /**
     * Get countries
     */
    getCountries(): Observable<Country[]> {
        return of(COUNTRY_CODES).pipe(
            tap((countries) => {
                this._countries.next(countries);
            })
        );
        // return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
        //     tap((countries) => {
        //         this._countries.next(countries);
        //     })
        // );
    }

    /**
     * Update the avatar of the given contact
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: string, avatar: File): Observable<Contact> {
        return forkJoin([this.contacts$.pipe(take(1)), this.allContacts$.pipe(take(1))]).pipe(
            take(1),
            switchMap(resp => {
                const contacts = resp[0];
                const allContacts = resp[1];
                const formData = new FormData();
                formData.append('thumbnail', avatar);
                return this._httpClient.put(APIV2_BASE_URL + 'Contacts/' + id + '/Avatar', formData).pipe(
                    map((response) => {
                        // Find the updated contact
                        const updatedContact = allContacts.find(c => c.id === id);

                        // Update its avatar
                        updatedContact.avatar = response['avatar'];

                        // Find the index of the updated contact
                        let index = contacts.findIndex(item => item.id === id);

                        // Delete the contact
                        contacts[index] = updatedContact;

                        // Update the contacts
                        this._contacts.next(contacts);

                        // Find the index of the updated contact
                        index = allContacts.findIndex(item => item.id === id);

                        // Delete the contact
                        allContacts[index] = updatedContact;

                        // Update the contacts
                        this._allContacts.next(allContacts);

                        this.userContact$.pipe(
                            take(1),
                            filter(item => item && item.id === id)
                        ).subscribe(() => {
                            // Update the contact if it's selected
                            this._userContact.next(updatedContact);
                        });

                        this.contact$.pipe(
                            take(1),
                            filter(item => item && item.id === id)
                        ).subscribe(() => {
                            // Update the contact if it's selected
                            this._contact.next(updatedContact);
                        });

                        // Return the updated contact
                        return updatedContact;
                    })
                );
            })
        );
    }

    verify(cd: IContactDetail): Observable<any> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            const url = API_BASE_URL + 'users/' + userID + '/contacts/' + cd.parent_id + '/contact_detail/' + cd.id + '/verify';
            return this._httpClient.put(url, '');
        }
        else {
            return throwError(() => 'Not Authenticated');
        }
    }

    verify2(cd: IContactDetail): Observable<any> {
        if (this._authService.loggedIn()) {
            return this._httpClient.post(APIV2_BASE_URL + 'ContactDetails/' + cd.id + '/Verify', '');
        }
        else {
            return throwError(() => 'Not Authenticated');
        }
    }

    smsVerify(cd: IContactDetail, pin: number): Observable<any> {
        if (this._authService.loggedIn()) {
            const url = APIV2_BASE_URL + 'ContactDetails/' +cd.id + '/SmsVerify';
            return this.allContacts$.pipe(
                take(1),
                switchMap((allContacts: Contact[]) => {
                    return this._httpClient.post(url, {smsPin: pin})
                        .pipe(tap(() => {
                            // eslint-disable-next-line eqeqeq
                            const updatedContact = allContacts.filter(contact => contact.id == cd.parent_id)[0];
                            const updatedContactDetail = updatedContact.contactDetails.filter(x => x.id === cd.id)[0];
                            updatedContactDetail.verified = true;
                            const contacts = allContacts.filter(contact => contact.type === 'SECONDARY');
                            this._allContacts.next(allContacts);
                            this._contacts.next(contacts);
                            if (updatedContact.type === 'PRIMARY') {
                                this._userContact.next(updatedContact);
                            }
                        }));
                }));
        }
        else {
            return throwError(() => 'Not Authenticated');
        }
    }
}
