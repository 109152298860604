import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'http-progress-dialog',
    templateUrl: './http-progress-dialog.component.html',
    styleUrls: ['./http-progress-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HttpProgressDialog {

    private _state = 'loading';
    get state(): string {
        return this._state;
    }
    set state(val) {
        this._state = val;
        this.processView();
    }

    loading: boolean;
    success: boolean;
    infoText: string;

    constructor(
        public dialogRef: MatDialogRef<HttpProgressDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.state = _data.state;
    }

    processView(): void {
        if (this.state === 'success') {
            setTimeout(() => {
                this.dialogRef.disableClose = false;
                this.loading = false;
                this.success = true;
                this.infoText = 'Success!';
                this.closeDelayed(500);
            }, 500);
        }
        else if (this.state === 'error') {
            this.dialogRef.disableClose = false;
            this.loading = false;
            this.success = false;
            this.infoText = 'Oops! Something went wrong...\nPlease try again';
        }
        else {
            this.dialogRef.disableClose = true;
            this.loading = true;
            this.success = false;
            this.infoText = 'Loading';
        }
    }

    close(): void {
        const result = this.success ? 'success' : 'error';
        this.dialogRef.close(result);
    }

    closeDelayed(delayMs: number): void {
        setTimeout(() => {
            const result = this.success ? 'success' : 'error';
            this.dialogRef.close(result);
        }, delayMs);
    }
}
