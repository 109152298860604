<!-- <div matDialogTitle fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
        {{ title| translate:translateParams}}
    </span>
</div> -->

<mat-dialog-content class="flex flex-col justify-center items-center">
    <mat-spinner *ngIf="loading" diameter="40">
    </mat-spinner>
    <mat-icon *ngIf="!loading" class="icon-size-12"
              [ngClass]="{'text-primary': success, 'text-warn': !success}"
              [svgIcon]="success?'check_circle':'error_outline'">
    </mat-icon>
    <div class="info-text">
        {{infoText}}
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!loading&&!success" class="flex flex-row justify-center items-center">
    <button mat-button class="warn" (click)="close()" aria-label="Confirm">
        OK
    </button>
</mat-dialog-actions>
