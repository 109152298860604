import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx-republish';
import {Filesystem, Directory} from '@capacitor/filesystem';
import {FileOpener} from '@capacitor-community/file-opener';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {

    constructor() {
    }

    converToXlsx(arrayOfArrays): XLSX.WorkBook {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arrayOfArrays);
        ws['!cols'] = this.fitToColumn(arrayOfArrays);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        return wb;
    }

    downloadXlsx(wb: XLSX.WorkBook, filename: string): void {
        filename = filename.replace(':', '_');
        if (environment.capacitor) {
            const data = XLSX.writeXLSX(wb, {type: 'base64'});
            Filesystem.writeFile({
                data,
                path: filename + '.xlsx',
                directory: Directory.Documents
            }).then((result) => {
                window.alert('File saved to ' + result.uri);
                FileOpener.open({filePath: result.uri, contentType: data.type})
                    .then(() => {
                        window.alert('File saved to ' + result.uri);
                    })
                    .catch(e => {
                        window.alert('File saved to ' + result.uri);
                    });
            }).catch((e) => {
                window.alert(e);
            });
        } else {
            XLSX.writeFile(wb, filename + '.xlsx');
        }
    }

    fitToColumn(arrayOfArray): any[] {
        // get maximum character of each column
        return arrayOfArray[0].map((a, i) => ({wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0))}));
    }

    printSheet(ws: XLSX.WorkSheet): void {
        let windowContent = '<!DOCTYPE html>';
        const html = XLSX.utils.sheet_to_html(ws, {id: 'data-table'});
        windowContent += '<html>' +
            '<head>' +
            '<meta charset="utf-8">' +
            '<meta http-equiv="X-UA-Compatible" content="IE=edge">' +
            '<meta name="viewport" content="width=device-width, initial-scale=1">' +
            '<meta name="description" content="">' +
            '<meta name="author" content="">' +
            '<title>' + ' πηγή: Sensorbase.io</title>' +
            '<style>' +
            '#data-table {' +
            'margin-left: auto;' +
            'margin-right: auto;' +
            '}' +
            '#data-table tr:first-child td {' +
            'background: #3C4252;' +
            'color: white;' +
            'font-weight: bold;' +
            '}' +
            '#data-table td {' +
            'padding: 0 5px;' +
            '}' +
            '#data-table, #data-table th, #data-table td {' +
            'border: 1px solid black;' +
            '}' +
            '</style>' +
            '</head>' +
            '<body onload="window.print()">' +
            html +
            '</body>' +
            '</html>';
        const printWin = window.open('', '', 'width=1500,height=700');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
    }
}
