import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges,
    ViewChild,
    OnDestroy,
    Output,
    EventEmitter
} from '@angular/core';
import {Sensor} from '@sensorbase/models';
import {EChartsOption} from 'echarts';
import {SensorsService} from '@sensorbase/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';
import {AppConfig} from '../../../../app/core/config/app.config';
import {FuseConfigService} from '../../../../@fuse/services/config';

@Component({
    selector: 'sb-current-display',
    templateUrl: './current-display.component.html',
    styleUrls: ['./current-display.component.scss']
})
export class CurrentDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;
    @Input() min = -50;
    @Input() max = 50;
    @Input() type = 'ngx-gauge';
    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        this._size = val;
        if(val === 'xs') {
            this.eChartsGaugeOptions.series[0].axisTick.show = false;
            this.eChartsGaugeOptions.series[0].axisLabel.show = false;
            this.eChartsGaugeOptions.series[0].splitLine.length = 1;
            this.eChartsGaugeOptions.series[0].title.fontSize = 3;
            this.eChartsGaugeOptions.series[0].pointer.width = 12;
            this.eChartsGaugeOptions.series[0].detail.rich.value.fontSize = 25;
            this.eChartsGaugeOptions.series[0].detail.rich.unit.padding[0] = 5;
            this.eChartsGaugeOptions.series[0].detail.offsetCenter[1] = '40%';
        } else {
            this.eChartsGaugeOptions.series[0].axisTick.show = true;
            this.eChartsGaugeOptions.series[0].axisLabel.show = true;
            this.eChartsGaugeOptions.series[0].splitLine.length = 5;
            this.eChartsGaugeOptions.series[0].title.fontSize = 10;
            this.eChartsGaugeOptions.series[0].pointer.width = 12;
            this.eChartsGaugeOptions.series[0].detail.rich.value.fontSize = 30;
            this.eChartsGaugeOptions.series[0].detail.rich.unit.padding[0] = 10;
            this.eChartsGaugeOptions.series[0].detail.offsetCenter[1] = '20%';
        }
        this.updateConfig();
    }

    @Output() hasWarning: EventEmitter<any> = new EventEmitter();

    conditions: any;
    minMaxConditions: any;

    loaded = false;
    eChartsGauge: any;
    eChartsGauseInitOpts: any = {
        renderer: 'svg'
    };
    eChartsGaugeOptions: EChartsOption = {
        tooltip: {
            show: false,
        },
        series: [
            {
                name: 'Current',
                type: 'gauge',
                min: -50,
                max: 50,
                z: 3,
                splitNumber: 5,
                radius: '95%',
                animationDuration: 1000,
                axisLine: {
                    lineStyle: {
                        width: 2,
                        color: [[1, '#000000']]
                    }
                },
                axisTick: {
                    splitNumber: 5,
                    length: 2,
                    distance: 1,
                    lineStyle: {}
                },
                axisLabel: {
                    fontSize: 10,
                    formatter: (value): string => Math.round(value).toString()
                },
                splitLine: {
                    show: false,
                    distance: -7,
                    length: 5,
                },
                title: {
                    fontWeight: 'bolder',
                    fontSize: 10,
                    offsetCenter: [0, '+75%']
                },
                pointer: {
                    show: true,
                    showAbove: false,
                    itemStyle: {
                        color: '#ff0000',
                        borderColor: '#fff',
                    },

                    // Triangle
                    width: 12,
                    length: '-22%',
                    // length: '40%',
                    icon: 'triangle',
                    offsetCenter: [0, '-105%']
                    // offsetCenter: [0, '-57%']

                },
                detail: {
                    align: 'center',
                    valueAnimation: true,
                    formatter: (value): string => {
                        const valueStr = String(value.toFixed(1));
                        if (valueStr.length > 4){
                            return '{value|' + Math.floor(value) + '}\n{unit|Amp}';
                        }
                        return '{value|' + value.toFixed(1) + '}\n{unit|Amp}';
                    },
                    rich: {
                        value: {
                            fontSize: 30,
                            fontWeight: 'normal',
                        },
                        unit: {
                            fontSize: 15,
                            fontWeight: 'lighter',
                            color: '#0',
                            padding: [10, 0, 0, 0]
                        }
                    },
                    offsetCenter: [0, '20%'],
                },
                data: [
                    {
                        value: 0
                    }
                ]
            }
        ]
    };

    ngxGaugeOptions = {
        '-50': {color: 'blue'},
        '-10': {color: 'lightskyblue'},
        '5': {color: 'green'},
        '20': {color: 'orange'},
        '50': {color: 'red'}
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    constructor(
        private _sensorsService: SensorsService,
        private _fuseConfigService: FuseConfigService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.eChartsGaugeOptions.series[0]['data'][0]['value'] = this.sensor.displayData;

        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.checkConditions(this.sensor.displayData);
                    if (this.type === 'eCharts') {
                        this.updateChart(this.sensor.displayData);
                    }
                });
        });
        this._sensorsService.getSensorConditions(this.sensor.sensorId).subscribe();
        this._sensorsService.onSensorConditionsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                const sensorId = data.sensorId;
                const conditions = data.conditions;
                if (sensorId === this.sensor.sensorId){

                    this.loaded = false;
                    this.conditions = conditions;
                    const minMaxConditions = this.conditions.find(
                        (element) => element.conditionType === 'MIN_MAX'
                    ).condition;
                    this.minMaxConditions = minMaxConditions;
                    const diff = minMaxConditions.max - minMaxConditions.min;
                    this.eChartsGaugeOptions.series[0].min = Math.max(0, Math.round(minMaxConditions.min - 0.1 * diff));
                    this.eChartsGaugeOptions.series[0].max = Math.round(minMaxConditions.max + 0.1 * diff);
                    this.updateConfig();
                    this.checkConditions(this.sensor.displayData);
                    this.loaded = true;
                }
            });

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                if (config.scheme === 'dark'){
                    this.eChartsGaugeOptions.series[0].axisLine.lineStyle.color = [[1, '#fff']];
                    this.eChartsGaugeOptions.series[0].detail.rich.unit.color = '#fff';
                    this.eChartsGaugeOptions.series[0].axisLabel.color = '#efefef';
                    this.eChartsGaugeOptions.series[0].axisTick['lineStyle']['color'] = '#efefef';
                } else {
                    this.eChartsGaugeOptions.series[0].axisLine.lineStyle.color = [[1, '#000']];
                    this.eChartsGaugeOptions.series[0].detail.rich.unit.color = '#000';
                    this.eChartsGaugeOptions.series[0].axisLabel.color = undefined;
                    this.eChartsGaugeOptions.series[0].axisTick['lineStyle']['color'] = undefined;
                }
                this.updateConfig();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onChartInit(chartInstance): void {
        this.eChartsGauge = chartInstance;
        // this.updateChart(this.sensor.displayData);
    }

    updateChart(value: any): void {
        this.eChartsGaugeOptions.series[0]['data'][0]['value'] = value;
        this.updateConfig();
    }

    updateConfig(): void {
        if (this.eChartsGauge){
            setTimeout(() => {
                this.eChartsGauge.setOption(this.eChartsGaugeOptions, true);
            });
        }
    }

    checkConditions(value: any): void {
        if (this.minMaxConditions){
            if (value > this.minMaxConditions.max){
                this.hasWarning.next({
                    type: 'max',
                    message: 'Υψηλή Τάση Ρεύματος'
                });
            } else if (value < this.minMaxConditions.min){
                this.hasWarning.next({
                    type: 'min',
                    message: 'Χαμηλή Τάση Ρεύματος'
                });
            } else {
                this.hasWarning.next(null);
            }
        }
    }
}
