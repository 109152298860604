<mat-table #table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
        <mat-header-cell class="primary" *matHeaderCellDef fxFlex="90px">
            <mat-checkbox color="primary" labelPosition="before" [checked]="mainChecked" [indeterminate]="mainIndeterminate"
                          [disabled]="!object.notifications" (change)="onCheckboxChange('MAIN', $event)">
                <!-- ON-OFF -->
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink" fxFlex="90px">
            <mat-slide-toggle color="primary" (change)="processToggle('MAIN', contactLink);"
                              [(ngModel)]="contactLink.contactEnabled" [disabled]="!object.notifications"
                              (click)="onContactLinkChanged(contactLink)">
            </mat-slide-toggle>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell class="primary" *matHeaderCellDef>
            {{'CONTACT.NAME' | transloco}}
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink"
                  [matTooltip]="getContactEmail(contactLink)+'&#13;'+getContactPhone(contactLink)"
                  matTooltipClass="line-broken-tooltip">
            <!-- <img class="avatar" *ngIf="contact.avatar" [alt]="contact.name" [src]="contact.avatar"
                (click)="editContact(contact)"> -->
            <p class="text-truncate font-weight-600">{{contactLink.name}}
            </p>
        </mat-cell>
    </ng-container>

    <!-- SMS Column -->
    <ng-container matColumnDef="sms">
        <mat-header-cell class="primary" *matHeaderCellDef fxHide fxShow.gt-xs>
            <mat-checkbox color="primary" [checked]="smsChecked" [indeterminate]="smsIndeterminate"
                          (change)="onCheckboxChange('SMS', $event)">
                SMS
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink" fxHide fxShow.gt-xs>
            <ng-container *ngIf="contactLink.smsVerified">
                <mat-slide-toggle color="primary" [(ngModel)]="contactLink.smsEnabled"
                                  [disabled]="!contactLink.contactEnabled"
                                  (change)="processToggle('SMS', contactLink)">
                </mat-slide-toggle>
                <button type="button" mat-button mat-icon-button [matTooltip]="'GENERIC.Settings' | transloco"
                        (click)="editMultiSelection(contactLink, 'SMS')">
                    <mat-icon matBadgeHidden="{{getActiveDetails(contactLink,'SMS').length==0}}"
                              matBadge="{{getActiveDetails(contactLink,'SMS').length}}"
                              matBadgeColor="info" svgIcon="more_vert">
                    </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="!contactLink.smsVerified">
                <button mat-icon-button type="button" color="warn"
                        (click)="openContactVerifyDialog(contactLink, 'MOBILE')">
                    <mat-icon svgIcon="warning"></mat-icon>
                </button>
            </ng-container>
            <!-- <multi-selection-list [selectionItems]="notificationTypes"></multi-selection-list> -->
        </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
        <mat-header-cell class="primary" *matHeaderCellDef fxHide fxShow.gt-xs>
            <mat-checkbox color="primary" [checked]="emailChecked" [indeterminate]="emailIndeterminate"
                          (change)="onCheckboxChange('EMAIL', $event)">
                {{'CONTACT.EMAIL' | transloco}}
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink; let i = index" fxHide fxShow.gt-xs>
            <ng-container *ngIf="contactLink.emailVerified">
                <mat-slide-toggle color="primary" [(ngModel)]="contactLink.emailEnabled"
                                  [disabled]="!contactLink.contactEnabled"
                                  (change)="processToggle('EMAIL', contactLink)">
                </mat-slide-toggle>
                <button type="button" mat-button mat-icon-button [matTooltip]="'GENERIC.Settings' | transloco"
                        (click)="editMultiSelection(contactLink, 'EMAIL')">
                    <mat-icon matBadgeHidden="{{getActiveDetails(contactLink,'EMAIL').length==0}}"
                              matBadge="{{getActiveDetails(contactLink,'EMAIL').length}}"
                              matBadgeColor="info" svgIcon="more_vert">
                    </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="!contactLink.emailVerified">
                <button mat-icon-button type="button" color="warn"
                        (click)="openContactVerifyDialog(contactLink, 'EMAIL')">
                    <mat-icon svgIcon="warning"></mat-icon>
                </button>
            </ng-container>
            <!-- <multi-selection-list [selectionItems]="notificationTypes"></multi-selection-list> -->
        </mat-cell>
    </ng-container>

    <!-- Phone Column -->
<!--    <ng-container matColumnDef="phone">-->
<!--        <mat-header-cell class="primary" *matHeaderCellDef fxHide>-->
<!--            {{'CONTACT.PHONE' | transloco}}-->
<!--            <mat-checkbox>-->
<!--            </mat-checkbox>-->
<!--        </mat-header-cell>-->
<!--        <mat-cell *matCellDef="let contactLink" fxHide>-->
<!--            <mat-slide-toggle>-->
<!--            </mat-slide-toggle>-->
<!--            <multi-selection-list [selectionItems]="notificationTypes"></multi-selection-list>-->
<!--        </mat-cell>-->
<!--    </ng-container>-->

    <!-- Delay Column -->
    <ng-container matColumnDef="delay">
        <mat-header-cell class="primary" *matHeaderCellDef fxHide fxShow.gt-xs>
            {{'NOTIFICATIONS.SETTINGS.DELAY_MINI' | transloco}}
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink" fxHide fxShow.gt-xs>
            <increment-input [(ngModel)]="contactLink.delay" [step]="1" [min]="0" [max]="10" [wrap]="true"
                             [label]="'NOTIFICATIONS.SETTINGS.MINUTES'| transloco"
                             [tooltip]="'TOOLTIPS.NOTIFICATION_SETTINGS.DELAY'| transloco"
                             (change1)="onContactLinkChanged(contactLink)">
            </increment-input>
        </mat-cell>
    </ng-container>

    <!-- More Column -->
    <ng-container matColumnDef="more">
        <mat-header-cell class="primary" *matHeaderCellDef fxHide.gt-xs>
        </mat-header-cell>
        <mat-cell *matCellDef="let contactLink" fxHide.gt-xs>
            <button type="button" mat-button mat-icon-button matTooltip="More"
                    (click)="openMiniSelectionDialog(contactLink)">
                <mat-icon svgIcon="more_vert">
                </mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="bg-gray-50 dark:bg-black dark:bg-opacity-5 shadow"></mat-header-row>
    <mat-row *matRowDef="let contactLink; columns: displayedColumns;" class="contact"
             [@animate]="{value:'*',params:{y:'100%'}}">
    </mat-row>

</mat-table>

<mat-paginator [pageSizeOptions]="[3, 5, 15, 25]"></mat-paginator>
