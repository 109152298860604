<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SENSOR_WIDGET.SETTINGS_TYPE.TIMERS' | transloco}}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0"
            [ngClass]="{'success': sensor.status>0, 'error': sensor.status<=0}">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <div *ngIf="!loaded" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>

        <div class="timer-list m-2 mb-0 rounded-lg" *ngIf="loaded">
            <mat-table #table [dataSource]="dataSource">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="on-off">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        ON-OFF
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;"
                              [attr.data-label]="'SENSOR.TIMERS.ACTIVE' | transloco">
                        <mat-slide-toggle [(ngModel)]="row.active" (change)="toggleChange($event,row)"
                                          [disabled]="row.action === 'add'"
                                          [matTooltip]="'TOOLTIPS.TIMERS.ROW_ACTION' | transloco"
                                          [matTooltipDisabled]="row.action !== 'add'">
                        </mat-slide-toggle>
                    </mat-cell>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        {{'GENERIC.Name' | transloco}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [attr.data-label]="'GENERIC.Name' | transloco">
                        <mat-form-field class="fuse-mat-no-subscript">
                            <input matInput [placeholder]="'GENERIC.Name' | transloco"
                                   [(ngModel)]="row.name" (change)="updateRowState(row)">
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <!-- Settings Column -->
                <ng-container matColumnDef="duration">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        {{'SENSOR.TIMERS.DURATION' | transloco}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [attr.data-label]="'SENSOR.TIMERS.DURATION' | transloco">
                        <mat-form-field class="fuse-mat-no-subscript"
                                        [matTooltip]="'TOOLTIPS.TIMERS.ROW_ACTIVE' | transloco"
                                        [matTooltipDisabled]="!row.active">
                            <input matInput [placeholder]="'SENSOR.TIMERS.HOURS_MINUTES' | transloco"
                                   [ngxTimepicker]="fullTime" [format]="24"
                                   [(ngModel)]="row.duration" [disabled]="row.active" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker (timeChanged)="updateRowState(row)" #fullTime
                                                 [enableKeyboardInput]="true"
                                                 [editableHintTmpl]="dialHint">
                        </ngx-material-timepicker>
                        <ng-template #dialHint>
                            <p>{{'SENSOR.TIMERS.HOURS_MINUTES' | transloco}}</p>
                        </ng-template>
                        <!-- </div> -->
                    </mat-cell>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="state">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        {{'SENSOR.TIMERS.TARGET' | transloco}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;"
                              [attr.data-label]="'SENSOR.TIMERS.TARGET' | transloco">
                        <ui-switch class="pl-4" size="medium" [(ngModel)]="row.value" checkedLabel="ON "
                                   uncheckedLabel="OFF" [disabled]="row.active" defaultBgColor="#c5c6cb7"
                                   (change)="updateRowState(row)">
                        </ui-switch>
                    </mat-cell>
                </ng-container>

                <!-- Star Time Column -->
                <ng-container matColumnDef="start-time">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                        {{'SENSOR.TIMERS.STARTED' | transloco}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [attr.data-label]="'SENSOR.TIMERS.STARTED' | transloco">
                        <p>{{row.runAt | date:'dd/MM/yy HH:mm'}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="delete">
                    <mat-header-cell class="primary" *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;">
                        <button *ngIf="!isSmallScreen" type="button" mat-icon-button color="warn"
                                (click)="deleteRow(row, i)"
                                [matTooltip]="'TOOLTIPS.TIMERS.DELETE' | transloco" [disabled]="row.active">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                        <button *ngIf="isSmallScreen" type="button" mat-flat-button color="warn"
                                (click)="deleteRow(row, i)"
                                [disabled]="row.active">
                            <mat-icon svgIcon="delete"></mat-icon>
                            {{'TOOLTIPS.TIMERS.DELETE_MINI' | transloco}}
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" [id]="'timer'+i"></mat-row>
            </mat-table>
        </div>

        <div class="m-3" *ngIf="loaded && !isSmallScreen" fxLayout="row-reverse" fxLayoutAlign="start start">
            <button mat-flat-button color="warn" type="button" class="add-timer-button" (click)="addRow()">
                <mat-icon svgIcon="add"></mat-icon> {{'TOOLTIPS.TIMERS.ADD' | transloco}}
            </button>
        </div>

        <button *ngIf="loaded && isSmallScreen" mat-mini-fab color="warn" type="button" class="add-timer-button"
                (click)="addRow()" [matTooltip]="'TOOLTIPS.TIMERS.ADD_MINI' | transloco">
            <mat-icon svgIcon="add"></mat-icon>
        </button>

        <div *ngIf="show" class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div class="flex flex-row justify-between items-center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    {{'HELP.SENSOR.TIMER_DIALOG.INFO' | transloco }}
                </div>
            </div>
        </div>

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button class="mr-2" type="submit" mat-flat-button color="primary" (click)="onSave()">
                {{'GENERIC.Save' | transloco }}
            </button>
            <button class="ml-2" type="button" mat-stroked-button color="warn" (click)="onCancel()">
                {{'GENERIC.Cancel' | transloco }}
            </button>
        </div>

        <div class="relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>

    </div>
</div>
