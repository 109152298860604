<!--suppress TypeScriptValidateTypes -->
<div *ngIf="alerts" class="fixed bottom-1 w-76 pr-2.5 flex flex-col justify-center">
    <fuse-alert class="m-1" *ngFor="let alert of alerts"
                [name]="alert.guid"
                [appearance]="'border'"
                [dismissible]="true"
                [dismissed]="false"
                [type]="alert.type"
                (dismissedChanged) = "onDismiss($event, alert)">
        <span fuseAlertTitle *ngIf="alert.title !== ''" class="truncate">{{alert.title | transloco}}</span>
        {{alert.body | transloco}}
    </fuse-alert>
</div>
