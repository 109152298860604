import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { Sensor, Dashboard, Device } from '@sensorbase/models';
import { DashboardsService } from '@sensorbase/services';
import {DashboardsNewDashboardDialog} from '@sensorbase/components';

@Component({
    selector: 'sensor-board-dialog',
    templateUrl: './sensor-board-dialog.component.html',
    styleUrls: ['./sensor-board-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SensorBoardsDialog implements OnInit, OnDestroy {
    @ViewChild('boards', { static: false })
    dialogContent: TemplateRef<any>;

    dataSource: SensorBoardsListDataSource;
    displayedColumns: string[] = ['checkbox', 'name', 'sensors', 'add'];
    selection = new SelectionModel<Dashboard>(true, []);

    boards: Dashboard[];
    device: Device;
    sensor: Sensor;

    public show = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param dialogRef
     * @param _dashboardsService
     * @param _matDialog
     * @param data
     */
    constructor(
        public dialogRef: MatDialogRef<SensorBoardsDialog>,
        private _dashboardsService: DashboardsService,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.sensor = data.sensor;
        this.device = data.device;

        this.dataSource = new SensorBoardsListDataSource(this._dashboardsService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource.onBoardsLoaded
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(boards => {
                this.boards = boards;
                boards.forEach(dashboard => {
                    const member = dashboard.members.find((item) => item.id === this.sensor.sensorId);
                    if (member) {
                        this.selection.toggle(dashboard);
                    }
                });
                // this.boards = boards;
                console.log(this.boards);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Dashboard): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    onSave(): void {
        for (const selection of this.selection.selected) {
            const member = selection.members.find((item) => item.id === this.sensor.sensorId);
            if (!member) {
                const widget = {
                    type: 'sensor',
                    id: this.sensor.sensorId
                };
                selection.members.push(widget);
                this._dashboardsService.updateBoard(selection).subscribe();
            }
            console.log(selection);
        }
        for (const dashboard of this.boards) {
            const member = dashboard.members.find((item) => item.id === this.sensor.sensorId);
            if (member && !this.selection.selected.includes(dashboard)) {
                const index: number = dashboard.members.indexOf(member);
                dashboard.members.splice(index, 1);
                this._dashboardsService.updateBoard(dashboard).subscribe();
            }
        }
        this.dialogRef.close();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    toogle(): void {
        this.show = !this.show;
    }

    newBoard(): void {
        const dialogRef = this._matDialog.open(DashboardsNewDashboardDialog, {
            panelClass: 'new-dashboard-dialog',
            restoreFocus: false,
            maxWidth: '90%',
            data: {
            }
        });

        dialogRef.afterClosed()
            .subscribe(response => {
                console.log(response);
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const contactRes: Dashboard = response[1];

                console.log(actionType);
                this._dashboardsService.createNewBoard(contactRes).subscribe();
            });
    }

}

export class SensorBoardsListDataSource extends DataSource<Dashboard>
{
    public data: Dashboard[];
    // Private
    public onBoardsLoaded: BehaviorSubject<any>;
    subscription: Subscription;
    /**
     * Constructor
     *
     * @param _dashboardsService
     */
    constructor(
        private _dashboardsService: DashboardsService
    ) {
        super();
        this.onBoardsLoaded = new BehaviorSubject([]);
        this.subscription = this._dashboardsService.dashboards$
            .subscribe(dashboards => {
                this.data = dashboards;
                this.onBoardsLoaded.next(dashboards);
            });
        // Set the private defaults
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns
     */
    connect(): Observable<any[]> {
        return this.onBoardsLoaded;
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }
}
