import {BaseModel} from './base.model';

export class Dashboard extends BaseModel {
    id: string;
    name: string;
    favourites: boolean;
    settings: {
        color: string;
        icon: string;
        subscribed: boolean;
    };
    position: number;
    members: any[];
    userId: string;
    location: any;
    tags: any[];

    /**
     * Constructor
     *
     * @param dashboard
     */
    constructor(dashboard) {
        super();
        this.userId = dashboard.userId || '';
        this.name = dashboard.name || '';
        this.id = dashboard.id || 0;
        this.favourites = dashboard.favourites || false;
        this.position = dashboard.position || 2;
        if (dashboard.members) {
            this.members = Object.assign([], dashboard.members);
        }
        else {
            this.members = [];
        }
        if (dashboard.settings) {
            this.settings = Object.assign({}, dashboard.settings);
        }
        else {
            this.settings = {
                color: '',
                icon: 'dashboard',
                subscribed: false
            };
        }
        this.location = dashboard.location || {};
        this.tags = dashboard.tags || [];
    }

    static fromApi(dataApi): any {
        const data = dataApi;
        const members = [];
        data['members'].forEach(m => {
            members.push(JSON.parse(m));
        });
        data['members'] = members;
        data['settings'] = JSON.parse(data['settings']);
        data['location'] = JSON.parse(data['location']);
        // @ts-ignore
        return new this(data);
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            if (key === 'members') {
                const members = [];
                value.forEach(v => {
                    members.push(JSON.stringify(v));
                });
                dataApi[key] = members;
            } else if (key === 'settings' || key === 'location') {
                dataApi[key] = JSON.stringify(value);
            } else {
                dataApi[key] = value;
            }
        });
        return dataApi;
    }
}
