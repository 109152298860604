import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

import { FormGroup, FormControl } from '@angular/forms';
import { ContactsService } from '@sensorbase/services';
import { IContactDetail } from '@sensorbase/models';
import {HttpProgressDialog} from '../http-progress-dialog/http-progress-dialog.component';

@Component({
    selector: 'app-contact-verification-dialog',
    templateUrl: './contact-verification-dialog.component.html',
    styleUrls: ['./contact-verification-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactVerificationDialog {
    type: string;
    contactDetail: IContactDetail;
    title: string;
    step1Title: string;
    step1Text: string;
    step1Send: string;
    step2Title: string;
    step2Text: string;
    step2Send: string;
    step2Verify: string;
    step2Success: string;
    step3Title: string;
    step3Text: string;
    step3Success: string;

    formGroup: FormGroup;

    step = 1;

    verified = false;

    @ViewChild('stepper', { static: false }) stepper;

    /**
     * Constructor
     *
     * @param _contactService
     * @param dialogRef
     * @param _matDialog
     * @param _data
     */
    constructor(
        private _contactService: ContactsService,
        public dialogRef: MatDialogRef<ContactVerificationDialog>,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.type = _data.type;
        if (this.type === 'PHONE' || this.type === 'MOBILE') {
            this.title = 'CONTACT_VERIFICATION_DIALOG.PHONE.TITLE';
            this.step1Title = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP1.TITLE';
            this.step1Text = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP1.TEXT';
            this.step1Send = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP1.SEND';
            this.step2Title = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP2.TITLE';
            this.step2Text = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP2.TEXT';
            this.step2Send = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP2.SEND';
            this.step2Verify = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP2.VERIFY';
            this.step3Title = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP3.TITLE';
            this.step3Text = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP3.TEXT';
            this.step3Success = 'CONTACT_VERIFICATION_DIALOG.PHONE.STEP3.SUCCESS';
        } else {
            this.title = 'CONTACT_VERIFICATION_DIALOG.EMAIL.TITLE';
            this.step1Title = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP1.TITLE';
            this.step1Text = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP1.TEXT';
            this.step1Send = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP1.SEND';
            this.step2Title = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP2.TITLE';
            this.step2Text = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP2.TEXT';
            this.step2Success = 'CONTACT_VERIFICATION_DIALOG.EMAIL.STEP2.SUCCESS';
        }
        this.contactDetail = _data.contactDetail;
        this.formGroup = new FormGroup({
            pin: new FormControl()
        });
    }

    onCancel(): void {
        // console.log(this.selection);
        if (this.verified) {
            this.dialogRef.close(true);
        }
        else {
            this.dialogRef.close();
        }
    }

    sendVerification(): void {
        this._contactService.verify(this.contactDetail).subscribe(
            {
                next: () => {
                    this.step += 1;
                },
                error: (error) => {
                    switch (error.status) {
                        case (405): {
                            console.log('[ERROR]: Contact detail already verified!!!');
                            break;
                        }
                        case (409): {
                            console.log('[ERROR]: Verification request already exists!!!');
                            break;
                        }
                    }
                }
            });
    }

    sendVerificationv2(): void {
         const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        this._contactService.verify2(this.contactDetail).subscribe(
            {
                next: () => {
                    httpProgressDialog.componentInstance.state = 'success';
                    httpProgressDialog.afterClosed().subscribe(() => {
                        this.step += 1;
                    });
                },
                error: (error) => {
                    httpProgressDialog.componentInstance.state = 'error';
                    switch (error.error) {
                        case (405): {
                            console.log('[ERROR]: Contact detail already verified!!!');
                            break;
                        }
                        case ('Existing Verify Request'): {
                            console.log('[ERROR]: Verification request already exists!!!');
                            this.step += 1;
                            break;
                        }
                    }
                }
            });
    }

    sendVerificationPin(): void {
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });
        this._contactService.smsVerify(this.contactDetail, this.formGroup.value.pin).subscribe(
            {
                next: () => {
                    this.verified = true;
                    httpProgressDialog.componentInstance.state = 'success';
                    httpProgressDialog.afterClosed().subscribe(() => {
                        this.dialogRef.close();
                    });
                },
                error: (error) => {
                    httpProgressDialog.componentInstance.state = 'error';
                    switch (error.status) {
                        case (405): {
                            console.log('[ERROR]: Contact detail already verified!!!');
                            break;
                        }
                        case (409): {
                            console.log('[ERROR]: Verification request already exists!!!');
                            break;
                        }
                    }
                }
            });
    }

}
