import {Component, Input, forwardRef, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
    selector: 'increment-input',
    templateUrl: './increment-input.component.html',
    styleUrls: ['./increment-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IncrementInputComponent),
            multi: true
        }
    ]
})

export class IncrementInputComponent implements ControlValueAccessor {

    @Input('value')
    set inputValue(_value: number) {
        this._value = this.parseNumber(_value);
    }

    @Input()
    set step(_step: number) {
        this._step = this.parseNumber(_step);
    }

    @Input()
    set min(_min: number) {
        this._min = this.parseNumber(_min);
    }

    @Input()
    set max(_max: number) {
        this._max = this.parseNumber(_max);
    }

    @Input()
    set wrap(_wrap: boolean) {
        this._wrap = this.parseBoolean(_wrap);
    }

    @Input()
    set label(_label: string) {
        this._label = _label;
    }

    @Input()
    set tooltip(_tooltip: string) {
        this._tooltip = _tooltip;
    }

    @Input() appearance: MatFormFieldAppearance = 'legacy';

    @Output() change1: EventEmitter<any> = new EventEmitter<any>();

    myFormGroup = new FormGroup({
        formField: new FormControl()
    });

    _nullFlag = false;  // Null flag is used to signal when user types only +/-
    _value = 0;
    _step = 1;
    _min = 0;
    _max = Infinity;
    _wrap = false;
    _label = '';
    _tooltip = '';
    color = 'default';


    constructor(private cd: ChangeDetectorRef) { }

    onChange: any = () => {};
    onTouched: any = () => { };

    get value(): number {
        return this._value;
    }

    set value(val) {
        if (val !== null){
            this._nullFlag = false;
            this._value = this.parseNumber(val);
            if (this._value > this._max) {
                this._value = this._max;
            } else if (this._value < this._min){
                this._value = this._min;
            }
            this.onChange(this._value);
            this.change1.emit();
            this.onTouched();
        } else {
            // This will be run when the user has typed an illegal number (e.g. +/-)
            // (see onFocusOut for how this is handled when the input component loses focus)
            this._nullFlag = true;
        }
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    writeValue(value): void {
        if (value != null) {
            this.value = value;
        }
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    private parseNumber(num: any): number {
        return +num;
    }

    private parseBoolean(bool: any): boolean {
        return !!bool;
    }

    setColor(color: string): void {
        this.color = color;
    }

    getColor(): string {
        return this.color;
    }

    incrementValue(step: number = 1): void {
        // console.log(this.value);
        // console.log(step);
        let inputValue = this.value + step;
        if (this.isFloat(inputValue)) {
            inputValue = +inputValue.toPrecision(4);
        }
        // console.log(inputValue);
        if (this._wrap) {
            inputValue = this.wrappedValue(inputValue);
        }
        this.value = inputValue;
        // console.log(this.value);
    }

    private wrappedValue(inputValue): number {
        if (inputValue > this._max) {
            return this._min;
        }

        if (inputValue < this._min) {
            if (this._max === Infinity) {
                return 0;
            }
            return this._max;
        }
        return inputValue;
    }

    shouldDisableDecrement(inputValue: number): boolean {
        return !this._wrap && inputValue <= this._min;
    }

    shouldDisableIncrement(inputValue: number): boolean {
        return !this._wrap && inputValue >= this._max;
    }

    isFloat(n): boolean {
        return Number(n) === n && n % 1 !== 0;
    }

    onFocusOut(): void {
        if (this._nullFlag){
            // If the null flag is set when the input component loses focus then:
            this.value = 0;  // Set the value to 0
            this.incrementValue(0.1);  // Increment by 0.1
            this.cd.detectChanges();  // Force angular to detect the change (and instantly update the view)
            this.incrementValue(-0.1); // Decrement by 0.1
            this._nullFlag = false;  // Unset the null flag

            // NOTE:
            // Use of cd.detectChanges above is necessary for cases where the last valid value was 0
            // In this case if we simply set the new value to 0 again, angular will not detect the change
            // and therefore will not update the value in the input component
        }
    }
}
