export interface ISensorChartOptions {
    showDatePicker?: boolean;
    showTitle?: boolean;
    leftMargin?: number|string;
    bottomMargin?: number|string;
    showDataZoom?: boolean;
    height?: number|string;
}

export class SensorChartOptions implements ISensorChartOptions {
    showDatePicker?: boolean;
    showTitle?: boolean;
    leftMargin?: number|string;
    bottomMargin?: number|string;
    showDataZoom?: boolean;
    height?: number|string;

    constructor(opts?: ISensorChartOptions) {
        opts = opts || {};
        this.showDatePicker = opts.showDatePicker === undefined ? true : opts.showDatePicker;
        this.showTitle = opts.showTitle === undefined ? true : opts.showTitle;
        this.leftMargin = opts.leftMargin || null;
        this.bottomMargin = opts.bottomMargin || null;
        this.showDataZoom = opts.showDataZoom === undefined ? true : opts.showDataZoom;
        this.height = opts.height || '400px';
    }
}
