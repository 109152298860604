<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'HELP.SENSOR.INFORMATION_DIALOG.DIALOG_TITLE' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- DIALOG CONTENT -->
    <div>
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
        'success': sensor.status>0,
        'error': sensor.status<=0
         }">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <div class="flex flex-col justify-center items-center sm:flex-row sm:flex-wrap sm:justify-between sm:items-start ">
            <div class="flex flex-row justify-between items-center p-4">
                <!-- <mat-icon>info</mat-icon> -->
                <ul class="list-disc pl-10">
                    <li>
                        {{'HELP.SENSOR.INFORMATION_DIALOG.DASHBOARDS' | transloco }}
                    </li>
                    <button class="pl-12" mat-icon-button color="accent" style="font-size: 12px"
                            (click)="openBoards()">
                        <mat-icon svgIcon="dashboard"></mat-icon>
                        {{'SENSOR_WIDGET.EDIT_BOARDS' | transloco }}
                    </button>

                    <li>
                        {{'HELP.SENSOR.INFORMATION_DIALOG.NOTIFICATIONS' | transloco }}
                    </li>
                    <button mat-icon-button (click)="toggleNotifications()"
                                   matTooltip="{{(sensor.notifications? 'SENSOR_WIDGET.NOTIFICATIONS.TURN_OFF': 'SENSOR_WIDGET.NOTIFICATIONS.TURN_ON') |transloco}}"
                                   matTooltipPosition="below" aria-label="Toggle notifications">
                    <mat-icon [ngClass]="{'green-800-fg': sensor.notifications, 'red-500-fg': !sensor.notifications}"
                              [svgIcon]="sensor.notifications ? 'notifications' :'notifications_off'">
                    </mat-icon>
                    <ng-container *ngIf="sensor.notifications"><strong class="green-fg"><em>
                        {{'HELP.SENSOR.INFORMATION_DIALOG.NOTIFICATIONS_BUTTON_ON' | transloco }}
                    </em></strong></ng-container>
                    <ng-container *ngIf="!sensor.notifications"><strong class="red-fg"><em>
                        {{'HELP.SENSOR.INFORMATION_DIALOG.NOTIFICATIONS_BUTTON_OFF' | transloco }}
                    </em></strong></ng-container>
                    </button>

                    <li> <div [innerHTML]="'HELP.SENSOR.INFORMATION_DIALOG.SETTINGS' | transloco"></div>
                        <ul>
                            <li [innerHTML]="'HELP.SENSOR.INFORMATION_DIALOG.SETTINGS_2' | transloco">
                            </li>
                        </ul>
                    </li>
                    <button class="pl-12" mat-icon-button color="accent" style="font-size: 12px"
                            (click)="openSettings()">
                        <mat-icon svgIcon="settings"></mat-icon>
                        {{'GENERIC.Settings' | transloco }}
                    </button>

                    <li [innerHTML]="'HELP.SENSOR.INFORMATION_DIALOG.CHART' | transloco">
                    </li>
                    <button class="pl-12" mat-icon-button color="accent" style="font-size: 12px"
                            (click)="openChart()">
                        <mat-icon svgIcon="insert_chart_outlined"></mat-icon>
                        {{'SENSOR_WIDGET.CHART.SHOW' | transloco }}
                    </button>

                    <li>
                        {{'HELP.SENSOR.INFORMATION_DIALOG.DELETE' | transloco }}
                    </li>
                    <button mat-icon-button color="warn" (click)="onDeleteButtonClicked()"
                            matTooltip="Διαγραφή Αισθητήρα">
                        <mat-icon svgIcon="delete"></mat-icon>
                        {{'SENSOR_WIDGET.DELETE_SENSOR_DIALOG.TITLE' | transloco }}
                    </button>
                </ul>
            </div>
        </div>
        <!-- /DIALOG CONTENT -->

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button type="button" mat-raised-button color="warn" (click)="onCancel()">
                {{'GENERIC.Close' | transloco }}
            </button>
        </div>
    </div>

</div>
