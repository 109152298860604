import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import {SensorsService} from '@sensorbase/services';
import {Device, Sensor} from '@sensorbase/models';
import {
    EventGroup,
    Schedule,
    SensorAction,
    SensorAutomationService,
    SensorEvent,
    SensorFunction
} from '../sensor-automation-service.service';
import {HttpProgressDialog} from '@sensorbase/components';
import {TranslocoService} from '@ngneat/transloco';
import {SbSensorUtils} from '@sensorbase/utils';
import {SensorType} from '@sensorbase/config';
import {DateTime} from 'luxon';

export interface Option {
    value: any;
    viewValue: string;
}

export interface SensorOption {
    value: string;
    viewValue: string;
}

interface SensorGroup {
    disabled?: boolean;
    name: string;
    sensors: SensorOption[];
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            isSubmitted
        );
    }

    getRootParent(control): FormGroup {
        if (!control.parent) {
            return control;
        }
        return this.getRootParent(control.parent);
    }
}

@Component({
    selector: 'relay-schedules-dialog',
    templateUrl: './relay-schedules-dialog.component.html',
    styleUrls: ['./relay-schedules-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RelaySchedulesDialogComponent implements OnInit {
    userAccount = 'Pro';
    function: SensorFunction;
    sensor: Sensor;
    device: Device;
    sensors: Sensor[] = [];
    sensorGroups: SensorGroup[] = [];
    defaultPosition: string;
    actions: SensorAction[];

    sensorsLoaded = false;
    submitted = false;

    linkTypes: Option[] = [
        {value: 'and', viewValue: 'Και'},
        {value: 'or', viewValue: 'Ή'}
    ];

    categories: Option[] = [
        {value: 'schedule', viewValue: 'Απλό Χρονοδιάγραμμα'},
        {value: 'sensor', viewValue: 'Μόνο Γεγονότα'},
        {value: 'both', viewValue: 'Και με τα δύο'}
    ];

    criterions: Option[] = [
        {value: '>', viewValue: '>'},
        {value: '<', viewValue: '<'},
        {value: '==', viewValue: '='}
    ];

    relayValues: Option[] = [
        {value: 1, viewValue: 'ON'},
        {value: 0, viewValue: 'OFF'}
    ];

    doorValues: Option[] = [
        {value: 1, viewValue: 'Ανοιχτή'},
        {value: 0, viewValue: 'Κλειστή'}
    ];

    displayedColumns = ['id', 'event'];

    showHelp = false;

    form: FormGroup;
    actionsForm: FormGroup;
    matcher = new MyErrorStateMatcher();

    /**
     * Constructor
     *
     * @param _sensorService
     * @param _automationService
     * @param _matDialog
     * @param matDialogRef
     * @param formBuilder
     * @param _translateService
     * @param data
     */
    constructor(
        private _sensorService: SensorsService,
        private _automationService: SensorAutomationService,
        public _matDialog: MatDialog,
        public matDialogRef: MatDialogRef<RelaySchedulesDialogComponent>,
        private formBuilder: FormBuilder,
        private _translateService: TranslocoService,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {

        this.sensor = data.sensor;
        this.device = data.device;
        this.function = SensorFunction.fromApi(data.function.toApi());
        console.log(this.function);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    get eventGroupsFormArray(): FormArray {
        return this.form.get('eventGroups') as FormArray;
    }

    ngOnInit(): void {
        this.form = this.function.toFormGroup();
        this.actionsForm = this.formBuilder.group({
            action: new FormGroup({
                enabled: new FormControl(false),
                relay_state: new FormControl(false)
            }),
            inactiveAction: new FormGroup({
                enabled: new FormControl(false),
                relay_state: new FormControl(false)
            })
        });
        if (this.function.actions.length > 0) {
            this.actionsForm.get('action').get('enabled').setValue(true);
            this.actionsForm.get('action').get('relay_state').setValue(
                this.function.actions[0].name === 'ON'
            );
        }
        if (this.function.inactiveActions.length > 0) {
            this.actionsForm.get('inactiveAction').get('enabled').setValue(true);
            this.actionsForm.get('inactiveAction').get('relay_state').setValue(
                this.function.inactiveActions[0].name === 'ON'
            );
        }

        console.log(this.form);
        this.defaultPosition = this.sensor.options.default_value ?? 'ON';
        this._sensorService.getSensors()
            .subscribe(sensors => {
                this.sensors = sensors;
                const types = [];
                Object.values(SensorType).forEach(value => {
                    types.push(value);
                });
                types.forEach(type => {
                    const group = this.getSensorGroup(type);
                    if (group) {
                        this.sensorGroups.push(group);
                    }
                });
                this.sensorsLoaded = true;
            });
        this._automationService._getSensorActions(this.sensor.sensorId).toPromise()
            .then(response => {
                this.actions = response.map(x => new SensorAction(x));
                console.log(this.function);
            });
    }

    getSensorGroup(type): SensorGroup | null {
        const sensors = this.sensors.filter(sensor => sensor.type === type);
        if (sensors.length > 0) {
            const sensorOptions: SensorOption[] = [];
            sensors.forEach(sensor => {
                sensorOptions.push({
                    value: sensor.sensorId,
                    viewValue: sensor.sensorName
                });
            });
            return {
                name: 'SENSOR.TYPES.' + type,
                sensors: sensorOptions
            };
        } else {
            return null;
        }

    }

    getSensorTypeFromId(sensorId): SensorType {
        const sensor = this.sensors.find(x => x.sensorId === sensorId);
        return sensor.type;
    }

    hasSensorValue(sensorId): boolean {
        const type = this.getSensorTypeFromId(sensorId);
        return SbSensorUtils.isMinMaxSensorType(type);
    }

    toggleAction(type: string): void {
        this.actionsForm.markAllAsTouched();
        if (type === 'active') {
            this.actionsForm.get('action').get('enabled').setValue(!this.actionsForm.get('action').get('enabled').value);
        }
        else {
            this.actionsForm.get('inactiveAction').get('enabled').setValue(!this.actionsForm.get('inactiveAction').get('enabled').value);
        }
    }

    useSchedule(type): void {
        this.function.schedule.type = type;
        if (this.function.schedule.apiAction !== 'add') {
            this.function.schedule.apiAction = 'update';
        }
        if (type === 'FULL') {
            if (this.function.schedule.months === undefined) {
                this.function.schedule.months = [];
            }
            if (this.function.schedule.dayOfWeek === undefined) {
                this.function.schedule.dayOfWeek = [];
            }
        } else {
            if (this.function.schedule.repeatStart === undefined) {
                this.function.schedule.repeatStart = DateTime.now();
            }
            if (this.function.schedule.repeatNo === undefined) {
                this.function.schedule.repeatNo = 1;
            }
        }
    }

    onFunctionUpdated(functionForm): void {
        if (functionForm.get('apiAction').value !== 'add') {
            functionForm.get('apiAction').setValue('update');
        }
    }

    onScheduleUpdated(schedule): void {
        if (schedule.apiAction !== 'add') {
            schedule.apiAction = 'update';
        }
        console.log(schedule);
    }

    onScheduleTimeUpdated(time: string, scheduleForm: FormGroup, type: string): void {
        if (scheduleForm.get('apiAction').value !== 'add') {
            scheduleForm.get('apiAction').setValue('update');
        }
        if (type === 'start') {
            scheduleForm.get('startTime').setValue(time);
        } else {
            scheduleForm.get('endTime').setValue(time);
        }
    }

    onEventGroupUpdated(eventGroupForm): void {
        if (eventGroupForm.get('apiAction').value !== 'add') {
            eventGroupForm.get('apiAction').setValue('update');
        }
    }

    onEventUpdated(eventForm): void {
        if (eventForm.get('apiAction').value !== 'add') {
            eventForm.get('apiAction').setValue('update');
        }
    }

    onSensorSelected(event, eventForm: FormGroup): void {
        console.log(event);
        const sensor = this.sensors.find(x => x.sensorId === event.value);
        if (sensor.type === 'door' || sensor.type === 'relay') {
            eventForm.get('operator').setValue('==');
        }
        eventForm.get('value').reset(null);
        if (eventForm.get('apiAction').value !== 'add') {
            eventForm.get('apiAction').setValue('update');
        }
    }

    addEventGroup(): void {
        // this.function.eventGroups.push(this.createNewEventGroup());
        // this.form = this.function.toFormGroup();
        this.eventGroupsFormArray.push(this.createNewEventGroup().toFormGroup());
        const a = 2;
    }

    addSensorEvent(eventGroupForm: FormGroup, objectType: string = 'SENSOR'): void {
        const eventsFormArray = eventGroupForm.controls.events as FormArray;
        if (objectType === 'DUMMY') {
            const dummyEvents = eventGroupForm.get('events').value.filter(x => x.objectType === 'DUMMY' && x.apiAction === 'delete');
            if (dummyEvents.length) {
                dummyEvents[0].get('apiAction').setValue(null);
            } else {
                eventsFormArray.push(this.createNewSensorEvent(objectType).toFormGroup());
            }
        } else {
            eventsFormArray.push(this.createNewSensorEvent(objectType).toFormGroup());
        }
    }

    addSchedule(eventGroupForm: FormGroup): void {
        const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
        schedulesFormArray.push(this.createNewSchedule().toFormGroup());
    }

    deleteSensorEvent(eventGroupForm: FormGroup, eventForm: FormGroup, index): void {
        if (eventForm.get('apiAction').value === 'add') {
            const eventsFormArray = eventGroupForm.get('events') as FormArray;
            eventsFormArray.removeAt(eventsFormArray.controls.findIndex(x => x === eventForm));
        } else {
            eventForm.get('apiAction').setValue('delete');
            if (eventForm.invalid) {
                this.removeInvalidControlsFromForm(eventForm);
            }
        }
        const validEvents = eventGroupForm.get('events').value.filter(x => x.apiAction !== 'delete');
        const validSchedules = eventGroupForm.get('schedules').value.filter(x => x.apiAction !== 'delete');
        if (validEvents.length === 0) {
            if (validSchedules.length === 0) {
                eventGroupForm.get('type').setValue(null);
            } else {
                this.addSensorEvent(eventGroupForm, 'DUMMY');
                eventGroupForm.get('type').setValue('schedule');
            }
        }
    }

    deleteEventGroup(eventGroupForm: FormGroup, index): void {
        if (eventGroupForm.get('apiAction').value === 'add') {
            this.eventGroupsFormArray.removeAt(this.eventGroupsFormArray.controls.findIndex(x => x === eventGroupForm));
        } else {
            eventGroupForm.get('apiAction').setValue('delete');
            if (eventGroupForm.invalid) {
                const eventsFormArray = eventGroupForm.get('events') as FormArray;
                this.removeInvalidControlsFromFormArray(eventsFormArray);
                const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
                this.removeInvalidControlsFromFormArray(schedulesFormArray);
            }
        }
        console.log(eventGroupForm);
    }

    deleteSchedule(eventGroupForm: FormGroup, scheduleForm: FormGroup, index): void {
        if (scheduleForm.get('apiAction').value === 'add') {
            const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
            schedulesFormArray.removeAt(schedulesFormArray.controls.findIndex(x => x === scheduleForm));
        } else {
            scheduleForm.get('apiAction').setValue('delete');
        }
        const eventsFormArray = eventGroupForm.get('events') as FormArray;
        const dummyEvents = eventsFormArray.controls.filter(x => x.value.objectType === 'DUMMY');
        const validEvents = eventGroupForm.get('events').value.filter(x => x.apiAction !== 'delete' && x.objectType !== 'DUMMY');
        const validSchedules = eventGroupForm.get('schedules').value.filter(x => x.apiAction !== 'delete');
        if (validSchedules.length === 0) {
            if (validEvents.length === 0) {
                eventGroupForm.get('type').setValue(null);
                dummyEvents.forEach(d => {
                    if (d.get('apiAction').value === 'add') {
                        eventsFormArray.removeAt(eventsFormArray.controls.findIndex(x => x === d));
                    } else {
                        d.get('apiAction').setValue('delete');
                    }
                });
            } else {
                eventGroupForm.get('type').setValue('sensor');
            }
        }
    }

    onCategorySelected(eventGroupForm: FormGroup, event): void {
        const eventsFormArray = eventGroupForm.get('events') as FormArray;
        if (event.source.value === 'schedule' || event.source.value === 'both') {
            const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
            schedulesFormArray.push(this.createNewSchedule().toFormGroup());
            // Add a dummy event, if type is only schedules
            if (event.source.value === 'schedule') {
                const dummyEvents = eventsFormArray.controls.filter(x => x.value.objectType === 'DUMMY' && x.value.apiAction === 'delete');
                if (dummyEvents.length) {
                    // If a dummy event already exists and has been flagged for deletion
                    dummyEvents[0].get('apiAction').setValue(null);
                } else {
                    // Else add a new one
                    eventsFormArray.push(this.createNewSensorEvent('DUMMY').toFormGroup());
                }
            }
        }
        if (event.source.value === 'sensor' || event.source.value === 'both') {
            eventsFormArray.push(this.createNewSensorEvent().toFormGroup());
        }
        // Remove any dummy events when type is both
        if (event.source.value === 'both') {
            // Remove any new dummy events
            let dummyEvents = eventsFormArray.controls.filter(x => x.value.objectType === 'DUMMY' && x.value.apiAction === 'add');
            dummyEvents.forEach(d => {
                eventsFormArray.removeAt(eventsFormArray.controls.findIndex(x => x === d));
            });
            // Flag any existing dummy events for deletion
            dummyEvents = eventsFormArray.controls.filter(x => x.value.objectType === 'DUMMY' && x.value.apiAction === null);
            dummyEvents.forEach(d => {
                d.get('apiAction').setValue('delete');
            });
        }
    }

    doSomething(row: EventGroup): void {
        console.log('sensorEvents:', row);
    }

    createNewEventGroup(): EventGroup {
        return new EventGroup({
            name: 'Νέο Σενάριο',
            apiAction: 'add'
        });
    }

    createNewSensorEvent(objectType: string = 'SENSOR'): SensorEvent {
        return new SensorEvent({apiAction: 'add', objectType: objectType});
    }

    createNewSchedule(): Schedule {
        return new Schedule({
            type: 'FULL',
            startTime: '00:01',
            endTime: '23:59',
            apiAction: 'add',
            months: null,
            dayOfWeek: null
        });
    }

    sensorTypeSelected(event): void {
        console.log(event);
    }

    onSave(): void {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        // save1 = true if apiAction for either the function or its schedule is defined
        const save1 = !!this.form.get('apiAction').value || !!this.function.schedule.apiAction || this.actionsForm.touched;

        // save2 = true if any of the eventGroups has been updated
        let save2 = false;
        const eventGroupsFormArray = this.form.get('eventGroups') as FormArray;
        eventGroupsFormArray.controls.every(eventGroupForm => {
            // If event group has been updated
            if (!!eventGroupForm.get('apiAction').value) {
                // Flag save and return early
                save2 = true;
                return false;
            }
            // If any event has been updated
            const eventsFormArray = eventGroupForm.get('events') as FormArray;
            const updatedEvent = eventsFormArray.controls.find(event => !!event.get('apiAction').value);
            if (updatedEvent) {
                // Flag save and return early
                save2 = true;
                return false;
            }
            // If any schedule has been updated
            const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
            const updatedSchedule = schedulesFormArray.controls.find(schedule => !!schedule.get('apiAction').value);
            if (updatedSchedule) {
                // Flag save and return early
                save2 = true;
                return false;
            }
            return true;
        });

        // If we need to save
        if (save1 || save2) {

            const tempFunction = SensorFunction.fromApi(this.function.toApi());

            tempFunction.name = this.form.get('name').value;
            tempFunction.apiAction = this.form.get('apiAction').value;
            tempFunction.notes = this.form.get('notes').value;

            const action = this.actionsForm.get('action');
            const inactiveAction = this.actionsForm.get('inactiveAction');
            if (action.get('enabled').value) {
                const actionValue = action.get('relay_state').value ? 'ON' : 'OFF';
                tempFunction.actions = this.actions.filter(x => x.name === actionValue);
            } else {
                tempFunction.actions = [];
            }
            if (inactiveAction.get('enabled').value) {
                const actionValue = inactiveAction.get('relay_state').value ? 'ON' : 'OFF';
                tempFunction.inactiveActions = this.actions.filter(x => x.name === actionValue);
            }
            else {
                tempFunction.inactiveActions = [];
            }

            // Only run this if any of the event groups have been updated
            if (save2) {
                this.eventGroupsFormArray.controls.forEach((eventGroupForm: FormGroup) => {
                    const apiAction = eventGroupForm.get('apiAction').value;
                    switch (apiAction) {
                        case 'add': {
                            const eventGroup = this.objectFromForm(eventGroupForm, ['events', 'schedules']);
                            const events = [];
                            const eventsFormArray = eventGroupForm.get('events') as FormArray;
                            eventsFormArray.controls.forEach((eventForm: FormGroup) => {
                                const event = this.objectFromForm(eventForm);
                                events.push(new SensorEvent(event));
                            });
                            const schedules = [];
                            const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
                            schedulesFormArray.controls.forEach((scheduleForm: FormGroup) => {
                                const schedule = this.objectFromForm(scheduleForm);
                                schedules.push(new Schedule(schedule));
                            });
                            eventGroup['events'] = events;
                            eventGroup['schedules'] = schedules;
                            tempFunction.eventGroups.push(new EventGroup(eventGroup));
                            break;
                        }
                        case 'delete': {
                            const eventGroup = tempFunction.eventGroups.find(e => e.id === eventGroupForm.get('id').value);
                            eventGroup.apiAction = 'delete';
                            break;
                        }
                        default: {
                            const eventGroup = tempFunction.eventGroups.find(e => e.id === eventGroupForm.get('id').value);
                            eventGroup.name = eventGroupForm.get('name').value;
                            eventGroup.operator = eventGroupForm.get('operator').value;
                            eventGroup.apiAction = eventGroupForm.get('apiAction').value;
                            const eventsFormArray = eventGroupForm.get('events') as FormArray;
                            eventsFormArray.controls.forEach((eventForm: FormGroup) => {
                                const apiAction1 = eventForm.get('apiAction').value;
                                switch (apiAction1) {
                                    case 'update': {
                                        const event = eventGroup.events.find(e => e.id === eventForm.get('id').value);
                                        Object.keys(eventForm.controls).forEach(key => {
                                            event[key] = eventForm.get(key).value;
                                        });
                                        break;
                                    }
                                    case 'add': {
                                        const event = this.objectFromForm(eventForm);
                                        eventGroup.events.push(new SensorEvent(event));
                                        break;
                                    }
                                    case 'delete': {
                                        const event = eventGroup.events.find(e => e.id === eventForm.get('id').value);
                                        event.apiAction = apiAction1;
                                        break;
                                    }
                                }
                            });
                            const schedulesFormArray = eventGroupForm.get('schedules') as FormArray;
                            schedulesFormArray.controls.forEach((scheduleForm: FormGroup) => {
                                const apiAction1 = scheduleForm.get('apiAction').value;
                                switch (apiAction1) {
                                    case 'update': {
                                        const schedule = eventGroup.schedules.find(s => s.id === scheduleForm.get('id').value);
                                        Object.keys(scheduleForm.controls).forEach(key => {
                                            schedule[key] = scheduleForm.get(key).value;
                                        });
                                        break;
                                    }
                                    case 'add': {
                                        const schedule = this.objectFromForm(scheduleForm);
                                        eventGroup.schedules.push(new Schedule(schedule));
                                        break;
                                    }
                                    case 'delete': {
                                        const schedule = eventGroup.schedules.find(s => s.id === scheduleForm.get('id').value);
                                        schedule.apiAction = apiAction1;
                                    }
                                }
                            });
                        }
                    }
                });
            }

            // Spawn an http process dialog
            const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
                disableClose: true,
                data: {
                    state: 'loading',
                }
            });

            let newFunc;  // variable to store returned function
            // Proceed to save
            this._automationService.saveFunction(tempFunction).then(
                response => {
                    httpProgressDialog.componentInstance.state = 'success';
                    newFunc = response;
                },
                error => httpProgressDialog.componentInstance.state = 'error'
            );
            // Close the main dialog after http dialog is closed
            httpProgressDialog.afterClosed().subscribe(response => {
                if (response === 'success') {
                    this.matDialogRef.close(SensorFunction.fromApi(newFunc));
                } else {
                    return;
                }
            });
        } else {
            this.matDialogRef.close();
        }
    }

    toggleHelp(): void {
        this.showHelp = !this.showHelp;
    }

    asFormArray(item): FormArray {
        return item as FormArray;
    }

    asFormControl(item): FormControl {
        return item as FormControl;
    }

    objectFromForm(form: FormGroup, exclude: string[] = []): any {
        const obj = {};
        Object.keys(form.controls).forEach(key => {
            if (!(key in exclude)) {
                obj[key] = form.get(key).value;
            }
        });
        return obj;
    }

    removeInvalidControlsFromForm(form: FormGroup): void {
        const invalidControls = Object.keys(form.controls).filter(x => form.get(x).invalid);
        invalidControls.forEach(controlName => {
            form.removeControl(controlName);
        });
    }

    removeInvalidControlsFromFormArray(formArray: FormArray): void {
        // eslint-disable-next-line guard-for-in
        for (const i in formArray.controls) {
            const form = formArray.controls[i] as FormGroup;
            if (form.invalid) {
                this.removeInvalidControlsFromForm(form);
            }
        }
    }
}
