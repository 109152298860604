export const uiIcons = [
    {
        'name': 'custom:alarm'
    },
    {
        'name': 'custom:attention'
    },
    {
        'name': 'custom:battery_empty'
    },
    {
        'name': 'custom:battery_full'
    },
    {
        'name': 'custom:battery_level'
    },
    {
        'name': 'custom:battery_level_1'
    },
    {
        'name': 'custom:battery_level_2'
    },
    {
        'name': 'custom:campfire'
    },
    {
        'name': 'custom:campfire_1'
    },
    {
        'name': 'custom:celsius'
    },
    {
        'name': 'custom:celsius_1'
    },
    {
        'name': 'custom:celsius_2'
    },
    {
        'name': 'custom:earthquake'
    },
    {
        'name': 'custom:earthquake_1'
    },
    {
        'name': 'custom:electric_car'
    },
    {
        'name': 'custom:electric_tower'
    },
    {
        'name': 'custom:electric_tower_1'
    },
    {
        'name': 'custom:electric_tower_2'
    },
    {
        'name': 'custom:fan_on'
    },
    {
        'name': 'custom:faucet_closed'
    },
    {
        'name': 'custom:faucet_open'
    },
    {
        'name': 'custom:faucet_open_1'
    },
    {
        'name': 'custom:faucet_open_2'
    },
    {
        'name': 'custom:faucet_open_3'
    },
    {
        'name': 'custom:faucet_open_4'
    },
    {
        'name': 'custom:fire'
    },
    {
        'name': 'custom:fire_1'
    },
    {
        'name': 'custom:fire_danger'
    },
    {
        'name': 'custom:fire_extinguisher'
    },
    {
        'name': 'custom:fire_hydrant'
    },
    {
        'name': 'custom:fire_hydrant_1'
    },
    {
        'name': 'custom:fire_hydrant_2'
    },
    {
        'name': 'custom:Flat_tick_icon'
    },
    {
        'name': 'custom:forest_fire'
    },
    {
        'name': 'custom:gmail'
    },
    {
        'name': 'custom:google_mail'
    },
    {
        'name': 'custom:heater_off'
    },
    {
        'name': 'custom:heater_on'
    },
    {
        'name': 'custom:humidity'
    },
    {
        'name': 'custom:level'
    },
    {
        'name': 'custom:level_1'
    },
    {
        'name': 'custom:level_2'
    },
    {
        'name': 'custom:light_off'
    },
    {
        'name': 'custom:light_on'
    },
    {
        'name': 'custom:liquid'
    },
    {
        'name': 'custom:man_on_fire'
    },
    {
        'name': 'custom:message_mails'
    },
    {
        'name': 'custom:motor_off'
    },
    {
        'name': 'custom:motor_on'
    },
    {
        'name': 'custom:no_fire'
    },
    {
        'name': 'custom:oil_tanks'
    },
    {
        'name': 'custom:padlock_closed'
    },
    {
        'name': 'custom:padlock_closed_1'
    },
    {
        'name': 'custom:padlock_closed_2'
    },
    {
        'name': 'custom:padlock_closed_3'
    },
    {
        'name': 'custom:padlock_open'
    },
    {
        'name': 'custom:panel_solar'
    },
    {
        'name': 'custom:ph_meter'
    },
    {
        'name': 'custom:pipe_on'
    },
    {
        'name': 'custom:power_off'
    },
    {
        'name': 'custom:power_on'
    },
    {
        'name': 'custom:room_clean'
    },
    {
        'name': 'custom:room_clean1'
    },
    {
        'name': 'custom:room_clean2'
    },
    {
        'name': 'custom:room_dnd'
    },
    {
        'name': 'custom:room_dnd1'
    },
    {
        'name': 'custom:room_dnd2'
    },
    {
        'name': 'custom:room_dnd3'
    },
    {
        'name': 'custom:room_thumbs'
    },
    {
        'name': 'custom:sea_level'
    },
    {
        'name': 'custom:shield_no'
    },
    {
        'name': 'custom:sms_message'
    },
    {
        'name': 'custom:snowflake'
    },
    {
        'name': 'custom:snowflake_1'
    },
    {
        'name': 'custom:socket_electrical'
    },
    {
        'name': 'custom:socket_electrical_1'
    },
    {
        'name': 'custom:socket_electrical_2'
    },
    {
        'name': 'custom:solar_panels_DTS'
    },
    {
        'name': 'custom:solar_panels_DTS_ENERGY'
    },
    {
        'name': 'custom:switch_closed'
    },
    {
        'name': 'custom:switch_open'
    },
    {
        'name': 'custom:water_drop'
    },
    {
        'name': 'custom:water_level'
    },
    {
        'name': 'custom:water_tank'
    },
    {
        'name': 'custom:wind'
    },
    {
        'name': 'custom:windmill_blue'
    },
    {
        'name': 'custom:windmill_green'
    },
    {
        'name': 'custom:windmill_off'
    },
    {
        'name': 'custom:windmill_off_1'
    },
    {
        'name': 'custom:windmill_off_2'
    },
    {
        'name': 'custom:windmill_on'
    },
    {
        'name': 'custom:windmill_on_1'
    },
    {
        'name': 'custom:windmill_on_2'
    },
    {
        'name': 'custom:windmill_orange'
    },
    {
        'name': 'custom:windmill_red'
    },
    {
        'name': 'custom:windmill_yellow'
    },
    {
        'name': 'custom:wind_1'
    },
    {
        'name': 'custom:wind_2'
    },
    {
        'name': 'mat_outline:10k'
    },
    {
        'name': 'mat_outline:10mp'
    },
    {
        'name': 'mat_outline:11mp'
    },
    {
        'name': 'mat_outline:123'
    },
    {
        'name': 'mat_outline:12mp'
    },
    {
        'name': 'mat_outline:13mp'
    },
    {
        'name': 'mat_outline:14mp'
    },
    {
        'name': 'mat_outline:15mp'
    },
    {
        'name': 'mat_outline:16mp'
    },
    {
        'name': 'mat_outline:17mp'
    },
    {
        'name': 'mat_outline:18mp'
    },
    {
        'name': 'mat_outline:18_up_rating'
    },
    {
        'name': 'mat_outline:19mp'
    },
    {
        'name': 'mat_outline:1k'
    },
    {
        'name': 'mat_outline:1k_plus'
    },
    {
        'name': 'mat_outline:1x_mobiledata'
    },
    {
        'name': 'mat_outline:20mp'
    },
    {
        'name': 'mat_outline:21mp'
    },
    {
        'name': 'mat_outline:22mp'
    },
    {
        'name': 'mat_outline:23mp'
    },
    {
        'name': 'mat_outline:24mp'
    },
    {
        'name': 'mat_outline:2k'
    },
    {
        'name': 'mat_outline:2k_plus'
    },
    {
        'name': 'mat_outline:2mp'
    },
    {
        'name': 'mat_outline:30fps'
    },
    {
        'name': 'mat_outline:30fps_select'
    },
    {
        'name': 'mat_outline:360'
    },
    {
        'name': 'mat_outline:3d_rotation'
    },
    {
        'name': 'mat_outline:3g_mobiledata'
    },
    {
        'name': 'mat_outline:3k'
    },
    {
        'name': 'mat_outline:3k_plus'
    },
    {
        'name': 'mat_outline:3mp'
    },
    {
        'name': 'mat_outline:3p'
    },
    {
        'name': 'mat_outline:4g_mobiledata'
    },
    {
        'name': 'mat_outline:4g_plus_mobiledata'
    },
    {
        'name': 'mat_outline:4k'
    },
    {
        'name': 'mat_outline:4k_plus'
    },
    {
        'name': 'mat_outline:4mp'
    },
    {
        'name': 'mat_outline:5g'
    },
    {
        'name': 'mat_outline:5k'
    },
    {
        'name': 'mat_outline:5k_plus'
    },
    {
        'name': 'mat_outline:5mp'
    },
    {
        'name': 'mat_outline:60fps'
    },
    {
        'name': 'mat_outline:60fps_select'
    },
    {
        'name': 'mat_outline:6k'
    },
    {
        'name': 'mat_outline:6k_plus'
    },
    {
        'name': 'mat_outline:6mp'
    },
    {
        'name': 'mat_outline:6_ft_apart'
    },
    {
        'name': 'mat_outline:7k'
    },
    {
        'name': 'mat_outline:7k_plus'
    },
    {
        'name': 'mat_outline:7mp'
    },
    {
        'name': 'mat_outline:8k'
    },
    {
        'name': 'mat_outline:8k_plus'
    },
    {
        'name': 'mat_outline:8mp'
    },
    {
        'name': 'mat_outline:9k'
    },
    {
        'name': 'mat_outline:9k_plus'
    },
    {
        'name': 'mat_outline:9mp'
    },
    {
        'name': 'mat_outline:abc'
    },
    {
        'name': 'mat_outline:accessibility'
    },
    {
        'name': 'mat_outline:accessibility_new'
    },
    {
        'name': 'mat_outline:accessible'
    },
    {
        'name': 'mat_outline:accessible_forward'
    },
    {
        'name': 'mat_outline:access_alarm'
    },
    {
        'name': 'mat_outline:access_alarms'
    },
    {
        'name': 'mat_outline:access_time'
    },
    {
        'name': 'mat_outline:access_time_filled'
    },
    {
        'name': 'mat_outline:account_balance'
    },
    {
        'name': 'mat_outline:account_balance_wallet'
    },
    {
        'name': 'mat_outline:account_box'
    },
    {
        'name': 'mat_outline:account_circle'
    },
    {
        'name': 'mat_outline:account_tree'
    },
    {
        'name': 'mat_outline:ac_unit'
    },
    {
        'name': 'mat_outline:adb'
    },
    {
        'name': 'mat_outline:add'
    },
    {
        'name': 'mat_outline:addchart'
    },
    {
        'name': 'mat_outline:add_alarm'
    },
    {
        'name': 'mat_outline:add_alert'
    },
    {
        'name': 'mat_outline:add_a_photo'
    },
    {
        'name': 'mat_outline:add_box'
    },
    {
        'name': 'mat_outline:add_business'
    },
    {
        'name': 'mat_outline:add_card'
    },
    {
        'name': 'mat_outline:add_chart'
    },
    {
        'name': 'mat_outline:add_circle'
    },
    {
        'name': 'mat_outline:add_circle_outline'
    },
    {
        'name': 'mat_outline:add_comment'
    },
    {
        'name': 'mat_outline:add_home'
    },
    {
        'name': 'mat_outline:add_home_work'
    },
    {
        'name': 'mat_outline:add_ic_call'
    },
    {
        'name': 'mat_outline:add_link'
    },
    {
        'name': 'mat_outline:add_location'
    },
    {
        'name': 'mat_outline:add_location_alt'
    },
    {
        'name': 'mat_outline:add_moderator'
    },
    {
        'name': 'mat_outline:add_photo_alternate'
    },
    {
        'name': 'mat_outline:add_reaction'
    },
    {
        'name': 'mat_outline:add_road'
    },
    {
        'name': 'mat_outline:add_shopping_cart'
    },
    {
        'name': 'mat_outline:add_task'
    },
    {
        'name': 'mat_outline:add_to_drive'
    },
    {
        'name': 'mat_outline:add_to_home_screen'
    },
    {
        'name': 'mat_outline:add_to_photos'
    },
    {
        'name': 'mat_outline:add_to_queue'
    },
    {
        'name': 'mat_outline:adf_scanner'
    },
    {
        'name': 'mat_outline:adjust'
    },
    {
        'name': 'mat_outline:admin_panel_settings'
    },
    {
        'name': 'mat_outline:ads_click'
    },
    {
        'name': 'mat_outline:ad_units'
    },
    {
        'name': 'mat_outline:agriculture'
    },
    {
        'name': 'mat_outline:air'
    },
    {
        'name': 'mat_outline:airlines'
    },
    {
        'name': 'mat_outline:airline_seat_flat'
    },
    {
        'name': 'mat_outline:airline_seat_flat_angled'
    },
    {
        'name': 'mat_outline:airline_seat_individual_suite'
    },
    {
        'name': 'mat_outline:airline_seat_legroom_extra'
    },
    {
        'name': 'mat_outline:airline_seat_legroom_normal'
    },
    {
        'name': 'mat_outline:airline_seat_legroom_reduced'
    },
    {
        'name': 'mat_outline:airline_seat_recline_extra'
    },
    {
        'name': 'mat_outline:airline_seat_recline_normal'
    },
    {
        'name': 'mat_outline:airline_stops'
    },
    {
        'name': 'mat_outline:airplanemode_active'
    },
    {
        'name': 'mat_outline:airplanemode_inactive'
    },
    {
        'name': 'mat_outline:airplane_ticket'
    },
    {
        'name': 'mat_outline:airplay'
    },
    {
        'name': 'mat_outline:airport_shuttle'
    },
    {
        'name': 'mat_outline:alarm'
    },
    {
        'name': 'mat_outline:alarm_add'
    },
    {
        'name': 'mat_outline:alarm_off'
    },
    {
        'name': 'mat_outline:alarm_on'
    },
    {
        'name': 'mat_outline:album'
    },
    {
        'name': 'mat_outline:align_horizontal_center'
    },
    {
        'name': 'mat_outline:align_horizontal_left'
    },
    {
        'name': 'mat_outline:align_horizontal_right'
    },
    {
        'name': 'mat_outline:align_vertical_bottom'
    },
    {
        'name': 'mat_outline:align_vertical_center'
    },
    {
        'name': 'mat_outline:align_vertical_top'
    },
    {
        'name': 'mat_outline:all_inbox'
    },
    {
        'name': 'mat_outline:all_inclusive'
    },
    {
        'name': 'mat_outline:all_out'
    },
    {
        'name': 'mat_outline:alternate_email'
    },
    {
        'name': 'mat_outline:alt_route'
    },
    {
        'name': 'mat_outline:analytics'
    },
    {
        'name': 'mat_outline:anchor'
    },
    {
        'name': 'mat_outline:android'
    },
    {
        'name': 'mat_outline:animation'
    },
    {
        'name': 'mat_outline:announcement'
    },
    {
        'name': 'mat_outline:aod'
    },
    {
        'name': 'mat_outline:apartment'
    },
    {
        'name': 'mat_outline:api'
    },
    {
        'name': 'mat_outline:approval'
    },
    {
        'name': 'mat_outline:apps'
    },
    {
        'name': 'mat_outline:apps_outage'
    },
    {
        'name': 'mat_outline:app_blocking'
    },
    {
        'name': 'mat_outline:app_registration'
    },
    {
        'name': 'mat_outline:app_settings_alt'
    },
    {
        'name': 'mat_outline:app_shortcut'
    },
    {
        'name': 'mat_outline:architecture'
    },
    {
        'name': 'mat_outline:archive'
    },
    {
        'name': 'mat_outline:area_chart'
    },
    {
        'name': 'mat_outline:arrow_back'
    },
    {
        'name': 'mat_outline:arrow_back_ios'
    },
    {
        'name': 'mat_outline:arrow_back_ios_new'
    },
    {
        'name': 'mat_outline:arrow_circle_down'
    },
    {
        'name': 'mat_outline:arrow_circle_left'
    },
    {
        'name': 'mat_outline:arrow_circle_right'
    },
    {
        'name': 'mat_outline:arrow_circle_up'
    },
    {
        'name': 'mat_outline:arrow_downward'
    },
    {
        'name': 'mat_outline:arrow_drop_down'
    },
    {
        'name': 'mat_outline:arrow_drop_down_circle'
    },
    {
        'name': 'mat_outline:arrow_drop_up'
    },
    {
        'name': 'mat_outline:arrow_forward'
    },
    {
        'name': 'mat_outline:arrow_forward_ios'
    },
    {
        'name': 'mat_outline:arrow_left'
    },
    {
        'name': 'mat_outline:arrow_outward'
    },
    {
        'name': 'mat_outline:arrow_right'
    },
    {
        'name': 'mat_outline:arrow_right_alt'
    },
    {
        'name': 'mat_outline:arrow_upward'
    },
    {
        'name': 'mat_outline:article'
    },
    {
        'name': 'mat_outline:art_track'
    },
    {
        'name': 'mat_outline:aspect_ratio'
    },
    {
        'name': 'mat_outline:assessment'
    },
    {
        'name': 'mat_outline:assignment'
    },
    {
        'name': 'mat_outline:assignment_ind'
    },
    {
        'name': 'mat_outline:assignment_late'
    },
    {
        'name': 'mat_outline:assignment_return'
    },
    {
        'name': 'mat_outline:assignment_returned'
    },
    {
        'name': 'mat_outline:assignment_turned_in'
    },
    {
        'name': 'mat_outline:assistant'
    },
    {
        'name': 'mat_outline:assistant_direction'
    },
    {
        'name': 'mat_outline:assistant_photo'
    },
    {
        'name': 'mat_outline:assist_walker'
    },
    {
        'name': 'mat_outline:assured_workload'
    },
    {
        'name': 'mat_outline:atm'
    },
    {
        'name': 'mat_outline:attachment'
    },
    {
        'name': 'mat_outline:attach_email'
    },
    {
        'name': 'mat_outline:attach_file'
    },
    {
        'name': 'mat_outline:attach_money'
    },
    {
        'name': 'mat_outline:attractions'
    },
    {
        'name': 'mat_outline:attribution'
    },
    {
        'name': 'mat_outline:audiotrack'
    },
    {
        'name': 'mat_outline:audio_file'
    },
    {
        'name': 'mat_outline:autofps_select'
    },
    {
        'name': 'mat_outline:autorenew'
    },
    {
        'name': 'mat_outline:auto_awesome'
    },
    {
        'name': 'mat_outline:auto_awesome_mosaic'
    },
    {
        'name': 'mat_outline:auto_awesome_motion'
    },
    {
        'name': 'mat_outline:auto_delete'
    },
    {
        'name': 'mat_outline:auto_fix_high'
    },
    {
        'name': 'mat_outline:auto_fix_normal'
    },
    {
        'name': 'mat_outline:auto_fix_off'
    },
    {
        'name': 'mat_outline:auto_graph'
    },
    {
        'name': 'mat_outline:auto_mode'
    },
    {
        'name': 'mat_outline:auto_stories'
    },
    {
        'name': 'mat_outline:av_timer'
    },
    {
        'name': 'mat_outline:baby_changing_station'
    },
    {
        'name': 'mat_outline:backpack'
    },
    {
        'name': 'mat_outline:backspace'
    },
    {
        'name': 'mat_outline:backup'
    },
    {
        'name': 'mat_outline:backup_table'
    },
    {
        'name': 'mat_outline:back_hand'
    },
    {
        'name': 'mat_outline:badge'
    },
    {
        'name': 'mat_outline:bakery_dining'
    },
    {
        'name': 'mat_outline:balance'
    },
    {
        'name': 'mat_outline:balcony'
    },
    {
        'name': 'mat_outline:ballot'
    },
    {
        'name': 'mat_outline:bar_chart'
    },
    {
        'name': 'mat_outline:batch_prediction'
    },
    {
        'name': 'mat_outline:bathroom'
    },
    {
        'name': 'mat_outline:bathtub'
    },
    {
        'name': 'mat_outline:battery_0_bar'
    },
    {
        'name': 'mat_outline:battery_1_bar'
    },
    {
        'name': 'mat_outline:battery_2_bar'
    },
    {
        'name': 'mat_outline:battery_3_bar'
    },
    {
        'name': 'mat_outline:battery_4_bar'
    },
    {
        'name': 'mat_outline:battery_5_bar'
    },
    {
        'name': 'mat_outline:battery_6_bar'
    },
    {
        'name': 'mat_outline:battery_alert'
    },
    {
        'name': 'mat_outline:battery_charging_full'
    },
    {
        'name': 'mat_outline:battery_full'
    },
    {
        'name': 'mat_outline:battery_saver'
    },
    {
        'name': 'mat_outline:battery_std'
    },
    {
        'name': 'mat_outline:battery_unknown'
    },
    {
        'name': 'mat_outline:beach_access'
    },
    {
        'name': 'mat_outline:bed'
    },
    {
        'name': 'mat_outline:bedroom_baby'
    },
    {
        'name': 'mat_outline:bedroom_child'
    },
    {
        'name': 'mat_outline:bedroom_parent'
    },
    {
        'name': 'mat_outline:bedtime'
    },
    {
        'name': 'mat_outline:bedtime_off'
    },
    {
        'name': 'mat_outline:beenhere'
    },
    {
        'name': 'mat_outline:bento'
    },
    {
        'name': 'mat_outline:bike_scooter'
    },
    {
        'name': 'mat_outline:biotech'
    },
    {
        'name': 'mat_outline:blender'
    },
    {
        'name': 'mat_outline:blind'
    },
    {
        'name': 'mat_outline:blinds'
    },
    {
        'name': 'mat_outline:blinds_closed'
    },
    {
        'name': 'mat_outline:block'
    },
    {
        'name': 'mat_outline:bloodtype'
    },
    {
        'name': 'mat_outline:bluetooth'
    },
    {
        'name': 'mat_outline:bluetooth_audio'
    },
    {
        'name': 'mat_outline:bluetooth_connected'
    },
    {
        'name': 'mat_outline:bluetooth_disabled'
    },
    {
        'name': 'mat_outline:bluetooth_drive'
    },
    {
        'name': 'mat_outline:bluetooth_searching'
    },
    {
        'name': 'mat_outline:blur_circular'
    },
    {
        'name': 'mat_outline:blur_linear'
    },
    {
        'name': 'mat_outline:blur_off'
    },
    {
        'name': 'mat_outline:blur_on'
    },
    {
        'name': 'mat_outline:bolt'
    },
    {
        'name': 'mat_outline:book'
    },
    {
        'name': 'mat_outline:bookmark'
    },
    {
        'name': 'mat_outline:bookmarks'
    },
    {
        'name': 'mat_outline:bookmark_add'
    },
    {
        'name': 'mat_outline:bookmark_added'
    },
    {
        'name': 'mat_outline:bookmark_border'
    },
    {
        'name': 'mat_outline:bookmark_remove'
    },
    {
        'name': 'mat_outline:book_online'
    },
    {
        'name': 'mat_outline:border_all'
    },
    {
        'name': 'mat_outline:border_bottom'
    },
    {
        'name': 'mat_outline:border_clear'
    },
    {
        'name': 'mat_outline:border_color'
    },
    {
        'name': 'mat_outline:border_horizontal'
    },
    {
        'name': 'mat_outline:border_inner'
    },
    {
        'name': 'mat_outline:border_left'
    },
    {
        'name': 'mat_outline:border_outer'
    },
    {
        'name': 'mat_outline:border_right'
    },
    {
        'name': 'mat_outline:border_style'
    },
    {
        'name': 'mat_outline:border_top'
    },
    {
        'name': 'mat_outline:border_vertical'
    },
    {
        'name': 'mat_outline:boy'
    },
    {
        'name': 'mat_outline:branding_watermark'
    },
    {
        'name': 'mat_outline:breakfast_dining'
    },
    {
        'name': 'mat_outline:brightness_1'
    },
    {
        'name': 'mat_outline:brightness_2'
    },
    {
        'name': 'mat_outline:brightness_3'
    },
    {
        'name': 'mat_outline:brightness_4'
    },
    {
        'name': 'mat_outline:brightness_5'
    },
    {
        'name': 'mat_outline:brightness_6'
    },
    {
        'name': 'mat_outline:brightness_7'
    },
    {
        'name': 'mat_outline:brightness_auto'
    },
    {
        'name': 'mat_outline:brightness_high'
    },
    {
        'name': 'mat_outline:brightness_low'
    },
    {
        'name': 'mat_outline:brightness_medium'
    },
    {
        'name': 'mat_outline:broadcast_on_home'
    },
    {
        'name': 'mat_outline:broadcast_on_personal'
    },
    {
        'name': 'mat_outline:broken_image'
    },
    {
        'name': 'mat_outline:browser_not_supported'
    },
    {
        'name': 'mat_outline:browser_updated'
    },
    {
        'name': 'mat_outline:browse_gallery'
    },
    {
        'name': 'mat_outline:brunch_dining'
    },
    {
        'name': 'mat_outline:brush'
    },
    {
        'name': 'mat_outline:bubble_chart'
    },
    {
        'name': 'mat_outline:bug_report'
    },
    {
        'name': 'mat_outline:build'
    },
    {
        'name': 'mat_outline:build_circle'
    },
    {
        'name': 'mat_outline:bungalow'
    },
    {
        'name': 'mat_outline:burst_mode'
    },
    {
        'name': 'mat_outline:business'
    },
    {
        'name': 'mat_outline:business_center'
    },
    {
        'name': 'mat_outline:bus_alert'
    },
    {
        'name': 'mat_outline:cabin'
    },
    {
        'name': 'mat_outline:cable'
    },
    {
        'name': 'mat_outline:cached'
    },
    {
        'name': 'mat_outline:cake'
    },
    {
        'name': 'mat_outline:calculate'
    },
    {
        'name': 'mat_outline:calendar_month'
    },
    {
        'name': 'mat_outline:calendar_today'
    },
    {
        'name': 'mat_outline:calendar_view_day'
    },
    {
        'name': 'mat_outline:calendar_view_month'
    },
    {
        'name': 'mat_outline:calendar_view_week'
    },
    {
        'name': 'mat_outline:call'
    },
    {
        'name': 'mat_outline:call_end'
    },
    {
        'name': 'mat_outline:call_made'
    },
    {
        'name': 'mat_outline:call_merge'
    },
    {
        'name': 'mat_outline:call_missed'
    },
    {
        'name': 'mat_outline:call_missed_outgoing'
    },
    {
        'name': 'mat_outline:call_received'
    },
    {
        'name': 'mat_outline:call_split'
    },
    {
        'name': 'mat_outline:call_to_action'
    },
    {
        'name': 'mat_outline:camera'
    },
    {
        'name': 'mat_outline:cameraswitch'
    },
    {
        'name': 'mat_outline:camera_alt'
    },
    {
        'name': 'mat_outline:camera_enhance'
    },
    {
        'name': 'mat_outline:camera_front'
    },
    {
        'name': 'mat_outline:camera_indoor'
    },
    {
        'name': 'mat_outline:camera_outdoor'
    },
    {
        'name': 'mat_outline:camera_rear'
    },
    {
        'name': 'mat_outline:camera_roll'
    },
    {
        'name': 'mat_outline:campaign'
    },
    {
        'name': 'mat_outline:cancel'
    },
    {
        'name': 'mat_outline:cancel_presentation'
    },
    {
        'name': 'mat_outline:cancel_schedule_send'
    },
    {
        'name': 'mat_outline:candlestick_chart'
    },
    {
        'name': 'mat_outline:card_giftcard'
    },
    {
        'name': 'mat_outline:card_membership'
    },
    {
        'name': 'mat_outline:card_travel'
    },
    {
        'name': 'mat_outline:carpenter'
    },
    {
        'name': 'mat_outline:car_crash'
    },
    {
        'name': 'mat_outline:car_rental'
    },
    {
        'name': 'mat_outline:car_repair'
    },
    {
        'name': 'mat_outline:cases'
    },
    {
        'name': 'mat_outline:casino'
    },
    {
        'name': 'mat_outline:cast'
    },
    {
        'name': 'mat_outline:castle'
    },
    {
        'name': 'mat_outline:cast_connected'
    },
    {
        'name': 'mat_outline:cast_for_education'
    },
    {
        'name': 'mat_outline:catching_pokemon'
    },
    {
        'name': 'mat_outline:category'
    },
    {
        'name': 'mat_outline:celebration'
    },
    {
        'name': 'mat_outline:cell_tower'
    },
    {
        'name': 'mat_outline:cell_wifi'
    },
    {
        'name': 'mat_outline:center_focus_strong'
    },
    {
        'name': 'mat_outline:center_focus_weak'
    },
    {
        'name': 'mat_outline:chair'
    },
    {
        'name': 'mat_outline:chair_alt'
    },
    {
        'name': 'mat_outline:chalet'
    },
    {
        'name': 'mat_outline:change_circle'
    },
    {
        'name': 'mat_outline:change_history'
    },
    {
        'name': 'mat_outline:charging_station'
    },
    {
        'name': 'mat_outline:chat'
    },
    {
        'name': 'mat_outline:chat_bubble'
    },
    {
        'name': 'mat_outline:chat_bubble_outline'
    },
    {
        'name': 'mat_outline:check'
    },
    {
        'name': 'mat_outline:checklist'
    },
    {
        'name': 'mat_outline:checklist_rtl'
    },
    {
        'name': 'mat_outline:checkroom'
    },
    {
        'name': 'mat_outline:check_box'
    },
    {
        'name': 'mat_outline:check_box_outline_blank'
    },
    {
        'name': 'mat_outline:check_circle'
    },
    {
        'name': 'mat_outline:check_circle_outline'
    },
    {
        'name': 'mat_outline:chevron_left'
    },
    {
        'name': 'mat_outline:chevron_right'
    },
    {
        'name': 'mat_outline:child_care'
    },
    {
        'name': 'mat_outline:child_friendly'
    },
    {
        'name': 'mat_outline:chrome_reader_mode'
    },
    {
        'name': 'mat_outline:church'
    },
    {
        'name': 'mat_outline:circle'
    },
    {
        'name': 'mat_outline:circle_notifications'
    },
    {
        'name': 'mat_outline:class'
    },
    {
        'name': 'mat_outline:cleaning_services'
    },
    {
        'name': 'mat_outline:clean_hands'
    },
    {
        'name': 'mat_outline:clear'
    },
    {
        'name': 'mat_outline:clear_all'
    },
    {
        'name': 'mat_outline:close'
    },
    {
        'name': 'mat_outline:closed_caption'
    },
    {
        'name': 'mat_outline:closed_caption_disabled'
    },
    {
        'name': 'mat_outline:closed_caption_off'
    },
    {
        'name': 'mat_outline:close_fullscreen'
    },
    {
        'name': 'mat_outline:cloud'
    },
    {
        'name': 'mat_outline:cloud_circle'
    },
    {
        'name': 'mat_outline:cloud_done'
    },
    {
        'name': 'mat_outline:cloud_download'
    },
    {
        'name': 'mat_outline:cloud_off'
    },
    {
        'name': 'mat_outline:cloud_queue'
    },
    {
        'name': 'mat_outline:cloud_sync'
    },
    {
        'name': 'mat_outline:cloud_upload'
    },
    {
        'name': 'mat_outline:co2'
    },
    {
        'name': 'mat_outline:code'
    },
    {
        'name': 'mat_outline:code_off'
    },
    {
        'name': 'mat_outline:coffee'
    },
    {
        'name': 'mat_outline:coffee_maker'
    },
    {
        'name': 'mat_outline:collections'
    },
    {
        'name': 'mat_outline:collections_bookmark'
    },
    {
        'name': 'mat_outline:colorize'
    },
    {
        'name': 'mat_outline:color_lens'
    },
    {
        'name': 'mat_outline:comment'
    },
    {
        'name': 'mat_outline:comments_disabled'
    },
    {
        'name': 'mat_outline:comment_bank'
    },
    {
        'name': 'mat_outline:commit'
    },
    {
        'name': 'mat_outline:commute'
    },
    {
        'name': 'mat_outline:compare'
    },
    {
        'name': 'mat_outline:compare_arrows'
    },
    {
        'name': 'mat_outline:compass_calibration'
    },
    {
        'name': 'mat_outline:compost'
    },
    {
        'name': 'mat_outline:compress'
    },
    {
        'name': 'mat_outline:computer'
    },
    {
        'name': 'mat_outline:confirmation_number'
    },
    {
        'name': 'mat_outline:connected_tv'
    },
    {
        'name': 'mat_outline:connecting_airports'
    },
    {
        'name': 'mat_outline:connect_without_contact'
    },
    {
        'name': 'mat_outline:construction'
    },
    {
        'name': 'mat_outline:contactless'
    },
    {
        'name': 'mat_outline:contacts'
    },
    {
        'name': 'mat_outline:contact_emergency'
    },
    {
        'name': 'mat_outline:contact_mail'
    },
    {
        'name': 'mat_outline:contact_page'
    },
    {
        'name': 'mat_outline:contact_phone'
    },
    {
        'name': 'mat_outline:contact_support'
    },
    {
        'name': 'mat_outline:content_copy'
    },
    {
        'name': 'mat_outline:content_cut'
    },
    {
        'name': 'mat_outline:content_paste'
    },
    {
        'name': 'mat_outline:content_paste_go'
    },
    {
        'name': 'mat_outline:content_paste_off'
    },
    {
        'name': 'mat_outline:content_paste_search'
    },
    {
        'name': 'mat_outline:contrast'
    },
    {
        'name': 'mat_outline:control_camera'
    },
    {
        'name': 'mat_outline:control_point'
    },
    {
        'name': 'mat_outline:control_point_duplicate'
    },
    {
        'name': 'mat_outline:cookie'
    },
    {
        'name': 'mat_outline:copyright'
    },
    {
        'name': 'mat_outline:copy_all'
    },
    {
        'name': 'mat_outline:coronavirus'
    },
    {
        'name': 'mat_outline:corporate_fare'
    },
    {
        'name': 'mat_outline:cottage'
    },
    {
        'name': 'mat_outline:countertops'
    },
    {
        'name': 'mat_outline:co_present'
    },
    {
        'name': 'mat_outline:create'
    },
    {
        'name': 'mat_outline:create_new_folder'
    },
    {
        'name': 'mat_outline:credit_card'
    },
    {
        'name': 'mat_outline:credit_card_off'
    },
    {
        'name': 'mat_outline:credit_score'
    },
    {
        'name': 'mat_outline:crib'
    },
    {
        'name': 'mat_outline:crisis_alert'
    },
    {
        'name': 'mat_outline:crop'
    },
    {
        'name': 'mat_outline:crop_16_9'
    },
    {
        'name': 'mat_outline:crop_3_2'
    },
    {
        'name': 'mat_outline:crop_5_4'
    },
    {
        'name': 'mat_outline:crop_7_5'
    },
    {
        'name': 'mat_outline:crop_din'
    },
    {
        'name': 'mat_outline:crop_free'
    },
    {
        'name': 'mat_outline:crop_landscape'
    },
    {
        'name': 'mat_outline:crop_original'
    },
    {
        'name': 'mat_outline:crop_portrait'
    },
    {
        'name': 'mat_outline:crop_rotate'
    },
    {
        'name': 'mat_outline:crop_square'
    },
    {
        'name': 'mat_outline:cruelty_free'
    },
    {
        'name': 'mat_outline:css'
    },
    {
        'name': 'mat_outline:currency_bitcoin'
    },
    {
        'name': 'mat_outline:currency_exchange'
    },
    {
        'name': 'mat_outline:currency_franc'
    },
    {
        'name': 'mat_outline:currency_lira'
    },
    {
        'name': 'mat_outline:currency_pound'
    },
    {
        'name': 'mat_outline:currency_ruble'
    },
    {
        'name': 'mat_outline:currency_rupee'
    },
    {
        'name': 'mat_outline:currency_yen'
    },
    {
        'name': 'mat_outline:currency_yuan'
    },
    {
        'name': 'mat_outline:curtains'
    },
    {
        'name': 'mat_outline:curtains_closed'
    },
    {
        'name': 'mat_outline:cyclone'
    },
    {
        'name': 'mat_outline:dangerous'
    },
    {
        'name': 'mat_outline:dark_mode'
    },
    {
        'name': 'mat_outline:dashboard'
    },
    {
        'name': 'mat_outline:dashboard_customize'
    },
    {
        'name': 'mat_outline:dataset'
    },
    {
        'name': 'mat_outline:dataset_linked'
    },
    {
        'name': 'mat_outline:data_array'
    },
    {
        'name': 'mat_outline:data_exploration'
    },
    {
        'name': 'mat_outline:data_object'
    },
    {
        'name': 'mat_outline:data_saver_off'
    },
    {
        'name': 'mat_outline:data_saver_on'
    },
    {
        'name': 'mat_outline:data_thresholding'
    },
    {
        'name': 'mat_outline:data_usage'
    },
    {
        'name': 'mat_outline:date_range'
    },
    {
        'name': 'mat_outline:deblur'
    },
    {
        'name': 'mat_outline:deck'
    },
    {
        'name': 'mat_outline:dehaze'
    },
    {
        'name': 'mat_outline:delete'
    },
    {
        'name': 'mat_outline:delete_forever'
    },
    {
        'name': 'mat_outline:delete_outline'
    },
    {
        'name': 'mat_outline:delete_sweep'
    },
    {
        'name': 'mat_outline:delivery_dining'
    },
    {
        'name': 'mat_outline:density_large'
    },
    {
        'name': 'mat_outline:density_medium'
    },
    {
        'name': 'mat_outline:density_small'
    },
    {
        'name': 'mat_outline:departure_board'
    },
    {
        'name': 'mat_outline:description'
    },
    {
        'name': 'mat_outline:deselect'
    },
    {
        'name': 'mat_outline:design_services'
    },
    {
        'name': 'mat_outline:desk'
    },
    {
        'name': 'mat_outline:desktop_access_disabled'
    },
    {
        'name': 'mat_outline:desktop_mac'
    },
    {
        'name': 'mat_outline:desktop_windows'
    },
    {
        'name': 'mat_outline:details'
    },
    {
        'name': 'mat_outline:developer_board'
    },
    {
        'name': 'mat_outline:developer_board_off'
    },
    {
        'name': 'mat_outline:developer_mode'
    },
    {
        'name': 'mat_outline:devices'
    },
    {
        'name': 'mat_outline:devices_fold'
    },
    {
        'name': 'mat_outline:devices_other'
    },
    {
        'name': 'mat_outline:device_hub'
    },
    {
        'name': 'mat_outline:device_thermostat'
    },
    {
        'name': 'mat_outline:device_unknown'
    },
    {
        'name': 'mat_outline:dialer_sip'
    },
    {
        'name': 'mat_outline:dialpad'
    },
    {
        'name': 'mat_outline:diamond'
    },
    {
        'name': 'mat_outline:difference'
    },
    {
        'name': 'mat_outline:dining'
    },
    {
        'name': 'mat_outline:dinner_dining'
    },
    {
        'name': 'mat_outline:directions'
    },
    {
        'name': 'mat_outline:directions_bike'
    },
    {
        'name': 'mat_outline:directions_boat'
    },
    {
        'name': 'mat_outline:directions_boat_filled'
    },
    {
        'name': 'mat_outline:directions_bus'
    },
    {
        'name': 'mat_outline:directions_bus_filled'
    },
    {
        'name': 'mat_outline:directions_car'
    },
    {
        'name': 'mat_outline:directions_car_filled'
    },
    {
        'name': 'mat_outline:directions_off'
    },
    {
        'name': 'mat_outline:directions_railway'
    },
    {
        'name': 'mat_outline:directions_railway_filled'
    },
    {
        'name': 'mat_outline:directions_run'
    },
    {
        'name': 'mat_outline:directions_subway'
    },
    {
        'name': 'mat_outline:directions_subway_filled'
    },
    {
        'name': 'mat_outline:directions_transit'
    },
    {
        'name': 'mat_outline:directions_transit_filled'
    },
    {
        'name': 'mat_outline:directions_walk'
    },
    {
        'name': 'mat_outline:dirty_lens'
    },
    {
        'name': 'mat_outline:disabled_by_default'
    },
    {
        'name': 'mat_outline:disabled_visible'
    },
    {
        'name': 'mat_outline:discount'
    },
    {
        'name': 'mat_outline:disc_full'
    },
    {
        'name': 'mat_outline:display_settings'
    },
    {
        'name': 'mat_outline:diversity_1'
    },
    {
        'name': 'mat_outline:diversity_2'
    },
    {
        'name': 'mat_outline:diversity_3'
    },
    {
        'name': 'mat_outline:dns'
    },
    {
        'name': 'mat_outline:dock'
    },
    {
        'name': 'mat_outline:document_scanner'
    },
    {
        'name': 'mat_outline:domain'
    },
    {
        'name': 'mat_outline:domain_add'
    },
    {
        'name': 'mat_outline:domain_disabled'
    },
    {
        'name': 'mat_outline:domain_verification'
    },
    {
        'name': 'mat_outline:done'
    },
    {
        'name': 'mat_outline:done_all'
    },
    {
        'name': 'mat_outline:done_outline'
    },
    {
        'name': 'mat_outline:donut_large'
    },
    {
        'name': 'mat_outline:donut_small'
    },
    {
        'name': 'mat_outline:doorbell'
    },
    {
        'name': 'mat_outline:door_back'
    },
    {
        'name': 'mat_outline:door_front'
    },
    {
        'name': 'mat_outline:door_sliding'
    },
    {
        'name': 'mat_outline:double_arrow'
    },
    {
        'name': 'mat_outline:downhill_skiing'
    },
    {
        'name': 'mat_outline:download'
    },
    {
        'name': 'mat_outline:downloading'
    },
    {
        'name': 'mat_outline:download_done'
    },
    {
        'name': 'mat_outline:download_for_offline'
    },
    {
        'name': 'mat_outline:do_disturb'
    },
    {
        'name': 'mat_outline:do_disturb_alt'
    },
    {
        'name': 'mat_outline:do_disturb_off'
    },
    {
        'name': 'mat_outline:do_disturb_on'
    },
    {
        'name': 'mat_outline:do_not_disturb'
    },
    {
        'name': 'mat_outline:do_not_disturb_alt'
    },
    {
        'name': 'mat_outline:do_not_disturb_off'
    },
    {
        'name': 'mat_outline:do_not_disturb_on'
    },
    {
        'name': 'mat_outline:do_not_disturb_on_total_silence'
    },
    {
        'name': 'mat_outline:do_not_step'
    },
    {
        'name': 'mat_outline:do_not_touch'
    },
    {
        'name': 'mat_outline:drafts'
    },
    {
        'name': 'mat_outline:drag_handle'
    },
    {
        'name': 'mat_outline:drag_indicator'
    },
    {
        'name': 'mat_outline:draw'
    },
    {
        'name': 'mat_outline:drive_eta'
    },
    {
        'name': 'mat_outline:drive_file_move'
    },
    {
        'name': 'mat_outline:drive_file_move_rtl'
    },
    {
        'name': 'mat_outline:drive_file_rename_outline'
    },
    {
        'name': 'mat_outline:drive_folder_upload'
    },
    {
        'name': 'mat_outline:dry'
    },
    {
        'name': 'mat_outline:dry_cleaning'
    },
    {
        'name': 'mat_outline:duo'
    },
    {
        'name': 'mat_outline:dvr'
    },
    {
        'name': 'mat_outline:dynamic_feed'
    },
    {
        'name': 'mat_outline:dynamic_form'
    },
    {
        'name': 'mat_outline:earbuds'
    },
    {
        'name': 'mat_outline:earbuds_battery'
    },
    {
        'name': 'mat_outline:east'
    },
    {
        'name': 'mat_outline:edgesensor_high'
    },
    {
        'name': 'mat_outline:edgesensor_low'
    },
    {
        'name': 'mat_outline:edit'
    },
    {
        'name': 'mat_outline:edit_attributes'
    },
    {
        'name': 'mat_outline:edit_calendar'
    },
    {
        'name': 'mat_outline:edit_location'
    },
    {
        'name': 'mat_outline:edit_location_alt'
    },
    {
        'name': 'mat_outline:edit_note'
    },
    {
        'name': 'mat_outline:edit_notifications'
    },
    {
        'name': 'mat_outline:edit_off'
    },
    {
        'name': 'mat_outline:edit_road'
    },
    {
        'name': 'mat_outline:egg'
    },
    {
        'name': 'mat_outline:egg_alt'
    },
    {
        'name': 'mat_outline:eject'
    },
    {
        'name': 'mat_outline:elderly'
    },
    {
        'name': 'mat_outline:elderly_woman'
    },
    {
        'name': 'mat_outline:electrical_services'
    },
    {
        'name': 'mat_outline:electric_bike'
    },
    {
        'name': 'mat_outline:electric_bolt'
    },
    {
        'name': 'mat_outline:electric_car'
    },
    {
        'name': 'mat_outline:electric_meter'
    },
    {
        'name': 'mat_outline:electric_moped'
    },
    {
        'name': 'mat_outline:electric_rickshaw'
    },
    {
        'name': 'mat_outline:electric_scooter'
    },
    {
        'name': 'mat_outline:elevator'
    },
    {
        'name': 'mat_outline:email'
    },
    {
        'name': 'mat_outline:emergency'
    },
    {
        'name': 'mat_outline:emergency_recording'
    },
    {
        'name': 'mat_outline:emergency_share'
    },
    {
        'name': 'mat_outline:emoji_emotions'
    },
    {
        'name': 'mat_outline:emoji_events'
    },
    {
        'name': 'mat_outline:emoji_food_beverage'
    },
    {
        'name': 'mat_outline:emoji_nature'
    },
    {
        'name': 'mat_outline:emoji_objects'
    },
    {
        'name': 'mat_outline:emoji_people'
    },
    {
        'name': 'mat_outline:emoji_symbols'
    },
    {
        'name': 'mat_outline:emoji_transportation'
    },
    {
        'name': 'mat_outline:energy_savings_leaf'
    },
    {
        'name': 'mat_outline:engineering'
    },
    {
        'name': 'mat_outline:enhanced_encryption'
    },
    {
        'name': 'mat_outline:equalizer'
    },
    {
        'name': 'mat_outline:error'
    },
    {
        'name': 'mat_outline:error_outline'
    },
    {
        'name': 'mat_outline:escalator'
    },
    {
        'name': 'mat_outline:escalator_warning'
    },
    {
        'name': 'mat_outline:euro'
    },
    {
        'name': 'mat_outline:euro_symbol'
    },
    {
        'name': 'mat_outline:event'
    },
    {
        'name': 'mat_outline:event_available'
    },
    {
        'name': 'mat_outline:event_busy'
    },
    {
        'name': 'mat_outline:event_note'
    },
    {
        'name': 'mat_outline:event_repeat'
    },
    {
        'name': 'mat_outline:event_seat'
    },
    {
        'name': 'mat_outline:ev_station'
    },
    {
        'name': 'mat_outline:exit_to_app'
    },
    {
        'name': 'mat_outline:expand'
    },
    {
        'name': 'mat_outline:expand_circle_down'
    },
    {
        'name': 'mat_outline:expand_less'
    },
    {
        'name': 'mat_outline:expand_more'
    },
    {
        'name': 'mat_outline:explicit'
    },
    {
        'name': 'mat_outline:explore'
    },
    {
        'name': 'mat_outline:explore_off'
    },
    {
        'name': 'mat_outline:exposure'
    },
    {
        'name': 'mat_outline:exposure_neg_1'
    },
    {
        'name': 'mat_outline:exposure_neg_2'
    },
    {
        'name': 'mat_outline:exposure_plus_1'
    },
    {
        'name': 'mat_outline:exposure_plus_2'
    },
    {
        'name': 'mat_outline:exposure_zero'
    },
    {
        'name': 'mat_outline:extension'
    },
    {
        'name': 'mat_outline:extension_off'
    },
    {
        'name': 'mat_outline:e_mobiledata'
    },
    {
        'name': 'mat_outline:face'
    },
    {
        'name': 'mat_outline:face_2'
    },
    {
        'name': 'mat_outline:face_3'
    },
    {
        'name': 'mat_outline:face_4'
    },
    {
        'name': 'mat_outline:face_5'
    },
    {
        'name': 'mat_outline:face_6'
    },
    {
        'name': 'mat_outline:face_retouching_natural'
    },
    {
        'name': 'mat_outline:face_retouching_off'
    },
    {
        'name': 'mat_outline:factory'
    },
    {
        'name': 'mat_outline:fact_check'
    },
    {
        'name': 'mat_outline:family_restroom'
    },
    {
        'name': 'mat_outline:fastfood'
    },
    {
        'name': 'mat_outline:fast_forward'
    },
    {
        'name': 'mat_outline:fast_rewind'
    },
    {
        'name': 'mat_outline:favorite'
    },
    {
        'name': 'mat_outline:favorite_border'
    },
    {
        'name': 'mat_outline:fax'
    },
    {
        'name': 'mat_outline:featured_play_list'
    },
    {
        'name': 'mat_outline:featured_video'
    },
    {
        'name': 'mat_outline:feed'
    },
    {
        'name': 'mat_outline:feedback'
    },
    {
        'name': 'mat_outline:female'
    },
    {
        'name': 'mat_outline:fence'
    },
    {
        'name': 'mat_outline:festival'
    },
    {
        'name': 'mat_outline:fiber_dvr'
    },
    {
        'name': 'mat_outline:fiber_manual_record'
    },
    {
        'name': 'mat_outline:fiber_new'
    },
    {
        'name': 'mat_outline:fiber_pin'
    },
    {
        'name': 'mat_outline:fiber_smart_record'
    },
    {
        'name': 'mat_outline:file_copy'
    },
    {
        'name': 'mat_outline:file_download'
    },
    {
        'name': 'mat_outline:file_download_done'
    },
    {
        'name': 'mat_outline:file_download_off'
    },
    {
        'name': 'mat_outline:file_open'
    },
    {
        'name': 'mat_outline:file_present'
    },
    {
        'name': 'mat_outline:file_upload'
    },
    {
        'name': 'mat_outline:filter'
    },
    {
        'name': 'mat_outline:filter_1'
    },
    {
        'name': 'mat_outline:filter_2'
    },
    {
        'name': 'mat_outline:filter_3'
    },
    {
        'name': 'mat_outline:filter_4'
    },
    {
        'name': 'mat_outline:filter_5'
    },
    {
        'name': 'mat_outline:filter_6'
    },
    {
        'name': 'mat_outline:filter_7'
    },
    {
        'name': 'mat_outline:filter_8'
    },
    {
        'name': 'mat_outline:filter_9'
    },
    {
        'name': 'mat_outline:filter_9_plus'
    },
    {
        'name': 'mat_outline:filter_alt'
    },
    {
        'name': 'mat_outline:filter_alt_off'
    },
    {
        'name': 'mat_outline:filter_b_and_w'
    },
    {
        'name': 'mat_outline:filter_center_focus'
    },
    {
        'name': 'mat_outline:filter_drama'
    },
    {
        'name': 'mat_outline:filter_frames'
    },
    {
        'name': 'mat_outline:filter_hdr'
    },
    {
        'name': 'mat_outline:filter_list'
    },
    {
        'name': 'mat_outline:filter_list_off'
    },
    {
        'name': 'mat_outline:filter_none'
    },
    {
        'name': 'mat_outline:filter_tilt_shift'
    },
    {
        'name': 'mat_outline:filter_vintage'
    },
    {
        'name': 'mat_outline:find_in_page'
    },
    {
        'name': 'mat_outline:find_replace'
    },
    {
        'name': 'mat_outline:fingerprint'
    },
    {
        'name': 'mat_outline:fireplace'
    },
    {
        'name': 'mat_outline:fire_extinguisher'
    },
    {
        'name': 'mat_outline:fire_hydrant_alt'
    },
    {
        'name': 'mat_outline:fire_truck'
    },
    {
        'name': 'mat_outline:first_page'
    },
    {
        'name': 'mat_outline:fitbit'
    },
    {
        'name': 'mat_outline:fitness_center'
    },
    {
        'name': 'mat_outline:fit_screen'
    },
    {
        'name': 'mat_outline:flag'
    },
    {
        'name': 'mat_outline:flag_circle'
    },
    {
        'name': 'mat_outline:flaky'
    },
    {
        'name': 'mat_outline:flare'
    },
    {
        'name': 'mat_outline:flashlight_off'
    },
    {
        'name': 'mat_outline:flashlight_on'
    },
    {
        'name': 'mat_outline:flash_auto'
    },
    {
        'name': 'mat_outline:flash_off'
    },
    {
        'name': 'mat_outline:flash_on'
    },
    {
        'name': 'mat_outline:flatware'
    },
    {
        'name': 'mat_outline:flight'
    },
    {
        'name': 'mat_outline:flight_class'
    },
    {
        'name': 'mat_outline:flight_land'
    },
    {
        'name': 'mat_outline:flight_takeoff'
    },
    {
        'name': 'mat_outline:flip'
    },
    {
        'name': 'mat_outline:flip_camera_android'
    },
    {
        'name': 'mat_outline:flip_camera_ios'
    },
    {
        'name': 'mat_outline:flip_to_back'
    },
    {
        'name': 'mat_outline:flip_to_front'
    },
    {
        'name': 'mat_outline:flood'
    },
    {
        'name': 'mat_outline:fluorescent'
    },
    {
        'name': 'mat_outline:flutter_dash'
    },
    {
        'name': 'mat_outline:fmd_bad'
    },
    {
        'name': 'mat_outline:fmd_good'
    },
    {
        'name': 'mat_outline:folder'
    },
    {
        'name': 'mat_outline:folder_copy'
    },
    {
        'name': 'mat_outline:folder_delete'
    },
    {
        'name': 'mat_outline:folder_off'
    },
    {
        'name': 'mat_outline:folder_open'
    },
    {
        'name': 'mat_outline:folder_shared'
    },
    {
        'name': 'mat_outline:folder_special'
    },
    {
        'name': 'mat_outline:folder_zip'
    },
    {
        'name': 'mat_outline:follow_the_signs'
    },
    {
        'name': 'mat_outline:font_download'
    },
    {
        'name': 'mat_outline:font_download_off'
    },
    {
        'name': 'mat_outline:food_bank'
    },
    {
        'name': 'mat_outline:forest'
    },
    {
        'name': 'mat_outline:fork_left'
    },
    {
        'name': 'mat_outline:fork_right'
    },
    {
        'name': 'mat_outline:format_align_center'
    },
    {
        'name': 'mat_outline:format_align_justify'
    },
    {
        'name': 'mat_outline:format_align_left'
    },
    {
        'name': 'mat_outline:format_align_right'
    },
    {
        'name': 'mat_outline:format_bold'
    },
    {
        'name': 'mat_outline:format_clear'
    },
    {
        'name': 'mat_outline:format_color_fill'
    },
    {
        'name': 'mat_outline:format_color_reset'
    },
    {
        'name': 'mat_outline:format_color_text'
    },
    {
        'name': 'mat_outline:format_indent_decrease'
    },
    {
        'name': 'mat_outline:format_indent_increase'
    },
    {
        'name': 'mat_outline:format_italic'
    },
    {
        'name': 'mat_outline:format_line_spacing'
    },
    {
        'name': 'mat_outline:format_list_bulleted'
    },
    {
        'name': 'mat_outline:format_list_numbered'
    },
    {
        'name': 'mat_outline:format_list_numbered_rtl'
    },
    {
        'name': 'mat_outline:format_overline'
    },
    {
        'name': 'mat_outline:format_paint'
    },
    {
        'name': 'mat_outline:format_quote'
    },
    {
        'name': 'mat_outline:format_shapes'
    },
    {
        'name': 'mat_outline:format_size'
    },
    {
        'name': 'mat_outline:format_strikethrough'
    },
    {
        'name': 'mat_outline:format_textdirection_l_to_r'
    },
    {
        'name': 'mat_outline:format_textdirection_r_to_l'
    },
    {
        'name': 'mat_outline:format_underlined'
    },
    {
        'name': 'mat_outline:fort'
    },
    {
        'name': 'mat_outline:forum'
    },
    {
        'name': 'mat_outline:forward'
    },
    {
        'name': 'mat_outline:forward_10'
    },
    {
        'name': 'mat_outline:forward_30'
    },
    {
        'name': 'mat_outline:forward_5'
    },
    {
        'name': 'mat_outline:forward_to_inbox'
    },
    {
        'name': 'mat_outline:foundation'
    },
    {
        'name': 'mat_outline:free_breakfast'
    },
    {
        'name': 'mat_outline:free_cancellation'
    },
    {
        'name': 'mat_outline:front_hand'
    },
    {
        'name': 'mat_outline:fullscreen'
    },
    {
        'name': 'mat_outline:fullscreen_exit'
    },
    {
        'name': 'mat_outline:functions'
    },
    {
        'name': 'mat_outline:gamepad'
    },
    {
        'name': 'mat_outline:games'
    },
    {
        'name': 'mat_outline:garage'
    },
    {
        'name': 'mat_outline:gas_meter'
    },
    {
        'name': 'mat_outline:gavel'
    },
    {
        'name': 'mat_outline:generating_tokens'
    },
    {
        'name': 'mat_outline:gesture'
    },
    {
        'name': 'mat_outline:get_app'
    },
    {
        'name': 'mat_outline:gif'
    },
    {
        'name': 'mat_outline:gif_box'
    },
    {
        'name': 'mat_outline:girl'
    },
    {
        'name': 'mat_outline:gite'
    },
    {
        'name': 'mat_outline:golf_course'
    },
    {
        'name': 'mat_outline:gpp_bad'
    },
    {
        'name': 'mat_outline:gpp_good'
    },
    {
        'name': 'mat_outline:gpp_maybe'
    },
    {
        'name': 'mat_outline:gps_fixed'
    },
    {
        'name': 'mat_outline:gps_not_fixed'
    },
    {
        'name': 'mat_outline:gps_off'
    },
    {
        'name': 'mat_outline:grade'
    },
    {
        'name': 'mat_outline:gradient'
    },
    {
        'name': 'mat_outline:grading'
    },
    {
        'name': 'mat_outline:grain'
    },
    {
        'name': 'mat_outline:graphic_eq'
    },
    {
        'name': 'mat_outline:grass'
    },
    {
        'name': 'mat_outline:grid_3x3'
    },
    {
        'name': 'mat_outline:grid_4x4'
    },
    {
        'name': 'mat_outline:grid_goldenratio'
    },
    {
        'name': 'mat_outline:grid_off'
    },
    {
        'name': 'mat_outline:grid_on'
    },
    {
        'name': 'mat_outline:grid_view'
    },
    {
        'name': 'mat_outline:group'
    },
    {
        'name': 'mat_outline:groups'
    },
    {
        'name': 'mat_outline:groups_2'
    },
    {
        'name': 'mat_outline:groups_3'
    },
    {
        'name': 'mat_outline:group_add'
    },
    {
        'name': 'mat_outline:group_off'
    },
    {
        'name': 'mat_outline:group_remove'
    },
    {
        'name': 'mat_outline:group_work'
    },
    {
        'name': 'mat_outline:g_mobiledata'
    },
    {
        'name': 'mat_outline:g_translate'
    },
    {
        'name': 'mat_outline:hail'
    },
    {
        'name': 'mat_outline:handshake'
    },
    {
        'name': 'mat_outline:handyman'
    },
    {
        'name': 'mat_outline:hardware'
    },
    {
        'name': 'mat_outline:hd'
    },
    {
        'name': 'mat_outline:hdr_auto'
    },
    {
        'name': 'mat_outline:hdr_auto_select'
    },
    {
        'name': 'mat_outline:hdr_enhanced_select'
    },
    {
        'name': 'mat_outline:hdr_off'
    },
    {
        'name': 'mat_outline:hdr_off_select'
    },
    {
        'name': 'mat_outline:hdr_on'
    },
    {
        'name': 'mat_outline:hdr_on_select'
    },
    {
        'name': 'mat_outline:hdr_plus'
    },
    {
        'name': 'mat_outline:hdr_strong'
    },
    {
        'name': 'mat_outline:hdr_weak'
    },
    {
        'name': 'mat_outline:headphones'
    },
    {
        'name': 'mat_outline:headphones_battery'
    },
    {
        'name': 'mat_outline:headset'
    },
    {
        'name': 'mat_outline:headset_mic'
    },
    {
        'name': 'mat_outline:headset_off'
    },
    {
        'name': 'mat_outline:healing'
    },
    {
        'name': 'mat_outline:health_and_safety'
    },
    {
        'name': 'mat_outline:hearing'
    },
    {
        'name': 'mat_outline:hearing_disabled'
    },
    {
        'name': 'mat_outline:heart_broken'
    },
    {
        'name': 'mat_outline:heat_pump'
    },
    {
        'name': 'mat_outline:height'
    },
    {
        'name': 'mat_outline:help'
    },
    {
        'name': 'mat_outline:help_center'
    },
    {
        'name': 'mat_outline:help_outline'
    },
    {
        'name': 'mat_outline:hevc'
    },
    {
        'name': 'mat_outline:hexagon'
    },
    {
        'name': 'mat_outline:hide_image'
    },
    {
        'name': 'mat_outline:hide_source'
    },
    {
        'name': 'mat_outline:highlight'
    },
    {
        'name': 'mat_outline:highlight_alt'
    },
    {
        'name': 'mat_outline:highlight_off'
    },
    {
        'name': 'mat_outline:high_quality'
    },
    {
        'name': 'mat_outline:hiking'
    },
    {
        'name': 'mat_outline:history'
    },
    {
        'name': 'mat_outline:history_edu'
    },
    {
        'name': 'mat_outline:history_toggle_off'
    },
    {
        'name': 'mat_outline:hive'
    },
    {
        'name': 'mat_outline:hls'
    },
    {
        'name': 'mat_outline:hls_off'
    },
    {
        'name': 'mat_outline:holiday_village'
    },
    {
        'name': 'mat_outline:home'
    },
    {
        'name': 'mat_outline:home_max'
    },
    {
        'name': 'mat_outline:home_mini'
    },
    {
        'name': 'mat_outline:home_repair_service'
    },
    {
        'name': 'mat_outline:home_work'
    },
    {
        'name': 'mat_outline:horizontal_distribute'
    },
    {
        'name': 'mat_outline:horizontal_rule'
    },
    {
        'name': 'mat_outline:horizontal_split'
    },
    {
        'name': 'mat_outline:hotel'
    },
    {
        'name': 'mat_outline:hotel_class'
    },
    {
        'name': 'mat_outline:hot_tub'
    },
    {
        'name': 'mat_outline:hourglass_bottom'
    },
    {
        'name': 'mat_outline:hourglass_disabled'
    },
    {
        'name': 'mat_outline:hourglass_empty'
    },
    {
        'name': 'mat_outline:hourglass_full'
    },
    {
        'name': 'mat_outline:hourglass_top'
    },
    {
        'name': 'mat_outline:house'
    },
    {
        'name': 'mat_outline:houseboat'
    },
    {
        'name': 'mat_outline:house_siding'
    },
    {
        'name': 'mat_outline:how_to_reg'
    },
    {
        'name': 'mat_outline:how_to_vote'
    },
    {
        'name': 'mat_outline:html'
    },
    {
        'name': 'mat_outline:http'
    },
    {
        'name': 'mat_outline:https'
    },
    {
        'name': 'mat_outline:hub'
    },
    {
        'name': 'mat_outline:hvac'
    },
    {
        'name': 'mat_outline:h_mobiledata'
    },
    {
        'name': 'mat_outline:h_plus_mobiledata'
    },
    {
        'name': 'mat_outline:icecream'
    },
    {
        'name': 'mat_outline:ice_skating'
    },
    {
        'name': 'mat_outline:image'
    },
    {
        'name': 'mat_outline:imagesearch_roller'
    },
    {
        'name': 'mat_outline:image_aspect_ratio'
    },
    {
        'name': 'mat_outline:image_not_supported'
    },
    {
        'name': 'mat_outline:image_search'
    },
    {
        'name': 'mat_outline:important_devices'
    },
    {
        'name': 'mat_outline:import_contacts'
    },
    {
        'name': 'mat_outline:import_export'
    },
    {
        'name': 'mat_outline:inbox'
    },
    {
        'name': 'mat_outline:incomplete_circle'
    },
    {
        'name': 'mat_outline:indeterminate_check_box'
    },
    {
        'name': 'mat_outline:info'
    },
    {
        'name': 'mat_outline:input'
    },
    {
        'name': 'mat_outline:insert_chart'
    },
    {
        'name': 'mat_outline:insert_chart_outlined'
    },
    {
        'name': 'mat_outline:insert_comment'
    },
    {
        'name': 'mat_outline:insert_drive_file'
    },
    {
        'name': 'mat_outline:insert_emoticon'
    },
    {
        'name': 'mat_outline:insert_invitation'
    },
    {
        'name': 'mat_outline:insert_link'
    },
    {
        'name': 'mat_outline:insert_page_break'
    },
    {
        'name': 'mat_outline:insert_photo'
    },
    {
        'name': 'mat_outline:insights'
    },
    {
        'name': 'mat_outline:install_desktop'
    },
    {
        'name': 'mat_outline:install_mobile'
    },
    {
        'name': 'mat_outline:integration_instructions'
    },
    {
        'name': 'mat_outline:interests'
    },
    {
        'name': 'mat_outline:interpreter_mode'
    },
    {
        'name': 'mat_outline:inventory'
    },
    {
        'name': 'mat_outline:inventory_2'
    },
    {
        'name': 'mat_outline:invert_colors'
    },
    {
        'name': 'mat_outline:invert_colors_off'
    },
    {
        'name': 'mat_outline:ios_share'
    },
    {
        'name': 'mat_outline:iron'
    },
    {
        'name': 'mat_outline:iso'
    },
    {
        'name': 'mat_outline:javascript'
    },
    {
        'name': 'mat_outline:join_full'
    },
    {
        'name': 'mat_outline:join_inner'
    },
    {
        'name': 'mat_outline:join_left'
    },
    {
        'name': 'mat_outline:join_right'
    },
    {
        'name': 'mat_outline:kayaking'
    },
    {
        'name': 'mat_outline:kebab_dining'
    },
    {
        'name': 'mat_outline:key'
    },
    {
        'name': 'mat_outline:keyboard'
    },
    {
        'name': 'mat_outline:keyboard_alt'
    },
    {
        'name': 'mat_outline:keyboard_arrow_down'
    },
    {
        'name': 'mat_outline:keyboard_arrow_left'
    },
    {
        'name': 'mat_outline:keyboard_arrow_right'
    },
    {
        'name': 'mat_outline:keyboard_arrow_up'
    },
    {
        'name': 'mat_outline:keyboard_backspace'
    },
    {
        'name': 'mat_outline:keyboard_capslock'
    },
    {
        'name': 'mat_outline:keyboard_command_key'
    },
    {
        'name': 'mat_outline:keyboard_control_key'
    },
    {
        'name': 'mat_outline:keyboard_double_arrow_down'
    },
    {
        'name': 'mat_outline:keyboard_double_arrow_left'
    },
    {
        'name': 'mat_outline:keyboard_double_arrow_right'
    },
    {
        'name': 'mat_outline:keyboard_double_arrow_up'
    },
    {
        'name': 'mat_outline:keyboard_hide'
    },
    {
        'name': 'mat_outline:keyboard_option_key'
    },
    {
        'name': 'mat_outline:keyboard_return'
    },
    {
        'name': 'mat_outline:keyboard_tab'
    },
    {
        'name': 'mat_outline:keyboard_voice'
    },
    {
        'name': 'mat_outline:key_off'
    },
    {
        'name': 'mat_outline:king_bed'
    },
    {
        'name': 'mat_outline:kitchen'
    },
    {
        'name': 'mat_outline:kitesurfing'
    },
    {
        'name': 'mat_outline:label'
    },
    {
        'name': 'mat_outline:label_important'
    },
    {
        'name': 'mat_outline:label_off'
    },
    {
        'name': 'mat_outline:lan'
    },
    {
        'name': 'mat_outline:landscape'
    },
    {
        'name': 'mat_outline:landslide'
    },
    {
        'name': 'mat_outline:language'
    },
    {
        'name': 'mat_outline:laptop'
    },
    {
        'name': 'mat_outline:laptop_chromebook'
    },
    {
        'name': 'mat_outline:laptop_mac'
    },
    {
        'name': 'mat_outline:laptop_windows'
    },
    {
        'name': 'mat_outline:last_page'
    },
    {
        'name': 'mat_outline:launch'
    },
    {
        'name': 'mat_outline:layers'
    },
    {
        'name': 'mat_outline:layers_clear'
    },
    {
        'name': 'mat_outline:leaderboard'
    },
    {
        'name': 'mat_outline:leak_add'
    },
    {
        'name': 'mat_outline:leak_remove'
    },
    {
        'name': 'mat_outline:legend_toggle'
    },
    {
        'name': 'mat_outline:lens'
    },
    {
        'name': 'mat_outline:lens_blur'
    },
    {
        'name': 'mat_outline:library_add'
    },
    {
        'name': 'mat_outline:library_add_check'
    },
    {
        'name': 'mat_outline:library_books'
    },
    {
        'name': 'mat_outline:library_music'
    },
    {
        'name': 'mat_outline:light'
    },
    {
        'name': 'mat_outline:lightbulb'
    },
    {
        'name': 'mat_outline:lightbulb_circle'
    },
    {
        'name': 'mat_outline:light_mode'
    },
    {
        'name': 'mat_outline:linear_scale'
    },
    {
        'name': 'mat_outline:line_axis'
    },
    {
        'name': 'mat_outline:line_style'
    },
    {
        'name': 'mat_outline:line_weight'
    },
    {
        'name': 'mat_outline:link'
    },
    {
        'name': 'mat_outline:linked_camera'
    },
    {
        'name': 'mat_outline:link_off'
    },
    {
        'name': 'mat_outline:liquor'
    },
    {
        'name': 'mat_outline:list'
    },
    {
        'name': 'mat_outline:list_alt'
    },
    {
        'name': 'mat_outline:live_help'
    },
    {
        'name': 'mat_outline:live_tv'
    },
    {
        'name': 'mat_outline:living'
    },
    {
        'name': 'mat_outline:local_activity'
    },
    {
        'name': 'mat_outline:local_airport'
    },
    {
        'name': 'mat_outline:local_atm'
    },
    {
        'name': 'mat_outline:local_bar'
    },
    {
        'name': 'mat_outline:local_cafe'
    },
    {
        'name': 'mat_outline:local_car_wash'
    },
    {
        'name': 'mat_outline:local_convenience_store'
    },
    {
        'name': 'mat_outline:local_dining'
    },
    {
        'name': 'mat_outline:local_drink'
    },
    {
        'name': 'mat_outline:local_fire_department'
    },
    {
        'name': 'mat_outline:local_florist'
    },
    {
        'name': 'mat_outline:local_gas_station'
    },
    {
        'name': 'mat_outline:local_grocery_store'
    },
    {
        'name': 'mat_outline:local_hospital'
    },
    {
        'name': 'mat_outline:local_hotel'
    },
    {
        'name': 'mat_outline:local_laundry_service'
    },
    {
        'name': 'mat_outline:local_library'
    },
    {
        'name': 'mat_outline:local_mall'
    },
    {
        'name': 'mat_outline:local_movies'
    },
    {
        'name': 'mat_outline:local_offer'
    },
    {
        'name': 'mat_outline:local_parking'
    },
    {
        'name': 'mat_outline:local_pharmacy'
    },
    {
        'name': 'mat_outline:local_phone'
    },
    {
        'name': 'mat_outline:local_pizza'
    },
    {
        'name': 'mat_outline:local_play'
    },
    {
        'name': 'mat_outline:local_police'
    },
    {
        'name': 'mat_outline:local_post_office'
    },
    {
        'name': 'mat_outline:local_printshop'
    },
    {
        'name': 'mat_outline:local_see'
    },
    {
        'name': 'mat_outline:local_shipping'
    },
    {
        'name': 'mat_outline:local_taxi'
    },
    {
        'name': 'mat_outline:location_city'
    },
    {
        'name': 'mat_outline:location_disabled'
    },
    {
        'name': 'mat_outline:location_off'
    },
    {
        'name': 'mat_outline:location_on'
    },
    {
        'name': 'mat_outline:location_searching'
    },
    {
        'name': 'mat_outline:lock'
    },
    {
        'name': 'mat_outline:lock_clock'
    },
    {
        'name': 'mat_outline:lock_open'
    },
    {
        'name': 'mat_outline:lock_person'
    },
    {
        'name': 'mat_outline:lock_reset'
    },
    {
        'name': 'mat_outline:login'
    },
    {
        'name': 'mat_outline:logout'
    },
    {
        'name': 'mat_outline:logo_dev'
    },
    {
        'name': 'mat_outline:looks'
    },
    {
        'name': 'mat_outline:looks_3'
    },
    {
        'name': 'mat_outline:looks_4'
    },
    {
        'name': 'mat_outline:looks_5'
    },
    {
        'name': 'mat_outline:looks_6'
    },
    {
        'name': 'mat_outline:looks_one'
    },
    {
        'name': 'mat_outline:looks_two'
    },
    {
        'name': 'mat_outline:loop'
    },
    {
        'name': 'mat_outline:loupe'
    },
    {
        'name': 'mat_outline:low_priority'
    },
    {
        'name': 'mat_outline:loyalty'
    },
    {
        'name': 'mat_outline:lte_mobiledata'
    },
    {
        'name': 'mat_outline:lte_plus_mobiledata'
    },
    {
        'name': 'mat_outline:luggage'
    },
    {
        'name': 'mat_outline:lunch_dining'
    },
    {
        'name': 'mat_outline:lyrics'
    },
    {
        'name': 'mat_outline:macro_off'
    },
    {
        'name': 'mat_outline:mail'
    },
    {
        'name': 'mat_outline:mail_lock'
    },
    {
        'name': 'mat_outline:mail_outline'
    },
    {
        'name': 'mat_outline:male'
    },
    {
        'name': 'mat_outline:man'
    },
    {
        'name': 'mat_outline:manage_accounts'
    },
    {
        'name': 'mat_outline:manage_history'
    },
    {
        'name': 'mat_outline:manage_search'
    },
    {
        'name': 'mat_outline:man_2'
    },
    {
        'name': 'mat_outline:man_3'
    },
    {
        'name': 'mat_outline:man_4'
    },
    {
        'name': 'mat_outline:map'
    },
    {
        'name': 'mat_outline:maps_home_work'
    },
    {
        'name': 'mat_outline:maps_ugc'
    },
    {
        'name': 'mat_outline:margin'
    },
    {
        'name': 'mat_outline:markunread'
    },
    {
        'name': 'mat_outline:markunread_mailbox'
    },
    {
        'name': 'mat_outline:mark_as_unread'
    },
    {
        'name': 'mat_outline:mark_chat_read'
    },
    {
        'name': 'mat_outline:mark_chat_unread'
    },
    {
        'name': 'mat_outline:mark_email_read'
    },
    {
        'name': 'mat_outline:mark_email_unread'
    },
    {
        'name': 'mat_outline:mark_unread_chat_alt'
    },
    {
        'name': 'mat_outline:masks'
    },
    {
        'name': 'mat_outline:maximize'
    },
    {
        'name': 'mat_outline:mediation'
    },
    {
        'name': 'mat_outline:media_bluetooth_off'
    },
    {
        'name': 'mat_outline:media_bluetooth_on'
    },
    {
        'name': 'mat_outline:medical_information'
    },
    {
        'name': 'mat_outline:medical_services'
    },
    {
        'name': 'mat_outline:medication'
    },
    {
        'name': 'mat_outline:medication_liquid'
    },
    {
        'name': 'mat_outline:meeting_room'
    },
    {
        'name': 'mat_outline:memory'
    },
    {
        'name': 'mat_outline:menu'
    },
    {
        'name': 'mat_outline:menu_book'
    },
    {
        'name': 'mat_outline:menu_open'
    },
    {
        'name': 'mat_outline:merge'
    },
    {
        'name': 'mat_outline:merge_type'
    },
    {
        'name': 'mat_outline:message'
    },
    {
        'name': 'mat_outline:mic'
    },
    {
        'name': 'mat_outline:microwave'
    },
    {
        'name': 'mat_outline:mic_external_off'
    },
    {
        'name': 'mat_outline:mic_external_on'
    },
    {
        'name': 'mat_outline:mic_none'
    },
    {
        'name': 'mat_outline:mic_off'
    },
    {
        'name': 'mat_outline:military_tech'
    },
    {
        'name': 'mat_outline:minimize'
    },
    {
        'name': 'mat_outline:minor_crash'
    },
    {
        'name': 'mat_outline:miscellaneous_services'
    },
    {
        'name': 'mat_outline:missed_video_call'
    },
    {
        'name': 'mat_outline:mms'
    },
    {
        'name': 'mat_outline:mobiledata_off'
    },
    {
        'name': 'mat_outline:mobile_friendly'
    },
    {
        'name': 'mat_outline:mobile_off'
    },
    {
        'name': 'mat_outline:mobile_screen_share'
    },
    {
        'name': 'mat_outline:mode'
    },
    {
        'name': 'mat_outline:model_training'
    },
    {
        'name': 'mat_outline:mode_comment'
    },
    {
        'name': 'mat_outline:mode_edit'
    },
    {
        'name': 'mat_outline:mode_edit_outline'
    },
    {
        'name': 'mat_outline:mode_fan_off'
    },
    {
        'name': 'mat_outline:mode_night'
    },
    {
        'name': 'mat_outline:mode_of_travel'
    },
    {
        'name': 'mat_outline:mode_standby'
    },
    {
        'name': 'mat_outline:monetization_on'
    },
    {
        'name': 'mat_outline:money'
    },
    {
        'name': 'mat_outline:money_off'
    },
    {
        'name': 'mat_outline:money_off_csred'
    },
    {
        'name': 'mat_outline:monitor'
    },
    {
        'name': 'mat_outline:monitor_heart'
    },
    {
        'name': 'mat_outline:monitor_weight'
    },
    {
        'name': 'mat_outline:monochrome_photos'
    },
    {
        'name': 'mat_outline:mood'
    },
    {
        'name': 'mat_outline:mood_bad'
    },
    {
        'name': 'mat_outline:moped'
    },
    {
        'name': 'mat_outline:more'
    },
    {
        'name': 'mat_outline:more_horiz'
    },
    {
        'name': 'mat_outline:more_time'
    },
    {
        'name': 'mat_outline:more_vert'
    },
    {
        'name': 'mat_outline:mosque'
    },
    {
        'name': 'mat_outline:motion_photos_auto'
    },
    {
        'name': 'mat_outline:motion_photos_off'
    },
    {
        'name': 'mat_outline:motion_photos_on'
    },
    {
        'name': 'mat_outline:motion_photos_pause'
    },
    {
        'name': 'mat_outline:motion_photos_paused'
    },
    {
        'name': 'mat_outline:mouse'
    },
    {
        'name': 'mat_outline:move_down'
    },
    {
        'name': 'mat_outline:move_to_inbox'
    },
    {
        'name': 'mat_outline:move_up'
    },
    {
        'name': 'mat_outline:movie'
    },
    {
        'name': 'mat_outline:movie_creation'
    },
    {
        'name': 'mat_outline:movie_filter'
    },
    {
        'name': 'mat_outline:moving'
    },
    {
        'name': 'mat_outline:mp'
    },
    {
        'name': 'mat_outline:multiline_chart'
    },
    {
        'name': 'mat_outline:multiple_stop'
    },
    {
        'name': 'mat_outline:museum'
    },
    {
        'name': 'mat_outline:music_note'
    },
    {
        'name': 'mat_outline:music_off'
    },
    {
        'name': 'mat_outline:music_video'
    },
    {
        'name': 'mat_outline:my_location'
    },
    {
        'name': 'mat_outline:nat'
    },
    {
        'name': 'mat_outline:nature'
    },
    {
        'name': 'mat_outline:nature_people'
    },
    {
        'name': 'mat_outline:navigate_before'
    },
    {
        'name': 'mat_outline:navigate_next'
    },
    {
        'name': 'mat_outline:navigation'
    },
    {
        'name': 'mat_outline:nearby_error'
    },
    {
        'name': 'mat_outline:nearby_off'
    },
    {
        'name': 'mat_outline:near_me'
    },
    {
        'name': 'mat_outline:near_me_disabled'
    },
    {
        'name': 'mat_outline:nest_cam_wired_stand'
    },
    {
        'name': 'mat_outline:network_cell'
    },
    {
        'name': 'mat_outline:network_check'
    },
    {
        'name': 'mat_outline:network_locked'
    },
    {
        'name': 'mat_outline:network_ping'
    },
    {
        'name': 'mat_outline:network_wifi'
    },
    {
        'name': 'mat_outline:network_wifi_1_bar'
    },
    {
        'name': 'mat_outline:network_wifi_2_bar'
    },
    {
        'name': 'mat_outline:network_wifi_3_bar'
    },
    {
        'name': 'mat_outline:newspaper'
    },
    {
        'name': 'mat_outline:new_label'
    },
    {
        'name': 'mat_outline:new_releases'
    },
    {
        'name': 'mat_outline:next_plan'
    },
    {
        'name': 'mat_outline:next_week'
    },
    {
        'name': 'mat_outline:nfc'
    },
    {
        'name': 'mat_outline:nightlife'
    },
    {
        'name': 'mat_outline:nightlight'
    },
    {
        'name': 'mat_outline:nightlight_round'
    },
    {
        'name': 'mat_outline:nights_stay'
    },
    {
        'name': 'mat_outline:night_shelter'
    },
    {
        'name': 'mat_outline:noise_aware'
    },
    {
        'name': 'mat_outline:noise_control_off'
    },
    {
        'name': 'mat_outline:nordic_walking'
    },
    {
        'name': 'mat_outline:north'
    },
    {
        'name': 'mat_outline:north_east'
    },
    {
        'name': 'mat_outline:north_west'
    },
    {
        'name': 'mat_outline:note'
    },
    {
        'name': 'mat_outline:notes'
    },
    {
        'name': 'mat_outline:note_add'
    },
    {
        'name': 'mat_outline:note_alt'
    },
    {
        'name': 'mat_outline:notifications'
    },
    {
        'name': 'mat_outline:notifications_active'
    },
    {
        'name': 'mat_outline:notifications_none'
    },
    {
        'name': 'mat_outline:notifications_off'
    },
    {
        'name': 'mat_outline:notifications_paused'
    },
    {
        'name': 'mat_outline:notification_add'
    },
    {
        'name': 'mat_outline:notification_important'
    },
    {
        'name': 'mat_outline:not_accessible'
    },
    {
        'name': 'mat_outline:not_interested'
    },
    {
        'name': 'mat_outline:not_listed_location'
    },
    {
        'name': 'mat_outline:not_started'
    },
    {
        'name': 'mat_outline:no_accounts'
    },
    {
        'name': 'mat_outline:no_adult_content'
    },
    {
        'name': 'mat_outline:no_backpack'
    },
    {
        'name': 'mat_outline:no_cell'
    },
    {
        'name': 'mat_outline:no_crash'
    },
    {
        'name': 'mat_outline:no_drinks'
    },
    {
        'name': 'mat_outline:no_encryption'
    },
    {
        'name': 'mat_outline:no_encryption_gmailerrorred'
    },
    {
        'name': 'mat_outline:no_flash'
    },
    {
        'name': 'mat_outline:no_food'
    },
    {
        'name': 'mat_outline:no_luggage'
    },
    {
        'name': 'mat_outline:no_meals'
    },
    {
        'name': 'mat_outline:no_meeting_room'
    },
    {
        'name': 'mat_outline:no_photography'
    },
    {
        'name': 'mat_outline:no_sim'
    },
    {
        'name': 'mat_outline:no_stroller'
    },
    {
        'name': 'mat_outline:no_transfer'
    },
    {
        'name': 'mat_outline:numbers'
    },
    {
        'name': 'mat_outline:offline_bolt'
    },
    {
        'name': 'mat_outline:offline_pin'
    },
    {
        'name': 'mat_outline:offline_share'
    },
    {
        'name': 'mat_outline:oil_barrel'
    },
    {
        'name': 'mat_outline:ondemand_video'
    },
    {
        'name': 'mat_outline:online_prediction'
    },
    {
        'name': 'mat_outline:on_device_training'
    },
    {
        'name': 'mat_outline:opacity'
    },
    {
        'name': 'mat_outline:open_in_browser'
    },
    {
        'name': 'mat_outline:open_in_full'
    },
    {
        'name': 'mat_outline:open_in_new'
    },
    {
        'name': 'mat_outline:open_in_new_off'
    },
    {
        'name': 'mat_outline:open_with'
    },
    {
        'name': 'mat_outline:other_houses'
    },
    {
        'name': 'mat_outline:outbound'
    },
    {
        'name': 'mat_outline:outbox'
    },
    {
        'name': 'mat_outline:outdoor_grill'
    },
    {
        'name': 'mat_outline:outlet'
    },
    {
        'name': 'mat_outline:outlined_flag'
    },
    {
        'name': 'mat_outline:output'
    },
    {
        'name': 'mat_outline:padding'
    },
    {
        'name': 'mat_outline:pages'
    },
    {
        'name': 'mat_outline:pageview'
    },
    {
        'name': 'mat_outline:paid'
    },
    {
        'name': 'mat_outline:palette'
    },
    {
        'name': 'mat_outline:panorama'
    },
    {
        'name': 'mat_outline:panorama_fish_eye'
    },
    {
        'name': 'mat_outline:panorama_horizontal'
    },
    {
        'name': 'mat_outline:panorama_horizontal_select'
    },
    {
        'name': 'mat_outline:panorama_photosphere'
    },
    {
        'name': 'mat_outline:panorama_photosphere_select'
    },
    {
        'name': 'mat_outline:panorama_vertical'
    },
    {
        'name': 'mat_outline:panorama_vertical_select'
    },
    {
        'name': 'mat_outline:panorama_wide_angle'
    },
    {
        'name': 'mat_outline:panorama_wide_angle_select'
    },
    {
        'name': 'mat_outline:pan_tool'
    },
    {
        'name': 'mat_outline:pan_tool_alt'
    },
    {
        'name': 'mat_outline:paragliding'
    },
    {
        'name': 'mat_outline:park'
    },
    {
        'name': 'mat_outline:party_mode'
    },
    {
        'name': 'mat_outline:password'
    },
    {
        'name': 'mat_outline:pattern'
    },
    {
        'name': 'mat_outline:pause'
    },
    {
        'name': 'mat_outline:pause_circle'
    },
    {
        'name': 'mat_outline:pause_circle_filled'
    },
    {
        'name': 'mat_outline:pause_circle_outline'
    },
    {
        'name': 'mat_outline:pause_presentation'
    },
    {
        'name': 'mat_outline:payment'
    },
    {
        'name': 'mat_outline:payments'
    },
    {
        'name': 'mat_outline:pedal_bike'
    },
    {
        'name': 'mat_outline:pending'
    },
    {
        'name': 'mat_outline:pending_actions'
    },
    {
        'name': 'mat_outline:pentagon'
    },
    {
        'name': 'mat_outline:people'
    },
    {
        'name': 'mat_outline:people_alt'
    },
    {
        'name': 'mat_outline:people_outline'
    },
    {
        'name': 'mat_outline:percent'
    },
    {
        'name': 'mat_outline:perm_camera_mic'
    },
    {
        'name': 'mat_outline:perm_contact_calendar'
    },
    {
        'name': 'mat_outline:perm_data_setting'
    },
    {
        'name': 'mat_outline:perm_device_information'
    },
    {
        'name': 'mat_outline:perm_identity'
    },
    {
        'name': 'mat_outline:perm_media'
    },
    {
        'name': 'mat_outline:perm_phone_msg'
    },
    {
        'name': 'mat_outline:perm_scan_wifi'
    },
    {
        'name': 'mat_outline:person'
    },
    {
        'name': 'mat_outline:personal_injury'
    },
    {
        'name': 'mat_outline:personal_video'
    },
    {
        'name': 'mat_outline:person_2'
    },
    {
        'name': 'mat_outline:person_3'
    },
    {
        'name': 'mat_outline:person_4'
    },
    {
        'name': 'mat_outline:person_add'
    },
    {
        'name': 'mat_outline:person_add_alt'
    },
    {
        'name': 'mat_outline:person_add_alt_1'
    },
    {
        'name': 'mat_outline:person_add_disabled'
    },
    {
        'name': 'mat_outline:person_off'
    },
    {
        'name': 'mat_outline:person_outline'
    },
    {
        'name': 'mat_outline:person_pin'
    },
    {
        'name': 'mat_outline:person_pin_circle'
    },
    {
        'name': 'mat_outline:person_remove'
    },
    {
        'name': 'mat_outline:person_remove_alt_1'
    },
    {
        'name': 'mat_outline:person_search'
    },
    {
        'name': 'mat_outline:pest_control'
    },
    {
        'name': 'mat_outline:pest_control_rodent'
    },
    {
        'name': 'mat_outline:pets'
    },
    {
        'name': 'mat_outline:phishing'
    },
    {
        'name': 'mat_outline:phone'
    },
    {
        'name': 'mat_outline:phonelink'
    },
    {
        'name': 'mat_outline:phonelink_erase'
    },
    {
        'name': 'mat_outline:phonelink_lock'
    },
    {
        'name': 'mat_outline:phonelink_off'
    },
    {
        'name': 'mat_outline:phonelink_ring'
    },
    {
        'name': 'mat_outline:phonelink_setup'
    },
    {
        'name': 'mat_outline:phone_android'
    },
    {
        'name': 'mat_outline:phone_bluetooth_speaker'
    },
    {
        'name': 'mat_outline:phone_callback'
    },
    {
        'name': 'mat_outline:phone_disabled'
    },
    {
        'name': 'mat_outline:phone_enabled'
    },
    {
        'name': 'mat_outline:phone_forwarded'
    },
    {
        'name': 'mat_outline:phone_iphone'
    },
    {
        'name': 'mat_outline:phone_locked'
    },
    {
        'name': 'mat_outline:phone_missed'
    },
    {
        'name': 'mat_outline:phone_paused'
    },
    {
        'name': 'mat_outline:photo'
    },
    {
        'name': 'mat_outline:photo_album'
    },
    {
        'name': 'mat_outline:photo_camera'
    },
    {
        'name': 'mat_outline:photo_camera_back'
    },
    {
        'name': 'mat_outline:photo_camera_front'
    },
    {
        'name': 'mat_outline:photo_filter'
    },
    {
        'name': 'mat_outline:photo_library'
    },
    {
        'name': 'mat_outline:photo_size_select_actual'
    },
    {
        'name': 'mat_outline:photo_size_select_large'
    },
    {
        'name': 'mat_outline:photo_size_select_small'
    },
    {
        'name': 'mat_outline:php'
    },
    {
        'name': 'mat_outline:piano'
    },
    {
        'name': 'mat_outline:piano_off'
    },
    {
        'name': 'mat_outline:picture_as_pdf'
    },
    {
        'name': 'mat_outline:picture_in_picture'
    },
    {
        'name': 'mat_outline:picture_in_picture_alt'
    },
    {
        'name': 'mat_outline:pie_chart'
    },
    {
        'name': 'mat_outline:pie_chart_outline'
    },
    {
        'name': 'mat_outline:pin'
    },
    {
        'name': 'mat_outline:pinch'
    },
    {
        'name': 'mat_outline:pin_drop'
    },
    {
        'name': 'mat_outline:pin_end'
    },
    {
        'name': 'mat_outline:pin_invoke'
    },
    {
        'name': 'mat_outline:pivot_table_chart'
    },
    {
        'name': 'mat_outline:pix'
    },
    {
        'name': 'mat_outline:place'
    },
    {
        'name': 'mat_outline:plagiarism'
    },
    {
        'name': 'mat_outline:playlist_add'
    },
    {
        'name': 'mat_outline:playlist_add_check'
    },
    {
        'name': 'mat_outline:playlist_add_check_circle'
    },
    {
        'name': 'mat_outline:playlist_add_circle'
    },
    {
        'name': 'mat_outline:playlist_play'
    },
    {
        'name': 'mat_outline:playlist_remove'
    },
    {
        'name': 'mat_outline:play_arrow'
    },
    {
        'name': 'mat_outline:play_circle'
    },
    {
        'name': 'mat_outline:play_circle_filled'
    },
    {
        'name': 'mat_outline:play_circle_outline'
    },
    {
        'name': 'mat_outline:play_disabled'
    },
    {
        'name': 'mat_outline:play_for_work'
    },
    {
        'name': 'mat_outline:play_lesson'
    },
    {
        'name': 'mat_outline:plumbing'
    },
    {
        'name': 'mat_outline:plus_one'
    },
    {
        'name': 'mat_outline:podcasts'
    },
    {
        'name': 'mat_outline:point_of_sale'
    },
    {
        'name': 'mat_outline:policy'
    },
    {
        'name': 'mat_outline:poll'
    },
    {
        'name': 'mat_outline:polyline'
    },
    {
        'name': 'mat_outline:polymer'
    },
    {
        'name': 'mat_outline:pool'
    },
    {
        'name': 'mat_outline:portable_wifi_off'
    },
    {
        'name': 'mat_outline:portrait'
    },
    {
        'name': 'mat_outline:post_add'
    },
    {
        'name': 'mat_outline:power'
    },
    {
        'name': 'mat_outline:power_input'
    },
    {
        'name': 'mat_outline:power_off'
    },
    {
        'name': 'mat_outline:power_settings_new'
    },
    {
        'name': 'mat_outline:precision_manufacturing'
    },
    {
        'name': 'mat_outline:pregnant_woman'
    },
    {
        'name': 'mat_outline:present_to_all'
    },
    {
        'name': 'mat_outline:preview'
    },
    {
        'name': 'mat_outline:price_change'
    },
    {
        'name': 'mat_outline:price_check'
    },
    {
        'name': 'mat_outline:print'
    },
    {
        'name': 'mat_outline:print_disabled'
    },
    {
        'name': 'mat_outline:priority_high'
    },
    {
        'name': 'mat_outline:privacy_tip'
    },
    {
        'name': 'mat_outline:private_connectivity'
    },
    {
        'name': 'mat_outline:production_quantity_limits'
    },
    {
        'name': 'mat_outline:propane'
    },
    {
        'name': 'mat_outline:propane_tank'
    },
    {
        'name': 'mat_outline:psychology'
    },
    {
        'name': 'mat_outline:psychology_alt'
    },
    {
        'name': 'mat_outline:public'
    },
    {
        'name': 'mat_outline:public_off'
    },
    {
        'name': 'mat_outline:publish'
    },
    {
        'name': 'mat_outline:published_with_changes'
    },
    {
        'name': 'mat_outline:punch_clock'
    },
    {
        'name': 'mat_outline:push_pin'
    },
    {
        'name': 'mat_outline:qr_code'
    },
    {
        'name': 'mat_outline:qr_code_2'
    },
    {
        'name': 'mat_outline:qr_code_scanner'
    },
    {
        'name': 'mat_outline:query_builder'
    },
    {
        'name': 'mat_outline:query_stats'
    },
    {
        'name': 'mat_outline:question_answer'
    },
    {
        'name': 'mat_outline:question_mark'
    },
    {
        'name': 'mat_outline:queue'
    },
    {
        'name': 'mat_outline:queue_music'
    },
    {
        'name': 'mat_outline:queue_play_next'
    },
    {
        'name': 'mat_outline:quickreply'
    },
    {
        'name': 'mat_outline:quiz'
    },
    {
        'name': 'mat_outline:radar'
    },
    {
        'name': 'mat_outline:radio'
    },
    {
        'name': 'mat_outline:radio_button_checked'
    },
    {
        'name': 'mat_outline:radio_button_unchecked'
    },
    {
        'name': 'mat_outline:railway_alert'
    },
    {
        'name': 'mat_outline:ramen_dining'
    },
    {
        'name': 'mat_outline:ramp_left'
    },
    {
        'name': 'mat_outline:ramp_right'
    },
    {
        'name': 'mat_outline:rate_review'
    },
    {
        'name': 'mat_outline:raw_off'
    },
    {
        'name': 'mat_outline:raw_on'
    },
    {
        'name': 'mat_outline:read_more'
    },
    {
        'name': 'mat_outline:real_estate_agent'
    },
    {
        'name': 'mat_outline:receipt'
    },
    {
        'name': 'mat_outline:receipt_long'
    },
    {
        'name': 'mat_outline:recent_actors'
    },
    {
        'name': 'mat_outline:recommend'
    },
    {
        'name': 'mat_outline:record_voice_over'
    },
    {
        'name': 'mat_outline:rectangle'
    },
    {
        'name': 'mat_outline:recycling'
    },
    {
        'name': 'mat_outline:redeem'
    },
    {
        'name': 'mat_outline:redo'
    },
    {
        'name': 'mat_outline:reduce_capacity'
    },
    {
        'name': 'mat_outline:refresh'
    },
    {
        'name': 'mat_outline:remember_me'
    },
    {
        'name': 'mat_outline:remove'
    },
    {
        'name': 'mat_outline:remove_circle'
    },
    {
        'name': 'mat_outline:remove_circle_outline'
    },
    {
        'name': 'mat_outline:remove_done'
    },
    {
        'name': 'mat_outline:remove_from_queue'
    },
    {
        'name': 'mat_outline:remove_moderator'
    },
    {
        'name': 'mat_outline:remove_red_eye'
    },
    {
        'name': 'mat_outline:remove_road'
    },
    {
        'name': 'mat_outline:remove_shopping_cart'
    },
    {
        'name': 'mat_outline:reorder'
    },
    {
        'name': 'mat_outline:repartition'
    },
    {
        'name': 'mat_outline:repeat'
    },
    {
        'name': 'mat_outline:repeat_on'
    },
    {
        'name': 'mat_outline:repeat_one'
    },
    {
        'name': 'mat_outline:repeat_one_on'
    },
    {
        'name': 'mat_outline:replay'
    },
    {
        'name': 'mat_outline:replay_10'
    },
    {
        'name': 'mat_outline:replay_30'
    },
    {
        'name': 'mat_outline:replay_5'
    },
    {
        'name': 'mat_outline:replay_circle_filled'
    },
    {
        'name': 'mat_outline:reply'
    },
    {
        'name': 'mat_outline:reply_all'
    },
    {
        'name': 'mat_outline:report'
    },
    {
        'name': 'mat_outline:report_gmailerrorred'
    },
    {
        'name': 'mat_outline:report_off'
    },
    {
        'name': 'mat_outline:report_problem'
    },
    {
        'name': 'mat_outline:request_page'
    },
    {
        'name': 'mat_outline:request_quote'
    },
    {
        'name': 'mat_outline:reset_tv'
    },
    {
        'name': 'mat_outline:restart_alt'
    },
    {
        'name': 'mat_outline:restaurant'
    },
    {
        'name': 'mat_outline:restaurant_menu'
    },
    {
        'name': 'mat_outline:restore'
    },
    {
        'name': 'mat_outline:restore_from_trash'
    },
    {
        'name': 'mat_outline:restore_page'
    },
    {
        'name': 'mat_outline:reviews'
    },
    {
        'name': 'mat_outline:rice_bowl'
    },
    {
        'name': 'mat_outline:ring_volume'
    },
    {
        'name': 'mat_outline:rocket'
    },
    {
        'name': 'mat_outline:rocket_launch'
    },
    {
        'name': 'mat_outline:roller_shades'
    },
    {
        'name': 'mat_outline:roller_shades_closed'
    },
    {
        'name': 'mat_outline:roller_skating'
    },
    {
        'name': 'mat_outline:roofing'
    },
    {
        'name': 'mat_outline:room'
    },
    {
        'name': 'mat_outline:room_preferences'
    },
    {
        'name': 'mat_outline:room_service'
    },
    {
        'name': 'mat_outline:rotate_90_degrees_ccw'
    },
    {
        'name': 'mat_outline:rotate_90_degrees_cw'
    },
    {
        'name': 'mat_outline:rotate_left'
    },
    {
        'name': 'mat_outline:rotate_right'
    },
    {
        'name': 'mat_outline:roundabout_left'
    },
    {
        'name': 'mat_outline:roundabout_right'
    },
    {
        'name': 'mat_outline:rounded_corner'
    },
    {
        'name': 'mat_outline:route'
    },
    {
        'name': 'mat_outline:router'
    },
    {
        'name': 'mat_outline:rowing'
    },
    {
        'name': 'mat_outline:rss_feed'
    },
    {
        'name': 'mat_outline:rsvp'
    },
    {
        'name': 'mat_outline:rtt'
    },
    {
        'name': 'mat_outline:rule'
    },
    {
        'name': 'mat_outline:rule_folder'
    },
    {
        'name': 'mat_outline:running_with_errors'
    },
    {
        'name': 'mat_outline:run_circle'
    },
    {
        'name': 'mat_outline:rv_hookup'
    },
    {
        'name': 'mat_outline:r_mobiledata'
    },
    {
        'name': 'mat_outline:safety_check'
    },
    {
        'name': 'mat_outline:safety_divider'
    },
    {
        'name': 'mat_outline:sailing'
    },
    {
        'name': 'mat_outline:sanitizer'
    },
    {
        'name': 'mat_outline:satellite'
    },
    {
        'name': 'mat_outline:satellite_alt'
    },
    {
        'name': 'mat_outline:save'
    },
    {
        'name': 'mat_outline:saved_search'
    },
    {
        'name': 'mat_outline:save_alt'
    },
    {
        'name': 'mat_outline:save_as'
    },
    {
        'name': 'mat_outline:savings'
    },
    {
        'name': 'mat_outline:scale'
    },
    {
        'name': 'mat_outline:scanner'
    },
    {
        'name': 'mat_outline:scatter_plot'
    },
    {
        'name': 'mat_outline:schedule'
    },
    {
        'name': 'mat_outline:schedule_send'
    },
    {
        'name': 'mat_outline:schema'
    },
    {
        'name': 'mat_outline:school'
    },
    {
        'name': 'mat_outline:science'
    },
    {
        'name': 'mat_outline:score'
    },
    {
        'name': 'mat_outline:scoreboard'
    },
    {
        'name': 'mat_outline:screenshot'
    },
    {
        'name': 'mat_outline:screenshot_monitor'
    },
    {
        'name': 'mat_outline:screen_lock_landscape'
    },
    {
        'name': 'mat_outline:screen_lock_portrait'
    },
    {
        'name': 'mat_outline:screen_lock_rotation'
    },
    {
        'name': 'mat_outline:screen_rotation'
    },
    {
        'name': 'mat_outline:screen_rotation_alt'
    },
    {
        'name': 'mat_outline:screen_search_desktop'
    },
    {
        'name': 'mat_outline:screen_share'
    },
    {
        'name': 'mat_outline:scuba_diving'
    },
    {
        'name': 'mat_outline:sd'
    },
    {
        'name': 'mat_outline:sd_card'
    },
    {
        'name': 'mat_outline:sd_card_alert'
    },
    {
        'name': 'mat_outline:sd_storage'
    },
    {
        'name': 'mat_outline:search'
    },
    {
        'name': 'mat_outline:search_off'
    },
    {
        'name': 'mat_outline:security'
    },
    {
        'name': 'mat_outline:security_update'
    },
    {
        'name': 'mat_outline:security_update_good'
    },
    {
        'name': 'mat_outline:security_update_warning'
    },
    {
        'name': 'mat_outline:segment'
    },
    {
        'name': 'mat_outline:select_all'
    },
    {
        'name': 'mat_outline:self_improvement'
    },
    {
        'name': 'mat_outline:sell'
    },
    {
        'name': 'mat_outline:send'
    },
    {
        'name': 'mat_outline:send_and_archive'
    },
    {
        'name': 'mat_outline:send_time_extension'
    },
    {
        'name': 'mat_outline:send_to_mobile'
    },
    {
        'name': 'mat_outline:sensors'
    },
    {
        'name': 'mat_outline:sensors_off'
    },
    {
        'name': 'mat_outline:sensor_door'
    },
    {
        'name': 'mat_outline:sensor_occupied'
    },
    {
        'name': 'mat_outline:sensor_window'
    },
    {
        'name': 'mat_outline:sentiment_dissatisfied'
    },
    {
        'name': 'mat_outline:sentiment_neutral'
    },
    {
        'name': 'mat_outline:sentiment_satisfied'
    },
    {
        'name': 'mat_outline:sentiment_satisfied_alt'
    },
    {
        'name': 'mat_outline:sentiment_very_dissatisfied'
    },
    {
        'name': 'mat_outline:sentiment_very_satisfied'
    },
    {
        'name': 'mat_outline:settings'
    },
    {
        'name': 'mat_outline:settings_accessibility'
    },
    {
        'name': 'mat_outline:settings_applications'
    },
    {
        'name': 'mat_outline:settings_backup_restore'
    },
    {
        'name': 'mat_outline:settings_bluetooth'
    },
    {
        'name': 'mat_outline:settings_brightness'
    },
    {
        'name': 'mat_outline:settings_cell'
    },
    {
        'name': 'mat_outline:settings_ethernet'
    },
    {
        'name': 'mat_outline:settings_input_antenna'
    },
    {
        'name': 'mat_outline:settings_input_component'
    },
    {
        'name': 'mat_outline:settings_input_composite'
    },
    {
        'name': 'mat_outline:settings_input_hdmi'
    },
    {
        'name': 'mat_outline:settings_input_svideo'
    },
    {
        'name': 'mat_outline:settings_overscan'
    },
    {
        'name': 'mat_outline:settings_phone'
    },
    {
        'name': 'mat_outline:settings_power'
    },
    {
        'name': 'mat_outline:settings_remote'
    },
    {
        'name': 'mat_outline:settings_suggest'
    },
    {
        'name': 'mat_outline:settings_system_daydream'
    },
    {
        'name': 'mat_outline:settings_voice'
    },
    {
        'name': 'mat_outline:set_meal'
    },
    {
        'name': 'mat_outline:severe_cold'
    },
    {
        'name': 'mat_outline:shape_line'
    },
    {
        'name': 'mat_outline:share'
    },
    {
        'name': 'mat_outline:share_location'
    },
    {
        'name': 'mat_outline:shield'
    },
    {
        'name': 'mat_outline:shield_moon'
    },
    {
        'name': 'mat_outline:shop'
    },
    {
        'name': 'mat_outline:shopping_bag'
    },
    {
        'name': 'mat_outline:shopping_basket'
    },
    {
        'name': 'mat_outline:shopping_cart'
    },
    {
        'name': 'mat_outline:shopping_cart_checkout'
    },
    {
        'name': 'mat_outline:shop_2'
    },
    {
        'name': 'mat_outline:shop_two'
    },
    {
        'name': 'mat_outline:shortcut'
    },
    {
        'name': 'mat_outline:short_text'
    },
    {
        'name': 'mat_outline:shower'
    },
    {
        'name': 'mat_outline:show_chart'
    },
    {
        'name': 'mat_outline:shuffle'
    },
    {
        'name': 'mat_outline:shuffle_on'
    },
    {
        'name': 'mat_outline:shutter_speed'
    },
    {
        'name': 'mat_outline:sick'
    },
    {
        'name': 'mat_outline:signal_cellular_0_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_4_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_alt'
    },
    {
        'name': 'mat_outline:signal_cellular_alt_1_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_alt_2_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_connected_no_internet_0_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_connected_no_internet_4_bar'
    },
    {
        'name': 'mat_outline:signal_cellular_nodata'
    },
    {
        'name': 'mat_outline:signal_cellular_no_sim'
    },
    {
        'name': 'mat_outline:signal_cellular_null'
    },
    {
        'name': 'mat_outline:signal_cellular_off'
    },
    {
        'name': 'mat_outline:signal_wifi_0_bar'
    },
    {
        'name': 'mat_outline:signal_wifi_4_bar'
    },
    {
        'name': 'mat_outline:signal_wifi_4_bar_lock'
    },
    {
        'name': 'mat_outline:signal_wifi_bad'
    },
    {
        'name': 'mat_outline:signal_wifi_connected_no_internet_4'
    },
    {
        'name': 'mat_outline:signal_wifi_off'
    },
    {
        'name': 'mat_outline:signal_wifi_statusbar_4_bar'
    },
    {
        'name': 'mat_outline:signal_wifi_statusbar_connected_no_internet_4'
    },
    {
        'name': 'mat_outline:signal_wifi_statusbar_null'
    },
    {
        'name': 'mat_outline:signpost'
    },
    {
        'name': 'mat_outline:sign_language'
    },
    {
        'name': 'mat_outline:sim_card'
    },
    {
        'name': 'mat_outline:sim_card_alert'
    },
    {
        'name': 'mat_outline:sim_card_download'
    },
    {
        'name': 'mat_outline:single_bed'
    },
    {
        'name': 'mat_outline:sip'
    },
    {
        'name': 'mat_outline:skateboarding'
    },
    {
        'name': 'mat_outline:skip_next'
    },
    {
        'name': 'mat_outline:skip_previous'
    },
    {
        'name': 'mat_outline:sledding'
    },
    {
        'name': 'mat_outline:slideshow'
    },
    {
        'name': 'mat_outline:slow_motion_video'
    },
    {
        'name': 'mat_outline:smartphone'
    },
    {
        'name': 'mat_outline:smart_button'
    },
    {
        'name': 'mat_outline:smart_display'
    },
    {
        'name': 'mat_outline:smart_screen'
    },
    {
        'name': 'mat_outline:smart_toy'
    },
    {
        'name': 'mat_outline:smoke_free'
    },
    {
        'name': 'mat_outline:smoking_rooms'
    },
    {
        'name': 'mat_outline:sms'
    },
    {
        'name': 'mat_outline:sms_failed'
    },
    {
        'name': 'mat_outline:snippet_folder'
    },
    {
        'name': 'mat_outline:snooze'
    },
    {
        'name': 'mat_outline:snowboarding'
    },
    {
        'name': 'mat_outline:snowmobile'
    },
    {
        'name': 'mat_outline:snowshoeing'
    },
    {
        'name': 'mat_outline:soap'
    },
    {
        'name': 'mat_outline:social_distance'
    },
    {
        'name': 'mat_outline:solar_power'
    },
    {
        'name': 'mat_outline:sort'
    },
    {
        'name': 'mat_outline:sort_by_alpha'
    },
    {
        'name': 'mat_outline:sos'
    },
    {
        'name': 'mat_outline:soup_kitchen'
    },
    {
        'name': 'mat_outline:source'
    },
    {
        'name': 'mat_outline:south'
    },
    {
        'name': 'mat_outline:south_america'
    },
    {
        'name': 'mat_outline:south_east'
    },
    {
        'name': 'mat_outline:south_west'
    },
    {
        'name': 'mat_outline:spa'
    },
    {
        'name': 'mat_outline:space_bar'
    },
    {
        'name': 'mat_outline:space_dashboard'
    },
    {
        'name': 'mat_outline:spatial_audio'
    },
    {
        'name': 'mat_outline:spatial_audio_off'
    },
    {
        'name': 'mat_outline:spatial_tracking'
    },
    {
        'name': 'mat_outline:speaker'
    },
    {
        'name': 'mat_outline:speaker_group'
    },
    {
        'name': 'mat_outline:speaker_notes'
    },
    {
        'name': 'mat_outline:speaker_notes_off'
    },
    {
        'name': 'mat_outline:speaker_phone'
    },
    {
        'name': 'mat_outline:speed'
    },
    {
        'name': 'mat_outline:spellcheck'
    },
    {
        'name': 'mat_outline:splitscreen'
    },
    {
        'name': 'mat_outline:spoke'
    },
    {
        'name': 'mat_outline:sports'
    },
    {
        'name': 'mat_outline:sports_bar'
    },
    {
        'name': 'mat_outline:sports_baseball'
    },
    {
        'name': 'mat_outline:sports_basketball'
    },
    {
        'name': 'mat_outline:sports_cricket'
    },
    {
        'name': 'mat_outline:sports_esports'
    },
    {
        'name': 'mat_outline:sports_football'
    },
    {
        'name': 'mat_outline:sports_golf'
    },
    {
        'name': 'mat_outline:sports_gymnastics'
    },
    {
        'name': 'mat_outline:sports_handball'
    },
    {
        'name': 'mat_outline:sports_hockey'
    },
    {
        'name': 'mat_outline:sports_kabaddi'
    },
    {
        'name': 'mat_outline:sports_martial_arts'
    },
    {
        'name': 'mat_outline:sports_mma'
    },
    {
        'name': 'mat_outline:sports_motorsports'
    },
    {
        'name': 'mat_outline:sports_rugby'
    },
    {
        'name': 'mat_outline:sports_score'
    },
    {
        'name': 'mat_outline:sports_soccer'
    },
    {
        'name': 'mat_outline:sports_tennis'
    },
    {
        'name': 'mat_outline:sports_volleyball'
    },
    {
        'name': 'mat_outline:square'
    },
    {
        'name': 'mat_outline:square_foot'
    },
    {
        'name': 'mat_outline:ssid_chart'
    },
    {
        'name': 'mat_outline:stacked_bar_chart'
    },
    {
        'name': 'mat_outline:stacked_line_chart'
    },
    {
        'name': 'mat_outline:stadium'
    },
    {
        'name': 'mat_outline:stairs'
    },
    {
        'name': 'mat_outline:star'
    },
    {
        'name': 'mat_outline:stars'
    },
    {
        'name': 'mat_outline:start'
    },
    {
        'name': 'mat_outline:star_border'
    },
    {
        'name': 'mat_outline:star_border_purple500'
    },
    {
        'name': 'mat_outline:star_half'
    },
    {
        'name': 'mat_outline:star_outline'
    },
    {
        'name': 'mat_outline:star_purple500'
    },
    {
        'name': 'mat_outline:star_rate'
    },
    {
        'name': 'mat_outline:stay_current_landscape'
    },
    {
        'name': 'mat_outline:stay_current_portrait'
    },
    {
        'name': 'mat_outline:stay_primary_landscape'
    },
    {
        'name': 'mat_outline:stay_primary_portrait'
    },
    {
        'name': 'mat_outline:sticky_note_2'
    },
    {
        'name': 'mat_outline:stop'
    },
    {
        'name': 'mat_outline:stop_circle'
    },
    {
        'name': 'mat_outline:stop_screen_share'
    },
    {
        'name': 'mat_outline:storage'
    },
    {
        'name': 'mat_outline:store'
    },
    {
        'name': 'mat_outline:storefront'
    },
    {
        'name': 'mat_outline:store_mall_directory'
    },
    {
        'name': 'mat_outline:storm'
    },
    {
        'name': 'mat_outline:straight'
    },
    {
        'name': 'mat_outline:straighten'
    },
    {
        'name': 'mat_outline:stream'
    },
    {
        'name': 'mat_outline:streetview'
    },
    {
        'name': 'mat_outline:strikethrough_s'
    },
    {
        'name': 'mat_outline:stroller'
    },
    {
        'name': 'mat_outline:style'
    },
    {
        'name': 'mat_outline:subdirectory_arrow_left'
    },
    {
        'name': 'mat_outline:subdirectory_arrow_right'
    },
    {
        'name': 'mat_outline:subject'
    },
    {
        'name': 'mat_outline:subscript'
    },
    {
        'name': 'mat_outline:subscriptions'
    },
    {
        'name': 'mat_outline:subtitles'
    },
    {
        'name': 'mat_outline:subtitles_off'
    },
    {
        'name': 'mat_outline:subway'
    },
    {
        'name': 'mat_outline:summarize'
    },
    {
        'name': 'mat_outline:superscript'
    },
    {
        'name': 'mat_outline:supervised_user_circle'
    },
    {
        'name': 'mat_outline:supervisor_account'
    },
    {
        'name': 'mat_outline:support'
    },
    {
        'name': 'mat_outline:support_agent'
    },
    {
        'name': 'mat_outline:surfing'
    },
    {
        'name': 'mat_outline:surround_sound'
    },
    {
        'name': 'mat_outline:swap_calls'
    },
    {
        'name': 'mat_outline:swap_horiz'
    },
    {
        'name': 'mat_outline:swap_horizontal_circle'
    },
    {
        'name': 'mat_outline:swap_vert'
    },
    {
        'name': 'mat_outline:swap_vertical_circle'
    },
    {
        'name': 'mat_outline:swipe'
    },
    {
        'name': 'mat_outline:swipe_down'
    },
    {
        'name': 'mat_outline:swipe_down_alt'
    },
    {
        'name': 'mat_outline:swipe_left'
    },
    {
        'name': 'mat_outline:swipe_left_alt'
    },
    {
        'name': 'mat_outline:swipe_right'
    },
    {
        'name': 'mat_outline:swipe_right_alt'
    },
    {
        'name': 'mat_outline:swipe_up'
    },
    {
        'name': 'mat_outline:swipe_up_alt'
    },
    {
        'name': 'mat_outline:swipe_vertical'
    },
    {
        'name': 'mat_outline:switch_access_shortcut'
    },
    {
        'name': 'mat_outline:switch_access_shortcut_add'
    },
    {
        'name': 'mat_outline:switch_account'
    },
    {
        'name': 'mat_outline:switch_camera'
    },
    {
        'name': 'mat_outline:switch_left'
    },
    {
        'name': 'mat_outline:switch_right'
    },
    {
        'name': 'mat_outline:switch_video'
    },
    {
        'name': 'mat_outline:synagogue'
    },
    {
        'name': 'mat_outline:sync'
    },
    {
        'name': 'mat_outline:sync_alt'
    },
    {
        'name': 'mat_outline:sync_disabled'
    },
    {
        'name': 'mat_outline:sync_lock'
    },
    {
        'name': 'mat_outline:sync_problem'
    },
    {
        'name': 'mat_outline:system_security_update'
    },
    {
        'name': 'mat_outline:system_security_update_good'
    },
    {
        'name': 'mat_outline:system_security_update_warning'
    },
    {
        'name': 'mat_outline:system_update'
    },
    {
        'name': 'mat_outline:system_update_alt'
    },
    {
        'name': 'mat_outline:tab'
    },
    {
        'name': 'mat_outline:tablet'
    },
    {
        'name': 'mat_outline:tablet_android'
    },
    {
        'name': 'mat_outline:tablet_mac'
    },
    {
        'name': 'mat_outline:table_bar'
    },
    {
        'name': 'mat_outline:table_chart'
    },
    {
        'name': 'mat_outline:table_restaurant'
    },
    {
        'name': 'mat_outline:table_rows'
    },
    {
        'name': 'mat_outline:table_view'
    },
    {
        'name': 'mat_outline:tab_unselected'
    },
    {
        'name': 'mat_outline:tag'
    },
    {
        'name': 'mat_outline:tag_faces'
    },
    {
        'name': 'mat_outline:takeout_dining'
    },
    {
        'name': 'mat_outline:tapas'
    },
    {
        'name': 'mat_outline:tap_and_play'
    },
    {
        'name': 'mat_outline:task'
    },
    {
        'name': 'mat_outline:task_alt'
    },
    {
        'name': 'mat_outline:taxi_alert'
    },
    {
        'name': 'mat_outline:temple_buddhist'
    },
    {
        'name': 'mat_outline:temple_hindu'
    },
    {
        'name': 'mat_outline:terminal'
    },
    {
        'name': 'mat_outline:terrain'
    },
    {
        'name': 'mat_outline:textsms'
    },
    {
        'name': 'mat_outline:texture'
    },
    {
        'name': 'mat_outline:text_decrease'
    },
    {
        'name': 'mat_outline:text_fields'
    },
    {
        'name': 'mat_outline:text_format'
    },
    {
        'name': 'mat_outline:text_increase'
    },
    {
        'name': 'mat_outline:text_rotate_up'
    },
    {
        'name': 'mat_outline:text_rotate_vertical'
    },
    {
        'name': 'mat_outline:text_rotation_angledown'
    },
    {
        'name': 'mat_outline:text_rotation_angleup'
    },
    {
        'name': 'mat_outline:text_rotation_down'
    },
    {
        'name': 'mat_outline:text_rotation_none'
    },
    {
        'name': 'mat_outline:text_snippet'
    },
    {
        'name': 'mat_outline:theaters'
    },
    {
        'name': 'mat_outline:theater_comedy'
    },
    {
        'name': 'mat_outline:thermostat'
    },
    {
        'name': 'mat_outline:thermostat_auto'
    },
    {
        'name': 'mat_outline:thumbs_up_down'
    },
    {
        'name': 'mat_outline:thumb_down'
    },
    {
        'name': 'mat_outline:thumb_down_alt'
    },
    {
        'name': 'mat_outline:thumb_down_off_alt'
    },
    {
        'name': 'mat_outline:thumb_up'
    },
    {
        'name': 'mat_outline:thumb_up_alt'
    },
    {
        'name': 'mat_outline:thumb_up_off_alt'
    },
    {
        'name': 'mat_outline:thunderstorm'
    },
    {
        'name': 'mat_outline:timelapse'
    },
    {
        'name': 'mat_outline:timeline'
    },
    {
        'name': 'mat_outline:timer'
    },
    {
        'name': 'mat_outline:timer_10'
    },
    {
        'name': 'mat_outline:timer_10_select'
    },
    {
        'name': 'mat_outline:timer_3'
    },
    {
        'name': 'mat_outline:timer_3_select'
    },
    {
        'name': 'mat_outline:timer_off'
    },
    {
        'name': 'mat_outline:time_to_leave'
    },
    {
        'name': 'mat_outline:tips_and_updates'
    },
    {
        'name': 'mat_outline:tire_repair'
    },
    {
        'name': 'mat_outline:title'
    },
    {
        'name': 'mat_outline:toc'
    },
    {
        'name': 'mat_outline:today'
    },
    {
        'name': 'mat_outline:toggle_off'
    },
    {
        'name': 'mat_outline:toggle_on'
    },
    {
        'name': 'mat_outline:token'
    },
    {
        'name': 'mat_outline:toll'
    },
    {
        'name': 'mat_outline:tonality'
    },
    {
        'name': 'mat_outline:topic'
    },
    {
        'name': 'mat_outline:tornado'
    },
    {
        'name': 'mat_outline:touch_app'
    },
    {
        'name': 'mat_outline:tour'
    },
    {
        'name': 'mat_outline:toys'
    },
    {
        'name': 'mat_outline:track_changes'
    },
    {
        'name': 'mat_outline:traffic'
    },
    {
        'name': 'mat_outline:train'
    },
    {
        'name': 'mat_outline:tram'
    },
    {
        'name': 'mat_outline:transcribe'
    },
    {
        'name': 'mat_outline:transfer_within_a_station'
    },
    {
        'name': 'mat_outline:transform'
    },
    {
        'name': 'mat_outline:transgender'
    },
    {
        'name': 'mat_outline:transit_enterexit'
    },
    {
        'name': 'mat_outline:translate'
    },
    {
        'name': 'mat_outline:travel_explore'
    },
    {
        'name': 'mat_outline:trending_down'
    },
    {
        'name': 'mat_outline:trending_flat'
    },
    {
        'name': 'mat_outline:trending_up'
    },
    {
        'name': 'mat_outline:trip_origin'
    },
    {
        'name': 'mat_outline:troubleshoot'
    },
    {
        'name': 'mat_outline:try'
    },
    {
        'name': 'mat_outline:tsunami'
    },
    {
        'name': 'mat_outline:tty'
    },
    {
        'name': 'mat_outline:tune'
    },
    {
        'name': 'mat_outline:tungsten'
    },
    {
        'name': 'mat_outline:turned_in'
    },
    {
        'name': 'mat_outline:turned_in_not'
    },
    {
        'name': 'mat_outline:turn_left'
    },
    {
        'name': 'mat_outline:turn_right'
    },
    {
        'name': 'mat_outline:turn_sharp_left'
    },
    {
        'name': 'mat_outline:turn_sharp_right'
    },
    {
        'name': 'mat_outline:turn_slight_left'
    },
    {
        'name': 'mat_outline:turn_slight_right'
    },
    {
        'name': 'mat_outline:tv'
    },
    {
        'name': 'mat_outline:tv_off'
    },
    {
        'name': 'mat_outline:two_wheeler'
    },
    {
        'name': 'mat_outline:type_specimen'
    },
    {
        'name': 'mat_outline:umbrella'
    },
    {
        'name': 'mat_outline:unarchive'
    },
    {
        'name': 'mat_outline:undo'
    },
    {
        'name': 'mat_outline:unfold_less'
    },
    {
        'name': 'mat_outline:unfold_less_double'
    },
    {
        'name': 'mat_outline:unfold_more'
    },
    {
        'name': 'mat_outline:unfold_more_double'
    },
    {
        'name': 'mat_outline:unpublished'
    },
    {
        'name': 'mat_outline:unsubscribe'
    },
    {
        'name': 'mat_outline:upcoming'
    },
    {
        'name': 'mat_outline:update'
    },
    {
        'name': 'mat_outline:update_disabled'
    },
    {
        'name': 'mat_outline:upgrade'
    },
    {
        'name': 'mat_outline:upload'
    },
    {
        'name': 'mat_outline:upload_file'
    },
    {
        'name': 'mat_outline:usb'
    },
    {
        'name': 'mat_outline:usb_off'
    },
    {
        'name': 'mat_outline:u_turn_left'
    },
    {
        'name': 'mat_outline:u_turn_right'
    },
    {
        'name': 'mat_outline:vaccines'
    },
    {
        'name': 'mat_outline:vape_free'
    },
    {
        'name': 'mat_outline:vaping_rooms'
    },
    {
        'name': 'mat_outline:verified'
    },
    {
        'name': 'mat_outline:verified_user'
    },
    {
        'name': 'mat_outline:vertical_align_bottom'
    },
    {
        'name': 'mat_outline:vertical_align_center'
    },
    {
        'name': 'mat_outline:vertical_align_top'
    },
    {
        'name': 'mat_outline:vertical_distribute'
    },
    {
        'name': 'mat_outline:vertical_shades'
    },
    {
        'name': 'mat_outline:vertical_shades_closed'
    },
    {
        'name': 'mat_outline:vertical_split'
    },
    {
        'name': 'mat_outline:vibration'
    },
    {
        'name': 'mat_outline:videocam'
    },
    {
        'name': 'mat_outline:videocam_off'
    },
    {
        'name': 'mat_outline:videogame_asset'
    },
    {
        'name': 'mat_outline:videogame_asset_off'
    },
    {
        'name': 'mat_outline:video_call'
    },
    {
        'name': 'mat_outline:video_camera_back'
    },
    {
        'name': 'mat_outline:video_camera_front'
    },
    {
        'name': 'mat_outline:video_chat'
    },
    {
        'name': 'mat_outline:video_file'
    },
    {
        'name': 'mat_outline:video_label'
    },
    {
        'name': 'mat_outline:video_library'
    },
    {
        'name': 'mat_outline:video_settings'
    },
    {
        'name': 'mat_outline:video_stable'
    },
    {
        'name': 'mat_outline:view_agenda'
    },
    {
        'name': 'mat_outline:view_array'
    },
    {
        'name': 'mat_outline:view_carousel'
    },
    {
        'name': 'mat_outline:view_column'
    },
    {
        'name': 'mat_outline:view_comfy'
    },
    {
        'name': 'mat_outline:view_comfy_alt'
    },
    {
        'name': 'mat_outline:view_compact'
    },
    {
        'name': 'mat_outline:view_compact_alt'
    },
    {
        'name': 'mat_outline:view_cozy'
    },
    {
        'name': 'mat_outline:view_day'
    },
    {
        'name': 'mat_outline:view_headline'
    },
    {
        'name': 'mat_outline:view_in_ar'
    },
    {
        'name': 'mat_outline:view_kanban'
    },
    {
        'name': 'mat_outline:view_list'
    },
    {
        'name': 'mat_outline:view_module'
    },
    {
        'name': 'mat_outline:view_quilt'
    },
    {
        'name': 'mat_outline:view_sidebar'
    },
    {
        'name': 'mat_outline:view_stream'
    },
    {
        'name': 'mat_outline:view_timeline'
    },
    {
        'name': 'mat_outline:view_week'
    },
    {
        'name': 'mat_outline:vignette'
    },
    {
        'name': 'mat_outline:villa'
    },
    {
        'name': 'mat_outline:visibility'
    },
    {
        'name': 'mat_outline:visibility_off'
    },
    {
        'name': 'mat_outline:voicemail'
    },
    {
        'name': 'mat_outline:voice_chat'
    },
    {
        'name': 'mat_outline:voice_over_off'
    },
    {
        'name': 'mat_outline:volcano'
    },
    {
        'name': 'mat_outline:volume_down'
    },
    {
        'name': 'mat_outline:volume_mute'
    },
    {
        'name': 'mat_outline:volume_off'
    },
    {
        'name': 'mat_outline:volume_up'
    },
    {
        'name': 'mat_outline:volunteer_activism'
    },
    {
        'name': 'mat_outline:vpn_key'
    },
    {
        'name': 'mat_outline:vpn_key_off'
    },
    {
        'name': 'mat_outline:vpn_lock'
    },
    {
        'name': 'mat_outline:vrpano'
    },
    {
        'name': 'mat_outline:wallet'
    },
    {
        'name': 'mat_outline:wallpaper'
    },
    {
        'name': 'mat_outline:warehouse'
    },
    {
        'name': 'mat_outline:warning'
    },
    {
        'name': 'mat_outline:warning_amber'
    },
    {
        'name': 'mat_outline:wash'
    },
    {
        'name': 'mat_outline:watch'
    },
    {
        'name': 'mat_outline:watch_later'
    },
    {
        'name': 'mat_outline:watch_off'
    },
    {
        'name': 'mat_outline:water'
    },
    {
        'name': 'mat_outline:waterfall_chart'
    },
    {
        'name': 'mat_outline:water_damage'
    },
    {
        'name': 'mat_outline:water_drop'
    },
    {
        'name': 'mat_outline:waves'
    },
    {
        'name': 'mat_outline:waving_hand'
    },
    {
        'name': 'mat_outline:wb_auto'
    },
    {
        'name': 'mat_outline:wb_cloudy'
    },
    {
        'name': 'mat_outline:wb_incandescent'
    },
    {
        'name': 'mat_outline:wb_iridescent'
    },
    {
        'name': 'mat_outline:wb_shade'
    },
    {
        'name': 'mat_outline:wb_sunny'
    },
    {
        'name': 'mat_outline:wb_twilight'
    },
    {
        'name': 'mat_outline:wc'
    },
    {
        'name': 'mat_outline:web'
    },
    {
        'name': 'mat_outline:webhook'
    },
    {
        'name': 'mat_outline:web_asset'
    },
    {
        'name': 'mat_outline:web_asset_off'
    },
    {
        'name': 'mat_outline:web_stories'
    },
    {
        'name': 'mat_outline:weekend'
    },
    {
        'name': 'mat_outline:west'
    },
    {
        'name': 'mat_outline:whatshot'
    },
    {
        'name': 'mat_outline:wheelchair_pickup'
    },
    {
        'name': 'mat_outline:where_to_vote'
    },
    {
        'name': 'mat_outline:widgets'
    },
    {
        'name': 'mat_outline:width_full'
    },
    {
        'name': 'mat_outline:width_normal'
    },
    {
        'name': 'mat_outline:width_wide'
    },
    {
        'name': 'mat_outline:wifi'
    },
    {
        'name': 'mat_outline:wifi_1_bar'
    },
    {
        'name': 'mat_outline:wifi_2_bar'
    },
    {
        'name': 'mat_outline:wifi_calling'
    },
    {
        'name': 'mat_outline:wifi_calling_3'
    },
    {
        'name': 'mat_outline:wifi_channel'
    },
    {
        'name': 'mat_outline:wifi_find'
    },
    {
        'name': 'mat_outline:wifi_lock'
    },
    {
        'name': 'mat_outline:wifi_off'
    },
    {
        'name': 'mat_outline:wifi_password'
    },
    {
        'name': 'mat_outline:wifi_protected_setup'
    },
    {
        'name': 'mat_outline:wifi_tethering'
    },
    {
        'name': 'mat_outline:wifi_tethering_error'
    },
    {
        'name': 'mat_outline:wifi_tethering_off'
    },
    {
        'name': 'mat_outline:window'
    },
    {
        'name': 'mat_outline:wind_power'
    },
    {
        'name': 'mat_outline:wine_bar'
    },
    {
        'name': 'mat_outline:woman'
    },
    {
        'name': 'mat_outline:woman_2'
    },
    {
        'name': 'mat_outline:work'
    },
    {
        'name': 'mat_outline:workspaces'
    },
    {
        'name': 'mat_outline:workspace_premium'
    },
    {
        'name': 'mat_outline:work_history'
    },
    {
        'name': 'mat_outline:work_off'
    },
    {
        'name': 'mat_outline:work_outline'
    },
    {
        'name': 'mat_outline:wrap_text'
    },
    {
        'name': 'mat_outline:wrong_location'
    },
    {
        'name': 'mat_outline:wysiwyg'
    },
    {
        'name': 'mat_outline:yard'
    },
    {
        'name': 'mat_outline:youtube_searched_for'
    },
    {
        'name': 'mat_outline:zoom_in'
    },
    {
        'name': 'mat_outline:zoom_in_map'
    },
    {
        'name': 'mat_outline:zoom_out'
    },
    {
        'name': 'mat_outline:zoom_out_map'
    },
    {
        'name': 'mat_round:10k'
    },
    {
        'name': 'mat_round:10mp'
    },
    {
        'name': 'mat_round:11mp'
    },
    {
        'name': 'mat_round:123'
    },
    {
        'name': 'mat_round:12mp'
    },
    {
        'name': 'mat_round:13mp'
    },
    {
        'name': 'mat_round:14mp'
    },
    {
        'name': 'mat_round:15mp'
    },
    {
        'name': 'mat_round:16mp'
    },
    {
        'name': 'mat_round:17mp'
    },
    {
        'name': 'mat_round:18mp'
    },
    {
        'name': 'mat_round:18_up_rating'
    },
    {
        'name': 'mat_round:19mp'
    },
    {
        'name': 'mat_round:1k'
    },
    {
        'name': 'mat_round:1k_plus'
    },
    {
        'name': 'mat_round:1x_mobiledata'
    },
    {
        'name': 'mat_round:20mp'
    },
    {
        'name': 'mat_round:21mp'
    },
    {
        'name': 'mat_round:22mp'
    },
    {
        'name': 'mat_round:23mp'
    },
    {
        'name': 'mat_round:24mp'
    },
    {
        'name': 'mat_round:2k'
    },
    {
        'name': 'mat_round:2k_plus'
    },
    {
        'name': 'mat_round:2mp'
    },
    {
        'name': 'mat_round:30fps'
    },
    {
        'name': 'mat_round:30fps_select'
    },
    {
        'name': 'mat_round:360'
    },
    {
        'name': 'mat_round:3d_rotation'
    },
    {
        'name': 'mat_round:3g_mobiledata'
    },
    {
        'name': 'mat_round:3k'
    },
    {
        'name': 'mat_round:3k_plus'
    },
    {
        'name': 'mat_round:3mp'
    },
    {
        'name': 'mat_round:3p'
    },
    {
        'name': 'mat_round:4g_mobiledata'
    },
    {
        'name': 'mat_round:4g_plus_mobiledata'
    },
    {
        'name': 'mat_round:4k'
    },
    {
        'name': 'mat_round:4k_plus'
    },
    {
        'name': 'mat_round:4mp'
    },
    {
        'name': 'mat_round:5g'
    },
    {
        'name': 'mat_round:5k'
    },
    {
        'name': 'mat_round:5k_plus'
    },
    {
        'name': 'mat_round:5mp'
    },
    {
        'name': 'mat_round:60fps'
    },
    {
        'name': 'mat_round:60fps_select'
    },
    {
        'name': 'mat_round:6k'
    },
    {
        'name': 'mat_round:6k_plus'
    },
    {
        'name': 'mat_round:6mp'
    },
    {
        'name': 'mat_round:6_ft_apart'
    },
    {
        'name': 'mat_round:7k'
    },
    {
        'name': 'mat_round:7k_plus'
    },
    {
        'name': 'mat_round:7mp'
    },
    {
        'name': 'mat_round:8k'
    },
    {
        'name': 'mat_round:8k_plus'
    },
    {
        'name': 'mat_round:8mp'
    },
    {
        'name': 'mat_round:9k'
    },
    {
        'name': 'mat_round:9k_plus'
    },
    {
        'name': 'mat_round:9mp'
    },
    {
        'name': 'mat_round:abc'
    },
    {
        'name': 'mat_round:accessibility'
    },
    {
        'name': 'mat_round:accessibility_new'
    },
    {
        'name': 'mat_round:accessible'
    },
    {
        'name': 'mat_round:accessible_forward'
    },
    {
        'name': 'mat_round:access_alarm'
    },
    {
        'name': 'mat_round:access_alarms'
    },
    {
        'name': 'mat_round:access_time'
    },
    {
        'name': 'mat_round:access_time_filled'
    },
    {
        'name': 'mat_round:account_balance'
    },
    {
        'name': 'mat_round:account_balance_wallet'
    },
    {
        'name': 'mat_round:account_box'
    },
    {
        'name': 'mat_round:account_circle'
    },
    {
        'name': 'mat_round:account_tree'
    },
    {
        'name': 'mat_round:ac_unit'
    },
    {
        'name': 'mat_round:adb'
    },
    {
        'name': 'mat_round:add'
    },
    {
        'name': 'mat_round:addchart'
    },
    {
        'name': 'mat_round:add_alarm'
    },
    {
        'name': 'mat_round:add_alert'
    },
    {
        'name': 'mat_round:add_a_photo'
    },
    {
        'name': 'mat_round:add_box'
    },
    {
        'name': 'mat_round:add_business'
    },
    {
        'name': 'mat_round:add_card'
    },
    {
        'name': 'mat_round:add_chart'
    },
    {
        'name': 'mat_round:add_circle'
    },
    {
        'name': 'mat_round:add_circle_outline'
    },
    {
        'name': 'mat_round:add_comment'
    },
    {
        'name': 'mat_round:add_home'
    },
    {
        'name': 'mat_round:add_home_work'
    },
    {
        'name': 'mat_round:add_ic_call'
    },
    {
        'name': 'mat_round:add_link'
    },
    {
        'name': 'mat_round:add_location'
    },
    {
        'name': 'mat_round:add_location_alt'
    },
    {
        'name': 'mat_round:add_moderator'
    },
    {
        'name': 'mat_round:add_photo_alternate'
    },
    {
        'name': 'mat_round:add_reaction'
    },
    {
        'name': 'mat_round:add_road'
    },
    {
        'name': 'mat_round:add_shopping_cart'
    },
    {
        'name': 'mat_round:add_task'
    },
    {
        'name': 'mat_round:add_to_drive'
    },
    {
        'name': 'mat_round:add_to_home_screen'
    },
    {
        'name': 'mat_round:add_to_photos'
    },
    {
        'name': 'mat_round:add_to_queue'
    },
    {
        'name': 'mat_round:adf_scanner'
    },
    {
        'name': 'mat_round:adjust'
    },
    {
        'name': 'mat_round:admin_panel_settings'
    },
    {
        'name': 'mat_round:ads_click'
    },
    {
        'name': 'mat_round:ad_units'
    },
    {
        'name': 'mat_round:agriculture'
    },
    {
        'name': 'mat_round:air'
    },
    {
        'name': 'mat_round:airlines'
    },
    {
        'name': 'mat_round:airline_seat_flat'
    },
    {
        'name': 'mat_round:airline_seat_flat_angled'
    },
    {
        'name': 'mat_round:airline_seat_individual_suite'
    },
    {
        'name': 'mat_round:airline_seat_legroom_extra'
    },
    {
        'name': 'mat_round:airline_seat_legroom_normal'
    },
    {
        'name': 'mat_round:airline_seat_legroom_reduced'
    },
    {
        'name': 'mat_round:airline_seat_recline_extra'
    },
    {
        'name': 'mat_round:airline_seat_recline_normal'
    },
    {
        'name': 'mat_round:airline_stops'
    },
    {
        'name': 'mat_round:airplanemode_active'
    },
    {
        'name': 'mat_round:airplanemode_inactive'
    },
    {
        'name': 'mat_round:airplane_ticket'
    },
    {
        'name': 'mat_round:airplay'
    },
    {
        'name': 'mat_round:airport_shuttle'
    },
    {
        'name': 'mat_round:alarm'
    },
    {
        'name': 'mat_round:alarm_add'
    },
    {
        'name': 'mat_round:alarm_off'
    },
    {
        'name': 'mat_round:alarm_on'
    },
    {
        'name': 'mat_round:album'
    },
    {
        'name': 'mat_round:align_horizontal_center'
    },
    {
        'name': 'mat_round:align_horizontal_left'
    },
    {
        'name': 'mat_round:align_horizontal_right'
    },
    {
        'name': 'mat_round:align_vertical_bottom'
    },
    {
        'name': 'mat_round:align_vertical_center'
    },
    {
        'name': 'mat_round:align_vertical_top'
    },
    {
        'name': 'mat_round:all_inbox'
    },
    {
        'name': 'mat_round:all_inclusive'
    },
    {
        'name': 'mat_round:all_out'
    },
    {
        'name': 'mat_round:alternate_email'
    },
    {
        'name': 'mat_round:alt_route'
    },
    {
        'name': 'mat_round:analytics'
    },
    {
        'name': 'mat_round:anchor'
    },
    {
        'name': 'mat_round:android'
    },
    {
        'name': 'mat_round:animation'
    },
    {
        'name': 'mat_round:announcement'
    },
    {
        'name': 'mat_round:aod'
    },
    {
        'name': 'mat_round:apartment'
    },
    {
        'name': 'mat_round:api'
    },
    {
        'name': 'mat_round:approval'
    },
    {
        'name': 'mat_round:apps'
    },
    {
        'name': 'mat_round:apps_outage'
    },
    {
        'name': 'mat_round:app_blocking'
    },
    {
        'name': 'mat_round:app_registration'
    },
    {
        'name': 'mat_round:app_settings_alt'
    },
    {
        'name': 'mat_round:app_shortcut'
    },
    {
        'name': 'mat_round:architecture'
    },
    {
        'name': 'mat_round:archive'
    },
    {
        'name': 'mat_round:area_chart'
    },
    {
        'name': 'mat_round:arrow_back'
    },
    {
        'name': 'mat_round:arrow_back_ios'
    },
    {
        'name': 'mat_round:arrow_back_ios_new'
    },
    {
        'name': 'mat_round:arrow_circle_down'
    },
    {
        'name': 'mat_round:arrow_circle_left'
    },
    {
        'name': 'mat_round:arrow_circle_right'
    },
    {
        'name': 'mat_round:arrow_circle_up'
    },
    {
        'name': 'mat_round:arrow_downward'
    },
    {
        'name': 'mat_round:arrow_drop_down'
    },
    {
        'name': 'mat_round:arrow_drop_down_circle'
    },
    {
        'name': 'mat_round:arrow_drop_up'
    },
    {
        'name': 'mat_round:arrow_forward'
    },
    {
        'name': 'mat_round:arrow_forward_ios'
    },
    {
        'name': 'mat_round:arrow_left'
    },
    {
        'name': 'mat_round:arrow_outward'
    },
    {
        'name': 'mat_round:arrow_right'
    },
    {
        'name': 'mat_round:arrow_right_alt'
    },
    {
        'name': 'mat_round:arrow_upward'
    },
    {
        'name': 'mat_round:article'
    },
    {
        'name': 'mat_round:art_track'
    },
    {
        'name': 'mat_round:aspect_ratio'
    },
    {
        'name': 'mat_round:assessment'
    },
    {
        'name': 'mat_round:assignment'
    },
    {
        'name': 'mat_round:assignment_ind'
    },
    {
        'name': 'mat_round:assignment_late'
    },
    {
        'name': 'mat_round:assignment_return'
    },
    {
        'name': 'mat_round:assignment_returned'
    },
    {
        'name': 'mat_round:assignment_turned_in'
    },
    {
        'name': 'mat_round:assistant'
    },
    {
        'name': 'mat_round:assistant_direction'
    },
    {
        'name': 'mat_round:assistant_photo'
    },
    {
        'name': 'mat_round:assist_walker'
    },
    {
        'name': 'mat_round:assured_workload'
    },
    {
        'name': 'mat_round:atm'
    },
    {
        'name': 'mat_round:attachment'
    },
    {
        'name': 'mat_round:attach_email'
    },
    {
        'name': 'mat_round:attach_file'
    },
    {
        'name': 'mat_round:attach_money'
    },
    {
        'name': 'mat_round:attractions'
    },
    {
        'name': 'mat_round:attribution'
    },
    {
        'name': 'mat_round:audiotrack'
    },
    {
        'name': 'mat_round:audio_file'
    },
    {
        'name': 'mat_round:autofps_select'
    },
    {
        'name': 'mat_round:autorenew'
    },
    {
        'name': 'mat_round:auto_awesome'
    },
    {
        'name': 'mat_round:auto_awesome_mosaic'
    },
    {
        'name': 'mat_round:auto_awesome_motion'
    },
    {
        'name': 'mat_round:auto_delete'
    },
    {
        'name': 'mat_round:auto_fix_high'
    },
    {
        'name': 'mat_round:auto_fix_normal'
    },
    {
        'name': 'mat_round:auto_fix_off'
    },
    {
        'name': 'mat_round:auto_graph'
    },
    {
        'name': 'mat_round:auto_mode'
    },
    {
        'name': 'mat_round:auto_stories'
    },
    {
        'name': 'mat_round:av_timer'
    },
    {
        'name': 'mat_round:baby_changing_station'
    },
    {
        'name': 'mat_round:backpack'
    },
    {
        'name': 'mat_round:backspace'
    },
    {
        'name': 'mat_round:backup'
    },
    {
        'name': 'mat_round:backup_table'
    },
    {
        'name': 'mat_round:back_hand'
    },
    {
        'name': 'mat_round:badge'
    },
    {
        'name': 'mat_round:bakery_dining'
    },
    {
        'name': 'mat_round:balance'
    },
    {
        'name': 'mat_round:balcony'
    },
    {
        'name': 'mat_round:ballot'
    },
    {
        'name': 'mat_round:bar_chart'
    },
    {
        'name': 'mat_round:batch_prediction'
    },
    {
        'name': 'mat_round:bathroom'
    },
    {
        'name': 'mat_round:bathtub'
    },
    {
        'name': 'mat_round:battery_0_bar'
    },
    {
        'name': 'mat_round:battery_1_bar'
    },
    {
        'name': 'mat_round:battery_2_bar'
    },
    {
        'name': 'mat_round:battery_3_bar'
    },
    {
        'name': 'mat_round:battery_4_bar'
    },
    {
        'name': 'mat_round:battery_5_bar'
    },
    {
        'name': 'mat_round:battery_6_bar'
    },
    {
        'name': 'mat_round:battery_alert'
    },
    {
        'name': 'mat_round:battery_charging_full'
    },
    {
        'name': 'mat_round:battery_full'
    },
    {
        'name': 'mat_round:battery_saver'
    },
    {
        'name': 'mat_round:battery_std'
    },
    {
        'name': 'mat_round:battery_unknown'
    },
    {
        'name': 'mat_round:beach_access'
    },
    {
        'name': 'mat_round:bed'
    },
    {
        'name': 'mat_round:bedroom_baby'
    },
    {
        'name': 'mat_round:bedroom_child'
    },
    {
        'name': 'mat_round:bedroom_parent'
    },
    {
        'name': 'mat_round:bedtime'
    },
    {
        'name': 'mat_round:bedtime_off'
    },
    {
        'name': 'mat_round:beenhere'
    },
    {
        'name': 'mat_round:bento'
    },
    {
        'name': 'mat_round:bike_scooter'
    },
    {
        'name': 'mat_round:biotech'
    },
    {
        'name': 'mat_round:blender'
    },
    {
        'name': 'mat_round:blind'
    },
    {
        'name': 'mat_round:blinds'
    },
    {
        'name': 'mat_round:blinds_closed'
    },
    {
        'name': 'mat_round:block'
    },
    {
        'name': 'mat_round:bloodtype'
    },
    {
        'name': 'mat_round:bluetooth'
    },
    {
        'name': 'mat_round:bluetooth_audio'
    },
    {
        'name': 'mat_round:bluetooth_connected'
    },
    {
        'name': 'mat_round:bluetooth_disabled'
    },
    {
        'name': 'mat_round:bluetooth_drive'
    },
    {
        'name': 'mat_round:bluetooth_searching'
    },
    {
        'name': 'mat_round:blur_circular'
    },
    {
        'name': 'mat_round:blur_linear'
    },
    {
        'name': 'mat_round:blur_off'
    },
    {
        'name': 'mat_round:blur_on'
    },
    {
        'name': 'mat_round:bolt'
    },
    {
        'name': 'mat_round:book'
    },
    {
        'name': 'mat_round:bookmark'
    },
    {
        'name': 'mat_round:bookmarks'
    },
    {
        'name': 'mat_round:bookmark_add'
    },
    {
        'name': 'mat_round:bookmark_added'
    },
    {
        'name': 'mat_round:bookmark_border'
    },
    {
        'name': 'mat_round:bookmark_remove'
    },
    {
        'name': 'mat_round:book_online'
    },
    {
        'name': 'mat_round:border_all'
    },
    {
        'name': 'mat_round:border_bottom'
    },
    {
        'name': 'mat_round:border_clear'
    },
    {
        'name': 'mat_round:border_color'
    },
    {
        'name': 'mat_round:border_horizontal'
    },
    {
        'name': 'mat_round:border_inner'
    },
    {
        'name': 'mat_round:border_left'
    },
    {
        'name': 'mat_round:border_outer'
    },
    {
        'name': 'mat_round:border_right'
    },
    {
        'name': 'mat_round:border_style'
    },
    {
        'name': 'mat_round:border_top'
    },
    {
        'name': 'mat_round:border_vertical'
    },
    {
        'name': 'mat_round:boy'
    },
    {
        'name': 'mat_round:branding_watermark'
    },
    {
        'name': 'mat_round:breakfast_dining'
    },
    {
        'name': 'mat_round:brightness_1'
    },
    {
        'name': 'mat_round:brightness_2'
    },
    {
        'name': 'mat_round:brightness_3'
    },
    {
        'name': 'mat_round:brightness_4'
    },
    {
        'name': 'mat_round:brightness_5'
    },
    {
        'name': 'mat_round:brightness_6'
    },
    {
        'name': 'mat_round:brightness_7'
    },
    {
        'name': 'mat_round:brightness_auto'
    },
    {
        'name': 'mat_round:brightness_high'
    },
    {
        'name': 'mat_round:brightness_low'
    },
    {
        'name': 'mat_round:brightness_medium'
    },
    {
        'name': 'mat_round:broadcast_on_home'
    },
    {
        'name': 'mat_round:broadcast_on_personal'
    },
    {
        'name': 'mat_round:broken_image'
    },
    {
        'name': 'mat_round:browser_not_supported'
    },
    {
        'name': 'mat_round:browser_updated'
    },
    {
        'name': 'mat_round:browse_gallery'
    },
    {
        'name': 'mat_round:brunch_dining'
    },
    {
        'name': 'mat_round:brush'
    },
    {
        'name': 'mat_round:bubble_chart'
    },
    {
        'name': 'mat_round:bug_report'
    },
    {
        'name': 'mat_round:build'
    },
    {
        'name': 'mat_round:build_circle'
    },
    {
        'name': 'mat_round:bungalow'
    },
    {
        'name': 'mat_round:burst_mode'
    },
    {
        'name': 'mat_round:business'
    },
    {
        'name': 'mat_round:business_center'
    },
    {
        'name': 'mat_round:bus_alert'
    },
    {
        'name': 'mat_round:cabin'
    },
    {
        'name': 'mat_round:cable'
    },
    {
        'name': 'mat_round:cached'
    },
    {
        'name': 'mat_round:cake'
    },
    {
        'name': 'mat_round:calculate'
    },
    {
        'name': 'mat_round:calendar_month'
    },
    {
        'name': 'mat_round:calendar_today'
    },
    {
        'name': 'mat_round:calendar_view_day'
    },
    {
        'name': 'mat_round:calendar_view_month'
    },
    {
        'name': 'mat_round:calendar_view_week'
    },
    {
        'name': 'mat_round:call'
    },
    {
        'name': 'mat_round:call_end'
    },
    {
        'name': 'mat_round:call_made'
    },
    {
        'name': 'mat_round:call_merge'
    },
    {
        'name': 'mat_round:call_missed'
    },
    {
        'name': 'mat_round:call_missed_outgoing'
    },
    {
        'name': 'mat_round:call_received'
    },
    {
        'name': 'mat_round:call_split'
    },
    {
        'name': 'mat_round:call_to_action'
    },
    {
        'name': 'mat_round:camera'
    },
    {
        'name': 'mat_round:cameraswitch'
    },
    {
        'name': 'mat_round:camera_alt'
    },
    {
        'name': 'mat_round:camera_enhance'
    },
    {
        'name': 'mat_round:camera_front'
    },
    {
        'name': 'mat_round:camera_indoor'
    },
    {
        'name': 'mat_round:camera_outdoor'
    },
    {
        'name': 'mat_round:camera_rear'
    },
    {
        'name': 'mat_round:camera_roll'
    },
    {
        'name': 'mat_round:campaign'
    },
    {
        'name': 'mat_round:cancel'
    },
    {
        'name': 'mat_round:cancel_presentation'
    },
    {
        'name': 'mat_round:cancel_schedule_send'
    },
    {
        'name': 'mat_round:candlestick_chart'
    },
    {
        'name': 'mat_round:card_giftcard'
    },
    {
        'name': 'mat_round:card_membership'
    },
    {
        'name': 'mat_round:card_travel'
    },
    {
        'name': 'mat_round:carpenter'
    },
    {
        'name': 'mat_round:car_crash'
    },
    {
        'name': 'mat_round:car_rental'
    },
    {
        'name': 'mat_round:car_repair'
    },
    {
        'name': 'mat_round:cases'
    },
    {
        'name': 'mat_round:casino'
    },
    {
        'name': 'mat_round:cast'
    },
    {
        'name': 'mat_round:castle'
    },
    {
        'name': 'mat_round:cast_connected'
    },
    {
        'name': 'mat_round:cast_for_education'
    },
    {
        'name': 'mat_round:catching_pokemon'
    },
    {
        'name': 'mat_round:category'
    },
    {
        'name': 'mat_round:celebration'
    },
    {
        'name': 'mat_round:cell_tower'
    },
    {
        'name': 'mat_round:cell_wifi'
    },
    {
        'name': 'mat_round:center_focus_strong'
    },
    {
        'name': 'mat_round:center_focus_weak'
    },
    {
        'name': 'mat_round:chair'
    },
    {
        'name': 'mat_round:chair_alt'
    },
    {
        'name': 'mat_round:chalet'
    },
    {
        'name': 'mat_round:change_circle'
    },
    {
        'name': 'mat_round:change_history'
    },
    {
        'name': 'mat_round:charging_station'
    },
    {
        'name': 'mat_round:chat'
    },
    {
        'name': 'mat_round:chat_bubble'
    },
    {
        'name': 'mat_round:chat_bubble_outline'
    },
    {
        'name': 'mat_round:check'
    },
    {
        'name': 'mat_round:checklist'
    },
    {
        'name': 'mat_round:checklist_rtl'
    },
    {
        'name': 'mat_round:checkroom'
    },
    {
        'name': 'mat_round:check_box'
    },
    {
        'name': 'mat_round:check_box_outline_blank'
    },
    {
        'name': 'mat_round:check_circle'
    },
    {
        'name': 'mat_round:check_circle_outline'
    },
    {
        'name': 'mat_round:chevron_left'
    },
    {
        'name': 'mat_round:chevron_right'
    },
    {
        'name': 'mat_round:child_care'
    },
    {
        'name': 'mat_round:child_friendly'
    },
    {
        'name': 'mat_round:chrome_reader_mode'
    },
    {
        'name': 'mat_round:church'
    },
    {
        'name': 'mat_round:circle'
    },
    {
        'name': 'mat_round:circle_notifications'
    },
    {
        'name': 'mat_round:class'
    },
    {
        'name': 'mat_round:cleaning_services'
    },
    {
        'name': 'mat_round:clean_hands'
    },
    {
        'name': 'mat_round:clear'
    },
    {
        'name': 'mat_round:clear_all'
    },
    {
        'name': 'mat_round:close'
    },
    {
        'name': 'mat_round:closed_caption'
    },
    {
        'name': 'mat_round:closed_caption_disabled'
    },
    {
        'name': 'mat_round:closed_caption_off'
    },
    {
        'name': 'mat_round:close_fullscreen'
    },
    {
        'name': 'mat_round:cloud'
    },
    {
        'name': 'mat_round:cloud_circle'
    },
    {
        'name': 'mat_round:cloud_done'
    },
    {
        'name': 'mat_round:cloud_download'
    },
    {
        'name': 'mat_round:cloud_off'
    },
    {
        'name': 'mat_round:cloud_queue'
    },
    {
        'name': 'mat_round:cloud_sync'
    },
    {
        'name': 'mat_round:cloud_upload'
    },
    {
        'name': 'mat_round:co2'
    },
    {
        'name': 'mat_round:code'
    },
    {
        'name': 'mat_round:code_off'
    },
    {
        'name': 'mat_round:coffee'
    },
    {
        'name': 'mat_round:coffee_maker'
    },
    {
        'name': 'mat_round:collections'
    },
    {
        'name': 'mat_round:collections_bookmark'
    },
    {
        'name': 'mat_round:colorize'
    },
    {
        'name': 'mat_round:color_lens'
    },
    {
        'name': 'mat_round:comment'
    },
    {
        'name': 'mat_round:comments_disabled'
    },
    {
        'name': 'mat_round:comment_bank'
    },
    {
        'name': 'mat_round:commit'
    },
    {
        'name': 'mat_round:commute'
    },
    {
        'name': 'mat_round:compare'
    },
    {
        'name': 'mat_round:compare_arrows'
    },
    {
        'name': 'mat_round:compass_calibration'
    },
    {
        'name': 'mat_round:compost'
    },
    {
        'name': 'mat_round:compress'
    },
    {
        'name': 'mat_round:computer'
    },
    {
        'name': 'mat_round:confirmation_number'
    },
    {
        'name': 'mat_round:connected_tv'
    },
    {
        'name': 'mat_round:connecting_airports'
    },
    {
        'name': 'mat_round:connect_without_contact'
    },
    {
        'name': 'mat_round:construction'
    },
    {
        'name': 'mat_round:contactless'
    },
    {
        'name': 'mat_round:contacts'
    },
    {
        'name': 'mat_round:contact_emergency'
    },
    {
        'name': 'mat_round:contact_mail'
    },
    {
        'name': 'mat_round:contact_page'
    },
    {
        'name': 'mat_round:contact_phone'
    },
    {
        'name': 'mat_round:contact_support'
    },
    {
        'name': 'mat_round:content_copy'
    },
    {
        'name': 'mat_round:content_cut'
    },
    {
        'name': 'mat_round:content_paste'
    },
    {
        'name': 'mat_round:content_paste_go'
    },
    {
        'name': 'mat_round:content_paste_off'
    },
    {
        'name': 'mat_round:content_paste_search'
    },
    {
        'name': 'mat_round:contrast'
    },
    {
        'name': 'mat_round:control_camera'
    },
    {
        'name': 'mat_round:control_point'
    },
    {
        'name': 'mat_round:control_point_duplicate'
    },
    {
        'name': 'mat_round:cookie'
    },
    {
        'name': 'mat_round:copyright'
    },
    {
        'name': 'mat_round:copy_all'
    },
    {
        'name': 'mat_round:coronavirus'
    },
    {
        'name': 'mat_round:corporate_fare'
    },
    {
        'name': 'mat_round:cottage'
    },
    {
        'name': 'mat_round:countertops'
    },
    {
        'name': 'mat_round:co_present'
    },
    {
        'name': 'mat_round:create'
    },
    {
        'name': 'mat_round:create_new_folder'
    },
    {
        'name': 'mat_round:credit_card'
    },
    {
        'name': 'mat_round:credit_card_off'
    },
    {
        'name': 'mat_round:credit_score'
    },
    {
        'name': 'mat_round:crib'
    },
    {
        'name': 'mat_round:crisis_alert'
    },
    {
        'name': 'mat_round:crop'
    },
    {
        'name': 'mat_round:crop_16_9'
    },
    {
        'name': 'mat_round:crop_3_2'
    },
    {
        'name': 'mat_round:crop_5_4'
    },
    {
        'name': 'mat_round:crop_7_5'
    },
    {
        'name': 'mat_round:crop_din'
    },
    {
        'name': 'mat_round:crop_free'
    },
    {
        'name': 'mat_round:crop_landscape'
    },
    {
        'name': 'mat_round:crop_original'
    },
    {
        'name': 'mat_round:crop_portrait'
    },
    {
        'name': 'mat_round:crop_rotate'
    },
    {
        'name': 'mat_round:crop_square'
    },
    {
        'name': 'mat_round:cruelty_free'
    },
    {
        'name': 'mat_round:css'
    },
    {
        'name': 'mat_round:currency_bitcoin'
    },
    {
        'name': 'mat_round:currency_exchange'
    },
    {
        'name': 'mat_round:currency_franc'
    },
    {
        'name': 'mat_round:currency_lira'
    },
    {
        'name': 'mat_round:currency_pound'
    },
    {
        'name': 'mat_round:currency_ruble'
    },
    {
        'name': 'mat_round:currency_rupee'
    },
    {
        'name': 'mat_round:currency_yen'
    },
    {
        'name': 'mat_round:currency_yuan'
    },
    {
        'name': 'mat_round:curtains'
    },
    {
        'name': 'mat_round:curtains_closed'
    },
    {
        'name': 'mat_round:cyclone'
    },
    {
        'name': 'mat_round:dangerous'
    },
    {
        'name': 'mat_round:dark_mode'
    },
    {
        'name': 'mat_round:dashboard'
    },
    {
        'name': 'mat_round:dashboard_customize'
    },
    {
        'name': 'mat_round:dataset'
    },
    {
        'name': 'mat_round:dataset_linked'
    },
    {
        'name': 'mat_round:data_array'
    },
    {
        'name': 'mat_round:data_exploration'
    },
    {
        'name': 'mat_round:data_object'
    },
    {
        'name': 'mat_round:data_saver_off'
    },
    {
        'name': 'mat_round:data_saver_on'
    },
    {
        'name': 'mat_round:data_thresholding'
    },
    {
        'name': 'mat_round:data_usage'
    },
    {
        'name': 'mat_round:date_range'
    },
    {
        'name': 'mat_round:deblur'
    },
    {
        'name': 'mat_round:deck'
    },
    {
        'name': 'mat_round:dehaze'
    },
    {
        'name': 'mat_round:delete'
    },
    {
        'name': 'mat_round:delete_forever'
    },
    {
        'name': 'mat_round:delete_outline'
    },
    {
        'name': 'mat_round:delete_sweep'
    },
    {
        'name': 'mat_round:delivery_dining'
    },
    {
        'name': 'mat_round:density_large'
    },
    {
        'name': 'mat_round:density_medium'
    },
    {
        'name': 'mat_round:density_small'
    },
    {
        'name': 'mat_round:departure_board'
    },
    {
        'name': 'mat_round:description'
    },
    {
        'name': 'mat_round:deselect'
    },
    {
        'name': 'mat_round:design_services'
    },
    {
        'name': 'mat_round:desk'
    },
    {
        'name': 'mat_round:desktop_access_disabled'
    },
    {
        'name': 'mat_round:desktop_mac'
    },
    {
        'name': 'mat_round:desktop_windows'
    },
    {
        'name': 'mat_round:details'
    },
    {
        'name': 'mat_round:developer_board'
    },
    {
        'name': 'mat_round:developer_board_off'
    },
    {
        'name': 'mat_round:developer_mode'
    },
    {
        'name': 'mat_round:devices'
    },
    {
        'name': 'mat_round:devices_fold'
    },
    {
        'name': 'mat_round:devices_other'
    },
    {
        'name': 'mat_round:device_hub'
    },
    {
        'name': 'mat_round:device_thermostat'
    },
    {
        'name': 'mat_round:device_unknown'
    },
    {
        'name': 'mat_round:dialer_sip'
    },
    {
        'name': 'mat_round:dialpad'
    },
    {
        'name': 'mat_round:diamond'
    },
    {
        'name': 'mat_round:difference'
    },
    {
        'name': 'mat_round:dining'
    },
    {
        'name': 'mat_round:dinner_dining'
    },
    {
        'name': 'mat_round:directions'
    },
    {
        'name': 'mat_round:directions_bike'
    },
    {
        'name': 'mat_round:directions_boat'
    },
    {
        'name': 'mat_round:directions_boat_filled'
    },
    {
        'name': 'mat_round:directions_bus'
    },
    {
        'name': 'mat_round:directions_bus_filled'
    },
    {
        'name': 'mat_round:directions_car'
    },
    {
        'name': 'mat_round:directions_car_filled'
    },
    {
        'name': 'mat_round:directions_off'
    },
    {
        'name': 'mat_round:directions_railway'
    },
    {
        'name': 'mat_round:directions_railway_filled'
    },
    {
        'name': 'mat_round:directions_run'
    },
    {
        'name': 'mat_round:directions_subway'
    },
    {
        'name': 'mat_round:directions_subway_filled'
    },
    {
        'name': 'mat_round:directions_transit'
    },
    {
        'name': 'mat_round:directions_transit_filled'
    },
    {
        'name': 'mat_round:directions_walk'
    },
    {
        'name': 'mat_round:dirty_lens'
    },
    {
        'name': 'mat_round:disabled_by_default'
    },
    {
        'name': 'mat_round:disabled_visible'
    },
    {
        'name': 'mat_round:discount'
    },
    {
        'name': 'mat_round:disc_full'
    },
    {
        'name': 'mat_round:display_settings'
    },
    {
        'name': 'mat_round:diversity_1'
    },
    {
        'name': 'mat_round:diversity_2'
    },
    {
        'name': 'mat_round:diversity_3'
    },
    {
        'name': 'mat_round:dns'
    },
    {
        'name': 'mat_round:dock'
    },
    {
        'name': 'mat_round:document_scanner'
    },
    {
        'name': 'mat_round:domain'
    },
    {
        'name': 'mat_round:domain_add'
    },
    {
        'name': 'mat_round:domain_disabled'
    },
    {
        'name': 'mat_round:domain_verification'
    },
    {
        'name': 'mat_round:done'
    },
    {
        'name': 'mat_round:done_all'
    },
    {
        'name': 'mat_round:done_outline'
    },
    {
        'name': 'mat_round:donut_large'
    },
    {
        'name': 'mat_round:donut_small'
    },
    {
        'name': 'mat_round:doorbell'
    },
    {
        'name': 'mat_round:door_back'
    },
    {
        'name': 'mat_round:door_front'
    },
    {
        'name': 'mat_round:door_sliding'
    },
    {
        'name': 'mat_round:double_arrow'
    },
    {
        'name': 'mat_round:downhill_skiing'
    },
    {
        'name': 'mat_round:download'
    },
    {
        'name': 'mat_round:downloading'
    },
    {
        'name': 'mat_round:download_done'
    },
    {
        'name': 'mat_round:download_for_offline'
    },
    {
        'name': 'mat_round:do_disturb'
    },
    {
        'name': 'mat_round:do_disturb_alt'
    },
    {
        'name': 'mat_round:do_disturb_off'
    },
    {
        'name': 'mat_round:do_disturb_on'
    },
    {
        'name': 'mat_round:do_not_disturb'
    },
    {
        'name': 'mat_round:do_not_disturb_alt'
    },
    {
        'name': 'mat_round:do_not_disturb_off'
    },
    {
        'name': 'mat_round:do_not_disturb_on'
    },
    {
        'name': 'mat_round:do_not_disturb_on_total_silence'
    },
    {
        'name': 'mat_round:do_not_step'
    },
    {
        'name': 'mat_round:do_not_touch'
    },
    {
        'name': 'mat_round:drafts'
    },
    {
        'name': 'mat_round:drag_handle'
    },
    {
        'name': 'mat_round:drag_indicator'
    },
    {
        'name': 'mat_round:draw'
    },
    {
        'name': 'mat_round:drive_eta'
    },
    {
        'name': 'mat_round:drive_file_move'
    },
    {
        'name': 'mat_round:drive_file_move_rtl'
    },
    {
        'name': 'mat_round:drive_file_rename_outline'
    },
    {
        'name': 'mat_round:drive_folder_upload'
    },
    {
        'name': 'mat_round:dry'
    },
    {
        'name': 'mat_round:dry_cleaning'
    },
    {
        'name': 'mat_round:duo'
    },
    {
        'name': 'mat_round:dvr'
    },
    {
        'name': 'mat_round:dynamic_feed'
    },
    {
        'name': 'mat_round:dynamic_form'
    },
    {
        'name': 'mat_round:earbuds'
    },
    {
        'name': 'mat_round:earbuds_battery'
    },
    {
        'name': 'mat_round:east'
    },
    {
        'name': 'mat_round:edgesensor_high'
    },
    {
        'name': 'mat_round:edgesensor_low'
    },
    {
        'name': 'mat_round:edit'
    },
    {
        'name': 'mat_round:edit_attributes'
    },
    {
        'name': 'mat_round:edit_calendar'
    },
    {
        'name': 'mat_round:edit_location'
    },
    {
        'name': 'mat_round:edit_location_alt'
    },
    {
        'name': 'mat_round:edit_note'
    },
    {
        'name': 'mat_round:edit_notifications'
    },
    {
        'name': 'mat_round:edit_off'
    },
    {
        'name': 'mat_round:edit_road'
    },
    {
        'name': 'mat_round:egg'
    },
    {
        'name': 'mat_round:egg_alt'
    },
    {
        'name': 'mat_round:eject'
    },
    {
        'name': 'mat_round:elderly'
    },
    {
        'name': 'mat_round:elderly_woman'
    },
    {
        'name': 'mat_round:electrical_services'
    },
    {
        'name': 'mat_round:electric_bike'
    },
    {
        'name': 'mat_round:electric_bolt'
    },
    {
        'name': 'mat_round:electric_car'
    },
    {
        'name': 'mat_round:electric_meter'
    },
    {
        'name': 'mat_round:electric_moped'
    },
    {
        'name': 'mat_round:electric_rickshaw'
    },
    {
        'name': 'mat_round:electric_scooter'
    },
    {
        'name': 'mat_round:elevator'
    },
    {
        'name': 'mat_round:email'
    },
    {
        'name': 'mat_round:emergency'
    },
    {
        'name': 'mat_round:emergency_recording'
    },
    {
        'name': 'mat_round:emergency_share'
    },
    {
        'name': 'mat_round:emoji_emotions'
    },
    {
        'name': 'mat_round:emoji_events'
    },
    {
        'name': 'mat_round:emoji_food_beverage'
    },
    {
        'name': 'mat_round:emoji_nature'
    },
    {
        'name': 'mat_round:emoji_objects'
    },
    {
        'name': 'mat_round:emoji_people'
    },
    {
        'name': 'mat_round:emoji_symbols'
    },
    {
        'name': 'mat_round:emoji_transportation'
    },
    {
        'name': 'mat_round:energy_savings_leaf'
    },
    {
        'name': 'mat_round:engineering'
    },
    {
        'name': 'mat_round:enhanced_encryption'
    },
    {
        'name': 'mat_round:equalizer'
    },
    {
        'name': 'mat_round:error'
    },
    {
        'name': 'mat_round:error_outline'
    },
    {
        'name': 'mat_round:escalator'
    },
    {
        'name': 'mat_round:escalator_warning'
    },
    {
        'name': 'mat_round:euro'
    },
    {
        'name': 'mat_round:euro_symbol'
    },
    {
        'name': 'mat_round:event'
    },
    {
        'name': 'mat_round:event_available'
    },
    {
        'name': 'mat_round:event_busy'
    },
    {
        'name': 'mat_round:event_note'
    },
    {
        'name': 'mat_round:event_repeat'
    },
    {
        'name': 'mat_round:event_seat'
    },
    {
        'name': 'mat_round:ev_station'
    },
    {
        'name': 'mat_round:exit_to_app'
    },
    {
        'name': 'mat_round:expand'
    },
    {
        'name': 'mat_round:expand_circle_down'
    },
    {
        'name': 'mat_round:expand_less'
    },
    {
        'name': 'mat_round:expand_more'
    },
    {
        'name': 'mat_round:explicit'
    },
    {
        'name': 'mat_round:explore'
    },
    {
        'name': 'mat_round:explore_off'
    },
    {
        'name': 'mat_round:exposure'
    },
    {
        'name': 'mat_round:exposure_neg_1'
    },
    {
        'name': 'mat_round:exposure_neg_2'
    },
    {
        'name': 'mat_round:exposure_plus_1'
    },
    {
        'name': 'mat_round:exposure_plus_2'
    },
    {
        'name': 'mat_round:exposure_zero'
    },
    {
        'name': 'mat_round:extension'
    },
    {
        'name': 'mat_round:extension_off'
    },
    {
        'name': 'mat_round:e_mobiledata'
    },
    {
        'name': 'mat_round:face'
    },
    {
        'name': 'mat_round:face_2'
    },
    {
        'name': 'mat_round:face_3'
    },
    {
        'name': 'mat_round:face_4'
    },
    {
        'name': 'mat_round:face_5'
    },
    {
        'name': 'mat_round:face_6'
    },
    {
        'name': 'mat_round:face_retouching_natural'
    },
    {
        'name': 'mat_round:face_retouching_off'
    },
    {
        'name': 'mat_round:factory'
    },
    {
        'name': 'mat_round:fact_check'
    },
    {
        'name': 'mat_round:family_restroom'
    },
    {
        'name': 'mat_round:fastfood'
    },
    {
        'name': 'mat_round:fast_forward'
    },
    {
        'name': 'mat_round:fast_rewind'
    },
    {
        'name': 'mat_round:favorite'
    },
    {
        'name': 'mat_round:favorite_border'
    },
    {
        'name': 'mat_round:fax'
    },
    {
        'name': 'mat_round:featured_play_list'
    },
    {
        'name': 'mat_round:featured_video'
    },
    {
        'name': 'mat_round:feed'
    },
    {
        'name': 'mat_round:feedback'
    },
    {
        'name': 'mat_round:female'
    },
    {
        'name': 'mat_round:fence'
    },
    {
        'name': 'mat_round:festival'
    },
    {
        'name': 'mat_round:fiber_dvr'
    },
    {
        'name': 'mat_round:fiber_manual_record'
    },
    {
        'name': 'mat_round:fiber_new'
    },
    {
        'name': 'mat_round:fiber_pin'
    },
    {
        'name': 'mat_round:fiber_smart_record'
    },
    {
        'name': 'mat_round:file_copy'
    },
    {
        'name': 'mat_round:file_download'
    },
    {
        'name': 'mat_round:file_download_done'
    },
    {
        'name': 'mat_round:file_download_off'
    },
    {
        'name': 'mat_round:file_open'
    },
    {
        'name': 'mat_round:file_present'
    },
    {
        'name': 'mat_round:file_upload'
    },
    {
        'name': 'mat_round:filter'
    },
    {
        'name': 'mat_round:filter_1'
    },
    {
        'name': 'mat_round:filter_2'
    },
    {
        'name': 'mat_round:filter_3'
    },
    {
        'name': 'mat_round:filter_4'
    },
    {
        'name': 'mat_round:filter_5'
    },
    {
        'name': 'mat_round:filter_6'
    },
    {
        'name': 'mat_round:filter_7'
    },
    {
        'name': 'mat_round:filter_8'
    },
    {
        'name': 'mat_round:filter_9'
    },
    {
        'name': 'mat_round:filter_9_plus'
    },
    {
        'name': 'mat_round:filter_alt'
    },
    {
        'name': 'mat_round:filter_alt_off'
    },
    {
        'name': 'mat_round:filter_b_and_w'
    },
    {
        'name': 'mat_round:filter_center_focus'
    },
    {
        'name': 'mat_round:filter_drama'
    },
    {
        'name': 'mat_round:filter_frames'
    },
    {
        'name': 'mat_round:filter_hdr'
    },
    {
        'name': 'mat_round:filter_list'
    },
    {
        'name': 'mat_round:filter_list_off'
    },
    {
        'name': 'mat_round:filter_none'
    },
    {
        'name': 'mat_round:filter_tilt_shift'
    },
    {
        'name': 'mat_round:filter_vintage'
    },
    {
        'name': 'mat_round:find_in_page'
    },
    {
        'name': 'mat_round:find_replace'
    },
    {
        'name': 'mat_round:fingerprint'
    },
    {
        'name': 'mat_round:fireplace'
    },
    {
        'name': 'mat_round:fire_extinguisher'
    },
    {
        'name': 'mat_round:fire_hydrant_alt'
    },
    {
        'name': 'mat_round:fire_truck'
    },
    {
        'name': 'mat_round:first_page'
    },
    {
        'name': 'mat_round:fitbit'
    },
    {
        'name': 'mat_round:fitness_center'
    },
    {
        'name': 'mat_round:fit_screen'
    },
    {
        'name': 'mat_round:flag'
    },
    {
        'name': 'mat_round:flag_circle'
    },
    {
        'name': 'mat_round:flaky'
    },
    {
        'name': 'mat_round:flare'
    },
    {
        'name': 'mat_round:flashlight_off'
    },
    {
        'name': 'mat_round:flashlight_on'
    },
    {
        'name': 'mat_round:flash_auto'
    },
    {
        'name': 'mat_round:flash_off'
    },
    {
        'name': 'mat_round:flash_on'
    },
    {
        'name': 'mat_round:flatware'
    },
    {
        'name': 'mat_round:flight'
    },
    {
        'name': 'mat_round:flight_class'
    },
    {
        'name': 'mat_round:flight_land'
    },
    {
        'name': 'mat_round:flight_takeoff'
    },
    {
        'name': 'mat_round:flip'
    },
    {
        'name': 'mat_round:flip_camera_android'
    },
    {
        'name': 'mat_round:flip_camera_ios'
    },
    {
        'name': 'mat_round:flip_to_back'
    },
    {
        'name': 'mat_round:flip_to_front'
    },
    {
        'name': 'mat_round:flood'
    },
    {
        'name': 'mat_round:fluorescent'
    },
    {
        'name': 'mat_round:flutter_dash'
    },
    {
        'name': 'mat_round:fmd_bad'
    },
    {
        'name': 'mat_round:fmd_good'
    },
    {
        'name': 'mat_round:folder'
    },
    {
        'name': 'mat_round:folder_copy'
    },
    {
        'name': 'mat_round:folder_delete'
    },
    {
        'name': 'mat_round:folder_off'
    },
    {
        'name': 'mat_round:folder_open'
    },
    {
        'name': 'mat_round:folder_shared'
    },
    {
        'name': 'mat_round:folder_special'
    },
    {
        'name': 'mat_round:folder_zip'
    },
    {
        'name': 'mat_round:follow_the_signs'
    },
    {
        'name': 'mat_round:font_download'
    },
    {
        'name': 'mat_round:font_download_off'
    },
    {
        'name': 'mat_round:food_bank'
    },
    {
        'name': 'mat_round:forest'
    },
    {
        'name': 'mat_round:fork_left'
    },
    {
        'name': 'mat_round:fork_right'
    },
    {
        'name': 'mat_round:format_align_center'
    },
    {
        'name': 'mat_round:format_align_justify'
    },
    {
        'name': 'mat_round:format_align_left'
    },
    {
        'name': 'mat_round:format_align_right'
    },
    {
        'name': 'mat_round:format_bold'
    },
    {
        'name': 'mat_round:format_clear'
    },
    {
        'name': 'mat_round:format_color_fill'
    },
    {
        'name': 'mat_round:format_color_reset'
    },
    {
        'name': 'mat_round:format_color_text'
    },
    {
        'name': 'mat_round:format_indent_decrease'
    },
    {
        'name': 'mat_round:format_indent_increase'
    },
    {
        'name': 'mat_round:format_italic'
    },
    {
        'name': 'mat_round:format_line_spacing'
    },
    {
        'name': 'mat_round:format_list_bulleted'
    },
    {
        'name': 'mat_round:format_list_numbered'
    },
    {
        'name': 'mat_round:format_list_numbered_rtl'
    },
    {
        'name': 'mat_round:format_overline'
    },
    {
        'name': 'mat_round:format_paint'
    },
    {
        'name': 'mat_round:format_quote'
    },
    {
        'name': 'mat_round:format_shapes'
    },
    {
        'name': 'mat_round:format_size'
    },
    {
        'name': 'mat_round:format_strikethrough'
    },
    {
        'name': 'mat_round:format_textdirection_l_to_r'
    },
    {
        'name': 'mat_round:format_textdirection_r_to_l'
    },
    {
        'name': 'mat_round:format_underlined'
    },
    {
        'name': 'mat_round:fort'
    },
    {
        'name': 'mat_round:forum'
    },
    {
        'name': 'mat_round:forward'
    },
    {
        'name': 'mat_round:forward_10'
    },
    {
        'name': 'mat_round:forward_30'
    },
    {
        'name': 'mat_round:forward_5'
    },
    {
        'name': 'mat_round:forward_to_inbox'
    },
    {
        'name': 'mat_round:foundation'
    },
    {
        'name': 'mat_round:free_breakfast'
    },
    {
        'name': 'mat_round:free_cancellation'
    },
    {
        'name': 'mat_round:front_hand'
    },
    {
        'name': 'mat_round:fullscreen'
    },
    {
        'name': 'mat_round:fullscreen_exit'
    },
    {
        'name': 'mat_round:functions'
    },
    {
        'name': 'mat_round:gamepad'
    },
    {
        'name': 'mat_round:games'
    },
    {
        'name': 'mat_round:garage'
    },
    {
        'name': 'mat_round:gas_meter'
    },
    {
        'name': 'mat_round:gavel'
    },
    {
        'name': 'mat_round:generating_tokens'
    },
    {
        'name': 'mat_round:gesture'
    },
    {
        'name': 'mat_round:get_app'
    },
    {
        'name': 'mat_round:gif'
    },
    {
        'name': 'mat_round:gif_box'
    },
    {
        'name': 'mat_round:girl'
    },
    {
        'name': 'mat_round:gite'
    },
    {
        'name': 'mat_round:golf_course'
    },
    {
        'name': 'mat_round:gpp_bad'
    },
    {
        'name': 'mat_round:gpp_good'
    },
    {
        'name': 'mat_round:gpp_maybe'
    },
    {
        'name': 'mat_round:gps_fixed'
    },
    {
        'name': 'mat_round:gps_not_fixed'
    },
    {
        'name': 'mat_round:gps_off'
    },
    {
        'name': 'mat_round:grade'
    },
    {
        'name': 'mat_round:gradient'
    },
    {
        'name': 'mat_round:grading'
    },
    {
        'name': 'mat_round:grain'
    },
    {
        'name': 'mat_round:graphic_eq'
    },
    {
        'name': 'mat_round:grass'
    },
    {
        'name': 'mat_round:grid_3x3'
    },
    {
        'name': 'mat_round:grid_4x4'
    },
    {
        'name': 'mat_round:grid_goldenratio'
    },
    {
        'name': 'mat_round:grid_off'
    },
    {
        'name': 'mat_round:grid_on'
    },
    {
        'name': 'mat_round:grid_view'
    },
    {
        'name': 'mat_round:group'
    },
    {
        'name': 'mat_round:groups'
    },
    {
        'name': 'mat_round:groups_2'
    },
    {
        'name': 'mat_round:groups_3'
    },
    {
        'name': 'mat_round:group_add'
    },
    {
        'name': 'mat_round:group_off'
    },
    {
        'name': 'mat_round:group_remove'
    },
    {
        'name': 'mat_round:group_work'
    },
    {
        'name': 'mat_round:g_mobiledata'
    },
    {
        'name': 'mat_round:g_translate'
    },
    {
        'name': 'mat_round:hail'
    },
    {
        'name': 'mat_round:handshake'
    },
    {
        'name': 'mat_round:handyman'
    },
    {
        'name': 'mat_round:hardware'
    },
    {
        'name': 'mat_round:hd'
    },
    {
        'name': 'mat_round:hdr_auto'
    },
    {
        'name': 'mat_round:hdr_auto_select'
    },
    {
        'name': 'mat_round:hdr_enhanced_select'
    },
    {
        'name': 'mat_round:hdr_off'
    },
    {
        'name': 'mat_round:hdr_off_select'
    },
    {
        'name': 'mat_round:hdr_on'
    },
    {
        'name': 'mat_round:hdr_on_select'
    },
    {
        'name': 'mat_round:hdr_plus'
    },
    {
        'name': 'mat_round:hdr_strong'
    },
    {
        'name': 'mat_round:hdr_weak'
    },
    {
        'name': 'mat_round:headphones'
    },
    {
        'name': 'mat_round:headphones_battery'
    },
    {
        'name': 'mat_round:headset'
    },
    {
        'name': 'mat_round:headset_mic'
    },
    {
        'name': 'mat_round:headset_off'
    },
    {
        'name': 'mat_round:healing'
    },
    {
        'name': 'mat_round:health_and_safety'
    },
    {
        'name': 'mat_round:hearing'
    },
    {
        'name': 'mat_round:hearing_disabled'
    },
    {
        'name': 'mat_round:heart_broken'
    },
    {
        'name': 'mat_round:heat_pump'
    },
    {
        'name': 'mat_round:height'
    },
    {
        'name': 'mat_round:help'
    },
    {
        'name': 'mat_round:help_center'
    },
    {
        'name': 'mat_round:help_outline'
    },
    {
        'name': 'mat_round:hevc'
    },
    {
        'name': 'mat_round:hexagon'
    },
    {
        'name': 'mat_round:hide_image'
    },
    {
        'name': 'mat_round:hide_source'
    },
    {
        'name': 'mat_round:highlight'
    },
    {
        'name': 'mat_round:highlight_alt'
    },
    {
        'name': 'mat_round:highlight_off'
    },
    {
        'name': 'mat_round:high_quality'
    },
    {
        'name': 'mat_round:hiking'
    },
    {
        'name': 'mat_round:history'
    },
    {
        'name': 'mat_round:history_edu'
    },
    {
        'name': 'mat_round:history_toggle_off'
    },
    {
        'name': 'mat_round:hive'
    },
    {
        'name': 'mat_round:hls'
    },
    {
        'name': 'mat_round:hls_off'
    },
    {
        'name': 'mat_round:holiday_village'
    },
    {
        'name': 'mat_round:home'
    },
    {
        'name': 'mat_round:home_max'
    },
    {
        'name': 'mat_round:home_mini'
    },
    {
        'name': 'mat_round:home_repair_service'
    },
    {
        'name': 'mat_round:home_work'
    },
    {
        'name': 'mat_round:horizontal_distribute'
    },
    {
        'name': 'mat_round:horizontal_rule'
    },
    {
        'name': 'mat_round:horizontal_split'
    },
    {
        'name': 'mat_round:hotel'
    },
    {
        'name': 'mat_round:hotel_class'
    },
    {
        'name': 'mat_round:hot_tub'
    },
    {
        'name': 'mat_round:hourglass_bottom'
    },
    {
        'name': 'mat_round:hourglass_disabled'
    },
    {
        'name': 'mat_round:hourglass_empty'
    },
    {
        'name': 'mat_round:hourglass_full'
    },
    {
        'name': 'mat_round:hourglass_top'
    },
    {
        'name': 'mat_round:house'
    },
    {
        'name': 'mat_round:houseboat'
    },
    {
        'name': 'mat_round:house_siding'
    },
    {
        'name': 'mat_round:how_to_reg'
    },
    {
        'name': 'mat_round:how_to_vote'
    },
    {
        'name': 'mat_round:html'
    },
    {
        'name': 'mat_round:http'
    },
    {
        'name': 'mat_round:https'
    },
    {
        'name': 'mat_round:hub'
    },
    {
        'name': 'mat_round:hvac'
    },
    {
        'name': 'mat_round:h_mobiledata'
    },
    {
        'name': 'mat_round:h_plus_mobiledata'
    },
    {
        'name': 'mat_round:icecream'
    },
    {
        'name': 'mat_round:ice_skating'
    },
    {
        'name': 'mat_round:image'
    },
    {
        'name': 'mat_round:imagesearch_roller'
    },
    {
        'name': 'mat_round:image_aspect_ratio'
    },
    {
        'name': 'mat_round:image_not_supported'
    },
    {
        'name': 'mat_round:image_search'
    },
    {
        'name': 'mat_round:important_devices'
    },
    {
        'name': 'mat_round:import_contacts'
    },
    {
        'name': 'mat_round:import_export'
    },
    {
        'name': 'mat_round:inbox'
    },
    {
        'name': 'mat_round:incomplete_circle'
    },
    {
        'name': 'mat_round:indeterminate_check_box'
    },
    {
        'name': 'mat_round:info'
    },
    {
        'name': 'mat_round:input'
    },
    {
        'name': 'mat_round:insert_chart'
    },
    {
        'name': 'mat_round:insert_chart_outlined'
    },
    {
        'name': 'mat_round:insert_comment'
    },
    {
        'name': 'mat_round:insert_drive_file'
    },
    {
        'name': 'mat_round:insert_emoticon'
    },
    {
        'name': 'mat_round:insert_invitation'
    },
    {
        'name': 'mat_round:insert_link'
    },
    {
        'name': 'mat_round:insert_page_break'
    },
    {
        'name': 'mat_round:insert_photo'
    },
    {
        'name': 'mat_round:insights'
    },
    {
        'name': 'mat_round:install_desktop'
    },
    {
        'name': 'mat_round:install_mobile'
    },
    {
        'name': 'mat_round:integration_instructions'
    },
    {
        'name': 'mat_round:interests'
    },
    {
        'name': 'mat_round:interpreter_mode'
    },
    {
        'name': 'mat_round:inventory'
    },
    {
        'name': 'mat_round:inventory_2'
    },
    {
        'name': 'mat_round:invert_colors'
    },
    {
        'name': 'mat_round:invert_colors_off'
    },
    {
        'name': 'mat_round:ios_share'
    },
    {
        'name': 'mat_round:iron'
    },
    {
        'name': 'mat_round:iso'
    },
    {
        'name': 'mat_round:javascript'
    },
    {
        'name': 'mat_round:join_full'
    },
    {
        'name': 'mat_round:join_inner'
    },
    {
        'name': 'mat_round:join_left'
    },
    {
        'name': 'mat_round:join_right'
    },
    {
        'name': 'mat_round:kayaking'
    },
    {
        'name': 'mat_round:kebab_dining'
    },
    {
        'name': 'mat_round:key'
    },
    {
        'name': 'mat_round:keyboard'
    },
    {
        'name': 'mat_round:keyboard_alt'
    },
    {
        'name': 'mat_round:keyboard_arrow_down'
    },
    {
        'name': 'mat_round:keyboard_arrow_left'
    },
    {
        'name': 'mat_round:keyboard_arrow_right'
    },
    {
        'name': 'mat_round:keyboard_arrow_up'
    },
    {
        'name': 'mat_round:keyboard_backspace'
    },
    {
        'name': 'mat_round:keyboard_capslock'
    },
    {
        'name': 'mat_round:keyboard_command_key'
    },
    {
        'name': 'mat_round:keyboard_control_key'
    },
    {
        'name': 'mat_round:keyboard_double_arrow_down'
    },
    {
        'name': 'mat_round:keyboard_double_arrow_left'
    },
    {
        'name': 'mat_round:keyboard_double_arrow_right'
    },
    {
        'name': 'mat_round:keyboard_double_arrow_up'
    },
    {
        'name': 'mat_round:keyboard_hide'
    },
    {
        'name': 'mat_round:keyboard_option_key'
    },
    {
        'name': 'mat_round:keyboard_return'
    },
    {
        'name': 'mat_round:keyboard_tab'
    },
    {
        'name': 'mat_round:keyboard_voice'
    },
    {
        'name': 'mat_round:key_off'
    },
    {
        'name': 'mat_round:king_bed'
    },
    {
        'name': 'mat_round:kitchen'
    },
    {
        'name': 'mat_round:kitesurfing'
    },
    {
        'name': 'mat_round:label'
    },
    {
        'name': 'mat_round:label_important'
    },
    {
        'name': 'mat_round:label_off'
    },
    {
        'name': 'mat_round:lan'
    },
    {
        'name': 'mat_round:landscape'
    },
    {
        'name': 'mat_round:landslide'
    },
    {
        'name': 'mat_round:language'
    },
    {
        'name': 'mat_round:laptop'
    },
    {
        'name': 'mat_round:laptop_chromebook'
    },
    {
        'name': 'mat_round:laptop_mac'
    },
    {
        'name': 'mat_round:laptop_windows'
    },
    {
        'name': 'mat_round:last_page'
    },
    {
        'name': 'mat_round:launch'
    },
    {
        'name': 'mat_round:layers'
    },
    {
        'name': 'mat_round:layers_clear'
    },
    {
        'name': 'mat_round:leaderboard'
    },
    {
        'name': 'mat_round:leak_add'
    },
    {
        'name': 'mat_round:leak_remove'
    },
    {
        'name': 'mat_round:legend_toggle'
    },
    {
        'name': 'mat_round:lens'
    },
    {
        'name': 'mat_round:lens_blur'
    },
    {
        'name': 'mat_round:library_add'
    },
    {
        'name': 'mat_round:library_add_check'
    },
    {
        'name': 'mat_round:library_books'
    },
    {
        'name': 'mat_round:library_music'
    },
    {
        'name': 'mat_round:light'
    },
    {
        'name': 'mat_round:lightbulb'
    },
    {
        'name': 'mat_round:lightbulb_circle'
    },
    {
        'name': 'mat_round:light_mode'
    },
    {
        'name': 'mat_round:linear_scale'
    },
    {
        'name': 'mat_round:line_axis'
    },
    {
        'name': 'mat_round:line_style'
    },
    {
        'name': 'mat_round:line_weight'
    },
    {
        'name': 'mat_round:link'
    },
    {
        'name': 'mat_round:linked_camera'
    },
    {
        'name': 'mat_round:link_off'
    },
    {
        'name': 'mat_round:liquor'
    },
    {
        'name': 'mat_round:list'
    },
    {
        'name': 'mat_round:list_alt'
    },
    {
        'name': 'mat_round:live_help'
    },
    {
        'name': 'mat_round:live_tv'
    },
    {
        'name': 'mat_round:living'
    },
    {
        'name': 'mat_round:local_activity'
    },
    {
        'name': 'mat_round:local_airport'
    },
    {
        'name': 'mat_round:local_atm'
    },
    {
        'name': 'mat_round:local_bar'
    },
    {
        'name': 'mat_round:local_cafe'
    },
    {
        'name': 'mat_round:local_car_wash'
    },
    {
        'name': 'mat_round:local_convenience_store'
    },
    {
        'name': 'mat_round:local_dining'
    },
    {
        'name': 'mat_round:local_drink'
    },
    {
        'name': 'mat_round:local_fire_department'
    },
    {
        'name': 'mat_round:local_florist'
    },
    {
        'name': 'mat_round:local_gas_station'
    },
    {
        'name': 'mat_round:local_grocery_store'
    },
    {
        'name': 'mat_round:local_hospital'
    },
    {
        'name': 'mat_round:local_hotel'
    },
    {
        'name': 'mat_round:local_laundry_service'
    },
    {
        'name': 'mat_round:local_library'
    },
    {
        'name': 'mat_round:local_mall'
    },
    {
        'name': 'mat_round:local_movies'
    },
    {
        'name': 'mat_round:local_offer'
    },
    {
        'name': 'mat_round:local_parking'
    },
    {
        'name': 'mat_round:local_pharmacy'
    },
    {
        'name': 'mat_round:local_phone'
    },
    {
        'name': 'mat_round:local_pizza'
    },
    {
        'name': 'mat_round:local_play'
    },
    {
        'name': 'mat_round:local_police'
    },
    {
        'name': 'mat_round:local_post_office'
    },
    {
        'name': 'mat_round:local_printshop'
    },
    {
        'name': 'mat_round:local_see'
    },
    {
        'name': 'mat_round:local_shipping'
    },
    {
        'name': 'mat_round:local_taxi'
    },
    {
        'name': 'mat_round:location_city'
    },
    {
        'name': 'mat_round:location_disabled'
    },
    {
        'name': 'mat_round:location_off'
    },
    {
        'name': 'mat_round:location_on'
    },
    {
        'name': 'mat_round:location_searching'
    },
    {
        'name': 'mat_round:lock'
    },
    {
        'name': 'mat_round:lock_clock'
    },
    {
        'name': 'mat_round:lock_open'
    },
    {
        'name': 'mat_round:lock_person'
    },
    {
        'name': 'mat_round:lock_reset'
    },
    {
        'name': 'mat_round:login'
    },
    {
        'name': 'mat_round:logout'
    },
    {
        'name': 'mat_round:logo_dev'
    },
    {
        'name': 'mat_round:looks'
    },
    {
        'name': 'mat_round:looks_3'
    },
    {
        'name': 'mat_round:looks_4'
    },
    {
        'name': 'mat_round:looks_5'
    },
    {
        'name': 'mat_round:looks_6'
    },
    {
        'name': 'mat_round:looks_one'
    },
    {
        'name': 'mat_round:looks_two'
    },
    {
        'name': 'mat_round:loop'
    },
    {
        'name': 'mat_round:loupe'
    },
    {
        'name': 'mat_round:low_priority'
    },
    {
        'name': 'mat_round:loyalty'
    },
    {
        'name': 'mat_round:lte_mobiledata'
    },
    {
        'name': 'mat_round:lte_plus_mobiledata'
    },
    {
        'name': 'mat_round:luggage'
    },
    {
        'name': 'mat_round:lunch_dining'
    },
    {
        'name': 'mat_round:lyrics'
    },
    {
        'name': 'mat_round:macro_off'
    },
    {
        'name': 'mat_round:mail'
    },
    {
        'name': 'mat_round:mail_lock'
    },
    {
        'name': 'mat_round:mail_outline'
    },
    {
        'name': 'mat_round:male'
    },
    {
        'name': 'mat_round:man'
    },
    {
        'name': 'mat_round:manage_accounts'
    },
    {
        'name': 'mat_round:manage_history'
    },
    {
        'name': 'mat_round:manage_search'
    },
    {
        'name': 'mat_round:man_2'
    },
    {
        'name': 'mat_round:man_3'
    },
    {
        'name': 'mat_round:man_4'
    },
    {
        'name': 'mat_round:map'
    },
    {
        'name': 'mat_round:maps_home_work'
    },
    {
        'name': 'mat_round:maps_ugc'
    },
    {
        'name': 'mat_round:margin'
    },
    {
        'name': 'mat_round:markunread'
    },
    {
        'name': 'mat_round:markunread_mailbox'
    },
    {
        'name': 'mat_round:mark_as_unread'
    },
    {
        'name': 'mat_round:mark_chat_read'
    },
    {
        'name': 'mat_round:mark_chat_unread'
    },
    {
        'name': 'mat_round:mark_email_read'
    },
    {
        'name': 'mat_round:mark_email_unread'
    },
    {
        'name': 'mat_round:mark_unread_chat_alt'
    },
    {
        'name': 'mat_round:masks'
    },
    {
        'name': 'mat_round:maximize'
    },
    {
        'name': 'mat_round:mediation'
    },
    {
        'name': 'mat_round:media_bluetooth_off'
    },
    {
        'name': 'mat_round:media_bluetooth_on'
    },
    {
        'name': 'mat_round:medical_information'
    },
    {
        'name': 'mat_round:medical_services'
    },
    {
        'name': 'mat_round:medication'
    },
    {
        'name': 'mat_round:medication_liquid'
    },
    {
        'name': 'mat_round:meeting_room'
    },
    {
        'name': 'mat_round:memory'
    },
    {
        'name': 'mat_round:menu'
    },
    {
        'name': 'mat_round:menu_book'
    },
    {
        'name': 'mat_round:menu_open'
    },
    {
        'name': 'mat_round:merge'
    },
    {
        'name': 'mat_round:merge_type'
    },
    {
        'name': 'mat_round:message'
    },
    {
        'name': 'mat_round:mic'
    },
    {
        'name': 'mat_round:microwave'
    },
    {
        'name': 'mat_round:mic_external_off'
    },
    {
        'name': 'mat_round:mic_external_on'
    },
    {
        'name': 'mat_round:mic_none'
    },
    {
        'name': 'mat_round:mic_off'
    },
    {
        'name': 'mat_round:military_tech'
    },
    {
        'name': 'mat_round:minimize'
    },
    {
        'name': 'mat_round:minor_crash'
    },
    {
        'name': 'mat_round:miscellaneous_services'
    },
    {
        'name': 'mat_round:missed_video_call'
    },
    {
        'name': 'mat_round:mms'
    },
    {
        'name': 'mat_round:mobiledata_off'
    },
    {
        'name': 'mat_round:mobile_friendly'
    },
    {
        'name': 'mat_round:mobile_off'
    },
    {
        'name': 'mat_round:mobile_screen_share'
    },
    {
        'name': 'mat_round:mode'
    },
    {
        'name': 'mat_round:model_training'
    },
    {
        'name': 'mat_round:mode_comment'
    },
    {
        'name': 'mat_round:mode_edit'
    },
    {
        'name': 'mat_round:mode_edit_outline'
    },
    {
        'name': 'mat_round:mode_fan_off'
    },
    {
        'name': 'mat_round:mode_night'
    },
    {
        'name': 'mat_round:mode_of_travel'
    },
    {
        'name': 'mat_round:mode_standby'
    },
    {
        'name': 'mat_round:monetization_on'
    },
    {
        'name': 'mat_round:money'
    },
    {
        'name': 'mat_round:money_off'
    },
    {
        'name': 'mat_round:money_off_csred'
    },
    {
        'name': 'mat_round:monitor'
    },
    {
        'name': 'mat_round:monitor_heart'
    },
    {
        'name': 'mat_round:monitor_weight'
    },
    {
        'name': 'mat_round:monochrome_photos'
    },
    {
        'name': 'mat_round:mood'
    },
    {
        'name': 'mat_round:mood_bad'
    },
    {
        'name': 'mat_round:moped'
    },
    {
        'name': 'mat_round:more'
    },
    {
        'name': 'mat_round:more_horiz'
    },
    {
        'name': 'mat_round:more_time'
    },
    {
        'name': 'mat_round:more_vert'
    },
    {
        'name': 'mat_round:mosque'
    },
    {
        'name': 'mat_round:motion_photos_auto'
    },
    {
        'name': 'mat_round:motion_photos_off'
    },
    {
        'name': 'mat_round:motion_photos_on'
    },
    {
        'name': 'mat_round:motion_photos_pause'
    },
    {
        'name': 'mat_round:motion_photos_paused'
    },
    {
        'name': 'mat_round:mouse'
    },
    {
        'name': 'mat_round:move_down'
    },
    {
        'name': 'mat_round:move_to_inbox'
    },
    {
        'name': 'mat_round:move_up'
    },
    {
        'name': 'mat_round:movie'
    },
    {
        'name': 'mat_round:movie_creation'
    },
    {
        'name': 'mat_round:movie_filter'
    },
    {
        'name': 'mat_round:moving'
    },
    {
        'name': 'mat_round:mp'
    },
    {
        'name': 'mat_round:multiline_chart'
    },
    {
        'name': 'mat_round:multiple_stop'
    },
    {
        'name': 'mat_round:museum'
    },
    {
        'name': 'mat_round:music_note'
    },
    {
        'name': 'mat_round:music_off'
    },
    {
        'name': 'mat_round:music_video'
    },
    {
        'name': 'mat_round:my_location'
    },
    {
        'name': 'mat_round:nat'
    },
    {
        'name': 'mat_round:nature'
    },
    {
        'name': 'mat_round:nature_people'
    },
    {
        'name': 'mat_round:navigate_before'
    },
    {
        'name': 'mat_round:navigate_next'
    },
    {
        'name': 'mat_round:navigation'
    },
    {
        'name': 'mat_round:nearby_error'
    },
    {
        'name': 'mat_round:nearby_off'
    },
    {
        'name': 'mat_round:near_me'
    },
    {
        'name': 'mat_round:near_me_disabled'
    },
    {
        'name': 'mat_round:nest_cam_wired_stand'
    },
    {
        'name': 'mat_round:network_cell'
    },
    {
        'name': 'mat_round:network_check'
    },
    {
        'name': 'mat_round:network_locked'
    },
    {
        'name': 'mat_round:network_ping'
    },
    {
        'name': 'mat_round:network_wifi'
    },
    {
        'name': 'mat_round:network_wifi_1_bar'
    },
    {
        'name': 'mat_round:network_wifi_2_bar'
    },
    {
        'name': 'mat_round:network_wifi_3_bar'
    },
    {
        'name': 'mat_round:newspaper'
    },
    {
        'name': 'mat_round:new_label'
    },
    {
        'name': 'mat_round:new_releases'
    },
    {
        'name': 'mat_round:next_plan'
    },
    {
        'name': 'mat_round:next_week'
    },
    {
        'name': 'mat_round:nfc'
    },
    {
        'name': 'mat_round:nightlife'
    },
    {
        'name': 'mat_round:nightlight'
    },
    {
        'name': 'mat_round:nightlight_round'
    },
    {
        'name': 'mat_round:nights_stay'
    },
    {
        'name': 'mat_round:night_shelter'
    },
    {
        'name': 'mat_round:noise_aware'
    },
    {
        'name': 'mat_round:noise_control_off'
    },
    {
        'name': 'mat_round:nordic_walking'
    },
    {
        'name': 'mat_round:north'
    },
    {
        'name': 'mat_round:north_east'
    },
    {
        'name': 'mat_round:north_west'
    },
    {
        'name': 'mat_round:note'
    },
    {
        'name': 'mat_round:notes'
    },
    {
        'name': 'mat_round:note_add'
    },
    {
        'name': 'mat_round:note_alt'
    },
    {
        'name': 'mat_round:notifications'
    },
    {
        'name': 'mat_round:notifications_active'
    },
    {
        'name': 'mat_round:notifications_none'
    },
    {
        'name': 'mat_round:notifications_off'
    },
    {
        'name': 'mat_round:notifications_paused'
    },
    {
        'name': 'mat_round:notification_add'
    },
    {
        'name': 'mat_round:notification_important'
    },
    {
        'name': 'mat_round:not_accessible'
    },
    {
        'name': 'mat_round:not_interested'
    },
    {
        'name': 'mat_round:not_listed_location'
    },
    {
        'name': 'mat_round:not_started'
    },
    {
        'name': 'mat_round:no_accounts'
    },
    {
        'name': 'mat_round:no_adult_content'
    },
    {
        'name': 'mat_round:no_backpack'
    },
    {
        'name': 'mat_round:no_cell'
    },
    {
        'name': 'mat_round:no_crash'
    },
    {
        'name': 'mat_round:no_drinks'
    },
    {
        'name': 'mat_round:no_encryption'
    },
    {
        'name': 'mat_round:no_encryption_gmailerrorred'
    },
    {
        'name': 'mat_round:no_flash'
    },
    {
        'name': 'mat_round:no_food'
    },
    {
        'name': 'mat_round:no_luggage'
    },
    {
        'name': 'mat_round:no_meals'
    },
    {
        'name': 'mat_round:no_meeting_room'
    },
    {
        'name': 'mat_round:no_photography'
    },
    {
        'name': 'mat_round:no_sim'
    },
    {
        'name': 'mat_round:no_stroller'
    },
    {
        'name': 'mat_round:no_transfer'
    },
    {
        'name': 'mat_round:numbers'
    },
    {
        'name': 'mat_round:offline_bolt'
    },
    {
        'name': 'mat_round:offline_pin'
    },
    {
        'name': 'mat_round:offline_share'
    },
    {
        'name': 'mat_round:oil_barrel'
    },
    {
        'name': 'mat_round:ondemand_video'
    },
    {
        'name': 'mat_round:online_prediction'
    },
    {
        'name': 'mat_round:on_device_training'
    },
    {
        'name': 'mat_round:opacity'
    },
    {
        'name': 'mat_round:open_in_browser'
    },
    {
        'name': 'mat_round:open_in_full'
    },
    {
        'name': 'mat_round:open_in_new'
    },
    {
        'name': 'mat_round:open_in_new_off'
    },
    {
        'name': 'mat_round:open_with'
    },
    {
        'name': 'mat_round:other_houses'
    },
    {
        'name': 'mat_round:outbound'
    },
    {
        'name': 'mat_round:outbox'
    },
    {
        'name': 'mat_round:outdoor_grill'
    },
    {
        'name': 'mat_round:outlet'
    },
    {
        'name': 'mat_round:outlined_flag'
    },
    {
        'name': 'mat_round:output'
    },
    {
        'name': 'mat_round:padding'
    },
    {
        'name': 'mat_round:pages'
    },
    {
        'name': 'mat_round:pageview'
    },
    {
        'name': 'mat_round:paid'
    },
    {
        'name': 'mat_round:palette'
    },
    {
        'name': 'mat_round:panorama'
    },
    {
        'name': 'mat_round:panorama_fish_eye'
    },
    {
        'name': 'mat_round:panorama_horizontal'
    },
    {
        'name': 'mat_round:panorama_horizontal_select'
    },
    {
        'name': 'mat_round:panorama_photosphere'
    },
    {
        'name': 'mat_round:panorama_photosphere_select'
    },
    {
        'name': 'mat_round:panorama_vertical'
    },
    {
        'name': 'mat_round:panorama_vertical_select'
    },
    {
        'name': 'mat_round:panorama_wide_angle'
    },
    {
        'name': 'mat_round:panorama_wide_angle_select'
    },
    {
        'name': 'mat_round:pan_tool'
    },
    {
        'name': 'mat_round:pan_tool_alt'
    },
    {
        'name': 'mat_round:paragliding'
    },
    {
        'name': 'mat_round:park'
    },
    {
        'name': 'mat_round:party_mode'
    },
    {
        'name': 'mat_round:password'
    },
    {
        'name': 'mat_round:pattern'
    },
    {
        'name': 'mat_round:pause'
    },
    {
        'name': 'mat_round:pause_circle'
    },
    {
        'name': 'mat_round:pause_circle_filled'
    },
    {
        'name': 'mat_round:pause_circle_outline'
    },
    {
        'name': 'mat_round:pause_presentation'
    },
    {
        'name': 'mat_round:payment'
    },
    {
        'name': 'mat_round:payments'
    },
    {
        'name': 'mat_round:pedal_bike'
    },
    {
        'name': 'mat_round:pending'
    },
    {
        'name': 'mat_round:pending_actions'
    },
    {
        'name': 'mat_round:pentagon'
    },
    {
        'name': 'mat_round:people'
    },
    {
        'name': 'mat_round:people_alt'
    },
    {
        'name': 'mat_round:people_outline'
    },
    {
        'name': 'mat_round:percent'
    },
    {
        'name': 'mat_round:perm_camera_mic'
    },
    {
        'name': 'mat_round:perm_contact_calendar'
    },
    {
        'name': 'mat_round:perm_data_setting'
    },
    {
        'name': 'mat_round:perm_device_information'
    },
    {
        'name': 'mat_round:perm_identity'
    },
    {
        'name': 'mat_round:perm_media'
    },
    {
        'name': 'mat_round:perm_phone_msg'
    },
    {
        'name': 'mat_round:perm_scan_wifi'
    },
    {
        'name': 'mat_round:person'
    },
    {
        'name': 'mat_round:personal_injury'
    },
    {
        'name': 'mat_round:personal_video'
    },
    {
        'name': 'mat_round:person_2'
    },
    {
        'name': 'mat_round:person_3'
    },
    {
        'name': 'mat_round:person_4'
    },
    {
        'name': 'mat_round:person_add'
    },
    {
        'name': 'mat_round:person_add_alt'
    },
    {
        'name': 'mat_round:person_add_alt_1'
    },
    {
        'name': 'mat_round:person_add_disabled'
    },
    {
        'name': 'mat_round:person_off'
    },
    {
        'name': 'mat_round:person_outline'
    },
    {
        'name': 'mat_round:person_pin'
    },
    {
        'name': 'mat_round:person_pin_circle'
    },
    {
        'name': 'mat_round:person_remove'
    },
    {
        'name': 'mat_round:person_remove_alt_1'
    },
    {
        'name': 'mat_round:person_search'
    },
    {
        'name': 'mat_round:pest_control'
    },
    {
        'name': 'mat_round:pest_control_rodent'
    },
    {
        'name': 'mat_round:pets'
    },
    {
        'name': 'mat_round:phishing'
    },
    {
        'name': 'mat_round:phone'
    },
    {
        'name': 'mat_round:phonelink'
    },
    {
        'name': 'mat_round:phonelink_erase'
    },
    {
        'name': 'mat_round:phonelink_lock'
    },
    {
        'name': 'mat_round:phonelink_off'
    },
    {
        'name': 'mat_round:phonelink_ring'
    },
    {
        'name': 'mat_round:phonelink_setup'
    },
    {
        'name': 'mat_round:phone_android'
    },
    {
        'name': 'mat_round:phone_bluetooth_speaker'
    },
    {
        'name': 'mat_round:phone_callback'
    },
    {
        'name': 'mat_round:phone_disabled'
    },
    {
        'name': 'mat_round:phone_enabled'
    },
    {
        'name': 'mat_round:phone_forwarded'
    },
    {
        'name': 'mat_round:phone_iphone'
    },
    {
        'name': 'mat_round:phone_locked'
    },
    {
        'name': 'mat_round:phone_missed'
    },
    {
        'name': 'mat_round:phone_paused'
    },
    {
        'name': 'mat_round:photo'
    },
    {
        'name': 'mat_round:photo_album'
    },
    {
        'name': 'mat_round:photo_camera'
    },
    {
        'name': 'mat_round:photo_camera_back'
    },
    {
        'name': 'mat_round:photo_camera_front'
    },
    {
        'name': 'mat_round:photo_filter'
    },
    {
        'name': 'mat_round:photo_library'
    },
    {
        'name': 'mat_round:photo_size_select_actual'
    },
    {
        'name': 'mat_round:photo_size_select_large'
    },
    {
        'name': 'mat_round:photo_size_select_small'
    },
    {
        'name': 'mat_round:php'
    },
    {
        'name': 'mat_round:piano'
    },
    {
        'name': 'mat_round:piano_off'
    },
    {
        'name': 'mat_round:picture_as_pdf'
    },
    {
        'name': 'mat_round:picture_in_picture'
    },
    {
        'name': 'mat_round:picture_in_picture_alt'
    },
    {
        'name': 'mat_round:pie_chart'
    },
    {
        'name': 'mat_round:pie_chart_outline'
    },
    {
        'name': 'mat_round:pin'
    },
    {
        'name': 'mat_round:pinch'
    },
    {
        'name': 'mat_round:pin_drop'
    },
    {
        'name': 'mat_round:pin_end'
    },
    {
        'name': 'mat_round:pin_invoke'
    },
    {
        'name': 'mat_round:pivot_table_chart'
    },
    {
        'name': 'mat_round:pix'
    },
    {
        'name': 'mat_round:place'
    },
    {
        'name': 'mat_round:plagiarism'
    },
    {
        'name': 'mat_round:playlist_add'
    },
    {
        'name': 'mat_round:playlist_add_check'
    },
    {
        'name': 'mat_round:playlist_add_check_circle'
    },
    {
        'name': 'mat_round:playlist_add_circle'
    },
    {
        'name': 'mat_round:playlist_play'
    },
    {
        'name': 'mat_round:playlist_remove'
    },
    {
        'name': 'mat_round:play_arrow'
    },
    {
        'name': 'mat_round:play_circle'
    },
    {
        'name': 'mat_round:play_circle_filled'
    },
    {
        'name': 'mat_round:play_circle_outline'
    },
    {
        'name': 'mat_round:play_disabled'
    },
    {
        'name': 'mat_round:play_for_work'
    },
    {
        'name': 'mat_round:play_lesson'
    },
    {
        'name': 'mat_round:plumbing'
    },
    {
        'name': 'mat_round:plus_one'
    },
    {
        'name': 'mat_round:podcasts'
    },
    {
        'name': 'mat_round:point_of_sale'
    },
    {
        'name': 'mat_round:policy'
    },
    {
        'name': 'mat_round:poll'
    },
    {
        'name': 'mat_round:polyline'
    },
    {
        'name': 'mat_round:polymer'
    },
    {
        'name': 'mat_round:pool'
    },
    {
        'name': 'mat_round:portable_wifi_off'
    },
    {
        'name': 'mat_round:portrait'
    },
    {
        'name': 'mat_round:post_add'
    },
    {
        'name': 'mat_round:power'
    },
    {
        'name': 'mat_round:power_input'
    },
    {
        'name': 'mat_round:power_off'
    },
    {
        'name': 'mat_round:power_settings_new'
    },
    {
        'name': 'mat_round:precision_manufacturing'
    },
    {
        'name': 'mat_round:pregnant_woman'
    },
    {
        'name': 'mat_round:present_to_all'
    },
    {
        'name': 'mat_round:preview'
    },
    {
        'name': 'mat_round:price_change'
    },
    {
        'name': 'mat_round:price_check'
    },
    {
        'name': 'mat_round:print'
    },
    {
        'name': 'mat_round:print_disabled'
    },
    {
        'name': 'mat_round:priority_high'
    },
    {
        'name': 'mat_round:privacy_tip'
    },
    {
        'name': 'mat_round:private_connectivity'
    },
    {
        'name': 'mat_round:production_quantity_limits'
    },
    {
        'name': 'mat_round:propane'
    },
    {
        'name': 'mat_round:propane_tank'
    },
    {
        'name': 'mat_round:psychology'
    },
    {
        'name': 'mat_round:psychology_alt'
    },
    {
        'name': 'mat_round:public'
    },
    {
        'name': 'mat_round:public_off'
    },
    {
        'name': 'mat_round:publish'
    },
    {
        'name': 'mat_round:published_with_changes'
    },
    {
        'name': 'mat_round:punch_clock'
    },
    {
        'name': 'mat_round:push_pin'
    },
    {
        'name': 'mat_round:qr_code'
    },
    {
        'name': 'mat_round:qr_code_2'
    },
    {
        'name': 'mat_round:qr_code_scanner'
    },
    {
        'name': 'mat_round:query_builder'
    },
    {
        'name': 'mat_round:query_stats'
    },
    {
        'name': 'mat_round:question_answer'
    },
    {
        'name': 'mat_round:question_mark'
    },
    {
        'name': 'mat_round:queue'
    },
    {
        'name': 'mat_round:queue_music'
    },
    {
        'name': 'mat_round:queue_play_next'
    },
    {
        'name': 'mat_round:quickreply'
    },
    {
        'name': 'mat_round:quiz'
    },
    {
        'name': 'mat_round:radar'
    },
    {
        'name': 'mat_round:radio'
    },
    {
        'name': 'mat_round:radio_button_checked'
    },
    {
        'name': 'mat_round:radio_button_unchecked'
    },
    {
        'name': 'mat_round:railway_alert'
    },
    {
        'name': 'mat_round:ramen_dining'
    },
    {
        'name': 'mat_round:ramp_left'
    },
    {
        'name': 'mat_round:ramp_right'
    },
    {
        'name': 'mat_round:rate_review'
    },
    {
        'name': 'mat_round:raw_off'
    },
    {
        'name': 'mat_round:raw_on'
    },
    {
        'name': 'mat_round:read_more'
    },
    {
        'name': 'mat_round:real_estate_agent'
    },
    {
        'name': 'mat_round:receipt'
    },
    {
        'name': 'mat_round:receipt_long'
    },
    {
        'name': 'mat_round:recent_actors'
    },
    {
        'name': 'mat_round:recommend'
    },
    {
        'name': 'mat_round:record_voice_over'
    },
    {
        'name': 'mat_round:rectangle'
    },
    {
        'name': 'mat_round:recycling'
    },
    {
        'name': 'mat_round:redeem'
    },
    {
        'name': 'mat_round:redo'
    },
    {
        'name': 'mat_round:reduce_capacity'
    },
    {
        'name': 'mat_round:refresh'
    },
    {
        'name': 'mat_round:remember_me'
    },
    {
        'name': 'mat_round:remove'
    },
    {
        'name': 'mat_round:remove_circle'
    },
    {
        'name': 'mat_round:remove_circle_outline'
    },
    {
        'name': 'mat_round:remove_done'
    },
    {
        'name': 'mat_round:remove_from_queue'
    },
    {
        'name': 'mat_round:remove_moderator'
    },
    {
        'name': 'mat_round:remove_red_eye'
    },
    {
        'name': 'mat_round:remove_road'
    },
    {
        'name': 'mat_round:remove_shopping_cart'
    },
    {
        'name': 'mat_round:reorder'
    },
    {
        'name': 'mat_round:repartition'
    },
    {
        'name': 'mat_round:repeat'
    },
    {
        'name': 'mat_round:repeat_on'
    },
    {
        'name': 'mat_round:repeat_one'
    },
    {
        'name': 'mat_round:repeat_one_on'
    },
    {
        'name': 'mat_round:replay'
    },
    {
        'name': 'mat_round:replay_10'
    },
    {
        'name': 'mat_round:replay_30'
    },
    {
        'name': 'mat_round:replay_5'
    },
    {
        'name': 'mat_round:replay_circle_filled'
    },
    {
        'name': 'mat_round:reply'
    },
    {
        'name': 'mat_round:reply_all'
    },
    {
        'name': 'mat_round:report'
    },
    {
        'name': 'mat_round:report_gmailerrorred'
    },
    {
        'name': 'mat_round:report_off'
    },
    {
        'name': 'mat_round:report_problem'
    },
    {
        'name': 'mat_round:request_page'
    },
    {
        'name': 'mat_round:request_quote'
    },
    {
        'name': 'mat_round:reset_tv'
    },
    {
        'name': 'mat_round:restart_alt'
    },
    {
        'name': 'mat_round:restaurant'
    },
    {
        'name': 'mat_round:restaurant_menu'
    },
    {
        'name': 'mat_round:restore'
    },
    {
        'name': 'mat_round:restore_from_trash'
    },
    {
        'name': 'mat_round:restore_page'
    },
    {
        'name': 'mat_round:reviews'
    },
    {
        'name': 'mat_round:rice_bowl'
    },
    {
        'name': 'mat_round:ring_volume'
    },
    {
        'name': 'mat_round:rocket'
    },
    {
        'name': 'mat_round:rocket_launch'
    },
    {
        'name': 'mat_round:roller_shades'
    },
    {
        'name': 'mat_round:roller_shades_closed'
    },
    {
        'name': 'mat_round:roller_skating'
    },
    {
        'name': 'mat_round:roofing'
    },
    {
        'name': 'mat_round:room'
    },
    {
        'name': 'mat_round:room_preferences'
    },
    {
        'name': 'mat_round:room_service'
    },
    {
        'name': 'mat_round:rotate_90_degrees_ccw'
    },
    {
        'name': 'mat_round:rotate_90_degrees_cw'
    },
    {
        'name': 'mat_round:rotate_left'
    },
    {
        'name': 'mat_round:rotate_right'
    },
    {
        'name': 'mat_round:roundabout_left'
    },
    {
        'name': 'mat_round:roundabout_right'
    },
    {
        'name': 'mat_round:rounded_corner'
    },
    {
        'name': 'mat_round:route'
    },
    {
        'name': 'mat_round:router'
    },
    {
        'name': 'mat_round:rowing'
    },
    {
        'name': 'mat_round:rss_feed'
    },
    {
        'name': 'mat_round:rsvp'
    },
    {
        'name': 'mat_round:rtt'
    },
    {
        'name': 'mat_round:rule'
    },
    {
        'name': 'mat_round:rule_folder'
    },
    {
        'name': 'mat_round:running_with_errors'
    },
    {
        'name': 'mat_round:run_circle'
    },
    {
        'name': 'mat_round:rv_hookup'
    },
    {
        'name': 'mat_round:r_mobiledata'
    },
    {
        'name': 'mat_round:safety_check'
    },
    {
        'name': 'mat_round:safety_divider'
    },
    {
        'name': 'mat_round:sailing'
    },
    {
        'name': 'mat_round:sanitizer'
    },
    {
        'name': 'mat_round:satellite'
    },
    {
        'name': 'mat_round:satellite_alt'
    },
    {
        'name': 'mat_round:save'
    },
    {
        'name': 'mat_round:saved_search'
    },
    {
        'name': 'mat_round:save_alt'
    },
    {
        'name': 'mat_round:save_as'
    },
    {
        'name': 'mat_round:savings'
    },
    {
        'name': 'mat_round:scale'
    },
    {
        'name': 'mat_round:scanner'
    },
    {
        'name': 'mat_round:scatter_plot'
    },
    {
        'name': 'mat_round:schedule'
    },
    {
        'name': 'mat_round:schedule_send'
    },
    {
        'name': 'mat_round:schema'
    },
    {
        'name': 'mat_round:school'
    },
    {
        'name': 'mat_round:science'
    },
    {
        'name': 'mat_round:score'
    },
    {
        'name': 'mat_round:scoreboard'
    },
    {
        'name': 'mat_round:screenshot'
    },
    {
        'name': 'mat_round:screenshot_monitor'
    },
    {
        'name': 'mat_round:screen_lock_landscape'
    },
    {
        'name': 'mat_round:screen_lock_portrait'
    },
    {
        'name': 'mat_round:screen_lock_rotation'
    },
    {
        'name': 'mat_round:screen_rotation'
    },
    {
        'name': 'mat_round:screen_rotation_alt'
    },
    {
        'name': 'mat_round:screen_search_desktop'
    },
    {
        'name': 'mat_round:screen_share'
    },
    {
        'name': 'mat_round:scuba_diving'
    },
    {
        'name': 'mat_round:sd'
    },
    {
        'name': 'mat_round:sd_card'
    },
    {
        'name': 'mat_round:sd_card_alert'
    },
    {
        'name': 'mat_round:sd_storage'
    },
    {
        'name': 'mat_round:search'
    },
    {
        'name': 'mat_round:search_off'
    },
    {
        'name': 'mat_round:security'
    },
    {
        'name': 'mat_round:security_update'
    },
    {
        'name': 'mat_round:security_update_good'
    },
    {
        'name': 'mat_round:security_update_warning'
    },
    {
        'name': 'mat_round:segment'
    },
    {
        'name': 'mat_round:select_all'
    },
    {
        'name': 'mat_round:self_improvement'
    },
    {
        'name': 'mat_round:sell'
    },
    {
        'name': 'mat_round:send'
    },
    {
        'name': 'mat_round:send_and_archive'
    },
    {
        'name': 'mat_round:send_time_extension'
    },
    {
        'name': 'mat_round:send_to_mobile'
    },
    {
        'name': 'mat_round:sensors'
    },
    {
        'name': 'mat_round:sensors_off'
    },
    {
        'name': 'mat_round:sensor_door'
    },
    {
        'name': 'mat_round:sensor_occupied'
    },
    {
        'name': 'mat_round:sensor_window'
    },
    {
        'name': 'mat_round:sentiment_dissatisfied'
    },
    {
        'name': 'mat_round:sentiment_neutral'
    },
    {
        'name': 'mat_round:sentiment_satisfied'
    },
    {
        'name': 'mat_round:sentiment_satisfied_alt'
    },
    {
        'name': 'mat_round:sentiment_very_dissatisfied'
    },
    {
        'name': 'mat_round:sentiment_very_satisfied'
    },
    {
        'name': 'mat_round:settings'
    },
    {
        'name': 'mat_round:settings_accessibility'
    },
    {
        'name': 'mat_round:settings_applications'
    },
    {
        'name': 'mat_round:settings_backup_restore'
    },
    {
        'name': 'mat_round:settings_bluetooth'
    },
    {
        'name': 'mat_round:settings_brightness'
    },
    {
        'name': 'mat_round:settings_cell'
    },
    {
        'name': 'mat_round:settings_ethernet'
    },
    {
        'name': 'mat_round:settings_input_antenna'
    },
    {
        'name': 'mat_round:settings_input_component'
    },
    {
        'name': 'mat_round:settings_input_composite'
    },
    {
        'name': 'mat_round:settings_input_hdmi'
    },
    {
        'name': 'mat_round:settings_input_svideo'
    },
    {
        'name': 'mat_round:settings_overscan'
    },
    {
        'name': 'mat_round:settings_phone'
    },
    {
        'name': 'mat_round:settings_power'
    },
    {
        'name': 'mat_round:settings_remote'
    },
    {
        'name': 'mat_round:settings_suggest'
    },
    {
        'name': 'mat_round:settings_system_daydream'
    },
    {
        'name': 'mat_round:settings_voice'
    },
    {
        'name': 'mat_round:set_meal'
    },
    {
        'name': 'mat_round:severe_cold'
    },
    {
        'name': 'mat_round:shape_line'
    },
    {
        'name': 'mat_round:share'
    },
    {
        'name': 'mat_round:share_location'
    },
    {
        'name': 'mat_round:shield'
    },
    {
        'name': 'mat_round:shield_moon'
    },
    {
        'name': 'mat_round:shop'
    },
    {
        'name': 'mat_round:shopping_bag'
    },
    {
        'name': 'mat_round:shopping_basket'
    },
    {
        'name': 'mat_round:shopping_cart'
    },
    {
        'name': 'mat_round:shopping_cart_checkout'
    },
    {
        'name': 'mat_round:shop_2'
    },
    {
        'name': 'mat_round:shop_two'
    },
    {
        'name': 'mat_round:shortcut'
    },
    {
        'name': 'mat_round:short_text'
    },
    {
        'name': 'mat_round:shower'
    },
    {
        'name': 'mat_round:show_chart'
    },
    {
        'name': 'mat_round:shuffle'
    },
    {
        'name': 'mat_round:shuffle_on'
    },
    {
        'name': 'mat_round:shutter_speed'
    },
    {
        'name': 'mat_round:sick'
    },
    {
        'name': 'mat_round:signal_cellular_0_bar'
    },
    {
        'name': 'mat_round:signal_cellular_4_bar'
    },
    {
        'name': 'mat_round:signal_cellular_alt'
    },
    {
        'name': 'mat_round:signal_cellular_alt_1_bar'
    },
    {
        'name': 'mat_round:signal_cellular_alt_2_bar'
    },
    {
        'name': 'mat_round:signal_cellular_connected_no_internet_0_bar'
    },
    {
        'name': 'mat_round:signal_cellular_connected_no_internet_4_bar'
    },
    {
        'name': 'mat_round:signal_cellular_nodata'
    },
    {
        'name': 'mat_round:signal_cellular_no_sim'
    },
    {
        'name': 'mat_round:signal_cellular_null'
    },
    {
        'name': 'mat_round:signal_cellular_off'
    },
    {
        'name': 'mat_round:signal_wifi_0_bar'
    },
    {
        'name': 'mat_round:signal_wifi_4_bar'
    },
    {
        'name': 'mat_round:signal_wifi_4_bar_lock'
    },
    {
        'name': 'mat_round:signal_wifi_bad'
    },
    {
        'name': 'mat_round:signal_wifi_connected_no_internet_4'
    },
    {
        'name': 'mat_round:signal_wifi_off'
    },
    {
        'name': 'mat_round:signal_wifi_statusbar_4_bar'
    },
    {
        'name': 'mat_round:signal_wifi_statusbar_connected_no_internet_4'
    },
    {
        'name': 'mat_round:signal_wifi_statusbar_null'
    },
    {
        'name': 'mat_round:signpost'
    },
    {
        'name': 'mat_round:sign_language'
    },
    {
        'name': 'mat_round:sim_card'
    },
    {
        'name': 'mat_round:sim_card_alert'
    },
    {
        'name': 'mat_round:sim_card_download'
    },
    {
        'name': 'mat_round:single_bed'
    },
    {
        'name': 'mat_round:sip'
    },
    {
        'name': 'mat_round:skateboarding'
    },
    {
        'name': 'mat_round:skip_next'
    },
    {
        'name': 'mat_round:skip_previous'
    },
    {
        'name': 'mat_round:sledding'
    },
    {
        'name': 'mat_round:slideshow'
    },
    {
        'name': 'mat_round:slow_motion_video'
    },
    {
        'name': 'mat_round:smartphone'
    },
    {
        'name': 'mat_round:smart_button'
    },
    {
        'name': 'mat_round:smart_display'
    },
    {
        'name': 'mat_round:smart_screen'
    },
    {
        'name': 'mat_round:smart_toy'
    },
    {
        'name': 'mat_round:smoke_free'
    },
    {
        'name': 'mat_round:smoking_rooms'
    },
    {
        'name': 'mat_round:sms'
    },
    {
        'name': 'mat_round:sms_failed'
    },
    {
        'name': 'mat_round:snippet_folder'
    },
    {
        'name': 'mat_round:snooze'
    },
    {
        'name': 'mat_round:snowboarding'
    },
    {
        'name': 'mat_round:snowmobile'
    },
    {
        'name': 'mat_round:snowshoeing'
    },
    {
        'name': 'mat_round:soap'
    },
    {
        'name': 'mat_round:social_distance'
    },
    {
        'name': 'mat_round:solar_power'
    },
    {
        'name': 'mat_round:sort'
    },
    {
        'name': 'mat_round:sort_by_alpha'
    },
    {
        'name': 'mat_round:sos'
    },
    {
        'name': 'mat_round:soup_kitchen'
    },
    {
        'name': 'mat_round:source'
    },
    {
        'name': 'mat_round:south'
    },
    {
        'name': 'mat_round:south_america'
    },
    {
        'name': 'mat_round:south_east'
    },
    {
        'name': 'mat_round:south_west'
    },
    {
        'name': 'mat_round:spa'
    },
    {
        'name': 'mat_round:space_bar'
    },
    {
        'name': 'mat_round:space_dashboard'
    },
    {
        'name': 'mat_round:spatial_audio'
    },
    {
        'name': 'mat_round:spatial_audio_off'
    },
    {
        'name': 'mat_round:spatial_tracking'
    },
    {
        'name': 'mat_round:speaker'
    },
    {
        'name': 'mat_round:speaker_group'
    },
    {
        'name': 'mat_round:speaker_notes'
    },
    {
        'name': 'mat_round:speaker_notes_off'
    },
    {
        'name': 'mat_round:speaker_phone'
    },
    {
        'name': 'mat_round:speed'
    },
    {
        'name': 'mat_round:spellcheck'
    },
    {
        'name': 'mat_round:splitscreen'
    },
    {
        'name': 'mat_round:spoke'
    },
    {
        'name': 'mat_round:sports'
    },
    {
        'name': 'mat_round:sports_bar'
    },
    {
        'name': 'mat_round:sports_baseball'
    },
    {
        'name': 'mat_round:sports_basketball'
    },
    {
        'name': 'mat_round:sports_cricket'
    },
    {
        'name': 'mat_round:sports_esports'
    },
    {
        'name': 'mat_round:sports_football'
    },
    {
        'name': 'mat_round:sports_golf'
    },
    {
        'name': 'mat_round:sports_gymnastics'
    },
    {
        'name': 'mat_round:sports_handball'
    },
    {
        'name': 'mat_round:sports_hockey'
    },
    {
        'name': 'mat_round:sports_kabaddi'
    },
    {
        'name': 'mat_round:sports_martial_arts'
    },
    {
        'name': 'mat_round:sports_mma'
    },
    {
        'name': 'mat_round:sports_motorsports'
    },
    {
        'name': 'mat_round:sports_rugby'
    },
    {
        'name': 'mat_round:sports_score'
    },
    {
        'name': 'mat_round:sports_soccer'
    },
    {
        'name': 'mat_round:sports_tennis'
    },
    {
        'name': 'mat_round:sports_volleyball'
    },
    {
        'name': 'mat_round:square'
    },
    {
        'name': 'mat_round:square_foot'
    },
    {
        'name': 'mat_round:ssid_chart'
    },
    {
        'name': 'mat_round:stacked_bar_chart'
    },
    {
        'name': 'mat_round:stacked_line_chart'
    },
    {
        'name': 'mat_round:stadium'
    },
    {
        'name': 'mat_round:stairs'
    },
    {
        'name': 'mat_round:star'
    },
    {
        'name': 'mat_round:stars'
    },
    {
        'name': 'mat_round:start'
    },
    {
        'name': 'mat_round:star_border'
    },
    {
        'name': 'mat_round:star_border_purple500'
    },
    {
        'name': 'mat_round:star_half'
    },
    {
        'name': 'mat_round:star_outline'
    },
    {
        'name': 'mat_round:star_purple500'
    },
    {
        'name': 'mat_round:star_rate'
    },
    {
        'name': 'mat_round:stay_current_landscape'
    },
    {
        'name': 'mat_round:stay_current_portrait'
    },
    {
        'name': 'mat_round:stay_primary_landscape'
    },
    {
        'name': 'mat_round:stay_primary_portrait'
    },
    {
        'name': 'mat_round:sticky_note_2'
    },
    {
        'name': 'mat_round:stop'
    },
    {
        'name': 'mat_round:stop_circle'
    },
    {
        'name': 'mat_round:stop_screen_share'
    },
    {
        'name': 'mat_round:storage'
    },
    {
        'name': 'mat_round:store'
    },
    {
        'name': 'mat_round:storefront'
    },
    {
        'name': 'mat_round:store_mall_directory'
    },
    {
        'name': 'mat_round:storm'
    },
    {
        'name': 'mat_round:straight'
    },
    {
        'name': 'mat_round:straighten'
    },
    {
        'name': 'mat_round:stream'
    },
    {
        'name': 'mat_round:streetview'
    },
    {
        'name': 'mat_round:strikethrough_s'
    },
    {
        'name': 'mat_round:stroller'
    },
    {
        'name': 'mat_round:style'
    },
    {
        'name': 'mat_round:subdirectory_arrow_left'
    },
    {
        'name': 'mat_round:subdirectory_arrow_right'
    },
    {
        'name': 'mat_round:subject'
    },
    {
        'name': 'mat_round:subscript'
    },
    {
        'name': 'mat_round:subscriptions'
    },
    {
        'name': 'mat_round:subtitles'
    },
    {
        'name': 'mat_round:subtitles_off'
    },
    {
        'name': 'mat_round:subway'
    },
    {
        'name': 'mat_round:summarize'
    },
    {
        'name': 'mat_round:superscript'
    },
    {
        'name': 'mat_round:supervised_user_circle'
    },
    {
        'name': 'mat_round:supervisor_account'
    },
    {
        'name': 'mat_round:support'
    },
    {
        'name': 'mat_round:support_agent'
    },
    {
        'name': 'mat_round:surfing'
    },
    {
        'name': 'mat_round:surround_sound'
    },
    {
        'name': 'mat_round:swap_calls'
    },
    {
        'name': 'mat_round:swap_horiz'
    },
    {
        'name': 'mat_round:swap_horizontal_circle'
    },
    {
        'name': 'mat_round:swap_vert'
    },
    {
        'name': 'mat_round:swap_vertical_circle'
    },
    {
        'name': 'mat_round:swipe'
    },
    {
        'name': 'mat_round:swipe_down'
    },
    {
        'name': 'mat_round:swipe_down_alt'
    },
    {
        'name': 'mat_round:swipe_left'
    },
    {
        'name': 'mat_round:swipe_left_alt'
    },
    {
        'name': 'mat_round:swipe_right'
    },
    {
        'name': 'mat_round:swipe_right_alt'
    },
    {
        'name': 'mat_round:swipe_up'
    },
    {
        'name': 'mat_round:swipe_up_alt'
    },
    {
        'name': 'mat_round:swipe_vertical'
    },
    {
        'name': 'mat_round:switch_access_shortcut'
    },
    {
        'name': 'mat_round:switch_access_shortcut_add'
    },
    {
        'name': 'mat_round:switch_account'
    },
    {
        'name': 'mat_round:switch_camera'
    },
    {
        'name': 'mat_round:switch_left'
    },
    {
        'name': 'mat_round:switch_right'
    },
    {
        'name': 'mat_round:switch_video'
    },
    {
        'name': 'mat_round:synagogue'
    },
    {
        'name': 'mat_round:sync'
    },
    {
        'name': 'mat_round:sync_alt'
    },
    {
        'name': 'mat_round:sync_disabled'
    },
    {
        'name': 'mat_round:sync_lock'
    },
    {
        'name': 'mat_round:sync_problem'
    },
    {
        'name': 'mat_round:system_security_update'
    },
    {
        'name': 'mat_round:system_security_update_good'
    },
    {
        'name': 'mat_round:system_security_update_warning'
    },
    {
        'name': 'mat_round:system_update'
    },
    {
        'name': 'mat_round:system_update_alt'
    },
    {
        'name': 'mat_round:tab'
    },
    {
        'name': 'mat_round:tablet'
    },
    {
        'name': 'mat_round:tablet_android'
    },
    {
        'name': 'mat_round:tablet_mac'
    },
    {
        'name': 'mat_round:table_bar'
    },
    {
        'name': 'mat_round:table_chart'
    },
    {
        'name': 'mat_round:table_restaurant'
    },
    {
        'name': 'mat_round:table_rows'
    },
    {
        'name': 'mat_round:table_view'
    },
    {
        'name': 'mat_round:tab_unselected'
    },
    {
        'name': 'mat_round:tag'
    },
    {
        'name': 'mat_round:tag_faces'
    },
    {
        'name': 'mat_round:takeout_dining'
    },
    {
        'name': 'mat_round:tapas'
    },
    {
        'name': 'mat_round:tap_and_play'
    },
    {
        'name': 'mat_round:task'
    },
    {
        'name': 'mat_round:task_alt'
    },
    {
        'name': 'mat_round:taxi_alert'
    },
    {
        'name': 'mat_round:temple_buddhist'
    },
    {
        'name': 'mat_round:temple_hindu'
    },
    {
        'name': 'mat_round:terminal'
    },
    {
        'name': 'mat_round:terrain'
    },
    {
        'name': 'mat_round:textsms'
    },
    {
        'name': 'mat_round:texture'
    },
    {
        'name': 'mat_round:text_decrease'
    },
    {
        'name': 'mat_round:text_fields'
    },
    {
        'name': 'mat_round:text_format'
    },
    {
        'name': 'mat_round:text_increase'
    },
    {
        'name': 'mat_round:text_rotate_up'
    },
    {
        'name': 'mat_round:text_rotate_vertical'
    },
    {
        'name': 'mat_round:text_rotation_angledown'
    },
    {
        'name': 'mat_round:text_rotation_angleup'
    },
    {
        'name': 'mat_round:text_rotation_down'
    },
    {
        'name': 'mat_round:text_rotation_none'
    },
    {
        'name': 'mat_round:text_snippet'
    },
    {
        'name': 'mat_round:theaters'
    },
    {
        'name': 'mat_round:theater_comedy'
    },
    {
        'name': 'mat_round:thermostat'
    },
    {
        'name': 'mat_round:thermostat_auto'
    },
    {
        'name': 'mat_round:thumbs_up_down'
    },
    {
        'name': 'mat_round:thumb_down'
    },
    {
        'name': 'mat_round:thumb_down_alt'
    },
    {
        'name': 'mat_round:thumb_down_off_alt'
    },
    {
        'name': 'mat_round:thumb_up'
    },
    {
        'name': 'mat_round:thumb_up_alt'
    },
    {
        'name': 'mat_round:thumb_up_off_alt'
    },
    {
        'name': 'mat_round:thunderstorm'
    },
    {
        'name': 'mat_round:timelapse'
    },
    {
        'name': 'mat_round:timeline'
    },
    {
        'name': 'mat_round:timer'
    },
    {
        'name': 'mat_round:timer_10'
    },
    {
        'name': 'mat_round:timer_10_select'
    },
    {
        'name': 'mat_round:timer_3'
    },
    {
        'name': 'mat_round:timer_3_select'
    },
    {
        'name': 'mat_round:timer_off'
    },
    {
        'name': 'mat_round:time_to_leave'
    },
    {
        'name': 'mat_round:tips_and_updates'
    },
    {
        'name': 'mat_round:tire_repair'
    },
    {
        'name': 'mat_round:title'
    },
    {
        'name': 'mat_round:toc'
    },
    {
        'name': 'mat_round:today'
    },
    {
        'name': 'mat_round:toggle_off'
    },
    {
        'name': 'mat_round:toggle_on'
    },
    {
        'name': 'mat_round:token'
    },
    {
        'name': 'mat_round:toll'
    },
    {
        'name': 'mat_round:tonality'
    },
    {
        'name': 'mat_round:topic'
    },
    {
        'name': 'mat_round:tornado'
    },
    {
        'name': 'mat_round:touch_app'
    },
    {
        'name': 'mat_round:tour'
    },
    {
        'name': 'mat_round:toys'
    },
    {
        'name': 'mat_round:track_changes'
    },
    {
        'name': 'mat_round:traffic'
    },
    {
        'name': 'mat_round:train'
    },
    {
        'name': 'mat_round:tram'
    },
    {
        'name': 'mat_round:transcribe'
    },
    {
        'name': 'mat_round:transfer_within_a_station'
    },
    {
        'name': 'mat_round:transform'
    },
    {
        'name': 'mat_round:transgender'
    },
    {
        'name': 'mat_round:transit_enterexit'
    },
    {
        'name': 'mat_round:translate'
    },
    {
        'name': 'mat_round:travel_explore'
    },
    {
        'name': 'mat_round:trending_down'
    },
    {
        'name': 'mat_round:trending_flat'
    },
    {
        'name': 'mat_round:trending_up'
    },
    {
        'name': 'mat_round:trip_origin'
    },
    {
        'name': 'mat_round:troubleshoot'
    },
    {
        'name': 'mat_round:try'
    },
    {
        'name': 'mat_round:tsunami'
    },
    {
        'name': 'mat_round:tty'
    },
    {
        'name': 'mat_round:tune'
    },
    {
        'name': 'mat_round:tungsten'
    },
    {
        'name': 'mat_round:turned_in'
    },
    {
        'name': 'mat_round:turned_in_not'
    },
    {
        'name': 'mat_round:turn_left'
    },
    {
        'name': 'mat_round:turn_right'
    },
    {
        'name': 'mat_round:turn_sharp_left'
    },
    {
        'name': 'mat_round:turn_sharp_right'
    },
    {
        'name': 'mat_round:turn_slight_left'
    },
    {
        'name': 'mat_round:turn_slight_right'
    },
    {
        'name': 'mat_round:tv'
    },
    {
        'name': 'mat_round:tv_off'
    },
    {
        'name': 'mat_round:two_wheeler'
    },
    {
        'name': 'mat_round:type_specimen'
    },
    {
        'name': 'mat_round:umbrella'
    },
    {
        'name': 'mat_round:unarchive'
    },
    {
        'name': 'mat_round:undo'
    },
    {
        'name': 'mat_round:unfold_less'
    },
    {
        'name': 'mat_round:unfold_less_double'
    },
    {
        'name': 'mat_round:unfold_more'
    },
    {
        'name': 'mat_round:unfold_more_double'
    },
    {
        'name': 'mat_round:unpublished'
    },
    {
        'name': 'mat_round:unsubscribe'
    },
    {
        'name': 'mat_round:upcoming'
    },
    {
        'name': 'mat_round:update'
    },
    {
        'name': 'mat_round:update_disabled'
    },
    {
        'name': 'mat_round:upgrade'
    },
    {
        'name': 'mat_round:upload'
    },
    {
        'name': 'mat_round:upload_file'
    },
    {
        'name': 'mat_round:usb'
    },
    {
        'name': 'mat_round:usb_off'
    },
    {
        'name': 'mat_round:u_turn_left'
    },
    {
        'name': 'mat_round:u_turn_right'
    },
    {
        'name': 'mat_round:vaccines'
    },
    {
        'name': 'mat_round:vape_free'
    },
    {
        'name': 'mat_round:vaping_rooms'
    },
    {
        'name': 'mat_round:verified'
    },
    {
        'name': 'mat_round:verified_user'
    },
    {
        'name': 'mat_round:vertical_align_bottom'
    },
    {
        'name': 'mat_round:vertical_align_center'
    },
    {
        'name': 'mat_round:vertical_align_top'
    },
    {
        'name': 'mat_round:vertical_distribute'
    },
    {
        'name': 'mat_round:vertical_shades'
    },
    {
        'name': 'mat_round:vertical_shades_closed'
    },
    {
        'name': 'mat_round:vertical_split'
    },
    {
        'name': 'mat_round:vibration'
    },
    {
        'name': 'mat_round:videocam'
    },
    {
        'name': 'mat_round:videocam_off'
    },
    {
        'name': 'mat_round:videogame_asset'
    },
    {
        'name': 'mat_round:videogame_asset_off'
    },
    {
        'name': 'mat_round:video_call'
    },
    {
        'name': 'mat_round:video_camera_back'
    },
    {
        'name': 'mat_round:video_camera_front'
    },
    {
        'name': 'mat_round:video_chat'
    },
    {
        'name': 'mat_round:video_file'
    },
    {
        'name': 'mat_round:video_label'
    },
    {
        'name': 'mat_round:video_library'
    },
    {
        'name': 'mat_round:video_settings'
    },
    {
        'name': 'mat_round:video_stable'
    },
    {
        'name': 'mat_round:view_agenda'
    },
    {
        'name': 'mat_round:view_array'
    },
    {
        'name': 'mat_round:view_carousel'
    },
    {
        'name': 'mat_round:view_column'
    },
    {
        'name': 'mat_round:view_comfy'
    },
    {
        'name': 'mat_round:view_comfy_alt'
    },
    {
        'name': 'mat_round:view_compact'
    },
    {
        'name': 'mat_round:view_compact_alt'
    },
    {
        'name': 'mat_round:view_cozy'
    },
    {
        'name': 'mat_round:view_day'
    },
    {
        'name': 'mat_round:view_headline'
    },
    {
        'name': 'mat_round:view_in_ar'
    },
    {
        'name': 'mat_round:view_kanban'
    },
    {
        'name': 'mat_round:view_list'
    },
    {
        'name': 'mat_round:view_module'
    },
    {
        'name': 'mat_round:view_quilt'
    },
    {
        'name': 'mat_round:view_sidebar'
    },
    {
        'name': 'mat_round:view_stream'
    },
    {
        'name': 'mat_round:view_timeline'
    },
    {
        'name': 'mat_round:view_week'
    },
    {
        'name': 'mat_round:vignette'
    },
    {
        'name': 'mat_round:villa'
    },
    {
        'name': 'mat_round:visibility'
    },
    {
        'name': 'mat_round:visibility_off'
    },
    {
        'name': 'mat_round:voicemail'
    },
    {
        'name': 'mat_round:voice_chat'
    },
    {
        'name': 'mat_round:voice_over_off'
    },
    {
        'name': 'mat_round:volcano'
    },
    {
        'name': 'mat_round:volume_down'
    },
    {
        'name': 'mat_round:volume_mute'
    },
    {
        'name': 'mat_round:volume_off'
    },
    {
        'name': 'mat_round:volume_up'
    },
    {
        'name': 'mat_round:volunteer_activism'
    },
    {
        'name': 'mat_round:vpn_key'
    },
    {
        'name': 'mat_round:vpn_key_off'
    },
    {
        'name': 'mat_round:vpn_lock'
    },
    {
        'name': 'mat_round:vrpano'
    },
    {
        'name': 'mat_round:wallet'
    },
    {
        'name': 'mat_round:wallpaper'
    },
    {
        'name': 'mat_round:warehouse'
    },
    {
        'name': 'mat_round:warning'
    },
    {
        'name': 'mat_round:warning_amber'
    },
    {
        'name': 'mat_round:wash'
    },
    {
        'name': 'mat_round:watch'
    },
    {
        'name': 'mat_round:watch_later'
    },
    {
        'name': 'mat_round:watch_off'
    },
    {
        'name': 'mat_round:water'
    },
    {
        'name': 'mat_round:waterfall_chart'
    },
    {
        'name': 'mat_round:water_damage'
    },
    {
        'name': 'mat_round:water_drop'
    },
    {
        'name': 'mat_round:waves'
    },
    {
        'name': 'mat_round:waving_hand'
    },
    {
        'name': 'mat_round:wb_auto'
    },
    {
        'name': 'mat_round:wb_cloudy'
    },
    {
        'name': 'mat_round:wb_incandescent'
    },
    {
        'name': 'mat_round:wb_iridescent'
    },
    {
        'name': 'mat_round:wb_shade'
    },
    {
        'name': 'mat_round:wb_sunny'
    },
    {
        'name': 'mat_round:wb_twilight'
    },
    {
        'name': 'mat_round:wc'
    },
    {
        'name': 'mat_round:web'
    },
    {
        'name': 'mat_round:webhook'
    },
    {
        'name': 'mat_round:web_asset'
    },
    {
        'name': 'mat_round:web_asset_off'
    },
    {
        'name': 'mat_round:web_stories'
    },
    {
        'name': 'mat_round:weekend'
    },
    {
        'name': 'mat_round:west'
    },
    {
        'name': 'mat_round:whatshot'
    },
    {
        'name': 'mat_round:wheelchair_pickup'
    },
    {
        'name': 'mat_round:where_to_vote'
    },
    {
        'name': 'mat_round:widgets'
    },
    {
        'name': 'mat_round:width_full'
    },
    {
        'name': 'mat_round:width_normal'
    },
    {
        'name': 'mat_round:width_wide'
    },
    {
        'name': 'mat_round:wifi'
    },
    {
        'name': 'mat_round:wifi_1_bar'
    },
    {
        'name': 'mat_round:wifi_2_bar'
    },
    {
        'name': 'mat_round:wifi_calling'
    },
    {
        'name': 'mat_round:wifi_calling_3'
    },
    {
        'name': 'mat_round:wifi_channel'
    },
    {
        'name': 'mat_round:wifi_find'
    },
    {
        'name': 'mat_round:wifi_lock'
    },
    {
        'name': 'mat_round:wifi_off'
    },
    {
        'name': 'mat_round:wifi_password'
    },
    {
        'name': 'mat_round:wifi_protected_setup'
    },
    {
        'name': 'mat_round:wifi_tethering'
    },
    {
        'name': 'mat_round:wifi_tethering_error'
    },
    {
        'name': 'mat_round:wifi_tethering_off'
    },
    {
        'name': 'mat_round:window'
    },
    {
        'name': 'mat_round:wind_power'
    },
    {
        'name': 'mat_round:wine_bar'
    },
    {
        'name': 'mat_round:woman'
    },
    {
        'name': 'mat_round:woman_2'
    },
    {
        'name': 'mat_round:work'
    },
    {
        'name': 'mat_round:workspaces'
    },
    {
        'name': 'mat_round:workspace_premium'
    },
    {
        'name': 'mat_round:work_history'
    },
    {
        'name': 'mat_round:work_off'
    },
    {
        'name': 'mat_round:work_outline'
    },
    {
        'name': 'mat_round:wrap_text'
    },
    {
        'name': 'mat_round:wrong_location'
    },
    {
        'name': 'mat_round:wysiwyg'
    },
    {
        'name': 'mat_round:yard'
    },
    {
        'name': 'mat_round:youtube_searched_for'
    },
    {
        'name': 'mat_round:zoom_in'
    },
    {
        'name': 'mat_round:zoom_in_map'
    },
    {
        'name': 'mat_round:zoom_out'
    },
    {
        'name': 'mat_round:zoom_out_map'
    },
    {
        'name': 'mat_sharp:10k'
    },
    {
        'name': 'mat_sharp:10mp'
    },
    {
        'name': 'mat_sharp:11mp'
    },
    {
        'name': 'mat_sharp:123'
    },
    {
        'name': 'mat_sharp:12mp'
    },
    {
        'name': 'mat_sharp:13mp'
    },
    {
        'name': 'mat_sharp:14mp'
    },
    {
        'name': 'mat_sharp:15mp'
    },
    {
        'name': 'mat_sharp:16mp'
    },
    {
        'name': 'mat_sharp:17mp'
    },
    {
        'name': 'mat_sharp:18mp'
    },
    {
        'name': 'mat_sharp:18_up_rating'
    },
    {
        'name': 'mat_sharp:19mp'
    },
    {
        'name': 'mat_sharp:1k'
    },
    {
        'name': 'mat_sharp:1k_plus'
    },
    {
        'name': 'mat_sharp:1x_mobiledata'
    },
    {
        'name': 'mat_sharp:20mp'
    },
    {
        'name': 'mat_sharp:21mp'
    },
    {
        'name': 'mat_sharp:22mp'
    },
    {
        'name': 'mat_sharp:23mp'
    },
    {
        'name': 'mat_sharp:24mp'
    },
    {
        'name': 'mat_sharp:2k'
    },
    {
        'name': 'mat_sharp:2k_plus'
    },
    {
        'name': 'mat_sharp:2mp'
    },
    {
        'name': 'mat_sharp:30fps'
    },
    {
        'name': 'mat_sharp:30fps_select'
    },
    {
        'name': 'mat_sharp:360'
    },
    {
        'name': 'mat_sharp:3d_rotation'
    },
    {
        'name': 'mat_sharp:3g_mobiledata'
    },
    {
        'name': 'mat_sharp:3k'
    },
    {
        'name': 'mat_sharp:3k_plus'
    },
    {
        'name': 'mat_sharp:3mp'
    },
    {
        'name': 'mat_sharp:3p'
    },
    {
        'name': 'mat_sharp:4g_mobiledata'
    },
    {
        'name': 'mat_sharp:4g_plus_mobiledata'
    },
    {
        'name': 'mat_sharp:4k'
    },
    {
        'name': 'mat_sharp:4k_plus'
    },
    {
        'name': 'mat_sharp:4mp'
    },
    {
        'name': 'mat_sharp:5g'
    },
    {
        'name': 'mat_sharp:5k'
    },
    {
        'name': 'mat_sharp:5k_plus'
    },
    {
        'name': 'mat_sharp:5mp'
    },
    {
        'name': 'mat_sharp:60fps'
    },
    {
        'name': 'mat_sharp:60fps_select'
    },
    {
        'name': 'mat_sharp:6k'
    },
    {
        'name': 'mat_sharp:6k_plus'
    },
    {
        'name': 'mat_sharp:6mp'
    },
    {
        'name': 'mat_sharp:6_ft_apart'
    },
    {
        'name': 'mat_sharp:7k'
    },
    {
        'name': 'mat_sharp:7k_plus'
    },
    {
        'name': 'mat_sharp:7mp'
    },
    {
        'name': 'mat_sharp:8k'
    },
    {
        'name': 'mat_sharp:8k_plus'
    },
    {
        'name': 'mat_sharp:8mp'
    },
    {
        'name': 'mat_sharp:9k'
    },
    {
        'name': 'mat_sharp:9k_plus'
    },
    {
        'name': 'mat_sharp:9mp'
    },
    {
        'name': 'mat_sharp:abc'
    },
    {
        'name': 'mat_sharp:accessibility'
    },
    {
        'name': 'mat_sharp:accessibility_new'
    },
    {
        'name': 'mat_sharp:accessible'
    },
    {
        'name': 'mat_sharp:accessible_forward'
    },
    {
        'name': 'mat_sharp:access_alarm'
    },
    {
        'name': 'mat_sharp:access_alarms'
    },
    {
        'name': 'mat_sharp:access_time'
    },
    {
        'name': 'mat_sharp:access_time_filled'
    },
    {
        'name': 'mat_sharp:account_balance'
    },
    {
        'name': 'mat_sharp:account_balance_wallet'
    },
    {
        'name': 'mat_sharp:account_box'
    },
    {
        'name': 'mat_sharp:account_circle'
    },
    {
        'name': 'mat_sharp:account_tree'
    },
    {
        'name': 'mat_sharp:ac_unit'
    },
    {
        'name': 'mat_sharp:adb'
    },
    {
        'name': 'mat_sharp:add'
    },
    {
        'name': 'mat_sharp:addchart'
    },
    {
        'name': 'mat_sharp:add_alarm'
    },
    {
        'name': 'mat_sharp:add_alert'
    },
    {
        'name': 'mat_sharp:add_a_photo'
    },
    {
        'name': 'mat_sharp:add_box'
    },
    {
        'name': 'mat_sharp:add_business'
    },
    {
        'name': 'mat_sharp:add_card'
    },
    {
        'name': 'mat_sharp:add_chart'
    },
    {
        'name': 'mat_sharp:add_circle'
    },
    {
        'name': 'mat_sharp:add_circle_outline'
    },
    {
        'name': 'mat_sharp:add_comment'
    },
    {
        'name': 'mat_sharp:add_home'
    },
    {
        'name': 'mat_sharp:add_home_work'
    },
    {
        'name': 'mat_sharp:add_ic_call'
    },
    {
        'name': 'mat_sharp:add_link'
    },
    {
        'name': 'mat_sharp:add_location'
    },
    {
        'name': 'mat_sharp:add_location_alt'
    },
    {
        'name': 'mat_sharp:add_moderator'
    },
    {
        'name': 'mat_sharp:add_photo_alternate'
    },
    {
        'name': 'mat_sharp:add_reaction'
    },
    {
        'name': 'mat_sharp:add_road'
    },
    {
        'name': 'mat_sharp:add_shopping_cart'
    },
    {
        'name': 'mat_sharp:add_task'
    },
    {
        'name': 'mat_sharp:add_to_drive'
    },
    {
        'name': 'mat_sharp:add_to_home_screen'
    },
    {
        'name': 'mat_sharp:add_to_photos'
    },
    {
        'name': 'mat_sharp:add_to_queue'
    },
    {
        'name': 'mat_sharp:adf_scanner'
    },
    {
        'name': 'mat_sharp:adjust'
    },
    {
        'name': 'mat_sharp:admin_panel_settings'
    },
    {
        'name': 'mat_sharp:ads_click'
    },
    {
        'name': 'mat_sharp:ad_units'
    },
    {
        'name': 'mat_sharp:agriculture'
    },
    {
        'name': 'mat_sharp:air'
    },
    {
        'name': 'mat_sharp:airlines'
    },
    {
        'name': 'mat_sharp:airline_seat_flat'
    },
    {
        'name': 'mat_sharp:airline_seat_flat_angled'
    },
    {
        'name': 'mat_sharp:airline_seat_individual_suite'
    },
    {
        'name': 'mat_sharp:airline_seat_legroom_extra'
    },
    {
        'name': 'mat_sharp:airline_seat_legroom_normal'
    },
    {
        'name': 'mat_sharp:airline_seat_legroom_reduced'
    },
    {
        'name': 'mat_sharp:airline_seat_recline_extra'
    },
    {
        'name': 'mat_sharp:airline_seat_recline_normal'
    },
    {
        'name': 'mat_sharp:airline_stops'
    },
    {
        'name': 'mat_sharp:airplanemode_active'
    },
    {
        'name': 'mat_sharp:airplanemode_inactive'
    },
    {
        'name': 'mat_sharp:airplane_ticket'
    },
    {
        'name': 'mat_sharp:airplay'
    },
    {
        'name': 'mat_sharp:airport_shuttle'
    },
    {
        'name': 'mat_sharp:alarm'
    },
    {
        'name': 'mat_sharp:alarm_add'
    },
    {
        'name': 'mat_sharp:alarm_off'
    },
    {
        'name': 'mat_sharp:alarm_on'
    },
    {
        'name': 'mat_sharp:album'
    },
    {
        'name': 'mat_sharp:align_horizontal_center'
    },
    {
        'name': 'mat_sharp:align_horizontal_left'
    },
    {
        'name': 'mat_sharp:align_horizontal_right'
    },
    {
        'name': 'mat_sharp:align_vertical_bottom'
    },
    {
        'name': 'mat_sharp:align_vertical_center'
    },
    {
        'name': 'mat_sharp:align_vertical_top'
    },
    {
        'name': 'mat_sharp:all_inbox'
    },
    {
        'name': 'mat_sharp:all_inclusive'
    },
    {
        'name': 'mat_sharp:all_out'
    },
    {
        'name': 'mat_sharp:alternate_email'
    },
    {
        'name': 'mat_sharp:alt_route'
    },
    {
        'name': 'mat_sharp:analytics'
    },
    {
        'name': 'mat_sharp:anchor'
    },
    {
        'name': 'mat_sharp:android'
    },
    {
        'name': 'mat_sharp:animation'
    },
    {
        'name': 'mat_sharp:announcement'
    },
    {
        'name': 'mat_sharp:aod'
    },
    {
        'name': 'mat_sharp:apartment'
    },
    {
        'name': 'mat_sharp:api'
    },
    {
        'name': 'mat_sharp:approval'
    },
    {
        'name': 'mat_sharp:apps'
    },
    {
        'name': 'mat_sharp:apps_outage'
    },
    {
        'name': 'mat_sharp:app_blocking'
    },
    {
        'name': 'mat_sharp:app_registration'
    },
    {
        'name': 'mat_sharp:app_settings_alt'
    },
    {
        'name': 'mat_sharp:app_shortcut'
    },
    {
        'name': 'mat_sharp:architecture'
    },
    {
        'name': 'mat_sharp:archive'
    },
    {
        'name': 'mat_sharp:area_chart'
    },
    {
        'name': 'mat_sharp:arrow_back'
    },
    {
        'name': 'mat_sharp:arrow_back_ios'
    },
    {
        'name': 'mat_sharp:arrow_back_ios_new'
    },
    {
        'name': 'mat_sharp:arrow_circle_down'
    },
    {
        'name': 'mat_sharp:arrow_circle_left'
    },
    {
        'name': 'mat_sharp:arrow_circle_right'
    },
    {
        'name': 'mat_sharp:arrow_circle_up'
    },
    {
        'name': 'mat_sharp:arrow_downward'
    },
    {
        'name': 'mat_sharp:arrow_drop_down'
    },
    {
        'name': 'mat_sharp:arrow_drop_down_circle'
    },
    {
        'name': 'mat_sharp:arrow_drop_up'
    },
    {
        'name': 'mat_sharp:arrow_forward'
    },
    {
        'name': 'mat_sharp:arrow_forward_ios'
    },
    {
        'name': 'mat_sharp:arrow_left'
    },
    {
        'name': 'mat_sharp:arrow_outward'
    },
    {
        'name': 'mat_sharp:arrow_right'
    },
    {
        'name': 'mat_sharp:arrow_right_alt'
    },
    {
        'name': 'mat_sharp:arrow_upward'
    },
    {
        'name': 'mat_sharp:article'
    },
    {
        'name': 'mat_sharp:art_track'
    },
    {
        'name': 'mat_sharp:aspect_ratio'
    },
    {
        'name': 'mat_sharp:assessment'
    },
    {
        'name': 'mat_sharp:assignment'
    },
    {
        'name': 'mat_sharp:assignment_ind'
    },
    {
        'name': 'mat_sharp:assignment_late'
    },
    {
        'name': 'mat_sharp:assignment_return'
    },
    {
        'name': 'mat_sharp:assignment_returned'
    },
    {
        'name': 'mat_sharp:assignment_turned_in'
    },
    {
        'name': 'mat_sharp:assistant'
    },
    {
        'name': 'mat_sharp:assistant_direction'
    },
    {
        'name': 'mat_sharp:assistant_photo'
    },
    {
        'name': 'mat_sharp:assist_walker'
    },
    {
        'name': 'mat_sharp:assured_workload'
    },
    {
        'name': 'mat_sharp:atm'
    },
    {
        'name': 'mat_sharp:attachment'
    },
    {
        'name': 'mat_sharp:attach_email'
    },
    {
        'name': 'mat_sharp:attach_file'
    },
    {
        'name': 'mat_sharp:attach_money'
    },
    {
        'name': 'mat_sharp:attractions'
    },
    {
        'name': 'mat_sharp:attribution'
    },
    {
        'name': 'mat_sharp:audiotrack'
    },
    {
        'name': 'mat_sharp:audio_file'
    },
    {
        'name': 'mat_sharp:autofps_select'
    },
    {
        'name': 'mat_sharp:autorenew'
    },
    {
        'name': 'mat_sharp:auto_awesome'
    },
    {
        'name': 'mat_sharp:auto_awesome_mosaic'
    },
    {
        'name': 'mat_sharp:auto_awesome_motion'
    },
    {
        'name': 'mat_sharp:auto_delete'
    },
    {
        'name': 'mat_sharp:auto_fix_high'
    },
    {
        'name': 'mat_sharp:auto_fix_normal'
    },
    {
        'name': 'mat_sharp:auto_fix_off'
    },
    {
        'name': 'mat_sharp:auto_graph'
    },
    {
        'name': 'mat_sharp:auto_mode'
    },
    {
        'name': 'mat_sharp:auto_stories'
    },
    {
        'name': 'mat_sharp:av_timer'
    },
    {
        'name': 'mat_sharp:baby_changing_station'
    },
    {
        'name': 'mat_sharp:backpack'
    },
    {
        'name': 'mat_sharp:backspace'
    },
    {
        'name': 'mat_sharp:backup'
    },
    {
        'name': 'mat_sharp:backup_table'
    },
    {
        'name': 'mat_sharp:back_hand'
    },
    {
        'name': 'mat_sharp:badge'
    },
    {
        'name': 'mat_sharp:bakery_dining'
    },
    {
        'name': 'mat_sharp:balance'
    },
    {
        'name': 'mat_sharp:balcony'
    },
    {
        'name': 'mat_sharp:ballot'
    },
    {
        'name': 'mat_sharp:bar_chart'
    },
    {
        'name': 'mat_sharp:batch_prediction'
    },
    {
        'name': 'mat_sharp:bathroom'
    },
    {
        'name': 'mat_sharp:bathtub'
    },
    {
        'name': 'mat_sharp:battery_0_bar'
    },
    {
        'name': 'mat_sharp:battery_1_bar'
    },
    {
        'name': 'mat_sharp:battery_2_bar'
    },
    {
        'name': 'mat_sharp:battery_3_bar'
    },
    {
        'name': 'mat_sharp:battery_4_bar'
    },
    {
        'name': 'mat_sharp:battery_5_bar'
    },
    {
        'name': 'mat_sharp:battery_6_bar'
    },
    {
        'name': 'mat_sharp:battery_alert'
    },
    {
        'name': 'mat_sharp:battery_charging_full'
    },
    {
        'name': 'mat_sharp:battery_full'
    },
    {
        'name': 'mat_sharp:battery_saver'
    },
    {
        'name': 'mat_sharp:battery_std'
    },
    {
        'name': 'mat_sharp:battery_unknown'
    },
    {
        'name': 'mat_sharp:beach_access'
    },
    {
        'name': 'mat_sharp:bed'
    },
    {
        'name': 'mat_sharp:bedroom_baby'
    },
    {
        'name': 'mat_sharp:bedroom_child'
    },
    {
        'name': 'mat_sharp:bedroom_parent'
    },
    {
        'name': 'mat_sharp:bedtime'
    },
    {
        'name': 'mat_sharp:bedtime_off'
    },
    {
        'name': 'mat_sharp:beenhere'
    },
    {
        'name': 'mat_sharp:bento'
    },
    {
        'name': 'mat_sharp:bike_scooter'
    },
    {
        'name': 'mat_sharp:biotech'
    },
    {
        'name': 'mat_sharp:blender'
    },
    {
        'name': 'mat_sharp:blind'
    },
    {
        'name': 'mat_sharp:blinds'
    },
    {
        'name': 'mat_sharp:blinds_closed'
    },
    {
        'name': 'mat_sharp:block'
    },
    {
        'name': 'mat_sharp:bloodtype'
    },
    {
        'name': 'mat_sharp:bluetooth'
    },
    {
        'name': 'mat_sharp:bluetooth_audio'
    },
    {
        'name': 'mat_sharp:bluetooth_connected'
    },
    {
        'name': 'mat_sharp:bluetooth_disabled'
    },
    {
        'name': 'mat_sharp:bluetooth_drive'
    },
    {
        'name': 'mat_sharp:bluetooth_searching'
    },
    {
        'name': 'mat_sharp:blur_circular'
    },
    {
        'name': 'mat_sharp:blur_linear'
    },
    {
        'name': 'mat_sharp:blur_off'
    },
    {
        'name': 'mat_sharp:blur_on'
    },
    {
        'name': 'mat_sharp:bolt'
    },
    {
        'name': 'mat_sharp:book'
    },
    {
        'name': 'mat_sharp:bookmark'
    },
    {
        'name': 'mat_sharp:bookmarks'
    },
    {
        'name': 'mat_sharp:bookmark_add'
    },
    {
        'name': 'mat_sharp:bookmark_added'
    },
    {
        'name': 'mat_sharp:bookmark_border'
    },
    {
        'name': 'mat_sharp:bookmark_remove'
    },
    {
        'name': 'mat_sharp:book_online'
    },
    {
        'name': 'mat_sharp:border_all'
    },
    {
        'name': 'mat_sharp:border_bottom'
    },
    {
        'name': 'mat_sharp:border_clear'
    },
    {
        'name': 'mat_sharp:border_color'
    },
    {
        'name': 'mat_sharp:border_horizontal'
    },
    {
        'name': 'mat_sharp:border_inner'
    },
    {
        'name': 'mat_sharp:border_left'
    },
    {
        'name': 'mat_sharp:border_outer'
    },
    {
        'name': 'mat_sharp:border_right'
    },
    {
        'name': 'mat_sharp:border_style'
    },
    {
        'name': 'mat_sharp:border_top'
    },
    {
        'name': 'mat_sharp:border_vertical'
    },
    {
        'name': 'mat_sharp:boy'
    },
    {
        'name': 'mat_sharp:branding_watermark'
    },
    {
        'name': 'mat_sharp:breakfast_dining'
    },
    {
        'name': 'mat_sharp:brightness_1'
    },
    {
        'name': 'mat_sharp:brightness_2'
    },
    {
        'name': 'mat_sharp:brightness_3'
    },
    {
        'name': 'mat_sharp:brightness_4'
    },
    {
        'name': 'mat_sharp:brightness_5'
    },
    {
        'name': 'mat_sharp:brightness_6'
    },
    {
        'name': 'mat_sharp:brightness_7'
    },
    {
        'name': 'mat_sharp:brightness_auto'
    },
    {
        'name': 'mat_sharp:brightness_high'
    },
    {
        'name': 'mat_sharp:brightness_low'
    },
    {
        'name': 'mat_sharp:brightness_medium'
    },
    {
        'name': 'mat_sharp:broadcast_on_home'
    },
    {
        'name': 'mat_sharp:broadcast_on_personal'
    },
    {
        'name': 'mat_sharp:broken_image'
    },
    {
        'name': 'mat_sharp:browser_not_supported'
    },
    {
        'name': 'mat_sharp:browser_updated'
    },
    {
        'name': 'mat_sharp:browse_gallery'
    },
    {
        'name': 'mat_sharp:brunch_dining'
    },
    {
        'name': 'mat_sharp:brush'
    },
    {
        'name': 'mat_sharp:bubble_chart'
    },
    {
        'name': 'mat_sharp:bug_report'
    },
    {
        'name': 'mat_sharp:build'
    },
    {
        'name': 'mat_sharp:build_circle'
    },
    {
        'name': 'mat_sharp:bungalow'
    },
    {
        'name': 'mat_sharp:burst_mode'
    },
    {
        'name': 'mat_sharp:business'
    },
    {
        'name': 'mat_sharp:business_center'
    },
    {
        'name': 'mat_sharp:bus_alert'
    },
    {
        'name': 'mat_sharp:cabin'
    },
    {
        'name': 'mat_sharp:cable'
    },
    {
        'name': 'mat_sharp:cached'
    },
    {
        'name': 'mat_sharp:cake'
    },
    {
        'name': 'mat_sharp:calculate'
    },
    {
        'name': 'mat_sharp:calendar_month'
    },
    {
        'name': 'mat_sharp:calendar_today'
    },
    {
        'name': 'mat_sharp:calendar_view_day'
    },
    {
        'name': 'mat_sharp:calendar_view_month'
    },
    {
        'name': 'mat_sharp:calendar_view_week'
    },
    {
        'name': 'mat_sharp:call'
    },
    {
        'name': 'mat_sharp:call_end'
    },
    {
        'name': 'mat_sharp:call_made'
    },
    {
        'name': 'mat_sharp:call_merge'
    },
    {
        'name': 'mat_sharp:call_missed'
    },
    {
        'name': 'mat_sharp:call_missed_outgoing'
    },
    {
        'name': 'mat_sharp:call_received'
    },
    {
        'name': 'mat_sharp:call_split'
    },
    {
        'name': 'mat_sharp:call_to_action'
    },
    {
        'name': 'mat_sharp:camera'
    },
    {
        'name': 'mat_sharp:cameraswitch'
    },
    {
        'name': 'mat_sharp:camera_alt'
    },
    {
        'name': 'mat_sharp:camera_enhance'
    },
    {
        'name': 'mat_sharp:camera_front'
    },
    {
        'name': 'mat_sharp:camera_indoor'
    },
    {
        'name': 'mat_sharp:camera_outdoor'
    },
    {
        'name': 'mat_sharp:camera_rear'
    },
    {
        'name': 'mat_sharp:camera_roll'
    },
    {
        'name': 'mat_sharp:campaign'
    },
    {
        'name': 'mat_sharp:cancel'
    },
    {
        'name': 'mat_sharp:cancel_presentation'
    },
    {
        'name': 'mat_sharp:cancel_schedule_send'
    },
    {
        'name': 'mat_sharp:candlestick_chart'
    },
    {
        'name': 'mat_sharp:card_giftcard'
    },
    {
        'name': 'mat_sharp:card_membership'
    },
    {
        'name': 'mat_sharp:card_travel'
    },
    {
        'name': 'mat_sharp:carpenter'
    },
    {
        'name': 'mat_sharp:car_crash'
    },
    {
        'name': 'mat_sharp:car_rental'
    },
    {
        'name': 'mat_sharp:car_repair'
    },
    {
        'name': 'mat_sharp:cases'
    },
    {
        'name': 'mat_sharp:casino'
    },
    {
        'name': 'mat_sharp:cast'
    },
    {
        'name': 'mat_sharp:castle'
    },
    {
        'name': 'mat_sharp:cast_connected'
    },
    {
        'name': 'mat_sharp:cast_for_education'
    },
    {
        'name': 'mat_sharp:catching_pokemon'
    },
    {
        'name': 'mat_sharp:category'
    },
    {
        'name': 'mat_sharp:celebration'
    },
    {
        'name': 'mat_sharp:cell_tower'
    },
    {
        'name': 'mat_sharp:cell_wifi'
    },
    {
        'name': 'mat_sharp:center_focus_strong'
    },
    {
        'name': 'mat_sharp:center_focus_weak'
    },
    {
        'name': 'mat_sharp:chair'
    },
    {
        'name': 'mat_sharp:chair_alt'
    },
    {
        'name': 'mat_sharp:chalet'
    },
    {
        'name': 'mat_sharp:change_circle'
    },
    {
        'name': 'mat_sharp:change_history'
    },
    {
        'name': 'mat_sharp:charging_station'
    },
    {
        'name': 'mat_sharp:chat'
    },
    {
        'name': 'mat_sharp:chat_bubble'
    },
    {
        'name': 'mat_sharp:chat_bubble_outline'
    },
    {
        'name': 'mat_sharp:check'
    },
    {
        'name': 'mat_sharp:checklist'
    },
    {
        'name': 'mat_sharp:checklist_rtl'
    },
    {
        'name': 'mat_sharp:checkroom'
    },
    {
        'name': 'mat_sharp:check_box'
    },
    {
        'name': 'mat_sharp:check_box_outline_blank'
    },
    {
        'name': 'mat_sharp:check_circle'
    },
    {
        'name': 'mat_sharp:check_circle_outline'
    },
    {
        'name': 'mat_sharp:chevron_left'
    },
    {
        'name': 'mat_sharp:chevron_right'
    },
    {
        'name': 'mat_sharp:child_care'
    },
    {
        'name': 'mat_sharp:child_friendly'
    },
    {
        'name': 'mat_sharp:chrome_reader_mode'
    },
    {
        'name': 'mat_sharp:church'
    },
    {
        'name': 'mat_sharp:circle'
    },
    {
        'name': 'mat_sharp:circle_notifications'
    },
    {
        'name': 'mat_sharp:class'
    },
    {
        'name': 'mat_sharp:cleaning_services'
    },
    {
        'name': 'mat_sharp:clean_hands'
    },
    {
        'name': 'mat_sharp:clear'
    },
    {
        'name': 'mat_sharp:clear_all'
    },
    {
        'name': 'mat_sharp:close'
    },
    {
        'name': 'mat_sharp:closed_caption'
    },
    {
        'name': 'mat_sharp:closed_caption_disabled'
    },
    {
        'name': 'mat_sharp:closed_caption_off'
    },
    {
        'name': 'mat_sharp:close_fullscreen'
    },
    {
        'name': 'mat_sharp:cloud'
    },
    {
        'name': 'mat_sharp:cloud_circle'
    },
    {
        'name': 'mat_sharp:cloud_done'
    },
    {
        'name': 'mat_sharp:cloud_download'
    },
    {
        'name': 'mat_sharp:cloud_off'
    },
    {
        'name': 'mat_sharp:cloud_queue'
    },
    {
        'name': 'mat_sharp:cloud_sync'
    },
    {
        'name': 'mat_sharp:cloud_upload'
    },
    {
        'name': 'mat_sharp:co2'
    },
    {
        'name': 'mat_sharp:code'
    },
    {
        'name': 'mat_sharp:code_off'
    },
    {
        'name': 'mat_sharp:coffee'
    },
    {
        'name': 'mat_sharp:coffee_maker'
    },
    {
        'name': 'mat_sharp:collections'
    },
    {
        'name': 'mat_sharp:collections_bookmark'
    },
    {
        'name': 'mat_sharp:colorize'
    },
    {
        'name': 'mat_sharp:color_lens'
    },
    {
        'name': 'mat_sharp:comment'
    },
    {
        'name': 'mat_sharp:comments_disabled'
    },
    {
        'name': 'mat_sharp:comment_bank'
    },
    {
        'name': 'mat_sharp:commit'
    },
    {
        'name': 'mat_sharp:commute'
    },
    {
        'name': 'mat_sharp:compare'
    },
    {
        'name': 'mat_sharp:compare_arrows'
    },
    {
        'name': 'mat_sharp:compass_calibration'
    },
    {
        'name': 'mat_sharp:compost'
    },
    {
        'name': 'mat_sharp:compress'
    },
    {
        'name': 'mat_sharp:computer'
    },
    {
        'name': 'mat_sharp:confirmation_number'
    },
    {
        'name': 'mat_sharp:connected_tv'
    },
    {
        'name': 'mat_sharp:connecting_airports'
    },
    {
        'name': 'mat_sharp:connect_without_contact'
    },
    {
        'name': 'mat_sharp:construction'
    },
    {
        'name': 'mat_sharp:contactless'
    },
    {
        'name': 'mat_sharp:contacts'
    },
    {
        'name': 'mat_sharp:contact_emergency'
    },
    {
        'name': 'mat_sharp:contact_mail'
    },
    {
        'name': 'mat_sharp:contact_page'
    },
    {
        'name': 'mat_sharp:contact_phone'
    },
    {
        'name': 'mat_sharp:contact_support'
    },
    {
        'name': 'mat_sharp:content_copy'
    },
    {
        'name': 'mat_sharp:content_cut'
    },
    {
        'name': 'mat_sharp:content_paste'
    },
    {
        'name': 'mat_sharp:content_paste_go'
    },
    {
        'name': 'mat_sharp:content_paste_off'
    },
    {
        'name': 'mat_sharp:content_paste_search'
    },
    {
        'name': 'mat_sharp:contrast'
    },
    {
        'name': 'mat_sharp:control_camera'
    },
    {
        'name': 'mat_sharp:control_point'
    },
    {
        'name': 'mat_sharp:control_point_duplicate'
    },
    {
        'name': 'mat_sharp:cookie'
    },
    {
        'name': 'mat_sharp:copyright'
    },
    {
        'name': 'mat_sharp:copy_all'
    },
    {
        'name': 'mat_sharp:coronavirus'
    },
    {
        'name': 'mat_sharp:corporate_fare'
    },
    {
        'name': 'mat_sharp:cottage'
    },
    {
        'name': 'mat_sharp:countertops'
    },
    {
        'name': 'mat_sharp:co_present'
    },
    {
        'name': 'mat_sharp:create'
    },
    {
        'name': 'mat_sharp:create_new_folder'
    },
    {
        'name': 'mat_sharp:credit_card'
    },
    {
        'name': 'mat_sharp:credit_card_off'
    },
    {
        'name': 'mat_sharp:credit_score'
    },
    {
        'name': 'mat_sharp:crib'
    },
    {
        'name': 'mat_sharp:crisis_alert'
    },
    {
        'name': 'mat_sharp:crop'
    },
    {
        'name': 'mat_sharp:crop_16_9'
    },
    {
        'name': 'mat_sharp:crop_3_2'
    },
    {
        'name': 'mat_sharp:crop_5_4'
    },
    {
        'name': 'mat_sharp:crop_7_5'
    },
    {
        'name': 'mat_sharp:crop_din'
    },
    {
        'name': 'mat_sharp:crop_free'
    },
    {
        'name': 'mat_sharp:crop_landscape'
    },
    {
        'name': 'mat_sharp:crop_original'
    },
    {
        'name': 'mat_sharp:crop_portrait'
    },
    {
        'name': 'mat_sharp:crop_rotate'
    },
    {
        'name': 'mat_sharp:crop_square'
    },
    {
        'name': 'mat_sharp:cruelty_free'
    },
    {
        'name': 'mat_sharp:css'
    },
    {
        'name': 'mat_sharp:currency_bitcoin'
    },
    {
        'name': 'mat_sharp:currency_exchange'
    },
    {
        'name': 'mat_sharp:currency_franc'
    },
    {
        'name': 'mat_sharp:currency_lira'
    },
    {
        'name': 'mat_sharp:currency_pound'
    },
    {
        'name': 'mat_sharp:currency_ruble'
    },
    {
        'name': 'mat_sharp:currency_rupee'
    },
    {
        'name': 'mat_sharp:currency_yen'
    },
    {
        'name': 'mat_sharp:currency_yuan'
    },
    {
        'name': 'mat_sharp:curtains'
    },
    {
        'name': 'mat_sharp:curtains_closed'
    },
    {
        'name': 'mat_sharp:cyclone'
    },
    {
        'name': 'mat_sharp:dangerous'
    },
    {
        'name': 'mat_sharp:dark_mode'
    },
    {
        'name': 'mat_sharp:dashboard'
    },
    {
        'name': 'mat_sharp:dashboard_customize'
    },
    {
        'name': 'mat_sharp:dataset'
    },
    {
        'name': 'mat_sharp:dataset_linked'
    },
    {
        'name': 'mat_sharp:data_array'
    },
    {
        'name': 'mat_sharp:data_exploration'
    },
    {
        'name': 'mat_sharp:data_object'
    },
    {
        'name': 'mat_sharp:data_saver_off'
    },
    {
        'name': 'mat_sharp:data_saver_on'
    },
    {
        'name': 'mat_sharp:data_thresholding'
    },
    {
        'name': 'mat_sharp:data_usage'
    },
    {
        'name': 'mat_sharp:date_range'
    },
    {
        'name': 'mat_sharp:deblur'
    },
    {
        'name': 'mat_sharp:deck'
    },
    {
        'name': 'mat_sharp:dehaze'
    },
    {
        'name': 'mat_sharp:delete'
    },
    {
        'name': 'mat_sharp:delete_forever'
    },
    {
        'name': 'mat_sharp:delete_outline'
    },
    {
        'name': 'mat_sharp:delete_sweep'
    },
    {
        'name': 'mat_sharp:delivery_dining'
    },
    {
        'name': 'mat_sharp:density_large'
    },
    {
        'name': 'mat_sharp:density_medium'
    },
    {
        'name': 'mat_sharp:density_small'
    },
    {
        'name': 'mat_sharp:departure_board'
    },
    {
        'name': 'mat_sharp:description'
    },
    {
        'name': 'mat_sharp:deselect'
    },
    {
        'name': 'mat_sharp:design_services'
    },
    {
        'name': 'mat_sharp:desk'
    },
    {
        'name': 'mat_sharp:desktop_access_disabled'
    },
    {
        'name': 'mat_sharp:desktop_mac'
    },
    {
        'name': 'mat_sharp:desktop_windows'
    },
    {
        'name': 'mat_sharp:details'
    },
    {
        'name': 'mat_sharp:developer_board'
    },
    {
        'name': 'mat_sharp:developer_board_off'
    },
    {
        'name': 'mat_sharp:developer_mode'
    },
    {
        'name': 'mat_sharp:devices'
    },
    {
        'name': 'mat_sharp:devices_fold'
    },
    {
        'name': 'mat_sharp:devices_other'
    },
    {
        'name': 'mat_sharp:device_hub'
    },
    {
        'name': 'mat_sharp:device_thermostat'
    },
    {
        'name': 'mat_sharp:device_unknown'
    },
    {
        'name': 'mat_sharp:dialer_sip'
    },
    {
        'name': 'mat_sharp:dialpad'
    },
    {
        'name': 'mat_sharp:diamond'
    },
    {
        'name': 'mat_sharp:difference'
    },
    {
        'name': 'mat_sharp:dining'
    },
    {
        'name': 'mat_sharp:dinner_dining'
    },
    {
        'name': 'mat_sharp:directions'
    },
    {
        'name': 'mat_sharp:directions_bike'
    },
    {
        'name': 'mat_sharp:directions_boat'
    },
    {
        'name': 'mat_sharp:directions_boat_filled'
    },
    {
        'name': 'mat_sharp:directions_bus'
    },
    {
        'name': 'mat_sharp:directions_bus_filled'
    },
    {
        'name': 'mat_sharp:directions_car'
    },
    {
        'name': 'mat_sharp:directions_car_filled'
    },
    {
        'name': 'mat_sharp:directions_off'
    },
    {
        'name': 'mat_sharp:directions_railway'
    },
    {
        'name': 'mat_sharp:directions_railway_filled'
    },
    {
        'name': 'mat_sharp:directions_run'
    },
    {
        'name': 'mat_sharp:directions_subway'
    },
    {
        'name': 'mat_sharp:directions_subway_filled'
    },
    {
        'name': 'mat_sharp:directions_transit'
    },
    {
        'name': 'mat_sharp:directions_transit_filled'
    },
    {
        'name': 'mat_sharp:directions_walk'
    },
    {
        'name': 'mat_sharp:dirty_lens'
    },
    {
        'name': 'mat_sharp:disabled_by_default'
    },
    {
        'name': 'mat_sharp:disabled_visible'
    },
    {
        'name': 'mat_sharp:discount'
    },
    {
        'name': 'mat_sharp:disc_full'
    },
    {
        'name': 'mat_sharp:display_settings'
    },
    {
        'name': 'mat_sharp:diversity_1'
    },
    {
        'name': 'mat_sharp:diversity_2'
    },
    {
        'name': 'mat_sharp:diversity_3'
    },
    {
        'name': 'mat_sharp:dns'
    },
    {
        'name': 'mat_sharp:dock'
    },
    {
        'name': 'mat_sharp:document_scanner'
    },
    {
        'name': 'mat_sharp:domain'
    },
    {
        'name': 'mat_sharp:domain_add'
    },
    {
        'name': 'mat_sharp:domain_disabled'
    },
    {
        'name': 'mat_sharp:domain_verification'
    },
    {
        'name': 'mat_sharp:done'
    },
    {
        'name': 'mat_sharp:done_all'
    },
    {
        'name': 'mat_sharp:done_outline'
    },
    {
        'name': 'mat_sharp:donut_large'
    },
    {
        'name': 'mat_sharp:donut_small'
    },
    {
        'name': 'mat_sharp:doorbell'
    },
    {
        'name': 'mat_sharp:door_back'
    },
    {
        'name': 'mat_sharp:door_front'
    },
    {
        'name': 'mat_sharp:door_sliding'
    },
    {
        'name': 'mat_sharp:double_arrow'
    },
    {
        'name': 'mat_sharp:downhill_skiing'
    },
    {
        'name': 'mat_sharp:download'
    },
    {
        'name': 'mat_sharp:downloading'
    },
    {
        'name': 'mat_sharp:download_done'
    },
    {
        'name': 'mat_sharp:download_for_offline'
    },
    {
        'name': 'mat_sharp:do_disturb'
    },
    {
        'name': 'mat_sharp:do_disturb_alt'
    },
    {
        'name': 'mat_sharp:do_disturb_off'
    },
    {
        'name': 'mat_sharp:do_disturb_on'
    },
    {
        'name': 'mat_sharp:do_not_disturb'
    },
    {
        'name': 'mat_sharp:do_not_disturb_alt'
    },
    {
        'name': 'mat_sharp:do_not_disturb_off'
    },
    {
        'name': 'mat_sharp:do_not_disturb_on'
    },
    {
        'name': 'mat_sharp:do_not_disturb_on_total_silence'
    },
    {
        'name': 'mat_sharp:do_not_step'
    },
    {
        'name': 'mat_sharp:do_not_touch'
    },
    {
        'name': 'mat_sharp:drafts'
    },
    {
        'name': 'mat_sharp:drag_handle'
    },
    {
        'name': 'mat_sharp:drag_indicator'
    },
    {
        'name': 'mat_sharp:draw'
    },
    {
        'name': 'mat_sharp:drive_eta'
    },
    {
        'name': 'mat_sharp:drive_file_move'
    },
    {
        'name': 'mat_sharp:drive_file_move_rtl'
    },
    {
        'name': 'mat_sharp:drive_file_rename_outline'
    },
    {
        'name': 'mat_sharp:drive_folder_upload'
    },
    {
        'name': 'mat_sharp:dry'
    },
    {
        'name': 'mat_sharp:dry_cleaning'
    },
    {
        'name': 'mat_sharp:duo'
    },
    {
        'name': 'mat_sharp:dvr'
    },
    {
        'name': 'mat_sharp:dynamic_feed'
    },
    {
        'name': 'mat_sharp:dynamic_form'
    },
    {
        'name': 'mat_sharp:earbuds'
    },
    {
        'name': 'mat_sharp:earbuds_battery'
    },
    {
        'name': 'mat_sharp:east'
    },
    {
        'name': 'mat_sharp:edgesensor_high'
    },
    {
        'name': 'mat_sharp:edgesensor_low'
    },
    {
        'name': 'mat_sharp:edit'
    },
    {
        'name': 'mat_sharp:edit_attributes'
    },
    {
        'name': 'mat_sharp:edit_calendar'
    },
    {
        'name': 'mat_sharp:edit_location'
    },
    {
        'name': 'mat_sharp:edit_location_alt'
    },
    {
        'name': 'mat_sharp:edit_note'
    },
    {
        'name': 'mat_sharp:edit_notifications'
    },
    {
        'name': 'mat_sharp:edit_off'
    },
    {
        'name': 'mat_sharp:edit_road'
    },
    {
        'name': 'mat_sharp:egg'
    },
    {
        'name': 'mat_sharp:egg_alt'
    },
    {
        'name': 'mat_sharp:eject'
    },
    {
        'name': 'mat_sharp:elderly'
    },
    {
        'name': 'mat_sharp:elderly_woman'
    },
    {
        'name': 'mat_sharp:electrical_services'
    },
    {
        'name': 'mat_sharp:electric_bike'
    },
    {
        'name': 'mat_sharp:electric_bolt'
    },
    {
        'name': 'mat_sharp:electric_car'
    },
    {
        'name': 'mat_sharp:electric_meter'
    },
    {
        'name': 'mat_sharp:electric_moped'
    },
    {
        'name': 'mat_sharp:electric_rickshaw'
    },
    {
        'name': 'mat_sharp:electric_scooter'
    },
    {
        'name': 'mat_sharp:elevator'
    },
    {
        'name': 'mat_sharp:email'
    },
    {
        'name': 'mat_sharp:emergency'
    },
    {
        'name': 'mat_sharp:emergency_recording'
    },
    {
        'name': 'mat_sharp:emergency_share'
    },
    {
        'name': 'mat_sharp:emoji_emotions'
    },
    {
        'name': 'mat_sharp:emoji_events'
    },
    {
        'name': 'mat_sharp:emoji_food_beverage'
    },
    {
        'name': 'mat_sharp:emoji_nature'
    },
    {
        'name': 'mat_sharp:emoji_objects'
    },
    {
        'name': 'mat_sharp:emoji_people'
    },
    {
        'name': 'mat_sharp:emoji_symbols'
    },
    {
        'name': 'mat_sharp:emoji_transportation'
    },
    {
        'name': 'mat_sharp:energy_savings_leaf'
    },
    {
        'name': 'mat_sharp:engineering'
    },
    {
        'name': 'mat_sharp:enhanced_encryption'
    },
    {
        'name': 'mat_sharp:equalizer'
    },
    {
        'name': 'mat_sharp:error'
    },
    {
        'name': 'mat_sharp:error_outline'
    },
    {
        'name': 'mat_sharp:escalator'
    },
    {
        'name': 'mat_sharp:escalator_warning'
    },
    {
        'name': 'mat_sharp:euro'
    },
    {
        'name': 'mat_sharp:euro_symbol'
    },
    {
        'name': 'mat_sharp:event'
    },
    {
        'name': 'mat_sharp:event_available'
    },
    {
        'name': 'mat_sharp:event_busy'
    },
    {
        'name': 'mat_sharp:event_note'
    },
    {
        'name': 'mat_sharp:event_repeat'
    },
    {
        'name': 'mat_sharp:event_seat'
    },
    {
        'name': 'mat_sharp:ev_station'
    },
    {
        'name': 'mat_sharp:exit_to_app'
    },
    {
        'name': 'mat_sharp:expand'
    },
    {
        'name': 'mat_sharp:expand_circle_down'
    },
    {
        'name': 'mat_sharp:expand_less'
    },
    {
        'name': 'mat_sharp:expand_more'
    },
    {
        'name': 'mat_sharp:explicit'
    },
    {
        'name': 'mat_sharp:explore'
    },
    {
        'name': 'mat_sharp:explore_off'
    },
    {
        'name': 'mat_sharp:exposure'
    },
    {
        'name': 'mat_sharp:exposure_neg_1'
    },
    {
        'name': 'mat_sharp:exposure_neg_2'
    },
    {
        'name': 'mat_sharp:exposure_plus_1'
    },
    {
        'name': 'mat_sharp:exposure_plus_2'
    },
    {
        'name': 'mat_sharp:exposure_zero'
    },
    {
        'name': 'mat_sharp:extension'
    },
    {
        'name': 'mat_sharp:extension_off'
    },
    {
        'name': 'mat_sharp:e_mobiledata'
    },
    {
        'name': 'mat_sharp:face'
    },
    {
        'name': 'mat_sharp:face_2'
    },
    {
        'name': 'mat_sharp:face_3'
    },
    {
        'name': 'mat_sharp:face_4'
    },
    {
        'name': 'mat_sharp:face_5'
    },
    {
        'name': 'mat_sharp:face_6'
    },
    {
        'name': 'mat_sharp:face_retouching_natural'
    },
    {
        'name': 'mat_sharp:face_retouching_off'
    },
    {
        'name': 'mat_sharp:factory'
    },
    {
        'name': 'mat_sharp:fact_check'
    },
    {
        'name': 'mat_sharp:family_restroom'
    },
    {
        'name': 'mat_sharp:fastfood'
    },
    {
        'name': 'mat_sharp:fast_forward'
    },
    {
        'name': 'mat_sharp:fast_rewind'
    },
    {
        'name': 'mat_sharp:favorite'
    },
    {
        'name': 'mat_sharp:favorite_border'
    },
    {
        'name': 'mat_sharp:fax'
    },
    {
        'name': 'mat_sharp:featured_play_list'
    },
    {
        'name': 'mat_sharp:featured_video'
    },
    {
        'name': 'mat_sharp:feed'
    },
    {
        'name': 'mat_sharp:feedback'
    },
    {
        'name': 'mat_sharp:female'
    },
    {
        'name': 'mat_sharp:fence'
    },
    {
        'name': 'mat_sharp:festival'
    },
    {
        'name': 'mat_sharp:fiber_dvr'
    },
    {
        'name': 'mat_sharp:fiber_manual_record'
    },
    {
        'name': 'mat_sharp:fiber_new'
    },
    {
        'name': 'mat_sharp:fiber_pin'
    },
    {
        'name': 'mat_sharp:fiber_smart_record'
    },
    {
        'name': 'mat_sharp:file_copy'
    },
    {
        'name': 'mat_sharp:file_download'
    },
    {
        'name': 'mat_sharp:file_download_done'
    },
    {
        'name': 'mat_sharp:file_download_off'
    },
    {
        'name': 'mat_sharp:file_open'
    },
    {
        'name': 'mat_sharp:file_present'
    },
    {
        'name': 'mat_sharp:file_upload'
    },
    {
        'name': 'mat_sharp:filter'
    },
    {
        'name': 'mat_sharp:filter_1'
    },
    {
        'name': 'mat_sharp:filter_2'
    },
    {
        'name': 'mat_sharp:filter_3'
    },
    {
        'name': 'mat_sharp:filter_4'
    },
    {
        'name': 'mat_sharp:filter_5'
    },
    {
        'name': 'mat_sharp:filter_6'
    },
    {
        'name': 'mat_sharp:filter_7'
    },
    {
        'name': 'mat_sharp:filter_8'
    },
    {
        'name': 'mat_sharp:filter_9'
    },
    {
        'name': 'mat_sharp:filter_9_plus'
    },
    {
        'name': 'mat_sharp:filter_alt'
    },
    {
        'name': 'mat_sharp:filter_alt_off'
    },
    {
        'name': 'mat_sharp:filter_b_and_w'
    },
    {
        'name': 'mat_sharp:filter_center_focus'
    },
    {
        'name': 'mat_sharp:filter_drama'
    },
    {
        'name': 'mat_sharp:filter_frames'
    },
    {
        'name': 'mat_sharp:filter_hdr'
    },
    {
        'name': 'mat_sharp:filter_list'
    },
    {
        'name': 'mat_sharp:filter_list_off'
    },
    {
        'name': 'mat_sharp:filter_none'
    },
    {
        'name': 'mat_sharp:filter_tilt_shift'
    },
    {
        'name': 'mat_sharp:filter_vintage'
    },
    {
        'name': 'mat_sharp:find_in_page'
    },
    {
        'name': 'mat_sharp:find_replace'
    },
    {
        'name': 'mat_sharp:fingerprint'
    },
    {
        'name': 'mat_sharp:fireplace'
    },
    {
        'name': 'mat_sharp:fire_extinguisher'
    },
    {
        'name': 'mat_sharp:fire_hydrant_alt'
    },
    {
        'name': 'mat_sharp:fire_truck'
    },
    {
        'name': 'mat_sharp:first_page'
    },
    {
        'name': 'mat_sharp:fitbit'
    },
    {
        'name': 'mat_sharp:fitness_center'
    },
    {
        'name': 'mat_sharp:fit_screen'
    },
    {
        'name': 'mat_sharp:flag'
    },
    {
        'name': 'mat_sharp:flag_circle'
    },
    {
        'name': 'mat_sharp:flaky'
    },
    {
        'name': 'mat_sharp:flare'
    },
    {
        'name': 'mat_sharp:flashlight_off'
    },
    {
        'name': 'mat_sharp:flashlight_on'
    },
    {
        'name': 'mat_sharp:flash_auto'
    },
    {
        'name': 'mat_sharp:flash_off'
    },
    {
        'name': 'mat_sharp:flash_on'
    },
    {
        'name': 'mat_sharp:flatware'
    },
    {
        'name': 'mat_sharp:flight'
    },
    {
        'name': 'mat_sharp:flight_class'
    },
    {
        'name': 'mat_sharp:flight_land'
    },
    {
        'name': 'mat_sharp:flight_takeoff'
    },
    {
        'name': 'mat_sharp:flip'
    },
    {
        'name': 'mat_sharp:flip_camera_android'
    },
    {
        'name': 'mat_sharp:flip_camera_ios'
    },
    {
        'name': 'mat_sharp:flip_to_back'
    },
    {
        'name': 'mat_sharp:flip_to_front'
    },
    {
        'name': 'mat_sharp:flood'
    },
    {
        'name': 'mat_sharp:fluorescent'
    },
    {
        'name': 'mat_sharp:flutter_dash'
    },
    {
        'name': 'mat_sharp:fmd_bad'
    },
    {
        'name': 'mat_sharp:fmd_good'
    },
    {
        'name': 'mat_sharp:folder'
    },
    {
        'name': 'mat_sharp:folder_copy'
    },
    {
        'name': 'mat_sharp:folder_delete'
    },
    {
        'name': 'mat_sharp:folder_off'
    },
    {
        'name': 'mat_sharp:folder_open'
    },
    {
        'name': 'mat_sharp:folder_shared'
    },
    {
        'name': 'mat_sharp:folder_special'
    },
    {
        'name': 'mat_sharp:folder_zip'
    },
    {
        'name': 'mat_sharp:follow_the_signs'
    },
    {
        'name': 'mat_sharp:font_download'
    },
    {
        'name': 'mat_sharp:font_download_off'
    },
    {
        'name': 'mat_sharp:food_bank'
    },
    {
        'name': 'mat_sharp:forest'
    },
    {
        'name': 'mat_sharp:fork_left'
    },
    {
        'name': 'mat_sharp:fork_right'
    },
    {
        'name': 'mat_sharp:format_align_center'
    },
    {
        'name': 'mat_sharp:format_align_justify'
    },
    {
        'name': 'mat_sharp:format_align_left'
    },
    {
        'name': 'mat_sharp:format_align_right'
    },
    {
        'name': 'mat_sharp:format_bold'
    },
    {
        'name': 'mat_sharp:format_clear'
    },
    {
        'name': 'mat_sharp:format_color_fill'
    },
    {
        'name': 'mat_sharp:format_color_reset'
    },
    {
        'name': 'mat_sharp:format_color_text'
    },
    {
        'name': 'mat_sharp:format_indent_decrease'
    },
    {
        'name': 'mat_sharp:format_indent_increase'
    },
    {
        'name': 'mat_sharp:format_italic'
    },
    {
        'name': 'mat_sharp:format_line_spacing'
    },
    {
        'name': 'mat_sharp:format_list_bulleted'
    },
    {
        'name': 'mat_sharp:format_list_numbered'
    },
    {
        'name': 'mat_sharp:format_list_numbered_rtl'
    },
    {
        'name': 'mat_sharp:format_overline'
    },
    {
        'name': 'mat_sharp:format_paint'
    },
    {
        'name': 'mat_sharp:format_quote'
    },
    {
        'name': 'mat_sharp:format_shapes'
    },
    {
        'name': 'mat_sharp:format_size'
    },
    {
        'name': 'mat_sharp:format_strikethrough'
    },
    {
        'name': 'mat_sharp:format_textdirection_l_to_r'
    },
    {
        'name': 'mat_sharp:format_textdirection_r_to_l'
    },
    {
        'name': 'mat_sharp:format_underlined'
    },
    {
        'name': 'mat_sharp:fort'
    },
    {
        'name': 'mat_sharp:forum'
    },
    {
        'name': 'mat_sharp:forward'
    },
    {
        'name': 'mat_sharp:forward_10'
    },
    {
        'name': 'mat_sharp:forward_30'
    },
    {
        'name': 'mat_sharp:forward_5'
    },
    {
        'name': 'mat_sharp:forward_to_inbox'
    },
    {
        'name': 'mat_sharp:foundation'
    },
    {
        'name': 'mat_sharp:free_breakfast'
    },
    {
        'name': 'mat_sharp:free_cancellation'
    },
    {
        'name': 'mat_sharp:front_hand'
    },
    {
        'name': 'mat_sharp:fullscreen'
    },
    {
        'name': 'mat_sharp:fullscreen_exit'
    },
    {
        'name': 'mat_sharp:functions'
    },
    {
        'name': 'mat_sharp:gamepad'
    },
    {
        'name': 'mat_sharp:games'
    },
    {
        'name': 'mat_sharp:garage'
    },
    {
        'name': 'mat_sharp:gas_meter'
    },
    {
        'name': 'mat_sharp:gavel'
    },
    {
        'name': 'mat_sharp:generating_tokens'
    },
    {
        'name': 'mat_sharp:gesture'
    },
    {
        'name': 'mat_sharp:get_app'
    },
    {
        'name': 'mat_sharp:gif'
    },
    {
        'name': 'mat_sharp:gif_box'
    },
    {
        'name': 'mat_sharp:girl'
    },
    {
        'name': 'mat_sharp:gite'
    },
    {
        'name': 'mat_sharp:golf_course'
    },
    {
        'name': 'mat_sharp:gpp_bad'
    },
    {
        'name': 'mat_sharp:gpp_good'
    },
    {
        'name': 'mat_sharp:gpp_maybe'
    },
    {
        'name': 'mat_sharp:gps_fixed'
    },
    {
        'name': 'mat_sharp:gps_not_fixed'
    },
    {
        'name': 'mat_sharp:gps_off'
    },
    {
        'name': 'mat_sharp:grade'
    },
    {
        'name': 'mat_sharp:gradient'
    },
    {
        'name': 'mat_sharp:grading'
    },
    {
        'name': 'mat_sharp:grain'
    },
    {
        'name': 'mat_sharp:graphic_eq'
    },
    {
        'name': 'mat_sharp:grass'
    },
    {
        'name': 'mat_sharp:grid_3x3'
    },
    {
        'name': 'mat_sharp:grid_4x4'
    },
    {
        'name': 'mat_sharp:grid_goldenratio'
    },
    {
        'name': 'mat_sharp:grid_off'
    },
    {
        'name': 'mat_sharp:grid_on'
    },
    {
        'name': 'mat_sharp:grid_view'
    },
    {
        'name': 'mat_sharp:group'
    },
    {
        'name': 'mat_sharp:groups'
    },
    {
        'name': 'mat_sharp:groups_2'
    },
    {
        'name': 'mat_sharp:groups_3'
    },
    {
        'name': 'mat_sharp:group_add'
    },
    {
        'name': 'mat_sharp:group_off'
    },
    {
        'name': 'mat_sharp:group_remove'
    },
    {
        'name': 'mat_sharp:group_work'
    },
    {
        'name': 'mat_sharp:g_mobiledata'
    },
    {
        'name': 'mat_sharp:g_translate'
    },
    {
        'name': 'mat_sharp:hail'
    },
    {
        'name': 'mat_sharp:handshake'
    },
    {
        'name': 'mat_sharp:handyman'
    },
    {
        'name': 'mat_sharp:hardware'
    },
    {
        'name': 'mat_sharp:hd'
    },
    {
        'name': 'mat_sharp:hdr_auto'
    },
    {
        'name': 'mat_sharp:hdr_auto_select'
    },
    {
        'name': 'mat_sharp:hdr_enhanced_select'
    },
    {
        'name': 'mat_sharp:hdr_off'
    },
    {
        'name': 'mat_sharp:hdr_off_select'
    },
    {
        'name': 'mat_sharp:hdr_on'
    },
    {
        'name': 'mat_sharp:hdr_on_select'
    },
    {
        'name': 'mat_sharp:hdr_plus'
    },
    {
        'name': 'mat_sharp:hdr_strong'
    },
    {
        'name': 'mat_sharp:hdr_weak'
    },
    {
        'name': 'mat_sharp:headphones'
    },
    {
        'name': 'mat_sharp:headphones_battery'
    },
    {
        'name': 'mat_sharp:headset'
    },
    {
        'name': 'mat_sharp:headset_mic'
    },
    {
        'name': 'mat_sharp:headset_off'
    },
    {
        'name': 'mat_sharp:healing'
    },
    {
        'name': 'mat_sharp:health_and_safety'
    },
    {
        'name': 'mat_sharp:hearing'
    },
    {
        'name': 'mat_sharp:hearing_disabled'
    },
    {
        'name': 'mat_sharp:heart_broken'
    },
    {
        'name': 'mat_sharp:heat_pump'
    },
    {
        'name': 'mat_sharp:height'
    },
    {
        'name': 'mat_sharp:help'
    },
    {
        'name': 'mat_sharp:help_center'
    },
    {
        'name': 'mat_sharp:help_outline'
    },
    {
        'name': 'mat_sharp:hevc'
    },
    {
        'name': 'mat_sharp:hexagon'
    },
    {
        'name': 'mat_sharp:hide_image'
    },
    {
        'name': 'mat_sharp:hide_source'
    },
    {
        'name': 'mat_sharp:highlight'
    },
    {
        'name': 'mat_sharp:highlight_alt'
    },
    {
        'name': 'mat_sharp:highlight_off'
    },
    {
        'name': 'mat_sharp:high_quality'
    },
    {
        'name': 'mat_sharp:hiking'
    },
    {
        'name': 'mat_sharp:history'
    },
    {
        'name': 'mat_sharp:history_edu'
    },
    {
        'name': 'mat_sharp:history_toggle_off'
    },
    {
        'name': 'mat_sharp:hive'
    },
    {
        'name': 'mat_sharp:hls'
    },
    {
        'name': 'mat_sharp:hls_off'
    },
    {
        'name': 'mat_sharp:holiday_village'
    },
    {
        'name': 'mat_sharp:home'
    },
    {
        'name': 'mat_sharp:home_max'
    },
    {
        'name': 'mat_sharp:home_mini'
    },
    {
        'name': 'mat_sharp:home_repair_service'
    },
    {
        'name': 'mat_sharp:home_work'
    },
    {
        'name': 'mat_sharp:horizontal_distribute'
    },
    {
        'name': 'mat_sharp:horizontal_rule'
    },
    {
        'name': 'mat_sharp:horizontal_split'
    },
    {
        'name': 'mat_sharp:hotel'
    },
    {
        'name': 'mat_sharp:hotel_class'
    },
    {
        'name': 'mat_sharp:hot_tub'
    },
    {
        'name': 'mat_sharp:hourglass_bottom'
    },
    {
        'name': 'mat_sharp:hourglass_disabled'
    },
    {
        'name': 'mat_sharp:hourglass_empty'
    },
    {
        'name': 'mat_sharp:hourglass_full'
    },
    {
        'name': 'mat_sharp:hourglass_top'
    },
    {
        'name': 'mat_sharp:house'
    },
    {
        'name': 'mat_sharp:houseboat'
    },
    {
        'name': 'mat_sharp:house_siding'
    },
    {
        'name': 'mat_sharp:how_to_reg'
    },
    {
        'name': 'mat_sharp:how_to_vote'
    },
    {
        'name': 'mat_sharp:html'
    },
    {
        'name': 'mat_sharp:http'
    },
    {
        'name': 'mat_sharp:https'
    },
    {
        'name': 'mat_sharp:hub'
    },
    {
        'name': 'mat_sharp:hvac'
    },
    {
        'name': 'mat_sharp:h_mobiledata'
    },
    {
        'name': 'mat_sharp:h_plus_mobiledata'
    },
    {
        'name': 'mat_sharp:icecream'
    },
    {
        'name': 'mat_sharp:ice_skating'
    },
    {
        'name': 'mat_sharp:image'
    },
    {
        'name': 'mat_sharp:imagesearch_roller'
    },
    {
        'name': 'mat_sharp:image_aspect_ratio'
    },
    {
        'name': 'mat_sharp:image_not_supported'
    },
    {
        'name': 'mat_sharp:image_search'
    },
    {
        'name': 'mat_sharp:important_devices'
    },
    {
        'name': 'mat_sharp:import_contacts'
    },
    {
        'name': 'mat_sharp:import_export'
    },
    {
        'name': 'mat_sharp:inbox'
    },
    {
        'name': 'mat_sharp:incomplete_circle'
    },
    {
        'name': 'mat_sharp:indeterminate_check_box'
    },
    {
        'name': 'mat_sharp:info'
    },
    {
        'name': 'mat_sharp:input'
    },
    {
        'name': 'mat_sharp:insert_chart'
    },
    {
        'name': 'mat_sharp:insert_chart_outlined'
    },
    {
        'name': 'mat_sharp:insert_comment'
    },
    {
        'name': 'mat_sharp:insert_drive_file'
    },
    {
        'name': 'mat_sharp:insert_emoticon'
    },
    {
        'name': 'mat_sharp:insert_invitation'
    },
    {
        'name': 'mat_sharp:insert_link'
    },
    {
        'name': 'mat_sharp:insert_page_break'
    },
    {
        'name': 'mat_sharp:insert_photo'
    },
    {
        'name': 'mat_sharp:insights'
    },
    {
        'name': 'mat_sharp:install_desktop'
    },
    {
        'name': 'mat_sharp:install_mobile'
    },
    {
        'name': 'mat_sharp:integration_instructions'
    },
    {
        'name': 'mat_sharp:interests'
    },
    {
        'name': 'mat_sharp:interpreter_mode'
    },
    {
        'name': 'mat_sharp:inventory'
    },
    {
        'name': 'mat_sharp:inventory_2'
    },
    {
        'name': 'mat_sharp:invert_colors'
    },
    {
        'name': 'mat_sharp:invert_colors_off'
    },
    {
        'name': 'mat_sharp:ios_share'
    },
    {
        'name': 'mat_sharp:iron'
    },
    {
        'name': 'mat_sharp:iso'
    },
    {
        'name': 'mat_sharp:javascript'
    },
    {
        'name': 'mat_sharp:join_full'
    },
    {
        'name': 'mat_sharp:join_inner'
    },
    {
        'name': 'mat_sharp:join_left'
    },
    {
        'name': 'mat_sharp:join_right'
    },
    {
        'name': 'mat_sharp:kayaking'
    },
    {
        'name': 'mat_sharp:kebab_dining'
    },
    {
        'name': 'mat_sharp:key'
    },
    {
        'name': 'mat_sharp:keyboard'
    },
    {
        'name': 'mat_sharp:keyboard_alt'
    },
    {
        'name': 'mat_sharp:keyboard_arrow_down'
    },
    {
        'name': 'mat_sharp:keyboard_arrow_left'
    },
    {
        'name': 'mat_sharp:keyboard_arrow_right'
    },
    {
        'name': 'mat_sharp:keyboard_arrow_up'
    },
    {
        'name': 'mat_sharp:keyboard_backspace'
    },
    {
        'name': 'mat_sharp:keyboard_capslock'
    },
    {
        'name': 'mat_sharp:keyboard_command_key'
    },
    {
        'name': 'mat_sharp:keyboard_control_key'
    },
    {
        'name': 'mat_sharp:keyboard_double_arrow_down'
    },
    {
        'name': 'mat_sharp:keyboard_double_arrow_left'
    },
    {
        'name': 'mat_sharp:keyboard_double_arrow_right'
    },
    {
        'name': 'mat_sharp:keyboard_double_arrow_up'
    },
    {
        'name': 'mat_sharp:keyboard_hide'
    },
    {
        'name': 'mat_sharp:keyboard_option_key'
    },
    {
        'name': 'mat_sharp:keyboard_return'
    },
    {
        'name': 'mat_sharp:keyboard_tab'
    },
    {
        'name': 'mat_sharp:keyboard_voice'
    },
    {
        'name': 'mat_sharp:key_off'
    },
    {
        'name': 'mat_sharp:king_bed'
    },
    {
        'name': 'mat_sharp:kitchen'
    },
    {
        'name': 'mat_sharp:kitesurfing'
    },
    {
        'name': 'mat_sharp:label'
    },
    {
        'name': 'mat_sharp:label_important'
    },
    {
        'name': 'mat_sharp:label_off'
    },
    {
        'name': 'mat_sharp:lan'
    },
    {
        'name': 'mat_sharp:landscape'
    },
    {
        'name': 'mat_sharp:landslide'
    },
    {
        'name': 'mat_sharp:language'
    },
    {
        'name': 'mat_sharp:laptop'
    },
    {
        'name': 'mat_sharp:laptop_chromebook'
    },
    {
        'name': 'mat_sharp:laptop_mac'
    },
    {
        'name': 'mat_sharp:laptop_windows'
    },
    {
        'name': 'mat_sharp:last_page'
    },
    {
        'name': 'mat_sharp:launch'
    },
    {
        'name': 'mat_sharp:layers'
    },
    {
        'name': 'mat_sharp:layers_clear'
    },
    {
        'name': 'mat_sharp:leaderboard'
    },
    {
        'name': 'mat_sharp:leak_add'
    },
    {
        'name': 'mat_sharp:leak_remove'
    },
    {
        'name': 'mat_sharp:legend_toggle'
    },
    {
        'name': 'mat_sharp:lens'
    },
    {
        'name': 'mat_sharp:lens_blur'
    },
    {
        'name': 'mat_sharp:library_add'
    },
    {
        'name': 'mat_sharp:library_add_check'
    },
    {
        'name': 'mat_sharp:library_books'
    },
    {
        'name': 'mat_sharp:library_music'
    },
    {
        'name': 'mat_sharp:light'
    },
    {
        'name': 'mat_sharp:lightbulb'
    },
    {
        'name': 'mat_sharp:lightbulb_circle'
    },
    {
        'name': 'mat_sharp:light_mode'
    },
    {
        'name': 'mat_sharp:linear_scale'
    },
    {
        'name': 'mat_sharp:line_axis'
    },
    {
        'name': 'mat_sharp:line_style'
    },
    {
        'name': 'mat_sharp:line_weight'
    },
    {
        'name': 'mat_sharp:link'
    },
    {
        'name': 'mat_sharp:linked_camera'
    },
    {
        'name': 'mat_sharp:link_off'
    },
    {
        'name': 'mat_sharp:liquor'
    },
    {
        'name': 'mat_sharp:list'
    },
    {
        'name': 'mat_sharp:list_alt'
    },
    {
        'name': 'mat_sharp:live_help'
    },
    {
        'name': 'mat_sharp:live_tv'
    },
    {
        'name': 'mat_sharp:living'
    },
    {
        'name': 'mat_sharp:local_activity'
    },
    {
        'name': 'mat_sharp:local_airport'
    },
    {
        'name': 'mat_sharp:local_atm'
    },
    {
        'name': 'mat_sharp:local_bar'
    },
    {
        'name': 'mat_sharp:local_cafe'
    },
    {
        'name': 'mat_sharp:local_car_wash'
    },
    {
        'name': 'mat_sharp:local_convenience_store'
    },
    {
        'name': 'mat_sharp:local_dining'
    },
    {
        'name': 'mat_sharp:local_drink'
    },
    {
        'name': 'mat_sharp:local_fire_department'
    },
    {
        'name': 'mat_sharp:local_florist'
    },
    {
        'name': 'mat_sharp:local_gas_station'
    },
    {
        'name': 'mat_sharp:local_grocery_store'
    },
    {
        'name': 'mat_sharp:local_hospital'
    },
    {
        'name': 'mat_sharp:local_hotel'
    },
    {
        'name': 'mat_sharp:local_laundry_service'
    },
    {
        'name': 'mat_sharp:local_library'
    },
    {
        'name': 'mat_sharp:local_mall'
    },
    {
        'name': 'mat_sharp:local_movies'
    },
    {
        'name': 'mat_sharp:local_offer'
    },
    {
        'name': 'mat_sharp:local_parking'
    },
    {
        'name': 'mat_sharp:local_pharmacy'
    },
    {
        'name': 'mat_sharp:local_phone'
    },
    {
        'name': 'mat_sharp:local_pizza'
    },
    {
        'name': 'mat_sharp:local_play'
    },
    {
        'name': 'mat_sharp:local_police'
    },
    {
        'name': 'mat_sharp:local_post_office'
    },
    {
        'name': 'mat_sharp:local_printshop'
    },
    {
        'name': 'mat_sharp:local_see'
    },
    {
        'name': 'mat_sharp:local_shipping'
    },
    {
        'name': 'mat_sharp:local_taxi'
    },
    {
        'name': 'mat_sharp:location_city'
    },
    {
        'name': 'mat_sharp:location_disabled'
    },
    {
        'name': 'mat_sharp:location_off'
    },
    {
        'name': 'mat_sharp:location_on'
    },
    {
        'name': 'mat_sharp:location_searching'
    },
    {
        'name': 'mat_sharp:lock'
    },
    {
        'name': 'mat_sharp:lock_clock'
    },
    {
        'name': 'mat_sharp:lock_open'
    },
    {
        'name': 'mat_sharp:lock_person'
    },
    {
        'name': 'mat_sharp:lock_reset'
    },
    {
        'name': 'mat_sharp:login'
    },
    {
        'name': 'mat_sharp:logout'
    },
    {
        'name': 'mat_sharp:logo_dev'
    },
    {
        'name': 'mat_sharp:looks'
    },
    {
        'name': 'mat_sharp:looks_3'
    },
    {
        'name': 'mat_sharp:looks_4'
    },
    {
        'name': 'mat_sharp:looks_5'
    },
    {
        'name': 'mat_sharp:looks_6'
    },
    {
        'name': 'mat_sharp:looks_one'
    },
    {
        'name': 'mat_sharp:looks_two'
    },
    {
        'name': 'mat_sharp:loop'
    },
    {
        'name': 'mat_sharp:loupe'
    },
    {
        'name': 'mat_sharp:low_priority'
    },
    {
        'name': 'mat_sharp:loyalty'
    },
    {
        'name': 'mat_sharp:lte_mobiledata'
    },
    {
        'name': 'mat_sharp:lte_plus_mobiledata'
    },
    {
        'name': 'mat_sharp:luggage'
    },
    {
        'name': 'mat_sharp:lunch_dining'
    },
    {
        'name': 'mat_sharp:lyrics'
    },
    {
        'name': 'mat_sharp:macro_off'
    },
    {
        'name': 'mat_sharp:mail'
    },
    {
        'name': 'mat_sharp:mail_lock'
    },
    {
        'name': 'mat_sharp:mail_outline'
    },
    {
        'name': 'mat_sharp:male'
    },
    {
        'name': 'mat_sharp:man'
    },
    {
        'name': 'mat_sharp:manage_accounts'
    },
    {
        'name': 'mat_sharp:manage_history'
    },
    {
        'name': 'mat_sharp:manage_search'
    },
    {
        'name': 'mat_sharp:man_2'
    },
    {
        'name': 'mat_sharp:man_3'
    },
    {
        'name': 'mat_sharp:man_4'
    },
    {
        'name': 'mat_sharp:map'
    },
    {
        'name': 'mat_sharp:maps_home_work'
    },
    {
        'name': 'mat_sharp:maps_ugc'
    },
    {
        'name': 'mat_sharp:margin'
    },
    {
        'name': 'mat_sharp:markunread'
    },
    {
        'name': 'mat_sharp:markunread_mailbox'
    },
    {
        'name': 'mat_sharp:mark_as_unread'
    },
    {
        'name': 'mat_sharp:mark_chat_read'
    },
    {
        'name': 'mat_sharp:mark_chat_unread'
    },
    {
        'name': 'mat_sharp:mark_email_read'
    },
    {
        'name': 'mat_sharp:mark_email_unread'
    },
    {
        'name': 'mat_sharp:mark_unread_chat_alt'
    },
    {
        'name': 'mat_sharp:masks'
    },
    {
        'name': 'mat_sharp:maximize'
    },
    {
        'name': 'mat_sharp:mediation'
    },
    {
        'name': 'mat_sharp:media_bluetooth_off'
    },
    {
        'name': 'mat_sharp:media_bluetooth_on'
    },
    {
        'name': 'mat_sharp:medical_information'
    },
    {
        'name': 'mat_sharp:medical_services'
    },
    {
        'name': 'mat_sharp:medication'
    },
    {
        'name': 'mat_sharp:medication_liquid'
    },
    {
        'name': 'mat_sharp:meeting_room'
    },
    {
        'name': 'mat_sharp:memory'
    },
    {
        'name': 'mat_sharp:menu'
    },
    {
        'name': 'mat_sharp:menu_book'
    },
    {
        'name': 'mat_sharp:menu_open'
    },
    {
        'name': 'mat_sharp:merge'
    },
    {
        'name': 'mat_sharp:merge_type'
    },
    {
        'name': 'mat_sharp:message'
    },
    {
        'name': 'mat_sharp:mic'
    },
    {
        'name': 'mat_sharp:microwave'
    },
    {
        'name': 'mat_sharp:mic_external_off'
    },
    {
        'name': 'mat_sharp:mic_external_on'
    },
    {
        'name': 'mat_sharp:mic_none'
    },
    {
        'name': 'mat_sharp:mic_off'
    },
    {
        'name': 'mat_sharp:military_tech'
    },
    {
        'name': 'mat_sharp:minimize'
    },
    {
        'name': 'mat_sharp:minor_crash'
    },
    {
        'name': 'mat_sharp:miscellaneous_services'
    },
    {
        'name': 'mat_sharp:missed_video_call'
    },
    {
        'name': 'mat_sharp:mms'
    },
    {
        'name': 'mat_sharp:mobiledata_off'
    },
    {
        'name': 'mat_sharp:mobile_friendly'
    },
    {
        'name': 'mat_sharp:mobile_off'
    },
    {
        'name': 'mat_sharp:mobile_screen_share'
    },
    {
        'name': 'mat_sharp:mode'
    },
    {
        'name': 'mat_sharp:model_training'
    },
    {
        'name': 'mat_sharp:mode_comment'
    },
    {
        'name': 'mat_sharp:mode_edit'
    },
    {
        'name': 'mat_sharp:mode_edit_outline'
    },
    {
        'name': 'mat_sharp:mode_fan_off'
    },
    {
        'name': 'mat_sharp:mode_night'
    },
    {
        'name': 'mat_sharp:mode_of_travel'
    },
    {
        'name': 'mat_sharp:mode_standby'
    },
    {
        'name': 'mat_sharp:monetization_on'
    },
    {
        'name': 'mat_sharp:money'
    },
    {
        'name': 'mat_sharp:money_off'
    },
    {
        'name': 'mat_sharp:money_off_csred'
    },
    {
        'name': 'mat_sharp:monitor'
    },
    {
        'name': 'mat_sharp:monitor_heart'
    },
    {
        'name': 'mat_sharp:monitor_weight'
    },
    {
        'name': 'mat_sharp:monochrome_photos'
    },
    {
        'name': 'mat_sharp:mood'
    },
    {
        'name': 'mat_sharp:mood_bad'
    },
    {
        'name': 'mat_sharp:moped'
    },
    {
        'name': 'mat_sharp:more'
    },
    {
        'name': 'mat_sharp:more_horiz'
    },
    {
        'name': 'mat_sharp:more_time'
    },
    {
        'name': 'mat_sharp:more_vert'
    },
    {
        'name': 'mat_sharp:mosque'
    },
    {
        'name': 'mat_sharp:motion_photos_auto'
    },
    {
        'name': 'mat_sharp:motion_photos_off'
    },
    {
        'name': 'mat_sharp:motion_photos_on'
    },
    {
        'name': 'mat_sharp:motion_photos_pause'
    },
    {
        'name': 'mat_sharp:motion_photos_paused'
    },
    {
        'name': 'mat_sharp:mouse'
    },
    {
        'name': 'mat_sharp:move_down'
    },
    {
        'name': 'mat_sharp:move_to_inbox'
    },
    {
        'name': 'mat_sharp:move_up'
    },
    {
        'name': 'mat_sharp:movie'
    },
    {
        'name': 'mat_sharp:movie_creation'
    },
    {
        'name': 'mat_sharp:movie_filter'
    },
    {
        'name': 'mat_sharp:moving'
    },
    {
        'name': 'mat_sharp:mp'
    },
    {
        'name': 'mat_sharp:multiline_chart'
    },
    {
        'name': 'mat_sharp:multiple_stop'
    },
    {
        'name': 'mat_sharp:museum'
    },
    {
        'name': 'mat_sharp:music_note'
    },
    {
        'name': 'mat_sharp:music_off'
    },
    {
        'name': 'mat_sharp:music_video'
    },
    {
        'name': 'mat_sharp:my_location'
    },
    {
        'name': 'mat_sharp:nat'
    },
    {
        'name': 'mat_sharp:nature'
    },
    {
        'name': 'mat_sharp:nature_people'
    },
    {
        'name': 'mat_sharp:navigate_before'
    },
    {
        'name': 'mat_sharp:navigate_next'
    },
    {
        'name': 'mat_sharp:navigation'
    },
    {
        'name': 'mat_sharp:nearby_error'
    },
    {
        'name': 'mat_sharp:nearby_off'
    },
    {
        'name': 'mat_sharp:near_me'
    },
    {
        'name': 'mat_sharp:near_me_disabled'
    },
    {
        'name': 'mat_sharp:nest_cam_wired_stand'
    },
    {
        'name': 'mat_sharp:network_cell'
    },
    {
        'name': 'mat_sharp:network_check'
    },
    {
        'name': 'mat_sharp:network_locked'
    },
    {
        'name': 'mat_sharp:network_ping'
    },
    {
        'name': 'mat_sharp:network_wifi'
    },
    {
        'name': 'mat_sharp:network_wifi_1_bar'
    },
    {
        'name': 'mat_sharp:network_wifi_2_bar'
    },
    {
        'name': 'mat_sharp:network_wifi_3_bar'
    },
    {
        'name': 'mat_sharp:newspaper'
    },
    {
        'name': 'mat_sharp:new_label'
    },
    {
        'name': 'mat_sharp:new_releases'
    },
    {
        'name': 'mat_sharp:next_plan'
    },
    {
        'name': 'mat_sharp:next_week'
    },
    {
        'name': 'mat_sharp:nfc'
    },
    {
        'name': 'mat_sharp:nightlife'
    },
    {
        'name': 'mat_sharp:nightlight'
    },
    {
        'name': 'mat_sharp:nightlight_round'
    },
    {
        'name': 'mat_sharp:nights_stay'
    },
    {
        'name': 'mat_sharp:night_shelter'
    },
    {
        'name': 'mat_sharp:noise_aware'
    },
    {
        'name': 'mat_sharp:noise_control_off'
    },
    {
        'name': 'mat_sharp:nordic_walking'
    },
    {
        'name': 'mat_sharp:north'
    },
    {
        'name': 'mat_sharp:north_east'
    },
    {
        'name': 'mat_sharp:north_west'
    },
    {
        'name': 'mat_sharp:note'
    },
    {
        'name': 'mat_sharp:notes'
    },
    {
        'name': 'mat_sharp:note_add'
    },
    {
        'name': 'mat_sharp:note_alt'
    },
    {
        'name': 'mat_sharp:notifications'
    },
    {
        'name': 'mat_sharp:notifications_active'
    },
    {
        'name': 'mat_sharp:notifications_none'
    },
    {
        'name': 'mat_sharp:notifications_off'
    },
    {
        'name': 'mat_sharp:notifications_paused'
    },
    {
        'name': 'mat_sharp:notification_add'
    },
    {
        'name': 'mat_sharp:notification_important'
    },
    {
        'name': 'mat_sharp:not_accessible'
    },
    {
        'name': 'mat_sharp:not_interested'
    },
    {
        'name': 'mat_sharp:not_listed_location'
    },
    {
        'name': 'mat_sharp:not_started'
    },
    {
        'name': 'mat_sharp:no_accounts'
    },
    {
        'name': 'mat_sharp:no_adult_content'
    },
    {
        'name': 'mat_sharp:no_backpack'
    },
    {
        'name': 'mat_sharp:no_cell'
    },
    {
        'name': 'mat_sharp:no_crash'
    },
    {
        'name': 'mat_sharp:no_drinks'
    },
    {
        'name': 'mat_sharp:no_encryption'
    },
    {
        'name': 'mat_sharp:no_encryption_gmailerrorred'
    },
    {
        'name': 'mat_sharp:no_flash'
    },
    {
        'name': 'mat_sharp:no_food'
    },
    {
        'name': 'mat_sharp:no_luggage'
    },
    {
        'name': 'mat_sharp:no_meals'
    },
    {
        'name': 'mat_sharp:no_meeting_room'
    },
    {
        'name': 'mat_sharp:no_photography'
    },
    {
        'name': 'mat_sharp:no_sim'
    },
    {
        'name': 'mat_sharp:no_stroller'
    },
    {
        'name': 'mat_sharp:no_transfer'
    },
    {
        'name': 'mat_sharp:numbers'
    },
    {
        'name': 'mat_sharp:offline_bolt'
    },
    {
        'name': 'mat_sharp:offline_pin'
    },
    {
        'name': 'mat_sharp:offline_share'
    },
    {
        'name': 'mat_sharp:oil_barrel'
    },
    {
        'name': 'mat_sharp:ondemand_video'
    },
    {
        'name': 'mat_sharp:online_prediction'
    },
    {
        'name': 'mat_sharp:on_device_training'
    },
    {
        'name': 'mat_sharp:opacity'
    },
    {
        'name': 'mat_sharp:open_in_browser'
    },
    {
        'name': 'mat_sharp:open_in_full'
    },
    {
        'name': 'mat_sharp:open_in_new'
    },
    {
        'name': 'mat_sharp:open_in_new_off'
    },
    {
        'name': 'mat_sharp:open_with'
    },
    {
        'name': 'mat_sharp:other_houses'
    },
    {
        'name': 'mat_sharp:outbound'
    },
    {
        'name': 'mat_sharp:outbox'
    },
    {
        'name': 'mat_sharp:outdoor_grill'
    },
    {
        'name': 'mat_sharp:outlet'
    },
    {
        'name': 'mat_sharp:outlined_flag'
    },
    {
        'name': 'mat_sharp:output'
    },
    {
        'name': 'mat_sharp:padding'
    },
    {
        'name': 'mat_sharp:pages'
    },
    {
        'name': 'mat_sharp:pageview'
    },
    {
        'name': 'mat_sharp:paid'
    },
    {
        'name': 'mat_sharp:palette'
    },
    {
        'name': 'mat_sharp:panorama'
    },
    {
        'name': 'mat_sharp:panorama_fish_eye'
    },
    {
        'name': 'mat_sharp:panorama_horizontal'
    },
    {
        'name': 'mat_sharp:panorama_horizontal_select'
    },
    {
        'name': 'mat_sharp:panorama_photosphere'
    },
    {
        'name': 'mat_sharp:panorama_photosphere_select'
    },
    {
        'name': 'mat_sharp:panorama_vertical'
    },
    {
        'name': 'mat_sharp:panorama_vertical_select'
    },
    {
        'name': 'mat_sharp:panorama_wide_angle'
    },
    {
        'name': 'mat_sharp:panorama_wide_angle_select'
    },
    {
        'name': 'mat_sharp:pan_tool'
    },
    {
        'name': 'mat_sharp:pan_tool_alt'
    },
    {
        'name': 'mat_sharp:paragliding'
    },
    {
        'name': 'mat_sharp:park'
    },
    {
        'name': 'mat_sharp:party_mode'
    },
    {
        'name': 'mat_sharp:password'
    },
    {
        'name': 'mat_sharp:pattern'
    },
    {
        'name': 'mat_sharp:pause'
    },
    {
        'name': 'mat_sharp:pause_circle'
    },
    {
        'name': 'mat_sharp:pause_circle_filled'
    },
    {
        'name': 'mat_sharp:pause_circle_outline'
    },
    {
        'name': 'mat_sharp:pause_presentation'
    },
    {
        'name': 'mat_sharp:payment'
    },
    {
        'name': 'mat_sharp:payments'
    },
    {
        'name': 'mat_sharp:pedal_bike'
    },
    {
        'name': 'mat_sharp:pending'
    },
    {
        'name': 'mat_sharp:pending_actions'
    },
    {
        'name': 'mat_sharp:pentagon'
    },
    {
        'name': 'mat_sharp:people'
    },
    {
        'name': 'mat_sharp:people_alt'
    },
    {
        'name': 'mat_sharp:people_outline'
    },
    {
        'name': 'mat_sharp:percent'
    },
    {
        'name': 'mat_sharp:perm_camera_mic'
    },
    {
        'name': 'mat_sharp:perm_contact_calendar'
    },
    {
        'name': 'mat_sharp:perm_data_setting'
    },
    {
        'name': 'mat_sharp:perm_device_information'
    },
    {
        'name': 'mat_sharp:perm_identity'
    },
    {
        'name': 'mat_sharp:perm_media'
    },
    {
        'name': 'mat_sharp:perm_phone_msg'
    },
    {
        'name': 'mat_sharp:perm_scan_wifi'
    },
    {
        'name': 'mat_sharp:person'
    },
    {
        'name': 'mat_sharp:personal_injury'
    },
    {
        'name': 'mat_sharp:personal_video'
    },
    {
        'name': 'mat_sharp:person_2'
    },
    {
        'name': 'mat_sharp:person_3'
    },
    {
        'name': 'mat_sharp:person_4'
    },
    {
        'name': 'mat_sharp:person_add'
    },
    {
        'name': 'mat_sharp:person_add_alt'
    },
    {
        'name': 'mat_sharp:person_add_alt_1'
    },
    {
        'name': 'mat_sharp:person_add_disabled'
    },
    {
        'name': 'mat_sharp:person_off'
    },
    {
        'name': 'mat_sharp:person_outline'
    },
    {
        'name': 'mat_sharp:person_pin'
    },
    {
        'name': 'mat_sharp:person_pin_circle'
    },
    {
        'name': 'mat_sharp:person_remove'
    },
    {
        'name': 'mat_sharp:person_remove_alt_1'
    },
    {
        'name': 'mat_sharp:person_search'
    },
    {
        'name': 'mat_sharp:pest_control'
    },
    {
        'name': 'mat_sharp:pest_control_rodent'
    },
    {
        'name': 'mat_sharp:pets'
    },
    {
        'name': 'mat_sharp:phishing'
    },
    {
        'name': 'mat_sharp:phone'
    },
    {
        'name': 'mat_sharp:phonelink'
    },
    {
        'name': 'mat_sharp:phonelink_erase'
    },
    {
        'name': 'mat_sharp:phonelink_lock'
    },
    {
        'name': 'mat_sharp:phonelink_off'
    },
    {
        'name': 'mat_sharp:phonelink_ring'
    },
    {
        'name': 'mat_sharp:phonelink_setup'
    },
    {
        'name': 'mat_sharp:phone_android'
    },
    {
        'name': 'mat_sharp:phone_bluetooth_speaker'
    },
    {
        'name': 'mat_sharp:phone_callback'
    },
    {
        'name': 'mat_sharp:phone_disabled'
    },
    {
        'name': 'mat_sharp:phone_enabled'
    },
    {
        'name': 'mat_sharp:phone_forwarded'
    },
    {
        'name': 'mat_sharp:phone_iphone'
    },
    {
        'name': 'mat_sharp:phone_locked'
    },
    {
        'name': 'mat_sharp:phone_missed'
    },
    {
        'name': 'mat_sharp:phone_paused'
    },
    {
        'name': 'mat_sharp:photo'
    },
    {
        'name': 'mat_sharp:photo_album'
    },
    {
        'name': 'mat_sharp:photo_camera'
    },
    {
        'name': 'mat_sharp:photo_camera_back'
    },
    {
        'name': 'mat_sharp:photo_camera_front'
    },
    {
        'name': 'mat_sharp:photo_filter'
    },
    {
        'name': 'mat_sharp:photo_library'
    },
    {
        'name': 'mat_sharp:photo_size_select_actual'
    },
    {
        'name': 'mat_sharp:photo_size_select_large'
    },
    {
        'name': 'mat_sharp:photo_size_select_small'
    },
    {
        'name': 'mat_sharp:php'
    },
    {
        'name': 'mat_sharp:piano'
    },
    {
        'name': 'mat_sharp:piano_off'
    },
    {
        'name': 'mat_sharp:picture_as_pdf'
    },
    {
        'name': 'mat_sharp:picture_in_picture'
    },
    {
        'name': 'mat_sharp:picture_in_picture_alt'
    },
    {
        'name': 'mat_sharp:pie_chart'
    },
    {
        'name': 'mat_sharp:pie_chart_outline'
    },
    {
        'name': 'mat_sharp:pin'
    },
    {
        'name': 'mat_sharp:pinch'
    },
    {
        'name': 'mat_sharp:pin_drop'
    },
    {
        'name': 'mat_sharp:pin_end'
    },
    {
        'name': 'mat_sharp:pin_invoke'
    },
    {
        'name': 'mat_sharp:pivot_table_chart'
    },
    {
        'name': 'mat_sharp:pix'
    },
    {
        'name': 'mat_sharp:place'
    },
    {
        'name': 'mat_sharp:plagiarism'
    },
    {
        'name': 'mat_sharp:playlist_add'
    },
    {
        'name': 'mat_sharp:playlist_add_check'
    },
    {
        'name': 'mat_sharp:playlist_add_check_circle'
    },
    {
        'name': 'mat_sharp:playlist_add_circle'
    },
    {
        'name': 'mat_sharp:playlist_play'
    },
    {
        'name': 'mat_sharp:playlist_remove'
    },
    {
        'name': 'mat_sharp:play_arrow'
    },
    {
        'name': 'mat_sharp:play_circle'
    },
    {
        'name': 'mat_sharp:play_circle_filled'
    },
    {
        'name': 'mat_sharp:play_circle_outline'
    },
    {
        'name': 'mat_sharp:play_disabled'
    },
    {
        'name': 'mat_sharp:play_for_work'
    },
    {
        'name': 'mat_sharp:play_lesson'
    },
    {
        'name': 'mat_sharp:plumbing'
    },
    {
        'name': 'mat_sharp:plus_one'
    },
    {
        'name': 'mat_sharp:podcasts'
    },
    {
        'name': 'mat_sharp:point_of_sale'
    },
    {
        'name': 'mat_sharp:policy'
    },
    {
        'name': 'mat_sharp:poll'
    },
    {
        'name': 'mat_sharp:polyline'
    },
    {
        'name': 'mat_sharp:polymer'
    },
    {
        'name': 'mat_sharp:pool'
    },
    {
        'name': 'mat_sharp:portable_wifi_off'
    },
    {
        'name': 'mat_sharp:portrait'
    },
    {
        'name': 'mat_sharp:post_add'
    },
    {
        'name': 'mat_sharp:power'
    },
    {
        'name': 'mat_sharp:power_input'
    },
    {
        'name': 'mat_sharp:power_off'
    },
    {
        'name': 'mat_sharp:power_settings_new'
    },
    {
        'name': 'mat_sharp:precision_manufacturing'
    },
    {
        'name': 'mat_sharp:pregnant_woman'
    },
    {
        'name': 'mat_sharp:present_to_all'
    },
    {
        'name': 'mat_sharp:preview'
    },
    {
        'name': 'mat_sharp:price_change'
    },
    {
        'name': 'mat_sharp:price_check'
    },
    {
        'name': 'mat_sharp:print'
    },
    {
        'name': 'mat_sharp:print_disabled'
    },
    {
        'name': 'mat_sharp:priority_high'
    },
    {
        'name': 'mat_sharp:privacy_tip'
    },
    {
        'name': 'mat_sharp:private_connectivity'
    },
    {
        'name': 'mat_sharp:production_quantity_limits'
    },
    {
        'name': 'mat_sharp:propane'
    },
    {
        'name': 'mat_sharp:propane_tank'
    },
    {
        'name': 'mat_sharp:psychology'
    },
    {
        'name': 'mat_sharp:psychology_alt'
    },
    {
        'name': 'mat_sharp:public'
    },
    {
        'name': 'mat_sharp:public_off'
    },
    {
        'name': 'mat_sharp:publish'
    },
    {
        'name': 'mat_sharp:published_with_changes'
    },
    {
        'name': 'mat_sharp:punch_clock'
    },
    {
        'name': 'mat_sharp:push_pin'
    },
    {
        'name': 'mat_sharp:qr_code'
    },
    {
        'name': 'mat_sharp:qr_code_2'
    },
    {
        'name': 'mat_sharp:qr_code_scanner'
    },
    {
        'name': 'mat_sharp:query_builder'
    },
    {
        'name': 'mat_sharp:query_stats'
    },
    {
        'name': 'mat_sharp:question_answer'
    },
    {
        'name': 'mat_sharp:question_mark'
    },
    {
        'name': 'mat_sharp:queue'
    },
    {
        'name': 'mat_sharp:queue_music'
    },
    {
        'name': 'mat_sharp:queue_play_next'
    },
    {
        'name': 'mat_sharp:quickreply'
    },
    {
        'name': 'mat_sharp:quiz'
    },
    {
        'name': 'mat_sharp:radar'
    },
    {
        'name': 'mat_sharp:radio'
    },
    {
        'name': 'mat_sharp:radio_button_checked'
    },
    {
        'name': 'mat_sharp:radio_button_unchecked'
    },
    {
        'name': 'mat_sharp:railway_alert'
    },
    {
        'name': 'mat_sharp:ramen_dining'
    },
    {
        'name': 'mat_sharp:ramp_left'
    },
    {
        'name': 'mat_sharp:ramp_right'
    },
    {
        'name': 'mat_sharp:rate_review'
    },
    {
        'name': 'mat_sharp:raw_off'
    },
    {
        'name': 'mat_sharp:raw_on'
    },
    {
        'name': 'mat_sharp:read_more'
    },
    {
        'name': 'mat_sharp:real_estate_agent'
    },
    {
        'name': 'mat_sharp:receipt'
    },
    {
        'name': 'mat_sharp:receipt_long'
    },
    {
        'name': 'mat_sharp:recent_actors'
    },
    {
        'name': 'mat_sharp:recommend'
    },
    {
        'name': 'mat_sharp:record_voice_over'
    },
    {
        'name': 'mat_sharp:rectangle'
    },
    {
        'name': 'mat_sharp:recycling'
    },
    {
        'name': 'mat_sharp:redeem'
    },
    {
        'name': 'mat_sharp:redo'
    },
    {
        'name': 'mat_sharp:reduce_capacity'
    },
    {
        'name': 'mat_sharp:refresh'
    },
    {
        'name': 'mat_sharp:remember_me'
    },
    {
        'name': 'mat_sharp:remove'
    },
    {
        'name': 'mat_sharp:remove_circle'
    },
    {
        'name': 'mat_sharp:remove_circle_outline'
    },
    {
        'name': 'mat_sharp:remove_done'
    },
    {
        'name': 'mat_sharp:remove_from_queue'
    },
    {
        'name': 'mat_sharp:remove_moderator'
    },
    {
        'name': 'mat_sharp:remove_red_eye'
    },
    {
        'name': 'mat_sharp:remove_road'
    },
    {
        'name': 'mat_sharp:remove_shopping_cart'
    },
    {
        'name': 'mat_sharp:reorder'
    },
    {
        'name': 'mat_sharp:repartition'
    },
    {
        'name': 'mat_sharp:repeat'
    },
    {
        'name': 'mat_sharp:repeat_on'
    },
    {
        'name': 'mat_sharp:repeat_one'
    },
    {
        'name': 'mat_sharp:repeat_one_on'
    },
    {
        'name': 'mat_sharp:replay'
    },
    {
        'name': 'mat_sharp:replay_10'
    },
    {
        'name': 'mat_sharp:replay_30'
    },
    {
        'name': 'mat_sharp:replay_5'
    },
    {
        'name': 'mat_sharp:replay_circle_filled'
    },
    {
        'name': 'mat_sharp:reply'
    },
    {
        'name': 'mat_sharp:reply_all'
    },
    {
        'name': 'mat_sharp:report'
    },
    {
        'name': 'mat_sharp:report_gmailerrorred'
    },
    {
        'name': 'mat_sharp:report_off'
    },
    {
        'name': 'mat_sharp:report_problem'
    },
    {
        'name': 'mat_sharp:request_page'
    },
    {
        'name': 'mat_sharp:request_quote'
    },
    {
        'name': 'mat_sharp:reset_tv'
    },
    {
        'name': 'mat_sharp:restart_alt'
    },
    {
        'name': 'mat_sharp:restaurant'
    },
    {
        'name': 'mat_sharp:restaurant_menu'
    },
    {
        'name': 'mat_sharp:restore'
    },
    {
        'name': 'mat_sharp:restore_from_trash'
    },
    {
        'name': 'mat_sharp:restore_page'
    },
    {
        'name': 'mat_sharp:reviews'
    },
    {
        'name': 'mat_sharp:rice_bowl'
    },
    {
        'name': 'mat_sharp:ring_volume'
    },
    {
        'name': 'mat_sharp:rocket'
    },
    {
        'name': 'mat_sharp:rocket_launch'
    },
    {
        'name': 'mat_sharp:roller_shades'
    },
    {
        'name': 'mat_sharp:roller_shades_closed'
    },
    {
        'name': 'mat_sharp:roller_skating'
    },
    {
        'name': 'mat_sharp:roofing'
    },
    {
        'name': 'mat_sharp:room'
    },
    {
        'name': 'mat_sharp:room_preferences'
    },
    {
        'name': 'mat_sharp:room_service'
    },
    {
        'name': 'mat_sharp:rotate_90_degrees_ccw'
    },
    {
        'name': 'mat_sharp:rotate_90_degrees_cw'
    },
    {
        'name': 'mat_sharp:rotate_left'
    },
    {
        'name': 'mat_sharp:rotate_right'
    },
    {
        'name': 'mat_sharp:roundabout_left'
    },
    {
        'name': 'mat_sharp:roundabout_right'
    },
    {
        'name': 'mat_sharp:rounded_corner'
    },
    {
        'name': 'mat_sharp:route'
    },
    {
        'name': 'mat_sharp:router'
    },
    {
        'name': 'mat_sharp:rowing'
    },
    {
        'name': 'mat_sharp:rss_feed'
    },
    {
        'name': 'mat_sharp:rsvp'
    },
    {
        'name': 'mat_sharp:rtt'
    },
    {
        'name': 'mat_sharp:rule'
    },
    {
        'name': 'mat_sharp:rule_folder'
    },
    {
        'name': 'mat_sharp:running_with_errors'
    },
    {
        'name': 'mat_sharp:run_circle'
    },
    {
        'name': 'mat_sharp:rv_hookup'
    },
    {
        'name': 'mat_sharp:r_mobiledata'
    },
    {
        'name': 'mat_sharp:safety_check'
    },
    {
        'name': 'mat_sharp:safety_divider'
    },
    {
        'name': 'mat_sharp:sailing'
    },
    {
        'name': 'mat_sharp:sanitizer'
    },
    {
        'name': 'mat_sharp:satellite'
    },
    {
        'name': 'mat_sharp:satellite_alt'
    },
    {
        'name': 'mat_sharp:save'
    },
    {
        'name': 'mat_sharp:saved_search'
    },
    {
        'name': 'mat_sharp:save_alt'
    },
    {
        'name': 'mat_sharp:save_as'
    },
    {
        'name': 'mat_sharp:savings'
    },
    {
        'name': 'mat_sharp:scale'
    },
    {
        'name': 'mat_sharp:scanner'
    },
    {
        'name': 'mat_sharp:scatter_plot'
    },
    {
        'name': 'mat_sharp:schedule'
    },
    {
        'name': 'mat_sharp:schedule_send'
    },
    {
        'name': 'mat_sharp:schema'
    },
    {
        'name': 'mat_sharp:school'
    },
    {
        'name': 'mat_sharp:science'
    },
    {
        'name': 'mat_sharp:score'
    },
    {
        'name': 'mat_sharp:scoreboard'
    },
    {
        'name': 'mat_sharp:screenshot'
    },
    {
        'name': 'mat_sharp:screenshot_monitor'
    },
    {
        'name': 'mat_sharp:screen_lock_landscape'
    },
    {
        'name': 'mat_sharp:screen_lock_portrait'
    },
    {
        'name': 'mat_sharp:screen_lock_rotation'
    },
    {
        'name': 'mat_sharp:screen_rotation'
    },
    {
        'name': 'mat_sharp:screen_rotation_alt'
    },
    {
        'name': 'mat_sharp:screen_search_desktop'
    },
    {
        'name': 'mat_sharp:screen_share'
    },
    {
        'name': 'mat_sharp:scuba_diving'
    },
    {
        'name': 'mat_sharp:sd'
    },
    {
        'name': 'mat_sharp:sd_card'
    },
    {
        'name': 'mat_sharp:sd_card_alert'
    },
    {
        'name': 'mat_sharp:sd_storage'
    },
    {
        'name': 'mat_sharp:search'
    },
    {
        'name': 'mat_sharp:search_off'
    },
    {
        'name': 'mat_sharp:security'
    },
    {
        'name': 'mat_sharp:security_update'
    },
    {
        'name': 'mat_sharp:security_update_good'
    },
    {
        'name': 'mat_sharp:security_update_warning'
    },
    {
        'name': 'mat_sharp:segment'
    },
    {
        'name': 'mat_sharp:select_all'
    },
    {
        'name': 'mat_sharp:self_improvement'
    },
    {
        'name': 'mat_sharp:sell'
    },
    {
        'name': 'mat_sharp:send'
    },
    {
        'name': 'mat_sharp:send_and_archive'
    },
    {
        'name': 'mat_sharp:send_time_extension'
    },
    {
        'name': 'mat_sharp:send_to_mobile'
    },
    {
        'name': 'mat_sharp:sensors'
    },
    {
        'name': 'mat_sharp:sensors_off'
    },
    {
        'name': 'mat_sharp:sensor_door'
    },
    {
        'name': 'mat_sharp:sensor_occupied'
    },
    {
        'name': 'mat_sharp:sensor_window'
    },
    {
        'name': 'mat_sharp:sentiment_dissatisfied'
    },
    {
        'name': 'mat_sharp:sentiment_neutral'
    },
    {
        'name': 'mat_sharp:sentiment_satisfied'
    },
    {
        'name': 'mat_sharp:sentiment_satisfied_alt'
    },
    {
        'name': 'mat_sharp:sentiment_very_dissatisfied'
    },
    {
        'name': 'mat_sharp:sentiment_very_satisfied'
    },
    {
        'name': 'mat_sharp:settings'
    },
    {
        'name': 'mat_sharp:settings_accessibility'
    },
    {
        'name': 'mat_sharp:settings_applications'
    },
    {
        'name': 'mat_sharp:settings_backup_restore'
    },
    {
        'name': 'mat_sharp:settings_bluetooth'
    },
    {
        'name': 'mat_sharp:settings_brightness'
    },
    {
        'name': 'mat_sharp:settings_cell'
    },
    {
        'name': 'mat_sharp:settings_ethernet'
    },
    {
        'name': 'mat_sharp:settings_input_antenna'
    },
    {
        'name': 'mat_sharp:settings_input_component'
    },
    {
        'name': 'mat_sharp:settings_input_composite'
    },
    {
        'name': 'mat_sharp:settings_input_hdmi'
    },
    {
        'name': 'mat_sharp:settings_input_svideo'
    },
    {
        'name': 'mat_sharp:settings_overscan'
    },
    {
        'name': 'mat_sharp:settings_phone'
    },
    {
        'name': 'mat_sharp:settings_power'
    },
    {
        'name': 'mat_sharp:settings_remote'
    },
    {
        'name': 'mat_sharp:settings_suggest'
    },
    {
        'name': 'mat_sharp:settings_system_daydream'
    },
    {
        'name': 'mat_sharp:settings_voice'
    },
    {
        'name': 'mat_sharp:set_meal'
    },
    {
        'name': 'mat_sharp:severe_cold'
    },
    {
        'name': 'mat_sharp:shape_line'
    },
    {
        'name': 'mat_sharp:share'
    },
    {
        'name': 'mat_sharp:share_location'
    },
    {
        'name': 'mat_sharp:shield'
    },
    {
        'name': 'mat_sharp:shield_moon'
    },
    {
        'name': 'mat_sharp:shop'
    },
    {
        'name': 'mat_sharp:shopping_bag'
    },
    {
        'name': 'mat_sharp:shopping_basket'
    },
    {
        'name': 'mat_sharp:shopping_cart'
    },
    {
        'name': 'mat_sharp:shopping_cart_checkout'
    },
    {
        'name': 'mat_sharp:shop_2'
    },
    {
        'name': 'mat_sharp:shop_two'
    },
    {
        'name': 'mat_sharp:shortcut'
    },
    {
        'name': 'mat_sharp:short_text'
    },
    {
        'name': 'mat_sharp:shower'
    },
    {
        'name': 'mat_sharp:show_chart'
    },
    {
        'name': 'mat_sharp:shuffle'
    },
    {
        'name': 'mat_sharp:shuffle_on'
    },
    {
        'name': 'mat_sharp:shutter_speed'
    },
    {
        'name': 'mat_sharp:sick'
    },
    {
        'name': 'mat_sharp:signal_cellular_0_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_4_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_alt'
    },
    {
        'name': 'mat_sharp:signal_cellular_alt_1_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_alt_2_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_connected_no_internet_0_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_connected_no_internet_4_bar'
    },
    {
        'name': 'mat_sharp:signal_cellular_nodata'
    },
    {
        'name': 'mat_sharp:signal_cellular_no_sim'
    },
    {
        'name': 'mat_sharp:signal_cellular_null'
    },
    {
        'name': 'mat_sharp:signal_cellular_off'
    },
    {
        'name': 'mat_sharp:signal_wifi_0_bar'
    },
    {
        'name': 'mat_sharp:signal_wifi_4_bar'
    },
    {
        'name': 'mat_sharp:signal_wifi_4_bar_lock'
    },
    {
        'name': 'mat_sharp:signal_wifi_bad'
    },
    {
        'name': 'mat_sharp:signal_wifi_connected_no_internet_4'
    },
    {
        'name': 'mat_sharp:signal_wifi_off'
    },
    {
        'name': 'mat_sharp:signal_wifi_statusbar_4_bar'
    },
    {
        'name': 'mat_sharp:signal_wifi_statusbar_connected_no_internet_4'
    },
    {
        'name': 'mat_sharp:signal_wifi_statusbar_null'
    },
    {
        'name': 'mat_sharp:signpost'
    },
    {
        'name': 'mat_sharp:sign_language'
    },
    {
        'name': 'mat_sharp:sim_card'
    },
    {
        'name': 'mat_sharp:sim_card_alert'
    },
    {
        'name': 'mat_sharp:sim_card_download'
    },
    {
        'name': 'mat_sharp:single_bed'
    },
    {
        'name': 'mat_sharp:sip'
    },
    {
        'name': 'mat_sharp:skateboarding'
    },
    {
        'name': 'mat_sharp:skip_next'
    },
    {
        'name': 'mat_sharp:skip_previous'
    },
    {
        'name': 'mat_sharp:sledding'
    },
    {
        'name': 'mat_sharp:slideshow'
    },
    {
        'name': 'mat_sharp:slow_motion_video'
    },
    {
        'name': 'mat_sharp:smartphone'
    },
    {
        'name': 'mat_sharp:smart_button'
    },
    {
        'name': 'mat_sharp:smart_display'
    },
    {
        'name': 'mat_sharp:smart_screen'
    },
    {
        'name': 'mat_sharp:smart_toy'
    },
    {
        'name': 'mat_sharp:smoke_free'
    },
    {
        'name': 'mat_sharp:smoking_rooms'
    },
    {
        'name': 'mat_sharp:sms'
    },
    {
        'name': 'mat_sharp:sms_failed'
    },
    {
        'name': 'mat_sharp:snippet_folder'
    },
    {
        'name': 'mat_sharp:snooze'
    },
    {
        'name': 'mat_sharp:snowboarding'
    },
    {
        'name': 'mat_sharp:snowmobile'
    },
    {
        'name': 'mat_sharp:snowshoeing'
    },
    {
        'name': 'mat_sharp:soap'
    },
    {
        'name': 'mat_sharp:social_distance'
    },
    {
        'name': 'mat_sharp:solar_power'
    },
    {
        'name': 'mat_sharp:sort'
    },
    {
        'name': 'mat_sharp:sort_by_alpha'
    },
    {
        'name': 'mat_sharp:sos'
    },
    {
        'name': 'mat_sharp:soup_kitchen'
    },
    {
        'name': 'mat_sharp:source'
    },
    {
        'name': 'mat_sharp:south'
    },
    {
        'name': 'mat_sharp:south_america'
    },
    {
        'name': 'mat_sharp:south_east'
    },
    {
        'name': 'mat_sharp:south_west'
    },
    {
        'name': 'mat_sharp:spa'
    },
    {
        'name': 'mat_sharp:space_bar'
    },
    {
        'name': 'mat_sharp:space_dashboard'
    },
    {
        'name': 'mat_sharp:spatial_audio'
    },
    {
        'name': 'mat_sharp:spatial_audio_off'
    },
    {
        'name': 'mat_sharp:spatial_tracking'
    },
    {
        'name': 'mat_sharp:speaker'
    },
    {
        'name': 'mat_sharp:speaker_group'
    },
    {
        'name': 'mat_sharp:speaker_notes'
    },
    {
        'name': 'mat_sharp:speaker_notes_off'
    },
    {
        'name': 'mat_sharp:speaker_phone'
    },
    {
        'name': 'mat_sharp:speed'
    },
    {
        'name': 'mat_sharp:spellcheck'
    },
    {
        'name': 'mat_sharp:splitscreen'
    },
    {
        'name': 'mat_sharp:spoke'
    },
    {
        'name': 'mat_sharp:sports'
    },
    {
        'name': 'mat_sharp:sports_bar'
    },
    {
        'name': 'mat_sharp:sports_baseball'
    },
    {
        'name': 'mat_sharp:sports_basketball'
    },
    {
        'name': 'mat_sharp:sports_cricket'
    },
    {
        'name': 'mat_sharp:sports_esports'
    },
    {
        'name': 'mat_sharp:sports_football'
    },
    {
        'name': 'mat_sharp:sports_golf'
    },
    {
        'name': 'mat_sharp:sports_gymnastics'
    },
    {
        'name': 'mat_sharp:sports_handball'
    },
    {
        'name': 'mat_sharp:sports_hockey'
    },
    {
        'name': 'mat_sharp:sports_kabaddi'
    },
    {
        'name': 'mat_sharp:sports_martial_arts'
    },
    {
        'name': 'mat_sharp:sports_mma'
    },
    {
        'name': 'mat_sharp:sports_motorsports'
    },
    {
        'name': 'mat_sharp:sports_rugby'
    },
    {
        'name': 'mat_sharp:sports_score'
    },
    {
        'name': 'mat_sharp:sports_soccer'
    },
    {
        'name': 'mat_sharp:sports_tennis'
    },
    {
        'name': 'mat_sharp:sports_volleyball'
    },
    {
        'name': 'mat_sharp:square'
    },
    {
        'name': 'mat_sharp:square_foot'
    },
    {
        'name': 'mat_sharp:ssid_chart'
    },
    {
        'name': 'mat_sharp:stacked_bar_chart'
    },
    {
        'name': 'mat_sharp:stacked_line_chart'
    },
    {
        'name': 'mat_sharp:stadium'
    },
    {
        'name': 'mat_sharp:stairs'
    },
    {
        'name': 'mat_sharp:star'
    },
    {
        'name': 'mat_sharp:stars'
    },
    {
        'name': 'mat_sharp:start'
    },
    {
        'name': 'mat_sharp:star_border'
    },
    {
        'name': 'mat_sharp:star_border_purple500'
    },
    {
        'name': 'mat_sharp:star_half'
    },
    {
        'name': 'mat_sharp:star_outline'
    },
    {
        'name': 'mat_sharp:star_purple500'
    },
    {
        'name': 'mat_sharp:star_rate'
    },
    {
        'name': 'mat_sharp:stay_current_landscape'
    },
    {
        'name': 'mat_sharp:stay_current_portrait'
    },
    {
        'name': 'mat_sharp:stay_primary_landscape'
    },
    {
        'name': 'mat_sharp:stay_primary_portrait'
    },
    {
        'name': 'mat_sharp:sticky_note_2'
    },
    {
        'name': 'mat_sharp:stop'
    },
    {
        'name': 'mat_sharp:stop_circle'
    },
    {
        'name': 'mat_sharp:stop_screen_share'
    },
    {
        'name': 'mat_sharp:storage'
    },
    {
        'name': 'mat_sharp:store'
    },
    {
        'name': 'mat_sharp:storefront'
    },
    {
        'name': 'mat_sharp:store_mall_directory'
    },
    {
        'name': 'mat_sharp:storm'
    },
    {
        'name': 'mat_sharp:straight'
    },
    {
        'name': 'mat_sharp:straighten'
    },
    {
        'name': 'mat_sharp:stream'
    },
    {
        'name': 'mat_sharp:streetview'
    },
    {
        'name': 'mat_sharp:strikethrough_s'
    },
    {
        'name': 'mat_sharp:stroller'
    },
    {
        'name': 'mat_sharp:style'
    },
    {
        'name': 'mat_sharp:subdirectory_arrow_left'
    },
    {
        'name': 'mat_sharp:subdirectory_arrow_right'
    },
    {
        'name': 'mat_sharp:subject'
    },
    {
        'name': 'mat_sharp:subscript'
    },
    {
        'name': 'mat_sharp:subscriptions'
    },
    {
        'name': 'mat_sharp:subtitles'
    },
    {
        'name': 'mat_sharp:subtitles_off'
    },
    {
        'name': 'mat_sharp:subway'
    },
    {
        'name': 'mat_sharp:summarize'
    },
    {
        'name': 'mat_sharp:superscript'
    },
    {
        'name': 'mat_sharp:supervised_user_circle'
    },
    {
        'name': 'mat_sharp:supervisor_account'
    },
    {
        'name': 'mat_sharp:support'
    },
    {
        'name': 'mat_sharp:support_agent'
    },
    {
        'name': 'mat_sharp:surfing'
    },
    {
        'name': 'mat_sharp:surround_sound'
    },
    {
        'name': 'mat_sharp:swap_calls'
    },
    {
        'name': 'mat_sharp:swap_horiz'
    },
    {
        'name': 'mat_sharp:swap_horizontal_circle'
    },
    {
        'name': 'mat_sharp:swap_vert'
    },
    {
        'name': 'mat_sharp:swap_vertical_circle'
    },
    {
        'name': 'mat_sharp:swipe'
    },
    {
        'name': 'mat_sharp:swipe_down'
    },
    {
        'name': 'mat_sharp:swipe_down_alt'
    },
    {
        'name': 'mat_sharp:swipe_left'
    },
    {
        'name': 'mat_sharp:swipe_left_alt'
    },
    {
        'name': 'mat_sharp:swipe_right'
    },
    {
        'name': 'mat_sharp:swipe_right_alt'
    },
    {
        'name': 'mat_sharp:swipe_up'
    },
    {
        'name': 'mat_sharp:swipe_up_alt'
    },
    {
        'name': 'mat_sharp:swipe_vertical'
    },
    {
        'name': 'mat_sharp:switch_access_shortcut'
    },
    {
        'name': 'mat_sharp:switch_access_shortcut_add'
    },
    {
        'name': 'mat_sharp:switch_account'
    },
    {
        'name': 'mat_sharp:switch_camera'
    },
    {
        'name': 'mat_sharp:switch_left'
    },
    {
        'name': 'mat_sharp:switch_right'
    },
    {
        'name': 'mat_sharp:switch_video'
    },
    {
        'name': 'mat_sharp:synagogue'
    },
    {
        'name': 'mat_sharp:sync'
    },
    {
        'name': 'mat_sharp:sync_alt'
    },
    {
        'name': 'mat_sharp:sync_disabled'
    },
    {
        'name': 'mat_sharp:sync_lock'
    },
    {
        'name': 'mat_sharp:sync_problem'
    },
    {
        'name': 'mat_sharp:system_security_update'
    },
    {
        'name': 'mat_sharp:system_security_update_good'
    },
    {
        'name': 'mat_sharp:system_security_update_warning'
    },
    {
        'name': 'mat_sharp:system_update'
    },
    {
        'name': 'mat_sharp:system_update_alt'
    },
    {
        'name': 'mat_sharp:tab'
    },
    {
        'name': 'mat_sharp:tablet'
    },
    {
        'name': 'mat_sharp:tablet_android'
    },
    {
        'name': 'mat_sharp:tablet_mac'
    },
    {
        'name': 'mat_sharp:table_bar'
    },
    {
        'name': 'mat_sharp:table_chart'
    },
    {
        'name': 'mat_sharp:table_restaurant'
    },
    {
        'name': 'mat_sharp:table_rows'
    },
    {
        'name': 'mat_sharp:table_view'
    },
    {
        'name': 'mat_sharp:tab_unselected'
    },
    {
        'name': 'mat_sharp:tag'
    },
    {
        'name': 'mat_sharp:tag_faces'
    },
    {
        'name': 'mat_sharp:takeout_dining'
    },
    {
        'name': 'mat_sharp:tapas'
    },
    {
        'name': 'mat_sharp:tap_and_play'
    },
    {
        'name': 'mat_sharp:task'
    },
    {
        'name': 'mat_sharp:task_alt'
    },
    {
        'name': 'mat_sharp:taxi_alert'
    },
    {
        'name': 'mat_sharp:temple_buddhist'
    },
    {
        'name': 'mat_sharp:temple_hindu'
    },
    {
        'name': 'mat_sharp:terminal'
    },
    {
        'name': 'mat_sharp:terrain'
    },
    {
        'name': 'mat_sharp:textsms'
    },
    {
        'name': 'mat_sharp:texture'
    },
    {
        'name': 'mat_sharp:text_decrease'
    },
    {
        'name': 'mat_sharp:text_fields'
    },
    {
        'name': 'mat_sharp:text_format'
    },
    {
        'name': 'mat_sharp:text_increase'
    },
    {
        'name': 'mat_sharp:text_rotate_up'
    },
    {
        'name': 'mat_sharp:text_rotate_vertical'
    },
    {
        'name': 'mat_sharp:text_rotation_angledown'
    },
    {
        'name': 'mat_sharp:text_rotation_angleup'
    },
    {
        'name': 'mat_sharp:text_rotation_down'
    },
    {
        'name': 'mat_sharp:text_rotation_none'
    },
    {
        'name': 'mat_sharp:text_snippet'
    },
    {
        'name': 'mat_sharp:theaters'
    },
    {
        'name': 'mat_sharp:theater_comedy'
    },
    {
        'name': 'mat_sharp:thermostat'
    },
    {
        'name': 'mat_sharp:thermostat_auto'
    },
    {
        'name': 'mat_sharp:thumbs_up_down'
    },
    {
        'name': 'mat_sharp:thumb_down'
    },
    {
        'name': 'mat_sharp:thumb_down_alt'
    },
    {
        'name': 'mat_sharp:thumb_down_off_alt'
    },
    {
        'name': 'mat_sharp:thumb_up'
    },
    {
        'name': 'mat_sharp:thumb_up_alt'
    },
    {
        'name': 'mat_sharp:thumb_up_off_alt'
    },
    {
        'name': 'mat_sharp:thunderstorm'
    },
    {
        'name': 'mat_sharp:timelapse'
    },
    {
        'name': 'mat_sharp:timeline'
    },
    {
        'name': 'mat_sharp:timer'
    },
    {
        'name': 'mat_sharp:timer_10'
    },
    {
        'name': 'mat_sharp:timer_10_select'
    },
    {
        'name': 'mat_sharp:timer_3'
    },
    {
        'name': 'mat_sharp:timer_3_select'
    },
    {
        'name': 'mat_sharp:timer_off'
    },
    {
        'name': 'mat_sharp:time_to_leave'
    },
    {
        'name': 'mat_sharp:tips_and_updates'
    },
    {
        'name': 'mat_sharp:tire_repair'
    },
    {
        'name': 'mat_sharp:title'
    },
    {
        'name': 'mat_sharp:toc'
    },
    {
        'name': 'mat_sharp:today'
    },
    {
        'name': 'mat_sharp:toggle_off'
    },
    {
        'name': 'mat_sharp:toggle_on'
    },
    {
        'name': 'mat_sharp:token'
    },
    {
        'name': 'mat_sharp:toll'
    },
    {
        'name': 'mat_sharp:tonality'
    },
    {
        'name': 'mat_sharp:topic'
    },
    {
        'name': 'mat_sharp:tornado'
    },
    {
        'name': 'mat_sharp:touch_app'
    },
    {
        'name': 'mat_sharp:tour'
    },
    {
        'name': 'mat_sharp:toys'
    },
    {
        'name': 'mat_sharp:track_changes'
    },
    {
        'name': 'mat_sharp:traffic'
    },
    {
        'name': 'mat_sharp:train'
    },
    {
        'name': 'mat_sharp:tram'
    },
    {
        'name': 'mat_sharp:transcribe'
    },
    {
        'name': 'mat_sharp:transfer_within_a_station'
    },
    {
        'name': 'mat_sharp:transform'
    },
    {
        'name': 'mat_sharp:transgender'
    },
    {
        'name': 'mat_sharp:transit_enterexit'
    },
    {
        'name': 'mat_sharp:translate'
    },
    {
        'name': 'mat_sharp:travel_explore'
    },
    {
        'name': 'mat_sharp:trending_down'
    },
    {
        'name': 'mat_sharp:trending_flat'
    },
    {
        'name': 'mat_sharp:trending_up'
    },
    {
        'name': 'mat_sharp:trip_origin'
    },
    {
        'name': 'mat_sharp:troubleshoot'
    },
    {
        'name': 'mat_sharp:try'
    },
    {
        'name': 'mat_sharp:tsunami'
    },
    {
        'name': 'mat_sharp:tty'
    },
    {
        'name': 'mat_sharp:tune'
    },
    {
        'name': 'mat_sharp:tungsten'
    },
    {
        'name': 'mat_sharp:turned_in'
    },
    {
        'name': 'mat_sharp:turned_in_not'
    },
    {
        'name': 'mat_sharp:turn_left'
    },
    {
        'name': 'mat_sharp:turn_right'
    },
    {
        'name': 'mat_sharp:turn_sharp_left'
    },
    {
        'name': 'mat_sharp:turn_sharp_right'
    },
    {
        'name': 'mat_sharp:turn_slight_left'
    },
    {
        'name': 'mat_sharp:turn_slight_right'
    },
    {
        'name': 'mat_sharp:tv'
    },
    {
        'name': 'mat_sharp:tv_off'
    },
    {
        'name': 'mat_sharp:two_wheeler'
    },
    {
        'name': 'mat_sharp:type_specimen'
    },
    {
        'name': 'mat_sharp:umbrella'
    },
    {
        'name': 'mat_sharp:unarchive'
    },
    {
        'name': 'mat_sharp:undo'
    },
    {
        'name': 'mat_sharp:unfold_less'
    },
    {
        'name': 'mat_sharp:unfold_less_double'
    },
    {
        'name': 'mat_sharp:unfold_more'
    },
    {
        'name': 'mat_sharp:unfold_more_double'
    },
    {
        'name': 'mat_sharp:unpublished'
    },
    {
        'name': 'mat_sharp:unsubscribe'
    },
    {
        'name': 'mat_sharp:upcoming'
    },
    {
        'name': 'mat_sharp:update'
    },
    {
        'name': 'mat_sharp:update_disabled'
    },
    {
        'name': 'mat_sharp:upgrade'
    },
    {
        'name': 'mat_sharp:upload'
    },
    {
        'name': 'mat_sharp:upload_file'
    },
    {
        'name': 'mat_sharp:usb'
    },
    {
        'name': 'mat_sharp:usb_off'
    },
    {
        'name': 'mat_sharp:u_turn_left'
    },
    {
        'name': 'mat_sharp:u_turn_right'
    },
    {
        'name': 'mat_sharp:vaccines'
    },
    {
        'name': 'mat_sharp:vape_free'
    },
    {
        'name': 'mat_sharp:vaping_rooms'
    },
    {
        'name': 'mat_sharp:verified'
    },
    {
        'name': 'mat_sharp:verified_user'
    },
    {
        'name': 'mat_sharp:vertical_align_bottom'
    },
    {
        'name': 'mat_sharp:vertical_align_center'
    },
    {
        'name': 'mat_sharp:vertical_align_top'
    },
    {
        'name': 'mat_sharp:vertical_distribute'
    },
    {
        'name': 'mat_sharp:vertical_shades'
    },
    {
        'name': 'mat_sharp:vertical_shades_closed'
    },
    {
        'name': 'mat_sharp:vertical_split'
    },
    {
        'name': 'mat_sharp:vibration'
    },
    {
        'name': 'mat_sharp:videocam'
    },
    {
        'name': 'mat_sharp:videocam_off'
    },
    {
        'name': 'mat_sharp:videogame_asset'
    },
    {
        'name': 'mat_sharp:videogame_asset_off'
    },
    {
        'name': 'mat_sharp:video_call'
    },
    {
        'name': 'mat_sharp:video_camera_back'
    },
    {
        'name': 'mat_sharp:video_camera_front'
    },
    {
        'name': 'mat_sharp:video_chat'
    },
    {
        'name': 'mat_sharp:video_file'
    },
    {
        'name': 'mat_sharp:video_label'
    },
    {
        'name': 'mat_sharp:video_library'
    },
    {
        'name': 'mat_sharp:video_settings'
    },
    {
        'name': 'mat_sharp:video_stable'
    },
    {
        'name': 'mat_sharp:view_agenda'
    },
    {
        'name': 'mat_sharp:view_array'
    },
    {
        'name': 'mat_sharp:view_carousel'
    },
    {
        'name': 'mat_sharp:view_column'
    },
    {
        'name': 'mat_sharp:view_comfy'
    },
    {
        'name': 'mat_sharp:view_comfy_alt'
    },
    {
        'name': 'mat_sharp:view_compact'
    },
    {
        'name': 'mat_sharp:view_compact_alt'
    },
    {
        'name': 'mat_sharp:view_cozy'
    },
    {
        'name': 'mat_sharp:view_day'
    },
    {
        'name': 'mat_sharp:view_headline'
    },
    {
        'name': 'mat_sharp:view_in_ar'
    },
    {
        'name': 'mat_sharp:view_kanban'
    },
    {
        'name': 'mat_sharp:view_list'
    },
    {
        'name': 'mat_sharp:view_module'
    },
    {
        'name': 'mat_sharp:view_quilt'
    },
    {
        'name': 'mat_sharp:view_sidebar'
    },
    {
        'name': 'mat_sharp:view_stream'
    },
    {
        'name': 'mat_sharp:view_timeline'
    },
    {
        'name': 'mat_sharp:view_week'
    },
    {
        'name': 'mat_sharp:vignette'
    },
    {
        'name': 'mat_sharp:villa'
    },
    {
        'name': 'mat_sharp:visibility'
    },
    {
        'name': 'mat_sharp:visibility_off'
    },
    {
        'name': 'mat_sharp:voicemail'
    },
    {
        'name': 'mat_sharp:voice_chat'
    },
    {
        'name': 'mat_sharp:voice_over_off'
    },
    {
        'name': 'mat_sharp:volcano'
    },
    {
        'name': 'mat_sharp:volume_down'
    },
    {
        'name': 'mat_sharp:volume_mute'
    },
    {
        'name': 'mat_sharp:volume_off'
    },
    {
        'name': 'mat_sharp:volume_up'
    },
    {
        'name': 'mat_sharp:volunteer_activism'
    },
    {
        'name': 'mat_sharp:vpn_key'
    },
    {
        'name': 'mat_sharp:vpn_key_off'
    },
    {
        'name': 'mat_sharp:vpn_lock'
    },
    {
        'name': 'mat_sharp:vrpano'
    },
    {
        'name': 'mat_sharp:wallet'
    },
    {
        'name': 'mat_sharp:wallpaper'
    },
    {
        'name': 'mat_sharp:warehouse'
    },
    {
        'name': 'mat_sharp:warning'
    },
    {
        'name': 'mat_sharp:warning_amber'
    },
    {
        'name': 'mat_sharp:wash'
    },
    {
        'name': 'mat_sharp:watch'
    },
    {
        'name': 'mat_sharp:watch_later'
    },
    {
        'name': 'mat_sharp:watch_off'
    },
    {
        'name': 'mat_sharp:water'
    },
    {
        'name': 'mat_sharp:waterfall_chart'
    },
    {
        'name': 'mat_sharp:water_damage'
    },
    {
        'name': 'mat_sharp:water_drop'
    },
    {
        'name': 'mat_sharp:waves'
    },
    {
        'name': 'mat_sharp:waving_hand'
    },
    {
        'name': 'mat_sharp:wb_auto'
    },
    {
        'name': 'mat_sharp:wb_cloudy'
    },
    {
        'name': 'mat_sharp:wb_incandescent'
    },
    {
        'name': 'mat_sharp:wb_iridescent'
    },
    {
        'name': 'mat_sharp:wb_shade'
    },
    {
        'name': 'mat_sharp:wb_sunny'
    },
    {
        'name': 'mat_sharp:wb_twilight'
    },
    {
        'name': 'mat_sharp:wc'
    },
    {
        'name': 'mat_sharp:web'
    },
    {
        'name': 'mat_sharp:webhook'
    },
    {
        'name': 'mat_sharp:web_asset'
    },
    {
        'name': 'mat_sharp:web_asset_off'
    },
    {
        'name': 'mat_sharp:web_stories'
    },
    {
        'name': 'mat_sharp:weekend'
    },
    {
        'name': 'mat_sharp:west'
    },
    {
        'name': 'mat_sharp:whatshot'
    },
    {
        'name': 'mat_sharp:wheelchair_pickup'
    },
    {
        'name': 'mat_sharp:where_to_vote'
    },
    {
        'name': 'mat_sharp:widgets'
    },
    {
        'name': 'mat_sharp:width_full'
    },
    {
        'name': 'mat_sharp:width_normal'
    },
    {
        'name': 'mat_sharp:width_wide'
    },
    {
        'name': 'mat_sharp:wifi'
    },
    {
        'name': 'mat_sharp:wifi_1_bar'
    },
    {
        'name': 'mat_sharp:wifi_2_bar'
    },
    {
        'name': 'mat_sharp:wifi_calling'
    },
    {
        'name': 'mat_sharp:wifi_calling_3'
    },
    {
        'name': 'mat_sharp:wifi_channel'
    },
    {
        'name': 'mat_sharp:wifi_find'
    },
    {
        'name': 'mat_sharp:wifi_lock'
    },
    {
        'name': 'mat_sharp:wifi_off'
    },
    {
        'name': 'mat_sharp:wifi_password'
    },
    {
        'name': 'mat_sharp:wifi_protected_setup'
    },
    {
        'name': 'mat_sharp:wifi_tethering'
    },
    {
        'name': 'mat_sharp:wifi_tethering_error'
    },
    {
        'name': 'mat_sharp:wifi_tethering_off'
    },
    {
        'name': 'mat_sharp:window'
    },
    {
        'name': 'mat_sharp:wind_power'
    },
    {
        'name': 'mat_sharp:wine_bar'
    },
    {
        'name': 'mat_sharp:woman'
    },
    {
        'name': 'mat_sharp:woman_2'
    },
    {
        'name': 'mat_sharp:work'
    },
    {
        'name': 'mat_sharp:workspaces'
    },
    {
        'name': 'mat_sharp:workspace_premium'
    },
    {
        'name': 'mat_sharp:work_history'
    },
    {
        'name': 'mat_sharp:work_off'
    },
    {
        'name': 'mat_sharp:work_outline'
    },
    {
        'name': 'mat_sharp:wrap_text'
    },
    {
        'name': 'mat_sharp:wrong_location'
    },
    {
        'name': 'mat_sharp:wysiwyg'
    },
    {
        'name': 'mat_sharp:yard'
    },
    {
        'name': 'mat_sharp:youtube_searched_for'
    },
    {
        'name': 'mat_sharp:zoom_in'
    },
    {
        'name': 'mat_sharp:zoom_in_map'
    },
    {
        'name': 'mat_sharp:zoom_out'
    },
    {
        'name': 'mat_sharp:zoom_out_map'
    },
    {
        'name': 'mat_solid:10k'
    },
    {
        'name': 'mat_solid:10mp'
    },
    {
        'name': 'mat_solid:11mp'
    },
    {
        'name': 'mat_solid:123'
    },
    {
        'name': 'mat_solid:12mp'
    },
    {
        'name': 'mat_solid:13mp'
    },
    {
        'name': 'mat_solid:14mp'
    },
    {
        'name': 'mat_solid:15mp'
    },
    {
        'name': 'mat_solid:16mp'
    },
    {
        'name': 'mat_solid:17mp'
    },
    {
        'name': 'mat_solid:18mp'
    },
    {
        'name': 'mat_solid:18_up_rating'
    },
    {
        'name': 'mat_solid:19mp'
    },
    {
        'name': 'mat_solid:1k'
    },
    {
        'name': 'mat_solid:1k_plus'
    },
    {
        'name': 'mat_solid:1x_mobiledata'
    },
    {
        'name': 'mat_solid:20mp'
    },
    {
        'name': 'mat_solid:21mp'
    },
    {
        'name': 'mat_solid:22mp'
    },
    {
        'name': 'mat_solid:23mp'
    },
    {
        'name': 'mat_solid:24mp'
    },
    {
        'name': 'mat_solid:2k'
    },
    {
        'name': 'mat_solid:2k_plus'
    },
    {
        'name': 'mat_solid:2mp'
    },
    {
        'name': 'mat_solid:30fps'
    },
    {
        'name': 'mat_solid:30fps_select'
    },
    {
        'name': 'mat_solid:360'
    },
    {
        'name': 'mat_solid:3d_rotation'
    },
    {
        'name': 'mat_solid:3g_mobiledata'
    },
    {
        'name': 'mat_solid:3k'
    },
    {
        'name': 'mat_solid:3k_plus'
    },
    {
        'name': 'mat_solid:3mp'
    },
    {
        'name': 'mat_solid:3p'
    },
    {
        'name': 'mat_solid:4g_mobiledata'
    },
    {
        'name': 'mat_solid:4g_plus_mobiledata'
    },
    {
        'name': 'mat_solid:4k'
    },
    {
        'name': 'mat_solid:4k_plus'
    },
    {
        'name': 'mat_solid:4mp'
    },
    {
        'name': 'mat_solid:5g'
    },
    {
        'name': 'mat_solid:5k'
    },
    {
        'name': 'mat_solid:5k_plus'
    },
    {
        'name': 'mat_solid:5mp'
    },
    {
        'name': 'mat_solid:60fps'
    },
    {
        'name': 'mat_solid:60fps_select'
    },
    {
        'name': 'mat_solid:6k'
    },
    {
        'name': 'mat_solid:6k_plus'
    },
    {
        'name': 'mat_solid:6mp'
    },
    {
        'name': 'mat_solid:6_ft_apart'
    },
    {
        'name': 'mat_solid:7k'
    },
    {
        'name': 'mat_solid:7k_plus'
    },
    {
        'name': 'mat_solid:7mp'
    },
    {
        'name': 'mat_solid:8k'
    },
    {
        'name': 'mat_solid:8k_plus'
    },
    {
        'name': 'mat_solid:8mp'
    },
    {
        'name': 'mat_solid:9k'
    },
    {
        'name': 'mat_solid:9k_plus'
    },
    {
        'name': 'mat_solid:9mp'
    },
    {
        'name': 'mat_solid:abc'
    },
    {
        'name': 'mat_solid:accessibility'
    },
    {
        'name': 'mat_solid:accessibility_new'
    },
    {
        'name': 'mat_solid:accessible'
    },
    {
        'name': 'mat_solid:accessible_forward'
    },
    {
        'name': 'mat_solid:access_alarm'
    },
    {
        'name': 'mat_solid:access_alarms'
    },
    {
        'name': 'mat_solid:access_time'
    },
    {
        'name': 'mat_solid:access_time_filled'
    },
    {
        'name': 'mat_solid:account_balance'
    },
    {
        'name': 'mat_solid:account_balance_wallet'
    },
    {
        'name': 'mat_solid:account_box'
    },
    {
        'name': 'mat_solid:account_circle'
    },
    {
        'name': 'mat_solid:account_tree'
    },
    {
        'name': 'mat_solid:ac_unit'
    },
    {
        'name': 'mat_solid:adb'
    },
    {
        'name': 'mat_solid:add'
    },
    {
        'name': 'mat_solid:addchart'
    },
    {
        'name': 'mat_solid:add_alarm'
    },
    {
        'name': 'mat_solid:add_alert'
    },
    {
        'name': 'mat_solid:add_a_photo'
    },
    {
        'name': 'mat_solid:add_box'
    },
    {
        'name': 'mat_solid:add_business'
    },
    {
        'name': 'mat_solid:add_card'
    },
    {
        'name': 'mat_solid:add_chart'
    },
    {
        'name': 'mat_solid:add_circle'
    },
    {
        'name': 'mat_solid:add_circle_outline'
    },
    {
        'name': 'mat_solid:add_comment'
    },
    {
        'name': 'mat_solid:add_home'
    },
    {
        'name': 'mat_solid:add_home_work'
    },
    {
        'name': 'mat_solid:add_ic_call'
    },
    {
        'name': 'mat_solid:add_link'
    },
    {
        'name': 'mat_solid:add_location'
    },
    {
        'name': 'mat_solid:add_location_alt'
    },
    {
        'name': 'mat_solid:add_moderator'
    },
    {
        'name': 'mat_solid:add_photo_alternate'
    },
    {
        'name': 'mat_solid:add_reaction'
    },
    {
        'name': 'mat_solid:add_road'
    },
    {
        'name': 'mat_solid:add_shopping_cart'
    },
    {
        'name': 'mat_solid:add_task'
    },
    {
        'name': 'mat_solid:add_to_drive'
    },
    {
        'name': 'mat_solid:add_to_home_screen'
    },
    {
        'name': 'mat_solid:add_to_photos'
    },
    {
        'name': 'mat_solid:add_to_queue'
    },
    {
        'name': 'mat_solid:adf_scanner'
    },
    {
        'name': 'mat_solid:adjust'
    },
    {
        'name': 'mat_solid:admin_panel_settings'
    },
    {
        'name': 'mat_solid:ads_click'
    },
    {
        'name': 'mat_solid:ad_units'
    },
    {
        'name': 'mat_solid:agriculture'
    },
    {
        'name': 'mat_solid:air'
    },
    {
        'name': 'mat_solid:airlines'
    },
    {
        'name': 'mat_solid:airline_seat_flat'
    },
    {
        'name': 'mat_solid:airline_seat_flat_angled'
    },
    {
        'name': 'mat_solid:airline_seat_individual_suite'
    },
    {
        'name': 'mat_solid:airline_seat_legroom_extra'
    },
    {
        'name': 'mat_solid:airline_seat_legroom_normal'
    },
    {
        'name': 'mat_solid:airline_seat_legroom_reduced'
    },
    {
        'name': 'mat_solid:airline_seat_recline_extra'
    },
    {
        'name': 'mat_solid:airline_seat_recline_normal'
    },
    {
        'name': 'mat_solid:airline_stops'
    },
    {
        'name': 'mat_solid:airplanemode_active'
    },
    {
        'name': 'mat_solid:airplanemode_inactive'
    },
    {
        'name': 'mat_solid:airplane_ticket'
    },
    {
        'name': 'mat_solid:airplay'
    },
    {
        'name': 'mat_solid:airport_shuttle'
    },
    {
        'name': 'mat_solid:alarm'
    },
    {
        'name': 'mat_solid:alarm_add'
    },
    {
        'name': 'mat_solid:alarm_off'
    },
    {
        'name': 'mat_solid:alarm_on'
    },
    {
        'name': 'mat_solid:album'
    },
    {
        'name': 'mat_solid:align_horizontal_center'
    },
    {
        'name': 'mat_solid:align_horizontal_left'
    },
    {
        'name': 'mat_solid:align_horizontal_right'
    },
    {
        'name': 'mat_solid:align_vertical_bottom'
    },
    {
        'name': 'mat_solid:align_vertical_center'
    },
    {
        'name': 'mat_solid:align_vertical_top'
    },
    {
        'name': 'mat_solid:all_inbox'
    },
    {
        'name': 'mat_solid:all_inclusive'
    },
    {
        'name': 'mat_solid:all_out'
    },
    {
        'name': 'mat_solid:alternate_email'
    },
    {
        'name': 'mat_solid:alt_route'
    },
    {
        'name': 'mat_solid:analytics'
    },
    {
        'name': 'mat_solid:anchor'
    },
    {
        'name': 'mat_solid:android'
    },
    {
        'name': 'mat_solid:animation'
    },
    {
        'name': 'mat_solid:announcement'
    },
    {
        'name': 'mat_solid:aod'
    },
    {
        'name': 'mat_solid:apartment'
    },
    {
        'name': 'mat_solid:api'
    },
    {
        'name': 'mat_solid:approval'
    },
    {
        'name': 'mat_solid:apps'
    },
    {
        'name': 'mat_solid:apps_outage'
    },
    {
        'name': 'mat_solid:app_blocking'
    },
    {
        'name': 'mat_solid:app_registration'
    },
    {
        'name': 'mat_solid:app_settings_alt'
    },
    {
        'name': 'mat_solid:app_shortcut'
    },
    {
        'name': 'mat_solid:architecture'
    },
    {
        'name': 'mat_solid:archive'
    },
    {
        'name': 'mat_solid:area_chart'
    },
    {
        'name': 'mat_solid:arrow_back'
    },
    {
        'name': 'mat_solid:arrow_back_ios'
    },
    {
        'name': 'mat_solid:arrow_back_ios_new'
    },
    {
        'name': 'mat_solid:arrow_circle_down'
    },
    {
        'name': 'mat_solid:arrow_circle_left'
    },
    {
        'name': 'mat_solid:arrow_circle_right'
    },
    {
        'name': 'mat_solid:arrow_circle_up'
    },
    {
        'name': 'mat_solid:arrow_downward'
    },
    {
        'name': 'mat_solid:arrow_drop_down'
    },
    {
        'name': 'mat_solid:arrow_drop_down_circle'
    },
    {
        'name': 'mat_solid:arrow_drop_up'
    },
    {
        'name': 'mat_solid:arrow_forward'
    },
    {
        'name': 'mat_solid:arrow_forward_ios'
    },
    {
        'name': 'mat_solid:arrow_left'
    },
    {
        'name': 'mat_solid:arrow_outward'
    },
    {
        'name': 'mat_solid:arrow_right'
    },
    {
        'name': 'mat_solid:arrow_right_alt'
    },
    {
        'name': 'mat_solid:arrow_upward'
    },
    {
        'name': 'mat_solid:article'
    },
    {
        'name': 'mat_solid:art_track'
    },
    {
        'name': 'mat_solid:aspect_ratio'
    },
    {
        'name': 'mat_solid:assessment'
    },
    {
        'name': 'mat_solid:assignment'
    },
    {
        'name': 'mat_solid:assignment_ind'
    },
    {
        'name': 'mat_solid:assignment_late'
    },
    {
        'name': 'mat_solid:assignment_return'
    },
    {
        'name': 'mat_solid:assignment_returned'
    },
    {
        'name': 'mat_solid:assignment_turned_in'
    },
    {
        'name': 'mat_solid:assistant'
    },
    {
        'name': 'mat_solid:assistant_direction'
    },
    {
        'name': 'mat_solid:assistant_photo'
    },
    {
        'name': 'mat_solid:assist_walker'
    },
    {
        'name': 'mat_solid:assured_workload'
    },
    {
        'name': 'mat_solid:atm'
    },
    {
        'name': 'mat_solid:attachment'
    },
    {
        'name': 'mat_solid:attach_email'
    },
    {
        'name': 'mat_solid:attach_file'
    },
    {
        'name': 'mat_solid:attach_money'
    },
    {
        'name': 'mat_solid:attractions'
    },
    {
        'name': 'mat_solid:attribution'
    },
    {
        'name': 'mat_solid:audiotrack'
    },
    {
        'name': 'mat_solid:audio_file'
    },
    {
        'name': 'mat_solid:autofps_select'
    },
    {
        'name': 'mat_solid:autorenew'
    },
    {
        'name': 'mat_solid:auto_awesome'
    },
    {
        'name': 'mat_solid:auto_awesome_mosaic'
    },
    {
        'name': 'mat_solid:auto_awesome_motion'
    },
    {
        'name': 'mat_solid:auto_delete'
    },
    {
        'name': 'mat_solid:auto_fix_high'
    },
    {
        'name': 'mat_solid:auto_fix_normal'
    },
    {
        'name': 'mat_solid:auto_fix_off'
    },
    {
        'name': 'mat_solid:auto_graph'
    },
    {
        'name': 'mat_solid:auto_mode'
    },
    {
        'name': 'mat_solid:auto_stories'
    },
    {
        'name': 'mat_solid:av_timer'
    },
    {
        'name': 'mat_solid:baby_changing_station'
    },
    {
        'name': 'mat_solid:backpack'
    },
    {
        'name': 'mat_solid:backspace'
    },
    {
        'name': 'mat_solid:backup'
    },
    {
        'name': 'mat_solid:backup_table'
    },
    {
        'name': 'mat_solid:back_hand'
    },
    {
        'name': 'mat_solid:badge'
    },
    {
        'name': 'mat_solid:bakery_dining'
    },
    {
        'name': 'mat_solid:balance'
    },
    {
        'name': 'mat_solid:balcony'
    },
    {
        'name': 'mat_solid:ballot'
    },
    {
        'name': 'mat_solid:bar_chart'
    },
    {
        'name': 'mat_solid:batch_prediction'
    },
    {
        'name': 'mat_solid:bathroom'
    },
    {
        'name': 'mat_solid:bathtub'
    },
    {
        'name': 'mat_solid:battery_0_bar'
    },
    {
        'name': 'mat_solid:battery_1_bar'
    },
    {
        'name': 'mat_solid:battery_2_bar'
    },
    {
        'name': 'mat_solid:battery_3_bar'
    },
    {
        'name': 'mat_solid:battery_4_bar'
    },
    {
        'name': 'mat_solid:battery_5_bar'
    },
    {
        'name': 'mat_solid:battery_6_bar'
    },
    {
        'name': 'mat_solid:battery_alert'
    },
    {
        'name': 'mat_solid:battery_charging_full'
    },
    {
        'name': 'mat_solid:battery_full'
    },
    {
        'name': 'mat_solid:battery_saver'
    },
    {
        'name': 'mat_solid:battery_std'
    },
    {
        'name': 'mat_solid:battery_unknown'
    },
    {
        'name': 'mat_solid:beach_access'
    },
    {
        'name': 'mat_solid:bed'
    },
    {
        'name': 'mat_solid:bedroom_baby'
    },
    {
        'name': 'mat_solid:bedroom_child'
    },
    {
        'name': 'mat_solid:bedroom_parent'
    },
    {
        'name': 'mat_solid:bedtime'
    },
    {
        'name': 'mat_solid:bedtime_off'
    },
    {
        'name': 'mat_solid:beenhere'
    },
    {
        'name': 'mat_solid:bento'
    },
    {
        'name': 'mat_solid:bike_scooter'
    },
    {
        'name': 'mat_solid:biotech'
    },
    {
        'name': 'mat_solid:blender'
    },
    {
        'name': 'mat_solid:blind'
    },
    {
        'name': 'mat_solid:blinds'
    },
    {
        'name': 'mat_solid:blinds_closed'
    },
    {
        'name': 'mat_solid:block'
    },
    {
        'name': 'mat_solid:bloodtype'
    },
    {
        'name': 'mat_solid:bluetooth'
    },
    {
        'name': 'mat_solid:bluetooth_audio'
    },
    {
        'name': 'mat_solid:bluetooth_connected'
    },
    {
        'name': 'mat_solid:bluetooth_disabled'
    },
    {
        'name': 'mat_solid:bluetooth_drive'
    },
    {
        'name': 'mat_solid:bluetooth_searching'
    },
    {
        'name': 'mat_solid:blur_circular'
    },
    {
        'name': 'mat_solid:blur_linear'
    },
    {
        'name': 'mat_solid:blur_off'
    },
    {
        'name': 'mat_solid:blur_on'
    },
    {
        'name': 'mat_solid:bolt'
    },
    {
        'name': 'mat_solid:book'
    },
    {
        'name': 'mat_solid:bookmark'
    },
    {
        'name': 'mat_solid:bookmarks'
    },
    {
        'name': 'mat_solid:bookmark_add'
    },
    {
        'name': 'mat_solid:bookmark_added'
    },
    {
        'name': 'mat_solid:bookmark_border'
    },
    {
        'name': 'mat_solid:bookmark_remove'
    },
    {
        'name': 'mat_solid:book_online'
    },
    {
        'name': 'mat_solid:border_all'
    },
    {
        'name': 'mat_solid:border_bottom'
    },
    {
        'name': 'mat_solid:border_clear'
    },
    {
        'name': 'mat_solid:border_color'
    },
    {
        'name': 'mat_solid:border_horizontal'
    },
    {
        'name': 'mat_solid:border_inner'
    },
    {
        'name': 'mat_solid:border_left'
    },
    {
        'name': 'mat_solid:border_outer'
    },
    {
        'name': 'mat_solid:border_right'
    },
    {
        'name': 'mat_solid:border_style'
    },
    {
        'name': 'mat_solid:border_top'
    },
    {
        'name': 'mat_solid:border_vertical'
    },
    {
        'name': 'mat_solid:boy'
    },
    {
        'name': 'mat_solid:branding_watermark'
    },
    {
        'name': 'mat_solid:breakfast_dining'
    },
    {
        'name': 'mat_solid:brightness_1'
    },
    {
        'name': 'mat_solid:brightness_2'
    },
    {
        'name': 'mat_solid:brightness_3'
    },
    {
        'name': 'mat_solid:brightness_4'
    },
    {
        'name': 'mat_solid:brightness_5'
    },
    {
        'name': 'mat_solid:brightness_6'
    },
    {
        'name': 'mat_solid:brightness_7'
    },
    {
        'name': 'mat_solid:brightness_auto'
    },
    {
        'name': 'mat_solid:brightness_high'
    },
    {
        'name': 'mat_solid:brightness_low'
    },
    {
        'name': 'mat_solid:brightness_medium'
    },
    {
        'name': 'mat_solid:broadcast_on_home'
    },
    {
        'name': 'mat_solid:broadcast_on_personal'
    },
    {
        'name': 'mat_solid:broken_image'
    },
    {
        'name': 'mat_solid:browser_not_supported'
    },
    {
        'name': 'mat_solid:browser_updated'
    },
    {
        'name': 'mat_solid:browse_gallery'
    },
    {
        'name': 'mat_solid:brunch_dining'
    },
    {
        'name': 'mat_solid:brush'
    },
    {
        'name': 'mat_solid:bubble_chart'
    },
    {
        'name': 'mat_solid:bug_report'
    },
    {
        'name': 'mat_solid:build'
    },
    {
        'name': 'mat_solid:build_circle'
    },
    {
        'name': 'mat_solid:bungalow'
    },
    {
        'name': 'mat_solid:burst_mode'
    },
    {
        'name': 'mat_solid:business'
    },
    {
        'name': 'mat_solid:business_center'
    },
    {
        'name': 'mat_solid:bus_alert'
    },
    {
        'name': 'mat_solid:cabin'
    },
    {
        'name': 'mat_solid:cable'
    },
    {
        'name': 'mat_solid:cached'
    },
    {
        'name': 'mat_solid:cake'
    },
    {
        'name': 'mat_solid:calculate'
    },
    {
        'name': 'mat_solid:calendar_month'
    },
    {
        'name': 'mat_solid:calendar_today'
    },
    {
        'name': 'mat_solid:calendar_view_day'
    },
    {
        'name': 'mat_solid:calendar_view_month'
    },
    {
        'name': 'mat_solid:calendar_view_week'
    },
    {
        'name': 'mat_solid:call'
    },
    {
        'name': 'mat_solid:call_end'
    },
    {
        'name': 'mat_solid:call_made'
    },
    {
        'name': 'mat_solid:call_merge'
    },
    {
        'name': 'mat_solid:call_missed'
    },
    {
        'name': 'mat_solid:call_missed_outgoing'
    },
    {
        'name': 'mat_solid:call_received'
    },
    {
        'name': 'mat_solid:call_split'
    },
    {
        'name': 'mat_solid:call_to_action'
    },
    {
        'name': 'mat_solid:camera'
    },
    {
        'name': 'mat_solid:cameraswitch'
    },
    {
        'name': 'mat_solid:camera_alt'
    },
    {
        'name': 'mat_solid:camera_enhance'
    },
    {
        'name': 'mat_solid:camera_front'
    },
    {
        'name': 'mat_solid:camera_indoor'
    },
    {
        'name': 'mat_solid:camera_outdoor'
    },
    {
        'name': 'mat_solid:camera_rear'
    },
    {
        'name': 'mat_solid:camera_roll'
    },
    {
        'name': 'mat_solid:campaign'
    },
    {
        'name': 'mat_solid:cancel'
    },
    {
        'name': 'mat_solid:cancel_presentation'
    },
    {
        'name': 'mat_solid:cancel_schedule_send'
    },
    {
        'name': 'mat_solid:candlestick_chart'
    },
    {
        'name': 'mat_solid:card_giftcard'
    },
    {
        'name': 'mat_solid:card_membership'
    },
    {
        'name': 'mat_solid:card_travel'
    },
    {
        'name': 'mat_solid:carpenter'
    },
    {
        'name': 'mat_solid:car_crash'
    },
    {
        'name': 'mat_solid:car_rental'
    },
    {
        'name': 'mat_solid:car_repair'
    },
    {
        'name': 'mat_solid:cases'
    },
    {
        'name': 'mat_solid:casino'
    },
    {
        'name': 'mat_solid:cast'
    },
    {
        'name': 'mat_solid:castle'
    },
    {
        'name': 'mat_solid:cast_connected'
    },
    {
        'name': 'mat_solid:cast_for_education'
    },
    {
        'name': 'mat_solid:catching_pokemon'
    },
    {
        'name': 'mat_solid:category'
    },
    {
        'name': 'mat_solid:celebration'
    },
    {
        'name': 'mat_solid:cell_tower'
    },
    {
        'name': 'mat_solid:cell_wifi'
    },
    {
        'name': 'mat_solid:center_focus_strong'
    },
    {
        'name': 'mat_solid:center_focus_weak'
    },
    {
        'name': 'mat_solid:chair'
    },
    {
        'name': 'mat_solid:chair_alt'
    },
    {
        'name': 'mat_solid:chalet'
    },
    {
        'name': 'mat_solid:change_circle'
    },
    {
        'name': 'mat_solid:change_history'
    },
    {
        'name': 'mat_solid:charging_station'
    },
    {
        'name': 'mat_solid:chat'
    },
    {
        'name': 'mat_solid:chat_bubble'
    },
    {
        'name': 'mat_solid:chat_bubble_outline'
    },
    {
        'name': 'mat_solid:check'
    },
    {
        'name': 'mat_solid:checklist'
    },
    {
        'name': 'mat_solid:checklist_rtl'
    },
    {
        'name': 'mat_solid:checkroom'
    },
    {
        'name': 'mat_solid:check_box'
    },
    {
        'name': 'mat_solid:check_box_outline_blank'
    },
    {
        'name': 'mat_solid:check_circle'
    },
    {
        'name': 'mat_solid:check_circle_outline'
    },
    {
        'name': 'mat_solid:chevron_left'
    },
    {
        'name': 'mat_solid:chevron_right'
    },
    {
        'name': 'mat_solid:child_care'
    },
    {
        'name': 'mat_solid:child_friendly'
    },
    {
        'name': 'mat_solid:chrome_reader_mode'
    },
    {
        'name': 'mat_solid:church'
    },
    {
        'name': 'mat_solid:circle'
    },
    {
        'name': 'mat_solid:circle_notifications'
    },
    {
        'name': 'mat_solid:class'
    },
    {
        'name': 'mat_solid:cleaning_services'
    },
    {
        'name': 'mat_solid:clean_hands'
    },
    {
        'name': 'mat_solid:clear'
    },
    {
        'name': 'mat_solid:clear_all'
    },
    {
        'name': 'mat_solid:close'
    },
    {
        'name': 'mat_solid:closed_caption'
    },
    {
        'name': 'mat_solid:closed_caption_disabled'
    },
    {
        'name': 'mat_solid:closed_caption_off'
    },
    {
        'name': 'mat_solid:close_fullscreen'
    },
    {
        'name': 'mat_solid:cloud'
    },
    {
        'name': 'mat_solid:cloud_circle'
    },
    {
        'name': 'mat_solid:cloud_done'
    },
    {
        'name': 'mat_solid:cloud_download'
    },
    {
        'name': 'mat_solid:cloud_off'
    },
    {
        'name': 'mat_solid:cloud_queue'
    },
    {
        'name': 'mat_solid:cloud_sync'
    },
    {
        'name': 'mat_solid:cloud_upload'
    },
    {
        'name': 'mat_solid:co2'
    },
    {
        'name': 'mat_solid:code'
    },
    {
        'name': 'mat_solid:code_off'
    },
    {
        'name': 'mat_solid:coffee'
    },
    {
        'name': 'mat_solid:coffee_maker'
    },
    {
        'name': 'mat_solid:collections'
    },
    {
        'name': 'mat_solid:collections_bookmark'
    },
    {
        'name': 'mat_solid:colorize'
    },
    {
        'name': 'mat_solid:color_lens'
    },
    {
        'name': 'mat_solid:comment'
    },
    {
        'name': 'mat_solid:comments_disabled'
    },
    {
        'name': 'mat_solid:comment_bank'
    },
    {
        'name': 'mat_solid:commit'
    },
    {
        'name': 'mat_solid:commute'
    },
    {
        'name': 'mat_solid:compare'
    },
    {
        'name': 'mat_solid:compare_arrows'
    },
    {
        'name': 'mat_solid:compass_calibration'
    },
    {
        'name': 'mat_solid:compost'
    },
    {
        'name': 'mat_solid:compress'
    },
    {
        'name': 'mat_solid:computer'
    },
    {
        'name': 'mat_solid:confirmation_number'
    },
    {
        'name': 'mat_solid:connected_tv'
    },
    {
        'name': 'mat_solid:connecting_airports'
    },
    {
        'name': 'mat_solid:connect_without_contact'
    },
    {
        'name': 'mat_solid:construction'
    },
    {
        'name': 'mat_solid:contactless'
    },
    {
        'name': 'mat_solid:contacts'
    },
    {
        'name': 'mat_solid:contact_emergency'
    },
    {
        'name': 'mat_solid:contact_mail'
    },
    {
        'name': 'mat_solid:contact_page'
    },
    {
        'name': 'mat_solid:contact_phone'
    },
    {
        'name': 'mat_solid:contact_support'
    },
    {
        'name': 'mat_solid:content_copy'
    },
    {
        'name': 'mat_solid:content_cut'
    },
    {
        'name': 'mat_solid:content_paste'
    },
    {
        'name': 'mat_solid:content_paste_go'
    },
    {
        'name': 'mat_solid:content_paste_off'
    },
    {
        'name': 'mat_solid:content_paste_search'
    },
    {
        'name': 'mat_solid:contrast'
    },
    {
        'name': 'mat_solid:control_camera'
    },
    {
        'name': 'mat_solid:control_point'
    },
    {
        'name': 'mat_solid:control_point_duplicate'
    },
    {
        'name': 'mat_solid:cookie'
    },
    {
        'name': 'mat_solid:copyright'
    },
    {
        'name': 'mat_solid:copy_all'
    },
    {
        'name': 'mat_solid:coronavirus'
    },
    {
        'name': 'mat_solid:corporate_fare'
    },
    {
        'name': 'mat_solid:cottage'
    },
    {
        'name': 'mat_solid:countertops'
    },
    {
        'name': 'mat_solid:co_present'
    },
    {
        'name': 'mat_solid:create'
    },
    {
        'name': 'mat_solid:create_new_folder'
    },
    {
        'name': 'mat_solid:credit_card'
    },
    {
        'name': 'mat_solid:credit_card_off'
    },
    {
        'name': 'mat_solid:credit_score'
    },
    {
        'name': 'mat_solid:crib'
    },
    {
        'name': 'mat_solid:crisis_alert'
    },
    {
        'name': 'mat_solid:crop'
    },
    {
        'name': 'mat_solid:crop_16_9'
    },
    {
        'name': 'mat_solid:crop_3_2'
    },
    {
        'name': 'mat_solid:crop_5_4'
    },
    {
        'name': 'mat_solid:crop_7_5'
    },
    {
        'name': 'mat_solid:crop_din'
    },
    {
        'name': 'mat_solid:crop_free'
    },
    {
        'name': 'mat_solid:crop_landscape'
    },
    {
        'name': 'mat_solid:crop_original'
    },
    {
        'name': 'mat_solid:crop_portrait'
    },
    {
        'name': 'mat_solid:crop_rotate'
    },
    {
        'name': 'mat_solid:crop_square'
    },
    {
        'name': 'mat_solid:cruelty_free'
    },
    {
        'name': 'mat_solid:css'
    },
    {
        'name': 'mat_solid:currency_bitcoin'
    },
    {
        'name': 'mat_solid:currency_exchange'
    },
    {
        'name': 'mat_solid:currency_franc'
    },
    {
        'name': 'mat_solid:currency_lira'
    },
    {
        'name': 'mat_solid:currency_pound'
    },
    {
        'name': 'mat_solid:currency_ruble'
    },
    {
        'name': 'mat_solid:currency_rupee'
    },
    {
        'name': 'mat_solid:currency_yen'
    },
    {
        'name': 'mat_solid:currency_yuan'
    },
    {
        'name': 'mat_solid:curtains'
    },
    {
        'name': 'mat_solid:curtains_closed'
    },
    {
        'name': 'mat_solid:cyclone'
    },
    {
        'name': 'mat_solid:dangerous'
    },
    {
        'name': 'mat_solid:dark_mode'
    },
    {
        'name': 'mat_solid:dashboard'
    },
    {
        'name': 'mat_solid:dashboard_customize'
    },
    {
        'name': 'mat_solid:dataset'
    },
    {
        'name': 'mat_solid:dataset_linked'
    },
    {
        'name': 'mat_solid:data_array'
    },
    {
        'name': 'mat_solid:data_exploration'
    },
    {
        'name': 'mat_solid:data_object'
    },
    {
        'name': 'mat_solid:data_saver_off'
    },
    {
        'name': 'mat_solid:data_saver_on'
    },
    {
        'name': 'mat_solid:data_thresholding'
    },
    {
        'name': 'mat_solid:data_usage'
    },
    {
        'name': 'mat_solid:date_range'
    },
    {
        'name': 'mat_solid:deblur'
    },
    {
        'name': 'mat_solid:deck'
    },
    {
        'name': 'mat_solid:dehaze'
    },
    {
        'name': 'mat_solid:delete'
    },
    {
        'name': 'mat_solid:delete_forever'
    },
    {
        'name': 'mat_solid:delete_outline'
    },
    {
        'name': 'mat_solid:delete_sweep'
    },
    {
        'name': 'mat_solid:delivery_dining'
    },
    {
        'name': 'mat_solid:density_large'
    },
    {
        'name': 'mat_solid:density_medium'
    },
    {
        'name': 'mat_solid:density_small'
    },
    {
        'name': 'mat_solid:departure_board'
    },
    {
        'name': 'mat_solid:description'
    },
    {
        'name': 'mat_solid:deselect'
    },
    {
        'name': 'mat_solid:design_services'
    },
    {
        'name': 'mat_solid:desk'
    },
    {
        'name': 'mat_solid:desktop_access_disabled'
    },
    {
        'name': 'mat_solid:desktop_mac'
    },
    {
        'name': 'mat_solid:desktop_windows'
    },
    {
        'name': 'mat_solid:details'
    },
    {
        'name': 'mat_solid:developer_board'
    },
    {
        'name': 'mat_solid:developer_board_off'
    },
    {
        'name': 'mat_solid:developer_mode'
    },
    {
        'name': 'mat_solid:devices'
    },
    {
        'name': 'mat_solid:devices_fold'
    },
    {
        'name': 'mat_solid:devices_other'
    },
    {
        'name': 'mat_solid:device_hub'
    },
    {
        'name': 'mat_solid:device_thermostat'
    },
    {
        'name': 'mat_solid:device_unknown'
    },
    {
        'name': 'mat_solid:dialer_sip'
    },
    {
        'name': 'mat_solid:dialpad'
    },
    {
        'name': 'mat_solid:diamond'
    },
    {
        'name': 'mat_solid:difference'
    },
    {
        'name': 'mat_solid:dining'
    },
    {
        'name': 'mat_solid:dinner_dining'
    },
    {
        'name': 'mat_solid:directions'
    },
    {
        'name': 'mat_solid:directions_bike'
    },
    {
        'name': 'mat_solid:directions_boat'
    },
    {
        'name': 'mat_solid:directions_boat_filled'
    },
    {
        'name': 'mat_solid:directions_bus'
    },
    {
        'name': 'mat_solid:directions_bus_filled'
    },
    {
        'name': 'mat_solid:directions_car'
    },
    {
        'name': 'mat_solid:directions_car_filled'
    },
    {
        'name': 'mat_solid:directions_off'
    },
    {
        'name': 'mat_solid:directions_railway'
    },
    {
        'name': 'mat_solid:directions_railway_filled'
    },
    {
        'name': 'mat_solid:directions_run'
    },
    {
        'name': 'mat_solid:directions_subway'
    },
    {
        'name': 'mat_solid:directions_subway_filled'
    },
    {
        'name': 'mat_solid:directions_transit'
    },
    {
        'name': 'mat_solid:directions_transit_filled'
    },
    {
        'name': 'mat_solid:directions_walk'
    },
    {
        'name': 'mat_solid:dirty_lens'
    },
    {
        'name': 'mat_solid:disabled_by_default'
    },
    {
        'name': 'mat_solid:disabled_visible'
    },
    {
        'name': 'mat_solid:discount'
    },
    {
        'name': 'mat_solid:disc_full'
    },
    {
        'name': 'mat_solid:display_settings'
    },
    {
        'name': 'mat_solid:diversity_1'
    },
    {
        'name': 'mat_solid:diversity_2'
    },
    {
        'name': 'mat_solid:diversity_3'
    },
    {
        'name': 'mat_solid:dns'
    },
    {
        'name': 'mat_solid:dock'
    },
    {
        'name': 'mat_solid:document_scanner'
    },
    {
        'name': 'mat_solid:domain'
    },
    {
        'name': 'mat_solid:domain_add'
    },
    {
        'name': 'mat_solid:domain_disabled'
    },
    {
        'name': 'mat_solid:domain_verification'
    },
    {
        'name': 'mat_solid:done'
    },
    {
        'name': 'mat_solid:done_all'
    },
    {
        'name': 'mat_solid:done_outline'
    },
    {
        'name': 'mat_solid:donut_large'
    },
    {
        'name': 'mat_solid:donut_small'
    },
    {
        'name': 'mat_solid:doorbell'
    },
    {
        'name': 'mat_solid:door_back'
    },
    {
        'name': 'mat_solid:door_front'
    },
    {
        'name': 'mat_solid:door_sliding'
    },
    {
        'name': 'mat_solid:double_arrow'
    },
    {
        'name': 'mat_solid:downhill_skiing'
    },
    {
        'name': 'mat_solid:download'
    },
    {
        'name': 'mat_solid:downloading'
    },
    {
        'name': 'mat_solid:download_done'
    },
    {
        'name': 'mat_solid:download_for_offline'
    },
    {
        'name': 'mat_solid:do_disturb'
    },
    {
        'name': 'mat_solid:do_disturb_alt'
    },
    {
        'name': 'mat_solid:do_disturb_off'
    },
    {
        'name': 'mat_solid:do_disturb_on'
    },
    {
        'name': 'mat_solid:do_not_disturb'
    },
    {
        'name': 'mat_solid:do_not_disturb_alt'
    },
    {
        'name': 'mat_solid:do_not_disturb_off'
    },
    {
        'name': 'mat_solid:do_not_disturb_on'
    },
    {
        'name': 'mat_solid:do_not_disturb_on_total_silence'
    },
    {
        'name': 'mat_solid:do_not_step'
    },
    {
        'name': 'mat_solid:do_not_touch'
    },
    {
        'name': 'mat_solid:drafts'
    },
    {
        'name': 'mat_solid:drag_handle'
    },
    {
        'name': 'mat_solid:drag_indicator'
    },
    {
        'name': 'mat_solid:draw'
    },
    {
        'name': 'mat_solid:drive_eta'
    },
    {
        'name': 'mat_solid:drive_file_move'
    },
    {
        'name': 'mat_solid:drive_file_move_rtl'
    },
    {
        'name': 'mat_solid:drive_file_rename_outline'
    },
    {
        'name': 'mat_solid:drive_folder_upload'
    },
    {
        'name': 'mat_solid:dry'
    },
    {
        'name': 'mat_solid:dry_cleaning'
    },
    {
        'name': 'mat_solid:duo'
    },
    {
        'name': 'mat_solid:dvr'
    },
    {
        'name': 'mat_solid:dynamic_feed'
    },
    {
        'name': 'mat_solid:dynamic_form'
    },
    {
        'name': 'mat_solid:earbuds'
    },
    {
        'name': 'mat_solid:earbuds_battery'
    },
    {
        'name': 'mat_solid:east'
    },
    {
        'name': 'mat_solid:edgesensor_high'
    },
    {
        'name': 'mat_solid:edgesensor_low'
    },
    {
        'name': 'mat_solid:edit'
    },
    {
        'name': 'mat_solid:edit_attributes'
    },
    {
        'name': 'mat_solid:edit_calendar'
    },
    {
        'name': 'mat_solid:edit_location'
    },
    {
        'name': 'mat_solid:edit_location_alt'
    },
    {
        'name': 'mat_solid:edit_note'
    },
    {
        'name': 'mat_solid:edit_notifications'
    },
    {
        'name': 'mat_solid:edit_off'
    },
    {
        'name': 'mat_solid:edit_road'
    },
    {
        'name': 'mat_solid:egg'
    },
    {
        'name': 'mat_solid:egg_alt'
    },
    {
        'name': 'mat_solid:eject'
    },
    {
        'name': 'mat_solid:elderly'
    },
    {
        'name': 'mat_solid:elderly_woman'
    },
    {
        'name': 'mat_solid:electrical_services'
    },
    {
        'name': 'mat_solid:electric_bike'
    },
    {
        'name': 'mat_solid:electric_bolt'
    },
    {
        'name': 'mat_solid:electric_car'
    },
    {
        'name': 'mat_solid:electric_meter'
    },
    {
        'name': 'mat_solid:electric_moped'
    },
    {
        'name': 'mat_solid:electric_rickshaw'
    },
    {
        'name': 'mat_solid:electric_scooter'
    },
    {
        'name': 'mat_solid:elevator'
    },
    {
        'name': 'mat_solid:email'
    },
    {
        'name': 'mat_solid:emergency'
    },
    {
        'name': 'mat_solid:emergency_recording'
    },
    {
        'name': 'mat_solid:emergency_share'
    },
    {
        'name': 'mat_solid:emoji_emotions'
    },
    {
        'name': 'mat_solid:emoji_events'
    },
    {
        'name': 'mat_solid:emoji_food_beverage'
    },
    {
        'name': 'mat_solid:emoji_nature'
    },
    {
        'name': 'mat_solid:emoji_objects'
    },
    {
        'name': 'mat_solid:emoji_people'
    },
    {
        'name': 'mat_solid:emoji_symbols'
    },
    {
        'name': 'mat_solid:emoji_transportation'
    },
    {
        'name': 'mat_solid:energy_savings_leaf'
    },
    {
        'name': 'mat_solid:engineering'
    },
    {
        'name': 'mat_solid:enhanced_encryption'
    },
    {
        'name': 'mat_solid:equalizer'
    },
    {
        'name': 'mat_solid:error'
    },
    {
        'name': 'mat_solid:error_outline'
    },
    {
        'name': 'mat_solid:escalator'
    },
    {
        'name': 'mat_solid:escalator_warning'
    },
    {
        'name': 'mat_solid:euro'
    },
    {
        'name': 'mat_solid:euro_symbol'
    },
    {
        'name': 'mat_solid:event'
    },
    {
        'name': 'mat_solid:event_available'
    },
    {
        'name': 'mat_solid:event_busy'
    },
    {
        'name': 'mat_solid:event_note'
    },
    {
        'name': 'mat_solid:event_repeat'
    },
    {
        'name': 'mat_solid:event_seat'
    },
    {
        'name': 'mat_solid:ev_station'
    },
    {
        'name': 'mat_solid:exit_to_app'
    },
    {
        'name': 'mat_solid:expand'
    },
    {
        'name': 'mat_solid:expand_circle_down'
    },
    {
        'name': 'mat_solid:expand_less'
    },
    {
        'name': 'mat_solid:expand_more'
    },
    {
        'name': 'mat_solid:explicit'
    },
    {
        'name': 'mat_solid:explore'
    },
    {
        'name': 'mat_solid:explore_off'
    },
    {
        'name': 'mat_solid:exposure'
    },
    {
        'name': 'mat_solid:exposure_neg_1'
    },
    {
        'name': 'mat_solid:exposure_neg_2'
    },
    {
        'name': 'mat_solid:exposure_plus_1'
    },
    {
        'name': 'mat_solid:exposure_plus_2'
    },
    {
        'name': 'mat_solid:exposure_zero'
    },
    {
        'name': 'mat_solid:extension'
    },
    {
        'name': 'mat_solid:extension_off'
    },
    {
        'name': 'mat_solid:e_mobiledata'
    },
    {
        'name': 'mat_solid:face'
    },
    {
        'name': 'mat_solid:face_2'
    },
    {
        'name': 'mat_solid:face_3'
    },
    {
        'name': 'mat_solid:face_4'
    },
    {
        'name': 'mat_solid:face_5'
    },
    {
        'name': 'mat_solid:face_6'
    },
    {
        'name': 'mat_solid:face_retouching_natural'
    },
    {
        'name': 'mat_solid:face_retouching_off'
    },
    {
        'name': 'mat_solid:factory'
    },
    {
        'name': 'mat_solid:fact_check'
    },
    {
        'name': 'mat_solid:family_restroom'
    },
    {
        'name': 'mat_solid:fastfood'
    },
    {
        'name': 'mat_solid:fast_forward'
    },
    {
        'name': 'mat_solid:fast_rewind'
    },
    {
        'name': 'mat_solid:favorite'
    },
    {
        'name': 'mat_solid:favorite_border'
    },
    {
        'name': 'mat_solid:fax'
    },
    {
        'name': 'mat_solid:featured_play_list'
    },
    {
        'name': 'mat_solid:featured_video'
    },
    {
        'name': 'mat_solid:feed'
    },
    {
        'name': 'mat_solid:feedback'
    },
    {
        'name': 'mat_solid:female'
    },
    {
        'name': 'mat_solid:fence'
    },
    {
        'name': 'mat_solid:festival'
    },
    {
        'name': 'mat_solid:fiber_dvr'
    },
    {
        'name': 'mat_solid:fiber_manual_record'
    },
    {
        'name': 'mat_solid:fiber_new'
    },
    {
        'name': 'mat_solid:fiber_pin'
    },
    {
        'name': 'mat_solid:fiber_smart_record'
    },
    {
        'name': 'mat_solid:file_copy'
    },
    {
        'name': 'mat_solid:file_download'
    },
    {
        'name': 'mat_solid:file_download_done'
    },
    {
        'name': 'mat_solid:file_download_off'
    },
    {
        'name': 'mat_solid:file_open'
    },
    {
        'name': 'mat_solid:file_present'
    },
    {
        'name': 'mat_solid:file_upload'
    },
    {
        'name': 'mat_solid:filter'
    },
    {
        'name': 'mat_solid:filter_1'
    },
    {
        'name': 'mat_solid:filter_2'
    },
    {
        'name': 'mat_solid:filter_3'
    },
    {
        'name': 'mat_solid:filter_4'
    },
    {
        'name': 'mat_solid:filter_5'
    },
    {
        'name': 'mat_solid:filter_6'
    },
    {
        'name': 'mat_solid:filter_7'
    },
    {
        'name': 'mat_solid:filter_8'
    },
    {
        'name': 'mat_solid:filter_9'
    },
    {
        'name': 'mat_solid:filter_9_plus'
    },
    {
        'name': 'mat_solid:filter_alt'
    },
    {
        'name': 'mat_solid:filter_alt_off'
    },
    {
        'name': 'mat_solid:filter_b_and_w'
    },
    {
        'name': 'mat_solid:filter_center_focus'
    },
    {
        'name': 'mat_solid:filter_drama'
    },
    {
        'name': 'mat_solid:filter_frames'
    },
    {
        'name': 'mat_solid:filter_hdr'
    },
    {
        'name': 'mat_solid:filter_list'
    },
    {
        'name': 'mat_solid:filter_list_off'
    },
    {
        'name': 'mat_solid:filter_none'
    },
    {
        'name': 'mat_solid:filter_tilt_shift'
    },
    {
        'name': 'mat_solid:filter_vintage'
    },
    {
        'name': 'mat_solid:find_in_page'
    },
    {
        'name': 'mat_solid:find_replace'
    },
    {
        'name': 'mat_solid:fingerprint'
    },
    {
        'name': 'mat_solid:fireplace'
    },
    {
        'name': 'mat_solid:fire_extinguisher'
    },
    {
        'name': 'mat_solid:fire_hydrant_alt'
    },
    {
        'name': 'mat_solid:fire_truck'
    },
    {
        'name': 'mat_solid:first_page'
    },
    {
        'name': 'mat_solid:fitbit'
    },
    {
        'name': 'mat_solid:fitness_center'
    },
    {
        'name': 'mat_solid:fit_screen'
    },
    {
        'name': 'mat_solid:flag'
    },
    {
        'name': 'mat_solid:flag_circle'
    },
    {
        'name': 'mat_solid:flaky'
    },
    {
        'name': 'mat_solid:flare'
    },
    {
        'name': 'mat_solid:flashlight_off'
    },
    {
        'name': 'mat_solid:flashlight_on'
    },
    {
        'name': 'mat_solid:flash_auto'
    },
    {
        'name': 'mat_solid:flash_off'
    },
    {
        'name': 'mat_solid:flash_on'
    },
    {
        'name': 'mat_solid:flatware'
    },
    {
        'name': 'mat_solid:flight'
    },
    {
        'name': 'mat_solid:flight_class'
    },
    {
        'name': 'mat_solid:flight_land'
    },
    {
        'name': 'mat_solid:flight_takeoff'
    },
    {
        'name': 'mat_solid:flip'
    },
    {
        'name': 'mat_solid:flip_camera_android'
    },
    {
        'name': 'mat_solid:flip_camera_ios'
    },
    {
        'name': 'mat_solid:flip_to_back'
    },
    {
        'name': 'mat_solid:flip_to_front'
    },
    {
        'name': 'mat_solid:flood'
    },
    {
        'name': 'mat_solid:fluorescent'
    },
    {
        'name': 'mat_solid:flutter_dash'
    },
    {
        'name': 'mat_solid:fmd_bad'
    },
    {
        'name': 'mat_solid:fmd_good'
    },
    {
        'name': 'mat_solid:folder'
    },
    {
        'name': 'mat_solid:folder_copy'
    },
    {
        'name': 'mat_solid:folder_delete'
    },
    {
        'name': 'mat_solid:folder_off'
    },
    {
        'name': 'mat_solid:folder_open'
    },
    {
        'name': 'mat_solid:folder_shared'
    },
    {
        'name': 'mat_solid:folder_special'
    },
    {
        'name': 'mat_solid:folder_zip'
    },
    {
        'name': 'mat_solid:follow_the_signs'
    },
    {
        'name': 'mat_solid:font_download'
    },
    {
        'name': 'mat_solid:font_download_off'
    },
    {
        'name': 'mat_solid:food_bank'
    },
    {
        'name': 'mat_solid:forest'
    },
    {
        'name': 'mat_solid:fork_left'
    },
    {
        'name': 'mat_solid:fork_right'
    },
    {
        'name': 'mat_solid:format_align_center'
    },
    {
        'name': 'mat_solid:format_align_justify'
    },
    {
        'name': 'mat_solid:format_align_left'
    },
    {
        'name': 'mat_solid:format_align_right'
    },
    {
        'name': 'mat_solid:format_bold'
    },
    {
        'name': 'mat_solid:format_clear'
    },
    {
        'name': 'mat_solid:format_color_fill'
    },
    {
        'name': 'mat_solid:format_color_reset'
    },
    {
        'name': 'mat_solid:format_color_text'
    },
    {
        'name': 'mat_solid:format_indent_decrease'
    },
    {
        'name': 'mat_solid:format_indent_increase'
    },
    {
        'name': 'mat_solid:format_italic'
    },
    {
        'name': 'mat_solid:format_line_spacing'
    },
    {
        'name': 'mat_solid:format_list_bulleted'
    },
    {
        'name': 'mat_solid:format_list_numbered'
    },
    {
        'name': 'mat_solid:format_list_numbered_rtl'
    },
    {
        'name': 'mat_solid:format_overline'
    },
    {
        'name': 'mat_solid:format_paint'
    },
    {
        'name': 'mat_solid:format_quote'
    },
    {
        'name': 'mat_solid:format_shapes'
    },
    {
        'name': 'mat_solid:format_size'
    },
    {
        'name': 'mat_solid:format_strikethrough'
    },
    {
        'name': 'mat_solid:format_textdirection_l_to_r'
    },
    {
        'name': 'mat_solid:format_textdirection_r_to_l'
    },
    {
        'name': 'mat_solid:format_underlined'
    },
    {
        'name': 'mat_solid:fort'
    },
    {
        'name': 'mat_solid:forum'
    },
    {
        'name': 'mat_solid:forward'
    },
    {
        'name': 'mat_solid:forward_10'
    },
    {
        'name': 'mat_solid:forward_30'
    },
    {
        'name': 'mat_solid:forward_5'
    },
    {
        'name': 'mat_solid:forward_to_inbox'
    },
    {
        'name': 'mat_solid:foundation'
    },
    {
        'name': 'mat_solid:free_breakfast'
    },
    {
        'name': 'mat_solid:free_cancellation'
    },
    {
        'name': 'mat_solid:front_hand'
    },
    {
        'name': 'mat_solid:fullscreen'
    },
    {
        'name': 'mat_solid:fullscreen_exit'
    },
    {
        'name': 'mat_solid:functions'
    },
    {
        'name': 'mat_solid:gamepad'
    },
    {
        'name': 'mat_solid:games'
    },
    {
        'name': 'mat_solid:garage'
    },
    {
        'name': 'mat_solid:gas_meter'
    },
    {
        'name': 'mat_solid:gavel'
    },
    {
        'name': 'mat_solid:generating_tokens'
    },
    {
        'name': 'mat_solid:gesture'
    },
    {
        'name': 'mat_solid:get_app'
    },
    {
        'name': 'mat_solid:gif'
    },
    {
        'name': 'mat_solid:gif_box'
    },
    {
        'name': 'mat_solid:girl'
    },
    {
        'name': 'mat_solid:gite'
    },
    {
        'name': 'mat_solid:golf_course'
    },
    {
        'name': 'mat_solid:gpp_bad'
    },
    {
        'name': 'mat_solid:gpp_good'
    },
    {
        'name': 'mat_solid:gpp_maybe'
    },
    {
        'name': 'mat_solid:gps_fixed'
    },
    {
        'name': 'mat_solid:gps_not_fixed'
    },
    {
        'name': 'mat_solid:gps_off'
    },
    {
        'name': 'mat_solid:grade'
    },
    {
        'name': 'mat_solid:gradient'
    },
    {
        'name': 'mat_solid:grading'
    },
    {
        'name': 'mat_solid:grain'
    },
    {
        'name': 'mat_solid:graphic_eq'
    },
    {
        'name': 'mat_solid:grass'
    },
    {
        'name': 'mat_solid:grid_3x3'
    },
    {
        'name': 'mat_solid:grid_4x4'
    },
    {
        'name': 'mat_solid:grid_goldenratio'
    },
    {
        'name': 'mat_solid:grid_off'
    },
    {
        'name': 'mat_solid:grid_on'
    },
    {
        'name': 'mat_solid:grid_view'
    },
    {
        'name': 'mat_solid:group'
    },
    {
        'name': 'mat_solid:groups'
    },
    {
        'name': 'mat_solid:groups_2'
    },
    {
        'name': 'mat_solid:groups_3'
    },
    {
        'name': 'mat_solid:group_add'
    },
    {
        'name': 'mat_solid:group_off'
    },
    {
        'name': 'mat_solid:group_remove'
    },
    {
        'name': 'mat_solid:group_work'
    },
    {
        'name': 'mat_solid:g_mobiledata'
    },
    {
        'name': 'mat_solid:g_translate'
    },
    {
        'name': 'mat_solid:hail'
    },
    {
        'name': 'mat_solid:handshake'
    },
    {
        'name': 'mat_solid:handyman'
    },
    {
        'name': 'mat_solid:hardware'
    },
    {
        'name': 'mat_solid:hd'
    },
    {
        'name': 'mat_solid:hdr_auto'
    },
    {
        'name': 'mat_solid:hdr_auto_select'
    },
    {
        'name': 'mat_solid:hdr_enhanced_select'
    },
    {
        'name': 'mat_solid:hdr_off'
    },
    {
        'name': 'mat_solid:hdr_off_select'
    },
    {
        'name': 'mat_solid:hdr_on'
    },
    {
        'name': 'mat_solid:hdr_on_select'
    },
    {
        'name': 'mat_solid:hdr_plus'
    },
    {
        'name': 'mat_solid:hdr_strong'
    },
    {
        'name': 'mat_solid:hdr_weak'
    },
    {
        'name': 'mat_solid:headphones'
    },
    {
        'name': 'mat_solid:headphones_battery'
    },
    {
        'name': 'mat_solid:headset'
    },
    {
        'name': 'mat_solid:headset_mic'
    },
    {
        'name': 'mat_solid:headset_off'
    },
    {
        'name': 'mat_solid:healing'
    },
    {
        'name': 'mat_solid:health_and_safety'
    },
    {
        'name': 'mat_solid:hearing'
    },
    {
        'name': 'mat_solid:hearing_disabled'
    },
    {
        'name': 'mat_solid:heart_broken'
    },
    {
        'name': 'mat_solid:heat_pump'
    },
    {
        'name': 'mat_solid:height'
    },
    {
        'name': 'mat_solid:help'
    },
    {
        'name': 'mat_solid:help_center'
    },
    {
        'name': 'mat_solid:help_outline'
    },
    {
        'name': 'mat_solid:hevc'
    },
    {
        'name': 'mat_solid:hexagon'
    },
    {
        'name': 'mat_solid:hide_image'
    },
    {
        'name': 'mat_solid:hide_source'
    },
    {
        'name': 'mat_solid:highlight'
    },
    {
        'name': 'mat_solid:highlight_alt'
    },
    {
        'name': 'mat_solid:highlight_off'
    },
    {
        'name': 'mat_solid:high_quality'
    },
    {
        'name': 'mat_solid:hiking'
    },
    {
        'name': 'mat_solid:history'
    },
    {
        'name': 'mat_solid:history_edu'
    },
    {
        'name': 'mat_solid:history_toggle_off'
    },
    {
        'name': 'mat_solid:hive'
    },
    {
        'name': 'mat_solid:hls'
    },
    {
        'name': 'mat_solid:hls_off'
    },
    {
        'name': 'mat_solid:holiday_village'
    },
    {
        'name': 'mat_solid:home'
    },
    {
        'name': 'mat_solid:home_max'
    },
    {
        'name': 'mat_solid:home_mini'
    },
    {
        'name': 'mat_solid:home_repair_service'
    },
    {
        'name': 'mat_solid:home_work'
    },
    {
        'name': 'mat_solid:horizontal_distribute'
    },
    {
        'name': 'mat_solid:horizontal_rule'
    },
    {
        'name': 'mat_solid:horizontal_split'
    },
    {
        'name': 'mat_solid:hotel'
    },
    {
        'name': 'mat_solid:hotel_class'
    },
    {
        'name': 'mat_solid:hot_tub'
    },
    {
        'name': 'mat_solid:hourglass_bottom'
    },
    {
        'name': 'mat_solid:hourglass_disabled'
    },
    {
        'name': 'mat_solid:hourglass_empty'
    },
    {
        'name': 'mat_solid:hourglass_full'
    },
    {
        'name': 'mat_solid:hourglass_top'
    },
    {
        'name': 'mat_solid:house'
    },
    {
        'name': 'mat_solid:houseboat'
    },
    {
        'name': 'mat_solid:house_siding'
    },
    {
        'name': 'mat_solid:how_to_reg'
    },
    {
        'name': 'mat_solid:how_to_vote'
    },
    {
        'name': 'mat_solid:html'
    },
    {
        'name': 'mat_solid:http'
    },
    {
        'name': 'mat_solid:https'
    },
    {
        'name': 'mat_solid:hub'
    },
    {
        'name': 'mat_solid:hvac'
    },
    {
        'name': 'mat_solid:h_mobiledata'
    },
    {
        'name': 'mat_solid:h_plus_mobiledata'
    },
    {
        'name': 'mat_solid:icecream'
    },
    {
        'name': 'mat_solid:ice_skating'
    },
    {
        'name': 'mat_solid:image'
    },
    {
        'name': 'mat_solid:imagesearch_roller'
    },
    {
        'name': 'mat_solid:image_aspect_ratio'
    },
    {
        'name': 'mat_solid:image_not_supported'
    },
    {
        'name': 'mat_solid:image_search'
    },
    {
        'name': 'mat_solid:important_devices'
    },
    {
        'name': 'mat_solid:import_contacts'
    },
    {
        'name': 'mat_solid:import_export'
    },
    {
        'name': 'mat_solid:inbox'
    },
    {
        'name': 'mat_solid:incomplete_circle'
    },
    {
        'name': 'mat_solid:indeterminate_check_box'
    },
    {
        'name': 'mat_solid:info'
    },
    {
        'name': 'mat_solid:input'
    },
    {
        'name': 'mat_solid:insert_chart'
    },
    {
        'name': 'mat_solid:insert_chart_outlined'
    },
    {
        'name': 'mat_solid:insert_comment'
    },
    {
        'name': 'mat_solid:insert_drive_file'
    },
    {
        'name': 'mat_solid:insert_emoticon'
    },
    {
        'name': 'mat_solid:insert_invitation'
    },
    {
        'name': 'mat_solid:insert_link'
    },
    {
        'name': 'mat_solid:insert_page_break'
    },
    {
        'name': 'mat_solid:insert_photo'
    },
    {
        'name': 'mat_solid:insights'
    },
    {
        'name': 'mat_solid:install_desktop'
    },
    {
        'name': 'mat_solid:install_mobile'
    },
    {
        'name': 'mat_solid:integration_instructions'
    },
    {
        'name': 'mat_solid:interests'
    },
    {
        'name': 'mat_solid:interpreter_mode'
    },
    {
        'name': 'mat_solid:inventory'
    },
    {
        'name': 'mat_solid:inventory_2'
    },
    {
        'name': 'mat_solid:invert_colors'
    },
    {
        'name': 'mat_solid:invert_colors_off'
    },
    {
        'name': 'mat_solid:ios_share'
    },
    {
        'name': 'mat_solid:iron'
    },
    {
        'name': 'mat_solid:iso'
    },
    {
        'name': 'mat_solid:javascript'
    },
    {
        'name': 'mat_solid:join_full'
    },
    {
        'name': 'mat_solid:join_inner'
    },
    {
        'name': 'mat_solid:join_left'
    },
    {
        'name': 'mat_solid:join_right'
    },
    {
        'name': 'mat_solid:kayaking'
    },
    {
        'name': 'mat_solid:kebab_dining'
    },
    {
        'name': 'mat_solid:key'
    },
    {
        'name': 'mat_solid:keyboard'
    },
    {
        'name': 'mat_solid:keyboard_alt'
    },
    {
        'name': 'mat_solid:keyboard_arrow_down'
    },
    {
        'name': 'mat_solid:keyboard_arrow_left'
    },
    {
        'name': 'mat_solid:keyboard_arrow_right'
    },
    {
        'name': 'mat_solid:keyboard_arrow_up'
    },
    {
        'name': 'mat_solid:keyboard_backspace'
    },
    {
        'name': 'mat_solid:keyboard_capslock'
    },
    {
        'name': 'mat_solid:keyboard_command_key'
    },
    {
        'name': 'mat_solid:keyboard_control_key'
    },
    {
        'name': 'mat_solid:keyboard_double_arrow_down'
    },
    {
        'name': 'mat_solid:keyboard_double_arrow_left'
    },
    {
        'name': 'mat_solid:keyboard_double_arrow_right'
    },
    {
        'name': 'mat_solid:keyboard_double_arrow_up'
    },
    {
        'name': 'mat_solid:keyboard_hide'
    },
    {
        'name': 'mat_solid:keyboard_option_key'
    },
    {
        'name': 'mat_solid:keyboard_return'
    },
    {
        'name': 'mat_solid:keyboard_tab'
    },
    {
        'name': 'mat_solid:keyboard_voice'
    },
    {
        'name': 'mat_solid:key_off'
    },
    {
        'name': 'mat_solid:king_bed'
    },
    {
        'name': 'mat_solid:kitchen'
    },
    {
        'name': 'mat_solid:kitesurfing'
    },
    {
        'name': 'mat_solid:label'
    },
    {
        'name': 'mat_solid:label_important'
    },
    {
        'name': 'mat_solid:label_off'
    },
    {
        'name': 'mat_solid:lan'
    },
    {
        'name': 'mat_solid:landscape'
    },
    {
        'name': 'mat_solid:landslide'
    },
    {
        'name': 'mat_solid:language'
    },
    {
        'name': 'mat_solid:laptop'
    },
    {
        'name': 'mat_solid:laptop_chromebook'
    },
    {
        'name': 'mat_solid:laptop_mac'
    },
    {
        'name': 'mat_solid:laptop_windows'
    },
    {
        'name': 'mat_solid:last_page'
    },
    {
        'name': 'mat_solid:launch'
    },
    {
        'name': 'mat_solid:layers'
    },
    {
        'name': 'mat_solid:layers_clear'
    },
    {
        'name': 'mat_solid:leaderboard'
    },
    {
        'name': 'mat_solid:leak_add'
    },
    {
        'name': 'mat_solid:leak_remove'
    },
    {
        'name': 'mat_solid:legend_toggle'
    },
    {
        'name': 'mat_solid:lens'
    },
    {
        'name': 'mat_solid:lens_blur'
    },
    {
        'name': 'mat_solid:library_add'
    },
    {
        'name': 'mat_solid:library_add_check'
    },
    {
        'name': 'mat_solid:library_books'
    },
    {
        'name': 'mat_solid:library_music'
    },
    {
        'name': 'mat_solid:light'
    },
    {
        'name': 'mat_solid:lightbulb'
    },
    {
        'name': 'mat_solid:lightbulb_circle'
    },
    {
        'name': 'mat_solid:light_mode'
    },
    {
        'name': 'mat_solid:linear_scale'
    },
    {
        'name': 'mat_solid:line_axis'
    },
    {
        'name': 'mat_solid:line_style'
    },
    {
        'name': 'mat_solid:line_weight'
    },
    {
        'name': 'mat_solid:link'
    },
    {
        'name': 'mat_solid:linked_camera'
    },
    {
        'name': 'mat_solid:link_off'
    },
    {
        'name': 'mat_solid:liquor'
    },
    {
        'name': 'mat_solid:list'
    },
    {
        'name': 'mat_solid:list_alt'
    },
    {
        'name': 'mat_solid:live_help'
    },
    {
        'name': 'mat_solid:live_tv'
    },
    {
        'name': 'mat_solid:living'
    },
    {
        'name': 'mat_solid:local_activity'
    },
    {
        'name': 'mat_solid:local_airport'
    },
    {
        'name': 'mat_solid:local_atm'
    },
    {
        'name': 'mat_solid:local_bar'
    },
    {
        'name': 'mat_solid:local_cafe'
    },
    {
        'name': 'mat_solid:local_car_wash'
    },
    {
        'name': 'mat_solid:local_convenience_store'
    },
    {
        'name': 'mat_solid:local_dining'
    },
    {
        'name': 'mat_solid:local_drink'
    },
    {
        'name': 'mat_solid:local_fire_department'
    },
    {
        'name': 'mat_solid:local_florist'
    },
    {
        'name': 'mat_solid:local_gas_station'
    },
    {
        'name': 'mat_solid:local_grocery_store'
    },
    {
        'name': 'mat_solid:local_hospital'
    },
    {
        'name': 'mat_solid:local_hotel'
    },
    {
        'name': 'mat_solid:local_laundry_service'
    },
    {
        'name': 'mat_solid:local_library'
    },
    {
        'name': 'mat_solid:local_mall'
    },
    {
        'name': 'mat_solid:local_movies'
    },
    {
        'name': 'mat_solid:local_offer'
    },
    {
        'name': 'mat_solid:local_parking'
    },
    {
        'name': 'mat_solid:local_pharmacy'
    },
    {
        'name': 'mat_solid:local_phone'
    },
    {
        'name': 'mat_solid:local_pizza'
    },
    {
        'name': 'mat_solid:local_play'
    },
    {
        'name': 'mat_solid:local_police'
    },
    {
        'name': 'mat_solid:local_post_office'
    },
    {
        'name': 'mat_solid:local_printshop'
    },
    {
        'name': 'mat_solid:local_see'
    },
    {
        'name': 'mat_solid:local_shipping'
    },
    {
        'name': 'mat_solid:local_taxi'
    },
    {
        'name': 'mat_solid:location_city'
    },
    {
        'name': 'mat_solid:location_disabled'
    },
    {
        'name': 'mat_solid:location_off'
    },
    {
        'name': 'mat_solid:location_on'
    },
    {
        'name': 'mat_solid:location_searching'
    },
    {
        'name': 'mat_solid:lock'
    },
    {
        'name': 'mat_solid:lock_clock'
    },
    {
        'name': 'mat_solid:lock_open'
    },
    {
        'name': 'mat_solid:lock_person'
    },
    {
        'name': 'mat_solid:lock_reset'
    },
    {
        'name': 'mat_solid:login'
    },
    {
        'name': 'mat_solid:logout'
    },
    {
        'name': 'mat_solid:logo_dev'
    },
    {
        'name': 'mat_solid:looks'
    },
    {
        'name': 'mat_solid:looks_3'
    },
    {
        'name': 'mat_solid:looks_4'
    },
    {
        'name': 'mat_solid:looks_5'
    },
    {
        'name': 'mat_solid:looks_6'
    },
    {
        'name': 'mat_solid:looks_one'
    },
    {
        'name': 'mat_solid:looks_two'
    },
    {
        'name': 'mat_solid:loop'
    },
    {
        'name': 'mat_solid:loupe'
    },
    {
        'name': 'mat_solid:low_priority'
    },
    {
        'name': 'mat_solid:loyalty'
    },
    {
        'name': 'mat_solid:lte_mobiledata'
    },
    {
        'name': 'mat_solid:lte_plus_mobiledata'
    },
    {
        'name': 'mat_solid:luggage'
    },
    {
        'name': 'mat_solid:lunch_dining'
    },
    {
        'name': 'mat_solid:lyrics'
    },
    {
        'name': 'mat_solid:macro_off'
    },
    {
        'name': 'mat_solid:mail'
    },
    {
        'name': 'mat_solid:mail_lock'
    },
    {
        'name': 'mat_solid:mail_outline'
    },
    {
        'name': 'mat_solid:male'
    },
    {
        'name': 'mat_solid:man'
    },
    {
        'name': 'mat_solid:manage_accounts'
    },
    {
        'name': 'mat_solid:manage_history'
    },
    {
        'name': 'mat_solid:manage_search'
    },
    {
        'name': 'mat_solid:man_2'
    },
    {
        'name': 'mat_solid:man_3'
    },
    {
        'name': 'mat_solid:man_4'
    },
    {
        'name': 'mat_solid:map'
    },
    {
        'name': 'mat_solid:maps_home_work'
    },
    {
        'name': 'mat_solid:maps_ugc'
    },
    {
        'name': 'mat_solid:margin'
    },
    {
        'name': 'mat_solid:markunread'
    },
    {
        'name': 'mat_solid:markunread_mailbox'
    },
    {
        'name': 'mat_solid:mark_as_unread'
    },
    {
        'name': 'mat_solid:mark_chat_read'
    },
    {
        'name': 'mat_solid:mark_chat_unread'
    },
    {
        'name': 'mat_solid:mark_email_read'
    },
    {
        'name': 'mat_solid:mark_email_unread'
    },
    {
        'name': 'mat_solid:mark_unread_chat_alt'
    },
    {
        'name': 'mat_solid:masks'
    },
    {
        'name': 'mat_solid:maximize'
    },
    {
        'name': 'mat_solid:mediation'
    },
    {
        'name': 'mat_solid:media_bluetooth_off'
    },
    {
        'name': 'mat_solid:media_bluetooth_on'
    },
    {
        'name': 'mat_solid:medical_information'
    },
    {
        'name': 'mat_solid:medical_services'
    },
    {
        'name': 'mat_solid:medication'
    },
    {
        'name': 'mat_solid:medication_liquid'
    },
    {
        'name': 'mat_solid:meeting_room'
    },
    {
        'name': 'mat_solid:memory'
    },
    {
        'name': 'mat_solid:menu'
    },
    {
        'name': 'mat_solid:menu_book'
    },
    {
        'name': 'mat_solid:menu_open'
    },
    {
        'name': 'mat_solid:merge'
    },
    {
        'name': 'mat_solid:merge_type'
    },
    {
        'name': 'mat_solid:message'
    },
    {
        'name': 'mat_solid:mic'
    },
    {
        'name': 'mat_solid:microwave'
    },
    {
        'name': 'mat_solid:mic_external_off'
    },
    {
        'name': 'mat_solid:mic_external_on'
    },
    {
        'name': 'mat_solid:mic_none'
    },
    {
        'name': 'mat_solid:mic_off'
    },
    {
        'name': 'mat_solid:military_tech'
    },
    {
        'name': 'mat_solid:minimize'
    },
    {
        'name': 'mat_solid:minor_crash'
    },
    {
        'name': 'mat_solid:miscellaneous_services'
    },
    {
        'name': 'mat_solid:missed_video_call'
    },
    {
        'name': 'mat_solid:mms'
    },
    {
        'name': 'mat_solid:mobiledata_off'
    },
    {
        'name': 'mat_solid:mobile_friendly'
    },
    {
        'name': 'mat_solid:mobile_off'
    },
    {
        'name': 'mat_solid:mobile_screen_share'
    },
    {
        'name': 'mat_solid:mode'
    },
    {
        'name': 'mat_solid:model_training'
    },
    {
        'name': 'mat_solid:mode_comment'
    },
    {
        'name': 'mat_solid:mode_edit'
    },
    {
        'name': 'mat_solid:mode_edit_outline'
    },
    {
        'name': 'mat_solid:mode_fan_off'
    },
    {
        'name': 'mat_solid:mode_night'
    },
    {
        'name': 'mat_solid:mode_of_travel'
    },
    {
        'name': 'mat_solid:mode_standby'
    },
    {
        'name': 'mat_solid:monetization_on'
    },
    {
        'name': 'mat_solid:money'
    },
    {
        'name': 'mat_solid:money_off'
    },
    {
        'name': 'mat_solid:money_off_csred'
    },
    {
        'name': 'mat_solid:monitor'
    },
    {
        'name': 'mat_solid:monitor_heart'
    },
    {
        'name': 'mat_solid:monitor_weight'
    },
    {
        'name': 'mat_solid:monochrome_photos'
    },
    {
        'name': 'mat_solid:mood'
    },
    {
        'name': 'mat_solid:mood_bad'
    },
    {
        'name': 'mat_solid:moped'
    },
    {
        'name': 'mat_solid:more'
    },
    {
        'name': 'mat_solid:more_horiz'
    },
    {
        'name': 'mat_solid:more_time'
    },
    {
        'name': 'mat_solid:more_vert'
    },
    {
        'name': 'mat_solid:mosque'
    },
    {
        'name': 'mat_solid:motion_photos_auto'
    },
    {
        'name': 'mat_solid:motion_photos_off'
    },
    {
        'name': 'mat_solid:motion_photos_on'
    },
    {
        'name': 'mat_solid:motion_photos_pause'
    },
    {
        'name': 'mat_solid:motion_photos_paused'
    },
    {
        'name': 'mat_solid:mouse'
    },
    {
        'name': 'mat_solid:move_down'
    },
    {
        'name': 'mat_solid:move_to_inbox'
    },
    {
        'name': 'mat_solid:move_up'
    },
    {
        'name': 'mat_solid:movie'
    },
    {
        'name': 'mat_solid:movie_creation'
    },
    {
        'name': 'mat_solid:movie_filter'
    },
    {
        'name': 'mat_solid:moving'
    },
    {
        'name': 'mat_solid:mp'
    },
    {
        'name': 'mat_solid:multiline_chart'
    },
    {
        'name': 'mat_solid:multiple_stop'
    },
    {
        'name': 'mat_solid:museum'
    },
    {
        'name': 'mat_solid:music_note'
    },
    {
        'name': 'mat_solid:music_off'
    },
    {
        'name': 'mat_solid:music_video'
    },
    {
        'name': 'mat_solid:my_location'
    },
    {
        'name': 'mat_solid:nat'
    },
    {
        'name': 'mat_solid:nature'
    },
    {
        'name': 'mat_solid:nature_people'
    },
    {
        'name': 'mat_solid:navigate_before'
    },
    {
        'name': 'mat_solid:navigate_next'
    },
    {
        'name': 'mat_solid:navigation'
    },
    {
        'name': 'mat_solid:nearby_error'
    },
    {
        'name': 'mat_solid:nearby_off'
    },
    {
        'name': 'mat_solid:near_me'
    },
    {
        'name': 'mat_solid:near_me_disabled'
    },
    {
        'name': 'mat_solid:nest_cam_wired_stand'
    },
    {
        'name': 'mat_solid:network_cell'
    },
    {
        'name': 'mat_solid:network_check'
    },
    {
        'name': 'mat_solid:network_locked'
    },
    {
        'name': 'mat_solid:network_ping'
    },
    {
        'name': 'mat_solid:network_wifi'
    },
    {
        'name': 'mat_solid:network_wifi_1_bar'
    },
    {
        'name': 'mat_solid:network_wifi_2_bar'
    },
    {
        'name': 'mat_solid:network_wifi_3_bar'
    },
    {
        'name': 'mat_solid:newspaper'
    },
    {
        'name': 'mat_solid:new_label'
    },
    {
        'name': 'mat_solid:new_releases'
    },
    {
        'name': 'mat_solid:next_plan'
    },
    {
        'name': 'mat_solid:next_week'
    },
    {
        'name': 'mat_solid:nfc'
    },
    {
        'name': 'mat_solid:nightlife'
    },
    {
        'name': 'mat_solid:nightlight'
    },
    {
        'name': 'mat_solid:nightlight_round'
    },
    {
        'name': 'mat_solid:nights_stay'
    },
    {
        'name': 'mat_solid:night_shelter'
    },
    {
        'name': 'mat_solid:noise_aware'
    },
    {
        'name': 'mat_solid:noise_control_off'
    },
    {
        'name': 'mat_solid:nordic_walking'
    },
    {
        'name': 'mat_solid:north'
    },
    {
        'name': 'mat_solid:north_east'
    },
    {
        'name': 'mat_solid:north_west'
    },
    {
        'name': 'mat_solid:note'
    },
    {
        'name': 'mat_solid:notes'
    },
    {
        'name': 'mat_solid:note_add'
    },
    {
        'name': 'mat_solid:note_alt'
    },
    {
        'name': 'mat_solid:notifications'
    },
    {
        'name': 'mat_solid:notifications_active'
    },
    {
        'name': 'mat_solid:notifications_none'
    },
    {
        'name': 'mat_solid:notifications_off'
    },
    {
        'name': 'mat_solid:notifications_paused'
    },
    {
        'name': 'mat_solid:notification_add'
    },
    {
        'name': 'mat_solid:notification_important'
    },
    {
        'name': 'mat_solid:not_accessible'
    },
    {
        'name': 'mat_solid:not_interested'
    },
    {
        'name': 'mat_solid:not_listed_location'
    },
    {
        'name': 'mat_solid:not_started'
    },
    {
        'name': 'mat_solid:no_accounts'
    },
    {
        'name': 'mat_solid:no_adult_content'
    },
    {
        'name': 'mat_solid:no_backpack'
    },
    {
        'name': 'mat_solid:no_cell'
    },
    {
        'name': 'mat_solid:no_crash'
    },
    {
        'name': 'mat_solid:no_drinks'
    },
    {
        'name': 'mat_solid:no_encryption'
    },
    {
        'name': 'mat_solid:no_encryption_gmailerrorred'
    },
    {
        'name': 'mat_solid:no_flash'
    },
    {
        'name': 'mat_solid:no_food'
    },
    {
        'name': 'mat_solid:no_luggage'
    },
    {
        'name': 'mat_solid:no_meals'
    },
    {
        'name': 'mat_solid:no_meeting_room'
    },
    {
        'name': 'mat_solid:no_photography'
    },
    {
        'name': 'mat_solid:no_sim'
    },
    {
        'name': 'mat_solid:no_stroller'
    },
    {
        'name': 'mat_solid:no_transfer'
    },
    {
        'name': 'mat_solid:numbers'
    },
    {
        'name': 'mat_solid:offline_bolt'
    },
    {
        'name': 'mat_solid:offline_pin'
    },
    {
        'name': 'mat_solid:offline_share'
    },
    {
        'name': 'mat_solid:oil_barrel'
    },
    {
        'name': 'mat_solid:ondemand_video'
    },
    {
        'name': 'mat_solid:online_prediction'
    },
    {
        'name': 'mat_solid:on_device_training'
    },
    {
        'name': 'mat_solid:opacity'
    },
    {
        'name': 'mat_solid:open_in_browser'
    },
    {
        'name': 'mat_solid:open_in_full'
    },
    {
        'name': 'mat_solid:open_in_new'
    },
    {
        'name': 'mat_solid:open_in_new_off'
    },
    {
        'name': 'mat_solid:open_with'
    },
    {
        'name': 'mat_solid:other_houses'
    },
    {
        'name': 'mat_solid:outbound'
    },
    {
        'name': 'mat_solid:outbox'
    },
    {
        'name': 'mat_solid:outdoor_grill'
    },
    {
        'name': 'mat_solid:outlet'
    },
    {
        'name': 'mat_solid:outlined_flag'
    },
    {
        'name': 'mat_solid:output'
    },
    {
        'name': 'mat_solid:padding'
    },
    {
        'name': 'mat_solid:pages'
    },
    {
        'name': 'mat_solid:pageview'
    },
    {
        'name': 'mat_solid:paid'
    },
    {
        'name': 'mat_solid:palette'
    },
    {
        'name': 'mat_solid:panorama'
    },
    {
        'name': 'mat_solid:panorama_fish_eye'
    },
    {
        'name': 'mat_solid:panorama_horizontal'
    },
    {
        'name': 'mat_solid:panorama_horizontal_select'
    },
    {
        'name': 'mat_solid:panorama_photosphere'
    },
    {
        'name': 'mat_solid:panorama_photosphere_select'
    },
    {
        'name': 'mat_solid:panorama_vertical'
    },
    {
        'name': 'mat_solid:panorama_vertical_select'
    },
    {
        'name': 'mat_solid:panorama_wide_angle'
    },
    {
        'name': 'mat_solid:panorama_wide_angle_select'
    },
    {
        'name': 'mat_solid:pan_tool'
    },
    {
        'name': 'mat_solid:pan_tool_alt'
    },
    {
        'name': 'mat_solid:paragliding'
    },
    {
        'name': 'mat_solid:park'
    },
    {
        'name': 'mat_solid:party_mode'
    },
    {
        'name': 'mat_solid:password'
    },
    {
        'name': 'mat_solid:pattern'
    },
    {
        'name': 'mat_solid:pause'
    },
    {
        'name': 'mat_solid:pause_circle'
    },
    {
        'name': 'mat_solid:pause_circle_filled'
    },
    {
        'name': 'mat_solid:pause_circle_outline'
    },
    {
        'name': 'mat_solid:pause_presentation'
    },
    {
        'name': 'mat_solid:payment'
    },
    {
        'name': 'mat_solid:payments'
    },
    {
        'name': 'mat_solid:pedal_bike'
    },
    {
        'name': 'mat_solid:pending'
    },
    {
        'name': 'mat_solid:pending_actions'
    },
    {
        'name': 'mat_solid:pentagon'
    },
    {
        'name': 'mat_solid:people'
    },
    {
        'name': 'mat_solid:people_alt'
    },
    {
        'name': 'mat_solid:people_outline'
    },
    {
        'name': 'mat_solid:percent'
    },
    {
        'name': 'mat_solid:perm_camera_mic'
    },
    {
        'name': 'mat_solid:perm_contact_calendar'
    },
    {
        'name': 'mat_solid:perm_data_setting'
    },
    {
        'name': 'mat_solid:perm_device_information'
    },
    {
        'name': 'mat_solid:perm_identity'
    },
    {
        'name': 'mat_solid:perm_media'
    },
    {
        'name': 'mat_solid:perm_phone_msg'
    },
    {
        'name': 'mat_solid:perm_scan_wifi'
    },
    {
        'name': 'mat_solid:person'
    },
    {
        'name': 'mat_solid:personal_injury'
    },
    {
        'name': 'mat_solid:personal_video'
    },
    {
        'name': 'mat_solid:person_2'
    },
    {
        'name': 'mat_solid:person_3'
    },
    {
        'name': 'mat_solid:person_4'
    },
    {
        'name': 'mat_solid:person_add'
    },
    {
        'name': 'mat_solid:person_add_alt'
    },
    {
        'name': 'mat_solid:person_add_alt_1'
    },
    {
        'name': 'mat_solid:person_add_disabled'
    },
    {
        'name': 'mat_solid:person_off'
    },
    {
        'name': 'mat_solid:person_outline'
    },
    {
        'name': 'mat_solid:person_pin'
    },
    {
        'name': 'mat_solid:person_pin_circle'
    },
    {
        'name': 'mat_solid:person_remove'
    },
    {
        'name': 'mat_solid:person_remove_alt_1'
    },
    {
        'name': 'mat_solid:person_search'
    },
    {
        'name': 'mat_solid:pest_control'
    },
    {
        'name': 'mat_solid:pest_control_rodent'
    },
    {
        'name': 'mat_solid:pets'
    },
    {
        'name': 'mat_solid:phishing'
    },
    {
        'name': 'mat_solid:phone'
    },
    {
        'name': 'mat_solid:phonelink'
    },
    {
        'name': 'mat_solid:phonelink_erase'
    },
    {
        'name': 'mat_solid:phonelink_lock'
    },
    {
        'name': 'mat_solid:phonelink_off'
    },
    {
        'name': 'mat_solid:phonelink_ring'
    },
    {
        'name': 'mat_solid:phonelink_setup'
    },
    {
        'name': 'mat_solid:phone_android'
    },
    {
        'name': 'mat_solid:phone_bluetooth_speaker'
    },
    {
        'name': 'mat_solid:phone_callback'
    },
    {
        'name': 'mat_solid:phone_disabled'
    },
    {
        'name': 'mat_solid:phone_enabled'
    },
    {
        'name': 'mat_solid:phone_forwarded'
    },
    {
        'name': 'mat_solid:phone_iphone'
    },
    {
        'name': 'mat_solid:phone_locked'
    },
    {
        'name': 'mat_solid:phone_missed'
    },
    {
        'name': 'mat_solid:phone_paused'
    },
    {
        'name': 'mat_solid:photo'
    },
    {
        'name': 'mat_solid:photo_album'
    },
    {
        'name': 'mat_solid:photo_camera'
    },
    {
        'name': 'mat_solid:photo_camera_back'
    },
    {
        'name': 'mat_solid:photo_camera_front'
    },
    {
        'name': 'mat_solid:photo_filter'
    },
    {
        'name': 'mat_solid:photo_library'
    },
    {
        'name': 'mat_solid:photo_size_select_actual'
    },
    {
        'name': 'mat_solid:photo_size_select_large'
    },
    {
        'name': 'mat_solid:photo_size_select_small'
    },
    {
        'name': 'mat_solid:php'
    },
    {
        'name': 'mat_solid:piano'
    },
    {
        'name': 'mat_solid:piano_off'
    },
    {
        'name': 'mat_solid:picture_as_pdf'
    },
    {
        'name': 'mat_solid:picture_in_picture'
    },
    {
        'name': 'mat_solid:picture_in_picture_alt'
    },
    {
        'name': 'mat_solid:pie_chart'
    },
    {
        'name': 'mat_solid:pie_chart_outline'
    },
    {
        'name': 'mat_solid:pin'
    },
    {
        'name': 'mat_solid:pinch'
    },
    {
        'name': 'mat_solid:pin_drop'
    },
    {
        'name': 'mat_solid:pin_end'
    },
    {
        'name': 'mat_solid:pin_invoke'
    },
    {
        'name': 'mat_solid:pivot_table_chart'
    },
    {
        'name': 'mat_solid:pix'
    },
    {
        'name': 'mat_solid:place'
    },
    {
        'name': 'mat_solid:plagiarism'
    },
    {
        'name': 'mat_solid:playlist_add'
    },
    {
        'name': 'mat_solid:playlist_add_check'
    },
    {
        'name': 'mat_solid:playlist_add_check_circle'
    },
    {
        'name': 'mat_solid:playlist_add_circle'
    },
    {
        'name': 'mat_solid:playlist_play'
    },
    {
        'name': 'mat_solid:playlist_remove'
    },
    {
        'name': 'mat_solid:play_arrow'
    },
    {
        'name': 'mat_solid:play_circle'
    },
    {
        'name': 'mat_solid:play_circle_filled'
    },
    {
        'name': 'mat_solid:play_circle_outline'
    },
    {
        'name': 'mat_solid:play_disabled'
    },
    {
        'name': 'mat_solid:play_for_work'
    },
    {
        'name': 'mat_solid:play_lesson'
    },
    {
        'name': 'mat_solid:plumbing'
    },
    {
        'name': 'mat_solid:plus_one'
    },
    {
        'name': 'mat_solid:podcasts'
    },
    {
        'name': 'mat_solid:point_of_sale'
    },
    {
        'name': 'mat_solid:policy'
    },
    {
        'name': 'mat_solid:poll'
    },
    {
        'name': 'mat_solid:polyline'
    },
    {
        'name': 'mat_solid:polymer'
    },
    {
        'name': 'mat_solid:pool'
    },
    {
        'name': 'mat_solid:portable_wifi_off'
    },
    {
        'name': 'mat_solid:portrait'
    },
    {
        'name': 'mat_solid:post_add'
    },
    {
        'name': 'mat_solid:power'
    },
    {
        'name': 'mat_solid:power_input'
    },
    {
        'name': 'mat_solid:power_off'
    },
    {
        'name': 'mat_solid:power_settings_new'
    },
    {
        'name': 'mat_solid:precision_manufacturing'
    },
    {
        'name': 'mat_solid:pregnant_woman'
    },
    {
        'name': 'mat_solid:present_to_all'
    },
    {
        'name': 'mat_solid:preview'
    },
    {
        'name': 'mat_solid:price_change'
    },
    {
        'name': 'mat_solid:price_check'
    },
    {
        'name': 'mat_solid:print'
    },
    {
        'name': 'mat_solid:print_disabled'
    },
    {
        'name': 'mat_solid:priority_high'
    },
    {
        'name': 'mat_solid:privacy_tip'
    },
    {
        'name': 'mat_solid:private_connectivity'
    },
    {
        'name': 'mat_solid:production_quantity_limits'
    },
    {
        'name': 'mat_solid:propane'
    },
    {
        'name': 'mat_solid:propane_tank'
    },
    {
        'name': 'mat_solid:psychology'
    },
    {
        'name': 'mat_solid:psychology_alt'
    },
    {
        'name': 'mat_solid:public'
    },
    {
        'name': 'mat_solid:public_off'
    },
    {
        'name': 'mat_solid:publish'
    },
    {
        'name': 'mat_solid:published_with_changes'
    },
    {
        'name': 'mat_solid:punch_clock'
    },
    {
        'name': 'mat_solid:push_pin'
    },
    {
        'name': 'mat_solid:qr_code'
    },
    {
        'name': 'mat_solid:qr_code_2'
    },
    {
        'name': 'mat_solid:qr_code_scanner'
    },
    {
        'name': 'mat_solid:query_builder'
    },
    {
        'name': 'mat_solid:query_stats'
    },
    {
        'name': 'mat_solid:question_answer'
    },
    {
        'name': 'mat_solid:question_mark'
    },
    {
        'name': 'mat_solid:queue'
    },
    {
        'name': 'mat_solid:queue_music'
    },
    {
        'name': 'mat_solid:queue_play_next'
    },
    {
        'name': 'mat_solid:quickreply'
    },
    {
        'name': 'mat_solid:quiz'
    },
    {
        'name': 'mat_solid:radar'
    },
    {
        'name': 'mat_solid:radio'
    },
    {
        'name': 'mat_solid:radio_button_checked'
    },
    {
        'name': 'mat_solid:radio_button_unchecked'
    },
    {
        'name': 'mat_solid:railway_alert'
    },
    {
        'name': 'mat_solid:ramen_dining'
    },
    {
        'name': 'mat_solid:ramp_left'
    },
    {
        'name': 'mat_solid:ramp_right'
    },
    {
        'name': 'mat_solid:rate_review'
    },
    {
        'name': 'mat_solid:raw_off'
    },
    {
        'name': 'mat_solid:raw_on'
    },
    {
        'name': 'mat_solid:read_more'
    },
    {
        'name': 'mat_solid:real_estate_agent'
    },
    {
        'name': 'mat_solid:receipt'
    },
    {
        'name': 'mat_solid:receipt_long'
    },
    {
        'name': 'mat_solid:recent_actors'
    },
    {
        'name': 'mat_solid:recommend'
    },
    {
        'name': 'mat_solid:record_voice_over'
    },
    {
        'name': 'mat_solid:rectangle'
    },
    {
        'name': 'mat_solid:recycling'
    },
    {
        'name': 'mat_solid:redeem'
    },
    {
        'name': 'mat_solid:redo'
    },
    {
        'name': 'mat_solid:reduce_capacity'
    },
    {
        'name': 'mat_solid:refresh'
    },
    {
        'name': 'mat_solid:remember_me'
    },
    {
        'name': 'mat_solid:remove'
    },
    {
        'name': 'mat_solid:remove_circle'
    },
    {
        'name': 'mat_solid:remove_circle_outline'
    },
    {
        'name': 'mat_solid:remove_done'
    },
    {
        'name': 'mat_solid:remove_from_queue'
    },
    {
        'name': 'mat_solid:remove_moderator'
    },
    {
        'name': 'mat_solid:remove_red_eye'
    },
    {
        'name': 'mat_solid:remove_road'
    },
    {
        'name': 'mat_solid:remove_shopping_cart'
    },
    {
        'name': 'mat_solid:reorder'
    },
    {
        'name': 'mat_solid:repartition'
    },
    {
        'name': 'mat_solid:repeat'
    },
    {
        'name': 'mat_solid:repeat_on'
    },
    {
        'name': 'mat_solid:repeat_one'
    },
    {
        'name': 'mat_solid:repeat_one_on'
    },
    {
        'name': 'mat_solid:replay'
    },
    {
        'name': 'mat_solid:replay_10'
    },
    {
        'name': 'mat_solid:replay_30'
    },
    {
        'name': 'mat_solid:replay_5'
    },
    {
        'name': 'mat_solid:replay_circle_filled'
    },
    {
        'name': 'mat_solid:reply'
    },
    {
        'name': 'mat_solid:reply_all'
    },
    {
        'name': 'mat_solid:report'
    },
    {
        'name': 'mat_solid:report_gmailerrorred'
    },
    {
        'name': 'mat_solid:report_off'
    },
    {
        'name': 'mat_solid:report_problem'
    },
    {
        'name': 'mat_solid:request_page'
    },
    {
        'name': 'mat_solid:request_quote'
    },
    {
        'name': 'mat_solid:reset_tv'
    },
    {
        'name': 'mat_solid:restart_alt'
    },
    {
        'name': 'mat_solid:restaurant'
    },
    {
        'name': 'mat_solid:restaurant_menu'
    },
    {
        'name': 'mat_solid:restore'
    },
    {
        'name': 'mat_solid:restore_from_trash'
    },
    {
        'name': 'mat_solid:restore_page'
    },
    {
        'name': 'mat_solid:reviews'
    },
    {
        'name': 'mat_solid:rice_bowl'
    },
    {
        'name': 'mat_solid:ring_volume'
    },
    {
        'name': 'mat_solid:rocket'
    },
    {
        'name': 'mat_solid:rocket_launch'
    },
    {
        'name': 'mat_solid:roller_shades'
    },
    {
        'name': 'mat_solid:roller_shades_closed'
    },
    {
        'name': 'mat_solid:roller_skating'
    },
    {
        'name': 'mat_solid:roofing'
    },
    {
        'name': 'mat_solid:room'
    },
    {
        'name': 'mat_solid:room_preferences'
    },
    {
        'name': 'mat_solid:room_service'
    },
    {
        'name': 'mat_solid:rotate_90_degrees_ccw'
    },
    {
        'name': 'mat_solid:rotate_90_degrees_cw'
    },
    {
        'name': 'mat_solid:rotate_left'
    },
    {
        'name': 'mat_solid:rotate_right'
    },
    {
        'name': 'mat_solid:roundabout_left'
    },
    {
        'name': 'mat_solid:roundabout_right'
    },
    {
        'name': 'mat_solid:rounded_corner'
    },
    {
        'name': 'mat_solid:route'
    },
    {
        'name': 'mat_solid:router'
    },
    {
        'name': 'mat_solid:rowing'
    },
    {
        'name': 'mat_solid:rss_feed'
    },
    {
        'name': 'mat_solid:rsvp'
    },
    {
        'name': 'mat_solid:rtt'
    },
    {
        'name': 'mat_solid:rule'
    },
    {
        'name': 'mat_solid:rule_folder'
    },
    {
        'name': 'mat_solid:running_with_errors'
    },
    {
        'name': 'mat_solid:run_circle'
    },
    {
        'name': 'mat_solid:rv_hookup'
    },
    {
        'name': 'mat_solid:r_mobiledata'
    },
    {
        'name': 'mat_solid:safety_check'
    },
    {
        'name': 'mat_solid:safety_divider'
    },
    {
        'name': 'mat_solid:sailing'
    },
    {
        'name': 'mat_solid:sanitizer'
    },
    {
        'name': 'mat_solid:satellite'
    },
    {
        'name': 'mat_solid:satellite_alt'
    },
    {
        'name': 'mat_solid:save'
    },
    {
        'name': 'mat_solid:saved_search'
    },
    {
        'name': 'mat_solid:save_alt'
    },
    {
        'name': 'mat_solid:save_as'
    },
    {
        'name': 'mat_solid:savings'
    },
    {
        'name': 'mat_solid:scale'
    },
    {
        'name': 'mat_solid:scanner'
    },
    {
        'name': 'mat_solid:scatter_plot'
    },
    {
        'name': 'mat_solid:schedule'
    },
    {
        'name': 'mat_solid:schedule_send'
    },
    {
        'name': 'mat_solid:schema'
    },
    {
        'name': 'mat_solid:school'
    },
    {
        'name': 'mat_solid:science'
    },
    {
        'name': 'mat_solid:score'
    },
    {
        'name': 'mat_solid:scoreboard'
    },
    {
        'name': 'mat_solid:screenshot'
    },
    {
        'name': 'mat_solid:screenshot_monitor'
    },
    {
        'name': 'mat_solid:screen_lock_landscape'
    },
    {
        'name': 'mat_solid:screen_lock_portrait'
    },
    {
        'name': 'mat_solid:screen_lock_rotation'
    },
    {
        'name': 'mat_solid:screen_rotation'
    },
    {
        'name': 'mat_solid:screen_rotation_alt'
    },
    {
        'name': 'mat_solid:screen_search_desktop'
    },
    {
        'name': 'mat_solid:screen_share'
    },
    {
        'name': 'mat_solid:scuba_diving'
    },
    {
        'name': 'mat_solid:sd'
    },
    {
        'name': 'mat_solid:sd_card'
    },
    {
        'name': 'mat_solid:sd_card_alert'
    },
    {
        'name': 'mat_solid:sd_storage'
    },
    {
        'name': 'mat_solid:search'
    },
    {
        'name': 'mat_solid:search_off'
    },
    {
        'name': 'mat_solid:security'
    },
    {
        'name': 'mat_solid:security_update'
    },
    {
        'name': 'mat_solid:security_update_good'
    },
    {
        'name': 'mat_solid:security_update_warning'
    },
    {
        'name': 'mat_solid:segment'
    },
    {
        'name': 'mat_solid:select_all'
    },
    {
        'name': 'mat_solid:self_improvement'
    },
    {
        'name': 'mat_solid:sell'
    },
    {
        'name': 'mat_solid:send'
    },
    {
        'name': 'mat_solid:send_and_archive'
    },
    {
        'name': 'mat_solid:send_time_extension'
    },
    {
        'name': 'mat_solid:send_to_mobile'
    },
    {
        'name': 'mat_solid:sensors'
    },
    {
        'name': 'mat_solid:sensors_off'
    },
    {
        'name': 'mat_solid:sensor_door'
    },
    {
        'name': 'mat_solid:sensor_occupied'
    },
    {
        'name': 'mat_solid:sensor_window'
    },
    {
        'name': 'mat_solid:sentiment_dissatisfied'
    },
    {
        'name': 'mat_solid:sentiment_neutral'
    },
    {
        'name': 'mat_solid:sentiment_satisfied'
    },
    {
        'name': 'mat_solid:sentiment_satisfied_alt'
    },
    {
        'name': 'mat_solid:sentiment_very_dissatisfied'
    },
    {
        'name': 'mat_solid:sentiment_very_satisfied'
    },
    {
        'name': 'mat_solid:settings'
    },
    {
        'name': 'mat_solid:settings_accessibility'
    },
    {
        'name': 'mat_solid:settings_applications'
    },
    {
        'name': 'mat_solid:settings_backup_restore'
    },
    {
        'name': 'mat_solid:settings_bluetooth'
    },
    {
        'name': 'mat_solid:settings_brightness'
    },
    {
        'name': 'mat_solid:settings_cell'
    },
    {
        'name': 'mat_solid:settings_ethernet'
    },
    {
        'name': 'mat_solid:settings_input_antenna'
    },
    {
        'name': 'mat_solid:settings_input_component'
    },
    {
        'name': 'mat_solid:settings_input_composite'
    },
    {
        'name': 'mat_solid:settings_input_hdmi'
    },
    {
        'name': 'mat_solid:settings_input_svideo'
    },
    {
        'name': 'mat_solid:settings_overscan'
    },
    {
        'name': 'mat_solid:settings_phone'
    },
    {
        'name': 'mat_solid:settings_power'
    },
    {
        'name': 'mat_solid:settings_remote'
    },
    {
        'name': 'mat_solid:settings_suggest'
    },
    {
        'name': 'mat_solid:settings_system_daydream'
    },
    {
        'name': 'mat_solid:settings_voice'
    },
    {
        'name': 'mat_solid:set_meal'
    },
    {
        'name': 'mat_solid:severe_cold'
    },
    {
        'name': 'mat_solid:shape_line'
    },
    {
        'name': 'mat_solid:share'
    },
    {
        'name': 'mat_solid:share_location'
    },
    {
        'name': 'mat_solid:shield'
    },
    {
        'name': 'mat_solid:shield_moon'
    },
    {
        'name': 'mat_solid:shop'
    },
    {
        'name': 'mat_solid:shopping_bag'
    },
    {
        'name': 'mat_solid:shopping_basket'
    },
    {
        'name': 'mat_solid:shopping_cart'
    },
    {
        'name': 'mat_solid:shopping_cart_checkout'
    },
    {
        'name': 'mat_solid:shop_2'
    },
    {
        'name': 'mat_solid:shop_two'
    },
    {
        'name': 'mat_solid:shortcut'
    },
    {
        'name': 'mat_solid:short_text'
    },
    {
        'name': 'mat_solid:shower'
    },
    {
        'name': 'mat_solid:show_chart'
    },
    {
        'name': 'mat_solid:shuffle'
    },
    {
        'name': 'mat_solid:shuffle_on'
    },
    {
        'name': 'mat_solid:shutter_speed'
    },
    {
        'name': 'mat_solid:sick'
    },
    {
        'name': 'mat_solid:signal_cellular_0_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_4_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_alt'
    },
    {
        'name': 'mat_solid:signal_cellular_alt_1_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_alt_2_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_connected_no_internet_0_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_connected_no_internet_4_bar'
    },
    {
        'name': 'mat_solid:signal_cellular_nodata'
    },
    {
        'name': 'mat_solid:signal_cellular_no_sim'
    },
    {
        'name': 'mat_solid:signal_cellular_null'
    },
    {
        'name': 'mat_solid:signal_cellular_off'
    },
    {
        'name': 'mat_solid:signal_wifi_0_bar'
    },
    {
        'name': 'mat_solid:signal_wifi_4_bar'
    },
    {
        'name': 'mat_solid:signal_wifi_4_bar_lock'
    },
    {
        'name': 'mat_solid:signal_wifi_bad'
    },
    {
        'name': 'mat_solid:signal_wifi_connected_no_internet_4'
    },
    {
        'name': 'mat_solid:signal_wifi_off'
    },
    {
        'name': 'mat_solid:signal_wifi_statusbar_4_bar'
    },
    {
        'name': 'mat_solid:signal_wifi_statusbar_connected_no_internet_4'
    },
    {
        'name': 'mat_solid:signal_wifi_statusbar_null'
    },
    {
        'name': 'mat_solid:signpost'
    },
    {
        'name': 'mat_solid:sign_language'
    },
    {
        'name': 'mat_solid:sim_card'
    },
    {
        'name': 'mat_solid:sim_card_alert'
    },
    {
        'name': 'mat_solid:sim_card_download'
    },
    {
        'name': 'mat_solid:single_bed'
    },
    {
        'name': 'mat_solid:sip'
    },
    {
        'name': 'mat_solid:skateboarding'
    },
    {
        'name': 'mat_solid:skip_next'
    },
    {
        'name': 'mat_solid:skip_previous'
    },
    {
        'name': 'mat_solid:sledding'
    },
    {
        'name': 'mat_solid:slideshow'
    },
    {
        'name': 'mat_solid:slow_motion_video'
    },
    {
        'name': 'mat_solid:smartphone'
    },
    {
        'name': 'mat_solid:smart_button'
    },
    {
        'name': 'mat_solid:smart_display'
    },
    {
        'name': 'mat_solid:smart_screen'
    },
    {
        'name': 'mat_solid:smart_toy'
    },
    {
        'name': 'mat_solid:smoke_free'
    },
    {
        'name': 'mat_solid:smoking_rooms'
    },
    {
        'name': 'mat_solid:sms'
    },
    {
        'name': 'mat_solid:sms_failed'
    },
    {
        'name': 'mat_solid:snippet_folder'
    },
    {
        'name': 'mat_solid:snooze'
    },
    {
        'name': 'mat_solid:snowboarding'
    },
    {
        'name': 'mat_solid:snowmobile'
    },
    {
        'name': 'mat_solid:snowshoeing'
    },
    {
        'name': 'mat_solid:soap'
    },
    {
        'name': 'mat_solid:social_distance'
    },
    {
        'name': 'mat_solid:solar_power'
    },
    {
        'name': 'mat_solid:sort'
    },
    {
        'name': 'mat_solid:sort_by_alpha'
    },
    {
        'name': 'mat_solid:sos'
    },
    {
        'name': 'mat_solid:soup_kitchen'
    },
    {
        'name': 'mat_solid:source'
    },
    {
        'name': 'mat_solid:south'
    },
    {
        'name': 'mat_solid:south_america'
    },
    {
        'name': 'mat_solid:south_east'
    },
    {
        'name': 'mat_solid:south_west'
    },
    {
        'name': 'mat_solid:spa'
    },
    {
        'name': 'mat_solid:space_bar'
    },
    {
        'name': 'mat_solid:space_dashboard'
    },
    {
        'name': 'mat_solid:spatial_audio'
    },
    {
        'name': 'mat_solid:spatial_audio_off'
    },
    {
        'name': 'mat_solid:spatial_tracking'
    },
    {
        'name': 'mat_solid:speaker'
    },
    {
        'name': 'mat_solid:speaker_group'
    },
    {
        'name': 'mat_solid:speaker_notes'
    },
    {
        'name': 'mat_solid:speaker_notes_off'
    },
    {
        'name': 'mat_solid:speaker_phone'
    },
    {
        'name': 'mat_solid:speed'
    },
    {
        'name': 'mat_solid:spellcheck'
    },
    {
        'name': 'mat_solid:splitscreen'
    },
    {
        'name': 'mat_solid:spoke'
    },
    {
        'name': 'mat_solid:sports'
    },
    {
        'name': 'mat_solid:sports_bar'
    },
    {
        'name': 'mat_solid:sports_baseball'
    },
    {
        'name': 'mat_solid:sports_basketball'
    },
    {
        'name': 'mat_solid:sports_cricket'
    },
    {
        'name': 'mat_solid:sports_esports'
    },
    {
        'name': 'mat_solid:sports_football'
    },
    {
        'name': 'mat_solid:sports_golf'
    },
    {
        'name': 'mat_solid:sports_gymnastics'
    },
    {
        'name': 'mat_solid:sports_handball'
    },
    {
        'name': 'mat_solid:sports_hockey'
    },
    {
        'name': 'mat_solid:sports_kabaddi'
    },
    {
        'name': 'mat_solid:sports_martial_arts'
    },
    {
        'name': 'mat_solid:sports_mma'
    },
    {
        'name': 'mat_solid:sports_motorsports'
    },
    {
        'name': 'mat_solid:sports_rugby'
    },
    {
        'name': 'mat_solid:sports_score'
    },
    {
        'name': 'mat_solid:sports_soccer'
    },
    {
        'name': 'mat_solid:sports_tennis'
    },
    {
        'name': 'mat_solid:sports_volleyball'
    },
    {
        'name': 'mat_solid:square'
    },
    {
        'name': 'mat_solid:square_foot'
    },
    {
        'name': 'mat_solid:ssid_chart'
    },
    {
        'name': 'mat_solid:stacked_bar_chart'
    },
    {
        'name': 'mat_solid:stacked_line_chart'
    },
    {
        'name': 'mat_solid:stadium'
    },
    {
        'name': 'mat_solid:stairs'
    },
    {
        'name': 'mat_solid:star'
    },
    {
        'name': 'mat_solid:stars'
    },
    {
        'name': 'mat_solid:start'
    },
    {
        'name': 'mat_solid:star_border'
    },
    {
        'name': 'mat_solid:star_border_purple500'
    },
    {
        'name': 'mat_solid:star_half'
    },
    {
        'name': 'mat_solid:star_outline'
    },
    {
        'name': 'mat_solid:star_purple500'
    },
    {
        'name': 'mat_solid:star_rate'
    },
    {
        'name': 'mat_solid:stay_current_landscape'
    },
    {
        'name': 'mat_solid:stay_current_portrait'
    },
    {
        'name': 'mat_solid:stay_primary_landscape'
    },
    {
        'name': 'mat_solid:stay_primary_portrait'
    },
    {
        'name': 'mat_solid:sticky_note_2'
    },
    {
        'name': 'mat_solid:stop'
    },
    {
        'name': 'mat_solid:stop_circle'
    },
    {
        'name': 'mat_solid:stop_screen_share'
    },
    {
        'name': 'mat_solid:storage'
    },
    {
        'name': 'mat_solid:store'
    },
    {
        'name': 'mat_solid:storefront'
    },
    {
        'name': 'mat_solid:store_mall_directory'
    },
    {
        'name': 'mat_solid:storm'
    },
    {
        'name': 'mat_solid:straight'
    },
    {
        'name': 'mat_solid:straighten'
    },
    {
        'name': 'mat_solid:stream'
    },
    {
        'name': 'mat_solid:streetview'
    },
    {
        'name': 'mat_solid:strikethrough_s'
    },
    {
        'name': 'mat_solid:stroller'
    },
    {
        'name': 'mat_solid:style'
    },
    {
        'name': 'mat_solid:subdirectory_arrow_left'
    },
    {
        'name': 'mat_solid:subdirectory_arrow_right'
    },
    {
        'name': 'mat_solid:subject'
    },
    {
        'name': 'mat_solid:subscript'
    },
    {
        'name': 'mat_solid:subscriptions'
    },
    {
        'name': 'mat_solid:subtitles'
    },
    {
        'name': 'mat_solid:subtitles_off'
    },
    {
        'name': 'mat_solid:subway'
    },
    {
        'name': 'mat_solid:summarize'
    },
    {
        'name': 'mat_solid:superscript'
    },
    {
        'name': 'mat_solid:supervised_user_circle'
    },
    {
        'name': 'mat_solid:supervisor_account'
    },
    {
        'name': 'mat_solid:support'
    },
    {
        'name': 'mat_solid:support_agent'
    },
    {
        'name': 'mat_solid:surfing'
    },
    {
        'name': 'mat_solid:surround_sound'
    },
    {
        'name': 'mat_solid:swap_calls'
    },
    {
        'name': 'mat_solid:swap_horiz'
    },
    {
        'name': 'mat_solid:swap_horizontal_circle'
    },
    {
        'name': 'mat_solid:swap_vert'
    },
    {
        'name': 'mat_solid:swap_vertical_circle'
    },
    {
        'name': 'mat_solid:swipe'
    },
    {
        'name': 'mat_solid:swipe_down'
    },
    {
        'name': 'mat_solid:swipe_down_alt'
    },
    {
        'name': 'mat_solid:swipe_left'
    },
    {
        'name': 'mat_solid:swipe_left_alt'
    },
    {
        'name': 'mat_solid:swipe_right'
    },
    {
        'name': 'mat_solid:swipe_right_alt'
    },
    {
        'name': 'mat_solid:swipe_up'
    },
    {
        'name': 'mat_solid:swipe_up_alt'
    },
    {
        'name': 'mat_solid:swipe_vertical'
    },
    {
        'name': 'mat_solid:switch_access_shortcut'
    },
    {
        'name': 'mat_solid:switch_access_shortcut_add'
    },
    {
        'name': 'mat_solid:switch_account'
    },
    {
        'name': 'mat_solid:switch_camera'
    },
    {
        'name': 'mat_solid:switch_left'
    },
    {
        'name': 'mat_solid:switch_right'
    },
    {
        'name': 'mat_solid:switch_video'
    },
    {
        'name': 'mat_solid:synagogue'
    },
    {
        'name': 'mat_solid:sync'
    },
    {
        'name': 'mat_solid:sync_alt'
    },
    {
        'name': 'mat_solid:sync_disabled'
    },
    {
        'name': 'mat_solid:sync_lock'
    },
    {
        'name': 'mat_solid:sync_problem'
    },
    {
        'name': 'mat_solid:system_security_update'
    },
    {
        'name': 'mat_solid:system_security_update_good'
    },
    {
        'name': 'mat_solid:system_security_update_warning'
    },
    {
        'name': 'mat_solid:system_update'
    },
    {
        'name': 'mat_solid:system_update_alt'
    },
    {
        'name': 'mat_solid:tab'
    },
    {
        'name': 'mat_solid:tablet'
    },
    {
        'name': 'mat_solid:tablet_android'
    },
    {
        'name': 'mat_solid:tablet_mac'
    },
    {
        'name': 'mat_solid:table_bar'
    },
    {
        'name': 'mat_solid:table_chart'
    },
    {
        'name': 'mat_solid:table_restaurant'
    },
    {
        'name': 'mat_solid:table_rows'
    },
    {
        'name': 'mat_solid:table_view'
    },
    {
        'name': 'mat_solid:tab_unselected'
    },
    {
        'name': 'mat_solid:tag'
    },
    {
        'name': 'mat_solid:tag_faces'
    },
    {
        'name': 'mat_solid:takeout_dining'
    },
    {
        'name': 'mat_solid:tapas'
    },
    {
        'name': 'mat_solid:tap_and_play'
    },
    {
        'name': 'mat_solid:task'
    },
    {
        'name': 'mat_solid:task_alt'
    },
    {
        'name': 'mat_solid:taxi_alert'
    },
    {
        'name': 'mat_solid:temple_buddhist'
    },
    {
        'name': 'mat_solid:temple_hindu'
    },
    {
        'name': 'mat_solid:terminal'
    },
    {
        'name': 'mat_solid:terrain'
    },
    {
        'name': 'mat_solid:textsms'
    },
    {
        'name': 'mat_solid:texture'
    },
    {
        'name': 'mat_solid:text_decrease'
    },
    {
        'name': 'mat_solid:text_fields'
    },
    {
        'name': 'mat_solid:text_format'
    },
    {
        'name': 'mat_solid:text_increase'
    },
    {
        'name': 'mat_solid:text_rotate_up'
    },
    {
        'name': 'mat_solid:text_rotate_vertical'
    },
    {
        'name': 'mat_solid:text_rotation_angledown'
    },
    {
        'name': 'mat_solid:text_rotation_angleup'
    },
    {
        'name': 'mat_solid:text_rotation_down'
    },
    {
        'name': 'mat_solid:text_rotation_none'
    },
    {
        'name': 'mat_solid:text_snippet'
    },
    {
        'name': 'mat_solid:theaters'
    },
    {
        'name': 'mat_solid:theater_comedy'
    },
    {
        'name': 'mat_solid:thermostat'
    },
    {
        'name': 'mat_solid:thermostat_auto'
    },
    {
        'name': 'mat_solid:thumbs_up_down'
    },
    {
        'name': 'mat_solid:thumb_down'
    },
    {
        'name': 'mat_solid:thumb_down_alt'
    },
    {
        'name': 'mat_solid:thumb_down_off_alt'
    },
    {
        'name': 'mat_solid:thumb_up'
    },
    {
        'name': 'mat_solid:thumb_up_alt'
    },
    {
        'name': 'mat_solid:thumb_up_off_alt'
    },
    {
        'name': 'mat_solid:thunderstorm'
    },
    {
        'name': 'mat_solid:timelapse'
    },
    {
        'name': 'mat_solid:timeline'
    },
    {
        'name': 'mat_solid:timer'
    },
    {
        'name': 'mat_solid:timer_10'
    },
    {
        'name': 'mat_solid:timer_10_select'
    },
    {
        'name': 'mat_solid:timer_3'
    },
    {
        'name': 'mat_solid:timer_3_select'
    },
    {
        'name': 'mat_solid:timer_off'
    },
    {
        'name': 'mat_solid:time_to_leave'
    },
    {
        'name': 'mat_solid:tips_and_updates'
    },
    {
        'name': 'mat_solid:tire_repair'
    },
    {
        'name': 'mat_solid:title'
    },
    {
        'name': 'mat_solid:toc'
    },
    {
        'name': 'mat_solid:today'
    },
    {
        'name': 'mat_solid:toggle_off'
    },
    {
        'name': 'mat_solid:toggle_on'
    },
    {
        'name': 'mat_solid:token'
    },
    {
        'name': 'mat_solid:toll'
    },
    {
        'name': 'mat_solid:tonality'
    },
    {
        'name': 'mat_solid:topic'
    },
    {
        'name': 'mat_solid:tornado'
    },
    {
        'name': 'mat_solid:touch_app'
    },
    {
        'name': 'mat_solid:tour'
    },
    {
        'name': 'mat_solid:toys'
    },
    {
        'name': 'mat_solid:track_changes'
    },
    {
        'name': 'mat_solid:traffic'
    },
    {
        'name': 'mat_solid:train'
    },
    {
        'name': 'mat_solid:tram'
    },
    {
        'name': 'mat_solid:transcribe'
    },
    {
        'name': 'mat_solid:transfer_within_a_station'
    },
    {
        'name': 'mat_solid:transform'
    },
    {
        'name': 'mat_solid:transgender'
    },
    {
        'name': 'mat_solid:transit_enterexit'
    },
    {
        'name': 'mat_solid:translate'
    },
    {
        'name': 'mat_solid:travel_explore'
    },
    {
        'name': 'mat_solid:trending_down'
    },
    {
        'name': 'mat_solid:trending_flat'
    },
    {
        'name': 'mat_solid:trending_up'
    },
    {
        'name': 'mat_solid:trip_origin'
    },
    {
        'name': 'mat_solid:troubleshoot'
    },
    {
        'name': 'mat_solid:try'
    },
    {
        'name': 'mat_solid:tsunami'
    },
    {
        'name': 'mat_solid:tty'
    },
    {
        'name': 'mat_solid:tune'
    },
    {
        'name': 'mat_solid:tungsten'
    },
    {
        'name': 'mat_solid:turned_in'
    },
    {
        'name': 'mat_solid:turned_in_not'
    },
    {
        'name': 'mat_solid:turn_left'
    },
    {
        'name': 'mat_solid:turn_right'
    },
    {
        'name': 'mat_solid:turn_sharp_left'
    },
    {
        'name': 'mat_solid:turn_sharp_right'
    },
    {
        'name': 'mat_solid:turn_slight_left'
    },
    {
        'name': 'mat_solid:turn_slight_right'
    },
    {
        'name': 'mat_solid:tv'
    },
    {
        'name': 'mat_solid:tv_off'
    },
    {
        'name': 'mat_solid:two_wheeler'
    },
    {
        'name': 'mat_solid:type_specimen'
    },
    {
        'name': 'mat_solid:umbrella'
    },
    {
        'name': 'mat_solid:unarchive'
    },
    {
        'name': 'mat_solid:undo'
    },
    {
        'name': 'mat_solid:unfold_less'
    },
    {
        'name': 'mat_solid:unfold_less_double'
    },
    {
        'name': 'mat_solid:unfold_more'
    },
    {
        'name': 'mat_solid:unfold_more_double'
    },
    {
        'name': 'mat_solid:unpublished'
    },
    {
        'name': 'mat_solid:unsubscribe'
    },
    {
        'name': 'mat_solid:upcoming'
    },
    {
        'name': 'mat_solid:update'
    },
    {
        'name': 'mat_solid:update_disabled'
    },
    {
        'name': 'mat_solid:upgrade'
    },
    {
        'name': 'mat_solid:upload'
    },
    {
        'name': 'mat_solid:upload_file'
    },
    {
        'name': 'mat_solid:usb'
    },
    {
        'name': 'mat_solid:usb_off'
    },
    {
        'name': 'mat_solid:u_turn_left'
    },
    {
        'name': 'mat_solid:u_turn_right'
    },
    {
        'name': 'mat_solid:vaccines'
    },
    {
        'name': 'mat_solid:vape_free'
    },
    {
        'name': 'mat_solid:vaping_rooms'
    },
    {
        'name': 'mat_solid:verified'
    },
    {
        'name': 'mat_solid:verified_user'
    },
    {
        'name': 'mat_solid:vertical_align_bottom'
    },
    {
        'name': 'mat_solid:vertical_align_center'
    },
    {
        'name': 'mat_solid:vertical_align_top'
    },
    {
        'name': 'mat_solid:vertical_distribute'
    },
    {
        'name': 'mat_solid:vertical_shades'
    },
    {
        'name': 'mat_solid:vertical_shades_closed'
    },
    {
        'name': 'mat_solid:vertical_split'
    },
    {
        'name': 'mat_solid:vibration'
    },
    {
        'name': 'mat_solid:videocam'
    },
    {
        'name': 'mat_solid:videocam_off'
    },
    {
        'name': 'mat_solid:videogame_asset'
    },
    {
        'name': 'mat_solid:videogame_asset_off'
    },
    {
        'name': 'mat_solid:video_call'
    },
    {
        'name': 'mat_solid:video_camera_back'
    },
    {
        'name': 'mat_solid:video_camera_front'
    },
    {
        'name': 'mat_solid:video_chat'
    },
    {
        'name': 'mat_solid:video_file'
    },
    {
        'name': 'mat_solid:video_label'
    },
    {
        'name': 'mat_solid:video_library'
    },
    {
        'name': 'mat_solid:video_settings'
    },
    {
        'name': 'mat_solid:video_stable'
    },
    {
        'name': 'mat_solid:view_agenda'
    },
    {
        'name': 'mat_solid:view_array'
    },
    {
        'name': 'mat_solid:view_carousel'
    },
    {
        'name': 'mat_solid:view_column'
    },
    {
        'name': 'mat_solid:view_comfy'
    },
    {
        'name': 'mat_solid:view_comfy_alt'
    },
    {
        'name': 'mat_solid:view_compact'
    },
    {
        'name': 'mat_solid:view_compact_alt'
    },
    {
        'name': 'mat_solid:view_cozy'
    },
    {
        'name': 'mat_solid:view_day'
    },
    {
        'name': 'mat_solid:view_headline'
    },
    {
        'name': 'mat_solid:view_in_ar'
    },
    {
        'name': 'mat_solid:view_kanban'
    },
    {
        'name': 'mat_solid:view_list'
    },
    {
        'name': 'mat_solid:view_module'
    },
    {
        'name': 'mat_solid:view_quilt'
    },
    {
        'name': 'mat_solid:view_sidebar'
    },
    {
        'name': 'mat_solid:view_stream'
    },
    {
        'name': 'mat_solid:view_timeline'
    },
    {
        'name': 'mat_solid:view_week'
    },
    {
        'name': 'mat_solid:vignette'
    },
    {
        'name': 'mat_solid:villa'
    },
    {
        'name': 'mat_solid:visibility'
    },
    {
        'name': 'mat_solid:visibility_off'
    },
    {
        'name': 'mat_solid:voicemail'
    },
    {
        'name': 'mat_solid:voice_chat'
    },
    {
        'name': 'mat_solid:voice_over_off'
    },
    {
        'name': 'mat_solid:volcano'
    },
    {
        'name': 'mat_solid:volume_down'
    },
    {
        'name': 'mat_solid:volume_mute'
    },
    {
        'name': 'mat_solid:volume_off'
    },
    {
        'name': 'mat_solid:volume_up'
    },
    {
        'name': 'mat_solid:volunteer_activism'
    },
    {
        'name': 'mat_solid:vpn_key'
    },
    {
        'name': 'mat_solid:vpn_key_off'
    },
    {
        'name': 'mat_solid:vpn_lock'
    },
    {
        'name': 'mat_solid:vrpano'
    },
    {
        'name': 'mat_solid:wallet'
    },
    {
        'name': 'mat_solid:wallpaper'
    },
    {
        'name': 'mat_solid:warehouse'
    },
    {
        'name': 'mat_solid:warning'
    },
    {
        'name': 'mat_solid:warning_amber'
    },
    {
        'name': 'mat_solid:wash'
    },
    {
        'name': 'mat_solid:watch'
    },
    {
        'name': 'mat_solid:watch_later'
    },
    {
        'name': 'mat_solid:watch_off'
    },
    {
        'name': 'mat_solid:water'
    },
    {
        'name': 'mat_solid:waterfall_chart'
    },
    {
        'name': 'mat_solid:water_damage'
    },
    {
        'name': 'mat_solid:water_drop'
    },
    {
        'name': 'mat_solid:waves'
    },
    {
        'name': 'mat_solid:waving_hand'
    },
    {
        'name': 'mat_solid:wb_auto'
    },
    {
        'name': 'mat_solid:wb_cloudy'
    },
    {
        'name': 'mat_solid:wb_incandescent'
    },
    {
        'name': 'mat_solid:wb_iridescent'
    },
    {
        'name': 'mat_solid:wb_shade'
    },
    {
        'name': 'mat_solid:wb_sunny'
    },
    {
        'name': 'mat_solid:wb_twilight'
    },
    {
        'name': 'mat_solid:wc'
    },
    {
        'name': 'mat_solid:web'
    },
    {
        'name': 'mat_solid:webhook'
    },
    {
        'name': 'mat_solid:web_asset'
    },
    {
        'name': 'mat_solid:web_asset_off'
    },
    {
        'name': 'mat_solid:web_stories'
    },
    {
        'name': 'mat_solid:weekend'
    },
    {
        'name': 'mat_solid:west'
    },
    {
        'name': 'mat_solid:whatshot'
    },
    {
        'name': 'mat_solid:wheelchair_pickup'
    },
    {
        'name': 'mat_solid:where_to_vote'
    },
    {
        'name': 'mat_solid:widgets'
    },
    {
        'name': 'mat_solid:width_full'
    },
    {
        'name': 'mat_solid:width_normal'
    },
    {
        'name': 'mat_solid:width_wide'
    },
    {
        'name': 'mat_solid:wifi'
    },
    {
        'name': 'mat_solid:wifi_1_bar'
    },
    {
        'name': 'mat_solid:wifi_2_bar'
    },
    {
        'name': 'mat_solid:wifi_calling'
    },
    {
        'name': 'mat_solid:wifi_calling_3'
    },
    {
        'name': 'mat_solid:wifi_channel'
    },
    {
        'name': 'mat_solid:wifi_find'
    },
    {
        'name': 'mat_solid:wifi_lock'
    },
    {
        'name': 'mat_solid:wifi_off'
    },
    {
        'name': 'mat_solid:wifi_password'
    },
    {
        'name': 'mat_solid:wifi_protected_setup'
    },
    {
        'name': 'mat_solid:wifi_tethering'
    },
    {
        'name': 'mat_solid:wifi_tethering_error'
    },
    {
        'name': 'mat_solid:wifi_tethering_off'
    },
    {
        'name': 'mat_solid:window'
    },
    {
        'name': 'mat_solid:wind_power'
    },
    {
        'name': 'mat_solid:wine_bar'
    },
    {
        'name': 'mat_solid:woman'
    },
    {
        'name': 'mat_solid:woman_2'
    },
    {
        'name': 'mat_solid:work'
    },
    {
        'name': 'mat_solid:workspaces'
    },
    {
        'name': 'mat_solid:workspace_premium'
    },
    {
        'name': 'mat_solid:work_history'
    },
    {
        'name': 'mat_solid:work_off'
    },
    {
        'name': 'mat_solid:work_outline'
    },
    {
        'name': 'mat_solid:wrap_text'
    },
    {
        'name': 'mat_solid:wrong_location'
    },
    {
        'name': 'mat_solid:wysiwyg'
    },
    {
        'name': 'mat_solid:yard'
    },
    {
        'name': 'mat_solid:youtube_searched_for'
    },
    {
        'name': 'mat_solid:zoom_in'
    },
    {
        'name': 'mat_solid:zoom_in_map'
    },
    {
        'name': 'mat_solid:zoom_out'
    },
    {
        'name': 'mat_solid:zoom_out_map'
    },
    {
        'name': '10k'
    },
    {
        'name': '10mp'
    },
    {
        'name': '11mp'
    },
    {
        'name': '123'
    },
    {
        'name': '12mp'
    },
    {
        'name': '13mp'
    },
    {
        'name': '14mp'
    },
    {
        'name': '15mp'
    },
    {
        'name': '16mp'
    },
    {
        'name': '17mp'
    },
    {
        'name': '18mp'
    },
    {
        'name': '18_up_rating'
    },
    {
        'name': '19mp'
    },
    {
        'name': '1k'
    },
    {
        'name': '1k_plus'
    },
    {
        'name': '1x_mobiledata'
    },
    {
        'name': '20mp'
    },
    {
        'name': '21mp'
    },
    {
        'name': '22mp'
    },
    {
        'name': '23mp'
    },
    {
        'name': '24mp'
    },
    {
        'name': '2k'
    },
    {
        'name': '2k_plus'
    },
    {
        'name': '2mp'
    },
    {
        'name': '30fps'
    },
    {
        'name': '30fps_select'
    },
    {
        'name': '360'
    },
    {
        'name': '3d_rotation'
    },
    {
        'name': '3g_mobiledata'
    },
    {
        'name': '3k'
    },
    {
        'name': '3k_plus'
    },
    {
        'name': '3mp'
    },
    {
        'name': '3p'
    },
    {
        'name': '4g_mobiledata'
    },
    {
        'name': '4g_plus_mobiledata'
    },
    {
        'name': '4k'
    },
    {
        'name': '4k_plus'
    },
    {
        'name': '4mp'
    },
    {
        'name': '5g'
    },
    {
        'name': '5k'
    },
    {
        'name': '5k_plus'
    },
    {
        'name': '5mp'
    },
    {
        'name': '60fps'
    },
    {
        'name': '60fps_select'
    },
    {
        'name': '6k'
    },
    {
        'name': '6k_plus'
    },
    {
        'name': '6mp'
    },
    {
        'name': '6_ft_apart'
    },
    {
        'name': '7k'
    },
    {
        'name': '7k_plus'
    },
    {
        'name': '7mp'
    },
    {
        'name': '8k'
    },
    {
        'name': '8k_plus'
    },
    {
        'name': '8mp'
    },
    {
        'name': '9k'
    },
    {
        'name': '9k_plus'
    },
    {
        'name': '9mp'
    },
    {
        'name': 'abc'
    },
    {
        'name': 'accessibility'
    },
    {
        'name': 'accessibility_new'
    },
    {
        'name': 'accessible'
    },
    {
        'name': 'accessible_forward'
    },
    {
        'name': 'access_alarm'
    },
    {
        'name': 'access_alarms'
    },
    {
        'name': 'access_time'
    },
    {
        'name': 'access_time_filled'
    },
    {
        'name': 'account_balance'
    },
    {
        'name': 'account_balance_wallet'
    },
    {
        'name': 'account_box'
    },
    {
        'name': 'account_circle'
    },
    {
        'name': 'account_tree'
    },
    {
        'name': 'ac_unit'
    },
    {
        'name': 'adb'
    },
    {
        'name': 'add'
    },
    {
        'name': 'addchart'
    },
    {
        'name': 'add_alarm'
    },
    {
        'name': 'add_alert'
    },
    {
        'name': 'add_a_photo'
    },
    {
        'name': 'add_box'
    },
    {
        'name': 'add_business'
    },
    {
        'name': 'add_card'
    },
    {
        'name': 'add_chart'
    },
    {
        'name': 'add_circle'
    },
    {
        'name': 'add_circle_outline'
    },
    {
        'name': 'add_comment'
    },
    {
        'name': 'add_home'
    },
    {
        'name': 'add_home_work'
    },
    {
        'name': 'add_ic_call'
    },
    {
        'name': 'add_link'
    },
    {
        'name': 'add_location'
    },
    {
        'name': 'add_location_alt'
    },
    {
        'name': 'add_moderator'
    },
    {
        'name': 'add_photo_alternate'
    },
    {
        'name': 'add_reaction'
    },
    {
        'name': 'add_road'
    },
    {
        'name': 'add_shopping_cart'
    },
    {
        'name': 'add_task'
    },
    {
        'name': 'add_to_drive'
    },
    {
        'name': 'add_to_home_screen'
    },
    {
        'name': 'add_to_photos'
    },
    {
        'name': 'add_to_queue'
    },
    {
        'name': 'adf_scanner'
    },
    {
        'name': 'adjust'
    },
    {
        'name': 'admin_panel_settings'
    },
    {
        'name': 'ads_click'
    },
    {
        'name': 'ad_units'
    },
    {
        'name': 'agriculture'
    },
    {
        'name': 'air'
    },
    {
        'name': 'airlines'
    },
    {
        'name': 'airline_seat_flat'
    },
    {
        'name': 'airline_seat_flat_angled'
    },
    {
        'name': 'airline_seat_individual_suite'
    },
    {
        'name': 'airline_seat_legroom_extra'
    },
    {
        'name': 'airline_seat_legroom_normal'
    },
    {
        'name': 'airline_seat_legroom_reduced'
    },
    {
        'name': 'airline_seat_recline_extra'
    },
    {
        'name': 'airline_seat_recline_normal'
    },
    {
        'name': 'airline_stops'
    },
    {
        'name': 'airplanemode_active'
    },
    {
        'name': 'airplanemode_inactive'
    },
    {
        'name': 'airplane_ticket'
    },
    {
        'name': 'airplay'
    },
    {
        'name': 'airport_shuttle'
    },
    {
        'name': 'alarm'
    },
    {
        'name': 'alarm_add'
    },
    {
        'name': 'alarm_off'
    },
    {
        'name': 'alarm_on'
    },
    {
        'name': 'album'
    },
    {
        'name': 'align_horizontal_center'
    },
    {
        'name': 'align_horizontal_left'
    },
    {
        'name': 'align_horizontal_right'
    },
    {
        'name': 'align_vertical_bottom'
    },
    {
        'name': 'align_vertical_center'
    },
    {
        'name': 'align_vertical_top'
    },
    {
        'name': 'all_inbox'
    },
    {
        'name': 'all_inclusive'
    },
    {
        'name': 'all_out'
    },
    {
        'name': 'alternate_email'
    },
    {
        'name': 'alt_route'
    },
    {
        'name': 'analytics'
    },
    {
        'name': 'anchor'
    },
    {
        'name': 'android'
    },
    {
        'name': 'animation'
    },
    {
        'name': 'announcement'
    },
    {
        'name': 'aod'
    },
    {
        'name': 'apartment'
    },
    {
        'name': 'api'
    },
    {
        'name': 'approval'
    },
    {
        'name': 'apps'
    },
    {
        'name': 'apps_outage'
    },
    {
        'name': 'app_blocking'
    },
    {
        'name': 'app_registration'
    },
    {
        'name': 'app_settings_alt'
    },
    {
        'name': 'app_shortcut'
    },
    {
        'name': 'architecture'
    },
    {
        'name': 'archive'
    },
    {
        'name': 'area_chart'
    },
    {
        'name': 'arrow_back'
    },
    {
        'name': 'arrow_back_ios'
    },
    {
        'name': 'arrow_back_ios_new'
    },
    {
        'name': 'arrow_circle_down'
    },
    {
        'name': 'arrow_circle_left'
    },
    {
        'name': 'arrow_circle_right'
    },
    {
        'name': 'arrow_circle_up'
    },
    {
        'name': 'arrow_downward'
    },
    {
        'name': 'arrow_drop_down'
    },
    {
        'name': 'arrow_drop_down_circle'
    },
    {
        'name': 'arrow_drop_up'
    },
    {
        'name': 'arrow_forward'
    },
    {
        'name': 'arrow_forward_ios'
    },
    {
        'name': 'arrow_left'
    },
    {
        'name': 'arrow_outward'
    },
    {
        'name': 'arrow_right'
    },
    {
        'name': 'arrow_right_alt'
    },
    {
        'name': 'arrow_upward'
    },
    {
        'name': 'article'
    },
    {
        'name': 'art_track'
    },
    {
        'name': 'aspect_ratio'
    },
    {
        'name': 'assessment'
    },
    {
        'name': 'assignment'
    },
    {
        'name': 'assignment_ind'
    },
    {
        'name': 'assignment_late'
    },
    {
        'name': 'assignment_return'
    },
    {
        'name': 'assignment_returned'
    },
    {
        'name': 'assignment_turned_in'
    },
    {
        'name': 'assistant'
    },
    {
        'name': 'assistant_direction'
    },
    {
        'name': 'assistant_photo'
    },
    {
        'name': 'assist_walker'
    },
    {
        'name': 'assured_workload'
    },
    {
        'name': 'atm'
    },
    {
        'name': 'attachment'
    },
    {
        'name': 'attach_email'
    },
    {
        'name': 'attach_file'
    },
    {
        'name': 'attach_money'
    },
    {
        'name': 'attractions'
    },
    {
        'name': 'attribution'
    },
    {
        'name': 'audiotrack'
    },
    {
        'name': 'audio_file'
    },
    {
        'name': 'autofps_select'
    },
    {
        'name': 'autorenew'
    },
    {
        'name': 'auto_awesome'
    },
    {
        'name': 'auto_awesome_mosaic'
    },
    {
        'name': 'auto_awesome_motion'
    },
    {
        'name': 'auto_delete'
    },
    {
        'name': 'auto_fix_high'
    },
    {
        'name': 'auto_fix_normal'
    },
    {
        'name': 'auto_fix_off'
    },
    {
        'name': 'auto_graph'
    },
    {
        'name': 'auto_mode'
    },
    {
        'name': 'auto_stories'
    },
    {
        'name': 'av_timer'
    },
    {
        'name': 'baby_changing_station'
    },
    {
        'name': 'backpack'
    },
    {
        'name': 'backspace'
    },
    {
        'name': 'backup'
    },
    {
        'name': 'backup_table'
    },
    {
        'name': 'back_hand'
    },
    {
        'name': 'badge'
    },
    {
        'name': 'bakery_dining'
    },
    {
        'name': 'balance'
    },
    {
        'name': 'balcony'
    },
    {
        'name': 'ballot'
    },
    {
        'name': 'bar_chart'
    },
    {
        'name': 'batch_prediction'
    },
    {
        'name': 'bathroom'
    },
    {
        'name': 'bathtub'
    },
    {
        'name': 'battery_0_bar'
    },
    {
        'name': 'battery_1_bar'
    },
    {
        'name': 'battery_2_bar'
    },
    {
        'name': 'battery_3_bar'
    },
    {
        'name': 'battery_4_bar'
    },
    {
        'name': 'battery_5_bar'
    },
    {
        'name': 'battery_6_bar'
    },
    {
        'name': 'battery_alert'
    },
    {
        'name': 'battery_charging_full'
    },
    {
        'name': 'battery_full'
    },
    {
        'name': 'battery_saver'
    },
    {
        'name': 'battery_std'
    },
    {
        'name': 'battery_unknown'
    },
    {
        'name': 'beach_access'
    },
    {
        'name': 'bed'
    },
    {
        'name': 'bedroom_baby'
    },
    {
        'name': 'bedroom_child'
    },
    {
        'name': 'bedroom_parent'
    },
    {
        'name': 'bedtime'
    },
    {
        'name': 'bedtime_off'
    },
    {
        'name': 'beenhere'
    },
    {
        'name': 'bento'
    },
    {
        'name': 'bike_scooter'
    },
    {
        'name': 'biotech'
    },
    {
        'name': 'blender'
    },
    {
        'name': 'blind'
    },
    {
        'name': 'blinds'
    },
    {
        'name': 'blinds_closed'
    },
    {
        'name': 'block'
    },
    {
        'name': 'bloodtype'
    },
    {
        'name': 'bluetooth'
    },
    {
        'name': 'bluetooth_audio'
    },
    {
        'name': 'bluetooth_connected'
    },
    {
        'name': 'bluetooth_disabled'
    },
    {
        'name': 'bluetooth_drive'
    },
    {
        'name': 'bluetooth_searching'
    },
    {
        'name': 'blur_circular'
    },
    {
        'name': 'blur_linear'
    },
    {
        'name': 'blur_off'
    },
    {
        'name': 'blur_on'
    },
    {
        'name': 'bolt'
    },
    {
        'name': 'book'
    },
    {
        'name': 'bookmark'
    },
    {
        'name': 'bookmarks'
    },
    {
        'name': 'bookmark_add'
    },
    {
        'name': 'bookmark_added'
    },
    {
        'name': 'bookmark_border'
    },
    {
        'name': 'bookmark_remove'
    },
    {
        'name': 'book_online'
    },
    {
        'name': 'border_all'
    },
    {
        'name': 'border_bottom'
    },
    {
        'name': 'border_clear'
    },
    {
        'name': 'border_color'
    },
    {
        'name': 'border_horizontal'
    },
    {
        'name': 'border_inner'
    },
    {
        'name': 'border_left'
    },
    {
        'name': 'border_outer'
    },
    {
        'name': 'border_right'
    },
    {
        'name': 'border_style'
    },
    {
        'name': 'border_top'
    },
    {
        'name': 'border_vertical'
    },
    {
        'name': 'boy'
    },
    {
        'name': 'branding_watermark'
    },
    {
        'name': 'breakfast_dining'
    },
    {
        'name': 'brightness_1'
    },
    {
        'name': 'brightness_2'
    },
    {
        'name': 'brightness_3'
    },
    {
        'name': 'brightness_4'
    },
    {
        'name': 'brightness_5'
    },
    {
        'name': 'brightness_6'
    },
    {
        'name': 'brightness_7'
    },
    {
        'name': 'brightness_auto'
    },
    {
        'name': 'brightness_high'
    },
    {
        'name': 'brightness_low'
    },
    {
        'name': 'brightness_medium'
    },
    {
        'name': 'broadcast_on_home'
    },
    {
        'name': 'broadcast_on_personal'
    },
    {
        'name': 'broken_image'
    },
    {
        'name': 'browser_not_supported'
    },
    {
        'name': 'browser_updated'
    },
    {
        'name': 'browse_gallery'
    },
    {
        'name': 'brunch_dining'
    },
    {
        'name': 'brush'
    },
    {
        'name': 'bubble_chart'
    },
    {
        'name': 'bug_report'
    },
    {
        'name': 'build'
    },
    {
        'name': 'build_circle'
    },
    {
        'name': 'bungalow'
    },
    {
        'name': 'burst_mode'
    },
    {
        'name': 'business'
    },
    {
        'name': 'business_center'
    },
    {
        'name': 'bus_alert'
    },
    {
        'name': 'cabin'
    },
    {
        'name': 'cable'
    },
    {
        'name': 'cached'
    },
    {
        'name': 'cake'
    },
    {
        'name': 'calculate'
    },
    {
        'name': 'calendar_month'
    },
    {
        'name': 'calendar_today'
    },
    {
        'name': 'calendar_view_day'
    },
    {
        'name': 'calendar_view_month'
    },
    {
        'name': 'calendar_view_week'
    },
    {
        'name': 'call'
    },
    {
        'name': 'call_end'
    },
    {
        'name': 'call_made'
    },
    {
        'name': 'call_merge'
    },
    {
        'name': 'call_missed'
    },
    {
        'name': 'call_missed_outgoing'
    },
    {
        'name': 'call_received'
    },
    {
        'name': 'call_split'
    },
    {
        'name': 'call_to_action'
    },
    {
        'name': 'camera'
    },
    {
        'name': 'cameraswitch'
    },
    {
        'name': 'camera_alt'
    },
    {
        'name': 'camera_enhance'
    },
    {
        'name': 'camera_front'
    },
    {
        'name': 'camera_indoor'
    },
    {
        'name': 'camera_outdoor'
    },
    {
        'name': 'camera_rear'
    },
    {
        'name': 'camera_roll'
    },
    {
        'name': 'campaign'
    },
    {
        'name': 'cancel'
    },
    {
        'name': 'cancel_presentation'
    },
    {
        'name': 'cancel_schedule_send'
    },
    {
        'name': 'candlestick_chart'
    },
    {
        'name': 'card_giftcard'
    },
    {
        'name': 'card_membership'
    },
    {
        'name': 'card_travel'
    },
    {
        'name': 'carpenter'
    },
    {
        'name': 'car_crash'
    },
    {
        'name': 'car_rental'
    },
    {
        'name': 'car_repair'
    },
    {
        'name': 'cases'
    },
    {
        'name': 'casino'
    },
    {
        'name': 'cast'
    },
    {
        'name': 'castle'
    },
    {
        'name': 'cast_connected'
    },
    {
        'name': 'cast_for_education'
    },
    {
        'name': 'catching_pokemon'
    },
    {
        'name': 'category'
    },
    {
        'name': 'celebration'
    },
    {
        'name': 'cell_tower'
    },
    {
        'name': 'cell_wifi'
    },
    {
        'name': 'center_focus_strong'
    },
    {
        'name': 'center_focus_weak'
    },
    {
        'name': 'chair'
    },
    {
        'name': 'chair_alt'
    },
    {
        'name': 'chalet'
    },
    {
        'name': 'change_circle'
    },
    {
        'name': 'change_history'
    },
    {
        'name': 'charging_station'
    },
    {
        'name': 'chat'
    },
    {
        'name': 'chat_bubble'
    },
    {
        'name': 'chat_bubble_outline'
    },
    {
        'name': 'check'
    },
    {
        'name': 'checklist'
    },
    {
        'name': 'checklist_rtl'
    },
    {
        'name': 'checkroom'
    },
    {
        'name': 'check_box'
    },
    {
        'name': 'check_box_outline_blank'
    },
    {
        'name': 'check_circle'
    },
    {
        'name': 'check_circle_outline'
    },
    {
        'name': 'chevron_left'
    },
    {
        'name': 'chevron_right'
    },
    {
        'name': 'child_care'
    },
    {
        'name': 'child_friendly'
    },
    {
        'name': 'chrome_reader_mode'
    },
    {
        'name': 'church'
    },
    {
        'name': 'circle'
    },
    {
        'name': 'circle_notifications'
    },
    {
        'name': 'class'
    },
    {
        'name': 'cleaning_services'
    },
    {
        'name': 'clean_hands'
    },
    {
        'name': 'clear'
    },
    {
        'name': 'clear_all'
    },
    {
        'name': 'close'
    },
    {
        'name': 'closed_caption'
    },
    {
        'name': 'closed_caption_disabled'
    },
    {
        'name': 'closed_caption_off'
    },
    {
        'name': 'close_fullscreen'
    },
    {
        'name': 'cloud'
    },
    {
        'name': 'cloud_circle'
    },
    {
        'name': 'cloud_done'
    },
    {
        'name': 'cloud_download'
    },
    {
        'name': 'cloud_off'
    },
    {
        'name': 'cloud_queue'
    },
    {
        'name': 'cloud_sync'
    },
    {
        'name': 'cloud_upload'
    },
    {
        'name': 'co2'
    },
    {
        'name': 'code'
    },
    {
        'name': 'code_off'
    },
    {
        'name': 'coffee'
    },
    {
        'name': 'coffee_maker'
    },
    {
        'name': 'collections'
    },
    {
        'name': 'collections_bookmark'
    },
    {
        'name': 'colorize'
    },
    {
        'name': 'color_lens'
    },
    {
        'name': 'comment'
    },
    {
        'name': 'comments_disabled'
    },
    {
        'name': 'comment_bank'
    },
    {
        'name': 'commit'
    },
    {
        'name': 'commute'
    },
    {
        'name': 'compare'
    },
    {
        'name': 'compare_arrows'
    },
    {
        'name': 'compass_calibration'
    },
    {
        'name': 'compost'
    },
    {
        'name': 'compress'
    },
    {
        'name': 'computer'
    },
    {
        'name': 'confirmation_number'
    },
    {
        'name': 'connected_tv'
    },
    {
        'name': 'connecting_airports'
    },
    {
        'name': 'connect_without_contact'
    },
    {
        'name': 'construction'
    },
    {
        'name': 'contactless'
    },
    {
        'name': 'contacts'
    },
    {
        'name': 'contact_emergency'
    },
    {
        'name': 'contact_mail'
    },
    {
        'name': 'contact_page'
    },
    {
        'name': 'contact_phone'
    },
    {
        'name': 'contact_support'
    },
    {
        'name': 'content_copy'
    },
    {
        'name': 'content_cut'
    },
    {
        'name': 'content_paste'
    },
    {
        'name': 'content_paste_go'
    },
    {
        'name': 'content_paste_off'
    },
    {
        'name': 'content_paste_search'
    },
    {
        'name': 'contrast'
    },
    {
        'name': 'control_camera'
    },
    {
        'name': 'control_point'
    },
    {
        'name': 'control_point_duplicate'
    },
    {
        'name': 'cookie'
    },
    {
        'name': 'copyright'
    },
    {
        'name': 'copy_all'
    },
    {
        'name': 'coronavirus'
    },
    {
        'name': 'corporate_fare'
    },
    {
        'name': 'cottage'
    },
    {
        'name': 'countertops'
    },
    {
        'name': 'co_present'
    },
    {
        'name': 'create'
    },
    {
        'name': 'create_new_folder'
    },
    {
        'name': 'credit_card'
    },
    {
        'name': 'credit_card_off'
    },
    {
        'name': 'credit_score'
    },
    {
        'name': 'crib'
    },
    {
        'name': 'crisis_alert'
    },
    {
        'name': 'crop'
    },
    {
        'name': 'crop_16_9'
    },
    {
        'name': 'crop_3_2'
    },
    {
        'name': 'crop_5_4'
    },
    {
        'name': 'crop_7_5'
    },
    {
        'name': 'crop_din'
    },
    {
        'name': 'crop_free'
    },
    {
        'name': 'crop_landscape'
    },
    {
        'name': 'crop_original'
    },
    {
        'name': 'crop_portrait'
    },
    {
        'name': 'crop_rotate'
    },
    {
        'name': 'crop_square'
    },
    {
        'name': 'cruelty_free'
    },
    {
        'name': 'css'
    },
    {
        'name': 'currency_bitcoin'
    },
    {
        'name': 'currency_exchange'
    },
    {
        'name': 'currency_franc'
    },
    {
        'name': 'currency_lira'
    },
    {
        'name': 'currency_pound'
    },
    {
        'name': 'currency_ruble'
    },
    {
        'name': 'currency_rupee'
    },
    {
        'name': 'currency_yen'
    },
    {
        'name': 'currency_yuan'
    },
    {
        'name': 'curtains'
    },
    {
        'name': 'curtains_closed'
    },
    {
        'name': 'cyclone'
    },
    {
        'name': 'dangerous'
    },
    {
        'name': 'dark_mode'
    },
    {
        'name': 'dashboard'
    },
    {
        'name': 'dashboard_customize'
    },
    {
        'name': 'dataset'
    },
    {
        'name': 'dataset_linked'
    },
    {
        'name': 'data_array'
    },
    {
        'name': 'data_exploration'
    },
    {
        'name': 'data_object'
    },
    {
        'name': 'data_saver_off'
    },
    {
        'name': 'data_saver_on'
    },
    {
        'name': 'data_thresholding'
    },
    {
        'name': 'data_usage'
    },
    {
        'name': 'date_range'
    },
    {
        'name': 'deblur'
    },
    {
        'name': 'deck'
    },
    {
        'name': 'dehaze'
    },
    {
        'name': 'delete'
    },
    {
        'name': 'delete_forever'
    },
    {
        'name': 'delete_outline'
    },
    {
        'name': 'delete_sweep'
    },
    {
        'name': 'delivery_dining'
    },
    {
        'name': 'density_large'
    },
    {
        'name': 'density_medium'
    },
    {
        'name': 'density_small'
    },
    {
        'name': 'departure_board'
    },
    {
        'name': 'description'
    },
    {
        'name': 'deselect'
    },
    {
        'name': 'design_services'
    },
    {
        'name': 'desk'
    },
    {
        'name': 'desktop_access_disabled'
    },
    {
        'name': 'desktop_mac'
    },
    {
        'name': 'desktop_windows'
    },
    {
        'name': 'details'
    },
    {
        'name': 'developer_board'
    },
    {
        'name': 'developer_board_off'
    },
    {
        'name': 'developer_mode'
    },
    {
        'name': 'devices'
    },
    {
        'name': 'devices_fold'
    },
    {
        'name': 'devices_other'
    },
    {
        'name': 'device_hub'
    },
    {
        'name': 'device_thermostat'
    },
    {
        'name': 'device_unknown'
    },
    {
        'name': 'dialer_sip'
    },
    {
        'name': 'dialpad'
    },
    {
        'name': 'diamond'
    },
    {
        'name': 'difference'
    },
    {
        'name': 'dining'
    },
    {
        'name': 'dinner_dining'
    },
    {
        'name': 'directions'
    },
    {
        'name': 'directions_bike'
    },
    {
        'name': 'directions_boat'
    },
    {
        'name': 'directions_boat_filled'
    },
    {
        'name': 'directions_bus'
    },
    {
        'name': 'directions_bus_filled'
    },
    {
        'name': 'directions_car'
    },
    {
        'name': 'directions_car_filled'
    },
    {
        'name': 'directions_off'
    },
    {
        'name': 'directions_railway'
    },
    {
        'name': 'directions_railway_filled'
    },
    {
        'name': 'directions_run'
    },
    {
        'name': 'directions_subway'
    },
    {
        'name': 'directions_subway_filled'
    },
    {
        'name': 'directions_transit'
    },
    {
        'name': 'directions_transit_filled'
    },
    {
        'name': 'directions_walk'
    },
    {
        'name': 'dirty_lens'
    },
    {
        'name': 'disabled_by_default'
    },
    {
        'name': 'disabled_visible'
    },
    {
        'name': 'discount'
    },
    {
        'name': 'disc_full'
    },
    {
        'name': 'display_settings'
    },
    {
        'name': 'diversity_1'
    },
    {
        'name': 'diversity_2'
    },
    {
        'name': 'diversity_3'
    },
    {
        'name': 'dns'
    },
    {
        'name': 'dock'
    },
    {
        'name': 'document_scanner'
    },
    {
        'name': 'domain'
    },
    {
        'name': 'domain_add'
    },
    {
        'name': 'domain_disabled'
    },
    {
        'name': 'domain_verification'
    },
    {
        'name': 'done'
    },
    {
        'name': 'done_all'
    },
    {
        'name': 'done_outline'
    },
    {
        'name': 'donut_large'
    },
    {
        'name': 'donut_small'
    },
    {
        'name': 'doorbell'
    },
    {
        'name': 'door_back'
    },
    {
        'name': 'door_front'
    },
    {
        'name': 'door_sliding'
    },
    {
        'name': 'double_arrow'
    },
    {
        'name': 'downhill_skiing'
    },
    {
        'name': 'download'
    },
    {
        'name': 'downloading'
    },
    {
        'name': 'download_done'
    },
    {
        'name': 'download_for_offline'
    },
    {
        'name': 'do_disturb'
    },
    {
        'name': 'do_disturb_alt'
    },
    {
        'name': 'do_disturb_off'
    },
    {
        'name': 'do_disturb_on'
    },
    {
        'name': 'do_not_disturb'
    },
    {
        'name': 'do_not_disturb_alt'
    },
    {
        'name': 'do_not_disturb_off'
    },
    {
        'name': 'do_not_disturb_on'
    },
    {
        'name': 'do_not_disturb_on_total_silence'
    },
    {
        'name': 'do_not_step'
    },
    {
        'name': 'do_not_touch'
    },
    {
        'name': 'drafts'
    },
    {
        'name': 'drag_handle'
    },
    {
        'name': 'drag_indicator'
    },
    {
        'name': 'draw'
    },
    {
        'name': 'drive_eta'
    },
    {
        'name': 'drive_file_move'
    },
    {
        'name': 'drive_file_move_rtl'
    },
    {
        'name': 'drive_file_rename_outline'
    },
    {
        'name': 'drive_folder_upload'
    },
    {
        'name': 'dry'
    },
    {
        'name': 'dry_cleaning'
    },
    {
        'name': 'duo'
    },
    {
        'name': 'dvr'
    },
    {
        'name': 'dynamic_feed'
    },
    {
        'name': 'dynamic_form'
    },
    {
        'name': 'earbuds'
    },
    {
        'name': 'earbuds_battery'
    },
    {
        'name': 'east'
    },
    {
        'name': 'edgesensor_high'
    },
    {
        'name': 'edgesensor_low'
    },
    {
        'name': 'edit'
    },
    {
        'name': 'edit_attributes'
    },
    {
        'name': 'edit_calendar'
    },
    {
        'name': 'edit_location'
    },
    {
        'name': 'edit_location_alt'
    },
    {
        'name': 'edit_note'
    },
    {
        'name': 'edit_notifications'
    },
    {
        'name': 'edit_off'
    },
    {
        'name': 'edit_road'
    },
    {
        'name': 'egg'
    },
    {
        'name': 'egg_alt'
    },
    {
        'name': 'eject'
    },
    {
        'name': 'elderly'
    },
    {
        'name': 'elderly_woman'
    },
    {
        'name': 'electrical_services'
    },
    {
        'name': 'electric_bike'
    },
    {
        'name': 'electric_bolt'
    },
    {
        'name': 'electric_car'
    },
    {
        'name': 'electric_meter'
    },
    {
        'name': 'electric_moped'
    },
    {
        'name': 'electric_rickshaw'
    },
    {
        'name': 'electric_scooter'
    },
    {
        'name': 'elevator'
    },
    {
        'name': 'email'
    },
    {
        'name': 'emergency'
    },
    {
        'name': 'emergency_recording'
    },
    {
        'name': 'emergency_share'
    },
    {
        'name': 'emoji_emotions'
    },
    {
        'name': 'emoji_events'
    },
    {
        'name': 'emoji_food_beverage'
    },
    {
        'name': 'emoji_nature'
    },
    {
        'name': 'emoji_objects'
    },
    {
        'name': 'emoji_people'
    },
    {
        'name': 'emoji_symbols'
    },
    {
        'name': 'emoji_transportation'
    },
    {
        'name': 'energy_savings_leaf'
    },
    {
        'name': 'engineering'
    },
    {
        'name': 'enhanced_encryption'
    },
    {
        'name': 'equalizer'
    },
    {
        'name': 'error'
    },
    {
        'name': 'error_outline'
    },
    {
        'name': 'escalator'
    },
    {
        'name': 'escalator_warning'
    },
    {
        'name': 'euro'
    },
    {
        'name': 'euro_symbol'
    },
    {
        'name': 'event'
    },
    {
        'name': 'event_available'
    },
    {
        'name': 'event_busy'
    },
    {
        'name': 'event_note'
    },
    {
        'name': 'event_repeat'
    },
    {
        'name': 'event_seat'
    },
    {
        'name': 'ev_station'
    },
    {
        'name': 'exit_to_app'
    },
    {
        'name': 'expand'
    },
    {
        'name': 'expand_circle_down'
    },
    {
        'name': 'expand_less'
    },
    {
        'name': 'expand_more'
    },
    {
        'name': 'explicit'
    },
    {
        'name': 'explore'
    },
    {
        'name': 'explore_off'
    },
    {
        'name': 'exposure'
    },
    {
        'name': 'exposure_neg_1'
    },
    {
        'name': 'exposure_neg_2'
    },
    {
        'name': 'exposure_plus_1'
    },
    {
        'name': 'exposure_plus_2'
    },
    {
        'name': 'exposure_zero'
    },
    {
        'name': 'extension'
    },
    {
        'name': 'extension_off'
    },
    {
        'name': 'e_mobiledata'
    },
    {
        'name': 'face'
    },
    {
        'name': 'face_2'
    },
    {
        'name': 'face_3'
    },
    {
        'name': 'face_4'
    },
    {
        'name': 'face_5'
    },
    {
        'name': 'face_6'
    },
    {
        'name': 'face_retouching_natural'
    },
    {
        'name': 'face_retouching_off'
    },
    {
        'name': 'factory'
    },
    {
        'name': 'fact_check'
    },
    {
        'name': 'family_restroom'
    },
    {
        'name': 'fastfood'
    },
    {
        'name': 'fast_forward'
    },
    {
        'name': 'fast_rewind'
    },
    {
        'name': 'favorite'
    },
    {
        'name': 'favorite_border'
    },
    {
        'name': 'fax'
    },
    {
        'name': 'featured_play_list'
    },
    {
        'name': 'featured_video'
    },
    {
        'name': 'feed'
    },
    {
        'name': 'feedback'
    },
    {
        'name': 'female'
    },
    {
        'name': 'fence'
    },
    {
        'name': 'festival'
    },
    {
        'name': 'fiber_dvr'
    },
    {
        'name': 'fiber_manual_record'
    },
    {
        'name': 'fiber_new'
    },
    {
        'name': 'fiber_pin'
    },
    {
        'name': 'fiber_smart_record'
    },
    {
        'name': 'file_copy'
    },
    {
        'name': 'file_download'
    },
    {
        'name': 'file_download_done'
    },
    {
        'name': 'file_download_off'
    },
    {
        'name': 'file_open'
    },
    {
        'name': 'file_present'
    },
    {
        'name': 'file_upload'
    },
    {
        'name': 'filter'
    },
    {
        'name': 'filter_1'
    },
    {
        'name': 'filter_2'
    },
    {
        'name': 'filter_3'
    },
    {
        'name': 'filter_4'
    },
    {
        'name': 'filter_5'
    },
    {
        'name': 'filter_6'
    },
    {
        'name': 'filter_7'
    },
    {
        'name': 'filter_8'
    },
    {
        'name': 'filter_9'
    },
    {
        'name': 'filter_9_plus'
    },
    {
        'name': 'filter_alt'
    },
    {
        'name': 'filter_alt_off'
    },
    {
        'name': 'filter_b_and_w'
    },
    {
        'name': 'filter_center_focus'
    },
    {
        'name': 'filter_drama'
    },
    {
        'name': 'filter_frames'
    },
    {
        'name': 'filter_hdr'
    },
    {
        'name': 'filter_list'
    },
    {
        'name': 'filter_list_off'
    },
    {
        'name': 'filter_none'
    },
    {
        'name': 'filter_tilt_shift'
    },
    {
        'name': 'filter_vintage'
    },
    {
        'name': 'find_in_page'
    },
    {
        'name': 'find_replace'
    },
    {
        'name': 'fingerprint'
    },
    {
        'name': 'fireplace'
    },
    {
        'name': 'fire_extinguisher'
    },
    {
        'name': 'fire_hydrant_alt'
    },
    {
        'name': 'fire_truck'
    },
    {
        'name': 'first_page'
    },
    {
        'name': 'fitbit'
    },
    {
        'name': 'fitness_center'
    },
    {
        'name': 'fit_screen'
    },
    {
        'name': 'flag'
    },
    {
        'name': 'flag_circle'
    },
    {
        'name': 'flaky'
    },
    {
        'name': 'flare'
    },
    {
        'name': 'flashlight_off'
    },
    {
        'name': 'flashlight_on'
    },
    {
        'name': 'flash_auto'
    },
    {
        'name': 'flash_off'
    },
    {
        'name': 'flash_on'
    },
    {
        'name': 'flatware'
    },
    {
        'name': 'flight'
    },
    {
        'name': 'flight_class'
    },
    {
        'name': 'flight_land'
    },
    {
        'name': 'flight_takeoff'
    },
    {
        'name': 'flip'
    },
    {
        'name': 'flip_camera_android'
    },
    {
        'name': 'flip_camera_ios'
    },
    {
        'name': 'flip_to_back'
    },
    {
        'name': 'flip_to_front'
    },
    {
        'name': 'flood'
    },
    {
        'name': 'fluorescent'
    },
    {
        'name': 'flutter_dash'
    },
    {
        'name': 'fmd_bad'
    },
    {
        'name': 'fmd_good'
    },
    {
        'name': 'folder'
    },
    {
        'name': 'folder_copy'
    },
    {
        'name': 'folder_delete'
    },
    {
        'name': 'folder_off'
    },
    {
        'name': 'folder_open'
    },
    {
        'name': 'folder_shared'
    },
    {
        'name': 'folder_special'
    },
    {
        'name': 'folder_zip'
    },
    {
        'name': 'follow_the_signs'
    },
    {
        'name': 'font_download'
    },
    {
        'name': 'font_download_off'
    },
    {
        'name': 'food_bank'
    },
    {
        'name': 'forest'
    },
    {
        'name': 'fork_left'
    },
    {
        'name': 'fork_right'
    },
    {
        'name': 'format_align_center'
    },
    {
        'name': 'format_align_justify'
    },
    {
        'name': 'format_align_left'
    },
    {
        'name': 'format_align_right'
    },
    {
        'name': 'format_bold'
    },
    {
        'name': 'format_clear'
    },
    {
        'name': 'format_color_fill'
    },
    {
        'name': 'format_color_reset'
    },
    {
        'name': 'format_color_text'
    },
    {
        'name': 'format_indent_decrease'
    },
    {
        'name': 'format_indent_increase'
    },
    {
        'name': 'format_italic'
    },
    {
        'name': 'format_line_spacing'
    },
    {
        'name': 'format_list_bulleted'
    },
    {
        'name': 'format_list_numbered'
    },
    {
        'name': 'format_list_numbered_rtl'
    },
    {
        'name': 'format_overline'
    },
    {
        'name': 'format_paint'
    },
    {
        'name': 'format_quote'
    },
    {
        'name': 'format_shapes'
    },
    {
        'name': 'format_size'
    },
    {
        'name': 'format_strikethrough'
    },
    {
        'name': 'format_textdirection_l_to_r'
    },
    {
        'name': 'format_textdirection_r_to_l'
    },
    {
        'name': 'format_underlined'
    },
    {
        'name': 'fort'
    },
    {
        'name': 'forum'
    },
    {
        'name': 'forward'
    },
    {
        'name': 'forward_10'
    },
    {
        'name': 'forward_30'
    },
    {
        'name': 'forward_5'
    },
    {
        'name': 'forward_to_inbox'
    },
    {
        'name': 'foundation'
    },
    {
        'name': 'free_breakfast'
    },
    {
        'name': 'free_cancellation'
    },
    {
        'name': 'front_hand'
    },
    {
        'name': 'fullscreen'
    },
    {
        'name': 'fullscreen_exit'
    },
    {
        'name': 'functions'
    },
    {
        'name': 'gamepad'
    },
    {
        'name': 'games'
    },
    {
        'name': 'garage'
    },
    {
        'name': 'gas_meter'
    },
    {
        'name': 'gavel'
    },
    {
        'name': 'generating_tokens'
    },
    {
        'name': 'gesture'
    },
    {
        'name': 'get_app'
    },
    {
        'name': 'gif'
    },
    {
        'name': 'gif_box'
    },
    {
        'name': 'girl'
    },
    {
        'name': 'gite'
    },
    {
        'name': 'golf_course'
    },
    {
        'name': 'gpp_bad'
    },
    {
        'name': 'gpp_good'
    },
    {
        'name': 'gpp_maybe'
    },
    {
        'name': 'gps_fixed'
    },
    {
        'name': 'gps_not_fixed'
    },
    {
        'name': 'gps_off'
    },
    {
        'name': 'grade'
    },
    {
        'name': 'gradient'
    },
    {
        'name': 'grading'
    },
    {
        'name': 'grain'
    },
    {
        'name': 'graphic_eq'
    },
    {
        'name': 'grass'
    },
    {
        'name': 'grid_3x3'
    },
    {
        'name': 'grid_4x4'
    },
    {
        'name': 'grid_goldenratio'
    },
    {
        'name': 'grid_off'
    },
    {
        'name': 'grid_on'
    },
    {
        'name': 'grid_view'
    },
    {
        'name': 'group'
    },
    {
        'name': 'groups'
    },
    {
        'name': 'groups_2'
    },
    {
        'name': 'groups_3'
    },
    {
        'name': 'group_add'
    },
    {
        'name': 'group_off'
    },
    {
        'name': 'group_remove'
    },
    {
        'name': 'group_work'
    },
    {
        'name': 'g_mobiledata'
    },
    {
        'name': 'g_translate'
    },
    {
        'name': 'hail'
    },
    {
        'name': 'handshake'
    },
    {
        'name': 'handyman'
    },
    {
        'name': 'hardware'
    },
    {
        'name': 'hd'
    },
    {
        'name': 'hdr_auto'
    },
    {
        'name': 'hdr_auto_select'
    },
    {
        'name': 'hdr_enhanced_select'
    },
    {
        'name': 'hdr_off'
    },
    {
        'name': 'hdr_off_select'
    },
    {
        'name': 'hdr_on'
    },
    {
        'name': 'hdr_on_select'
    },
    {
        'name': 'hdr_plus'
    },
    {
        'name': 'hdr_strong'
    },
    {
        'name': 'hdr_weak'
    },
    {
        'name': 'headphones'
    },
    {
        'name': 'headphones_battery'
    },
    {
        'name': 'headset'
    },
    {
        'name': 'headset_mic'
    },
    {
        'name': 'headset_off'
    },
    {
        'name': 'healing'
    },
    {
        'name': 'health_and_safety'
    },
    {
        'name': 'hearing'
    },
    {
        'name': 'hearing_disabled'
    },
    {
        'name': 'heart_broken'
    },
    {
        'name': 'heat_pump'
    },
    {
        'name': 'height'
    },
    {
        'name': 'help'
    },
    {
        'name': 'help_center'
    },
    {
        'name': 'help_outline'
    },
    {
        'name': 'hevc'
    },
    {
        'name': 'hexagon'
    },
    {
        'name': 'hide_image'
    },
    {
        'name': 'hide_source'
    },
    {
        'name': 'highlight'
    },
    {
        'name': 'highlight_alt'
    },
    {
        'name': 'highlight_off'
    },
    {
        'name': 'high_quality'
    },
    {
        'name': 'hiking'
    },
    {
        'name': 'history'
    },
    {
        'name': 'history_edu'
    },
    {
        'name': 'history_toggle_off'
    },
    {
        'name': 'hive'
    },
    {
        'name': 'hls'
    },
    {
        'name': 'hls_off'
    },
    {
        'name': 'holiday_village'
    },
    {
        'name': 'home'
    },
    {
        'name': 'home_max'
    },
    {
        'name': 'home_mini'
    },
    {
        'name': 'home_repair_service'
    },
    {
        'name': 'home_work'
    },
    {
        'name': 'horizontal_distribute'
    },
    {
        'name': 'horizontal_rule'
    },
    {
        'name': 'horizontal_split'
    },
    {
        'name': 'hotel'
    },
    {
        'name': 'hotel_class'
    },
    {
        'name': 'hot_tub'
    },
    {
        'name': 'hourglass_bottom'
    },
    {
        'name': 'hourglass_disabled'
    },
    {
        'name': 'hourglass_empty'
    },
    {
        'name': 'hourglass_full'
    },
    {
        'name': 'hourglass_top'
    },
    {
        'name': 'house'
    },
    {
        'name': 'houseboat'
    },
    {
        'name': 'house_siding'
    },
    {
        'name': 'how_to_reg'
    },
    {
        'name': 'how_to_vote'
    },
    {
        'name': 'html'
    },
    {
        'name': 'http'
    },
    {
        'name': 'https'
    },
    {
        'name': 'hub'
    },
    {
        'name': 'hvac'
    },
    {
        'name': 'h_mobiledata'
    },
    {
        'name': 'h_plus_mobiledata'
    },
    {
        'name': 'icecream'
    },
    {
        'name': 'ice_skating'
    },
    {
        'name': 'image'
    },
    {
        'name': 'imagesearch_roller'
    },
    {
        'name': 'image_aspect_ratio'
    },
    {
        'name': 'image_not_supported'
    },
    {
        'name': 'image_search'
    },
    {
        'name': 'important_devices'
    },
    {
        'name': 'import_contacts'
    },
    {
        'name': 'import_export'
    },
    {
        'name': 'inbox'
    },
    {
        'name': 'incomplete_circle'
    },
    {
        'name': 'indeterminate_check_box'
    },
    {
        'name': 'info'
    },
    {
        'name': 'input'
    },
    {
        'name': 'insert_chart'
    },
    {
        'name': 'insert_chart_outlined'
    },
    {
        'name': 'insert_comment'
    },
    {
        'name': 'insert_drive_file'
    },
    {
        'name': 'insert_emoticon'
    },
    {
        'name': 'insert_invitation'
    },
    {
        'name': 'insert_link'
    },
    {
        'name': 'insert_page_break'
    },
    {
        'name': 'insert_photo'
    },
    {
        'name': 'insights'
    },
    {
        'name': 'install_desktop'
    },
    {
        'name': 'install_mobile'
    },
    {
        'name': 'integration_instructions'
    },
    {
        'name': 'interests'
    },
    {
        'name': 'interpreter_mode'
    },
    {
        'name': 'inventory'
    },
    {
        'name': 'inventory_2'
    },
    {
        'name': 'invert_colors'
    },
    {
        'name': 'invert_colors_off'
    },
    {
        'name': 'ios_share'
    },
    {
        'name': 'iron'
    },
    {
        'name': 'iso'
    },
    {
        'name': 'javascript'
    },
    {
        'name': 'join_full'
    },
    {
        'name': 'join_inner'
    },
    {
        'name': 'join_left'
    },
    {
        'name': 'join_right'
    },
    {
        'name': 'kayaking'
    },
    {
        'name': 'kebab_dining'
    },
    {
        'name': 'key'
    },
    {
        'name': 'keyboard'
    },
    {
        'name': 'keyboard_alt'
    },
    {
        'name': 'keyboard_arrow_down'
    },
    {
        'name': 'keyboard_arrow_left'
    },
    {
        'name': 'keyboard_arrow_right'
    },
    {
        'name': 'keyboard_arrow_up'
    },
    {
        'name': 'keyboard_backspace'
    },
    {
        'name': 'keyboard_capslock'
    },
    {
        'name': 'keyboard_command_key'
    },
    {
        'name': 'keyboard_control_key'
    },
    {
        'name': 'keyboard_double_arrow_down'
    },
    {
        'name': 'keyboard_double_arrow_left'
    },
    {
        'name': 'keyboard_double_arrow_right'
    },
    {
        'name': 'keyboard_double_arrow_up'
    },
    {
        'name': 'keyboard_hide'
    },
    {
        'name': 'keyboard_option_key'
    },
    {
        'name': 'keyboard_return'
    },
    {
        'name': 'keyboard_tab'
    },
    {
        'name': 'keyboard_voice'
    },
    {
        'name': 'key_off'
    },
    {
        'name': 'king_bed'
    },
    {
        'name': 'kitchen'
    },
    {
        'name': 'kitesurfing'
    },
    {
        'name': 'label'
    },
    {
        'name': 'label_important'
    },
    {
        'name': 'label_off'
    },
    {
        'name': 'lan'
    },
    {
        'name': 'landscape'
    },
    {
        'name': 'landslide'
    },
    {
        'name': 'language'
    },
    {
        'name': 'laptop'
    },
    {
        'name': 'laptop_chromebook'
    },
    {
        'name': 'laptop_mac'
    },
    {
        'name': 'laptop_windows'
    },
    {
        'name': 'last_page'
    },
    {
        'name': 'launch'
    },
    {
        'name': 'layers'
    },
    {
        'name': 'layers_clear'
    },
    {
        'name': 'leaderboard'
    },
    {
        'name': 'leak_add'
    },
    {
        'name': 'leak_remove'
    },
    {
        'name': 'legend_toggle'
    },
    {
        'name': 'lens'
    },
    {
        'name': 'lens_blur'
    },
    {
        'name': 'library_add'
    },
    {
        'name': 'library_add_check'
    },
    {
        'name': 'library_books'
    },
    {
        'name': 'library_music'
    },
    {
        'name': 'light'
    },
    {
        'name': 'lightbulb'
    },
    {
        'name': 'lightbulb_circle'
    },
    {
        'name': 'light_mode'
    },
    {
        'name': 'linear_scale'
    },
    {
        'name': 'line_axis'
    },
    {
        'name': 'line_style'
    },
    {
        'name': 'line_weight'
    },
    {
        'name': 'link'
    },
    {
        'name': 'linked_camera'
    },
    {
        'name': 'link_off'
    },
    {
        'name': 'liquor'
    },
    {
        'name': 'list'
    },
    {
        'name': 'list_alt'
    },
    {
        'name': 'live_help'
    },
    {
        'name': 'live_tv'
    },
    {
        'name': 'living'
    },
    {
        'name': 'local_activity'
    },
    {
        'name': 'local_airport'
    },
    {
        'name': 'local_atm'
    },
    {
        'name': 'local_bar'
    },
    {
        'name': 'local_cafe'
    },
    {
        'name': 'local_car_wash'
    },
    {
        'name': 'local_convenience_store'
    },
    {
        'name': 'local_dining'
    },
    {
        'name': 'local_drink'
    },
    {
        'name': 'local_fire_department'
    },
    {
        'name': 'local_florist'
    },
    {
        'name': 'local_gas_station'
    },
    {
        'name': 'local_grocery_store'
    },
    {
        'name': 'local_hospital'
    },
    {
        'name': 'local_hotel'
    },
    {
        'name': 'local_laundry_service'
    },
    {
        'name': 'local_library'
    },
    {
        'name': 'local_mall'
    },
    {
        'name': 'local_movies'
    },
    {
        'name': 'local_offer'
    },
    {
        'name': 'local_parking'
    },
    {
        'name': 'local_pharmacy'
    },
    {
        'name': 'local_phone'
    },
    {
        'name': 'local_pizza'
    },
    {
        'name': 'local_play'
    },
    {
        'name': 'local_police'
    },
    {
        'name': 'local_post_office'
    },
    {
        'name': 'local_printshop'
    },
    {
        'name': 'local_see'
    },
    {
        'name': 'local_shipping'
    },
    {
        'name': 'local_taxi'
    },
    {
        'name': 'location_city'
    },
    {
        'name': 'location_disabled'
    },
    {
        'name': 'location_off'
    },
    {
        'name': 'location_on'
    },
    {
        'name': 'location_searching'
    },
    {
        'name': 'lock'
    },
    {
        'name': 'lock_clock'
    },
    {
        'name': 'lock_open'
    },
    {
        'name': 'lock_person'
    },
    {
        'name': 'lock_reset'
    },
    {
        'name': 'login'
    },
    {
        'name': 'logout'
    },
    {
        'name': 'logo_dev'
    },
    {
        'name': 'looks'
    },
    {
        'name': 'looks_3'
    },
    {
        'name': 'looks_4'
    },
    {
        'name': 'looks_5'
    },
    {
        'name': 'looks_6'
    },
    {
        'name': 'looks_one'
    },
    {
        'name': 'looks_two'
    },
    {
        'name': 'loop'
    },
    {
        'name': 'loupe'
    },
    {
        'name': 'low_priority'
    },
    {
        'name': 'loyalty'
    },
    {
        'name': 'lte_mobiledata'
    },
    {
        'name': 'lte_plus_mobiledata'
    },
    {
        'name': 'luggage'
    },
    {
        'name': 'lunch_dining'
    },
    {
        'name': 'lyrics'
    },
    {
        'name': 'macro_off'
    },
    {
        'name': 'mail'
    },
    {
        'name': 'mail_lock'
    },
    {
        'name': 'mail_outline'
    },
    {
        'name': 'male'
    },
    {
        'name': 'man'
    },
    {
        'name': 'manage_accounts'
    },
    {
        'name': 'manage_history'
    },
    {
        'name': 'manage_search'
    },
    {
        'name': 'man_2'
    },
    {
        'name': 'man_3'
    },
    {
        'name': 'man_4'
    },
    {
        'name': 'map'
    },
    {
        'name': 'maps_home_work'
    },
    {
        'name': 'maps_ugc'
    },
    {
        'name': 'margin'
    },
    {
        'name': 'markunread'
    },
    {
        'name': 'markunread_mailbox'
    },
    {
        'name': 'mark_as_unread'
    },
    {
        'name': 'mark_chat_read'
    },
    {
        'name': 'mark_chat_unread'
    },
    {
        'name': 'mark_email_read'
    },
    {
        'name': 'mark_email_unread'
    },
    {
        'name': 'mark_unread_chat_alt'
    },
    {
        'name': 'masks'
    },
    {
        'name': 'maximize'
    },
    {
        'name': 'mediation'
    },
    {
        'name': 'media_bluetooth_off'
    },
    {
        'name': 'media_bluetooth_on'
    },
    {
        'name': 'medical_information'
    },
    {
        'name': 'medical_services'
    },
    {
        'name': 'medication'
    },
    {
        'name': 'medication_liquid'
    },
    {
        'name': 'meeting_room'
    },
    {
        'name': 'memory'
    },
    {
        'name': 'menu'
    },
    {
        'name': 'menu_book'
    },
    {
        'name': 'menu_open'
    },
    {
        'name': 'merge'
    },
    {
        'name': 'merge_type'
    },
    {
        'name': 'message'
    },
    {
        'name': 'mic'
    },
    {
        'name': 'microwave'
    },
    {
        'name': 'mic_external_off'
    },
    {
        'name': 'mic_external_on'
    },
    {
        'name': 'mic_none'
    },
    {
        'name': 'mic_off'
    },
    {
        'name': 'military_tech'
    },
    {
        'name': 'minimize'
    },
    {
        'name': 'minor_crash'
    },
    {
        'name': 'miscellaneous_services'
    },
    {
        'name': 'missed_video_call'
    },
    {
        'name': 'mms'
    },
    {
        'name': 'mobiledata_off'
    },
    {
        'name': 'mobile_friendly'
    },
    {
        'name': 'mobile_off'
    },
    {
        'name': 'mobile_screen_share'
    },
    {
        'name': 'mode'
    },
    {
        'name': 'model_training'
    },
    {
        'name': 'mode_comment'
    },
    {
        'name': 'mode_edit'
    },
    {
        'name': 'mode_edit_outline'
    },
    {
        'name': 'mode_fan_off'
    },
    {
        'name': 'mode_night'
    },
    {
        'name': 'mode_of_travel'
    },
    {
        'name': 'mode_standby'
    },
    {
        'name': 'monetization_on'
    },
    {
        'name': 'money'
    },
    {
        'name': 'money_off'
    },
    {
        'name': 'money_off_csred'
    },
    {
        'name': 'monitor'
    },
    {
        'name': 'monitor_heart'
    },
    {
        'name': 'monitor_weight'
    },
    {
        'name': 'monochrome_photos'
    },
    {
        'name': 'mood'
    },
    {
        'name': 'mood_bad'
    },
    {
        'name': 'moped'
    },
    {
        'name': 'more'
    },
    {
        'name': 'more_horiz'
    },
    {
        'name': 'more_time'
    },
    {
        'name': 'more_vert'
    },
    {
        'name': 'mosque'
    },
    {
        'name': 'motion_photos_auto'
    },
    {
        'name': 'motion_photos_off'
    },
    {
        'name': 'motion_photos_on'
    },
    {
        'name': 'motion_photos_pause'
    },
    {
        'name': 'motion_photos_paused'
    },
    {
        'name': 'mouse'
    },
    {
        'name': 'move_down'
    },
    {
        'name': 'move_to_inbox'
    },
    {
        'name': 'move_up'
    },
    {
        'name': 'movie'
    },
    {
        'name': 'movie_creation'
    },
    {
        'name': 'movie_filter'
    },
    {
        'name': 'moving'
    },
    {
        'name': 'mp'
    },
    {
        'name': 'multiline_chart'
    },
    {
        'name': 'multiple_stop'
    },
    {
        'name': 'museum'
    },
    {
        'name': 'music_note'
    },
    {
        'name': 'music_off'
    },
    {
        'name': 'music_video'
    },
    {
        'name': 'my_location'
    },
    {
        'name': 'nat'
    },
    {
        'name': 'nature'
    },
    {
        'name': 'nature_people'
    },
    {
        'name': 'navigate_before'
    },
    {
        'name': 'navigate_next'
    },
    {
        'name': 'navigation'
    },
    {
        'name': 'nearby_error'
    },
    {
        'name': 'nearby_off'
    },
    {
        'name': 'near_me'
    },
    {
        'name': 'near_me_disabled'
    },
    {
        'name': 'nest_cam_wired_stand'
    },
    {
        'name': 'network_cell'
    },
    {
        'name': 'network_check'
    },
    {
        'name': 'network_locked'
    },
    {
        'name': 'network_ping'
    },
    {
        'name': 'network_wifi'
    },
    {
        'name': 'network_wifi_1_bar'
    },
    {
        'name': 'network_wifi_2_bar'
    },
    {
        'name': 'network_wifi_3_bar'
    },
    {
        'name': 'newspaper'
    },
    {
        'name': 'new_label'
    },
    {
        'name': 'new_releases'
    },
    {
        'name': 'next_plan'
    },
    {
        'name': 'next_week'
    },
    {
        'name': 'nfc'
    },
    {
        'name': 'nightlife'
    },
    {
        'name': 'nightlight'
    },
    {
        'name': 'nightlight_round'
    },
    {
        'name': 'nights_stay'
    },
    {
        'name': 'night_shelter'
    },
    {
        'name': 'noise_aware'
    },
    {
        'name': 'noise_control_off'
    },
    {
        'name': 'nordic_walking'
    },
    {
        'name': 'north'
    },
    {
        'name': 'north_east'
    },
    {
        'name': 'north_west'
    },
    {
        'name': 'note'
    },
    {
        'name': 'notes'
    },
    {
        'name': 'note_add'
    },
    {
        'name': 'note_alt'
    },
    {
        'name': 'notifications'
    },
    {
        'name': 'notifications_active'
    },
    {
        'name': 'notifications_none'
    },
    {
        'name': 'notifications_off'
    },
    {
        'name': 'notifications_paused'
    },
    {
        'name': 'notification_add'
    },
    {
        'name': 'notification_important'
    },
    {
        'name': 'not_accessible'
    },
    {
        'name': 'not_interested'
    },
    {
        'name': 'not_listed_location'
    },
    {
        'name': 'not_started'
    },
    {
        'name': 'no_accounts'
    },
    {
        'name': 'no_adult_content'
    },
    {
        'name': 'no_backpack'
    },
    {
        'name': 'no_cell'
    },
    {
        'name': 'no_crash'
    },
    {
        'name': 'no_drinks'
    },
    {
        'name': 'no_encryption'
    },
    {
        'name': 'no_encryption_gmailerrorred'
    },
    {
        'name': 'no_flash'
    },
    {
        'name': 'no_food'
    },
    {
        'name': 'no_luggage'
    },
    {
        'name': 'no_meals'
    },
    {
        'name': 'no_meeting_room'
    },
    {
        'name': 'no_photography'
    },
    {
        'name': 'no_sim'
    },
    {
        'name': 'no_stroller'
    },
    {
        'name': 'no_transfer'
    },
    {
        'name': 'numbers'
    },
    {
        'name': 'offline_bolt'
    },
    {
        'name': 'offline_pin'
    },
    {
        'name': 'offline_share'
    },
    {
        'name': 'oil_barrel'
    },
    {
        'name': 'ondemand_video'
    },
    {
        'name': 'online_prediction'
    },
    {
        'name': 'on_device_training'
    },
    {
        'name': 'opacity'
    },
    {
        'name': 'open_in_browser'
    },
    {
        'name': 'open_in_full'
    },
    {
        'name': 'open_in_new'
    },
    {
        'name': 'open_in_new_off'
    },
    {
        'name': 'open_with'
    },
    {
        'name': 'other_houses'
    },
    {
        'name': 'outbound'
    },
    {
        'name': 'outbox'
    },
    {
        'name': 'outdoor_grill'
    },
    {
        'name': 'outlet'
    },
    {
        'name': 'outlined_flag'
    },
    {
        'name': 'output'
    },
    {
        'name': 'padding'
    },
    {
        'name': 'pages'
    },
    {
        'name': 'pageview'
    },
    {
        'name': 'paid'
    },
    {
        'name': 'palette'
    },
    {
        'name': 'panorama'
    },
    {
        'name': 'panorama_fish_eye'
    },
    {
        'name': 'panorama_horizontal'
    },
    {
        'name': 'panorama_horizontal_select'
    },
    {
        'name': 'panorama_photosphere'
    },
    {
        'name': 'panorama_photosphere_select'
    },
    {
        'name': 'panorama_vertical'
    },
    {
        'name': 'panorama_vertical_select'
    },
    {
        'name': 'panorama_wide_angle'
    },
    {
        'name': 'panorama_wide_angle_select'
    },
    {
        'name': 'pan_tool'
    },
    {
        'name': 'pan_tool_alt'
    },
    {
        'name': 'paragliding'
    },
    {
        'name': 'park'
    },
    {
        'name': 'party_mode'
    },
    {
        'name': 'password'
    },
    {
        'name': 'pattern'
    },
    {
        'name': 'pause'
    },
    {
        'name': 'pause_circle'
    },
    {
        'name': 'pause_circle_filled'
    },
    {
        'name': 'pause_circle_outline'
    },
    {
        'name': 'pause_presentation'
    },
    {
        'name': 'payment'
    },
    {
        'name': 'payments'
    },
    {
        'name': 'pedal_bike'
    },
    {
        'name': 'pending'
    },
    {
        'name': 'pending_actions'
    },
    {
        'name': 'pentagon'
    },
    {
        'name': 'people'
    },
    {
        'name': 'people_alt'
    },
    {
        'name': 'people_outline'
    },
    {
        'name': 'percent'
    },
    {
        'name': 'perm_camera_mic'
    },
    {
        'name': 'perm_contact_calendar'
    },
    {
        'name': 'perm_data_setting'
    },
    {
        'name': 'perm_device_information'
    },
    {
        'name': 'perm_identity'
    },
    {
        'name': 'perm_media'
    },
    {
        'name': 'perm_phone_msg'
    },
    {
        'name': 'perm_scan_wifi'
    },
    {
        'name': 'person'
    },
    {
        'name': 'personal_injury'
    },
    {
        'name': 'personal_video'
    },
    {
        'name': 'person_2'
    },
    {
        'name': 'person_3'
    },
    {
        'name': 'person_4'
    },
    {
        'name': 'person_add'
    },
    {
        'name': 'person_add_alt'
    },
    {
        'name': 'person_add_alt_1'
    },
    {
        'name': 'person_add_disabled'
    },
    {
        'name': 'person_off'
    },
    {
        'name': 'person_outline'
    },
    {
        'name': 'person_pin'
    },
    {
        'name': 'person_pin_circle'
    },
    {
        'name': 'person_remove'
    },
    {
        'name': 'person_remove_alt_1'
    },
    {
        'name': 'person_search'
    },
    {
        'name': 'pest_control'
    },
    {
        'name': 'pest_control_rodent'
    },
    {
        'name': 'pets'
    },
    {
        'name': 'phishing'
    },
    {
        'name': 'phone'
    },
    {
        'name': 'phonelink'
    },
    {
        'name': 'phonelink_erase'
    },
    {
        'name': 'phonelink_lock'
    },
    {
        'name': 'phonelink_off'
    },
    {
        'name': 'phonelink_ring'
    },
    {
        'name': 'phonelink_setup'
    },
    {
        'name': 'phone_android'
    },
    {
        'name': 'phone_bluetooth_speaker'
    },
    {
        'name': 'phone_callback'
    },
    {
        'name': 'phone_disabled'
    },
    {
        'name': 'phone_enabled'
    },
    {
        'name': 'phone_forwarded'
    },
    {
        'name': 'phone_iphone'
    },
    {
        'name': 'phone_locked'
    },
    {
        'name': 'phone_missed'
    },
    {
        'name': 'phone_paused'
    },
    {
        'name': 'photo'
    },
    {
        'name': 'photo_album'
    },
    {
        'name': 'photo_camera'
    },
    {
        'name': 'photo_camera_back'
    },
    {
        'name': 'photo_camera_front'
    },
    {
        'name': 'photo_filter'
    },
    {
        'name': 'photo_library'
    },
    {
        'name': 'photo_size_select_actual'
    },
    {
        'name': 'photo_size_select_large'
    },
    {
        'name': 'photo_size_select_small'
    },
    {
        'name': 'php'
    },
    {
        'name': 'piano'
    },
    {
        'name': 'piano_off'
    },
    {
        'name': 'picture_as_pdf'
    },
    {
        'name': 'picture_in_picture'
    },
    {
        'name': 'picture_in_picture_alt'
    },
    {
        'name': 'pie_chart'
    },
    {
        'name': 'pie_chart_outline'
    },
    {
        'name': 'pin'
    },
    {
        'name': 'pinch'
    },
    {
        'name': 'pin_drop'
    },
    {
        'name': 'pin_end'
    },
    {
        'name': 'pin_invoke'
    },
    {
        'name': 'pivot_table_chart'
    },
    {
        'name': 'pix'
    },
    {
        'name': 'place'
    },
    {
        'name': 'plagiarism'
    },
    {
        'name': 'playlist_add'
    },
    {
        'name': 'playlist_add_check'
    },
    {
        'name': 'playlist_add_check_circle'
    },
    {
        'name': 'playlist_add_circle'
    },
    {
        'name': 'playlist_play'
    },
    {
        'name': 'playlist_remove'
    },
    {
        'name': 'play_arrow'
    },
    {
        'name': 'play_circle'
    },
    {
        'name': 'play_circle_filled'
    },
    {
        'name': 'play_circle_outline'
    },
    {
        'name': 'play_disabled'
    },
    {
        'name': 'play_for_work'
    },
    {
        'name': 'play_lesson'
    },
    {
        'name': 'plumbing'
    },
    {
        'name': 'plus_one'
    },
    {
        'name': 'podcasts'
    },
    {
        'name': 'point_of_sale'
    },
    {
        'name': 'policy'
    },
    {
        'name': 'poll'
    },
    {
        'name': 'polyline'
    },
    {
        'name': 'polymer'
    },
    {
        'name': 'pool'
    },
    {
        'name': 'portable_wifi_off'
    },
    {
        'name': 'portrait'
    },
    {
        'name': 'post_add'
    },
    {
        'name': 'power'
    },
    {
        'name': 'power_input'
    },
    {
        'name': 'power_off'
    },
    {
        'name': 'power_settings_new'
    },
    {
        'name': 'precision_manufacturing'
    },
    {
        'name': 'pregnant_woman'
    },
    {
        'name': 'present_to_all'
    },
    {
        'name': 'preview'
    },
    {
        'name': 'price_change'
    },
    {
        'name': 'price_check'
    },
    {
        'name': 'print'
    },
    {
        'name': 'print_disabled'
    },
    {
        'name': 'priority_high'
    },
    {
        'name': 'privacy_tip'
    },
    {
        'name': 'private_connectivity'
    },
    {
        'name': 'production_quantity_limits'
    },
    {
        'name': 'propane'
    },
    {
        'name': 'propane_tank'
    },
    {
        'name': 'psychology'
    },
    {
        'name': 'psychology_alt'
    },
    {
        'name': 'public'
    },
    {
        'name': 'public_off'
    },
    {
        'name': 'publish'
    },
    {
        'name': 'published_with_changes'
    },
    {
        'name': 'punch_clock'
    },
    {
        'name': 'push_pin'
    },
    {
        'name': 'qr_code'
    },
    {
        'name': 'qr_code_2'
    },
    {
        'name': 'qr_code_scanner'
    },
    {
        'name': 'query_builder'
    },
    {
        'name': 'query_stats'
    },
    {
        'name': 'question_answer'
    },
    {
        'name': 'question_mark'
    },
    {
        'name': 'queue'
    },
    {
        'name': 'queue_music'
    },
    {
        'name': 'queue_play_next'
    },
    {
        'name': 'quickreply'
    },
    {
        'name': 'quiz'
    },
    {
        'name': 'radar'
    },
    {
        'name': 'radio'
    },
    {
        'name': 'radio_button_checked'
    },
    {
        'name': 'radio_button_unchecked'
    },
    {
        'name': 'railway_alert'
    },
    {
        'name': 'ramen_dining'
    },
    {
        'name': 'ramp_left'
    },
    {
        'name': 'ramp_right'
    },
    {
        'name': 'rate_review'
    },
    {
        'name': 'raw_off'
    },
    {
        'name': 'raw_on'
    },
    {
        'name': 'read_more'
    },
    {
        'name': 'real_estate_agent'
    },
    {
        'name': 'receipt'
    },
    {
        'name': 'receipt_long'
    },
    {
        'name': 'recent_actors'
    },
    {
        'name': 'recommend'
    },
    {
        'name': 'record_voice_over'
    },
    {
        'name': 'rectangle'
    },
    {
        'name': 'recycling'
    },
    {
        'name': 'redeem'
    },
    {
        'name': 'redo'
    },
    {
        'name': 'reduce_capacity'
    },
    {
        'name': 'refresh'
    },
    {
        'name': 'remember_me'
    },
    {
        'name': 'remove'
    },
    {
        'name': 'remove_circle'
    },
    {
        'name': 'remove_circle_outline'
    },
    {
        'name': 'remove_done'
    },
    {
        'name': 'remove_from_queue'
    },
    {
        'name': 'remove_moderator'
    },
    {
        'name': 'remove_red_eye'
    },
    {
        'name': 'remove_road'
    },
    {
        'name': 'remove_shopping_cart'
    },
    {
        'name': 'reorder'
    },
    {
        'name': 'repartition'
    },
    {
        'name': 'repeat'
    },
    {
        'name': 'repeat_on'
    },
    {
        'name': 'repeat_one'
    },
    {
        'name': 'repeat_one_on'
    },
    {
        'name': 'replay'
    },
    {
        'name': 'replay_10'
    },
    {
        'name': 'replay_30'
    },
    {
        'name': 'replay_5'
    },
    {
        'name': 'replay_circle_filled'
    },
    {
        'name': 'reply'
    },
    {
        'name': 'reply_all'
    },
    {
        'name': 'report'
    },
    {
        'name': 'report_gmailerrorred'
    },
    {
        'name': 'report_off'
    },
    {
        'name': 'report_problem'
    },
    {
        'name': 'request_page'
    },
    {
        'name': 'request_quote'
    },
    {
        'name': 'reset_tv'
    },
    {
        'name': 'restart_alt'
    },
    {
        'name': 'restaurant'
    },
    {
        'name': 'restaurant_menu'
    },
    {
        'name': 'restore'
    },
    {
        'name': 'restore_from_trash'
    },
    {
        'name': 'restore_page'
    },
    {
        'name': 'reviews'
    },
    {
        'name': 'rice_bowl'
    },
    {
        'name': 'ring_volume'
    },
    {
        'name': 'rocket'
    },
    {
        'name': 'rocket_launch'
    },
    {
        'name': 'roller_shades'
    },
    {
        'name': 'roller_shades_closed'
    },
    {
        'name': 'roller_skating'
    },
    {
        'name': 'roofing'
    },
    {
        'name': 'room'
    },
    {
        'name': 'room_preferences'
    },
    {
        'name': 'room_service'
    },
    {
        'name': 'rotate_90_degrees_ccw'
    },
    {
        'name': 'rotate_90_degrees_cw'
    },
    {
        'name': 'rotate_left'
    },
    {
        'name': 'rotate_right'
    },
    {
        'name': 'roundabout_left'
    },
    {
        'name': 'roundabout_right'
    },
    {
        'name': 'rounded_corner'
    },
    {
        'name': 'route'
    },
    {
        'name': 'router'
    },
    {
        'name': 'rowing'
    },
    {
        'name': 'rss_feed'
    },
    {
        'name': 'rsvp'
    },
    {
        'name': 'rtt'
    },
    {
        'name': 'rule'
    },
    {
        'name': 'rule_folder'
    },
    {
        'name': 'running_with_errors'
    },
    {
        'name': 'run_circle'
    },
    {
        'name': 'rv_hookup'
    },
    {
        'name': 'r_mobiledata'
    },
    {
        'name': 'safety_check'
    },
    {
        'name': 'safety_divider'
    },
    {
        'name': 'sailing'
    },
    {
        'name': 'sanitizer'
    },
    {
        'name': 'satellite'
    },
    {
        'name': 'satellite_alt'
    },
    {
        'name': 'save'
    },
    {
        'name': 'saved_search'
    },
    {
        'name': 'save_alt'
    },
    {
        'name': 'save_as'
    },
    {
        'name': 'savings'
    },
    {
        'name': 'scale'
    },
    {
        'name': 'scanner'
    },
    {
        'name': 'scatter_plot'
    },
    {
        'name': 'schedule'
    },
    {
        'name': 'schedule_send'
    },
    {
        'name': 'schema'
    },
    {
        'name': 'school'
    },
    {
        'name': 'science'
    },
    {
        'name': 'score'
    },
    {
        'name': 'scoreboard'
    },
    {
        'name': 'screenshot'
    },
    {
        'name': 'screenshot_monitor'
    },
    {
        'name': 'screen_lock_landscape'
    },
    {
        'name': 'screen_lock_portrait'
    },
    {
        'name': 'screen_lock_rotation'
    },
    {
        'name': 'screen_rotation'
    },
    {
        'name': 'screen_rotation_alt'
    },
    {
        'name': 'screen_search_desktop'
    },
    {
        'name': 'screen_share'
    },
    {
        'name': 'scuba_diving'
    },
    {
        'name': 'sd'
    },
    {
        'name': 'sd_card'
    },
    {
        'name': 'sd_card_alert'
    },
    {
        'name': 'sd_storage'
    },
    {
        'name': 'search'
    },
    {
        'name': 'search_off'
    },
    {
        'name': 'security'
    },
    {
        'name': 'security_update'
    },
    {
        'name': 'security_update_good'
    },
    {
        'name': 'security_update_warning'
    },
    {
        'name': 'segment'
    },
    {
        'name': 'select_all'
    },
    {
        'name': 'self_improvement'
    },
    {
        'name': 'sell'
    },
    {
        'name': 'send'
    },
    {
        'name': 'send_and_archive'
    },
    {
        'name': 'send_time_extension'
    },
    {
        'name': 'send_to_mobile'
    },
    {
        'name': 'sensors'
    },
    {
        'name': 'sensors_off'
    },
    {
        'name': 'sensor_door'
    },
    {
        'name': 'sensor_occupied'
    },
    {
        'name': 'sensor_window'
    },
    {
        'name': 'sentiment_dissatisfied'
    },
    {
        'name': 'sentiment_neutral'
    },
    {
        'name': 'sentiment_satisfied'
    },
    {
        'name': 'sentiment_satisfied_alt'
    },
    {
        'name': 'sentiment_very_dissatisfied'
    },
    {
        'name': 'sentiment_very_satisfied'
    },
    {
        'name': 'settings'
    },
    {
        'name': 'settings_accessibility'
    },
    {
        'name': 'settings_applications'
    },
    {
        'name': 'settings_backup_restore'
    },
    {
        'name': 'settings_bluetooth'
    },
    {
        'name': 'settings_brightness'
    },
    {
        'name': 'settings_cell'
    },
    {
        'name': 'settings_ethernet'
    },
    {
        'name': 'settings_input_antenna'
    },
    {
        'name': 'settings_input_component'
    },
    {
        'name': 'settings_input_composite'
    },
    {
        'name': 'settings_input_hdmi'
    },
    {
        'name': 'settings_input_svideo'
    },
    {
        'name': 'settings_overscan'
    },
    {
        'name': 'settings_phone'
    },
    {
        'name': 'settings_power'
    },
    {
        'name': 'settings_remote'
    },
    {
        'name': 'settings_suggest'
    },
    {
        'name': 'settings_system_daydream'
    },
    {
        'name': 'settings_voice'
    },
    {
        'name': 'set_meal'
    },
    {
        'name': 'severe_cold'
    },
    {
        'name': 'shape_line'
    },
    {
        'name': 'share'
    },
    {
        'name': 'share_location'
    },
    {
        'name': 'shield'
    },
    {
        'name': 'shield_moon'
    },
    {
        'name': 'shop'
    },
    {
        'name': 'shopping_bag'
    },
    {
        'name': 'shopping_basket'
    },
    {
        'name': 'shopping_cart'
    },
    {
        'name': 'shopping_cart_checkout'
    },
    {
        'name': 'shop_2'
    },
    {
        'name': 'shop_two'
    },
    {
        'name': 'shortcut'
    },
    {
        'name': 'short_text'
    },
    {
        'name': 'shower'
    },
    {
        'name': 'show_chart'
    },
    {
        'name': 'shuffle'
    },
    {
        'name': 'shuffle_on'
    },
    {
        'name': 'shutter_speed'
    },
    {
        'name': 'sick'
    },
    {
        'name': 'signal_cellular_0_bar'
    },
    {
        'name': 'signal_cellular_4_bar'
    },
    {
        'name': 'signal_cellular_alt'
    },
    {
        'name': 'signal_cellular_alt_1_bar'
    },
    {
        'name': 'signal_cellular_alt_2_bar'
    },
    {
        'name': 'signal_cellular_connected_no_internet_0_bar'
    },
    {
        'name': 'signal_cellular_connected_no_internet_4_bar'
    },
    {
        'name': 'signal_cellular_nodata'
    },
    {
        'name': 'signal_cellular_no_sim'
    },
    {
        'name': 'signal_cellular_null'
    },
    {
        'name': 'signal_cellular_off'
    },
    {
        'name': 'signal_wifi_0_bar'
    },
    {
        'name': 'signal_wifi_4_bar'
    },
    {
        'name': 'signal_wifi_4_bar_lock'
    },
    {
        'name': 'signal_wifi_bad'
    },
    {
        'name': 'signal_wifi_connected_no_internet_4'
    },
    {
        'name': 'signal_wifi_off'
    },
    {
        'name': 'signal_wifi_statusbar_4_bar'
    },
    {
        'name': 'signal_wifi_statusbar_connected_no_internet_4'
    },
    {
        'name': 'signal_wifi_statusbar_null'
    },
    {
        'name': 'signpost'
    },
    {
        'name': 'sign_language'
    },
    {
        'name': 'sim_card'
    },
    {
        'name': 'sim_card_alert'
    },
    {
        'name': 'sim_card_download'
    },
    {
        'name': 'single_bed'
    },
    {
        'name': 'sip'
    },
    {
        'name': 'skateboarding'
    },
    {
        'name': 'skip_next'
    },
    {
        'name': 'skip_previous'
    },
    {
        'name': 'sledding'
    },
    {
        'name': 'slideshow'
    },
    {
        'name': 'slow_motion_video'
    },
    {
        'name': 'smartphone'
    },
    {
        'name': 'smart_button'
    },
    {
        'name': 'smart_display'
    },
    {
        'name': 'smart_screen'
    },
    {
        'name': 'smart_toy'
    },
    {
        'name': 'smoke_free'
    },
    {
        'name': 'smoking_rooms'
    },
    {
        'name': 'sms'
    },
    {
        'name': 'sms_failed'
    },
    {
        'name': 'snippet_folder'
    },
    {
        'name': 'snooze'
    },
    {
        'name': 'snowboarding'
    },
    {
        'name': 'snowmobile'
    },
    {
        'name': 'snowshoeing'
    },
    {
        'name': 'soap'
    },
    {
        'name': 'social_distance'
    },
    {
        'name': 'solar_power'
    },
    {
        'name': 'sort'
    },
    {
        'name': 'sort_by_alpha'
    },
    {
        'name': 'sos'
    },
    {
        'name': 'soup_kitchen'
    },
    {
        'name': 'source'
    },
    {
        'name': 'south'
    },
    {
        'name': 'south_america'
    },
    {
        'name': 'south_east'
    },
    {
        'name': 'south_west'
    },
    {
        'name': 'spa'
    },
    {
        'name': 'space_bar'
    },
    {
        'name': 'space_dashboard'
    },
    {
        'name': 'spatial_audio'
    },
    {
        'name': 'spatial_audio_off'
    },
    {
        'name': 'spatial_tracking'
    },
    {
        'name': 'speaker'
    },
    {
        'name': 'speaker_group'
    },
    {
        'name': 'speaker_notes'
    },
    {
        'name': 'speaker_notes_off'
    },
    {
        'name': 'speaker_phone'
    },
    {
        'name': 'speed'
    },
    {
        'name': 'spellcheck'
    },
    {
        'name': 'splitscreen'
    },
    {
        'name': 'spoke'
    },
    {
        'name': 'sports'
    },
    {
        'name': 'sports_bar'
    },
    {
        'name': 'sports_baseball'
    },
    {
        'name': 'sports_basketball'
    },
    {
        'name': 'sports_cricket'
    },
    {
        'name': 'sports_esports'
    },
    {
        'name': 'sports_football'
    },
    {
        'name': 'sports_golf'
    },
    {
        'name': 'sports_gymnastics'
    },
    {
        'name': 'sports_handball'
    },
    {
        'name': 'sports_hockey'
    },
    {
        'name': 'sports_kabaddi'
    },
    {
        'name': 'sports_martial_arts'
    },
    {
        'name': 'sports_mma'
    },
    {
        'name': 'sports_motorsports'
    },
    {
        'name': 'sports_rugby'
    },
    {
        'name': 'sports_score'
    },
    {
        'name': 'sports_soccer'
    },
    {
        'name': 'sports_tennis'
    },
    {
        'name': 'sports_volleyball'
    },
    {
        'name': 'square'
    },
    {
        'name': 'square_foot'
    },
    {
        'name': 'ssid_chart'
    },
    {
        'name': 'stacked_bar_chart'
    },
    {
        'name': 'stacked_line_chart'
    },
    {
        'name': 'stadium'
    },
    {
        'name': 'stairs'
    },
    {
        'name': 'star'
    },
    {
        'name': 'stars'
    },
    {
        'name': 'start'
    },
    {
        'name': 'star_border'
    },
    {
        'name': 'star_border_purple500'
    },
    {
        'name': 'star_half'
    },
    {
        'name': 'star_outline'
    },
    {
        'name': 'star_purple500'
    },
    {
        'name': 'star_rate'
    },
    {
        'name': 'stay_current_landscape'
    },
    {
        'name': 'stay_current_portrait'
    },
    {
        'name': 'stay_primary_landscape'
    },
    {
        'name': 'stay_primary_portrait'
    },
    {
        'name': 'sticky_note_2'
    },
    {
        'name': 'stop'
    },
    {
        'name': 'stop_circle'
    },
    {
        'name': 'stop_screen_share'
    },
    {
        'name': 'storage'
    },
    {
        'name': 'store'
    },
    {
        'name': 'storefront'
    },
    {
        'name': 'store_mall_directory'
    },
    {
        'name': 'storm'
    },
    {
        'name': 'straight'
    },
    {
        'name': 'straighten'
    },
    {
        'name': 'stream'
    },
    {
        'name': 'streetview'
    },
    {
        'name': 'strikethrough_s'
    },
    {
        'name': 'stroller'
    },
    {
        'name': 'style'
    },
    {
        'name': 'subdirectory_arrow_left'
    },
    {
        'name': 'subdirectory_arrow_right'
    },
    {
        'name': 'subject'
    },
    {
        'name': 'subscript'
    },
    {
        'name': 'subscriptions'
    },
    {
        'name': 'subtitles'
    },
    {
        'name': 'subtitles_off'
    },
    {
        'name': 'subway'
    },
    {
        'name': 'summarize'
    },
    {
        'name': 'superscript'
    },
    {
        'name': 'supervised_user_circle'
    },
    {
        'name': 'supervisor_account'
    },
    {
        'name': 'support'
    },
    {
        'name': 'support_agent'
    },
    {
        'name': 'surfing'
    },
    {
        'name': 'surround_sound'
    },
    {
        'name': 'swap_calls'
    },
    {
        'name': 'swap_horiz'
    },
    {
        'name': 'swap_horizontal_circle'
    },
    {
        'name': 'swap_vert'
    },
    {
        'name': 'swap_vertical_circle'
    },
    {
        'name': 'swipe'
    },
    {
        'name': 'swipe_down'
    },
    {
        'name': 'swipe_down_alt'
    },
    {
        'name': 'swipe_left'
    },
    {
        'name': 'swipe_left_alt'
    },
    {
        'name': 'swipe_right'
    },
    {
        'name': 'swipe_right_alt'
    },
    {
        'name': 'swipe_up'
    },
    {
        'name': 'swipe_up_alt'
    },
    {
        'name': 'swipe_vertical'
    },
    {
        'name': 'switch_access_shortcut'
    },
    {
        'name': 'switch_access_shortcut_add'
    },
    {
        'name': 'switch_account'
    },
    {
        'name': 'switch_camera'
    },
    {
        'name': 'switch_left'
    },
    {
        'name': 'switch_right'
    },
    {
        'name': 'switch_video'
    },
    {
        'name': 'synagogue'
    },
    {
        'name': 'sync'
    },
    {
        'name': 'sync_alt'
    },
    {
        'name': 'sync_disabled'
    },
    {
        'name': 'sync_lock'
    },
    {
        'name': 'sync_problem'
    },
    {
        'name': 'system_security_update'
    },
    {
        'name': 'system_security_update_good'
    },
    {
        'name': 'system_security_update_warning'
    },
    {
        'name': 'system_update'
    },
    {
        'name': 'system_update_alt'
    },
    {
        'name': 'tab'
    },
    {
        'name': 'tablet'
    },
    {
        'name': 'tablet_android'
    },
    {
        'name': 'tablet_mac'
    },
    {
        'name': 'table_bar'
    },
    {
        'name': 'table_chart'
    },
    {
        'name': 'table_restaurant'
    },
    {
        'name': 'table_rows'
    },
    {
        'name': 'table_view'
    },
    {
        'name': 'tab_unselected'
    },
    {
        'name': 'tag'
    },
    {
        'name': 'tag_faces'
    },
    {
        'name': 'takeout_dining'
    },
    {
        'name': 'tapas'
    },
    {
        'name': 'tap_and_play'
    },
    {
        'name': 'task'
    },
    {
        'name': 'task_alt'
    },
    {
        'name': 'taxi_alert'
    },
    {
        'name': 'temple_buddhist'
    },
    {
        'name': 'temple_hindu'
    },
    {
        'name': 'terminal'
    },
    {
        'name': 'terrain'
    },
    {
        'name': 'textsms'
    },
    {
        'name': 'texture'
    },
    {
        'name': 'text_decrease'
    },
    {
        'name': 'text_fields'
    },
    {
        'name': 'text_format'
    },
    {
        'name': 'text_increase'
    },
    {
        'name': 'text_rotate_up'
    },
    {
        'name': 'text_rotate_vertical'
    },
    {
        'name': 'text_rotation_angledown'
    },
    {
        'name': 'text_rotation_angleup'
    },
    {
        'name': 'text_rotation_down'
    },
    {
        'name': 'text_rotation_none'
    },
    {
        'name': 'text_snippet'
    },
    {
        'name': 'theaters'
    },
    {
        'name': 'theater_comedy'
    },
    {
        'name': 'thermostat'
    },
    {
        'name': 'thermostat_auto'
    },
    {
        'name': 'thumbs_up_down'
    },
    {
        'name': 'thumb_down'
    },
    {
        'name': 'thumb_down_alt'
    },
    {
        'name': 'thumb_down_off_alt'
    },
    {
        'name': 'thumb_up'
    },
    {
        'name': 'thumb_up_alt'
    },
    {
        'name': 'thumb_up_off_alt'
    },
    {
        'name': 'thunderstorm'
    },
    {
        'name': 'timelapse'
    },
    {
        'name': 'timeline'
    },
    {
        'name': 'timer'
    },
    {
        'name': 'timer_10'
    },
    {
        'name': 'timer_10_select'
    },
    {
        'name': 'timer_3'
    },
    {
        'name': 'timer_3_select'
    },
    {
        'name': 'timer_off'
    },
    {
        'name': 'time_to_leave'
    },
    {
        'name': 'tips_and_updates'
    },
    {
        'name': 'tire_repair'
    },
    {
        'name': 'title'
    },
    {
        'name': 'toc'
    },
    {
        'name': 'today'
    },
    {
        'name': 'toggle_off'
    },
    {
        'name': 'toggle_on'
    },
    {
        'name': 'token'
    },
    {
        'name': 'toll'
    },
    {
        'name': 'tonality'
    },
    {
        'name': 'topic'
    },
    {
        'name': 'tornado'
    },
    {
        'name': 'touch_app'
    },
    {
        'name': 'tour'
    },
    {
        'name': 'toys'
    },
    {
        'name': 'track_changes'
    },
    {
        'name': 'traffic'
    },
    {
        'name': 'train'
    },
    {
        'name': 'tram'
    },
    {
        'name': 'transcribe'
    },
    {
        'name': 'transfer_within_a_station'
    },
    {
        'name': 'transform'
    },
    {
        'name': 'transgender'
    },
    {
        'name': 'transit_enterexit'
    },
    {
        'name': 'translate'
    },
    {
        'name': 'travel_explore'
    },
    {
        'name': 'trending_down'
    },
    {
        'name': 'trending_flat'
    },
    {
        'name': 'trending_up'
    },
    {
        'name': 'trip_origin'
    },
    {
        'name': 'troubleshoot'
    },
    {
        'name': 'try'
    },
    {
        'name': 'tsunami'
    },
    {
        'name': 'tty'
    },
    {
        'name': 'tune'
    },
    {
        'name': 'tungsten'
    },
    {
        'name': 'turned_in'
    },
    {
        'name': 'turned_in_not'
    },
    {
        'name': 'turn_left'
    },
    {
        'name': 'turn_right'
    },
    {
        'name': 'turn_sharp_left'
    },
    {
        'name': 'turn_sharp_right'
    },
    {
        'name': 'turn_slight_left'
    },
    {
        'name': 'turn_slight_right'
    },
    {
        'name': 'tv'
    },
    {
        'name': 'tv_off'
    },
    {
        'name': 'two_wheeler'
    },
    {
        'name': 'type_specimen'
    },
    {
        'name': 'umbrella'
    },
    {
        'name': 'unarchive'
    },
    {
        'name': 'undo'
    },
    {
        'name': 'unfold_less'
    },
    {
        'name': 'unfold_less_double'
    },
    {
        'name': 'unfold_more'
    },
    {
        'name': 'unfold_more_double'
    },
    {
        'name': 'unpublished'
    },
    {
        'name': 'unsubscribe'
    },
    {
        'name': 'upcoming'
    },
    {
        'name': 'update'
    },
    {
        'name': 'update_disabled'
    },
    {
        'name': 'upgrade'
    },
    {
        'name': 'upload'
    },
    {
        'name': 'upload_file'
    },
    {
        'name': 'usb'
    },
    {
        'name': 'usb_off'
    },
    {
        'name': 'u_turn_left'
    },
    {
        'name': 'u_turn_right'
    },
    {
        'name': 'vaccines'
    },
    {
        'name': 'vape_free'
    },
    {
        'name': 'vaping_rooms'
    },
    {
        'name': 'verified'
    },
    {
        'name': 'verified_user'
    },
    {
        'name': 'vertical_align_bottom'
    },
    {
        'name': 'vertical_align_center'
    },
    {
        'name': 'vertical_align_top'
    },
    {
        'name': 'vertical_distribute'
    },
    {
        'name': 'vertical_shades'
    },
    {
        'name': 'vertical_shades_closed'
    },
    {
        'name': 'vertical_split'
    },
    {
        'name': 'vibration'
    },
    {
        'name': 'videocam'
    },
    {
        'name': 'videocam_off'
    },
    {
        'name': 'videogame_asset'
    },
    {
        'name': 'videogame_asset_off'
    },
    {
        'name': 'video_call'
    },
    {
        'name': 'video_camera_back'
    },
    {
        'name': 'video_camera_front'
    },
    {
        'name': 'video_chat'
    },
    {
        'name': 'video_file'
    },
    {
        'name': 'video_label'
    },
    {
        'name': 'video_library'
    },
    {
        'name': 'video_settings'
    },
    {
        'name': 'video_stable'
    },
    {
        'name': 'view_agenda'
    },
    {
        'name': 'view_array'
    },
    {
        'name': 'view_carousel'
    },
    {
        'name': 'view_column'
    },
    {
        'name': 'view_comfy'
    },
    {
        'name': 'view_comfy_alt'
    },
    {
        'name': 'view_compact'
    },
    {
        'name': 'view_compact_alt'
    },
    {
        'name': 'view_cozy'
    },
    {
        'name': 'view_day'
    },
    {
        'name': 'view_headline'
    },
    {
        'name': 'view_in_ar'
    },
    {
        'name': 'view_kanban'
    },
    {
        'name': 'view_list'
    },
    {
        'name': 'view_module'
    },
    {
        'name': 'view_quilt'
    },
    {
        'name': 'view_sidebar'
    },
    {
        'name': 'view_stream'
    },
    {
        'name': 'view_timeline'
    },
    {
        'name': 'view_week'
    },
    {
        'name': 'vignette'
    },
    {
        'name': 'villa'
    },
    {
        'name': 'visibility'
    },
    {
        'name': 'visibility_off'
    },
    {
        'name': 'voicemail'
    },
    {
        'name': 'voice_chat'
    },
    {
        'name': 'voice_over_off'
    },
    {
        'name': 'volcano'
    },
    {
        'name': 'volume_down'
    },
    {
        'name': 'volume_mute'
    },
    {
        'name': 'volume_off'
    },
    {
        'name': 'volume_up'
    },
    {
        'name': 'volunteer_activism'
    },
    {
        'name': 'vpn_key'
    },
    {
        'name': 'vpn_key_off'
    },
    {
        'name': 'vpn_lock'
    },
    {
        'name': 'vrpano'
    },
    {
        'name': 'wallet'
    },
    {
        'name': 'wallpaper'
    },
    {
        'name': 'warehouse'
    },
    {
        'name': 'warning'
    },
    {
        'name': 'warning_amber'
    },
    {
        'name': 'wash'
    },
    {
        'name': 'watch'
    },
    {
        'name': 'watch_later'
    },
    {
        'name': 'watch_off'
    },
    {
        'name': 'water'
    },
    {
        'name': 'waterfall_chart'
    },
    {
        'name': 'water_damage'
    },
    {
        'name': 'water_drop'
    },
    {
        'name': 'waves'
    },
    {
        'name': 'waving_hand'
    },
    {
        'name': 'wb_auto'
    },
    {
        'name': 'wb_cloudy'
    },
    {
        'name': 'wb_incandescent'
    },
    {
        'name': 'wb_iridescent'
    },
    {
        'name': 'wb_shade'
    },
    {
        'name': 'wb_sunny'
    },
    {
        'name': 'wb_twilight'
    },
    {
        'name': 'wc'
    },
    {
        'name': 'web'
    },
    {
        'name': 'webhook'
    },
    {
        'name': 'web_asset'
    },
    {
        'name': 'web_asset_off'
    },
    {
        'name': 'web_stories'
    },
    {
        'name': 'weekend'
    },
    {
        'name': 'west'
    },
    {
        'name': 'whatshot'
    },
    {
        'name': 'wheelchair_pickup'
    },
    {
        'name': 'where_to_vote'
    },
    {
        'name': 'widgets'
    },
    {
        'name': 'width_full'
    },
    {
        'name': 'width_normal'
    },
    {
        'name': 'width_wide'
    },
    {
        'name': 'wifi'
    },
    {
        'name': 'wifi_1_bar'
    },
    {
        'name': 'wifi_2_bar'
    },
    {
        'name': 'wifi_calling'
    },
    {
        'name': 'wifi_calling_3'
    },
    {
        'name': 'wifi_channel'
    },
    {
        'name': 'wifi_find'
    },
    {
        'name': 'wifi_lock'
    },
    {
        'name': 'wifi_off'
    },
    {
        'name': 'wifi_password'
    },
    {
        'name': 'wifi_protected_setup'
    },
    {
        'name': 'wifi_tethering'
    },
    {
        'name': 'wifi_tethering_error'
    },
    {
        'name': 'wifi_tethering_off'
    },
    {
        'name': 'window'
    },
    {
        'name': 'wind_power'
    },
    {
        'name': 'wine_bar'
    },
    {
        'name': 'woman'
    },
    {
        'name': 'woman_2'
    },
    {
        'name': 'work'
    },
    {
        'name': 'workspaces'
    },
    {
        'name': 'workspace_premium'
    },
    {
        'name': 'work_history'
    },
    {
        'name': 'work_off'
    },
    {
        'name': 'work_outline'
    },
    {
        'name': 'wrap_text'
    },
    {
        'name': 'wrong_location'
    },
    {
        'name': 'wysiwyg'
    },
    {
        'name': 'yard'
    },
    {
        'name': 'youtube_searched_for'
    },
    {
        'name': 'zoom_in'
    },
    {
        'name': 'zoom_in_map'
    },
    {
        'name': 'zoom_out'
    },
    {
        'name': 'zoom_out_map'
    },
    {
        'name': 'heroicons_outline:academic-cap'
    },
    {
        'name': 'heroicons_outline:adjustments'
    },
    {
        'name': 'heroicons_outline:arrow-circle-down'
    },
    {
        'name': 'heroicons_outline:annotation'
    },
    {
        'name': 'heroicons_outline:arrow-circle-left'
    },
    {
        'name': 'heroicons_outline:archive'
    },
    {
        'name': 'heroicons_outline:arrow-circle-right'
    },
    {
        'name': 'heroicons_outline:arrow-circle-up'
    },
    {
        'name': 'heroicons_outline:arrow-down'
    },
    {
        'name': 'heroicons_outline:arrow-left'
    },
    {
        'name': 'heroicons_outline:arrow-narrow-down'
    },
    {
        'name': 'heroicons_outline:arrow-narrow-left'
    },
    {
        'name': 'heroicons_outline:arrow-narrow-right'
    },
    {
        'name': 'heroicons_outline:arrow-narrow-up'
    },
    {
        'name': 'heroicons_outline:arrow-right'
    },
    {
        'name': 'heroicons_outline:arrow-sm-down'
    },
    {
        'name': 'heroicons_outline:arrow-sm-right'
    },
    {
        'name': 'heroicons_outline:arrow-sm-left'
    },
    {
        'name': 'heroicons_outline:arrow-sm-up'
    },
    {
        'name': 'heroicons_outline:arrow-up'
    },
    {
        'name': 'heroicons_outline:arrows-expand'
    },
    {
        'name': 'heroicons_outline:at-symbol'
    },
    {
        'name': 'heroicons_outline:backspace'
    },
    {
        'name': 'heroicons_outline:badge-check'
    },
    {
        'name': 'heroicons_outline:ban'
    },
    {
        'name': 'heroicons_outline:beaker'
    },
    {
        'name': 'heroicons_outline:bell'
    },
    {
        'name': 'heroicons_outline:book-open'
    },
    {
        'name': 'heroicons_outline:bookmark-alt'
    },
    {
        'name': 'heroicons_outline:bookmark'
    },
    {
        'name': 'heroicons_outline:briefcase'
    },
    {
        'name': 'heroicons_outline:cake'
    },
    {
        'name': 'heroicons_outline:calculator'
    },
    {
        'name': 'heroicons_outline:calendar'
    },
    {
        'name': 'heroicons_outline:camera'
    },
    {
        'name': 'heroicons_outline:cash'
    },
    {
        'name': 'heroicons_outline:chart-bar'
    },
    {
        'name': 'heroicons_outline:chart-pie'
    },
    {
        'name': 'heroicons_outline:chart-square-bar'
    },
    {
        'name': 'heroicons_outline:chat-alt-2'
    },
    {
        'name': 'heroicons_outline:chat-alt'
    },
    {
        'name': 'heroicons_outline:chat'
    },
    {
        'name': 'heroicons_outline:check-circle'
    },
    {
        'name': 'heroicons_outline:check'
    },
    {
        'name': 'heroicons_outline:chevron-double-down'
    },
    {
        'name': 'heroicons_outline:chevron-double-right'
    },
    {
        'name': 'heroicons_outline:chevron-down'
    },
    {
        'name': 'heroicons_outline:chevron-double-up'
    },
    {
        'name': 'heroicons_outline:chevron-left'
    },
    {
        'name': 'heroicons_outline:chevron-right'
    },
    {
        'name': 'heroicons_outline:chip'
    },
    {
        'name': 'heroicons_outline:chevron-up'
    },
    {
        'name': 'heroicons_outline:clipboard-check'
    },
    {
        'name': 'heroicons_outline:clipboard-copy'
    },
    {
        'name': 'heroicons_outline:clipboard-list'
    },
    {
        'name': 'heroicons_outline:clipboard'
    },
    {
        'name': 'heroicons_outline:chevron-double-left'
    },
    {
        'name': 'heroicons_outline:clock'
    },
    {
        'name': 'heroicons_outline:cloud-upload'
    },
    {
        'name': 'heroicons_outline:cloud-download'
    },
    {
        'name': 'heroicons_outline:cloud'
    },
    {
        'name': 'heroicons_outline:code'
    },
    {
        'name': 'heroicons_outline:cog'
    },
    {
        'name': 'heroicons_outline:collection'
    },
    {
        'name': 'heroicons_outline:color-swatch'
    },
    {
        'name': 'heroicons_outline:credit-card'
    },
    {
        'name': 'heroicons_outline:cube-transparent'
    },
    {
        'name': 'heroicons_outline:cube'
    },
    {
        'name': 'heroicons_outline:currency-bangladeshi'
    },
    {
        'name': 'heroicons_outline:currency-dollar'
    },
    {
        'name': 'heroicons_outline:currency-euro'
    },
    {
        'name': 'heroicons_outline:currency-pound'
    },
    {
        'name': 'heroicons_outline:currency-rupee'
    },
    {
        'name': 'heroicons_outline:currency-yen'
    },
    {
        'name': 'heroicons_outline:cursor-click'
    },
    {
        'name': 'heroicons_outline:database'
    },
    {
        'name': 'heroicons_outline:desktop-computer'
    },
    {
        'name': 'heroicons_outline:device-mobile'
    },
    {
        'name': 'heroicons_outline:device-tablet'
    },
    {
        'name': 'heroicons_outline:document-add'
    },
    {
        'name': 'heroicons_outline:document-download'
    },
    {
        'name': 'heroicons_outline:document-duplicate'
    },
    {
        'name': 'heroicons_outline:document-remove'
    },
    {
        'name': 'heroicons_outline:document-report'
    },
    {
        'name': 'heroicons_outline:document-search'
    },
    {
        'name': 'heroicons_outline:document-text'
    },
    {
        'name': 'heroicons_outline:document'
    },
    {
        'name': 'heroicons_outline:dots-circle-horizontal'
    },
    {
        'name': 'heroicons_outline:dots-horizontal'
    },
    {
        'name': 'heroicons_outline:dots-vertical'
    },
    {
        'name': 'heroicons_outline:download'
    },
    {
        'name': 'heroicons_outline:duplicate'
    },
    {
        'name': 'heroicons_outline:emoji-happy'
    },
    {
        'name': 'heroicons_outline:exclamation-circle'
    },
    {
        'name': 'heroicons_outline:emoji-sad'
    },
    {
        'name': 'heroicons_outline:exclamation'
    },
    {
        'name': 'heroicons_outline:external-link'
    },
    {
        'name': 'heroicons_outline:eye-off'
    },
    {
        'name': 'heroicons_outline:fast-forward'
    },
    {
        'name': 'heroicons_outline:eye'
    },
    {
        'name': 'heroicons_outline:film'
    },
    {
        'name': 'heroicons_outline:filter'
    },
    {
        'name': 'heroicons_outline:finger-print'
    },
    {
        'name': 'heroicons_outline:flag'
    },
    {
        'name': 'heroicons_outline:folder-add'
    },
    {
        'name': 'heroicons_outline:fire'
    },
    {
        'name': 'heroicons_outline:folder-download'
    },
    {
        'name': 'heroicons_outline:folder-open'
    },
    {
        'name': 'heroicons_outline:folder-remove'
    },
    {
        'name': 'heroicons_outline:folder'
    },
    {
        'name': 'heroicons_outline:gift'
    },
    {
        'name': 'heroicons_outline:globe-alt'
    },
    {
        'name': 'heroicons_outline:globe'
    },
    {
        'name': 'heroicons_outline:hand'
    },
    {
        'name': 'heroicons_outline:hashtag'
    },
    {
        'name': 'heroicons_outline:heart'
    },
    {
        'name': 'heroicons_outline:home'
    },
    {
        'name': 'heroicons_outline:identification'
    },
    {
        'name': 'heroicons_outline:inbox-in'
    },
    {
        'name': 'heroicons_outline:inbox'
    },
    {
        'name': 'heroicons_outline:key'
    },
    {
        'name': 'heroicons_outline:information-circle'
    },
    {
        'name': 'heroicons_outline:library'
    },
    {
        'name': 'heroicons_outline:light-bulb'
    },
    {
        'name': 'heroicons_outline:link'
    },
    {
        'name': 'heroicons_outline:location-marker'
    },
    {
        'name': 'heroicons_outline:lightning-bolt'
    },
    {
        'name': 'heroicons_outline:lock-closed'
    },
    {
        'name': 'heroicons_outline:login'
    },
    {
        'name': 'heroicons_outline:lock-open'
    },
    {
        'name': 'heroicons_outline:logout'
    },
    {
        'name': 'heroicons_outline:mail'
    },
    {
        'name': 'heroicons_outline:map'
    },
    {
        'name': 'heroicons_outline:menu-alt-1'
    },
    {
        'name': 'heroicons_outline:mail-open'
    },
    {
        'name': 'heroicons_outline:menu-alt-2'
    },
    {
        'name': 'heroicons_outline:menu-alt-3'
    },
    {
        'name': 'heroicons_outline:menu-alt-4'
    },
    {
        'name': 'heroicons_outline:menu'
    },
    {
        'name': 'heroicons_outline:microphone'
    },
    {
        'name': 'heroicons_outline:minus-circle'
    },
    {
        'name': 'heroicons_outline:minus-sm'
    },
    {
        'name': 'heroicons_outline:minus'
    },
    {
        'name': 'heroicons_outline:moon'
    },
    {
        'name': 'heroicons_outline:music-note'
    },
    {
        'name': 'heroicons_outline:newspaper'
    },
    {
        'name': 'heroicons_outline:office-building'
    },
    {
        'name': 'heroicons_outline:paper-airplane'
    },
    {
        'name': 'heroicons_outline:paper-clip'
    },
    {
        'name': 'heroicons_outline:pause'
    },
    {
        'name': 'heroicons_outline:pencil-alt'
    },
    {
        'name': 'heroicons_outline:phone-incoming'
    },
    {
        'name': 'heroicons_outline:phone-missed-call'
    },
    {
        'name': 'heroicons_outline:phone-outgoing'
    },
    {
        'name': 'heroicons_outline:pencil'
    },
    {
        'name': 'heroicons_outline:photograph'
    },
    {
        'name': 'heroicons_outline:phone'
    },
    {
        'name': 'heroicons_outline:plus-circle'
    },
    {
        'name': 'heroicons_outline:play'
    },
    {
        'name': 'heroicons_outline:plus-sm'
    },
    {
        'name': 'heroicons_outline:plus'
    },
    {
        'name': 'heroicons_outline:presentation-chart-bar'
    },
    {
        'name': 'heroicons_outline:presentation-chart-line'
    },
    {
        'name': 'heroicons_outline:printer'
    },
    {
        'name': 'heroicons_outline:puzzle'
    },
    {
        'name': 'heroicons_outline:qrcode'
    },
    {
        'name': 'heroicons_outline:question-mark-circle'
    },
    {
        'name': 'heroicons_outline:receipt-refund'
    },
    {
        'name': 'heroicons_outline:receipt-tax'
    },
    {
        'name': 'heroicons_outline:refresh'
    },
    {
        'name': 'heroicons_outline:reply'
    },
    {
        'name': 'heroicons_outline:rewind'
    },
    {
        'name': 'heroicons_outline:rss'
    },
    {
        'name': 'heroicons_outline:save'
    },
    {
        'name': 'heroicons_outline:save-as'
    },
    {
        'name': 'heroicons_outline:scale'
    },
    {
        'name': 'heroicons_outline:scissors'
    },
    {
        'name': 'heroicons_outline:search-circle'
    },
    {
        'name': 'heroicons_outline:search'
    },
    {
        'name': 'heroicons_outline:selector'
    },
    {
        'name': 'heroicons_outline:server'
    },
    {
        'name': 'heroicons_outline:share'
    },
    {
        'name': 'heroicons_outline:shield-check'
    },
    {
        'name': 'heroicons_outline:shield-exclamation'
    },
    {
        'name': 'heroicons_outline:shopping-bag'
    },
    {
        'name': 'heroicons_outline:shopping-cart'
    },
    {
        'name': 'heroicons_outline:sort-ascending'
    },
    {
        'name': 'heroicons_outline:sort-descending'
    },
    {
        'name': 'heroicons_outline:sparkles'
    },
    {
        'name': 'heroicons_outline:speakerphone'
    },
    {
        'name': 'heroicons_outline:star'
    },
    {
        'name': 'heroicons_outline:status-offline'
    },
    {
        'name': 'heroicons_outline:status-online'
    },
    {
        'name': 'heroicons_outline:stop'
    },
    {
        'name': 'heroicons_outline:sun'
    },
    {
        'name': 'heroicons_outline:support'
    },
    {
        'name': 'heroicons_outline:switch-horizontal'
    },
    {
        'name': 'heroicons_outline:switch-vertical'
    },
    {
        'name': 'heroicons_outline:table'
    },
    {
        'name': 'heroicons_outline:tag'
    },
    {
        'name': 'heroicons_outline:template'
    },
    {
        'name': 'heroicons_outline:terminal'
    },
    {
        'name': 'heroicons_outline:thumb-down'
    },
    {
        'name': 'heroicons_outline:thumb-up'
    },
    {
        'name': 'heroicons_outline:ticket'
    },
    {
        'name': 'heroicons_outline:translate'
    },
    {
        'name': 'heroicons_outline:trash'
    },
    {
        'name': 'heroicons_outline:trending-down'
    },
    {
        'name': 'heroicons_outline:trending-up'
    },
    {
        'name': 'heroicons_outline:truck'
    },
    {
        'name': 'heroicons_outline:upload'
    },
    {
        'name': 'heroicons_outline:user-add'
    },
    {
        'name': 'heroicons_outline:user-circle'
    },
    {
        'name': 'heroicons_outline:user-group'
    },
    {
        'name': 'heroicons_outline:user-remove'
    },
    {
        'name': 'heroicons_outline:user'
    },
    {
        'name': 'heroicons_outline:users'
    },
    {
        'name': 'heroicons_outline:variable'
    },
    {
        'name': 'heroicons_outline:video-camera'
    },
    {
        'name': 'heroicons_outline:view-boards'
    },
    {
        'name': 'heroicons_outline:view-grid-add'
    },
    {
        'name': 'heroicons_outline:view-grid'
    },
    {
        'name': 'heroicons_outline:view-list'
    },
    {
        'name': 'heroicons_outline:volume-off'
    },
    {
        'name': 'heroicons_outline:volume-up'
    },
    {
        'name': 'heroicons_outline:wifi'
    },
    {
        'name': 'heroicons_outline:x-circle'
    },
    {
        'name': 'heroicons_outline:x'
    },
    {
        'name': 'heroicons_outline:zoom-in'
    },
    {
        'name': 'heroicons_outline:zoom-out'
    },
    {
        'name': 'heroicons_solid:academic-cap'
    },
    {
        'name': 'heroicons_solid:annotation'
    },
    {
        'name': 'heroicons_solid:adjustments'
    },
    {
        'name': 'heroicons_solid:archive'
    },
    {
        'name': 'heroicons_solid:arrow-circle-down'
    },
    {
        'name': 'heroicons_solid:arrow-circle-left'
    },
    {
        'name': 'heroicons_solid:arrow-circle-right'
    },
    {
        'name': 'heroicons_solid:arrow-circle-up'
    },
    {
        'name': 'heroicons_solid:arrow-down'
    },
    {
        'name': 'heroicons_solid:arrow-left'
    },
    {
        'name': 'heroicons_solid:arrow-narrow-down'
    },
    {
        'name': 'heroicons_solid:arrow-narrow-left'
    },
    {
        'name': 'heroicons_solid:arrow-narrow-right'
    },
    {
        'name': 'heroicons_solid:arrow-narrow-up'
    },
    {
        'name': 'heroicons_solid:arrow-right'
    },
    {
        'name': 'heroicons_solid:arrow-sm-left'
    },
    {
        'name': 'heroicons_solid:arrow-sm-down'
    },
    {
        'name': 'heroicons_solid:arrow-sm-right'
    },
    {
        'name': 'heroicons_solid:arrow-sm-up'
    },
    {
        'name': 'heroicons_solid:arrow-up'
    },
    {
        'name': 'heroicons_solid:arrows-expand'
    },
    {
        'name': 'heroicons_solid:at-symbol'
    },
    {
        'name': 'heroicons_solid:badge-check'
    },
    {
        'name': 'heroicons_solid:backspace'
    },
    {
        'name': 'heroicons_solid:ban'
    },
    {
        'name': 'heroicons_solid:beaker'
    },
    {
        'name': 'heroicons_solid:bell'
    },
    {
        'name': 'heroicons_solid:book-open'
    },
    {
        'name': 'heroicons_solid:bookmark-alt'
    },
    {
        'name': 'heroicons_solid:bookmark'
    },
    {
        'name': 'heroicons_solid:briefcase'
    },
    {
        'name': 'heroicons_solid:cake'
    },
    {
        'name': 'heroicons_solid:calculator'
    },
    {
        'name': 'heroicons_solid:calendar'
    },
    {
        'name': 'heroicons_solid:camera'
    },
    {
        'name': 'heroicons_solid:cash'
    },
    {
        'name': 'heroicons_solid:chart-bar'
    },
    {
        'name': 'heroicons_solid:chart-pie'
    },
    {
        'name': 'heroicons_solid:chart-square-bar'
    },
    {
        'name': 'heroicons_solid:chat-alt-2'
    },
    {
        'name': 'heroicons_solid:chat-alt'
    },
    {
        'name': 'heroicons_solid:chat'
    },
    {
        'name': 'heroicons_solid:check-circle'
    },
    {
        'name': 'heroicons_solid:check'
    },
    {
        'name': 'heroicons_solid:chevron-double-down'
    },
    {
        'name': 'heroicons_solid:chevron-double-left'
    },
    {
        'name': 'heroicons_solid:chevron-double-up'
    },
    {
        'name': 'heroicons_solid:chevron-double-right'
    },
    {
        'name': 'heroicons_solid:chevron-down'
    },
    {
        'name': 'heroicons_solid:chevron-left'
    },
    {
        'name': 'heroicons_solid:chevron-right'
    },
    {
        'name': 'heroicons_solid:chevron-up'
    },
    {
        'name': 'heroicons_solid:chip'
    },
    {
        'name': 'heroicons_solid:clipboard-check'
    },
    {
        'name': 'heroicons_solid:clipboard-copy'
    },
    {
        'name': 'heroicons_solid:clipboard-list'
    },
    {
        'name': 'heroicons_solid:clipboard'
    },
    {
        'name': 'heroicons_solid:clock'
    },
    {
        'name': 'heroicons_solid:cloud-download'
    },
    {
        'name': 'heroicons_solid:cloud-upload'
    },
    {
        'name': 'heroicons_solid:cloud'
    },
    {
        'name': 'heroicons_solid:code'
    },
    {
        'name': 'heroicons_solid:cog'
    },
    {
        'name': 'heroicons_solid:collection'
    },
    {
        'name': 'heroicons_solid:credit-card'
    },
    {
        'name': 'heroicons_solid:color-swatch'
    },
    {
        'name': 'heroicons_solid:cube-transparent'
    },
    {
        'name': 'heroicons_solid:cube'
    },
    {
        'name': 'heroicons_solid:currency-bangladeshi'
    },
    {
        'name': 'heroicons_solid:currency-dollar'
    },
    {
        'name': 'heroicons_solid:currency-euro'
    },
    {
        'name': 'heroicons_solid:currency-pound'
    },
    {
        'name': 'heroicons_solid:currency-rupee'
    },
    {
        'name': 'heroicons_solid:currency-yen'
    },
    {
        'name': 'heroicons_solid:cursor-click'
    },
    {
        'name': 'heroicons_solid:database'
    },
    {
        'name': 'heroicons_solid:desktop-computer'
    },
    {
        'name': 'heroicons_solid:device-mobile'
    },
    {
        'name': 'heroicons_solid:device-tablet'
    },
    {
        'name': 'heroicons_solid:document-add'
    },
    {
        'name': 'heroicons_solid:document-download'
    },
    {
        'name': 'heroicons_solid:document-duplicate'
    },
    {
        'name': 'heroicons_solid:document-remove'
    },
    {
        'name': 'heroicons_solid:document-report'
    },
    {
        'name': 'heroicons_solid:document-search'
    },
    {
        'name': 'heroicons_solid:document-text'
    },
    {
        'name': 'heroicons_solid:document'
    },
    {
        'name': 'heroicons_solid:dots-circle-horizontal'
    },
    {
        'name': 'heroicons_solid:dots-horizontal'
    },
    {
        'name': 'heroicons_solid:dots-vertical'
    },
    {
        'name': 'heroicons_solid:download'
    },
    {
        'name': 'heroicons_solid:duplicate'
    },
    {
        'name': 'heroicons_solid:emoji-happy'
    },
    {
        'name': 'heroicons_solid:emoji-sad'
    },
    {
        'name': 'heroicons_solid:exclamation-circle'
    },
    {
        'name': 'heroicons_solid:exclamation'
    },
    {
        'name': 'heroicons_solid:external-link'
    },
    {
        'name': 'heroicons_solid:eye-off'
    },
    {
        'name': 'heroicons_solid:eye'
    },
    {
        'name': 'heroicons_solid:fast-forward'
    },
    {
        'name': 'heroicons_solid:film'
    },
    {
        'name': 'heroicons_solid:filter'
    },
    {
        'name': 'heroicons_solid:finger-print'
    },
    {
        'name': 'heroicons_solid:fire'
    },
    {
        'name': 'heroicons_solid:flag'
    },
    {
        'name': 'heroicons_solid:folder-add'
    },
    {
        'name': 'heroicons_solid:folder-download'
    },
    {
        'name': 'heroicons_solid:folder-open'
    },
    {
        'name': 'heroicons_solid:folder-remove'
    },
    {
        'name': 'heroicons_solid:folder'
    },
    {
        'name': 'heroicons_solid:gift'
    },
    {
        'name': 'heroicons_solid:globe-alt'
    },
    {
        'name': 'heroicons_solid:globe'
    },
    {
        'name': 'heroicons_solid:hand'
    },
    {
        'name': 'heroicons_solid:hashtag'
    },
    {
        'name': 'heroicons_solid:heart'
    },
    {
        'name': 'heroicons_solid:home'
    },
    {
        'name': 'heroicons_solid:identification'
    },
    {
        'name': 'heroicons_solid:inbox-in'
    },
    {
        'name': 'heroicons_solid:inbox'
    },
    {
        'name': 'heroicons_solid:information-circle'
    },
    {
        'name': 'heroicons_solid:key'
    },
    {
        'name': 'heroicons_solid:library'
    },
    {
        'name': 'heroicons_solid:light-bulb'
    },
    {
        'name': 'heroicons_solid:lightning-bolt'
    },
    {
        'name': 'heroicons_solid:link'
    },
    {
        'name': 'heroicons_solid:location-marker'
    },
    {
        'name': 'heroicons_solid:lock-closed'
    },
    {
        'name': 'heroicons_solid:lock-open'
    },
    {
        'name': 'heroicons_solid:login'
    },
    {
        'name': 'heroicons_solid:logout'
    },
    {
        'name': 'heroicons_solid:mail-open'
    },
    {
        'name': 'heroicons_solid:mail'
    },
    {
        'name': 'heroicons_solid:map'
    },
    {
        'name': 'heroicons_solid:menu-alt-1'
    },
    {
        'name': 'heroicons_solid:menu-alt-2'
    },
    {
        'name': 'heroicons_solid:menu-alt-3'
    },
    {
        'name': 'heroicons_solid:menu-alt-4'
    },
    {
        'name': 'heroicons_solid:menu'
    },
    {
        'name': 'heroicons_solid:microphone'
    },
    {
        'name': 'heroicons_solid:minus-circle'
    },
    {
        'name': 'heroicons_solid:minus-sm'
    },
    {
        'name': 'heroicons_solid:minus'
    },
    {
        'name': 'heroicons_solid:moon'
    },
    {
        'name': 'heroicons_solid:music-note'
    },
    {
        'name': 'heroicons_solid:newspaper'
    },
    {
        'name': 'heroicons_solid:office-building'
    },
    {
        'name': 'heroicons_solid:paper-airplane'
    },
    {
        'name': 'heroicons_solid:paper-clip'
    },
    {
        'name': 'heroicons_solid:pause'
    },
    {
        'name': 'heroicons_solid:pencil-alt'
    },
    {
        'name': 'heroicons_solid:pencil'
    },
    {
        'name': 'heroicons_solid:phone-incoming'
    },
    {
        'name': 'heroicons_solid:phone-missed-call'
    },
    {
        'name': 'heroicons_solid:phone-outgoing'
    },
    {
        'name': 'heroicons_solid:photograph'
    },
    {
        'name': 'heroicons_solid:phone'
    },
    {
        'name': 'heroicons_solid:play'
    },
    {
        'name': 'heroicons_solid:plus-circle'
    },
    {
        'name': 'heroicons_solid:plus-sm'
    },
    {
        'name': 'heroicons_solid:plus'
    },
    {
        'name': 'heroicons_solid:presentation-chart-bar'
    },
    {
        'name': 'heroicons_solid:presentation-chart-line'
    },
    {
        'name': 'heroicons_solid:printer'
    },
    {
        'name': 'heroicons_solid:qrcode'
    },
    {
        'name': 'heroicons_solid:puzzle'
    },
    {
        'name': 'heroicons_solid:question-mark-circle'
    },
    {
        'name': 'heroicons_solid:receipt-refund'
    },
    {
        'name': 'heroicons_solid:receipt-tax'
    },
    {
        'name': 'heroicons_solid:refresh'
    },
    {
        'name': 'heroicons_solid:reply'
    },
    {
        'name': 'heroicons_solid:rewind'
    },
    {
        'name': 'heroicons_solid:rss'
    },
    {
        'name': 'heroicons_solid:save-as'
    },
    {
        'name': 'heroicons_solid:save'
    },
    {
        'name': 'heroicons_solid:scale'
    },
    {
        'name': 'heroicons_solid:scissors'
    },
    {
        'name': 'heroicons_solid:search-circle'
    },
    {
        'name': 'heroicons_solid:search'
    },
    {
        'name': 'heroicons_solid:selector'
    },
    {
        'name': 'heroicons_solid:server'
    },
    {
        'name': 'heroicons_solid:share'
    },
    {
        'name': 'heroicons_solid:shield-check'
    },
    {
        'name': 'heroicons_solid:shield-exclamation'
    },
    {
        'name': 'heroicons_solid:shopping-bag'
    },
    {
        'name': 'heroicons_solid:shopping-cart'
    },
    {
        'name': 'heroicons_solid:sort-ascending'
    },
    {
        'name': 'heroicons_solid:sort-descending'
    },
    {
        'name': 'heroicons_solid:speakerphone'
    },
    {
        'name': 'heroicons_solid:sparkles'
    },
    {
        'name': 'heroicons_solid:star'
    },
    {
        'name': 'heroicons_solid:status-online'
    },
    {
        'name': 'heroicons_solid:status-offline'
    },
    {
        'name': 'heroicons_solid:stop'
    },
    {
        'name': 'heroicons_solid:sun'
    },
    {
        'name': 'heroicons_solid:support'
    },
    {
        'name': 'heroicons_solid:switch-vertical'
    },
    {
        'name': 'heroicons_solid:switch-horizontal'
    },
    {
        'name': 'heroicons_solid:table'
    },
    {
        'name': 'heroicons_solid:tag'
    },
    {
        'name': 'heroicons_solid:template'
    },
    {
        'name': 'heroicons_solid:terminal'
    },
    {
        'name': 'heroicons_solid:thumb-up'
    },
    {
        'name': 'heroicons_solid:thumb-down'
    },
    {
        'name': 'heroicons_solid:ticket'
    },
    {
        'name': 'heroicons_solid:translate'
    },
    {
        'name': 'heroicons_solid:trash'
    },
    {
        'name': 'heroicons_solid:trending-down'
    },
    {
        'name': 'heroicons_solid:trending-up'
    },
    {
        'name': 'heroicons_solid:truck'
    },
    {
        'name': 'heroicons_solid:upload'
    },
    {
        'name': 'heroicons_solid:user-add'
    },
    {
        'name': 'heroicons_solid:user-group'
    },
    {
        'name': 'heroicons_solid:user-circle'
    },
    {
        'name': 'heroicons_solid:user-remove'
    },
    {
        'name': 'heroicons_solid:user'
    },
    {
        'name': 'heroicons_solid:users'
    },
    {
        'name': 'heroicons_solid:variable'
    },
    {
        'name': 'heroicons_solid:video-camera'
    },
    {
        'name': 'heroicons_solid:view-boards'
    },
    {
        'name': 'heroicons_solid:view-grid-add'
    },
    {
        'name': 'heroicons_solid:view-grid'
    },
    {
        'name': 'heroicons_solid:view-list'
    },
    {
        'name': 'heroicons_solid:volume-up'
    },
    {
        'name': 'heroicons_solid:volume-off'
    },
    {
        'name': 'heroicons_solid:x-circle'
    },
    {
        'name': 'heroicons_solid:zoom-in'
    },
    {
        'name': 'heroicons_solid:wifi'
    },
    {
        'name': 'heroicons_solid:zoom-out'
    },
    {
        'name': 'heroicons_solid:x'
    },
    {
        'name': 'fa_regular:abacus'
    },
    {
        'name': 'fa_regular:acorn'
    },
    {
        'name': 'fa_regular:ad'
    },
    {
        'name': 'fa_regular:address-book'
    },
    {
        'name': 'fa_regular:address-card'
    },
    {
        'name': 'fa_regular:adjust'
    },
    {
        'name': 'fa_regular:air-freshener'
    },
    {
        'name': 'fa_regular:alarm-clock'
    },
    {
        'name': 'fa_regular:alarm-exclamation'
    },
    {
        'name': 'fa_regular:alarm-plus'
    },
    {
        'name': 'fa_regular:alarm-snooze'
    },
    {
        'name': 'fa_regular:alicorn'
    },
    {
        'name': 'fa_regular:align-center'
    },
    {
        'name': 'fa_regular:align-justify'
    },
    {
        'name': 'fa_regular:align-left'
    },
    {
        'name': 'fa_regular:align-right'
    },
    {
        'name': 'fa_regular:align-slash'
    },
    {
        'name': 'fa_regular:allergies'
    },
    {
        'name': 'fa_regular:ambulance'
    },
    {
        'name': 'fa_regular:american-sign-language-interpreting'
    },
    {
        'name': 'fa_regular:analytics'
    },
    {
        'name': 'fa_regular:anchor'
    },
    {
        'name': 'fa_regular:angel'
    },
    {
        'name': 'fa_regular:angle-double-down'
    },
    {
        'name': 'fa_regular:angle-double-left'
    },
    {
        'name': 'fa_regular:angle-double-right'
    },
    {
        'name': 'fa_regular:angle-double-up'
    },
    {
        'name': 'fa_regular:angle-down'
    },
    {
        'name': 'fa_regular:angle-left'
    },
    {
        'name': 'fa_regular:angle-right'
    },
    {
        'name': 'fa_regular:angle-up'
    },
    {
        'name': 'fa_regular:angry'
    },
    {
        'name': 'fa_regular:ankh'
    },
    {
        'name': 'fa_regular:apple-alt'
    },
    {
        'name': 'fa_regular:apple-crate'
    },
    {
        'name': 'fa_regular:archive'
    },
    {
        'name': 'fa_regular:archway'
    },
    {
        'name': 'fa_regular:arrow-alt-circle-down'
    },
    {
        'name': 'fa_regular:arrow-alt-circle-left'
    },
    {
        'name': 'fa_regular:arrow-alt-circle-right'
    },
    {
        'name': 'fa_regular:arrow-alt-circle-up'
    },
    {
        'name': 'fa_regular:arrow-alt-down'
    },
    {
        'name': 'fa_regular:arrow-alt-from-bottom'
    },
    {
        'name': 'fa_regular:arrow-alt-from-left'
    },
    {
        'name': 'fa_regular:arrow-alt-from-right'
    },
    {
        'name': 'fa_regular:arrow-alt-from-top'
    },
    {
        'name': 'fa_regular:arrow-alt-left'
    },
    {
        'name': 'fa_regular:arrow-alt-right'
    },
    {
        'name': 'fa_regular:arrow-alt-square-down'
    },
    {
        'name': 'fa_regular:arrow-alt-square-left'
    },
    {
        'name': 'fa_regular:arrow-alt-square-right'
    },
    {
        'name': 'fa_regular:arrow-alt-square-up'
    },
    {
        'name': 'fa_regular:arrow-alt-to-bottom'
    },
    {
        'name': 'fa_regular:arrow-alt-to-left'
    },
    {
        'name': 'fa_regular:arrow-alt-to-right'
    },
    {
        'name': 'fa_regular:arrow-alt-to-top'
    },
    {
        'name': 'fa_regular:arrow-alt-up'
    },
    {
        'name': 'fa_regular:arrow-circle-down'
    },
    {
        'name': 'fa_regular:arrow-circle-left'
    },
    {
        'name': 'fa_regular:arrow-circle-right'
    },
    {
        'name': 'fa_regular:arrow-circle-up'
    },
    {
        'name': 'fa_regular:arrow-down'
    },
    {
        'name': 'fa_regular:arrow-from-bottom'
    },
    {
        'name': 'fa_regular:arrow-from-left'
    },
    {
        'name': 'fa_regular:arrow-from-right'
    },
    {
        'name': 'fa_regular:arrow-from-top'
    },
    {
        'name': 'fa_regular:arrow-left'
    },
    {
        'name': 'fa_regular:arrow-right'
    },
    {
        'name': 'fa_regular:arrow-square-down'
    },
    {
        'name': 'fa_regular:arrow-square-left'
    },
    {
        'name': 'fa_regular:arrow-square-right'
    },
    {
        'name': 'fa_regular:arrow-square-up'
    },
    {
        'name': 'fa_regular:arrow-to-bottom'
    },
    {
        'name': 'fa_regular:arrow-to-left'
    },
    {
        'name': 'fa_regular:arrow-to-right'
    },
    {
        'name': 'fa_regular:arrow-to-top'
    },
    {
        'name': 'fa_regular:arrow-up'
    },
    {
        'name': 'fa_regular:arrows-alt-h'
    },
    {
        'name': 'fa_regular:arrows-alt-v'
    },
    {
        'name': 'fa_regular:arrows-alt'
    },
    {
        'name': 'fa_regular:arrows-h'
    },
    {
        'name': 'fa_regular:arrows-v'
    },
    {
        'name': 'fa_regular:arrows'
    },
    {
        'name': 'fa_regular:assistive-listening-systems'
    },
    {
        'name': 'fa_regular:asterisk'
    },
    {
        'name': 'fa_regular:at'
    },
    {
        'name': 'fa_regular:atlas'
    },
    {
        'name': 'fa_regular:atom-alt'
    },
    {
        'name': 'fa_regular:atom'
    },
    {
        'name': 'fa_regular:audio-description'
    },
    {
        'name': 'fa_regular:award'
    },
    {
        'name': 'fa_regular:axe-battle'
    },
    {
        'name': 'fa_regular:axe'
    },
    {
        'name': 'fa_regular:baby-carriage'
    },
    {
        'name': 'fa_regular:baby'
    },
    {
        'name': 'fa_regular:backpack'
    },
    {
        'name': 'fa_regular:backspace'
    },
    {
        'name': 'fa_regular:backward'
    },
    {
        'name': 'fa_regular:bacon'
    },
    {
        'name': 'fa_regular:badge-check'
    },
    {
        'name': 'fa_regular:badge-dollar'
    },
    {
        'name': 'fa_regular:badge-percent'
    },
    {
        'name': 'fa_regular:badge'
    },
    {
        'name': 'fa_regular:badger-honey'
    },
    {
        'name': 'fa_regular:bags-shopping'
    },
    {
        'name': 'fa_regular:balance-scale-left'
    },
    {
        'name': 'fa_regular:balance-scale-right'
    },
    {
        'name': 'fa_regular:balance-scale'
    },
    {
        'name': 'fa_regular:ball-pile'
    },
    {
        'name': 'fa_regular:ballot-check'
    },
    {
        'name': 'fa_regular:ballot'
    },
    {
        'name': 'fa_regular:ban'
    },
    {
        'name': 'fa_regular:band-aid'
    },
    {
        'name': 'fa_regular:barcode-alt'
    },
    {
        'name': 'fa_regular:barcode-read'
    },
    {
        'name': 'fa_regular:barcode-scan'
    },
    {
        'name': 'fa_regular:barcode'
    },
    {
        'name': 'fa_regular:bars'
    },
    {
        'name': 'fa_regular:baseball-ball'
    },
    {
        'name': 'fa_regular:baseball'
    },
    {
        'name': 'fa_regular:basketball-ball'
    },
    {
        'name': 'fa_regular:basketball-hoop'
    },
    {
        'name': 'fa_regular:bat'
    },
    {
        'name': 'fa_regular:bath'
    },
    {
        'name': 'fa_regular:battery-bolt'
    },
    {
        'name': 'fa_regular:battery-empty'
    },
    {
        'name': 'fa_regular:battery-full'
    },
    {
        'name': 'fa_regular:battery-half'
    },
    {
        'name': 'fa_regular:battery-quarter'
    },
    {
        'name': 'fa_regular:battery-slash'
    },
    {
        'name': 'fa_regular:battery-three-quarters'
    },
    {
        'name': 'fa_regular:bed'
    },
    {
        'name': 'fa_regular:beer'
    },
    {
        'name': 'fa_regular:bell-exclamation'
    },
    {
        'name': 'fa_regular:bell-plus'
    },
    {
        'name': 'fa_regular:bell-school-slash'
    },
    {
        'name': 'fa_regular:bell-school'
    },
    {
        'name': 'fa_regular:bell-slash'
    },
    {
        'name': 'fa_regular:bell'
    },
    {
        'name': 'fa_regular:bells'
    },
    {
        'name': 'fa_regular:bezier-curve'
    },
    {
        'name': 'fa_regular:bible'
    },
    {
        'name': 'fa_regular:bicycle'
    },
    {
        'name': 'fa_regular:biking-mountain'
    },
    {
        'name': 'fa_regular:biking'
    },
    {
        'name': 'fa_regular:binoculars'
    },
    {
        'name': 'fa_regular:biohazard'
    },
    {
        'name': 'fa_regular:birthday-cake'
    },
    {
        'name': 'fa_regular:blanket'
    },
    {
        'name': 'fa_regular:blender-phone'
    },
    {
        'name': 'fa_regular:blender'
    },
    {
        'name': 'fa_regular:blind'
    },
    {
        'name': 'fa_regular:blog'
    },
    {
        'name': 'fa_regular:bold'
    },
    {
        'name': 'fa_regular:bolt'
    },
    {
        'name': 'fa_regular:bomb'
    },
    {
        'name': 'fa_regular:bone-break'
    },
    {
        'name': 'fa_regular:bone'
    },
    {
        'name': 'fa_regular:bong'
    },
    {
        'name': 'fa_regular:book-alt'
    },
    {
        'name': 'fa_regular:book-dead'
    },
    {
        'name': 'fa_regular:book-heart'
    },
    {
        'name': 'fa_regular:book-medical'
    },
    {
        'name': 'fa_regular:book-open'
    },
    {
        'name': 'fa_regular:book-reader'
    },
    {
        'name': 'fa_regular:book-spells'
    },
    {
        'name': 'fa_regular:book-user'
    },
    {
        'name': 'fa_regular:book'
    },
    {
        'name': 'fa_regular:bookmark'
    },
    {
        'name': 'fa_regular:books-medical'
    },
    {
        'name': 'fa_regular:books'
    },
    {
        'name': 'fa_regular:boot'
    },
    {
        'name': 'fa_regular:booth-curtain'
    },
    {
        'name': 'fa_regular:border-all'
    },
    {
        'name': 'fa_regular:border-bottom'
    },
    {
        'name': 'fa_regular:border-center-h'
    },
    {
        'name': 'fa_regular:border-center-v'
    },
    {
        'name': 'fa_regular:border-inner'
    },
    {
        'name': 'fa_regular:border-left'
    },
    {
        'name': 'fa_regular:border-none'
    },
    {
        'name': 'fa_regular:border-outer'
    },
    {
        'name': 'fa_regular:border-right'
    },
    {
        'name': 'fa_regular:border-style-alt'
    },
    {
        'name': 'fa_regular:border-style'
    },
    {
        'name': 'fa_regular:border-top'
    },
    {
        'name': 'fa_regular:bow-arrow'
    },
    {
        'name': 'fa_regular:bowling-ball'
    },
    {
        'name': 'fa_regular:bowling-pins'
    },
    {
        'name': 'fa_regular:box-alt'
    },
    {
        'name': 'fa_regular:box-ballot'
    },
    {
        'name': 'fa_regular:box-check'
    },
    {
        'name': 'fa_regular:box-fragile'
    },
    {
        'name': 'fa_regular:box-full'
    },
    {
        'name': 'fa_regular:box-heart'
    },
    {
        'name': 'fa_regular:box-open'
    },
    {
        'name': 'fa_regular:box-up'
    },
    {
        'name': 'fa_regular:box-usd'
    },
    {
        'name': 'fa_regular:box'
    },
    {
        'name': 'fa_regular:boxes-alt'
    },
    {
        'name': 'fa_regular:boxes'
    },
    {
        'name': 'fa_regular:boxing-glove'
    },
    {
        'name': 'fa_regular:brackets-curly'
    },
    {
        'name': 'fa_regular:brackets'
    },
    {
        'name': 'fa_regular:braille'
    },
    {
        'name': 'fa_regular:brain'
    },
    {
        'name': 'fa_regular:bread-loaf'
    },
    {
        'name': 'fa_regular:bread-slice'
    },
    {
        'name': 'fa_regular:briefcase-medical'
    },
    {
        'name': 'fa_regular:briefcase'
    },
    {
        'name': 'fa_regular:bring-forward'
    },
    {
        'name': 'fa_regular:bring-front'
    },
    {
        'name': 'fa_regular:broadcast-tower'
    },
    {
        'name': 'fa_regular:broom'
    },
    {
        'name': 'fa_regular:browser'
    },
    {
        'name': 'fa_regular:brush'
    },
    {
        'name': 'fa_regular:bug'
    },
    {
        'name': 'fa_regular:building'
    },
    {
        'name': 'fa_regular:bullhorn'
    },
    {
        'name': 'fa_regular:bullseye-arrow'
    },
    {
        'name': 'fa_regular:bullseye-pointer'
    },
    {
        'name': 'fa_regular:bullseye'
    },
    {
        'name': 'fa_regular:burger-soda'
    },
    {
        'name': 'fa_regular:burn'
    },
    {
        'name': 'fa_regular:burrito'
    },
    {
        'name': 'fa_regular:bus-alt'
    },
    {
        'name': 'fa_regular:bus-school'
    },
    {
        'name': 'fa_regular:bus'
    },
    {
        'name': 'fa_regular:business-time'
    },
    {
        'name': 'fa_regular:cabinet-filing'
    },
    {
        'name': 'fa_regular:calculator-alt'
    },
    {
        'name': 'fa_regular:calculator'
    },
    {
        'name': 'fa_regular:calendar-alt'
    },
    {
        'name': 'fa_regular:calendar-check'
    },
    {
        'name': 'fa_regular:calendar-day'
    },
    {
        'name': 'fa_regular:calendar-edit'
    },
    {
        'name': 'fa_regular:calendar-exclamation'
    },
    {
        'name': 'fa_regular:calendar-minus'
    },
    {
        'name': 'fa_regular:calendar-plus'
    },
    {
        'name': 'fa_regular:calendar-star'
    },
    {
        'name': 'fa_regular:calendar-times'
    },
    {
        'name': 'fa_regular:calendar-week'
    },
    {
        'name': 'fa_regular:calendar'
    },
    {
        'name': 'fa_regular:camera-alt'
    },
    {
        'name': 'fa_regular:camera-retro'
    },
    {
        'name': 'fa_regular:camera'
    },
    {
        'name': 'fa_regular:campfire'
    },
    {
        'name': 'fa_regular:campground'
    },
    {
        'name': 'fa_regular:candle-holder'
    },
    {
        'name': 'fa_regular:candy-cane'
    },
    {
        'name': 'fa_regular:candy-corn'
    },
    {
        'name': 'fa_regular:cannabis'
    },
    {
        'name': 'fa_regular:capsules'
    },
    {
        'name': 'fa_regular:car-alt'
    },
    {
        'name': 'fa_regular:car-battery'
    },
    {
        'name': 'fa_regular:car-building'
    },
    {
        'name': 'fa_regular:car-bump'
    },
    {
        'name': 'fa_regular:car-bus'
    },
    {
        'name': 'fa_regular:car-crash'
    },
    {
        'name': 'fa_regular:car-garage'
    },
    {
        'name': 'fa_regular:car-mechanic'
    },
    {
        'name': 'fa_regular:car-side'
    },
    {
        'name': 'fa_regular:car-tilt'
    },
    {
        'name': 'fa_regular:car-wash'
    },
    {
        'name': 'fa_regular:car'
    },
    {
        'name': 'fa_regular:caret-circle-down'
    },
    {
        'name': 'fa_regular:caret-circle-left'
    },
    {
        'name': 'fa_regular:caret-circle-right'
    },
    {
        'name': 'fa_regular:caret-circle-up'
    },
    {
        'name': 'fa_regular:caret-down'
    },
    {
        'name': 'fa_regular:caret-left'
    },
    {
        'name': 'fa_regular:caret-right'
    },
    {
        'name': 'fa_regular:caret-square-down'
    },
    {
        'name': 'fa_regular:caret-square-left'
    },
    {
        'name': 'fa_regular:caret-square-right'
    },
    {
        'name': 'fa_regular:caret-square-up'
    },
    {
        'name': 'fa_regular:caret-up'
    },
    {
        'name': 'fa_regular:carrot'
    },
    {
        'name': 'fa_regular:cars'
    },
    {
        'name': 'fa_regular:cart-arrow-down'
    },
    {
        'name': 'fa_regular:cart-plus'
    },
    {
        'name': 'fa_regular:cash-register'
    },
    {
        'name': 'fa_regular:cat'
    },
    {
        'name': 'fa_regular:cauldron'
    },
    {
        'name': 'fa_regular:certificate'
    },
    {
        'name': 'fa_regular:chair-office'
    },
    {
        'name': 'fa_regular:chair'
    },
    {
        'name': 'fa_regular:chalkboard-teacher'
    },
    {
        'name': 'fa_regular:chalkboard'
    },
    {
        'name': 'fa_regular:charging-station'
    },
    {
        'name': 'fa_regular:chart-area'
    },
    {
        'name': 'fa_regular:chart-bar'
    },
    {
        'name': 'fa_regular:chart-line-down'
    },
    {
        'name': 'fa_regular:chart-line'
    },
    {
        'name': 'fa_regular:chart-network'
    },
    {
        'name': 'fa_regular:chart-pie-alt'
    },
    {
        'name': 'fa_regular:chart-pie'
    },
    {
        'name': 'fa_regular:chart-scatter'
    },
    {
        'name': 'fa_regular:check-circle'
    },
    {
        'name': 'fa_regular:check-double'
    },
    {
        'name': 'fa_regular:check-square'
    },
    {
        'name': 'fa_regular:check'
    },
    {
        'name': 'fa_regular:cheese-swiss'
    },
    {
        'name': 'fa_regular:cheese'
    },
    {
        'name': 'fa_regular:cheeseburger'
    },
    {
        'name': 'fa_regular:chess-bishop-alt'
    },
    {
        'name': 'fa_regular:chess-bishop'
    },
    {
        'name': 'fa_regular:chess-board'
    },
    {
        'name': 'fa_regular:chess-clock-alt'
    },
    {
        'name': 'fa_regular:chess-clock'
    },
    {
        'name': 'fa_regular:chess-king-alt'
    },
    {
        'name': 'fa_regular:chess-king'
    },
    {
        'name': 'fa_regular:chess-knight-alt'
    },
    {
        'name': 'fa_regular:chess-knight'
    },
    {
        'name': 'fa_regular:chess-pawn-alt'
    },
    {
        'name': 'fa_regular:chess-pawn'
    },
    {
        'name': 'fa_regular:chess-queen-alt'
    },
    {
        'name': 'fa_regular:chess-queen'
    },
    {
        'name': 'fa_regular:chess-rook-alt'
    },
    {
        'name': 'fa_regular:chess-rook'
    },
    {
        'name': 'fa_regular:chess'
    },
    {
        'name': 'fa_regular:chevron-circle-down'
    },
    {
        'name': 'fa_regular:chevron-circle-left'
    },
    {
        'name': 'fa_regular:chevron-circle-right'
    },
    {
        'name': 'fa_regular:chevron-circle-up'
    },
    {
        'name': 'fa_regular:chevron-double-down'
    },
    {
        'name': 'fa_regular:chevron-double-left'
    },
    {
        'name': 'fa_regular:chevron-double-right'
    },
    {
        'name': 'fa_regular:chevron-double-up'
    },
    {
        'name': 'fa_regular:chevron-down'
    },
    {
        'name': 'fa_regular:chevron-left'
    },
    {
        'name': 'fa_regular:chevron-right'
    },
    {
        'name': 'fa_regular:chevron-square-down'
    },
    {
        'name': 'fa_regular:chevron-square-left'
    },
    {
        'name': 'fa_regular:chevron-square-right'
    },
    {
        'name': 'fa_regular:chevron-square-up'
    },
    {
        'name': 'fa_regular:chevron-up'
    },
    {
        'name': 'fa_regular:child'
    },
    {
        'name': 'fa_regular:chimney'
    },
    {
        'name': 'fa_regular:church'
    },
    {
        'name': 'fa_regular:circle-notch'
    },
    {
        'name': 'fa_regular:circle'
    },
    {
        'name': 'fa_regular:city'
    },
    {
        'name': 'fa_regular:claw-marks'
    },
    {
        'name': 'fa_regular:clinic-medical'
    },
    {
        'name': 'fa_regular:clipboard-check'
    },
    {
        'name': 'fa_regular:clipboard-list-check'
    },
    {
        'name': 'fa_regular:clipboard-list'
    },
    {
        'name': 'fa_regular:clipboard-prescription'
    },
    {
        'name': 'fa_regular:clipboard-user'
    },
    {
        'name': 'fa_regular:clipboard'
    },
    {
        'name': 'fa_regular:clock'
    },
    {
        'name': 'fa_regular:clone'
    },
    {
        'name': 'fa_regular:closed-captioning'
    },
    {
        'name': 'fa_regular:cloud-download-alt'
    },
    {
        'name': 'fa_regular:cloud-download'
    },
    {
        'name': 'fa_regular:cloud-drizzle'
    },
    {
        'name': 'fa_regular:cloud-hail-mixed'
    },
    {
        'name': 'fa_regular:cloud-hail'
    },
    {
        'name': 'fa_regular:cloud-meatball'
    },
    {
        'name': 'fa_regular:cloud-moon-rain'
    },
    {
        'name': 'fa_regular:cloud-moon'
    },
    {
        'name': 'fa_regular:cloud-rain'
    },
    {
        'name': 'fa_regular:cloud-rainbow'
    },
    {
        'name': 'fa_regular:cloud-showers-heavy'
    },
    {
        'name': 'fa_regular:cloud-showers'
    },
    {
        'name': 'fa_regular:cloud-sleet'
    },
    {
        'name': 'fa_regular:cloud-snow'
    },
    {
        'name': 'fa_regular:cloud-sun-rain'
    },
    {
        'name': 'fa_regular:cloud-sun'
    },
    {
        'name': 'fa_regular:cloud-upload-alt'
    },
    {
        'name': 'fa_regular:cloud-upload'
    },
    {
        'name': 'fa_regular:cloud'
    },
    {
        'name': 'fa_regular:clouds-moon'
    },
    {
        'name': 'fa_regular:clouds-sun'
    },
    {
        'name': 'fa_regular:clouds'
    },
    {
        'name': 'fa_regular:club'
    },
    {
        'name': 'fa_regular:cocktail'
    },
    {
        'name': 'fa_regular:code-branch'
    },
    {
        'name': 'fa_regular:code-commit'
    },
    {
        'name': 'fa_regular:code-merge'
    },
    {
        'name': 'fa_regular:code'
    },
    {
        'name': 'fa_regular:coffee-togo'
    },
    {
        'name': 'fa_regular:coffee'
    },
    {
        'name': 'fa_regular:coffin'
    },
    {
        'name': 'fa_regular:cog'
    },
    {
        'name': 'fa_regular:cogs'
    },
    {
        'name': 'fa_regular:coin'
    },
    {
        'name': 'fa_regular:coins'
    },
    {
        'name': 'fa_regular:columns'
    },
    {
        'name': 'fa_regular:comment-alt-check'
    },
    {
        'name': 'fa_regular:comment-alt-dollar'
    },
    {
        'name': 'fa_regular:comment-alt-dots'
    },
    {
        'name': 'fa_regular:comment-alt-edit'
    },
    {
        'name': 'fa_regular:comment-alt-exclamation'
    },
    {
        'name': 'fa_regular:comment-alt-lines'
    },
    {
        'name': 'fa_regular:comment-alt-medical'
    },
    {
        'name': 'fa_regular:comment-alt-minus'
    },
    {
        'name': 'fa_regular:comment-alt-plus'
    },
    {
        'name': 'fa_regular:comment-alt-slash'
    },
    {
        'name': 'fa_regular:comment-alt-smile'
    },
    {
        'name': 'fa_regular:comment-alt-times'
    },
    {
        'name': 'fa_regular:comment-alt'
    },
    {
        'name': 'fa_regular:comment-check'
    },
    {
        'name': 'fa_regular:comment-dollar'
    },
    {
        'name': 'fa_regular:comment-dots'
    },
    {
        'name': 'fa_regular:comment-edit'
    },
    {
        'name': 'fa_regular:comment-exclamation'
    },
    {
        'name': 'fa_regular:comment-lines'
    },
    {
        'name': 'fa_regular:comment-medical'
    },
    {
        'name': 'fa_regular:comment-minus'
    },
    {
        'name': 'fa_regular:comment-plus'
    },
    {
        'name': 'fa_regular:comment-slash'
    },
    {
        'name': 'fa_regular:comment-smile'
    },
    {
        'name': 'fa_regular:comment-times'
    },
    {
        'name': 'fa_regular:comment'
    },
    {
        'name': 'fa_regular:comments-alt-dollar'
    },
    {
        'name': 'fa_regular:comments-alt'
    },
    {
        'name': 'fa_regular:comments-dollar'
    },
    {
        'name': 'fa_regular:comments'
    },
    {
        'name': 'fa_regular:compact-disc'
    },
    {
        'name': 'fa_regular:compass-slash'
    },
    {
        'name': 'fa_regular:compass'
    },
    {
        'name': 'fa_regular:compress-alt'
    },
    {
        'name': 'fa_regular:compress-arrows-alt'
    },
    {
        'name': 'fa_regular:compress-wide'
    },
    {
        'name': 'fa_regular:compress'
    },
    {
        'name': 'fa_regular:concierge-bell'
    },
    {
        'name': 'fa_regular:construction'
    },
    {
        'name': 'fa_regular:container-storage'
    },
    {
        'name': 'fa_regular:conveyor-belt-alt'
    },
    {
        'name': 'fa_regular:conveyor-belt'
    },
    {
        'name': 'fa_regular:cookie-bite'
    },
    {
        'name': 'fa_regular:cookie'
    },
    {
        'name': 'fa_regular:copy'
    },
    {
        'name': 'fa_regular:copyright'
    },
    {
        'name': 'fa_regular:corn'
    },
    {
        'name': 'fa_regular:couch'
    },
    {
        'name': 'fa_regular:cow'
    },
    {
        'name': 'fa_regular:credit-card-blank'
    },
    {
        'name': 'fa_regular:credit-card-front'
    },
    {
        'name': 'fa_regular:credit-card'
    },
    {
        'name': 'fa_regular:cricket'
    },
    {
        'name': 'fa_regular:croissant'
    },
    {
        'name': 'fa_regular:crop-alt'
    },
    {
        'name': 'fa_regular:crop'
    },
    {
        'name': 'fa_regular:cross'
    },
    {
        'name': 'fa_regular:crosshairs'
    },
    {
        'name': 'fa_regular:crow'
    },
    {
        'name': 'fa_regular:crown'
    },
    {
        'name': 'fa_regular:crutch'
    },
    {
        'name': 'fa_regular:crutches'
    },
    {
        'name': 'fa_regular:cube'
    },
    {
        'name': 'fa_regular:cubes'
    },
    {
        'name': 'fa_regular:curling'
    },
    {
        'name': 'fa_regular:cut'
    },
    {
        'name': 'fa_regular:dagger'
    },
    {
        'name': 'fa_regular:database'
    },
    {
        'name': 'fa_regular:deaf'
    },
    {
        'name': 'fa_regular:debug'
    },
    {
        'name': 'fa_regular:deer-rudolph'
    },
    {
        'name': 'fa_regular:deer'
    },
    {
        'name': 'fa_regular:democrat'
    },
    {
        'name': 'fa_regular:desktop-alt'
    },
    {
        'name': 'fa_regular:desktop'
    },
    {
        'name': 'fa_regular:dewpoint'
    },
    {
        'name': 'fa_regular:dharmachakra'
    },
    {
        'name': 'fa_regular:diagnoses'
    },
    {
        'name': 'fa_regular:diamond'
    },
    {
        'name': 'fa_regular:dice-d10'
    },
    {
        'name': 'fa_regular:dice-d12'
    },
    {
        'name': 'fa_regular:dice-d20'
    },
    {
        'name': 'fa_regular:dice-d4'
    },
    {
        'name': 'fa_regular:dice-d6'
    },
    {
        'name': 'fa_regular:dice-d8'
    },
    {
        'name': 'fa_regular:dice-five'
    },
    {
        'name': 'fa_regular:dice-four'
    },
    {
        'name': 'fa_regular:dice-one'
    },
    {
        'name': 'fa_regular:dice-six'
    },
    {
        'name': 'fa_regular:dice-three'
    },
    {
        'name': 'fa_regular:dice-two'
    },
    {
        'name': 'fa_regular:dice'
    },
    {
        'name': 'fa_regular:digging'
    },
    {
        'name': 'fa_regular:digital-tachograph'
    },
    {
        'name': 'fa_regular:diploma'
    },
    {
        'name': 'fa_regular:directions'
    },
    {
        'name': 'fa_regular:disease'
    },
    {
        'name': 'fa_regular:divide'
    },
    {
        'name': 'fa_regular:dizzy'
    },
    {
        'name': 'fa_regular:dna'
    },
    {
        'name': 'fa_regular:do-not-enter'
    },
    {
        'name': 'fa_regular:dog-leashed'
    },
    {
        'name': 'fa_regular:dog'
    },
    {
        'name': 'fa_regular:dollar-sign'
    },
    {
        'name': 'fa_regular:dolly-empty'
    },
    {
        'name': 'fa_regular:dolly-flatbed-alt'
    },
    {
        'name': 'fa_regular:dolly-flatbed-empty'
    },
    {
        'name': 'fa_regular:dolly-flatbed'
    },
    {
        'name': 'fa_regular:dolly'
    },
    {
        'name': 'fa_regular:donate'
    },
    {
        'name': 'fa_regular:door-closed'
    },
    {
        'name': 'fa_regular:door-open'
    },
    {
        'name': 'fa_regular:dot-circle'
    },
    {
        'name': 'fa_regular:dove'
    },
    {
        'name': 'fa_regular:download'
    },
    {
        'name': 'fa_regular:drafting-compass'
    },
    {
        'name': 'fa_regular:dragon'
    },
    {
        'name': 'fa_regular:draw-circle'
    },
    {
        'name': 'fa_regular:draw-polygon'
    },
    {
        'name': 'fa_regular:draw-square'
    },
    {
        'name': 'fa_regular:dreidel'
    },
    {
        'name': 'fa_regular:drone-alt'
    },
    {
        'name': 'fa_regular:drone'
    },
    {
        'name': 'fa_regular:drum-steelpan'
    },
    {
        'name': 'fa_regular:drum'
    },
    {
        'name': 'fa_regular:drumstick-bite'
    },
    {
        'name': 'fa_regular:drumstick'
    },
    {
        'name': 'fa_regular:dryer-alt'
    },
    {
        'name': 'fa_regular:dryer'
    },
    {
        'name': 'fa_regular:duck'
    },
    {
        'name': 'fa_regular:dumbbell'
    },
    {
        'name': 'fa_regular:dumpster-fire'
    },
    {
        'name': 'fa_regular:dumpster'
    },
    {
        'name': 'fa_regular:dungeon'
    },
    {
        'name': 'fa_regular:ear-muffs'
    },
    {
        'name': 'fa_regular:ear'
    },
    {
        'name': 'fa_regular:eclipse-alt'
    },
    {
        'name': 'fa_regular:eclipse'
    },
    {
        'name': 'fa_regular:edit'
    },
    {
        'name': 'fa_regular:egg-fried'
    },
    {
        'name': 'fa_regular:egg'
    },
    {
        'name': 'fa_regular:eject'
    },
    {
        'name': 'fa_regular:elephant'
    },
    {
        'name': 'fa_regular:ellipsis-h-alt'
    },
    {
        'name': 'fa_regular:ellipsis-h'
    },
    {
        'name': 'fa_regular:ellipsis-v-alt'
    },
    {
        'name': 'fa_regular:ellipsis-v'
    },
    {
        'name': 'fa_regular:empty-set'
    },
    {
        'name': 'fa_regular:engine-warning'
    },
    {
        'name': 'fa_regular:envelope-open-dollar'
    },
    {
        'name': 'fa_regular:envelope-open-text'
    },
    {
        'name': 'fa_regular:envelope-open'
    },
    {
        'name': 'fa_regular:envelope-square'
    },
    {
        'name': 'fa_regular:envelope'
    },
    {
        'name': 'fa_regular:equals'
    },
    {
        'name': 'fa_regular:eraser'
    },
    {
        'name': 'fa_regular:ethernet'
    },
    {
        'name': 'fa_regular:euro-sign'
    },
    {
        'name': 'fa_regular:exchange-alt'
    },
    {
        'name': 'fa_regular:exchange'
    },
    {
        'name': 'fa_regular:exclamation-circle'
    },
    {
        'name': 'fa_regular:exclamation-square'
    },
    {
        'name': 'fa_regular:exclamation-triangle'
    },
    {
        'name': 'fa_regular:exclamation'
    },
    {
        'name': 'fa_regular:expand-alt'
    },
    {
        'name': 'fa_regular:expand-arrows-alt'
    },
    {
        'name': 'fa_regular:expand-arrows'
    },
    {
        'name': 'fa_regular:expand-wide'
    },
    {
        'name': 'fa_regular:expand'
    },
    {
        'name': 'fa_regular:external-link-alt'
    },
    {
        'name': 'fa_regular:external-link-square-alt'
    },
    {
        'name': 'fa_regular:external-link-square'
    },
    {
        'name': 'fa_regular:external-link'
    },
    {
        'name': 'fa_regular:eye-dropper'
    },
    {
        'name': 'fa_regular:eye-evil'
    },
    {
        'name': 'fa_regular:eye-slash'
    },
    {
        'name': 'fa_regular:eye'
    },
    {
        'name': 'fa_regular:fan'
    },
    {
        'name': 'fa_regular:farm'
    },
    {
        'name': 'fa_regular:fast-backward'
    },
    {
        'name': 'fa_regular:fast-forward'
    },
    {
        'name': 'fa_regular:fax'
    },
    {
        'name': 'fa_regular:feather-alt'
    },
    {
        'name': 'fa_regular:feather'
    },
    {
        'name': 'fa_regular:female'
    },
    {
        'name': 'fa_regular:field-hockey'
    },
    {
        'name': 'fa_regular:fighter-jet'
    },
    {
        'name': 'fa_regular:file-alt'
    },
    {
        'name': 'fa_regular:file-archive'
    },
    {
        'name': 'fa_regular:file-audio'
    },
    {
        'name': 'fa_regular:file-certificate'
    },
    {
        'name': 'fa_regular:file-chart-line'
    },
    {
        'name': 'fa_regular:file-chart-pie'
    },
    {
        'name': 'fa_regular:file-check'
    },
    {
        'name': 'fa_regular:file-code'
    },
    {
        'name': 'fa_regular:file-contract'
    },
    {
        'name': 'fa_regular:file-csv'
    },
    {
        'name': 'fa_regular:file-download'
    },
    {
        'name': 'fa_regular:file-edit'
    },
    {
        'name': 'fa_regular:file-excel'
    },
    {
        'name': 'fa_regular:file-exclamation'
    },
    {
        'name': 'fa_regular:file-export'
    },
    {
        'name': 'fa_regular:file-image'
    },
    {
        'name': 'fa_regular:file-import'
    },
    {
        'name': 'fa_regular:file-invoice-dollar'
    },
    {
        'name': 'fa_regular:file-invoice'
    },
    {
        'name': 'fa_regular:file-medical-alt'
    },
    {
        'name': 'fa_regular:file-medical'
    },
    {
        'name': 'fa_regular:file-minus'
    },
    {
        'name': 'fa_regular:file-pdf'
    },
    {
        'name': 'fa_regular:file-plus'
    },
    {
        'name': 'fa_regular:file-powerpoint'
    },
    {
        'name': 'fa_regular:file-prescription'
    },
    {
        'name': 'fa_regular:file-search'
    },
    {
        'name': 'fa_regular:file-signature'
    },
    {
        'name': 'fa_regular:file-spreadsheet'
    },
    {
        'name': 'fa_regular:file-times'
    },
    {
        'name': 'fa_regular:file-upload'
    },
    {
        'name': 'fa_regular:file-user'
    },
    {
        'name': 'fa_regular:file-video'
    },
    {
        'name': 'fa_regular:file-word'
    },
    {
        'name': 'fa_regular:file'
    },
    {
        'name': 'fa_regular:files-medical'
    },
    {
        'name': 'fa_regular:fill-drip'
    },
    {
        'name': 'fa_regular:fill'
    },
    {
        'name': 'fa_regular:film-alt'
    },
    {
        'name': 'fa_regular:film'
    },
    {
        'name': 'fa_regular:filter'
    },
    {
        'name': 'fa_regular:fingerprint'
    },
    {
        'name': 'fa_regular:fire-alt'
    },
    {
        'name': 'fa_regular:fire-extinguisher'
    },
    {
        'name': 'fa_regular:fire-smoke'
    },
    {
        'name': 'fa_regular:fire'
    },
    {
        'name': 'fa_regular:fireplace'
    },
    {
        'name': 'fa_regular:first-aid'
    },
    {
        'name': 'fa_regular:fish-cooked'
    },
    {
        'name': 'fa_regular:fish'
    },
    {
        'name': 'fa_regular:fist-raised'
    },
    {
        'name': 'fa_regular:flag-alt'
    },
    {
        'name': 'fa_regular:flag-checkered'
    },
    {
        'name': 'fa_regular:flag-usa'
    },
    {
        'name': 'fa_regular:flag'
    },
    {
        'name': 'fa_regular:flame'
    },
    {
        'name': 'fa_regular:flask-poison'
    },
    {
        'name': 'fa_regular:flask-potion'
    },
    {
        'name': 'fa_regular:flask'
    },
    {
        'name': 'fa_regular:flower-daffodil'
    },
    {
        'name': 'fa_regular:flower-tulip'
    },
    {
        'name': 'fa_regular:flower'
    },
    {
        'name': 'fa_regular:flushed'
    },
    {
        'name': 'fa_regular:fog'
    },
    {
        'name': 'fa_regular:folder-minus'
    },
    {
        'name': 'fa_regular:folder-open'
    },
    {
        'name': 'fa_regular:folder-plus'
    },
    {
        'name': 'fa_regular:folder-times'
    },
    {
        'name': 'fa_regular:folder-tree'
    },
    {
        'name': 'fa_regular:folder'
    },
    {
        'name': 'fa_regular:folders'
    },
    {
        'name': 'fa_regular:font-awesome-logo-full'
    },
    {
        'name': 'fa_regular:font-case'
    },
    {
        'name': 'fa_regular:font'
    },
    {
        'name': 'fa_regular:football-ball'
    },
    {
        'name': 'fa_regular:football-helmet'
    },
    {
        'name': 'fa_regular:forklift'
    },
    {
        'name': 'fa_regular:forward'
    },
    {
        'name': 'fa_regular:fragile'
    },
    {
        'name': 'fa_regular:french-fries'
    },
    {
        'name': 'fa_regular:frog'
    },
    {
        'name': 'fa_regular:frosty-head'
    },
    {
        'name': 'fa_regular:frown-open'
    },
    {
        'name': 'fa_regular:frown'
    },
    {
        'name': 'fa_regular:function'
    },
    {
        'name': 'fa_regular:funnel-dollar'
    },
    {
        'name': 'fa_regular:futbol'
    },
    {
        'name': 'fa_regular:game-board-alt'
    },
    {
        'name': 'fa_regular:game-board'
    },
    {
        'name': 'fa_regular:gamepad'
    },
    {
        'name': 'fa_regular:gas-pump-slash'
    },
    {
        'name': 'fa_regular:gas-pump'
    },
    {
        'name': 'fa_regular:gavel'
    },
    {
        'name': 'fa_regular:gem'
    },
    {
        'name': 'fa_regular:genderless'
    },
    {
        'name': 'fa_regular:ghost'
    },
    {
        'name': 'fa_regular:gift-card'
    },
    {
        'name': 'fa_regular:gift'
    },
    {
        'name': 'fa_regular:gifts'
    },
    {
        'name': 'fa_regular:gingerbread-man'
    },
    {
        'name': 'fa_regular:glass-champagne'
    },
    {
        'name': 'fa_regular:glass-cheers'
    },
    {
        'name': 'fa_regular:glass-citrus'
    },
    {
        'name': 'fa_regular:glass-martini-alt'
    },
    {
        'name': 'fa_regular:glass-martini'
    },
    {
        'name': 'fa_regular:glass-whiskey-rocks'
    },
    {
        'name': 'fa_regular:glass-whiskey'
    },
    {
        'name': 'fa_regular:glass'
    },
    {
        'name': 'fa_regular:glasses-alt'
    },
    {
        'name': 'fa_regular:glasses'
    },
    {
        'name': 'fa_regular:globe-africa'
    },
    {
        'name': 'fa_regular:globe-americas'
    },
    {
        'name': 'fa_regular:globe-asia'
    },
    {
        'name': 'fa_regular:globe-europe'
    },
    {
        'name': 'fa_regular:globe-snow'
    },
    {
        'name': 'fa_regular:globe-stand'
    },
    {
        'name': 'fa_regular:globe'
    },
    {
        'name': 'fa_regular:golf-ball'
    },
    {
        'name': 'fa_regular:golf-club'
    },
    {
        'name': 'fa_regular:gopuram'
    },
    {
        'name': 'fa_regular:graduation-cap'
    },
    {
        'name': 'fa_regular:greater-than-equal'
    },
    {
        'name': 'fa_regular:greater-than'
    },
    {
        'name': 'fa_regular:grimace'
    },
    {
        'name': 'fa_regular:grin-alt'
    },
    {
        'name': 'fa_regular:grin-beam-sweat'
    },
    {
        'name': 'fa_regular:grin-beam'
    },
    {
        'name': 'fa_regular:grin-hearts'
    },
    {
        'name': 'fa_regular:grin-squint-tears'
    },
    {
        'name': 'fa_regular:grin-squint'
    },
    {
        'name': 'fa_regular:grin-stars'
    },
    {
        'name': 'fa_regular:grin-tears'
    },
    {
        'name': 'fa_regular:grin-tongue-squint'
    },
    {
        'name': 'fa_regular:grin-tongue-wink'
    },
    {
        'name': 'fa_regular:grin-tongue'
    },
    {
        'name': 'fa_regular:grin-wink'
    },
    {
        'name': 'fa_regular:grin'
    },
    {
        'name': 'fa_regular:grip-horizontal'
    },
    {
        'name': 'fa_regular:grip-lines-vertical'
    },
    {
        'name': 'fa_regular:grip-lines'
    },
    {
        'name': 'fa_regular:grip-vertical'
    },
    {
        'name': 'fa_regular:guitar'
    },
    {
        'name': 'fa_regular:h-square'
    },
    {
        'name': 'fa_regular:h1'
    },
    {
        'name': 'fa_regular:h2'
    },
    {
        'name': 'fa_regular:h3'
    },
    {
        'name': 'fa_regular:h4'
    },
    {
        'name': 'fa_regular:hamburger'
    },
    {
        'name': 'fa_regular:hammer-war'
    },
    {
        'name': 'fa_regular:hammer'
    },
    {
        'name': 'fa_regular:hamsa'
    },
    {
        'name': 'fa_regular:hand-heart'
    },
    {
        'name': 'fa_regular:hand-holding-box'
    },
    {
        'name': 'fa_regular:hand-holding-heart'
    },
    {
        'name': 'fa_regular:hand-holding-magic'
    },
    {
        'name': 'fa_regular:hand-holding-seedling'
    },
    {
        'name': 'fa_regular:hand-holding-usd'
    },
    {
        'name': 'fa_regular:hand-holding-water'
    },
    {
        'name': 'fa_regular:hand-holding'
    },
    {
        'name': 'fa_regular:hand-lizard'
    },
    {
        'name': 'fa_regular:hand-middle-finger'
    },
    {
        'name': 'fa_regular:hand-paper'
    },
    {
        'name': 'fa_regular:hand-peace'
    },
    {
        'name': 'fa_regular:hand-point-down'
    },
    {
        'name': 'fa_regular:hand-point-left'
    },
    {
        'name': 'fa_regular:hand-point-right'
    },
    {
        'name': 'fa_regular:hand-point-up'
    },
    {
        'name': 'fa_regular:hand-pointer'
    },
    {
        'name': 'fa_regular:hand-receiving'
    },
    {
        'name': 'fa_regular:hand-rock'
    },
    {
        'name': 'fa_regular:hand-scissors'
    },
    {
        'name': 'fa_regular:hand-spock'
    },
    {
        'name': 'fa_regular:hands-heart'
    },
    {
        'name': 'fa_regular:hands-helping'
    },
    {
        'name': 'fa_regular:hands-usd'
    },
    {
        'name': 'fa_regular:hands'
    },
    {
        'name': 'fa_regular:handshake-alt'
    },
    {
        'name': 'fa_regular:handshake'
    },
    {
        'name': 'fa_regular:hanukiah'
    },
    {
        'name': 'fa_regular:hard-hat'
    },
    {
        'name': 'fa_regular:hashtag'
    },
    {
        'name': 'fa_regular:hat-chef'
    },
    {
        'name': 'fa_regular:hat-santa'
    },
    {
        'name': 'fa_regular:hat-winter'
    },
    {
        'name': 'fa_regular:hat-witch'
    },
    {
        'name': 'fa_regular:hat-wizard'
    },
    {
        'name': 'fa_regular:haykal'
    },
    {
        'name': 'fa_regular:hdd'
    },
    {
        'name': 'fa_regular:head-side-brain'
    },
    {
        'name': 'fa_regular:head-side-medical'
    },
    {
        'name': 'fa_regular:head-side'
    },
    {
        'name': 'fa_regular:head-vr'
    },
    {
        'name': 'fa_regular:heading'
    },
    {
        'name': 'fa_regular:headphones-alt'
    },
    {
        'name': 'fa_regular:headphones'
    },
    {
        'name': 'fa_regular:headset'
    },
    {
        'name': 'fa_regular:heart-broken'
    },
    {
        'name': 'fa_regular:heart-circle'
    },
    {
        'name': 'fa_regular:heart-rate'
    },
    {
        'name': 'fa_regular:heart-square'
    },
    {
        'name': 'fa_regular:heart'
    },
    {
        'name': 'fa_regular:heartbeat'
    },
    {
        'name': 'fa_regular:helicopter'
    },
    {
        'name': 'fa_regular:helmet-battle'
    },
    {
        'name': 'fa_regular:hexagon'
    },
    {
        'name': 'fa_regular:highlighter'
    },
    {
        'name': 'fa_regular:hiking'
    },
    {
        'name': 'fa_regular:hippo'
    },
    {
        'name': 'fa_regular:history'
    },
    {
        'name': 'fa_regular:hockey-mask'
    },
    {
        'name': 'fa_regular:hockey-puck'
    },
    {
        'name': 'fa_regular:hockey-sticks'
    },
    {
        'name': 'fa_regular:holly-berry'
    },
    {
        'name': 'fa_regular:home-alt'
    },
    {
        'name': 'fa_regular:home-heart'
    },
    {
        'name': 'fa_regular:home-lg-alt'
    },
    {
        'name': 'fa_regular:home-lg'
    },
    {
        'name': 'fa_regular:home'
    },
    {
        'name': 'fa_regular:hood-cloak'
    },
    {
        'name': 'fa_regular:horizontal-rule'
    },
    {
        'name': 'fa_regular:horse-head'
    },
    {
        'name': 'fa_regular:horse'
    },
    {
        'name': 'fa_regular:hospital-alt'
    },
    {
        'name': 'fa_regular:hospital-symbol'
    },
    {
        'name': 'fa_regular:hospital-user'
    },
    {
        'name': 'fa_regular:hospital'
    },
    {
        'name': 'fa_regular:hospitals'
    },
    {
        'name': 'fa_regular:hot-tub'
    },
    {
        'name': 'fa_regular:hotdog'
    },
    {
        'name': 'fa_regular:hotel'
    },
    {
        'name': 'fa_regular:hourglass-end'
    },
    {
        'name': 'fa_regular:hourglass-half'
    },
    {
        'name': 'fa_regular:hourglass-start'
    },
    {
        'name': 'fa_regular:hourglass'
    },
    {
        'name': 'fa_regular:house-damage'
    },
    {
        'name': 'fa_regular:house-flood'
    },
    {
        'name': 'fa_regular:hryvnia'
    },
    {
        'name': 'fa_regular:humidity'
    },
    {
        'name': 'fa_regular:hurricane'
    },
    {
        'name': 'fa_regular:i-cursor'
    },
    {
        'name': 'fa_regular:ice-cream'
    },
    {
        'name': 'fa_regular:ice-skate'
    },
    {
        'name': 'fa_regular:icicles'
    },
    {
        'name': 'fa_regular:icons-alt'
    },
    {
        'name': 'fa_regular:icons'
    },
    {
        'name': 'fa_regular:id-badge'
    },
    {
        'name': 'fa_regular:id-card-alt'
    },
    {
        'name': 'fa_regular:id-card'
    },
    {
        'name': 'fa_regular:igloo'
    },
    {
        'name': 'fa_regular:image'
    },
    {
        'name': 'fa_regular:images'
    },
    {
        'name': 'fa_regular:inbox-in'
    },
    {
        'name': 'fa_regular:inbox-out'
    },
    {
        'name': 'fa_regular:inbox'
    },
    {
        'name': 'fa_regular:indent'
    },
    {
        'name': 'fa_regular:industry-alt'
    },
    {
        'name': 'fa_regular:industry'
    },
    {
        'name': 'fa_regular:infinity'
    },
    {
        'name': 'fa_regular:info-circle'
    },
    {
        'name': 'fa_regular:info-square'
    },
    {
        'name': 'fa_regular:info'
    },
    {
        'name': 'fa_regular:inhaler'
    },
    {
        'name': 'fa_regular:integral'
    },
    {
        'name': 'fa_regular:intersection'
    },
    {
        'name': 'fa_regular:inventory'
    },
    {
        'name': 'fa_regular:island-tropical'
    },
    {
        'name': 'fa_regular:italic'
    },
    {
        'name': 'fa_regular:jack-o-lantern'
    },
    {
        'name': 'fa_regular:jedi'
    },
    {
        'name': 'fa_regular:joint'
    },
    {
        'name': 'fa_regular:journal-whills'
    },
    {
        'name': 'fa_regular:kaaba'
    },
    {
        'name': 'fa_regular:kerning'
    },
    {
        'name': 'fa_regular:key-skeleton'
    },
    {
        'name': 'fa_regular:key'
    },
    {
        'name': 'fa_regular:keyboard'
    },
    {
        'name': 'fa_regular:keynote'
    },
    {
        'name': 'fa_regular:khanda'
    },
    {
        'name': 'fa_regular:kidneys'
    },
    {
        'name': 'fa_regular:kiss-beam'
    },
    {
        'name': 'fa_regular:kiss-wink-heart'
    },
    {
        'name': 'fa_regular:kiss'
    },
    {
        'name': 'fa_regular:kite'
    },
    {
        'name': 'fa_regular:kiwi-bird'
    },
    {
        'name': 'fa_regular:knife-kitchen'
    },
    {
        'name': 'fa_regular:lambda'
    },
    {
        'name': 'fa_regular:lamp'
    },
    {
        'name': 'fa_regular:landmark-alt'
    },
    {
        'name': 'fa_regular:landmark'
    },
    {
        'name': 'fa_regular:language'
    },
    {
        'name': 'fa_regular:laptop-code'
    },
    {
        'name': 'fa_regular:laptop-medical'
    },
    {
        'name': 'fa_regular:laptop'
    },
    {
        'name': 'fa_regular:laugh-beam'
    },
    {
        'name': 'fa_regular:laugh-squint'
    },
    {
        'name': 'fa_regular:laugh-wink'
    },
    {
        'name': 'fa_regular:laugh'
    },
    {
        'name': 'fa_regular:layer-group'
    },
    {
        'name': 'fa_regular:layer-minus'
    },
    {
        'name': 'fa_regular:layer-plus'
    },
    {
        'name': 'fa_regular:leaf-heart'
    },
    {
        'name': 'fa_regular:leaf-maple'
    },
    {
        'name': 'fa_regular:leaf-oak'
    },
    {
        'name': 'fa_regular:leaf'
    },
    {
        'name': 'fa_regular:lemon'
    },
    {
        'name': 'fa_regular:less-than-equal'
    },
    {
        'name': 'fa_regular:less-than'
    },
    {
        'name': 'fa_regular:level-down-alt'
    },
    {
        'name': 'fa_regular:level-down'
    },
    {
        'name': 'fa_regular:level-up-alt'
    },
    {
        'name': 'fa_regular:level-up'
    },
    {
        'name': 'fa_regular:life-ring'
    },
    {
        'name': 'fa_regular:lightbulb-dollar'
    },
    {
        'name': 'fa_regular:lightbulb-exclamation'
    },
    {
        'name': 'fa_regular:lightbulb-on'
    },
    {
        'name': 'fa_regular:lightbulb-slash'
    },
    {
        'name': 'fa_regular:lightbulb'
    },
    {
        'name': 'fa_regular:lights-holiday'
    },
    {
        'name': 'fa_regular:line-columns'
    },
    {
        'name': 'fa_regular:line-height'
    },
    {
        'name': 'fa_regular:link'
    },
    {
        'name': 'fa_regular:lips'
    },
    {
        'name': 'fa_regular:lira-sign'
    },
    {
        'name': 'fa_regular:list-alt'
    },
    {
        'name': 'fa_regular:list-ol'
    },
    {
        'name': 'fa_regular:list-ul'
    },
    {
        'name': 'fa_regular:list'
    },
    {
        'name': 'fa_regular:location-arrow'
    },
    {
        'name': 'fa_regular:location-circle'
    },
    {
        'name': 'fa_regular:location-slash'
    },
    {
        'name': 'fa_regular:location'
    },
    {
        'name': 'fa_regular:lock-alt'
    },
    {
        'name': 'fa_regular:lock-open-alt'
    },
    {
        'name': 'fa_regular:lock-open'
    },
    {
        'name': 'fa_regular:lock'
    },
    {
        'name': 'fa_regular:long-arrow-alt-down'
    },
    {
        'name': 'fa_regular:long-arrow-alt-left'
    },
    {
        'name': 'fa_regular:long-arrow-alt-right'
    },
    {
        'name': 'fa_regular:long-arrow-alt-up'
    },
    {
        'name': 'fa_regular:long-arrow-down'
    },
    {
        'name': 'fa_regular:long-arrow-left'
    },
    {
        'name': 'fa_regular:long-arrow-right'
    },
    {
        'name': 'fa_regular:long-arrow-up'
    },
    {
        'name': 'fa_regular:loveseat'
    },
    {
        'name': 'fa_regular:low-vision'
    },
    {
        'name': 'fa_regular:luchador'
    },
    {
        'name': 'fa_regular:luggage-cart'
    },
    {
        'name': 'fa_regular:lungs'
    },
    {
        'name': 'fa_regular:mace'
    },
    {
        'name': 'fa_regular:magic'
    },
    {
        'name': 'fa_regular:magnet'
    },
    {
        'name': 'fa_regular:mail-bulk'
    },
    {
        'name': 'fa_regular:mailbox'
    },
    {
        'name': 'fa_regular:male'
    },
    {
        'name': 'fa_regular:mandolin'
    },
    {
        'name': 'fa_regular:map-marked-alt'
    },
    {
        'name': 'fa_regular:map-marked'
    },
    {
        'name': 'fa_regular:map-marker-alt-slash'
    },
    {
        'name': 'fa_regular:map-marker-alt'
    },
    {
        'name': 'fa_regular:map-marker-check'
    },
    {
        'name': 'fa_regular:map-marker-edit'
    },
    {
        'name': 'fa_regular:map-marker-exclamation'
    },
    {
        'name': 'fa_regular:map-marker-minus'
    },
    {
        'name': 'fa_regular:map-marker-plus'
    },
    {
        'name': 'fa_regular:map-marker-question'
    },
    {
        'name': 'fa_regular:map-marker-slash'
    },
    {
        'name': 'fa_regular:map-marker-smile'
    },
    {
        'name': 'fa_regular:map-marker-times'
    },
    {
        'name': 'fa_regular:map-marker'
    },
    {
        'name': 'fa_regular:map-pin'
    },
    {
        'name': 'fa_regular:map-signs'
    },
    {
        'name': 'fa_regular:map'
    },
    {
        'name': 'fa_regular:marker'
    },
    {
        'name': 'fa_regular:mars-double'
    },
    {
        'name': 'fa_regular:mars-stroke-h'
    },
    {
        'name': 'fa_regular:mars-stroke-v'
    },
    {
        'name': 'fa_regular:mars-stroke'
    },
    {
        'name': 'fa_regular:mars'
    },
    {
        'name': 'fa_regular:mask'
    },
    {
        'name': 'fa_regular:meat'
    },
    {
        'name': 'fa_regular:medal'
    },
    {
        'name': 'fa_regular:medkit'
    },
    {
        'name': 'fa_regular:megaphone'
    },
    {
        'name': 'fa_regular:meh-blank'
    },
    {
        'name': 'fa_regular:meh-rolling-eyes'
    },
    {
        'name': 'fa_regular:meh'
    },
    {
        'name': 'fa_regular:memory'
    },
    {
        'name': 'fa_regular:menorah'
    },
    {
        'name': 'fa_regular:mercury'
    },
    {
        'name': 'fa_regular:meteor'
    },
    {
        'name': 'fa_regular:microchip'
    },
    {
        'name': 'fa_regular:microphone-alt-slash'
    },
    {
        'name': 'fa_regular:microphone-alt'
    },
    {
        'name': 'fa_regular:microphone-slash'
    },
    {
        'name': 'fa_regular:microphone'
    },
    {
        'name': 'fa_regular:microscope'
    },
    {
        'name': 'fa_regular:mind-share'
    },
    {
        'name': 'fa_regular:minus-circle'
    },
    {
        'name': 'fa_regular:minus-hexagon'
    },
    {
        'name': 'fa_regular:minus-octagon'
    },
    {
        'name': 'fa_regular:minus-square'
    },
    {
        'name': 'fa_regular:minus'
    },
    {
        'name': 'fa_regular:mistletoe'
    },
    {
        'name': 'fa_regular:mitten'
    },
    {
        'name': 'fa_regular:mobile-alt'
    },
    {
        'name': 'fa_regular:mobile-android-alt'
    },
    {
        'name': 'fa_regular:mobile-android'
    },
    {
        'name': 'fa_regular:mobile'
    },
    {
        'name': 'fa_regular:money-bill-alt'
    },
    {
        'name': 'fa_regular:money-bill-wave-alt'
    },
    {
        'name': 'fa_regular:money-bill-wave'
    },
    {
        'name': 'fa_regular:money-bill'
    },
    {
        'name': 'fa_regular:money-check-alt'
    },
    {
        'name': 'fa_regular:money-check-edit-alt'
    },
    {
        'name': 'fa_regular:money-check-edit'
    },
    {
        'name': 'fa_regular:money-check'
    },
    {
        'name': 'fa_regular:monitor-heart-rate'
    },
    {
        'name': 'fa_regular:monkey'
    },
    {
        'name': 'fa_regular:monument'
    },
    {
        'name': 'fa_regular:moon-cloud'
    },
    {
        'name': 'fa_regular:moon-stars'
    },
    {
        'name': 'fa_regular:moon'
    },
    {
        'name': 'fa_regular:mortar-pestle'
    },
    {
        'name': 'fa_regular:mosque'
    },
    {
        'name': 'fa_regular:motorcycle'
    },
    {
        'name': 'fa_regular:mountain'
    },
    {
        'name': 'fa_regular:mountains'
    },
    {
        'name': 'fa_regular:mouse-pointer'
    },
    {
        'name': 'fa_regular:mug-hot'
    },
    {
        'name': 'fa_regular:mug-marshmallows'
    },
    {
        'name': 'fa_regular:mug-tea'
    },
    {
        'name': 'fa_regular:mug'
    },
    {
        'name': 'fa_regular:music'
    },
    {
        'name': 'fa_regular:narwhal'
    },
    {
        'name': 'fa_regular:network-wired'
    },
    {
        'name': 'fa_regular:neuter'
    },
    {
        'name': 'fa_regular:newspaper'
    },
    {
        'name': 'fa_regular:not-equal'
    },
    {
        'name': 'fa_regular:notes-medical'
    },
    {
        'name': 'fa_regular:object-group'
    },
    {
        'name': 'fa_regular:object-ungroup'
    },
    {
        'name': 'fa_regular:octagon'
    },
    {
        'name': 'fa_regular:oil-can'
    },
    {
        'name': 'fa_regular:oil-temp'
    },
    {
        'name': 'fa_regular:om'
    },
    {
        'name': 'fa_regular:omega'
    },
    {
        'name': 'fa_regular:ornament'
    },
    {
        'name': 'fa_regular:otter'
    },
    {
        'name': 'fa_regular:outdent'
    },
    {
        'name': 'fa_regular:overline'
    },
    {
        'name': 'fa_regular:page-break'
    },
    {
        'name': 'fa_regular:pager'
    },
    {
        'name': 'fa_regular:paint-brush-alt'
    },
    {
        'name': 'fa_regular:paint-brush'
    },
    {
        'name': 'fa_regular:paint-roller'
    },
    {
        'name': 'fa_regular:palette'
    },
    {
        'name': 'fa_regular:pallet-alt'
    },
    {
        'name': 'fa_regular:pallet'
    },
    {
        'name': 'fa_regular:paper-plane'
    },
    {
        'name': 'fa_regular:paperclip'
    },
    {
        'name': 'fa_regular:parachute-box'
    },
    {
        'name': 'fa_regular:paragraph-rtl'
    },
    {
        'name': 'fa_regular:paragraph'
    },
    {
        'name': 'fa_regular:parking-circle-slash'
    },
    {
        'name': 'fa_regular:parking-circle'
    },
    {
        'name': 'fa_regular:parking-slash'
    },
    {
        'name': 'fa_regular:parking'
    },
    {
        'name': 'fa_regular:passport'
    },
    {
        'name': 'fa_regular:pastafarianism'
    },
    {
        'name': 'fa_regular:paste'
    },
    {
        'name': 'fa_regular:pause-circle'
    },
    {
        'name': 'fa_regular:pause'
    },
    {
        'name': 'fa_regular:paw-alt'
    },
    {
        'name': 'fa_regular:paw-claws'
    },
    {
        'name': 'fa_regular:paw'
    },
    {
        'name': 'fa_regular:peace'
    },
    {
        'name': 'fa_regular:pegasus'
    },
    {
        'name': 'fa_regular:pen-alt'
    },
    {
        'name': 'fa_regular:pen-fancy'
    },
    {
        'name': 'fa_regular:pen-nib'
    },
    {
        'name': 'fa_regular:pen-square'
    },
    {
        'name': 'fa_regular:pen'
    },
    {
        'name': 'fa_regular:pencil-alt'
    },
    {
        'name': 'fa_regular:pencil-paintbrush'
    },
    {
        'name': 'fa_regular:pencil-ruler'
    },
    {
        'name': 'fa_regular:pencil'
    },
    {
        'name': 'fa_regular:pennant'
    },
    {
        'name': 'fa_regular:people-carry'
    },
    {
        'name': 'fa_regular:pepper-hot'
    },
    {
        'name': 'fa_regular:percent'
    },
    {
        'name': 'fa_regular:percentage'
    },
    {
        'name': 'fa_regular:person-booth'
    },
    {
        'name': 'fa_regular:person-carry'
    },
    {
        'name': 'fa_regular:person-dolly-empty'
    },
    {
        'name': 'fa_regular:person-dolly'
    },
    {
        'name': 'fa_regular:person-sign'
    },
    {
        'name': 'fa_regular:phone-alt'
    },
    {
        'name': 'fa_regular:phone-laptop'
    },
    {
        'name': 'fa_regular:phone-office'
    },
    {
        'name': 'fa_regular:phone-plus'
    },
    {
        'name': 'fa_regular:phone-slash'
    },
    {
        'name': 'fa_regular:phone-square-alt'
    },
    {
        'name': 'fa_regular:phone-square'
    },
    {
        'name': 'fa_regular:phone-volume'
    },
    {
        'name': 'fa_regular:phone'
    },
    {
        'name': 'fa_regular:photo-video'
    },
    {
        'name': 'fa_regular:pi'
    },
    {
        'name': 'fa_regular:pie'
    },
    {
        'name': 'fa_regular:pig'
    },
    {
        'name': 'fa_regular:piggy-bank'
    },
    {
        'name': 'fa_regular:pills'
    },
    {
        'name': 'fa_regular:pizza-slice'
    },
    {
        'name': 'fa_regular:pizza'
    },
    {
        'name': 'fa_regular:place-of-worship'
    },
    {
        'name': 'fa_regular:plane-alt'
    },
    {
        'name': 'fa_regular:plane-arrival'
    },
    {
        'name': 'fa_regular:plane-departure'
    },
    {
        'name': 'fa_regular:plane'
    },
    {
        'name': 'fa_regular:play-circle'
    },
    {
        'name': 'fa_regular:play'
    },
    {
        'name': 'fa_regular:plug'
    },
    {
        'name': 'fa_regular:plus-circle'
    },
    {
        'name': 'fa_regular:plus-hexagon'
    },
    {
        'name': 'fa_regular:plus-octagon'
    },
    {
        'name': 'fa_regular:plus-square'
    },
    {
        'name': 'fa_regular:plus'
    },
    {
        'name': 'fa_regular:podcast'
    },
    {
        'name': 'fa_regular:podium-star'
    },
    {
        'name': 'fa_regular:podium'
    },
    {
        'name': 'fa_regular:poll-h'
    },
    {
        'name': 'fa_regular:poll-people'
    },
    {
        'name': 'fa_regular:poll'
    },
    {
        'name': 'fa_regular:poo-storm'
    },
    {
        'name': 'fa_regular:poo'
    },
    {
        'name': 'fa_regular:poop'
    },
    {
        'name': 'fa_regular:popcorn'
    },
    {
        'name': 'fa_regular:portrait'
    },
    {
        'name': 'fa_regular:pound-sign'
    },
    {
        'name': 'fa_regular:power-off'
    },
    {
        'name': 'fa_regular:pray'
    },
    {
        'name': 'fa_regular:praying-hands'
    },
    {
        'name': 'fa_regular:prescription-bottle-alt'
    },
    {
        'name': 'fa_regular:prescription-bottle'
    },
    {
        'name': 'fa_regular:prescription'
    },
    {
        'name': 'fa_regular:presentation'
    },
    {
        'name': 'fa_regular:print-search'
    },
    {
        'name': 'fa_regular:print-slash'
    },
    {
        'name': 'fa_regular:print'
    },
    {
        'name': 'fa_regular:procedures'
    },
    {
        'name': 'fa_regular:project-diagram'
    },
    {
        'name': 'fa_regular:pumpkin'
    },
    {
        'name': 'fa_regular:puzzle-piece'
    },
    {
        'name': 'fa_regular:qrcode'
    },
    {
        'name': 'fa_regular:question-circle'
    },
    {
        'name': 'fa_regular:question-square'
    },
    {
        'name': 'fa_regular:question'
    },
    {
        'name': 'fa_regular:quidditch'
    },
    {
        'name': 'fa_regular:quote-left'
    },
    {
        'name': 'fa_regular:quote-right'
    },
    {
        'name': 'fa_regular:quran'
    },
    {
        'name': 'fa_regular:rabbit-fast'
    },
    {
        'name': 'fa_regular:rabbit'
    },
    {
        'name': 'fa_regular:racquet'
    },
    {
        'name': 'fa_regular:radiation-alt'
    },
    {
        'name': 'fa_regular:radiation'
    },
    {
        'name': 'fa_regular:rainbow'
    },
    {
        'name': 'fa_regular:raindrops'
    },
    {
        'name': 'fa_regular:ram'
    },
    {
        'name': 'fa_regular:ramp-loading'
    },
    {
        'name': 'fa_regular:random'
    },
    {
        'name': 'fa_regular:receipt'
    },
    {
        'name': 'fa_regular:rectangle-landscape'
    },
    {
        'name': 'fa_regular:rectangle-portrait'
    },
    {
        'name': 'fa_regular:rectangle-wide'
    },
    {
        'name': 'fa_regular:recycle'
    },
    {
        'name': 'fa_regular:redo-alt'
    },
    {
        'name': 'fa_regular:redo'
    },
    {
        'name': 'fa_regular:registered'
    },
    {
        'name': 'fa_regular:remove-format'
    },
    {
        'name': 'fa_regular:repeat-1-alt'
    },
    {
        'name': 'fa_regular:repeat-1'
    },
    {
        'name': 'fa_regular:repeat-alt'
    },
    {
        'name': 'fa_regular:repeat'
    },
    {
        'name': 'fa_regular:reply-all'
    },
    {
        'name': 'fa_regular:reply'
    },
    {
        'name': 'fa_regular:republican'
    },
    {
        'name': 'fa_regular:restroom'
    },
    {
        'name': 'fa_regular:retweet-alt'
    },
    {
        'name': 'fa_regular:retweet'
    },
    {
        'name': 'fa_regular:ribbon'
    },
    {
        'name': 'fa_regular:ring'
    },
    {
        'name': 'fa_regular:rings-wedding'
    },
    {
        'name': 'fa_regular:road'
    },
    {
        'name': 'fa_regular:robot'
    },
    {
        'name': 'fa_regular:rocket'
    },
    {
        'name': 'fa_regular:route-highway'
    },
    {
        'name': 'fa_regular:route-interstate'
    },
    {
        'name': 'fa_regular:route'
    },
    {
        'name': 'fa_regular:rss-square'
    },
    {
        'name': 'fa_regular:rss'
    },
    {
        'name': 'fa_regular:ruble-sign'
    },
    {
        'name': 'fa_regular:ruler-combined'
    },
    {
        'name': 'fa_regular:ruler-horizontal'
    },
    {
        'name': 'fa_regular:ruler-triangle'
    },
    {
        'name': 'fa_regular:ruler-vertical'
    },
    {
        'name': 'fa_regular:ruler'
    },
    {
        'name': 'fa_regular:running'
    },
    {
        'name': 'fa_regular:rupee-sign'
    },
    {
        'name': 'fa_regular:rv'
    },
    {
        'name': 'fa_regular:sack-dollar'
    },
    {
        'name': 'fa_regular:sack'
    },
    {
        'name': 'fa_regular:sad-cry'
    },
    {
        'name': 'fa_regular:sad-tear'
    },
    {
        'name': 'fa_regular:salad'
    },
    {
        'name': 'fa_regular:sandwich'
    },
    {
        'name': 'fa_regular:satellite-dish'
    },
    {
        'name': 'fa_regular:satellite'
    },
    {
        'name': 'fa_regular:sausage'
    },
    {
        'name': 'fa_regular:save'
    },
    {
        'name': 'fa_regular:scalpel-path'
    },
    {
        'name': 'fa_regular:scalpel'
    },
    {
        'name': 'fa_regular:scanner-keyboard'
    },
    {
        'name': 'fa_regular:scanner-touchscreen'
    },
    {
        'name': 'fa_regular:scanner'
    },
    {
        'name': 'fa_regular:scarecrow'
    },
    {
        'name': 'fa_regular:scarf'
    },
    {
        'name': 'fa_regular:school'
    },
    {
        'name': 'fa_regular:screwdriver'
    },
    {
        'name': 'fa_regular:scroll-old'
    },
    {
        'name': 'fa_regular:scroll'
    },
    {
        'name': 'fa_regular:scrubber'
    },
    {
        'name': 'fa_regular:scythe'
    },
    {
        'name': 'fa_regular:sd-card'
    },
    {
        'name': 'fa_regular:search-dollar'
    },
    {
        'name': 'fa_regular:search-location'
    },
    {
        'name': 'fa_regular:search-minus'
    },
    {
        'name': 'fa_regular:search-plus'
    },
    {
        'name': 'fa_regular:search'
    },
    {
        'name': 'fa_regular:seedling'
    },
    {
        'name': 'fa_regular:send-back'
    },
    {
        'name': 'fa_regular:send-backward'
    },
    {
        'name': 'fa_regular:server'
    },
    {
        'name': 'fa_regular:shapes'
    },
    {
        'name': 'fa_regular:share-all'
    },
    {
        'name': 'fa_regular:share-alt-square'
    },
    {
        'name': 'fa_regular:share-alt'
    },
    {
        'name': 'fa_regular:share-square'
    },
    {
        'name': 'fa_regular:share'
    },
    {
        'name': 'fa_regular:sheep'
    },
    {
        'name': 'fa_regular:shekel-sign'
    },
    {
        'name': 'fa_regular:shield-alt'
    },
    {
        'name': 'fa_regular:shield-check'
    },
    {
        'name': 'fa_regular:shield-cross'
    },
    {
        'name': 'fa_regular:shield'
    },
    {
        'name': 'fa_regular:ship'
    },
    {
        'name': 'fa_regular:shipping-fast'
    },
    {
        'name': 'fa_regular:shipping-timed'
    },
    {
        'name': 'fa_regular:shish-kebab'
    },
    {
        'name': 'fa_regular:shoe-prints'
    },
    {
        'name': 'fa_regular:shopping-bag'
    },
    {
        'name': 'fa_regular:shopping-basket'
    },
    {
        'name': 'fa_regular:shopping-cart'
    },
    {
        'name': 'fa_regular:shovel-snow'
    },
    {
        'name': 'fa_regular:shovel'
    },
    {
        'name': 'fa_regular:shower'
    },
    {
        'name': 'fa_regular:shredder'
    },
    {
        'name': 'fa_regular:shuttle-van'
    },
    {
        'name': 'fa_regular:shuttlecock'
    },
    {
        'name': 'fa_regular:sickle'
    },
    {
        'name': 'fa_regular:sigma'
    },
    {
        'name': 'fa_regular:sign-in-alt'
    },
    {
        'name': 'fa_regular:sign-in'
    },
    {
        'name': 'fa_regular:sign-language'
    },
    {
        'name': 'fa_regular:sign-out-alt'
    },
    {
        'name': 'fa_regular:sign-out'
    },
    {
        'name': 'fa_regular:sign'
    },
    {
        'name': 'fa_regular:signal-1'
    },
    {
        'name': 'fa_regular:signal-2'
    },
    {
        'name': 'fa_regular:signal-3'
    },
    {
        'name': 'fa_regular:signal-4'
    },
    {
        'name': 'fa_regular:signal-alt-1'
    },
    {
        'name': 'fa_regular:signal-alt-2'
    },
    {
        'name': 'fa_regular:signal-alt-3'
    },
    {
        'name': 'fa_regular:signal-alt-slash'
    },
    {
        'name': 'fa_regular:signal-alt'
    },
    {
        'name': 'fa_regular:signal-slash'
    },
    {
        'name': 'fa_regular:signal'
    },
    {
        'name': 'fa_regular:signature'
    },
    {
        'name': 'fa_regular:sim-card'
    },
    {
        'name': 'fa_regular:sitemap'
    },
    {
        'name': 'fa_regular:skating'
    },
    {
        'name': 'fa_regular:skeleton'
    },
    {
        'name': 'fa_regular:ski-jump'
    },
    {
        'name': 'fa_regular:ski-lift'
    },
    {
        'name': 'fa_regular:skiing-nordic'
    },
    {
        'name': 'fa_regular:skiing'
    },
    {
        'name': 'fa_regular:skull-crossbones'
    },
    {
        'name': 'fa_regular:skull'
    },
    {
        'name': 'fa_regular:slash'
    },
    {
        'name': 'fa_regular:sledding'
    },
    {
        'name': 'fa_regular:sleigh'
    },
    {
        'name': 'fa_regular:sliders-h-square'
    },
    {
        'name': 'fa_regular:sliders-h'
    },
    {
        'name': 'fa_regular:sliders-v-square'
    },
    {
        'name': 'fa_regular:sliders-v'
    },
    {
        'name': 'fa_regular:smile-beam'
    },
    {
        'name': 'fa_regular:smile-plus'
    },
    {
        'name': 'fa_regular:smile-wink'
    },
    {
        'name': 'fa_regular:smile'
    },
    {
        'name': 'fa_regular:smog'
    },
    {
        'name': 'fa_regular:smoke'
    },
    {
        'name': 'fa_regular:smoking-ban'
    },
    {
        'name': 'fa_regular:smoking'
    },
    {
        'name': 'fa_regular:sms'
    },
    {
        'name': 'fa_regular:snake'
    },
    {
        'name': 'fa_regular:snooze'
    },
    {
        'name': 'fa_regular:snow-blowing'
    },
    {
        'name': 'fa_regular:snowboarding'
    },
    {
        'name': 'fa_regular:snowflake'
    },
    {
        'name': 'fa_regular:snowflakes'
    },
    {
        'name': 'fa_regular:snowman'
    },
    {
        'name': 'fa_regular:snowmobile'
    },
    {
        'name': 'fa_regular:snowplow'
    },
    {
        'name': 'fa_regular:socks'
    },
    {
        'name': 'fa_regular:solar-panel'
    },
    {
        'name': 'fa_regular:sort-alpha-down-alt'
    },
    {
        'name': 'fa_regular:sort-alpha-down'
    },
    {
        'name': 'fa_regular:sort-alpha-up-alt'
    },
    {
        'name': 'fa_regular:sort-alpha-up'
    },
    {
        'name': 'fa_regular:sort-alt'
    },
    {
        'name': 'fa_regular:sort-amount-down-alt'
    },
    {
        'name': 'fa_regular:sort-amount-down'
    },
    {
        'name': 'fa_regular:sort-amount-up-alt'
    },
    {
        'name': 'fa_regular:sort-amount-up'
    },
    {
        'name': 'fa_regular:sort-down'
    },
    {
        'name': 'fa_regular:sort-numeric-down-alt'
    },
    {
        'name': 'fa_regular:sort-numeric-down'
    },
    {
        'name': 'fa_regular:sort-numeric-up-alt'
    },
    {
        'name': 'fa_regular:sort-numeric-up'
    },
    {
        'name': 'fa_regular:sort-shapes-down-alt'
    },
    {
        'name': 'fa_regular:sort-shapes-down'
    },
    {
        'name': 'fa_regular:sort-shapes-up-alt'
    },
    {
        'name': 'fa_regular:sort-shapes-up'
    },
    {
        'name': 'fa_regular:sort-size-down-alt'
    },
    {
        'name': 'fa_regular:sort-size-down'
    },
    {
        'name': 'fa_regular:sort-size-up-alt'
    },
    {
        'name': 'fa_regular:sort-size-up'
    },
    {
        'name': 'fa_regular:sort-up'
    },
    {
        'name': 'fa_regular:sort'
    },
    {
        'name': 'fa_regular:soup'
    },
    {
        'name': 'fa_regular:spa'
    },
    {
        'name': 'fa_regular:space-shuttle'
    },
    {
        'name': 'fa_regular:spade'
    },
    {
        'name': 'fa_regular:sparkles'
    },
    {
        'name': 'fa_regular:spell-check'
    },
    {
        'name': 'fa_regular:spider-black-widow'
    },
    {
        'name': 'fa_regular:spider-web'
    },
    {
        'name': 'fa_regular:spider'
    },
    {
        'name': 'fa_regular:spinner-third'
    },
    {
        'name': 'fa_regular:spinner'
    },
    {
        'name': 'fa_regular:splotch'
    },
    {
        'name': 'fa_regular:spray-can'
    },
    {
        'name': 'fa_regular:square-full'
    },
    {
        'name': 'fa_regular:square-root-alt'
    },
    {
        'name': 'fa_regular:square-root'
    },
    {
        'name': 'fa_regular:square'
    },
    {
        'name': 'fa_regular:squirrel'
    },
    {
        'name': 'fa_regular:staff'
    },
    {
        'name': 'fa_regular:stamp'
    },
    {
        'name': 'fa_regular:star-and-crescent'
    },
    {
        'name': 'fa_regular:star-christmas'
    },
    {
        'name': 'fa_regular:star-exclamation'
    },
    {
        'name': 'fa_regular:star-half-alt'
    },
    {
        'name': 'fa_regular:star-half'
    },
    {
        'name': 'fa_regular:star-of-david'
    },
    {
        'name': 'fa_regular:star-of-life'
    },
    {
        'name': 'fa_regular:star'
    },
    {
        'name': 'fa_regular:stars'
    },
    {
        'name': 'fa_regular:steak'
    },
    {
        'name': 'fa_regular:steering-wheel'
    },
    {
        'name': 'fa_regular:step-backward'
    },
    {
        'name': 'fa_regular:step-forward'
    },
    {
        'name': 'fa_regular:stethoscope'
    },
    {
        'name': 'fa_regular:sticky-note'
    },
    {
        'name': 'fa_regular:stocking'
    },
    {
        'name': 'fa_regular:stomach'
    },
    {
        'name': 'fa_regular:stop-circle'
    },
    {
        'name': 'fa_regular:stop'
    },
    {
        'name': 'fa_regular:stopwatch'
    },
    {
        'name': 'fa_regular:store-alt'
    },
    {
        'name': 'fa_regular:store'
    },
    {
        'name': 'fa_regular:stream'
    },
    {
        'name': 'fa_regular:street-view'
    },
    {
        'name': 'fa_regular:stretcher'
    },
    {
        'name': 'fa_regular:strikethrough'
    },
    {
        'name': 'fa_regular:stroopwafel'
    },
    {
        'name': 'fa_regular:subscript'
    },
    {
        'name': 'fa_regular:subway'
    },
    {
        'name': 'fa_regular:suitcase-rolling'
    },
    {
        'name': 'fa_regular:suitcase'
    },
    {
        'name': 'fa_regular:sun-cloud'
    },
    {
        'name': 'fa_regular:sun-dust'
    },
    {
        'name': 'fa_regular:sun-haze'
    },
    {
        'name': 'fa_regular:sun'
    },
    {
        'name': 'fa_regular:sunglasses'
    },
    {
        'name': 'fa_regular:sunrise'
    },
    {
        'name': 'fa_regular:sunset'
    },
    {
        'name': 'fa_regular:superscript'
    },
    {
        'name': 'fa_regular:surprise'
    },
    {
        'name': 'fa_regular:swatchbook'
    },
    {
        'name': 'fa_regular:swimmer'
    },
    {
        'name': 'fa_regular:swimming-pool'
    },
    {
        'name': 'fa_regular:sword'
    },
    {
        'name': 'fa_regular:swords'
    },
    {
        'name': 'fa_regular:synagogue'
    },
    {
        'name': 'fa_regular:sync-alt'
    },
    {
        'name': 'fa_regular:sync'
    },
    {
        'name': 'fa_regular:syringe'
    },
    {
        'name': 'fa_regular:table-tennis'
    },
    {
        'name': 'fa_regular:table'
    },
    {
        'name': 'fa_regular:tablet-alt'
    },
    {
        'name': 'fa_regular:tablet-android-alt'
    },
    {
        'name': 'fa_regular:tablet-android'
    },
    {
        'name': 'fa_regular:tablet-rugged'
    },
    {
        'name': 'fa_regular:tablet'
    },
    {
        'name': 'fa_regular:tablets'
    },
    {
        'name': 'fa_regular:tachometer-alt-average'
    },
    {
        'name': 'fa_regular:tachometer-alt-fast'
    },
    {
        'name': 'fa_regular:tachometer-alt-fastest'
    },
    {
        'name': 'fa_regular:tachometer-alt-slow'
    },
    {
        'name': 'fa_regular:tachometer-alt-slowest'
    },
    {
        'name': 'fa_regular:tachometer-alt'
    },
    {
        'name': 'fa_regular:tachometer-average'
    },
    {
        'name': 'fa_regular:tachometer-fast'
    },
    {
        'name': 'fa_regular:tachometer-fastest'
    },
    {
        'name': 'fa_regular:tachometer-slow'
    },
    {
        'name': 'fa_regular:tachometer-slowest'
    },
    {
        'name': 'fa_regular:tachometer'
    },
    {
        'name': 'fa_regular:taco'
    },
    {
        'name': 'fa_regular:tag'
    },
    {
        'name': 'fa_regular:tags'
    },
    {
        'name': 'fa_regular:tally'
    },
    {
        'name': 'fa_regular:tanakh'
    },
    {
        'name': 'fa_regular:tape'
    },
    {
        'name': 'fa_regular:tasks-alt'
    },
    {
        'name': 'fa_regular:tasks'
    },
    {
        'name': 'fa_regular:taxi'
    },
    {
        'name': 'fa_regular:teeth-open'
    },
    {
        'name': 'fa_regular:teeth'
    },
    {
        'name': 'fa_regular:temperature-frigid'
    },
    {
        'name': 'fa_regular:temperature-high'
    },
    {
        'name': 'fa_regular:temperature-hot'
    },
    {
        'name': 'fa_regular:temperature-low'
    },
    {
        'name': 'fa_regular:tenge'
    },
    {
        'name': 'fa_regular:tennis-ball'
    },
    {
        'name': 'fa_regular:terminal'
    },
    {
        'name': 'fa_regular:text-height'
    },
    {
        'name': 'fa_regular:text-size'
    },
    {
        'name': 'fa_regular:text-width'
    },
    {
        'name': 'fa_regular:text'
    },
    {
        'name': 'fa_regular:th-large'
    },
    {
        'name': 'fa_regular:th-list'
    },
    {
        'name': 'fa_regular:th'
    },
    {
        'name': 'fa_regular:theater-masks'
    },
    {
        'name': 'fa_regular:thermometer-empty'
    },
    {
        'name': 'fa_regular:thermometer-full'
    },
    {
        'name': 'fa_regular:thermometer-half'
    },
    {
        'name': 'fa_regular:thermometer-quarter'
    },
    {
        'name': 'fa_regular:thermometer-three-quarters'
    },
    {
        'name': 'fa_regular:thermometer'
    },
    {
        'name': 'fa_regular:theta'
    },
    {
        'name': 'fa_regular:thumbs-down'
    },
    {
        'name': 'fa_regular:thumbs-up'
    },
    {
        'name': 'fa_regular:thumbtack'
    },
    {
        'name': 'fa_regular:thunderstorm-moon'
    },
    {
        'name': 'fa_regular:thunderstorm-sun'
    },
    {
        'name': 'fa_regular:thunderstorm'
    },
    {
        'name': 'fa_regular:ticket-alt'
    },
    {
        'name': 'fa_regular:ticket'
    },
    {
        'name': 'fa_regular:tilde'
    },
    {
        'name': 'fa_regular:times-circle'
    },
    {
        'name': 'fa_regular:times-hexagon'
    },
    {
        'name': 'fa_regular:times-octagon'
    },
    {
        'name': 'fa_regular:times-square'
    },
    {
        'name': 'fa_regular:times'
    },
    {
        'name': 'fa_regular:tint-slash'
    },
    {
        'name': 'fa_regular:tint'
    },
    {
        'name': 'fa_regular:tire-flat'
    },
    {
        'name': 'fa_regular:tire-pressure-warning'
    },
    {
        'name': 'fa_regular:tire-rugged'
    },
    {
        'name': 'fa_regular:tire'
    },
    {
        'name': 'fa_regular:tired'
    },
    {
        'name': 'fa_regular:toggle-off'
    },
    {
        'name': 'fa_regular:toggle-on'
    },
    {
        'name': 'fa_regular:toilet-paper-alt'
    },
    {
        'name': 'fa_regular:toilet-paper'
    },
    {
        'name': 'fa_regular:toilet'
    },
    {
        'name': 'fa_regular:tombstone-alt'
    },
    {
        'name': 'fa_regular:tombstone'
    },
    {
        'name': 'fa_regular:toolbox'
    },
    {
        'name': 'fa_regular:tools'
    },
    {
        'name': 'fa_regular:tooth'
    },
    {
        'name': 'fa_regular:toothbrush'
    },
    {
        'name': 'fa_regular:torah'
    },
    {
        'name': 'fa_regular:torii-gate'
    },
    {
        'name': 'fa_regular:tornado'
    },
    {
        'name': 'fa_regular:tractor'
    },
    {
        'name': 'fa_regular:trademark'
    },
    {
        'name': 'fa_regular:traffic-cone'
    },
    {
        'name': 'fa_regular:traffic-light-go'
    },
    {
        'name': 'fa_regular:traffic-light-slow'
    },
    {
        'name': 'fa_regular:traffic-light-stop'
    },
    {
        'name': 'fa_regular:traffic-light'
    },
    {
        'name': 'fa_regular:train'
    },
    {
        'name': 'fa_regular:tram'
    },
    {
        'name': 'fa_regular:transgender-alt'
    },
    {
        'name': 'fa_regular:transgender'
    },
    {
        'name': 'fa_regular:trash-alt'
    },
    {
        'name': 'fa_regular:trash-restore-alt'
    },
    {
        'name': 'fa_regular:trash-restore'
    },
    {
        'name': 'fa_regular:trash-undo-alt'
    },
    {
        'name': 'fa_regular:trash-undo'
    },
    {
        'name': 'fa_regular:trash'
    },
    {
        'name': 'fa_regular:treasure-chest'
    },
    {
        'name': 'fa_regular:tree-alt'
    },
    {
        'name': 'fa_regular:tree-christmas'
    },
    {
        'name': 'fa_regular:tree-decorated'
    },
    {
        'name': 'fa_regular:tree-large'
    },
    {
        'name': 'fa_regular:tree-palm'
    },
    {
        'name': 'fa_regular:tree'
    },
    {
        'name': 'fa_regular:trees'
    },
    {
        'name': 'fa_regular:triangle'
    },
    {
        'name': 'fa_regular:trophy-alt'
    },
    {
        'name': 'fa_regular:trophy'
    },
    {
        'name': 'fa_regular:truck-container'
    },
    {
        'name': 'fa_regular:truck-couch'
    },
    {
        'name': 'fa_regular:truck-loading'
    },
    {
        'name': 'fa_regular:truck-monster'
    },
    {
        'name': 'fa_regular:truck-moving'
    },
    {
        'name': 'fa_regular:truck-pickup'
    },
    {
        'name': 'fa_regular:truck-plow'
    },
    {
        'name': 'fa_regular:truck-ramp'
    },
    {
        'name': 'fa_regular:truck'
    },
    {
        'name': 'fa_regular:tshirt'
    },
    {
        'name': 'fa_regular:tty'
    },
    {
        'name': 'fa_regular:turkey'
    },
    {
        'name': 'fa_regular:turtle'
    },
    {
        'name': 'fa_regular:tv-retro'
    },
    {
        'name': 'fa_regular:tv'
    },
    {
        'name': 'fa_regular:umbrella-beach'
    },
    {
        'name': 'fa_regular:umbrella'
    },
    {
        'name': 'fa_regular:underline'
    },
    {
        'name': 'fa_regular:undo-alt'
    },
    {
        'name': 'fa_regular:undo'
    },
    {
        'name': 'fa_regular:unicorn'
    },
    {
        'name': 'fa_regular:union'
    },
    {
        'name': 'fa_regular:universal-access'
    },
    {
        'name': 'fa_regular:university'
    },
    {
        'name': 'fa_regular:unlink'
    },
    {
        'name': 'fa_regular:unlock-alt'
    },
    {
        'name': 'fa_regular:unlock'
    },
    {
        'name': 'fa_regular:upload'
    },
    {
        'name': 'fa_regular:usd-circle'
    },
    {
        'name': 'fa_regular:usd-square'
    },
    {
        'name': 'fa_regular:user-alt-slash'
    },
    {
        'name': 'fa_regular:user-alt'
    },
    {
        'name': 'fa_regular:user-astronaut'
    },
    {
        'name': 'fa_regular:user-chart'
    },
    {
        'name': 'fa_regular:user-check'
    },
    {
        'name': 'fa_regular:user-circle'
    },
    {
        'name': 'fa_regular:user-clock'
    },
    {
        'name': 'fa_regular:user-cog'
    },
    {
        'name': 'fa_regular:user-crown'
    },
    {
        'name': 'fa_regular:user-edit'
    },
    {
        'name': 'fa_regular:user-friends'
    },
    {
        'name': 'fa_regular:user-graduate'
    },
    {
        'name': 'fa_regular:user-hard-hat'
    },
    {
        'name': 'fa_regular:user-headset'
    },
    {
        'name': 'fa_regular:user-injured'
    },
    {
        'name': 'fa_regular:user-lock'
    },
    {
        'name': 'fa_regular:user-md-chat'
    },
    {
        'name': 'fa_regular:user-md'
    },
    {
        'name': 'fa_regular:user-minus'
    },
    {
        'name': 'fa_regular:user-ninja'
    },
    {
        'name': 'fa_regular:user-nurse'
    },
    {
        'name': 'fa_regular:user-plus'
    },
    {
        'name': 'fa_regular:user-secret'
    },
    {
        'name': 'fa_regular:user-shield'
    },
    {
        'name': 'fa_regular:user-slash'
    },
    {
        'name': 'fa_regular:user-tag'
    },
    {
        'name': 'fa_regular:user-tie'
    },
    {
        'name': 'fa_regular:user-times'
    },
    {
        'name': 'fa_regular:user'
    },
    {
        'name': 'fa_regular:users-class'
    },
    {
        'name': 'fa_regular:users-cog'
    },
    {
        'name': 'fa_regular:users-crown'
    },
    {
        'name': 'fa_regular:users-medical'
    },
    {
        'name': 'fa_regular:users'
    },
    {
        'name': 'fa_regular:utensil-fork'
    },
    {
        'name': 'fa_regular:utensil-knife'
    },
    {
        'name': 'fa_regular:utensil-spoon'
    },
    {
        'name': 'fa_regular:utensils-alt'
    },
    {
        'name': 'fa_regular:utensils'
    },
    {
        'name': 'fa_regular:value-absolute'
    },
    {
        'name': 'fa_regular:vector-square'
    },
    {
        'name': 'fa_regular:venus-double'
    },
    {
        'name': 'fa_regular:venus-mars'
    },
    {
        'name': 'fa_regular:venus'
    },
    {
        'name': 'fa_regular:vial'
    },
    {
        'name': 'fa_regular:vials'
    },
    {
        'name': 'fa_regular:video-plus'
    },
    {
        'name': 'fa_regular:video-slash'
    },
    {
        'name': 'fa_regular:video'
    },
    {
        'name': 'fa_regular:vihara'
    },
    {
        'name': 'fa_regular:voicemail'
    },
    {
        'name': 'fa_regular:volcano'
    },
    {
        'name': 'fa_regular:volleyball-ball'
    },
    {
        'name': 'fa_regular:volume-down'
    },
    {
        'name': 'fa_regular:volume-mute'
    },
    {
        'name': 'fa_regular:volume-off'
    },
    {
        'name': 'fa_regular:volume-slash'
    },
    {
        'name': 'fa_regular:volume-up'
    },
    {
        'name': 'fa_regular:volume'
    },
    {
        'name': 'fa_regular:vote-nay'
    },
    {
        'name': 'fa_regular:vote-yea'
    },
    {
        'name': 'fa_regular:vr-cardboard'
    },
    {
        'name': 'fa_regular:walker'
    },
    {
        'name': 'fa_regular:walking'
    },
    {
        'name': 'fa_regular:wallet'
    },
    {
        'name': 'fa_regular:wand-magic'
    },
    {
        'name': 'fa_regular:wand'
    },
    {
        'name': 'fa_regular:warehouse-alt'
    },
    {
        'name': 'fa_regular:warehouse'
    },
    {
        'name': 'fa_regular:washer'
    },
    {
        'name': 'fa_regular:watch-fitness'
    },
    {
        'name': 'fa_regular:watch'
    },
    {
        'name': 'fa_regular:water-lower'
    },
    {
        'name': 'fa_regular:water-rise'
    },
    {
        'name': 'fa_regular:water'
    },
    {
        'name': 'fa_regular:wave-sine'
    },
    {
        'name': 'fa_regular:wave-square'
    },
    {
        'name': 'fa_regular:wave-triangle'
    },
    {
        'name': 'fa_regular:webcam-slash'
    },
    {
        'name': 'fa_regular:webcam'
    },
    {
        'name': 'fa_regular:weight-hanging'
    },
    {
        'name': 'fa_regular:weight'
    },
    {
        'name': 'fa_regular:whale'
    },
    {
        'name': 'fa_regular:wheat'
    },
    {
        'name': 'fa_regular:wheelchair'
    },
    {
        'name': 'fa_regular:whistle'
    },
    {
        'name': 'fa_regular:wifi-1'
    },
    {
        'name': 'fa_regular:wifi-2'
    },
    {
        'name': 'fa_regular:wifi-slash'
    },
    {
        'name': 'fa_regular:wifi'
    },
    {
        'name': 'fa_regular:wind-turbine'
    },
    {
        'name': 'fa_regular:wind-warning'
    },
    {
        'name': 'fa_regular:wind'
    },
    {
        'name': 'fa_regular:window-alt'
    },
    {
        'name': 'fa_regular:window-close'
    },
    {
        'name': 'fa_regular:window-maximize'
    },
    {
        'name': 'fa_regular:window-minimize'
    },
    {
        'name': 'fa_regular:window-restore'
    },
    {
        'name': 'fa_regular:window'
    },
    {
        'name': 'fa_regular:windsock'
    },
    {
        'name': 'fa_regular:wine-bottle'
    },
    {
        'name': 'fa_regular:wine-glass-alt'
    },
    {
        'name': 'fa_regular:wine-glass'
    },
    {
        'name': 'fa_regular:won-sign'
    },
    {
        'name': 'fa_regular:wreath'
    },
    {
        'name': 'fa_regular:wrench'
    },
    {
        'name': 'fa_regular:x-ray'
    },
    {
        'name': 'fa_regular:yen-sign'
    },
    {
        'name': 'fa_regular:yin-yang'
    },
    {
        'name': 'fa_duotone:abacus'
    },
    {
        'name': 'fa_duotone:acorn'
    },
    {
        'name': 'fa_duotone:ad'
    },
    {
        'name': 'fa_duotone:address-book'
    },
    {
        'name': 'fa_duotone:address-card'
    },
    {
        'name': 'fa_duotone:adjust'
    },
    {
        'name': 'fa_duotone:air-freshener'
    },
    {
        'name': 'fa_duotone:alarm-clock'
    },
    {
        'name': 'fa_duotone:alarm-exclamation'
    },
    {
        'name': 'fa_duotone:alarm-plus'
    },
    {
        'name': 'fa_duotone:alarm-snooze'
    },
    {
        'name': 'fa_duotone:alicorn'
    },
    {
        'name': 'fa_duotone:align-center'
    },
    {
        'name': 'fa_duotone:align-justify'
    },
    {
        'name': 'fa_duotone:align-left'
    },
    {
        'name': 'fa_duotone:align-right'
    },
    {
        'name': 'fa_duotone:align-slash'
    },
    {
        'name': 'fa_duotone:allergies'
    },
    {
        'name': 'fa_duotone:ambulance'
    },
    {
        'name': 'fa_duotone:american-sign-language-interpreting'
    },
    {
        'name': 'fa_duotone:analytics'
    },
    {
        'name': 'fa_duotone:anchor'
    },
    {
        'name': 'fa_duotone:angel'
    },
    {
        'name': 'fa_duotone:angle-double-down'
    },
    {
        'name': 'fa_duotone:angle-double-left'
    },
    {
        'name': 'fa_duotone:angle-double-right'
    },
    {
        'name': 'fa_duotone:angle-double-up'
    },
    {
        'name': 'fa_duotone:angle-down'
    },
    {
        'name': 'fa_duotone:angle-left'
    },
    {
        'name': 'fa_duotone:angle-right'
    },
    {
        'name': 'fa_duotone:angle-up'
    },
    {
        'name': 'fa_duotone:angry'
    },
    {
        'name': 'fa_duotone:ankh'
    },
    {
        'name': 'fa_duotone:apple-alt'
    },
    {
        'name': 'fa_duotone:apple-crate'
    },
    {
        'name': 'fa_duotone:archive'
    },
    {
        'name': 'fa_duotone:archway'
    },
    {
        'name': 'fa_duotone:arrow-alt-circle-down'
    },
    {
        'name': 'fa_duotone:arrow-alt-circle-left'
    },
    {
        'name': 'fa_duotone:arrow-alt-circle-right'
    },
    {
        'name': 'fa_duotone:arrow-alt-circle-up'
    },
    {
        'name': 'fa_duotone:arrow-alt-down'
    },
    {
        'name': 'fa_duotone:arrow-alt-from-bottom'
    },
    {
        'name': 'fa_duotone:arrow-alt-from-left'
    },
    {
        'name': 'fa_duotone:arrow-alt-from-right'
    },
    {
        'name': 'fa_duotone:arrow-alt-from-top'
    },
    {
        'name': 'fa_duotone:arrow-alt-left'
    },
    {
        'name': 'fa_duotone:arrow-alt-right'
    },
    {
        'name': 'fa_duotone:arrow-alt-square-down'
    },
    {
        'name': 'fa_duotone:arrow-alt-square-left'
    },
    {
        'name': 'fa_duotone:arrow-alt-square-right'
    },
    {
        'name': 'fa_duotone:arrow-alt-square-up'
    },
    {
        'name': 'fa_duotone:arrow-alt-to-bottom'
    },
    {
        'name': 'fa_duotone:arrow-alt-to-left'
    },
    {
        'name': 'fa_duotone:arrow-alt-to-right'
    },
    {
        'name': 'fa_duotone:arrow-alt-to-top'
    },
    {
        'name': 'fa_duotone:arrow-alt-up'
    },
    {
        'name': 'fa_duotone:arrow-circle-down'
    },
    {
        'name': 'fa_duotone:arrow-circle-left'
    },
    {
        'name': 'fa_duotone:arrow-circle-right'
    },
    {
        'name': 'fa_duotone:arrow-circle-up'
    },
    {
        'name': 'fa_duotone:arrow-down'
    },
    {
        'name': 'fa_duotone:arrow-from-bottom'
    },
    {
        'name': 'fa_duotone:arrow-from-left'
    },
    {
        'name': 'fa_duotone:arrow-from-right'
    },
    {
        'name': 'fa_duotone:arrow-from-top'
    },
    {
        'name': 'fa_duotone:arrow-left'
    },
    {
        'name': 'fa_duotone:arrow-right'
    },
    {
        'name': 'fa_duotone:arrow-square-down'
    },
    {
        'name': 'fa_duotone:arrow-square-left'
    },
    {
        'name': 'fa_duotone:arrow-square-right'
    },
    {
        'name': 'fa_duotone:arrow-square-up'
    },
    {
        'name': 'fa_duotone:arrow-to-bottom'
    },
    {
        'name': 'fa_duotone:arrow-to-left'
    },
    {
        'name': 'fa_duotone:arrow-to-right'
    },
    {
        'name': 'fa_duotone:arrow-to-top'
    },
    {
        'name': 'fa_duotone:arrow-up'
    },
    {
        'name': 'fa_duotone:arrows-alt-h'
    },
    {
        'name': 'fa_duotone:arrows-alt-v'
    },
    {
        'name': 'fa_duotone:arrows-alt'
    },
    {
        'name': 'fa_duotone:arrows-h'
    },
    {
        'name': 'fa_duotone:arrows-v'
    },
    {
        'name': 'fa_duotone:arrows'
    },
    {
        'name': 'fa_duotone:assistive-listening-systems'
    },
    {
        'name': 'fa_duotone:asterisk'
    },
    {
        'name': 'fa_duotone:at'
    },
    {
        'name': 'fa_duotone:atlas'
    },
    {
        'name': 'fa_duotone:atom-alt'
    },
    {
        'name': 'fa_duotone:atom'
    },
    {
        'name': 'fa_duotone:audio-description'
    },
    {
        'name': 'fa_duotone:award'
    },
    {
        'name': 'fa_duotone:axe-battle'
    },
    {
        'name': 'fa_duotone:axe'
    },
    {
        'name': 'fa_duotone:baby-carriage'
    },
    {
        'name': 'fa_duotone:baby'
    },
    {
        'name': 'fa_duotone:backpack'
    },
    {
        'name': 'fa_duotone:backspace'
    },
    {
        'name': 'fa_duotone:backward'
    },
    {
        'name': 'fa_duotone:bacon'
    },
    {
        'name': 'fa_duotone:badge-check'
    },
    {
        'name': 'fa_duotone:badge-dollar'
    },
    {
        'name': 'fa_duotone:badge-percent'
    },
    {
        'name': 'fa_duotone:badge'
    },
    {
        'name': 'fa_duotone:badger-honey'
    },
    {
        'name': 'fa_duotone:bags-shopping'
    },
    {
        'name': 'fa_duotone:balance-scale-left'
    },
    {
        'name': 'fa_duotone:balance-scale-right'
    },
    {
        'name': 'fa_duotone:balance-scale'
    },
    {
        'name': 'fa_duotone:ball-pile'
    },
    {
        'name': 'fa_duotone:ballot-check'
    },
    {
        'name': 'fa_duotone:ballot'
    },
    {
        'name': 'fa_duotone:ban'
    },
    {
        'name': 'fa_duotone:band-aid'
    },
    {
        'name': 'fa_duotone:barcode-alt'
    },
    {
        'name': 'fa_duotone:barcode-read'
    },
    {
        'name': 'fa_duotone:barcode-scan'
    },
    {
        'name': 'fa_duotone:barcode'
    },
    {
        'name': 'fa_duotone:bars'
    },
    {
        'name': 'fa_duotone:baseball-ball'
    },
    {
        'name': 'fa_duotone:baseball'
    },
    {
        'name': 'fa_duotone:basketball-ball'
    },
    {
        'name': 'fa_duotone:basketball-hoop'
    },
    {
        'name': 'fa_duotone:bat'
    },
    {
        'name': 'fa_duotone:bath'
    },
    {
        'name': 'fa_duotone:battery-bolt'
    },
    {
        'name': 'fa_duotone:battery-empty'
    },
    {
        'name': 'fa_duotone:battery-full'
    },
    {
        'name': 'fa_duotone:battery-half'
    },
    {
        'name': 'fa_duotone:battery-quarter'
    },
    {
        'name': 'fa_duotone:battery-slash'
    },
    {
        'name': 'fa_duotone:battery-three-quarters'
    },
    {
        'name': 'fa_duotone:bed'
    },
    {
        'name': 'fa_duotone:beer'
    },
    {
        'name': 'fa_duotone:bell-exclamation'
    },
    {
        'name': 'fa_duotone:bell-plus'
    },
    {
        'name': 'fa_duotone:bell-school-slash'
    },
    {
        'name': 'fa_duotone:bell-school'
    },
    {
        'name': 'fa_duotone:bell-slash'
    },
    {
        'name': 'fa_duotone:bell'
    },
    {
        'name': 'fa_duotone:bells'
    },
    {
        'name': 'fa_duotone:bezier-curve'
    },
    {
        'name': 'fa_duotone:bible'
    },
    {
        'name': 'fa_duotone:bicycle'
    },
    {
        'name': 'fa_duotone:biking-mountain'
    },
    {
        'name': 'fa_duotone:biking'
    },
    {
        'name': 'fa_duotone:binoculars'
    },
    {
        'name': 'fa_duotone:biohazard'
    },
    {
        'name': 'fa_duotone:birthday-cake'
    },
    {
        'name': 'fa_duotone:blanket'
    },
    {
        'name': 'fa_duotone:blender-phone'
    },
    {
        'name': 'fa_duotone:blender'
    },
    {
        'name': 'fa_duotone:blind'
    },
    {
        'name': 'fa_duotone:blog'
    },
    {
        'name': 'fa_duotone:bold'
    },
    {
        'name': 'fa_duotone:bolt'
    },
    {
        'name': 'fa_duotone:bomb'
    },
    {
        'name': 'fa_duotone:bone-break'
    },
    {
        'name': 'fa_duotone:bone'
    },
    {
        'name': 'fa_duotone:bong'
    },
    {
        'name': 'fa_duotone:book-alt'
    },
    {
        'name': 'fa_duotone:book-dead'
    },
    {
        'name': 'fa_duotone:book-heart'
    },
    {
        'name': 'fa_duotone:book-medical'
    },
    {
        'name': 'fa_duotone:book-open'
    },
    {
        'name': 'fa_duotone:book-reader'
    },
    {
        'name': 'fa_duotone:book-spells'
    },
    {
        'name': 'fa_duotone:book-user'
    },
    {
        'name': 'fa_duotone:book'
    },
    {
        'name': 'fa_duotone:bookmark'
    },
    {
        'name': 'fa_duotone:books-medical'
    },
    {
        'name': 'fa_duotone:books'
    },
    {
        'name': 'fa_duotone:boot'
    },
    {
        'name': 'fa_duotone:booth-curtain'
    },
    {
        'name': 'fa_duotone:border-all'
    },
    {
        'name': 'fa_duotone:border-bottom'
    },
    {
        'name': 'fa_duotone:border-center-h'
    },
    {
        'name': 'fa_duotone:border-center-v'
    },
    {
        'name': 'fa_duotone:border-inner'
    },
    {
        'name': 'fa_duotone:border-left'
    },
    {
        'name': 'fa_duotone:border-none'
    },
    {
        'name': 'fa_duotone:border-outer'
    },
    {
        'name': 'fa_duotone:border-right'
    },
    {
        'name': 'fa_duotone:border-style-alt'
    },
    {
        'name': 'fa_duotone:border-style'
    },
    {
        'name': 'fa_duotone:border-top'
    },
    {
        'name': 'fa_duotone:bow-arrow'
    },
    {
        'name': 'fa_duotone:bowling-ball'
    },
    {
        'name': 'fa_duotone:bowling-pins'
    },
    {
        'name': 'fa_duotone:box-alt'
    },
    {
        'name': 'fa_duotone:box-ballot'
    },
    {
        'name': 'fa_duotone:box-check'
    },
    {
        'name': 'fa_duotone:box-fragile'
    },
    {
        'name': 'fa_duotone:box-full'
    },
    {
        'name': 'fa_duotone:box-heart'
    },
    {
        'name': 'fa_duotone:box-open'
    },
    {
        'name': 'fa_duotone:box-up'
    },
    {
        'name': 'fa_duotone:box-usd'
    },
    {
        'name': 'fa_duotone:box'
    },
    {
        'name': 'fa_duotone:boxes-alt'
    },
    {
        'name': 'fa_duotone:boxes'
    },
    {
        'name': 'fa_duotone:boxing-glove'
    },
    {
        'name': 'fa_duotone:brackets-curly'
    },
    {
        'name': 'fa_duotone:brackets'
    },
    {
        'name': 'fa_duotone:braille'
    },
    {
        'name': 'fa_duotone:brain'
    },
    {
        'name': 'fa_duotone:bread-loaf'
    },
    {
        'name': 'fa_duotone:bread-slice'
    },
    {
        'name': 'fa_duotone:briefcase-medical'
    },
    {
        'name': 'fa_duotone:briefcase'
    },
    {
        'name': 'fa_duotone:bring-forward'
    },
    {
        'name': 'fa_duotone:bring-front'
    },
    {
        'name': 'fa_duotone:broadcast-tower'
    },
    {
        'name': 'fa_duotone:broom'
    },
    {
        'name': 'fa_duotone:browser'
    },
    {
        'name': 'fa_duotone:brush'
    },
    {
        'name': 'fa_duotone:bug'
    },
    {
        'name': 'fa_duotone:building'
    },
    {
        'name': 'fa_duotone:bullhorn'
    },
    {
        'name': 'fa_duotone:bullseye-arrow'
    },
    {
        'name': 'fa_duotone:bullseye-pointer'
    },
    {
        'name': 'fa_duotone:bullseye'
    },
    {
        'name': 'fa_duotone:burger-soda'
    },
    {
        'name': 'fa_duotone:burn'
    },
    {
        'name': 'fa_duotone:burrito'
    },
    {
        'name': 'fa_duotone:bus-alt'
    },
    {
        'name': 'fa_duotone:bus-school'
    },
    {
        'name': 'fa_duotone:bus'
    },
    {
        'name': 'fa_duotone:business-time'
    },
    {
        'name': 'fa_duotone:cabinet-filing'
    },
    {
        'name': 'fa_duotone:calculator-alt'
    },
    {
        'name': 'fa_duotone:calculator'
    },
    {
        'name': 'fa_duotone:calendar-alt'
    },
    {
        'name': 'fa_duotone:calendar-check'
    },
    {
        'name': 'fa_duotone:calendar-day'
    },
    {
        'name': 'fa_duotone:calendar-edit'
    },
    {
        'name': 'fa_duotone:calendar-exclamation'
    },
    {
        'name': 'fa_duotone:calendar-minus'
    },
    {
        'name': 'fa_duotone:calendar-plus'
    },
    {
        'name': 'fa_duotone:calendar-star'
    },
    {
        'name': 'fa_duotone:calendar-times'
    },
    {
        'name': 'fa_duotone:calendar-week'
    },
    {
        'name': 'fa_duotone:calendar'
    },
    {
        'name': 'fa_duotone:camera-alt'
    },
    {
        'name': 'fa_duotone:camera-retro'
    },
    {
        'name': 'fa_duotone:camera'
    },
    {
        'name': 'fa_duotone:campfire'
    },
    {
        'name': 'fa_duotone:campground'
    },
    {
        'name': 'fa_duotone:candle-holder'
    },
    {
        'name': 'fa_duotone:candy-cane'
    },
    {
        'name': 'fa_duotone:candy-corn'
    },
    {
        'name': 'fa_duotone:cannabis'
    },
    {
        'name': 'fa_duotone:capsules'
    },
    {
        'name': 'fa_duotone:car-alt'
    },
    {
        'name': 'fa_duotone:car-battery'
    },
    {
        'name': 'fa_duotone:car-building'
    },
    {
        'name': 'fa_duotone:car-bump'
    },
    {
        'name': 'fa_duotone:car-bus'
    },
    {
        'name': 'fa_duotone:car-crash'
    },
    {
        'name': 'fa_duotone:car-garage'
    },
    {
        'name': 'fa_duotone:car-mechanic'
    },
    {
        'name': 'fa_duotone:car-side'
    },
    {
        'name': 'fa_duotone:car-tilt'
    },
    {
        'name': 'fa_duotone:car-wash'
    },
    {
        'name': 'fa_duotone:car'
    },
    {
        'name': 'fa_duotone:caret-circle-down'
    },
    {
        'name': 'fa_duotone:caret-circle-left'
    },
    {
        'name': 'fa_duotone:caret-circle-right'
    },
    {
        'name': 'fa_duotone:caret-circle-up'
    },
    {
        'name': 'fa_duotone:caret-down'
    },
    {
        'name': 'fa_duotone:caret-left'
    },
    {
        'name': 'fa_duotone:caret-right'
    },
    {
        'name': 'fa_duotone:caret-square-down'
    },
    {
        'name': 'fa_duotone:caret-square-left'
    },
    {
        'name': 'fa_duotone:caret-square-right'
    },
    {
        'name': 'fa_duotone:caret-square-up'
    },
    {
        'name': 'fa_duotone:caret-up'
    },
    {
        'name': 'fa_duotone:carrot'
    },
    {
        'name': 'fa_duotone:cars'
    },
    {
        'name': 'fa_duotone:cart-arrow-down'
    },
    {
        'name': 'fa_duotone:cart-plus'
    },
    {
        'name': 'fa_duotone:cash-register'
    },
    {
        'name': 'fa_duotone:cat'
    },
    {
        'name': 'fa_duotone:cauldron'
    },
    {
        'name': 'fa_duotone:certificate'
    },
    {
        'name': 'fa_duotone:chair-office'
    },
    {
        'name': 'fa_duotone:chair'
    },
    {
        'name': 'fa_duotone:chalkboard-teacher'
    },
    {
        'name': 'fa_duotone:chalkboard'
    },
    {
        'name': 'fa_duotone:charging-station'
    },
    {
        'name': 'fa_duotone:chart-area'
    },
    {
        'name': 'fa_duotone:chart-bar'
    },
    {
        'name': 'fa_duotone:chart-line-down'
    },
    {
        'name': 'fa_duotone:chart-line'
    },
    {
        'name': 'fa_duotone:chart-network'
    },
    {
        'name': 'fa_duotone:chart-pie-alt'
    },
    {
        'name': 'fa_duotone:chart-pie'
    },
    {
        'name': 'fa_duotone:chart-scatter'
    },
    {
        'name': 'fa_duotone:check-circle'
    },
    {
        'name': 'fa_duotone:check-double'
    },
    {
        'name': 'fa_duotone:check-square'
    },
    {
        'name': 'fa_duotone:check'
    },
    {
        'name': 'fa_duotone:cheese-swiss'
    },
    {
        'name': 'fa_duotone:cheese'
    },
    {
        'name': 'fa_duotone:cheeseburger'
    },
    {
        'name': 'fa_duotone:chess-bishop-alt'
    },
    {
        'name': 'fa_duotone:chess-bishop'
    },
    {
        'name': 'fa_duotone:chess-board'
    },
    {
        'name': 'fa_duotone:chess-clock-alt'
    },
    {
        'name': 'fa_duotone:chess-clock'
    },
    {
        'name': 'fa_duotone:chess-king-alt'
    },
    {
        'name': 'fa_duotone:chess-king'
    },
    {
        'name': 'fa_duotone:chess-knight-alt'
    },
    {
        'name': 'fa_duotone:chess-knight'
    },
    {
        'name': 'fa_duotone:chess-pawn-alt'
    },
    {
        'name': 'fa_duotone:chess-pawn'
    },
    {
        'name': 'fa_duotone:chess-queen-alt'
    },
    {
        'name': 'fa_duotone:chess-queen'
    },
    {
        'name': 'fa_duotone:chess-rook-alt'
    },
    {
        'name': 'fa_duotone:chess-rook'
    },
    {
        'name': 'fa_duotone:chess'
    },
    {
        'name': 'fa_duotone:chevron-circle-down'
    },
    {
        'name': 'fa_duotone:chevron-circle-left'
    },
    {
        'name': 'fa_duotone:chevron-circle-right'
    },
    {
        'name': 'fa_duotone:chevron-circle-up'
    },
    {
        'name': 'fa_duotone:chevron-double-down'
    },
    {
        'name': 'fa_duotone:chevron-double-left'
    },
    {
        'name': 'fa_duotone:chevron-double-right'
    },
    {
        'name': 'fa_duotone:chevron-double-up'
    },
    {
        'name': 'fa_duotone:chevron-down'
    },
    {
        'name': 'fa_duotone:chevron-left'
    },
    {
        'name': 'fa_duotone:chevron-right'
    },
    {
        'name': 'fa_duotone:chevron-square-down'
    },
    {
        'name': 'fa_duotone:chevron-square-left'
    },
    {
        'name': 'fa_duotone:chevron-square-right'
    },
    {
        'name': 'fa_duotone:chevron-square-up'
    },
    {
        'name': 'fa_duotone:chevron-up'
    },
    {
        'name': 'fa_duotone:child'
    },
    {
        'name': 'fa_duotone:chimney'
    },
    {
        'name': 'fa_duotone:church'
    },
    {
        'name': 'fa_duotone:circle-notch'
    },
    {
        'name': 'fa_duotone:circle'
    },
    {
        'name': 'fa_duotone:city'
    },
    {
        'name': 'fa_duotone:claw-marks'
    },
    {
        'name': 'fa_duotone:clinic-medical'
    },
    {
        'name': 'fa_duotone:clipboard-check'
    },
    {
        'name': 'fa_duotone:clipboard-list-check'
    },
    {
        'name': 'fa_duotone:clipboard-list'
    },
    {
        'name': 'fa_duotone:clipboard-prescription'
    },
    {
        'name': 'fa_duotone:clipboard-user'
    },
    {
        'name': 'fa_duotone:clipboard'
    },
    {
        'name': 'fa_duotone:clock'
    },
    {
        'name': 'fa_duotone:clone'
    },
    {
        'name': 'fa_duotone:closed-captioning'
    },
    {
        'name': 'fa_duotone:cloud-download-alt'
    },
    {
        'name': 'fa_duotone:cloud-download'
    },
    {
        'name': 'fa_duotone:cloud-drizzle'
    },
    {
        'name': 'fa_duotone:cloud-hail-mixed'
    },
    {
        'name': 'fa_duotone:cloud-hail'
    },
    {
        'name': 'fa_duotone:cloud-meatball'
    },
    {
        'name': 'fa_duotone:cloud-moon-rain'
    },
    {
        'name': 'fa_duotone:cloud-moon'
    },
    {
        'name': 'fa_duotone:cloud-rain'
    },
    {
        'name': 'fa_duotone:cloud-rainbow'
    },
    {
        'name': 'fa_duotone:cloud-showers-heavy'
    },
    {
        'name': 'fa_duotone:cloud-showers'
    },
    {
        'name': 'fa_duotone:cloud-sleet'
    },
    {
        'name': 'fa_duotone:cloud-snow'
    },
    {
        'name': 'fa_duotone:cloud-sun-rain'
    },
    {
        'name': 'fa_duotone:cloud-sun'
    },
    {
        'name': 'fa_duotone:cloud-upload-alt'
    },
    {
        'name': 'fa_duotone:cloud-upload'
    },
    {
        'name': 'fa_duotone:cloud'
    },
    {
        'name': 'fa_duotone:clouds-moon'
    },
    {
        'name': 'fa_duotone:clouds-sun'
    },
    {
        'name': 'fa_duotone:clouds'
    },
    {
        'name': 'fa_duotone:club'
    },
    {
        'name': 'fa_duotone:cocktail'
    },
    {
        'name': 'fa_duotone:code-branch'
    },
    {
        'name': 'fa_duotone:code-commit'
    },
    {
        'name': 'fa_duotone:code-merge'
    },
    {
        'name': 'fa_duotone:code'
    },
    {
        'name': 'fa_duotone:coffee-togo'
    },
    {
        'name': 'fa_duotone:coffee'
    },
    {
        'name': 'fa_duotone:coffin'
    },
    {
        'name': 'fa_duotone:cog'
    },
    {
        'name': 'fa_duotone:cogs'
    },
    {
        'name': 'fa_duotone:coin'
    },
    {
        'name': 'fa_duotone:coins'
    },
    {
        'name': 'fa_duotone:columns'
    },
    {
        'name': 'fa_duotone:comment-alt-check'
    },
    {
        'name': 'fa_duotone:comment-alt-dollar'
    },
    {
        'name': 'fa_duotone:comment-alt-dots'
    },
    {
        'name': 'fa_duotone:comment-alt-edit'
    },
    {
        'name': 'fa_duotone:comment-alt-exclamation'
    },
    {
        'name': 'fa_duotone:comment-alt-lines'
    },
    {
        'name': 'fa_duotone:comment-alt-medical'
    },
    {
        'name': 'fa_duotone:comment-alt-minus'
    },
    {
        'name': 'fa_duotone:comment-alt-plus'
    },
    {
        'name': 'fa_duotone:comment-alt-slash'
    },
    {
        'name': 'fa_duotone:comment-alt-smile'
    },
    {
        'name': 'fa_duotone:comment-alt-times'
    },
    {
        'name': 'fa_duotone:comment-alt'
    },
    {
        'name': 'fa_duotone:comment-check'
    },
    {
        'name': 'fa_duotone:comment-dollar'
    },
    {
        'name': 'fa_duotone:comment-dots'
    },
    {
        'name': 'fa_duotone:comment-edit'
    },
    {
        'name': 'fa_duotone:comment-exclamation'
    },
    {
        'name': 'fa_duotone:comment-lines'
    },
    {
        'name': 'fa_duotone:comment-medical'
    },
    {
        'name': 'fa_duotone:comment-minus'
    },
    {
        'name': 'fa_duotone:comment-plus'
    },
    {
        'name': 'fa_duotone:comment-slash'
    },
    {
        'name': 'fa_duotone:comment-smile'
    },
    {
        'name': 'fa_duotone:comment-times'
    },
    {
        'name': 'fa_duotone:comment'
    },
    {
        'name': 'fa_duotone:comments-alt-dollar'
    },
    {
        'name': 'fa_duotone:comments-alt'
    },
    {
        'name': 'fa_duotone:comments-dollar'
    },
    {
        'name': 'fa_duotone:comments'
    },
    {
        'name': 'fa_duotone:compact-disc'
    },
    {
        'name': 'fa_duotone:compass-slash'
    },
    {
        'name': 'fa_duotone:compass'
    },
    {
        'name': 'fa_duotone:compress-alt'
    },
    {
        'name': 'fa_duotone:compress-arrows-alt'
    },
    {
        'name': 'fa_duotone:compress-wide'
    },
    {
        'name': 'fa_duotone:compress'
    },
    {
        'name': 'fa_duotone:concierge-bell'
    },
    {
        'name': 'fa_duotone:construction'
    },
    {
        'name': 'fa_duotone:container-storage'
    },
    {
        'name': 'fa_duotone:conveyor-belt-alt'
    },
    {
        'name': 'fa_duotone:conveyor-belt'
    },
    {
        'name': 'fa_duotone:cookie-bite'
    },
    {
        'name': 'fa_duotone:cookie'
    },
    {
        'name': 'fa_duotone:copy'
    },
    {
        'name': 'fa_duotone:copyright'
    },
    {
        'name': 'fa_duotone:corn'
    },
    {
        'name': 'fa_duotone:couch'
    },
    {
        'name': 'fa_duotone:cow'
    },
    {
        'name': 'fa_duotone:credit-card-blank'
    },
    {
        'name': 'fa_duotone:credit-card-front'
    },
    {
        'name': 'fa_duotone:credit-card'
    },
    {
        'name': 'fa_duotone:cricket'
    },
    {
        'name': 'fa_duotone:croissant'
    },
    {
        'name': 'fa_duotone:crop-alt'
    },
    {
        'name': 'fa_duotone:crop'
    },
    {
        'name': 'fa_duotone:cross'
    },
    {
        'name': 'fa_duotone:crosshairs'
    },
    {
        'name': 'fa_duotone:crow'
    },
    {
        'name': 'fa_duotone:crown'
    },
    {
        'name': 'fa_duotone:crutch'
    },
    {
        'name': 'fa_duotone:crutches'
    },
    {
        'name': 'fa_duotone:cube'
    },
    {
        'name': 'fa_duotone:cubes'
    },
    {
        'name': 'fa_duotone:curling'
    },
    {
        'name': 'fa_duotone:cut'
    },
    {
        'name': 'fa_duotone:dagger'
    },
    {
        'name': 'fa_duotone:database'
    },
    {
        'name': 'fa_duotone:deaf'
    },
    {
        'name': 'fa_duotone:debug'
    },
    {
        'name': 'fa_duotone:deer-rudolph'
    },
    {
        'name': 'fa_duotone:deer'
    },
    {
        'name': 'fa_duotone:democrat'
    },
    {
        'name': 'fa_duotone:desktop-alt'
    },
    {
        'name': 'fa_duotone:desktop'
    },
    {
        'name': 'fa_duotone:dewpoint'
    },
    {
        'name': 'fa_duotone:dharmachakra'
    },
    {
        'name': 'fa_duotone:diagnoses'
    },
    {
        'name': 'fa_duotone:diamond'
    },
    {
        'name': 'fa_duotone:dice-d10'
    },
    {
        'name': 'fa_duotone:dice-d12'
    },
    {
        'name': 'fa_duotone:dice-d20'
    },
    {
        'name': 'fa_duotone:dice-d4'
    },
    {
        'name': 'fa_duotone:dice-d6'
    },
    {
        'name': 'fa_duotone:dice-d8'
    },
    {
        'name': 'fa_duotone:dice-five'
    },
    {
        'name': 'fa_duotone:dice-four'
    },
    {
        'name': 'fa_duotone:dice-one'
    },
    {
        'name': 'fa_duotone:dice-six'
    },
    {
        'name': 'fa_duotone:dice-three'
    },
    {
        'name': 'fa_duotone:dice-two'
    },
    {
        'name': 'fa_duotone:dice'
    },
    {
        'name': 'fa_duotone:digging'
    },
    {
        'name': 'fa_duotone:digital-tachograph'
    },
    {
        'name': 'fa_duotone:diploma'
    },
    {
        'name': 'fa_duotone:directions'
    },
    {
        'name': 'fa_duotone:disease'
    },
    {
        'name': 'fa_duotone:divide'
    },
    {
        'name': 'fa_duotone:dizzy'
    },
    {
        'name': 'fa_duotone:dna'
    },
    {
        'name': 'fa_duotone:do-not-enter'
    },
    {
        'name': 'fa_duotone:dog-leashed'
    },
    {
        'name': 'fa_duotone:dog'
    },
    {
        'name': 'fa_duotone:dollar-sign'
    },
    {
        'name': 'fa_duotone:dolly-empty'
    },
    {
        'name': 'fa_duotone:dolly-flatbed-alt'
    },
    {
        'name': 'fa_duotone:dolly-flatbed-empty'
    },
    {
        'name': 'fa_duotone:dolly-flatbed'
    },
    {
        'name': 'fa_duotone:dolly'
    },
    {
        'name': 'fa_duotone:donate'
    },
    {
        'name': 'fa_duotone:door-closed'
    },
    {
        'name': 'fa_duotone:door-open'
    },
    {
        'name': 'fa_duotone:dot-circle'
    },
    {
        'name': 'fa_duotone:dove'
    },
    {
        'name': 'fa_duotone:download'
    },
    {
        'name': 'fa_duotone:drafting-compass'
    },
    {
        'name': 'fa_duotone:dragon'
    },
    {
        'name': 'fa_duotone:draw-circle'
    },
    {
        'name': 'fa_duotone:draw-polygon'
    },
    {
        'name': 'fa_duotone:draw-square'
    },
    {
        'name': 'fa_duotone:dreidel'
    },
    {
        'name': 'fa_duotone:drone-alt'
    },
    {
        'name': 'fa_duotone:drone'
    },
    {
        'name': 'fa_duotone:drum-steelpan'
    },
    {
        'name': 'fa_duotone:drum'
    },
    {
        'name': 'fa_duotone:drumstick-bite'
    },
    {
        'name': 'fa_duotone:drumstick'
    },
    {
        'name': 'fa_duotone:dryer-alt'
    },
    {
        'name': 'fa_duotone:dryer'
    },
    {
        'name': 'fa_duotone:duck'
    },
    {
        'name': 'fa_duotone:dumbbell'
    },
    {
        'name': 'fa_duotone:dumpster-fire'
    },
    {
        'name': 'fa_duotone:dumpster'
    },
    {
        'name': 'fa_duotone:dungeon'
    },
    {
        'name': 'fa_duotone:ear-muffs'
    },
    {
        'name': 'fa_duotone:ear'
    },
    {
        'name': 'fa_duotone:eclipse-alt'
    },
    {
        'name': 'fa_duotone:eclipse'
    },
    {
        'name': 'fa_duotone:edit'
    },
    {
        'name': 'fa_duotone:egg-fried'
    },
    {
        'name': 'fa_duotone:egg'
    },
    {
        'name': 'fa_duotone:eject'
    },
    {
        'name': 'fa_duotone:elephant'
    },
    {
        'name': 'fa_duotone:ellipsis-h-alt'
    },
    {
        'name': 'fa_duotone:ellipsis-h'
    },
    {
        'name': 'fa_duotone:ellipsis-v-alt'
    },
    {
        'name': 'fa_duotone:ellipsis-v'
    },
    {
        'name': 'fa_duotone:empty-set'
    },
    {
        'name': 'fa_duotone:engine-warning'
    },
    {
        'name': 'fa_duotone:envelope-open-dollar'
    },
    {
        'name': 'fa_duotone:envelope-open-text'
    },
    {
        'name': 'fa_duotone:envelope-open'
    },
    {
        'name': 'fa_duotone:envelope-square'
    },
    {
        'name': 'fa_duotone:envelope'
    },
    {
        'name': 'fa_duotone:equals'
    },
    {
        'name': 'fa_duotone:eraser'
    },
    {
        'name': 'fa_duotone:ethernet'
    },
    {
        'name': 'fa_duotone:euro-sign'
    },
    {
        'name': 'fa_duotone:exchange-alt'
    },
    {
        'name': 'fa_duotone:exchange'
    },
    {
        'name': 'fa_duotone:exclamation-circle'
    },
    {
        'name': 'fa_duotone:exclamation-square'
    },
    {
        'name': 'fa_duotone:exclamation-triangle'
    },
    {
        'name': 'fa_duotone:exclamation'
    },
    {
        'name': 'fa_duotone:expand-alt'
    },
    {
        'name': 'fa_duotone:expand-arrows-alt'
    },
    {
        'name': 'fa_duotone:expand-arrows'
    },
    {
        'name': 'fa_duotone:expand-wide'
    },
    {
        'name': 'fa_duotone:expand'
    },
    {
        'name': 'fa_duotone:external-link-alt'
    },
    {
        'name': 'fa_duotone:external-link-square-alt'
    },
    {
        'name': 'fa_duotone:external-link-square'
    },
    {
        'name': 'fa_duotone:external-link'
    },
    {
        'name': 'fa_duotone:eye-dropper'
    },
    {
        'name': 'fa_duotone:eye-evil'
    },
    {
        'name': 'fa_duotone:eye-slash'
    },
    {
        'name': 'fa_duotone:eye'
    },
    {
        'name': 'fa_duotone:fan'
    },
    {
        'name': 'fa_duotone:farm'
    },
    {
        'name': 'fa_duotone:fast-backward'
    },
    {
        'name': 'fa_duotone:fast-forward'
    },
    {
        'name': 'fa_duotone:fax'
    },
    {
        'name': 'fa_duotone:feather-alt'
    },
    {
        'name': 'fa_duotone:feather'
    },
    {
        'name': 'fa_duotone:female'
    },
    {
        'name': 'fa_duotone:field-hockey'
    },
    {
        'name': 'fa_duotone:fighter-jet'
    },
    {
        'name': 'fa_duotone:file-alt'
    },
    {
        'name': 'fa_duotone:file-archive'
    },
    {
        'name': 'fa_duotone:file-audio'
    },
    {
        'name': 'fa_duotone:file-certificate'
    },
    {
        'name': 'fa_duotone:file-chart-line'
    },
    {
        'name': 'fa_duotone:file-chart-pie'
    },
    {
        'name': 'fa_duotone:file-check'
    },
    {
        'name': 'fa_duotone:file-code'
    },
    {
        'name': 'fa_duotone:file-contract'
    },
    {
        'name': 'fa_duotone:file-csv'
    },
    {
        'name': 'fa_duotone:file-download'
    },
    {
        'name': 'fa_duotone:file-edit'
    },
    {
        'name': 'fa_duotone:file-excel'
    },
    {
        'name': 'fa_duotone:file-exclamation'
    },
    {
        'name': 'fa_duotone:file-export'
    },
    {
        'name': 'fa_duotone:file-image'
    },
    {
        'name': 'fa_duotone:file-import'
    },
    {
        'name': 'fa_duotone:file-invoice-dollar'
    },
    {
        'name': 'fa_duotone:file-invoice'
    },
    {
        'name': 'fa_duotone:file-medical-alt'
    },
    {
        'name': 'fa_duotone:file-medical'
    },
    {
        'name': 'fa_duotone:file-minus'
    },
    {
        'name': 'fa_duotone:file-pdf'
    },
    {
        'name': 'fa_duotone:file-plus'
    },
    {
        'name': 'fa_duotone:file-powerpoint'
    },
    {
        'name': 'fa_duotone:file-prescription'
    },
    {
        'name': 'fa_duotone:file-search'
    },
    {
        'name': 'fa_duotone:file-signature'
    },
    {
        'name': 'fa_duotone:file-spreadsheet'
    },
    {
        'name': 'fa_duotone:file-times'
    },
    {
        'name': 'fa_duotone:file-upload'
    },
    {
        'name': 'fa_duotone:file-user'
    },
    {
        'name': 'fa_duotone:file-video'
    },
    {
        'name': 'fa_duotone:file-word'
    },
    {
        'name': 'fa_duotone:file'
    },
    {
        'name': 'fa_duotone:files-medical'
    },
    {
        'name': 'fa_duotone:fill-drip'
    },
    {
        'name': 'fa_duotone:fill'
    },
    {
        'name': 'fa_duotone:film-alt'
    },
    {
        'name': 'fa_duotone:film'
    },
    {
        'name': 'fa_duotone:filter'
    },
    {
        'name': 'fa_duotone:fingerprint'
    },
    {
        'name': 'fa_duotone:fire-alt'
    },
    {
        'name': 'fa_duotone:fire-extinguisher'
    },
    {
        'name': 'fa_duotone:fire-smoke'
    },
    {
        'name': 'fa_duotone:fire'
    },
    {
        'name': 'fa_duotone:fireplace'
    },
    {
        'name': 'fa_duotone:first-aid'
    },
    {
        'name': 'fa_duotone:fish-cooked'
    },
    {
        'name': 'fa_duotone:fish'
    },
    {
        'name': 'fa_duotone:fist-raised'
    },
    {
        'name': 'fa_duotone:flag-alt'
    },
    {
        'name': 'fa_duotone:flag-checkered'
    },
    {
        'name': 'fa_duotone:flag-usa'
    },
    {
        'name': 'fa_duotone:flag'
    },
    {
        'name': 'fa_duotone:flame'
    },
    {
        'name': 'fa_duotone:flask-poison'
    },
    {
        'name': 'fa_duotone:flask-potion'
    },
    {
        'name': 'fa_duotone:flask'
    },
    {
        'name': 'fa_duotone:flower-daffodil'
    },
    {
        'name': 'fa_duotone:flower-tulip'
    },
    {
        'name': 'fa_duotone:flower'
    },
    {
        'name': 'fa_duotone:flushed'
    },
    {
        'name': 'fa_duotone:fog'
    },
    {
        'name': 'fa_duotone:folder-minus'
    },
    {
        'name': 'fa_duotone:folder-open'
    },
    {
        'name': 'fa_duotone:folder-plus'
    },
    {
        'name': 'fa_duotone:folder-times'
    },
    {
        'name': 'fa_duotone:folder-tree'
    },
    {
        'name': 'fa_duotone:folder'
    },
    {
        'name': 'fa_duotone:folders'
    },
    {
        'name': 'fa_duotone:font-awesome-logo-full'
    },
    {
        'name': 'fa_duotone:font-case'
    },
    {
        'name': 'fa_duotone:font'
    },
    {
        'name': 'fa_duotone:football-ball'
    },
    {
        'name': 'fa_duotone:football-helmet'
    },
    {
        'name': 'fa_duotone:forklift'
    },
    {
        'name': 'fa_duotone:forward'
    },
    {
        'name': 'fa_duotone:fragile'
    },
    {
        'name': 'fa_duotone:french-fries'
    },
    {
        'name': 'fa_duotone:frog'
    },
    {
        'name': 'fa_duotone:frosty-head'
    },
    {
        'name': 'fa_duotone:frown-open'
    },
    {
        'name': 'fa_duotone:frown'
    },
    {
        'name': 'fa_duotone:function'
    },
    {
        'name': 'fa_duotone:funnel-dollar'
    },
    {
        'name': 'fa_duotone:futbol'
    },
    {
        'name': 'fa_duotone:game-board-alt'
    },
    {
        'name': 'fa_duotone:game-board'
    },
    {
        'name': 'fa_duotone:gamepad'
    },
    {
        'name': 'fa_duotone:gas-pump-slash'
    },
    {
        'name': 'fa_duotone:gas-pump'
    },
    {
        'name': 'fa_duotone:gavel'
    },
    {
        'name': 'fa_duotone:gem'
    },
    {
        'name': 'fa_duotone:genderless'
    },
    {
        'name': 'fa_duotone:ghost'
    },
    {
        'name': 'fa_duotone:gift-card'
    },
    {
        'name': 'fa_duotone:gift'
    },
    {
        'name': 'fa_duotone:gifts'
    },
    {
        'name': 'fa_duotone:gingerbread-man'
    },
    {
        'name': 'fa_duotone:glass-champagne'
    },
    {
        'name': 'fa_duotone:glass-cheers'
    },
    {
        'name': 'fa_duotone:glass-citrus'
    },
    {
        'name': 'fa_duotone:glass-martini-alt'
    },
    {
        'name': 'fa_duotone:glass-martini'
    },
    {
        'name': 'fa_duotone:glass-whiskey-rocks'
    },
    {
        'name': 'fa_duotone:glass-whiskey'
    },
    {
        'name': 'fa_duotone:glass'
    },
    {
        'name': 'fa_duotone:glasses-alt'
    },
    {
        'name': 'fa_duotone:glasses'
    },
    {
        'name': 'fa_duotone:globe-africa'
    },
    {
        'name': 'fa_duotone:globe-americas'
    },
    {
        'name': 'fa_duotone:globe-asia'
    },
    {
        'name': 'fa_duotone:globe-europe'
    },
    {
        'name': 'fa_duotone:globe-snow'
    },
    {
        'name': 'fa_duotone:globe-stand'
    },
    {
        'name': 'fa_duotone:globe'
    },
    {
        'name': 'fa_duotone:golf-ball'
    },
    {
        'name': 'fa_duotone:golf-club'
    },
    {
        'name': 'fa_duotone:gopuram'
    },
    {
        'name': 'fa_duotone:graduation-cap'
    },
    {
        'name': 'fa_duotone:greater-than-equal'
    },
    {
        'name': 'fa_duotone:greater-than'
    },
    {
        'name': 'fa_duotone:grimace'
    },
    {
        'name': 'fa_duotone:grin-alt'
    },
    {
        'name': 'fa_duotone:grin-beam-sweat'
    },
    {
        'name': 'fa_duotone:grin-beam'
    },
    {
        'name': 'fa_duotone:grin-hearts'
    },
    {
        'name': 'fa_duotone:grin-squint-tears'
    },
    {
        'name': 'fa_duotone:grin-squint'
    },
    {
        'name': 'fa_duotone:grin-stars'
    },
    {
        'name': 'fa_duotone:grin-tears'
    },
    {
        'name': 'fa_duotone:grin-tongue-squint'
    },
    {
        'name': 'fa_duotone:grin-tongue-wink'
    },
    {
        'name': 'fa_duotone:grin-tongue'
    },
    {
        'name': 'fa_duotone:grin-wink'
    },
    {
        'name': 'fa_duotone:grin'
    },
    {
        'name': 'fa_duotone:grip-horizontal'
    },
    {
        'name': 'fa_duotone:grip-lines-vertical'
    },
    {
        'name': 'fa_duotone:grip-lines'
    },
    {
        'name': 'fa_duotone:grip-vertical'
    },
    {
        'name': 'fa_duotone:guitar'
    },
    {
        'name': 'fa_duotone:h-square'
    },
    {
        'name': 'fa_duotone:h1'
    },
    {
        'name': 'fa_duotone:h2'
    },
    {
        'name': 'fa_duotone:h3'
    },
    {
        'name': 'fa_duotone:h4'
    },
    {
        'name': 'fa_duotone:hamburger'
    },
    {
        'name': 'fa_duotone:hammer-war'
    },
    {
        'name': 'fa_duotone:hammer'
    },
    {
        'name': 'fa_duotone:hamsa'
    },
    {
        'name': 'fa_duotone:hand-heart'
    },
    {
        'name': 'fa_duotone:hand-holding-box'
    },
    {
        'name': 'fa_duotone:hand-holding-heart'
    },
    {
        'name': 'fa_duotone:hand-holding-magic'
    },
    {
        'name': 'fa_duotone:hand-holding-seedling'
    },
    {
        'name': 'fa_duotone:hand-holding-usd'
    },
    {
        'name': 'fa_duotone:hand-holding-water'
    },
    {
        'name': 'fa_duotone:hand-holding'
    },
    {
        'name': 'fa_duotone:hand-lizard'
    },
    {
        'name': 'fa_duotone:hand-middle-finger'
    },
    {
        'name': 'fa_duotone:hand-paper'
    },
    {
        'name': 'fa_duotone:hand-peace'
    },
    {
        'name': 'fa_duotone:hand-point-down'
    },
    {
        'name': 'fa_duotone:hand-point-left'
    },
    {
        'name': 'fa_duotone:hand-point-right'
    },
    {
        'name': 'fa_duotone:hand-point-up'
    },
    {
        'name': 'fa_duotone:hand-pointer'
    },
    {
        'name': 'fa_duotone:hand-receiving'
    },
    {
        'name': 'fa_duotone:hand-rock'
    },
    {
        'name': 'fa_duotone:hand-scissors'
    },
    {
        'name': 'fa_duotone:hand-spock'
    },
    {
        'name': 'fa_duotone:hands-heart'
    },
    {
        'name': 'fa_duotone:hands-helping'
    },
    {
        'name': 'fa_duotone:hands-usd'
    },
    {
        'name': 'fa_duotone:hands'
    },
    {
        'name': 'fa_duotone:handshake-alt'
    },
    {
        'name': 'fa_duotone:handshake'
    },
    {
        'name': 'fa_duotone:hanukiah'
    },
    {
        'name': 'fa_duotone:hard-hat'
    },
    {
        'name': 'fa_duotone:hashtag'
    },
    {
        'name': 'fa_duotone:hat-chef'
    },
    {
        'name': 'fa_duotone:hat-santa'
    },
    {
        'name': 'fa_duotone:hat-winter'
    },
    {
        'name': 'fa_duotone:hat-witch'
    },
    {
        'name': 'fa_duotone:hat-wizard'
    },
    {
        'name': 'fa_duotone:haykal'
    },
    {
        'name': 'fa_duotone:hdd'
    },
    {
        'name': 'fa_duotone:head-side-brain'
    },
    {
        'name': 'fa_duotone:head-side-medical'
    },
    {
        'name': 'fa_duotone:head-side'
    },
    {
        'name': 'fa_duotone:head-vr'
    },
    {
        'name': 'fa_duotone:heading'
    },
    {
        'name': 'fa_duotone:headphones-alt'
    },
    {
        'name': 'fa_duotone:headphones'
    },
    {
        'name': 'fa_duotone:headset'
    },
    {
        'name': 'fa_duotone:heart-broken'
    },
    {
        'name': 'fa_duotone:heart-circle'
    },
    {
        'name': 'fa_duotone:heart-rate'
    },
    {
        'name': 'fa_duotone:heart-square'
    },
    {
        'name': 'fa_duotone:heart'
    },
    {
        'name': 'fa_duotone:heartbeat'
    },
    {
        'name': 'fa_duotone:helicopter'
    },
    {
        'name': 'fa_duotone:helmet-battle'
    },
    {
        'name': 'fa_duotone:hexagon'
    },
    {
        'name': 'fa_duotone:highlighter'
    },
    {
        'name': 'fa_duotone:hiking'
    },
    {
        'name': 'fa_duotone:hippo'
    },
    {
        'name': 'fa_duotone:history'
    },
    {
        'name': 'fa_duotone:hockey-mask'
    },
    {
        'name': 'fa_duotone:hockey-puck'
    },
    {
        'name': 'fa_duotone:hockey-sticks'
    },
    {
        'name': 'fa_duotone:holly-berry'
    },
    {
        'name': 'fa_duotone:home-alt'
    },
    {
        'name': 'fa_duotone:home-heart'
    },
    {
        'name': 'fa_duotone:home-lg-alt'
    },
    {
        'name': 'fa_duotone:home-lg'
    },
    {
        'name': 'fa_duotone:home'
    },
    {
        'name': 'fa_duotone:hood-cloak'
    },
    {
        'name': 'fa_duotone:horizontal-rule'
    },
    {
        'name': 'fa_duotone:horse-head'
    },
    {
        'name': 'fa_duotone:horse'
    },
    {
        'name': 'fa_duotone:hospital-alt'
    },
    {
        'name': 'fa_duotone:hospital-symbol'
    },
    {
        'name': 'fa_duotone:hospital-user'
    },
    {
        'name': 'fa_duotone:hospital'
    },
    {
        'name': 'fa_duotone:hospitals'
    },
    {
        'name': 'fa_duotone:hot-tub'
    },
    {
        'name': 'fa_duotone:hotdog'
    },
    {
        'name': 'fa_duotone:hotel'
    },
    {
        'name': 'fa_duotone:hourglass-end'
    },
    {
        'name': 'fa_duotone:hourglass-half'
    },
    {
        'name': 'fa_duotone:hourglass-start'
    },
    {
        'name': 'fa_duotone:hourglass'
    },
    {
        'name': 'fa_duotone:house-damage'
    },
    {
        'name': 'fa_duotone:house-flood'
    },
    {
        'name': 'fa_duotone:hryvnia'
    },
    {
        'name': 'fa_duotone:humidity'
    },
    {
        'name': 'fa_duotone:hurricane'
    },
    {
        'name': 'fa_duotone:i-cursor'
    },
    {
        'name': 'fa_duotone:ice-cream'
    },
    {
        'name': 'fa_duotone:ice-skate'
    },
    {
        'name': 'fa_duotone:icicles'
    },
    {
        'name': 'fa_duotone:icons-alt'
    },
    {
        'name': 'fa_duotone:icons'
    },
    {
        'name': 'fa_duotone:id-badge'
    },
    {
        'name': 'fa_duotone:id-card-alt'
    },
    {
        'name': 'fa_duotone:id-card'
    },
    {
        'name': 'fa_duotone:igloo'
    },
    {
        'name': 'fa_duotone:image'
    },
    {
        'name': 'fa_duotone:images'
    },
    {
        'name': 'fa_duotone:inbox-in'
    },
    {
        'name': 'fa_duotone:inbox-out'
    },
    {
        'name': 'fa_duotone:inbox'
    },
    {
        'name': 'fa_duotone:indent'
    },
    {
        'name': 'fa_duotone:industry-alt'
    },
    {
        'name': 'fa_duotone:industry'
    },
    {
        'name': 'fa_duotone:infinity'
    },
    {
        'name': 'fa_duotone:info-circle'
    },
    {
        'name': 'fa_duotone:info-square'
    },
    {
        'name': 'fa_duotone:info'
    },
    {
        'name': 'fa_duotone:inhaler'
    },
    {
        'name': 'fa_duotone:integral'
    },
    {
        'name': 'fa_duotone:intersection'
    },
    {
        'name': 'fa_duotone:inventory'
    },
    {
        'name': 'fa_duotone:island-tropical'
    },
    {
        'name': 'fa_duotone:italic'
    },
    {
        'name': 'fa_duotone:jack-o-lantern'
    },
    {
        'name': 'fa_duotone:jedi'
    },
    {
        'name': 'fa_duotone:joint'
    },
    {
        'name': 'fa_duotone:journal-whills'
    },
    {
        'name': 'fa_duotone:kaaba'
    },
    {
        'name': 'fa_duotone:kerning'
    },
    {
        'name': 'fa_duotone:key-skeleton'
    },
    {
        'name': 'fa_duotone:key'
    },
    {
        'name': 'fa_duotone:keyboard'
    },
    {
        'name': 'fa_duotone:keynote'
    },
    {
        'name': 'fa_duotone:khanda'
    },
    {
        'name': 'fa_duotone:kidneys'
    },
    {
        'name': 'fa_duotone:kiss-beam'
    },
    {
        'name': 'fa_duotone:kiss-wink-heart'
    },
    {
        'name': 'fa_duotone:kiss'
    },
    {
        'name': 'fa_duotone:kite'
    },
    {
        'name': 'fa_duotone:kiwi-bird'
    },
    {
        'name': 'fa_duotone:knife-kitchen'
    },
    {
        'name': 'fa_duotone:lambda'
    },
    {
        'name': 'fa_duotone:lamp'
    },
    {
        'name': 'fa_duotone:landmark-alt'
    },
    {
        'name': 'fa_duotone:landmark'
    },
    {
        'name': 'fa_duotone:language'
    },
    {
        'name': 'fa_duotone:laptop-code'
    },
    {
        'name': 'fa_duotone:laptop-medical'
    },
    {
        'name': 'fa_duotone:laptop'
    },
    {
        'name': 'fa_duotone:laugh-beam'
    },
    {
        'name': 'fa_duotone:laugh-squint'
    },
    {
        'name': 'fa_duotone:laugh-wink'
    },
    {
        'name': 'fa_duotone:laugh'
    },
    {
        'name': 'fa_duotone:layer-group'
    },
    {
        'name': 'fa_duotone:layer-minus'
    },
    {
        'name': 'fa_duotone:layer-plus'
    },
    {
        'name': 'fa_duotone:leaf-heart'
    },
    {
        'name': 'fa_duotone:leaf-maple'
    },
    {
        'name': 'fa_duotone:leaf-oak'
    },
    {
        'name': 'fa_duotone:leaf'
    },
    {
        'name': 'fa_duotone:lemon'
    },
    {
        'name': 'fa_duotone:less-than-equal'
    },
    {
        'name': 'fa_duotone:less-than'
    },
    {
        'name': 'fa_duotone:level-down-alt'
    },
    {
        'name': 'fa_duotone:level-down'
    },
    {
        'name': 'fa_duotone:level-up-alt'
    },
    {
        'name': 'fa_duotone:level-up'
    },
    {
        'name': 'fa_duotone:life-ring'
    },
    {
        'name': 'fa_duotone:lightbulb-dollar'
    },
    {
        'name': 'fa_duotone:lightbulb-exclamation'
    },
    {
        'name': 'fa_duotone:lightbulb-on'
    },
    {
        'name': 'fa_duotone:lightbulb-slash'
    },
    {
        'name': 'fa_duotone:lightbulb'
    },
    {
        'name': 'fa_duotone:lights-holiday'
    },
    {
        'name': 'fa_duotone:line-columns'
    },
    {
        'name': 'fa_duotone:line-height'
    },
    {
        'name': 'fa_duotone:link'
    },
    {
        'name': 'fa_duotone:lips'
    },
    {
        'name': 'fa_duotone:lira-sign'
    },
    {
        'name': 'fa_duotone:list-alt'
    },
    {
        'name': 'fa_duotone:list-ol'
    },
    {
        'name': 'fa_duotone:list-ul'
    },
    {
        'name': 'fa_duotone:list'
    },
    {
        'name': 'fa_duotone:location-arrow'
    },
    {
        'name': 'fa_duotone:location-circle'
    },
    {
        'name': 'fa_duotone:location-slash'
    },
    {
        'name': 'fa_duotone:location'
    },
    {
        'name': 'fa_duotone:lock-alt'
    },
    {
        'name': 'fa_duotone:lock-open-alt'
    },
    {
        'name': 'fa_duotone:lock-open'
    },
    {
        'name': 'fa_duotone:lock'
    },
    {
        'name': 'fa_duotone:long-arrow-alt-down'
    },
    {
        'name': 'fa_duotone:long-arrow-alt-left'
    },
    {
        'name': 'fa_duotone:long-arrow-alt-right'
    },
    {
        'name': 'fa_duotone:long-arrow-alt-up'
    },
    {
        'name': 'fa_duotone:long-arrow-down'
    },
    {
        'name': 'fa_duotone:long-arrow-left'
    },
    {
        'name': 'fa_duotone:long-arrow-right'
    },
    {
        'name': 'fa_duotone:long-arrow-up'
    },
    {
        'name': 'fa_duotone:loveseat'
    },
    {
        'name': 'fa_duotone:low-vision'
    },
    {
        'name': 'fa_duotone:luchador'
    },
    {
        'name': 'fa_duotone:luggage-cart'
    },
    {
        'name': 'fa_duotone:lungs'
    },
    {
        'name': 'fa_duotone:mace'
    },
    {
        'name': 'fa_duotone:magic'
    },
    {
        'name': 'fa_duotone:magnet'
    },
    {
        'name': 'fa_duotone:mail-bulk'
    },
    {
        'name': 'fa_duotone:mailbox'
    },
    {
        'name': 'fa_duotone:male'
    },
    {
        'name': 'fa_duotone:mandolin'
    },
    {
        'name': 'fa_duotone:map-marked-alt'
    },
    {
        'name': 'fa_duotone:map-marked'
    },
    {
        'name': 'fa_duotone:map-marker-alt-slash'
    },
    {
        'name': 'fa_duotone:map-marker-alt'
    },
    {
        'name': 'fa_duotone:map-marker-check'
    },
    {
        'name': 'fa_duotone:map-marker-edit'
    },
    {
        'name': 'fa_duotone:map-marker-exclamation'
    },
    {
        'name': 'fa_duotone:map-marker-minus'
    },
    {
        'name': 'fa_duotone:map-marker-plus'
    },
    {
        'name': 'fa_duotone:map-marker-question'
    },
    {
        'name': 'fa_duotone:map-marker-slash'
    },
    {
        'name': 'fa_duotone:map-marker-smile'
    },
    {
        'name': 'fa_duotone:map-marker-times'
    },
    {
        'name': 'fa_duotone:map-marker'
    },
    {
        'name': 'fa_duotone:map-pin'
    },
    {
        'name': 'fa_duotone:map-signs'
    },
    {
        'name': 'fa_duotone:map'
    },
    {
        'name': 'fa_duotone:marker'
    },
    {
        'name': 'fa_duotone:mars-double'
    },
    {
        'name': 'fa_duotone:mars-stroke-h'
    },
    {
        'name': 'fa_duotone:mars-stroke-v'
    },
    {
        'name': 'fa_duotone:mars-stroke'
    },
    {
        'name': 'fa_duotone:mars'
    },
    {
        'name': 'fa_duotone:mask'
    },
    {
        'name': 'fa_duotone:meat'
    },
    {
        'name': 'fa_duotone:medal'
    },
    {
        'name': 'fa_duotone:medkit'
    },
    {
        'name': 'fa_duotone:megaphone'
    },
    {
        'name': 'fa_duotone:meh-blank'
    },
    {
        'name': 'fa_duotone:meh-rolling-eyes'
    },
    {
        'name': 'fa_duotone:meh'
    },
    {
        'name': 'fa_duotone:memory'
    },
    {
        'name': 'fa_duotone:menorah'
    },
    {
        'name': 'fa_duotone:mercury'
    },
    {
        'name': 'fa_duotone:meteor'
    },
    {
        'name': 'fa_duotone:microchip'
    },
    {
        'name': 'fa_duotone:microphone-alt-slash'
    },
    {
        'name': 'fa_duotone:microphone-alt'
    },
    {
        'name': 'fa_duotone:microphone-slash'
    },
    {
        'name': 'fa_duotone:microphone'
    },
    {
        'name': 'fa_duotone:microscope'
    },
    {
        'name': 'fa_duotone:mind-share'
    },
    {
        'name': 'fa_duotone:minus-circle'
    },
    {
        'name': 'fa_duotone:minus-hexagon'
    },
    {
        'name': 'fa_duotone:minus-octagon'
    },
    {
        'name': 'fa_duotone:minus-square'
    },
    {
        'name': 'fa_duotone:minus'
    },
    {
        'name': 'fa_duotone:mistletoe'
    },
    {
        'name': 'fa_duotone:mitten'
    },
    {
        'name': 'fa_duotone:mobile-alt'
    },
    {
        'name': 'fa_duotone:mobile-android-alt'
    },
    {
        'name': 'fa_duotone:mobile-android'
    },
    {
        'name': 'fa_duotone:mobile'
    },
    {
        'name': 'fa_duotone:money-bill-alt'
    },
    {
        'name': 'fa_duotone:money-bill-wave-alt'
    },
    {
        'name': 'fa_duotone:money-bill-wave'
    },
    {
        'name': 'fa_duotone:money-bill'
    },
    {
        'name': 'fa_duotone:money-check-alt'
    },
    {
        'name': 'fa_duotone:money-check-edit-alt'
    },
    {
        'name': 'fa_duotone:money-check-edit'
    },
    {
        'name': 'fa_duotone:money-check'
    },
    {
        'name': 'fa_duotone:monitor-heart-rate'
    },
    {
        'name': 'fa_duotone:monkey'
    },
    {
        'name': 'fa_duotone:monument'
    },
    {
        'name': 'fa_duotone:moon-cloud'
    },
    {
        'name': 'fa_duotone:moon-stars'
    },
    {
        'name': 'fa_duotone:moon'
    },
    {
        'name': 'fa_duotone:mortar-pestle'
    },
    {
        'name': 'fa_duotone:mosque'
    },
    {
        'name': 'fa_duotone:motorcycle'
    },
    {
        'name': 'fa_duotone:mountain'
    },
    {
        'name': 'fa_duotone:mountains'
    },
    {
        'name': 'fa_duotone:mouse-pointer'
    },
    {
        'name': 'fa_duotone:mug-hot'
    },
    {
        'name': 'fa_duotone:mug-marshmallows'
    },
    {
        'name': 'fa_duotone:mug-tea'
    },
    {
        'name': 'fa_duotone:mug'
    },
    {
        'name': 'fa_duotone:music'
    },
    {
        'name': 'fa_duotone:narwhal'
    },
    {
        'name': 'fa_duotone:network-wired'
    },
    {
        'name': 'fa_duotone:neuter'
    },
    {
        'name': 'fa_duotone:newspaper'
    },
    {
        'name': 'fa_duotone:not-equal'
    },
    {
        'name': 'fa_duotone:notes-medical'
    },
    {
        'name': 'fa_duotone:object-group'
    },
    {
        'name': 'fa_duotone:object-ungroup'
    },
    {
        'name': 'fa_duotone:octagon'
    },
    {
        'name': 'fa_duotone:oil-can'
    },
    {
        'name': 'fa_duotone:oil-temp'
    },
    {
        'name': 'fa_duotone:om'
    },
    {
        'name': 'fa_duotone:omega'
    },
    {
        'name': 'fa_duotone:ornament'
    },
    {
        'name': 'fa_duotone:otter'
    },
    {
        'name': 'fa_duotone:outdent'
    },
    {
        'name': 'fa_duotone:overline'
    },
    {
        'name': 'fa_duotone:page-break'
    },
    {
        'name': 'fa_duotone:pager'
    },
    {
        'name': 'fa_duotone:paint-brush-alt'
    },
    {
        'name': 'fa_duotone:paint-brush'
    },
    {
        'name': 'fa_duotone:paint-roller'
    },
    {
        'name': 'fa_duotone:palette'
    },
    {
        'name': 'fa_duotone:pallet-alt'
    },
    {
        'name': 'fa_duotone:pallet'
    },
    {
        'name': 'fa_duotone:paper-plane'
    },
    {
        'name': 'fa_duotone:paperclip'
    },
    {
        'name': 'fa_duotone:parachute-box'
    },
    {
        'name': 'fa_duotone:paragraph-rtl'
    },
    {
        'name': 'fa_duotone:paragraph'
    },
    {
        'name': 'fa_duotone:parking-circle-slash'
    },
    {
        'name': 'fa_duotone:parking-circle'
    },
    {
        'name': 'fa_duotone:parking-slash'
    },
    {
        'name': 'fa_duotone:parking'
    },
    {
        'name': 'fa_duotone:passport'
    },
    {
        'name': 'fa_duotone:pastafarianism'
    },
    {
        'name': 'fa_duotone:paste'
    },
    {
        'name': 'fa_duotone:pause-circle'
    },
    {
        'name': 'fa_duotone:pause'
    },
    {
        'name': 'fa_duotone:paw-alt'
    },
    {
        'name': 'fa_duotone:paw-claws'
    },
    {
        'name': 'fa_duotone:paw'
    },
    {
        'name': 'fa_duotone:peace'
    },
    {
        'name': 'fa_duotone:pegasus'
    },
    {
        'name': 'fa_duotone:pen-alt'
    },
    {
        'name': 'fa_duotone:pen-fancy'
    },
    {
        'name': 'fa_duotone:pen-nib'
    },
    {
        'name': 'fa_duotone:pen-square'
    },
    {
        'name': 'fa_duotone:pen'
    },
    {
        'name': 'fa_duotone:pencil-alt'
    },
    {
        'name': 'fa_duotone:pencil-paintbrush'
    },
    {
        'name': 'fa_duotone:pencil-ruler'
    },
    {
        'name': 'fa_duotone:pencil'
    },
    {
        'name': 'fa_duotone:pennant'
    },
    {
        'name': 'fa_duotone:people-carry'
    },
    {
        'name': 'fa_duotone:pepper-hot'
    },
    {
        'name': 'fa_duotone:percent'
    },
    {
        'name': 'fa_duotone:percentage'
    },
    {
        'name': 'fa_duotone:person-booth'
    },
    {
        'name': 'fa_duotone:person-carry'
    },
    {
        'name': 'fa_duotone:person-dolly-empty'
    },
    {
        'name': 'fa_duotone:person-dolly'
    },
    {
        'name': 'fa_duotone:person-sign'
    },
    {
        'name': 'fa_duotone:phone-alt'
    },
    {
        'name': 'fa_duotone:phone-laptop'
    },
    {
        'name': 'fa_duotone:phone-office'
    },
    {
        'name': 'fa_duotone:phone-plus'
    },
    {
        'name': 'fa_duotone:phone-slash'
    },
    {
        'name': 'fa_duotone:phone-square-alt'
    },
    {
        'name': 'fa_duotone:phone-square'
    },
    {
        'name': 'fa_duotone:phone-volume'
    },
    {
        'name': 'fa_duotone:phone'
    },
    {
        'name': 'fa_duotone:photo-video'
    },
    {
        'name': 'fa_duotone:pi'
    },
    {
        'name': 'fa_duotone:pie'
    },
    {
        'name': 'fa_duotone:pig'
    },
    {
        'name': 'fa_duotone:piggy-bank'
    },
    {
        'name': 'fa_duotone:pills'
    },
    {
        'name': 'fa_duotone:pizza-slice'
    },
    {
        'name': 'fa_duotone:pizza'
    },
    {
        'name': 'fa_duotone:place-of-worship'
    },
    {
        'name': 'fa_duotone:plane-alt'
    },
    {
        'name': 'fa_duotone:plane-arrival'
    },
    {
        'name': 'fa_duotone:plane-departure'
    },
    {
        'name': 'fa_duotone:plane'
    },
    {
        'name': 'fa_duotone:play-circle'
    },
    {
        'name': 'fa_duotone:play'
    },
    {
        'name': 'fa_duotone:plug'
    },
    {
        'name': 'fa_duotone:plus-circle'
    },
    {
        'name': 'fa_duotone:plus-hexagon'
    },
    {
        'name': 'fa_duotone:plus-octagon'
    },
    {
        'name': 'fa_duotone:plus-square'
    },
    {
        'name': 'fa_duotone:plus'
    },
    {
        'name': 'fa_duotone:podcast'
    },
    {
        'name': 'fa_duotone:podium-star'
    },
    {
        'name': 'fa_duotone:podium'
    },
    {
        'name': 'fa_duotone:poll-h'
    },
    {
        'name': 'fa_duotone:poll-people'
    },
    {
        'name': 'fa_duotone:poll'
    },
    {
        'name': 'fa_duotone:poo-storm'
    },
    {
        'name': 'fa_duotone:poo'
    },
    {
        'name': 'fa_duotone:poop'
    },
    {
        'name': 'fa_duotone:popcorn'
    },
    {
        'name': 'fa_duotone:portrait'
    },
    {
        'name': 'fa_duotone:pound-sign'
    },
    {
        'name': 'fa_duotone:power-off'
    },
    {
        'name': 'fa_duotone:pray'
    },
    {
        'name': 'fa_duotone:praying-hands'
    },
    {
        'name': 'fa_duotone:prescription-bottle-alt'
    },
    {
        'name': 'fa_duotone:prescription-bottle'
    },
    {
        'name': 'fa_duotone:prescription'
    },
    {
        'name': 'fa_duotone:presentation'
    },
    {
        'name': 'fa_duotone:print-search'
    },
    {
        'name': 'fa_duotone:print-slash'
    },
    {
        'name': 'fa_duotone:print'
    },
    {
        'name': 'fa_duotone:procedures'
    },
    {
        'name': 'fa_duotone:project-diagram'
    },
    {
        'name': 'fa_duotone:pumpkin'
    },
    {
        'name': 'fa_duotone:puzzle-piece'
    },
    {
        'name': 'fa_duotone:qrcode'
    },
    {
        'name': 'fa_duotone:question-circle'
    },
    {
        'name': 'fa_duotone:question-square'
    },
    {
        'name': 'fa_duotone:question'
    },
    {
        'name': 'fa_duotone:quidditch'
    },
    {
        'name': 'fa_duotone:quote-left'
    },
    {
        'name': 'fa_duotone:quote-right'
    },
    {
        'name': 'fa_duotone:quran'
    },
    {
        'name': 'fa_duotone:rabbit-fast'
    },
    {
        'name': 'fa_duotone:rabbit'
    },
    {
        'name': 'fa_duotone:racquet'
    },
    {
        'name': 'fa_duotone:radiation-alt'
    },
    {
        'name': 'fa_duotone:radiation'
    },
    {
        'name': 'fa_duotone:rainbow'
    },
    {
        'name': 'fa_duotone:raindrops'
    },
    {
        'name': 'fa_duotone:ram'
    },
    {
        'name': 'fa_duotone:ramp-loading'
    },
    {
        'name': 'fa_duotone:random'
    },
    {
        'name': 'fa_duotone:receipt'
    },
    {
        'name': 'fa_duotone:rectangle-landscape'
    },
    {
        'name': 'fa_duotone:rectangle-portrait'
    },
    {
        'name': 'fa_duotone:rectangle-wide'
    },
    {
        'name': 'fa_duotone:recycle'
    },
    {
        'name': 'fa_duotone:redo-alt'
    },
    {
        'name': 'fa_duotone:redo'
    },
    {
        'name': 'fa_duotone:registered'
    },
    {
        'name': 'fa_duotone:remove-format'
    },
    {
        'name': 'fa_duotone:repeat-1-alt'
    },
    {
        'name': 'fa_duotone:repeat-1'
    },
    {
        'name': 'fa_duotone:repeat-alt'
    },
    {
        'name': 'fa_duotone:repeat'
    },
    {
        'name': 'fa_duotone:reply-all'
    },
    {
        'name': 'fa_duotone:reply'
    },
    {
        'name': 'fa_duotone:republican'
    },
    {
        'name': 'fa_duotone:restroom'
    },
    {
        'name': 'fa_duotone:retweet-alt'
    },
    {
        'name': 'fa_duotone:retweet'
    },
    {
        'name': 'fa_duotone:ribbon'
    },
    {
        'name': 'fa_duotone:ring'
    },
    {
        'name': 'fa_duotone:rings-wedding'
    },
    {
        'name': 'fa_duotone:road'
    },
    {
        'name': 'fa_duotone:robot'
    },
    {
        'name': 'fa_duotone:rocket'
    },
    {
        'name': 'fa_duotone:route-highway'
    },
    {
        'name': 'fa_duotone:route-interstate'
    },
    {
        'name': 'fa_duotone:route'
    },
    {
        'name': 'fa_duotone:rss-square'
    },
    {
        'name': 'fa_duotone:rss'
    },
    {
        'name': 'fa_duotone:ruble-sign'
    },
    {
        'name': 'fa_duotone:ruler-combined'
    },
    {
        'name': 'fa_duotone:ruler-horizontal'
    },
    {
        'name': 'fa_duotone:ruler-triangle'
    },
    {
        'name': 'fa_duotone:ruler-vertical'
    },
    {
        'name': 'fa_duotone:ruler'
    },
    {
        'name': 'fa_duotone:running'
    },
    {
        'name': 'fa_duotone:rupee-sign'
    },
    {
        'name': 'fa_duotone:rv'
    },
    {
        'name': 'fa_duotone:sack-dollar'
    },
    {
        'name': 'fa_duotone:sack'
    },
    {
        'name': 'fa_duotone:sad-cry'
    },
    {
        'name': 'fa_duotone:sad-tear'
    },
    {
        'name': 'fa_duotone:salad'
    },
    {
        'name': 'fa_duotone:sandwich'
    },
    {
        'name': 'fa_duotone:satellite-dish'
    },
    {
        'name': 'fa_duotone:satellite'
    },
    {
        'name': 'fa_duotone:sausage'
    },
    {
        'name': 'fa_duotone:save'
    },
    {
        'name': 'fa_duotone:scalpel-path'
    },
    {
        'name': 'fa_duotone:scalpel'
    },
    {
        'name': 'fa_duotone:scanner-keyboard'
    },
    {
        'name': 'fa_duotone:scanner-touchscreen'
    },
    {
        'name': 'fa_duotone:scanner'
    },
    {
        'name': 'fa_duotone:scarecrow'
    },
    {
        'name': 'fa_duotone:scarf'
    },
    {
        'name': 'fa_duotone:school'
    },
    {
        'name': 'fa_duotone:screwdriver'
    },
    {
        'name': 'fa_duotone:scroll-old'
    },
    {
        'name': 'fa_duotone:scroll'
    },
    {
        'name': 'fa_duotone:scrubber'
    },
    {
        'name': 'fa_duotone:scythe'
    },
    {
        'name': 'fa_duotone:sd-card'
    },
    {
        'name': 'fa_duotone:search-dollar'
    },
    {
        'name': 'fa_duotone:search-location'
    },
    {
        'name': 'fa_duotone:search-minus'
    },
    {
        'name': 'fa_duotone:search-plus'
    },
    {
        'name': 'fa_duotone:search'
    },
    {
        'name': 'fa_duotone:seedling'
    },
    {
        'name': 'fa_duotone:send-back'
    },
    {
        'name': 'fa_duotone:send-backward'
    },
    {
        'name': 'fa_duotone:server'
    },
    {
        'name': 'fa_duotone:shapes'
    },
    {
        'name': 'fa_duotone:share-all'
    },
    {
        'name': 'fa_duotone:share-alt-square'
    },
    {
        'name': 'fa_duotone:share-alt'
    },
    {
        'name': 'fa_duotone:share-square'
    },
    {
        'name': 'fa_duotone:share'
    },
    {
        'name': 'fa_duotone:sheep'
    },
    {
        'name': 'fa_duotone:shekel-sign'
    },
    {
        'name': 'fa_duotone:shield-alt'
    },
    {
        'name': 'fa_duotone:shield-check'
    },
    {
        'name': 'fa_duotone:shield-cross'
    },
    {
        'name': 'fa_duotone:shield'
    },
    {
        'name': 'fa_duotone:ship'
    },
    {
        'name': 'fa_duotone:shipping-fast'
    },
    {
        'name': 'fa_duotone:shipping-timed'
    },
    {
        'name': 'fa_duotone:shish-kebab'
    },
    {
        'name': 'fa_duotone:shoe-prints'
    },
    {
        'name': 'fa_duotone:shopping-bag'
    },
    {
        'name': 'fa_duotone:shopping-basket'
    },
    {
        'name': 'fa_duotone:shopping-cart'
    },
    {
        'name': 'fa_duotone:shovel-snow'
    },
    {
        'name': 'fa_duotone:shovel'
    },
    {
        'name': 'fa_duotone:shower'
    },
    {
        'name': 'fa_duotone:shredder'
    },
    {
        'name': 'fa_duotone:shuttle-van'
    },
    {
        'name': 'fa_duotone:shuttlecock'
    },
    {
        'name': 'fa_duotone:sickle'
    },
    {
        'name': 'fa_duotone:sigma'
    },
    {
        'name': 'fa_duotone:sign-in-alt'
    },
    {
        'name': 'fa_duotone:sign-in'
    },
    {
        'name': 'fa_duotone:sign-language'
    },
    {
        'name': 'fa_duotone:sign-out-alt'
    },
    {
        'name': 'fa_duotone:sign-out'
    },
    {
        'name': 'fa_duotone:sign'
    },
    {
        'name': 'fa_duotone:signal-1'
    },
    {
        'name': 'fa_duotone:signal-2'
    },
    {
        'name': 'fa_duotone:signal-3'
    },
    {
        'name': 'fa_duotone:signal-4'
    },
    {
        'name': 'fa_duotone:signal-alt-1'
    },
    {
        'name': 'fa_duotone:signal-alt-2'
    },
    {
        'name': 'fa_duotone:signal-alt-3'
    },
    {
        'name': 'fa_duotone:signal-alt-slash'
    },
    {
        'name': 'fa_duotone:signal-alt'
    },
    {
        'name': 'fa_duotone:signal-slash'
    },
    {
        'name': 'fa_duotone:signal'
    },
    {
        'name': 'fa_duotone:signature'
    },
    {
        'name': 'fa_duotone:sim-card'
    },
    {
        'name': 'fa_duotone:sitemap'
    },
    {
        'name': 'fa_duotone:skating'
    },
    {
        'name': 'fa_duotone:skeleton'
    },
    {
        'name': 'fa_duotone:ski-jump'
    },
    {
        'name': 'fa_duotone:ski-lift'
    },
    {
        'name': 'fa_duotone:skiing-nordic'
    },
    {
        'name': 'fa_duotone:skiing'
    },
    {
        'name': 'fa_duotone:skull-crossbones'
    },
    {
        'name': 'fa_duotone:skull'
    },
    {
        'name': 'fa_duotone:slash'
    },
    {
        'name': 'fa_duotone:sledding'
    },
    {
        'name': 'fa_duotone:sleigh'
    },
    {
        'name': 'fa_duotone:sliders-h-square'
    },
    {
        'name': 'fa_duotone:sliders-h'
    },
    {
        'name': 'fa_duotone:sliders-v-square'
    },
    {
        'name': 'fa_duotone:sliders-v'
    },
    {
        'name': 'fa_duotone:smile-beam'
    },
    {
        'name': 'fa_duotone:smile-plus'
    },
    {
        'name': 'fa_duotone:smile-wink'
    },
    {
        'name': 'fa_duotone:smile'
    },
    {
        'name': 'fa_duotone:smog'
    },
    {
        'name': 'fa_duotone:smoke'
    },
    {
        'name': 'fa_duotone:smoking-ban'
    },
    {
        'name': 'fa_duotone:smoking'
    },
    {
        'name': 'fa_duotone:sms'
    },
    {
        'name': 'fa_duotone:snake'
    },
    {
        'name': 'fa_duotone:snooze'
    },
    {
        'name': 'fa_duotone:snow-blowing'
    },
    {
        'name': 'fa_duotone:snowboarding'
    },
    {
        'name': 'fa_duotone:snowflake'
    },
    {
        'name': 'fa_duotone:snowflakes'
    },
    {
        'name': 'fa_duotone:snowman'
    },
    {
        'name': 'fa_duotone:snowmobile'
    },
    {
        'name': 'fa_duotone:snowplow'
    },
    {
        'name': 'fa_duotone:socks'
    },
    {
        'name': 'fa_duotone:solar-panel'
    },
    {
        'name': 'fa_duotone:sort-alpha-down-alt'
    },
    {
        'name': 'fa_duotone:sort-alpha-down'
    },
    {
        'name': 'fa_duotone:sort-alpha-up-alt'
    },
    {
        'name': 'fa_duotone:sort-alpha-up'
    },
    {
        'name': 'fa_duotone:sort-alt'
    },
    {
        'name': 'fa_duotone:sort-amount-down-alt'
    },
    {
        'name': 'fa_duotone:sort-amount-down'
    },
    {
        'name': 'fa_duotone:sort-amount-up-alt'
    },
    {
        'name': 'fa_duotone:sort-amount-up'
    },
    {
        'name': 'fa_duotone:sort-down'
    },
    {
        'name': 'fa_duotone:sort-numeric-down-alt'
    },
    {
        'name': 'fa_duotone:sort-numeric-down'
    },
    {
        'name': 'fa_duotone:sort-numeric-up-alt'
    },
    {
        'name': 'fa_duotone:sort-numeric-up'
    },
    {
        'name': 'fa_duotone:sort-shapes-down-alt'
    },
    {
        'name': 'fa_duotone:sort-shapes-down'
    },
    {
        'name': 'fa_duotone:sort-shapes-up-alt'
    },
    {
        'name': 'fa_duotone:sort-shapes-up'
    },
    {
        'name': 'fa_duotone:sort-size-down-alt'
    },
    {
        'name': 'fa_duotone:sort-size-down'
    },
    {
        'name': 'fa_duotone:sort-size-up-alt'
    },
    {
        'name': 'fa_duotone:sort-size-up'
    },
    {
        'name': 'fa_duotone:sort-up'
    },
    {
        'name': 'fa_duotone:sort'
    },
    {
        'name': 'fa_duotone:soup'
    },
    {
        'name': 'fa_duotone:spa'
    },
    {
        'name': 'fa_duotone:space-shuttle'
    },
    {
        'name': 'fa_duotone:spade'
    },
    {
        'name': 'fa_duotone:sparkles'
    },
    {
        'name': 'fa_duotone:spell-check'
    },
    {
        'name': 'fa_duotone:spider-black-widow'
    },
    {
        'name': 'fa_duotone:spider-web'
    },
    {
        'name': 'fa_duotone:spider'
    },
    {
        'name': 'fa_duotone:spinner-third'
    },
    {
        'name': 'fa_duotone:spinner'
    },
    {
        'name': 'fa_duotone:splotch'
    },
    {
        'name': 'fa_duotone:spray-can'
    },
    {
        'name': 'fa_duotone:square-full'
    },
    {
        'name': 'fa_duotone:square-root-alt'
    },
    {
        'name': 'fa_duotone:square-root'
    },
    {
        'name': 'fa_duotone:square'
    },
    {
        'name': 'fa_duotone:squirrel'
    },
    {
        'name': 'fa_duotone:staff'
    },
    {
        'name': 'fa_duotone:stamp'
    },
    {
        'name': 'fa_duotone:star-and-crescent'
    },
    {
        'name': 'fa_duotone:star-christmas'
    },
    {
        'name': 'fa_duotone:star-exclamation'
    },
    {
        'name': 'fa_duotone:star-half-alt'
    },
    {
        'name': 'fa_duotone:star-half'
    },
    {
        'name': 'fa_duotone:star-of-david'
    },
    {
        'name': 'fa_duotone:star-of-life'
    },
    {
        'name': 'fa_duotone:star'
    },
    {
        'name': 'fa_duotone:stars'
    },
    {
        'name': 'fa_duotone:steak'
    },
    {
        'name': 'fa_duotone:steering-wheel'
    },
    {
        'name': 'fa_duotone:step-backward'
    },
    {
        'name': 'fa_duotone:step-forward'
    },
    {
        'name': 'fa_duotone:stethoscope'
    },
    {
        'name': 'fa_duotone:sticky-note'
    },
    {
        'name': 'fa_duotone:stocking'
    },
    {
        'name': 'fa_duotone:stomach'
    },
    {
        'name': 'fa_duotone:stop-circle'
    },
    {
        'name': 'fa_duotone:stop'
    },
    {
        'name': 'fa_duotone:stopwatch'
    },
    {
        'name': 'fa_duotone:store-alt'
    },
    {
        'name': 'fa_duotone:store'
    },
    {
        'name': 'fa_duotone:stream'
    },
    {
        'name': 'fa_duotone:street-view'
    },
    {
        'name': 'fa_duotone:stretcher'
    },
    {
        'name': 'fa_duotone:strikethrough'
    },
    {
        'name': 'fa_duotone:stroopwafel'
    },
    {
        'name': 'fa_duotone:subscript'
    },
    {
        'name': 'fa_duotone:subway'
    },
    {
        'name': 'fa_duotone:suitcase-rolling'
    },
    {
        'name': 'fa_duotone:suitcase'
    },
    {
        'name': 'fa_duotone:sun-cloud'
    },
    {
        'name': 'fa_duotone:sun-dust'
    },
    {
        'name': 'fa_duotone:sun-haze'
    },
    {
        'name': 'fa_duotone:sun'
    },
    {
        'name': 'fa_duotone:sunglasses'
    },
    {
        'name': 'fa_duotone:sunrise'
    },
    {
        'name': 'fa_duotone:sunset'
    },
    {
        'name': 'fa_duotone:superscript'
    },
    {
        'name': 'fa_duotone:surprise'
    },
    {
        'name': 'fa_duotone:swatchbook'
    },
    {
        'name': 'fa_duotone:swimmer'
    },
    {
        'name': 'fa_duotone:swimming-pool'
    },
    {
        'name': 'fa_duotone:sword'
    },
    {
        'name': 'fa_duotone:swords'
    },
    {
        'name': 'fa_duotone:synagogue'
    },
    {
        'name': 'fa_duotone:sync-alt'
    },
    {
        'name': 'fa_duotone:sync'
    },
    {
        'name': 'fa_duotone:syringe'
    },
    {
        'name': 'fa_duotone:table-tennis'
    },
    {
        'name': 'fa_duotone:table'
    },
    {
        'name': 'fa_duotone:tablet-alt'
    },
    {
        'name': 'fa_duotone:tablet-android-alt'
    },
    {
        'name': 'fa_duotone:tablet-android'
    },
    {
        'name': 'fa_duotone:tablet-rugged'
    },
    {
        'name': 'fa_duotone:tablet'
    },
    {
        'name': 'fa_duotone:tablets'
    },
    {
        'name': 'fa_duotone:tachometer-alt-average'
    },
    {
        'name': 'fa_duotone:tachometer-alt-fast'
    },
    {
        'name': 'fa_duotone:tachometer-alt-fastest'
    },
    {
        'name': 'fa_duotone:tachometer-alt-slow'
    },
    {
        'name': 'fa_duotone:tachometer-alt-slowest'
    },
    {
        'name': 'fa_duotone:tachometer-alt'
    },
    {
        'name': 'fa_duotone:tachometer-average'
    },
    {
        'name': 'fa_duotone:tachometer-fast'
    },
    {
        'name': 'fa_duotone:tachometer-fastest'
    },
    {
        'name': 'fa_duotone:tachometer-slow'
    },
    {
        'name': 'fa_duotone:tachometer-slowest'
    },
    {
        'name': 'fa_duotone:tachometer'
    },
    {
        'name': 'fa_duotone:taco'
    },
    {
        'name': 'fa_duotone:tag'
    },
    {
        'name': 'fa_duotone:tags'
    },
    {
        'name': 'fa_duotone:tally'
    },
    {
        'name': 'fa_duotone:tanakh'
    },
    {
        'name': 'fa_duotone:tape'
    },
    {
        'name': 'fa_duotone:tasks-alt'
    },
    {
        'name': 'fa_duotone:tasks'
    },
    {
        'name': 'fa_duotone:taxi'
    },
    {
        'name': 'fa_duotone:teeth-open'
    },
    {
        'name': 'fa_duotone:teeth'
    },
    {
        'name': 'fa_duotone:temperature-frigid'
    },
    {
        'name': 'fa_duotone:temperature-high'
    },
    {
        'name': 'fa_duotone:temperature-hot'
    },
    {
        'name': 'fa_duotone:temperature-low'
    },
    {
        'name': 'fa_duotone:tenge'
    },
    {
        'name': 'fa_duotone:tennis-ball'
    },
    {
        'name': 'fa_duotone:terminal'
    },
    {
        'name': 'fa_duotone:text-height'
    },
    {
        'name': 'fa_duotone:text-size'
    },
    {
        'name': 'fa_duotone:text-width'
    },
    {
        'name': 'fa_duotone:text'
    },
    {
        'name': 'fa_duotone:th-large'
    },
    {
        'name': 'fa_duotone:th-list'
    },
    {
        'name': 'fa_duotone:th'
    },
    {
        'name': 'fa_duotone:theater-masks'
    },
    {
        'name': 'fa_duotone:thermometer-empty'
    },
    {
        'name': 'fa_duotone:thermometer-full'
    },
    {
        'name': 'fa_duotone:thermometer-half'
    },
    {
        'name': 'fa_duotone:thermometer-quarter'
    },
    {
        'name': 'fa_duotone:thermometer-three-quarters'
    },
    {
        'name': 'fa_duotone:thermometer'
    },
    {
        'name': 'fa_duotone:theta'
    },
    {
        'name': 'fa_duotone:thumbs-down'
    },
    {
        'name': 'fa_duotone:thumbs-up'
    },
    {
        'name': 'fa_duotone:thumbtack'
    },
    {
        'name': 'fa_duotone:thunderstorm-moon'
    },
    {
        'name': 'fa_duotone:thunderstorm-sun'
    },
    {
        'name': 'fa_duotone:thunderstorm'
    },
    {
        'name': 'fa_duotone:ticket-alt'
    },
    {
        'name': 'fa_duotone:ticket'
    },
    {
        'name': 'fa_duotone:tilde'
    },
    {
        'name': 'fa_duotone:times-circle'
    },
    {
        'name': 'fa_duotone:times-hexagon'
    },
    {
        'name': 'fa_duotone:times-octagon'
    },
    {
        'name': 'fa_duotone:times-square'
    },
    {
        'name': 'fa_duotone:times'
    },
    {
        'name': 'fa_duotone:tint-slash'
    },
    {
        'name': 'fa_duotone:tint'
    },
    {
        'name': 'fa_duotone:tire-flat'
    },
    {
        'name': 'fa_duotone:tire-pressure-warning'
    },
    {
        'name': 'fa_duotone:tire-rugged'
    },
    {
        'name': 'fa_duotone:tire'
    },
    {
        'name': 'fa_duotone:tired'
    },
    {
        'name': 'fa_duotone:toggle-off'
    },
    {
        'name': 'fa_duotone:toggle-on'
    },
    {
        'name': 'fa_duotone:toilet-paper-alt'
    },
    {
        'name': 'fa_duotone:toilet-paper'
    },
    {
        'name': 'fa_duotone:toilet'
    },
    {
        'name': 'fa_duotone:tombstone-alt'
    },
    {
        'name': 'fa_duotone:tombstone'
    },
    {
        'name': 'fa_duotone:toolbox'
    },
    {
        'name': 'fa_duotone:tools'
    },
    {
        'name': 'fa_duotone:tooth'
    },
    {
        'name': 'fa_duotone:toothbrush'
    },
    {
        'name': 'fa_duotone:torah'
    },
    {
        'name': 'fa_duotone:torii-gate'
    },
    {
        'name': 'fa_duotone:tornado'
    },
    {
        'name': 'fa_duotone:tractor'
    },
    {
        'name': 'fa_duotone:trademark'
    },
    {
        'name': 'fa_duotone:traffic-cone'
    },
    {
        'name': 'fa_duotone:traffic-light-go'
    },
    {
        'name': 'fa_duotone:traffic-light-slow'
    },
    {
        'name': 'fa_duotone:traffic-light-stop'
    },
    {
        'name': 'fa_duotone:traffic-light'
    },
    {
        'name': 'fa_duotone:train'
    },
    {
        'name': 'fa_duotone:tram'
    },
    {
        'name': 'fa_duotone:transgender-alt'
    },
    {
        'name': 'fa_duotone:transgender'
    },
    {
        'name': 'fa_duotone:trash-alt'
    },
    {
        'name': 'fa_duotone:trash-restore-alt'
    },
    {
        'name': 'fa_duotone:trash-restore'
    },
    {
        'name': 'fa_duotone:trash-undo-alt'
    },
    {
        'name': 'fa_duotone:trash-undo'
    },
    {
        'name': 'fa_duotone:trash'
    },
    {
        'name': 'fa_duotone:treasure-chest'
    },
    {
        'name': 'fa_duotone:tree-alt'
    },
    {
        'name': 'fa_duotone:tree-christmas'
    },
    {
        'name': 'fa_duotone:tree-decorated'
    },
    {
        'name': 'fa_duotone:tree-large'
    },
    {
        'name': 'fa_duotone:tree-palm'
    },
    {
        'name': 'fa_duotone:tree'
    },
    {
        'name': 'fa_duotone:trees'
    },
    {
        'name': 'fa_duotone:triangle'
    },
    {
        'name': 'fa_duotone:trophy-alt'
    },
    {
        'name': 'fa_duotone:trophy'
    },
    {
        'name': 'fa_duotone:truck-container'
    },
    {
        'name': 'fa_duotone:truck-couch'
    },
    {
        'name': 'fa_duotone:truck-loading'
    },
    {
        'name': 'fa_duotone:truck-monster'
    },
    {
        'name': 'fa_duotone:truck-moving'
    },
    {
        'name': 'fa_duotone:truck-pickup'
    },
    {
        'name': 'fa_duotone:truck-plow'
    },
    {
        'name': 'fa_duotone:truck-ramp'
    },
    {
        'name': 'fa_duotone:truck'
    },
    {
        'name': 'fa_duotone:tshirt'
    },
    {
        'name': 'fa_duotone:tty'
    },
    {
        'name': 'fa_duotone:turkey'
    },
    {
        'name': 'fa_duotone:turtle'
    },
    {
        'name': 'fa_duotone:tv-retro'
    },
    {
        'name': 'fa_duotone:tv'
    },
    {
        'name': 'fa_duotone:umbrella-beach'
    },
    {
        'name': 'fa_duotone:umbrella'
    },
    {
        'name': 'fa_duotone:underline'
    },
    {
        'name': 'fa_duotone:undo-alt'
    },
    {
        'name': 'fa_duotone:undo'
    },
    {
        'name': 'fa_duotone:unicorn'
    },
    {
        'name': 'fa_duotone:union'
    },
    {
        'name': 'fa_duotone:universal-access'
    },
    {
        'name': 'fa_duotone:university'
    },
    {
        'name': 'fa_duotone:unlink'
    },
    {
        'name': 'fa_duotone:unlock-alt'
    },
    {
        'name': 'fa_duotone:unlock'
    },
    {
        'name': 'fa_duotone:upload'
    },
    {
        'name': 'fa_duotone:usd-circle'
    },
    {
        'name': 'fa_duotone:usd-square'
    },
    {
        'name': 'fa_duotone:user-alt-slash'
    },
    {
        'name': 'fa_duotone:user-alt'
    },
    {
        'name': 'fa_duotone:user-astronaut'
    },
    {
        'name': 'fa_duotone:user-chart'
    },
    {
        'name': 'fa_duotone:user-check'
    },
    {
        'name': 'fa_duotone:user-circle'
    },
    {
        'name': 'fa_duotone:user-clock'
    },
    {
        'name': 'fa_duotone:user-cog'
    },
    {
        'name': 'fa_duotone:user-crown'
    },
    {
        'name': 'fa_duotone:user-edit'
    },
    {
        'name': 'fa_duotone:user-friends'
    },
    {
        'name': 'fa_duotone:user-graduate'
    },
    {
        'name': 'fa_duotone:user-hard-hat'
    },
    {
        'name': 'fa_duotone:user-headset'
    },
    {
        'name': 'fa_duotone:user-injured'
    },
    {
        'name': 'fa_duotone:user-lock'
    },
    {
        'name': 'fa_duotone:user-md-chat'
    },
    {
        'name': 'fa_duotone:user-md'
    },
    {
        'name': 'fa_duotone:user-minus'
    },
    {
        'name': 'fa_duotone:user-ninja'
    },
    {
        'name': 'fa_duotone:user-nurse'
    },
    {
        'name': 'fa_duotone:user-plus'
    },
    {
        'name': 'fa_duotone:user-secret'
    },
    {
        'name': 'fa_duotone:user-shield'
    },
    {
        'name': 'fa_duotone:user-slash'
    },
    {
        'name': 'fa_duotone:user-tag'
    },
    {
        'name': 'fa_duotone:user-tie'
    },
    {
        'name': 'fa_duotone:user-times'
    },
    {
        'name': 'fa_duotone:user'
    },
    {
        'name': 'fa_duotone:users-class'
    },
    {
        'name': 'fa_duotone:users-cog'
    },
    {
        'name': 'fa_duotone:users-crown'
    },
    {
        'name': 'fa_duotone:users-medical'
    },
    {
        'name': 'fa_duotone:users'
    },
    {
        'name': 'fa_duotone:utensil-fork'
    },
    {
        'name': 'fa_duotone:utensil-knife'
    },
    {
        'name': 'fa_duotone:utensil-spoon'
    },
    {
        'name': 'fa_duotone:utensils-alt'
    },
    {
        'name': 'fa_duotone:utensils'
    },
    {
        'name': 'fa_duotone:value-absolute'
    },
    {
        'name': 'fa_duotone:vector-square'
    },
    {
        'name': 'fa_duotone:venus-double'
    },
    {
        'name': 'fa_duotone:venus-mars'
    },
    {
        'name': 'fa_duotone:venus'
    },
    {
        'name': 'fa_duotone:vial'
    },
    {
        'name': 'fa_duotone:vials'
    },
    {
        'name': 'fa_duotone:video-plus'
    },
    {
        'name': 'fa_duotone:video-slash'
    },
    {
        'name': 'fa_duotone:video'
    },
    {
        'name': 'fa_duotone:vihara'
    },
    {
        'name': 'fa_duotone:voicemail'
    },
    {
        'name': 'fa_duotone:volcano'
    },
    {
        'name': 'fa_duotone:volleyball-ball'
    },
    {
        'name': 'fa_duotone:volume-down'
    },
    {
        'name': 'fa_duotone:volume-mute'
    },
    {
        'name': 'fa_duotone:volume-off'
    },
    {
        'name': 'fa_duotone:volume-slash'
    },
    {
        'name': 'fa_duotone:volume-up'
    },
    {
        'name': 'fa_duotone:volume'
    },
    {
        'name': 'fa_duotone:vote-nay'
    },
    {
        'name': 'fa_duotone:vote-yea'
    },
    {
        'name': 'fa_duotone:vr-cardboard'
    },
    {
        'name': 'fa_duotone:walker'
    },
    {
        'name': 'fa_duotone:walking'
    },
    {
        'name': 'fa_duotone:wallet'
    },
    {
        'name': 'fa_duotone:wand-magic'
    },
    {
        'name': 'fa_duotone:wand'
    },
    {
        'name': 'fa_duotone:warehouse-alt'
    },
    {
        'name': 'fa_duotone:warehouse'
    },
    {
        'name': 'fa_duotone:washer'
    },
    {
        'name': 'fa_duotone:watch-fitness'
    },
    {
        'name': 'fa_duotone:watch'
    },
    {
        'name': 'fa_duotone:water-lower'
    },
    {
        'name': 'fa_duotone:water-rise'
    },
    {
        'name': 'fa_duotone:water'
    },
    {
        'name': 'fa_duotone:wave-sine'
    },
    {
        'name': 'fa_duotone:wave-square'
    },
    {
        'name': 'fa_duotone:wave-triangle'
    },
    {
        'name': 'fa_duotone:webcam-slash'
    },
    {
        'name': 'fa_duotone:webcam'
    },
    {
        'name': 'fa_duotone:weight-hanging'
    },
    {
        'name': 'fa_duotone:weight'
    },
    {
        'name': 'fa_duotone:whale'
    },
    {
        'name': 'fa_duotone:wheat'
    },
    {
        'name': 'fa_duotone:wheelchair'
    },
    {
        'name': 'fa_duotone:whistle'
    },
    {
        'name': 'fa_duotone:wifi-1'
    },
    {
        'name': 'fa_duotone:wifi-2'
    },
    {
        'name': 'fa_duotone:wifi-slash'
    },
    {
        'name': 'fa_duotone:wifi'
    },
    {
        'name': 'fa_duotone:wind-turbine'
    },
    {
        'name': 'fa_duotone:wind-warning'
    },
    {
        'name': 'fa_duotone:wind'
    },
    {
        'name': 'fa_duotone:window-alt'
    },
    {
        'name': 'fa_duotone:window-close'
    },
    {
        'name': 'fa_duotone:window-maximize'
    },
    {
        'name': 'fa_duotone:window-minimize'
    },
    {
        'name': 'fa_duotone:window-restore'
    },
    {
        'name': 'fa_duotone:window'
    },
    {
        'name': 'fa_duotone:windsock'
    },
    {
        'name': 'fa_duotone:wine-bottle'
    },
    {
        'name': 'fa_duotone:wine-glass-alt'
    },
    {
        'name': 'fa_duotone:wine-glass'
    },
    {
        'name': 'fa_duotone:won-sign'
    },
    {
        'name': 'fa_duotone:wreath'
    },
    {
        'name': 'fa_duotone:wrench'
    },
    {
        'name': 'fa_duotone:x-ray'
    },
    {
        'name': 'fa_duotone:yen-sign'
    },
    {
        'name': 'fa_duotone:yin-yang'
    },
    {
        'name': 'fa_brands:500px'
    },
    {
        'name': 'fa_brands:accessible-icon'
    },
    {
        'name': 'fa_brands:accusoft'
    },
    {
        'name': 'fa_brands:acquisitions-incorporated'
    },
    {
        'name': 'fa_brands:adn'
    },
    {
        'name': 'fa_brands:adobe'
    },
    {
        'name': 'fa_brands:adversal'
    },
    {
        'name': 'fa_brands:affiliatetheme'
    },
    {
        'name': 'fa_brands:airbnb'
    },
    {
        'name': 'fa_brands:algolia'
    },
    {
        'name': 'fa_brands:alipay'
    },
    {
        'name': 'fa_brands:amazon-pay'
    },
    {
        'name': 'fa_brands:amazon'
    },
    {
        'name': 'fa_brands:amilia'
    },
    {
        'name': 'fa_brands:android'
    },
    {
        'name': 'fa_brands:angellist'
    },
    {
        'name': 'fa_brands:angrycreative'
    },
    {
        'name': 'fa_brands:angular'
    },
    {
        'name': 'fa_brands:app-store-ios'
    },
    {
        'name': 'fa_brands:app-store'
    },
    {
        'name': 'fa_brands:apper'
    },
    {
        'name': 'fa_brands:apple-pay'
    },
    {
        'name': 'fa_brands:apple'
    },
    {
        'name': 'fa_brands:artstation'
    },
    {
        'name': 'fa_brands:asymmetrik'
    },
    {
        'name': 'fa_brands:atlassian'
    },
    {
        'name': 'fa_brands:audible'
    },
    {
        'name': 'fa_brands:autoprefixer'
    },
    {
        'name': 'fa_brands:avianex'
    },
    {
        'name': 'fa_brands:aviato'
    },
    {
        'name': 'fa_brands:aws'
    },
    {
        'name': 'fa_brands:bandcamp'
    },
    {
        'name': 'fa_brands:battle-net'
    },
    {
        'name': 'fa_brands:behance-square'
    },
    {
        'name': 'fa_brands:behance'
    },
    {
        'name': 'fa_brands:bimobject'
    },
    {
        'name': 'fa_brands:bitbucket'
    },
    {
        'name': 'fa_brands:bitcoin'
    },
    {
        'name': 'fa_brands:bity'
    },
    {
        'name': 'fa_brands:black-tie'
    },
    {
        'name': 'fa_brands:blackberry'
    },
    {
        'name': 'fa_brands:blogger-b'
    },
    {
        'name': 'fa_brands:blogger'
    },
    {
        'name': 'fa_brands:bluetooth-b'
    },
    {
        'name': 'fa_brands:bluetooth'
    },
    {
        'name': 'fa_brands:bootstrap'
    },
    {
        'name': 'fa_brands:btc'
    },
    {
        'name': 'fa_brands:buffer'
    },
    {
        'name': 'fa_brands:buromobelexperte'
    },
    {
        'name': 'fa_brands:buysellads'
    },
    {
        'name': 'fa_brands:canadian-maple-leaf'
    },
    {
        'name': 'fa_brands:cc-amazon-pay'
    },
    {
        'name': 'fa_brands:cc-amex'
    },
    {
        'name': 'fa_brands:cc-apple-pay'
    },
    {
        'name': 'fa_brands:cc-diners-club'
    },
    {
        'name': 'fa_brands:cc-discover'
    },
    {
        'name': 'fa_brands:cc-jcb'
    },
    {
        'name': 'fa_brands:cc-mastercard'
    },
    {
        'name': 'fa_brands:cc-paypal'
    },
    {
        'name': 'fa_brands:cc-stripe'
    },
    {
        'name': 'fa_brands:cc-visa'
    },
    {
        'name': 'fa_brands:centercode'
    },
    {
        'name': 'fa_brands:centos'
    },
    {
        'name': 'fa_brands:chrome'
    },
    {
        'name': 'fa_brands:chromecast'
    },
    {
        'name': 'fa_brands:cloudscale'
    },
    {
        'name': 'fa_brands:cloudsmith'
    },
    {
        'name': 'fa_brands:cloudversify'
    },
    {
        'name': 'fa_brands:codepen'
    },
    {
        'name': 'fa_brands:codiepie'
    },
    {
        'name': 'fa_brands:confluence'
    },
    {
        'name': 'fa_brands:connectdevelop'
    },
    {
        'name': 'fa_brands:contao'
    },
    {
        'name': 'fa_brands:cotton-bureau'
    },
    {
        'name': 'fa_brands:cpanel'
    },
    {
        'name': 'fa_brands:creative-commons-by'
    },
    {
        'name': 'fa_brands:creative-commons-nc-eu'
    },
    {
        'name': 'fa_brands:creative-commons-nc-jp'
    },
    {
        'name': 'fa_brands:creative-commons-nc'
    },
    {
        'name': 'fa_brands:creative-commons-nd'
    },
    {
        'name': 'fa_brands:creative-commons-pd-alt'
    },
    {
        'name': 'fa_brands:creative-commons-pd'
    },
    {
        'name': 'fa_brands:creative-commons-remix'
    },
    {
        'name': 'fa_brands:creative-commons-sa'
    },
    {
        'name': 'fa_brands:creative-commons-sampling-plus'
    },
    {
        'name': 'fa_brands:creative-commons-sampling'
    },
    {
        'name': 'fa_brands:creative-commons-share'
    },
    {
        'name': 'fa_brands:creative-commons-zero'
    },
    {
        'name': 'fa_brands:creative-commons'
    },
    {
        'name': 'fa_brands:critical-role'
    },
    {
        'name': 'fa_brands:css3-alt'
    },
    {
        'name': 'fa_brands:css3'
    },
    {
        'name': 'fa_brands:cuttlefish'
    },
    {
        'name': 'fa_brands:d-and-d-beyond'
    },
    {
        'name': 'fa_brands:d-and-d'
    },
    {
        'name': 'fa_brands:dashcube'
    },
    {
        'name': 'fa_brands:delicious'
    },
    {
        'name': 'fa_brands:deploydog'
    },
    {
        'name': 'fa_brands:deskpro'
    },
    {
        'name': 'fa_brands:dev'
    },
    {
        'name': 'fa_brands:deviantart'
    },
    {
        'name': 'fa_brands:dhl'
    },
    {
        'name': 'fa_brands:diaspora'
    },
    {
        'name': 'fa_brands:digg'
    },
    {
        'name': 'fa_brands:digital-ocean'
    },
    {
        'name': 'fa_brands:discord'
    },
    {
        'name': 'fa_brands:discourse'
    },
    {
        'name': 'fa_brands:dochub'
    },
    {
        'name': 'fa_brands:docker'
    },
    {
        'name': 'fa_brands:draft2digital'
    },
    {
        'name': 'fa_brands:dribbble-square'
    },
    {
        'name': 'fa_brands:dribbble'
    },
    {
        'name': 'fa_brands:dropbox'
    },
    {
        'name': 'fa_brands:drupal'
    },
    {
        'name': 'fa_brands:dyalog'
    },
    {
        'name': 'fa_brands:earlybirds'
    },
    {
        'name': 'fa_brands:ebay'
    },
    {
        'name': 'fa_brands:edge'
    },
    {
        'name': 'fa_brands:elementor'
    },
    {
        'name': 'fa_brands:ello'
    },
    {
        'name': 'fa_brands:ember'
    },
    {
        'name': 'fa_brands:empire'
    },
    {
        'name': 'fa_brands:envira'
    },
    {
        'name': 'fa_brands:erlang'
    },
    {
        'name': 'fa_brands:ethereum'
    },
    {
        'name': 'fa_brands:etsy'
    },
    {
        'name': 'fa_brands:evernote'
    },
    {
        'name': 'fa_brands:expeditedssl'
    },
    {
        'name': 'fa_brands:facebook-f'
    },
    {
        'name': 'fa_brands:facebook-messenger'
    },
    {
        'name': 'fa_brands:facebook-square'
    },
    {
        'name': 'fa_brands:facebook'
    },
    {
        'name': 'fa_brands:fantasy-flight-games'
    },
    {
        'name': 'fa_brands:fedex'
    },
    {
        'name': 'fa_brands:fedora'
    },
    {
        'name': 'fa_brands:figma'
    },
    {
        'name': 'fa_brands:firefox'
    },
    {
        'name': 'fa_brands:first-order-alt'
    },
    {
        'name': 'fa_brands:first-order'
    },
    {
        'name': 'fa_brands:firstdraft'
    },
    {
        'name': 'fa_brands:flickr'
    },
    {
        'name': 'fa_brands:flipboard'
    },
    {
        'name': 'fa_brands:fly'
    },
    {
        'name': 'fa_brands:font-awesome-alt'
    },
    {
        'name': 'fa_brands:font-awesome-flag'
    },
    {
        'name': 'fa_brands:font-awesome-logo-full'
    },
    {
        'name': 'fa_brands:font-awesome'
    },
    {
        'name': 'fa_brands:fonticons-fi'
    },
    {
        'name': 'fa_brands:fonticons'
    },
    {
        'name': 'fa_brands:fort-awesome-alt'
    },
    {
        'name': 'fa_brands:fort-awesome'
    },
    {
        'name': 'fa_brands:forumbee'
    },
    {
        'name': 'fa_brands:foursquare'
    },
    {
        'name': 'fa_brands:free-code-camp'
    },
    {
        'name': 'fa_brands:freebsd'
    },
    {
        'name': 'fa_brands:fulcrum'
    },
    {
        'name': 'fa_brands:galactic-republic'
    },
    {
        'name': 'fa_brands:galactic-senate'
    },
    {
        'name': 'fa_brands:get-pocket'
    },
    {
        'name': 'fa_brands:gg-circle'
    },
    {
        'name': 'fa_brands:gg'
    },
    {
        'name': 'fa_brands:git-alt'
    },
    {
        'name': 'fa_brands:git-square'
    },
    {
        'name': 'fa_brands:git'
    },
    {
        'name': 'fa_brands:github-alt'
    },
    {
        'name': 'fa_brands:github-square'
    },
    {
        'name': 'fa_brands:github'
    },
    {
        'name': 'fa_brands:gitkraken'
    },
    {
        'name': 'fa_brands:gitlab'
    },
    {
        'name': 'fa_brands:gitter'
    },
    {
        'name': 'fa_brands:glide-g'
    },
    {
        'name': 'fa_brands:glide'
    },
    {
        'name': 'fa_brands:gofore'
    },
    {
        'name': 'fa_brands:goodreads-g'
    },
    {
        'name': 'fa_brands:goodreads'
    },
    {
        'name': 'fa_brands:google-drive'
    },
    {
        'name': 'fa_brands:google-play'
    },
    {
        'name': 'fa_brands:google-plus-g'
    },
    {
        'name': 'fa_brands:google-plus-square'
    },
    {
        'name': 'fa_brands:google-plus'
    },
    {
        'name': 'fa_brands:google-wallet'
    },
    {
        'name': 'fa_brands:google'
    },
    {
        'name': 'fa_brands:gratipay'
    },
    {
        'name': 'fa_brands:grav'
    },
    {
        'name': 'fa_brands:gripfire'
    },
    {
        'name': 'fa_brands:grunt'
    },
    {
        'name': 'fa_brands:gulp'
    },
    {
        'name': 'fa_brands:hacker-news-square'
    },
    {
        'name': 'fa_brands:hacker-news'
    },
    {
        'name': 'fa_brands:hackerrank'
    },
    {
        'name': 'fa_brands:hips'
    },
    {
        'name': 'fa_brands:hire-a-helper'
    },
    {
        'name': 'fa_brands:hooli'
    },
    {
        'name': 'fa_brands:hornbill'
    },
    {
        'name': 'fa_brands:hotjar'
    },
    {
        'name': 'fa_brands:houzz'
    },
    {
        'name': 'fa_brands:html5'
    },
    {
        'name': 'fa_brands:hubspot'
    },
    {
        'name': 'fa_brands:imdb'
    },
    {
        'name': 'fa_brands:instagram'
    },
    {
        'name': 'fa_brands:intercom'
    },
    {
        'name': 'fa_brands:internet-explorer'
    },
    {
        'name': 'fa_brands:invision'
    },
    {
        'name': 'fa_brands:ioxhost'
    },
    {
        'name': 'fa_brands:itch-io'
    },
    {
        'name': 'fa_brands:itunes-note'
    },
    {
        'name': 'fa_brands:itunes'
    },
    {
        'name': 'fa_brands:java'
    },
    {
        'name': 'fa_brands:jedi-order'
    },
    {
        'name': 'fa_brands:jenkins'
    },
    {
        'name': 'fa_brands:jira'
    },
    {
        'name': 'fa_brands:joget'
    },
    {
        'name': 'fa_brands:joomla'
    },
    {
        'name': 'fa_brands:js-square'
    },
    {
        'name': 'fa_brands:js'
    },
    {
        'name': 'fa_brands:jsfiddle'
    },
    {
        'name': 'fa_brands:kaggle'
    },
    {
        'name': 'fa_brands:keybase'
    },
    {
        'name': 'fa_brands:keycdn'
    },
    {
        'name': 'fa_brands:kickstarter-k'
    },
    {
        'name': 'fa_brands:kickstarter'
    },
    {
        'name': 'fa_brands:korvue'
    },
    {
        'name': 'fa_brands:laravel'
    },
    {
        'name': 'fa_brands:lastfm-square'
    },
    {
        'name': 'fa_brands:lastfm'
    },
    {
        'name': 'fa_brands:leanpub'
    },
    {
        'name': 'fa_brands:less'
    },
    {
        'name': 'fa_brands:line'
    },
    {
        'name': 'fa_brands:linkedin-in'
    },
    {
        'name': 'fa_brands:linkedin'
    },
    {
        'name': 'fa_brands:linode'
    },
    {
        'name': 'fa_brands:linux'
    },
    {
        'name': 'fa_brands:lyft'
    },
    {
        'name': 'fa_brands:magento'
    },
    {
        'name': 'fa_brands:mailchimp'
    },
    {
        'name': 'fa_brands:mandalorian'
    },
    {
        'name': 'fa_brands:markdown'
    },
    {
        'name': 'fa_brands:mastodon'
    },
    {
        'name': 'fa_brands:maxcdn'
    },
    {
        'name': 'fa_brands:medapps'
    },
    {
        'name': 'fa_brands:medium-m'
    },
    {
        'name': 'fa_brands:medium'
    },
    {
        'name': 'fa_brands:medrt'
    },
    {
        'name': 'fa_brands:meetup'
    },
    {
        'name': 'fa_brands:megaport'
    },
    {
        'name': 'fa_brands:mendeley'
    },
    {
        'name': 'fa_brands:microsoft'
    },
    {
        'name': 'fa_brands:mix'
    },
    {
        'name': 'fa_brands:mixcloud'
    },
    {
        'name': 'fa_brands:mizuni'
    },
    {
        'name': 'fa_brands:modx'
    },
    {
        'name': 'fa_brands:monero'
    },
    {
        'name': 'fa_brands:napster'
    },
    {
        'name': 'fa_brands:neos'
    },
    {
        'name': 'fa_brands:nimblr'
    },
    {
        'name': 'fa_brands:node-js'
    },
    {
        'name': 'fa_brands:node'
    },
    {
        'name': 'fa_brands:npm'
    },
    {
        'name': 'fa_brands:ns8'
    },
    {
        'name': 'fa_brands:nutritionix'
    },
    {
        'name': 'fa_brands:odnoklassniki-square'
    },
    {
        'name': 'fa_brands:odnoklassniki'
    },
    {
        'name': 'fa_brands:old-republic'
    },
    {
        'name': 'fa_brands:opencart'
    },
    {
        'name': 'fa_brands:openid'
    },
    {
        'name': 'fa_brands:opera'
    },
    {
        'name': 'fa_brands:optin-monster'
    },
    {
        'name': 'fa_brands:osi'
    },
    {
        'name': 'fa_brands:page4'
    },
    {
        'name': 'fa_brands:pagelines'
    },
    {
        'name': 'fa_brands:palfed'
    },
    {
        'name': 'fa_brands:patreon'
    },
    {
        'name': 'fa_brands:paypal'
    },
    {
        'name': 'fa_brands:penny-arcade'
    },
    {
        'name': 'fa_brands:periscope'
    },
    {
        'name': 'fa_brands:phabricator'
    },
    {
        'name': 'fa_brands:phoenix-framework'
    },
    {
        'name': 'fa_brands:phoenix-squadron'
    },
    {
        'name': 'fa_brands:php'
    },
    {
        'name': 'fa_brands:pied-piper-alt'
    },
    {
        'name': 'fa_brands:pied-piper-hat'
    },
    {
        'name': 'fa_brands:pied-piper-pp'
    },
    {
        'name': 'fa_brands:pied-piper'
    },
    {
        'name': 'fa_brands:pinterest-p'
    },
    {
        'name': 'fa_brands:pinterest-square'
    },
    {
        'name': 'fa_brands:pinterest'
    },
    {
        'name': 'fa_brands:playstation'
    },
    {
        'name': 'fa_brands:product-hunt'
    },
    {
        'name': 'fa_brands:pushed'
    },
    {
        'name': 'fa_brands:python'
    },
    {
        'name': 'fa_brands:qq'
    },
    {
        'name': 'fa_brands:quinscape'
    },
    {
        'name': 'fa_brands:quora'
    },
    {
        'name': 'fa_brands:r-project'
    },
    {
        'name': 'fa_brands:raspberry-pi'
    },
    {
        'name': 'fa_brands:ravelry'
    },
    {
        'name': 'fa_brands:react'
    },
    {
        'name': 'fa_brands:reacteurope'
    },
    {
        'name': 'fa_brands:readme'
    },
    {
        'name': 'fa_brands:rebel'
    },
    {
        'name': 'fa_brands:red-river'
    },
    {
        'name': 'fa_brands:reddit-alien'
    },
    {
        'name': 'fa_brands:reddit-square'
    },
    {
        'name': 'fa_brands:reddit'
    },
    {
        'name': 'fa_brands:redhat'
    },
    {
        'name': 'fa_brands:renren'
    },
    {
        'name': 'fa_brands:replyd'
    },
    {
        'name': 'fa_brands:researchgate'
    },
    {
        'name': 'fa_brands:resolving'
    },
    {
        'name': 'fa_brands:rev'
    },
    {
        'name': 'fa_brands:rocketchat'
    },
    {
        'name': 'fa_brands:rockrms'
    },
    {
        'name': 'fa_brands:safari'
    },
    {
        'name': 'fa_brands:salesforce'
    },
    {
        'name': 'fa_brands:sass'
    },
    {
        'name': 'fa_brands:schlix'
    },
    {
        'name': 'fa_brands:scribd'
    },
    {
        'name': 'fa_brands:searchengin'
    },
    {
        'name': 'fa_brands:sellcast'
    },
    {
        'name': 'fa_brands:sellsy'
    },
    {
        'name': 'fa_brands:servicestack'
    },
    {
        'name': 'fa_brands:shirtsinbulk'
    },
    {
        'name': 'fa_brands:shopware'
    },
    {
        'name': 'fa_brands:simplybuilt'
    },
    {
        'name': 'fa_brands:sistrix'
    },
    {
        'name': 'fa_brands:sith'
    },
    {
        'name': 'fa_brands:sketch'
    },
    {
        'name': 'fa_brands:skyatlas'
    },
    {
        'name': 'fa_brands:skype'
    },
    {
        'name': 'fa_brands:slack-hash'
    },
    {
        'name': 'fa_brands:slack'
    },
    {
        'name': 'fa_brands:slideshare'
    },
    {
        'name': 'fa_brands:snapchat-ghost'
    },
    {
        'name': 'fa_brands:snapchat-square'
    },
    {
        'name': 'fa_brands:snapchat'
    },
    {
        'name': 'fa_brands:soundcloud'
    },
    {
        'name': 'fa_brands:sourcetree'
    },
    {
        'name': 'fa_brands:speakap'
    },
    {
        'name': 'fa_brands:speaker-deck'
    },
    {
        'name': 'fa_brands:spotify'
    },
    {
        'name': 'fa_brands:squarespace'
    },
    {
        'name': 'fa_brands:stack-exchange'
    },
    {
        'name': 'fa_brands:stack-overflow'
    },
    {
        'name': 'fa_brands:stackpath'
    },
    {
        'name': 'fa_brands:staylinked'
    },
    {
        'name': 'fa_brands:steam-square'
    },
    {
        'name': 'fa_brands:steam-symbol'
    },
    {
        'name': 'fa_brands:steam'
    },
    {
        'name': 'fa_brands:sticker-mule'
    },
    {
        'name': 'fa_brands:strava'
    },
    {
        'name': 'fa_brands:stripe-s'
    },
    {
        'name': 'fa_brands:stripe'
    },
    {
        'name': 'fa_brands:studiovinari'
    },
    {
        'name': 'fa_brands:stumbleupon-circle'
    },
    {
        'name': 'fa_brands:stumbleupon'
    },
    {
        'name': 'fa_brands:superpowers'
    },
    {
        'name': 'fa_brands:supple'
    },
    {
        'name': 'fa_brands:suse'
    },
    {
        'name': 'fa_brands:symfony'
    },
    {
        'name': 'fa_brands:teamspeak'
    },
    {
        'name': 'fa_brands:telegram-plane'
    },
    {
        'name': 'fa_brands:telegram'
    },
    {
        'name': 'fa_brands:tencent-weibo'
    },
    {
        'name': 'fa_brands:the-red-yeti'
    },
    {
        'name': 'fa_brands:themeco'
    },
    {
        'name': 'fa_brands:themeisle'
    },
    {
        'name': 'fa_brands:think-peaks'
    },
    {
        'name': 'fa_brands:trade-federation'
    },
    {
        'name': 'fa_brands:trello'
    },
    {
        'name': 'fa_brands:tripadvisor'
    },
    {
        'name': 'fa_brands:tumblr-square'
    },
    {
        'name': 'fa_brands:tumblr'
    },
    {
        'name': 'fa_brands:twitch'
    },
    {
        'name': 'fa_brands:twitter-square'
    },
    {
        'name': 'fa_brands:twitter'
    },
    {
        'name': 'fa_brands:typo3'
    },
    {
        'name': 'fa_brands:uber'
    },
    {
        'name': 'fa_brands:ubuntu'
    },
    {
        'name': 'fa_brands:uikit'
    },
    {
        'name': 'fa_brands:uniregistry'
    },
    {
        'name': 'fa_brands:untappd'
    },
    {
        'name': 'fa_brands:ups'
    },
    {
        'name': 'fa_brands:usb'
    },
    {
        'name': 'fa_brands:usps'
    },
    {
        'name': 'fa_brands:ussunnah'
    },
    {
        'name': 'fa_brands:vaadin'
    },
    {
        'name': 'fa_brands:viacoin'
    },
    {
        'name': 'fa_brands:viadeo-square'
    },
    {
        'name': 'fa_brands:viadeo'
    },
    {
        'name': 'fa_brands:viber'
    },
    {
        'name': 'fa_brands:vimeo-square'
    },
    {
        'name': 'fa_brands:vimeo-v'
    },
    {
        'name': 'fa_brands:vimeo'
    },
    {
        'name': 'fa_brands:vine'
    },
    {
        'name': 'fa_brands:vk'
    },
    {
        'name': 'fa_brands:vnv'
    },
    {
        'name': 'fa_brands:vuejs'
    },
    {
        'name': 'fa_brands:waze'
    },
    {
        'name': 'fa_brands:weebly'
    },
    {
        'name': 'fa_brands:weibo'
    },
    {
        'name': 'fa_brands:weixin'
    },
    {
        'name': 'fa_brands:whatsapp-square'
    },
    {
        'name': 'fa_brands:whatsapp'
    },
    {
        'name': 'fa_brands:whmcs'
    },
    {
        'name': 'fa_brands:wikipedia-w'
    },
    {
        'name': 'fa_brands:windows'
    },
    {
        'name': 'fa_brands:wix'
    },
    {
        'name': 'fa_brands:wizards-of-the-coast'
    },
    {
        'name': 'fa_brands:wolf-pack-battalion'
    },
    {
        'name': 'fa_brands:wordpress-simple'
    },
    {
        'name': 'fa_brands:wordpress'
    },
    {
        'name': 'fa_brands:wpbeginner'
    },
    {
        'name': 'fa_brands:wpexplorer'
    },
    {
        'name': 'fa_brands:wpforms'
    },
    {
        'name': 'fa_brands:wpressr'
    },
    {
        'name': 'fa_brands:xbox'
    },
    {
        'name': 'fa_brands:xing-square'
    },
    {
        'name': 'fa_brands:xing'
    },
    {
        'name': 'fa_brands:y-combinator'
    },
    {
        'name': 'fa_brands:yahoo'
    },
    {
        'name': 'fa_brands:yammer'
    },
    {
        'name': 'fa_brands:yandex-international'
    },
    {
        'name': 'fa_brands:yandex'
    },
    {
        'name': 'fa_brands:yarn'
    },
    {
        'name': 'fa_brands:yelp'
    },
    {
        'name': 'fa_brands:yoast'
    },
    {
        'name': 'fa_brands:youtube-square'
    },
    {
        'name': 'fa_brands:youtube'
    },
    {
        'name': 'fa_brands:zhihu'
    }
]
