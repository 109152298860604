export const notificationTypes = {
    'device': [
        {
            key: 1,
            value: 'STATUS_ON'
        },
        {
            key: 2,
            value: 'STATUS_OFF'
        }
    ],
    'sensor': {
        'MIN_MAX': [
            {
                key: 8,
                value: 'MIN'
            },
            {
                key: 7,
                value: 'MAX'
            },
            {
                key: 6,
                value: 'IN_RANGE'
            },
            {
                key: 1,
                value: 'STATUS_ON'
            },
            {
                key: 2,
                value: 'STATUS_OFF'
            }
        ],
        'OPEN_CLOSED': [
            {
                key: 4,
                value: 'OPEN'
            },
            {
                key: 5,
                value: 'CLOSED'
            },
            {
                key: 1,
                value: 'STATUS_ON'
            },
            {
                key: 2,
                value: 'STATUS_OFF'
            }
        ],
    }
};
