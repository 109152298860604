import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByPropertyNeq',
    pure: false
})
export class FilterByPropertyNeqPipe implements PipeTransform {

    transform(items: any, property: string, propertyValue: any): any {
        if (!items || !property) {
            return items;
        }
        return items.filter(i => i[property] !== propertyValue);
    }

}
