import {Component, Inject, OnInit, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'sms-dialog',
    templateUrl: './sms-dialog.component.html',
    styleUrls: ['./sms-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SensorbaseSmsDialog implements OnInit {

    yearlyBilling: boolean = true;

    // /**
    //  * Constructor
    //  *
    //  * @param {MatDialogRef<SensorbaseWarningDialog>} dialogRef
    //  * @param translate
    //  * @param _data
    //  */
    constructor(
        public dialogRef: MatDialogRef<SensorbaseSmsDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {

    }

    ngOnInit(): void {
    }

}
