<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'HELP.SENSOR.BOARD_DIALOG.DIALOG_TITLE' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
            'success': sensor.status>0,
            'error': sensor.status<=0
             }" fxLayoutAlign="center">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <mat-table #table [dataSource]="dataSource" class="w-full">

            <!-- Position Column -->
            <ng-container matColumnDef="checkbox">
                <mat-header-cell class="primary" fxFlex="64px" *matHeaderCellDef>
                    <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="64px">
                    <mat-checkbox  color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef class="primary">
                    {{'HELP.SENSOR.BOARD_DIALOG.CELLS.NAME' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="truncate">
                    {{element.name}}
                </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="sensors">
                <mat-header-cell *matHeaderCellDef class="primary" fxHide.xs>
                    {{'HELP.SENSOR.BOARD_DIALOG.CELLS.TOTAL_SENSORS' | transloco }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxHide.xs> {{element.members.length}}
                </mat-cell>
            </ng-container>

            <!-- Add board button -->
            <ng-container matColumnDef="add">
                <mat-header-cell class="primary" *matHeaderCellDef fxFlex="64px">
                    <div class="flex justify-center">
                        <button mat-mini-fab color="warn" class="shadow-none" (click)="newBoard()"
                            matTooltip="{{'NAV.MAINMENU.DASHBOARDS.ADD' | transloco}}"
                            [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                            <mat-icon svgIcon="add"></mat-icon>
                        </button>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="64px">
                </mat-cell>
            </ng-container>


            <!-- Symbol Column
            <ng-container matColumnDef="symbol">
                <mat-header-cell *matHeaderCellDef class="primary"> Symbol </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.symbol}} </mat-cell>
            </ng-container> -->

            <mat-header-row *matHeaderRowDef="displayedColumns" class="bg-gray-50 dark:bg-black dark:bg-opacity-5 shadow"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer hover:bg-gray-100 dark:hover:bg-hover"
                     (click)="selection.toggle(row)" matRipple></mat-row>
        </mat-table>


        <!-- /DIALOG CONTENT -->

        <div *ngIf="show" class="message-box info flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div class="flex flex-row justify-between items-center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    {{'HELP.SENSOR.BOARD_DIALOG.INFO' | transloco }}
                </div>
            </div>
        </div>

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button class="mr-2" type="button" mat-flat-button color="primary" (click)="onSave()">
                {{'GENERIC.Save' | transloco }}
            </button>
            <button class="ml-2" type="button" mat-stroked-button color="warn" (click)="onCancel()">
                {{'GENERIC.Cancel' | transloco }}
            </button>
        </div>
        <!-- /DIALOG BUTONS -->

        <div class="relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>
    </div>

</div>
