// Employee Data
export const devicesModels = [
    {
        'id': 'INSTRUCTIONS.DEVICES.HUBZERO.ID',
        'title': 'INSTRUCTIONS.DEVICES.HUBZERO.TITLE',
        'content': 'INSTRUCTIONS.DEVICES.HUBZERO.CONTENT',
        'icon': 'device_hub',
        'image': '<img class="image" src="assets/images/images/hubzero1.jpg">'
    },
    {
        'id': 'INSTRUCTIONS.DEVICES.HUBZERO_V2.ID',
        'title': 'INSTRUCTIONS.DEVICES.HUBZERO_V2.TITLE',
        'content': 'INSTRUCTIONS.DEVICES.HUBZERO_V2.CONTENT',
        'icon': 'device_hub',
        'image': ''
    },
    {
        'id': 'INSTRUCTIONS.DEVICES.WIFIRELAYDIN.ID',
        'title': 'INSTRUCTIONS.DEVICES.WIFIRELAYDIN.TITLE',
        'content': 'INSTRUCTIONS.DEVICES.WIFIRELAYDIN.CONTENT',
        'icon': 'custom:switch_open',
        'image': ''
    },
];
export const sensorsModels = [
    {
        'id': 'INSTRUCTIONS.SENSORS.TEMPERATURE.ID',
        'title': 'INSTRUCTIONS.SENSORS.TEMPERATURE.TITLE',
        'content': 'INSTRUCTIONS.SENSORS.TEMPERATURE.CONTENT',
        'icon': 'fa_duotone:temperature-high',
        'image': '<img class="image" src="assets/images/images/temp.jpg">'
    },
    {
        'id': 'INSTRUCTIONS.SENSORS.HUMIDITY.ID',
        'title': 'INSTRUCTIONS.SENSORS.HUMIDITY.TITLE',
        'content': 'INSTRUCTIONS.SENSORS.HUMIDITY.CONTENT',
        'icon': 'custom:humidity',
        'image': ''
    },
    {
        'id': 'INSTRUCTIONS.SENSORS.SWITCH.ID',
        'title': 'INSTRUCTIONS.SENSORS.SWITCH.TITLE',
        'content': 'INSTRUCTIONS.SENSORS.SWITCH.CONTENT',
        'icon': 'custom:switch_open',
        'image': '<img class="image" src="assets/images/images/door.jpg">'
    },
];
export const APPS = [
    {
        'id': 'INSTRUCTIONS.APPS.REFRIGERATOR_RECORDING.ID',
        'title': 'INSTRUCTIONS.APPS.REFRIGERATOR_RECORDING.TITLE',
        'content': 'INSTRUCTIONS.APPS.REFRIGERATOR_RECORDING.CONTENT',
        'icon': 'fa_duotone:temperature-frigid',
        'image': '<img class="image" src="assets/images/images/hubzero4.jpg">' +
            '<img class="image" src="assets/images/images/temp.jpg">' +
            '<img class="image" src="assets/images/images/door.jpg">'
    },
    {
        'id': 'INSTRUCTIONS.APPS.GREENHOUSE.ID',
        'title': 'INSTRUCTIONS.APPS.GREENHOUSE.TITLE',
        'content': 'INSTRUCTIONS.APPS.GREENHOUSE.CONTENT',
        'icon': 'fa_duotone:warehouse',
        'image': 'Με εκτίμηση'
    },
    {
        'id': 'INSTRUCTIONS.APPS.WINDMILLS.ID',
        'title': 'INSTRUCTIONS.APPS.WINDMILLS.TITLE',
        'content': 'INSTRUCTIONS.APPS.WINDMILLS.CONTENT',
        'icon': 'custom:windmill_on',
        'image': '<img class="image" src="assets/images/images/hubzero3.jpg">' +
            '<img class="image" src="assets/images/images/door.jpg">'
    },
    {
        'id': 'INSTRUCTIONS.APPS.BURNER.ID',
        'title': 'INSTRUCTIONS.APPS.BURNER.TITLE',
        'content': 'INSTRUCTIONS.APPS.BURNER.CONTENT',
        'icon': 'custom:campfire',
        'image': 'Με εκτίμηση'
    },
    {
        'id': 'INSTRUCTIONS.APPS.WATER_HEATER.ID',
        'title': 'INSTRUCTIONS.APPS.WATER_HEATER.TITLE',
        'content': 'INSTRUCTIONS.APPS.WATER_HEATER.CONTENT',
        'icon': 'custom:liquid',
        'image': 'Με εκτίμηση'
    },
    {
        'id': 'INSTRUCTIONS.APPS.PHOTOVOLTAIC.ID',
        'title': 'INSTRUCTIONS.APPS.PHOTOVOLTAIC.TITLE',
        'content': 'INSTRUCTIONS.APPS.PHOTOVOLTAIC.CONTENT',
        'icon': 'custom:solar_panels_DTS',
        'image': 'Με εκτίμηση'
    },
    ];
export const PlatformUpdates = [
    {
        'id': 'Εμφάνιση του καιρού με βάση την τοποθεσία σας',
        'title': 'Βασικές έννοιες για το Θερμοκήπειο',
        'content': 'Έτσι με το hubzero μέσο της πλατφόρμας sensorbase.io  και την\n' +
            'αμφίδρομη επικοινωνία η συσκευή ενημερώνει την κατάστασή σε πραγματικό\n' +
            'χρόνο για ην θερμοκρασία , υγρασία ,ενεργοποίηση – απενεργοποίηση\n' +
            'θέρμανσης, κλιματισμού, ποτίσματος, Φωτισμού ,Πισίνας, θερμοσίφωνα,\n' +
            'ανεμοδείκτη , καυστήρα ρολών, Γκαραζόπορτας παροχής ΔΕΗ και γενικά\n' +
            'συσκευών ρεύματος\n' +
            'Αγόρασε το sensorbase hubzero. και δημιούργησε σενάρια κάνε τους\n' +
            'δικούς σου αυτοματισμούς με τον πιο απλό τρόπο άμεσα και εύκολα με την\n' +
            'πλατφόρμα sensorbase.io\n' +
            'Για την εγκατάσταση της συσκευής απαιτείται επίσκεψη τεχνικού\n' +
            '(αδειοδοτημένου) ηλεκτρολόγου εγκαταστάτη\n' +
            'Η συσκευή τοποθετείται είτε σε ράγα ηλεκτρικού πινάκα , ειτε με επιτιχεια\n' +
            'τροφοδοτείται με 5-35 v dc',
        'icon': 'published_with_changes',
        'image': '0'
    },
    {
        'id': 'Διορθώσεις στην εξαγωγή των δεδομένων',
        'title': 'Βασικές έννοιες για το Θερμοκήπειο',
        'content': 'Έτσι με το hubzero μέσο της πλατφόρμας sensorbase.io  και την\n' +
            'αμφίδρομη επικοινωνία η συσκευή ενημερώνει την κατάστασή σε πραγματικό\n' +
            'χρόνο για ην θερμοκρασία , υγρασία ,ενεργοποίηση – απενεργοποίηση\n' +
            'θέρμανσης, κλιματισμού, ποτίσματος, Φωτισμού ,Πισίνας, θερμοσίφωνα,\n' +
            'ανεμοδείκτη , καυστήρα ρολών, Γκαραζόπορτας παροχής ΔΕΗ και γενικά\n' +
            'συσκευών ρεύματος\n' +
            'Αγόρασε το sensorbase hubzero. και δημιούργησε σενάρια κάνε τους\n' +
            'δικούς σου αυτοματισμούς με τον πιο απλό τρόπο άμεσα και εύκολα με την\n' +
            'πλατφόρμα sensorbase.io\n' +
            'Για την εγκατάσταση της συσκευής απαιτείται επίσκεψη τεχνικού\n' +
            '(αδειοδοτημένου) ηλεκτρολόγου εγκαταστάτη\n' +
            'Η συσκευή τοποθετείται είτε σε ράγα ηλεκτρικού πινάκα , ειτε με επιτιχεια\n' +
            'τροφοδοτείται με 5-35 v dc',
        'icon': 'fa_duotone:tools',
        'image': '0'
    },
    {
        'id': 'Επιλογή Εικονιδίων σε όλες τις Ξηρές Επαφές',
        'title': 'Βασικές έννοιες για το πως θα κάνεις αυτη την εφαρμογή της Καταγραφή Ψυγείων',
        'content': 'Έτσι με το hubzero μέσο της πλατφόρμας sensorbase.io και την\n' +
            'αμφίδρομη επικοινωνία η συσκευή ενημερώνει την κατάστασή σε πραγματικό\n' +
            'χρόνο για ην θερμοκρασία , υγρασία ,ενεργοποίηση – απενεργοποίηση\n' +
            'θέρμανσης, κλιματισμού, ποτίσματος, Φωτισμού ,Πισίνας, θερμοσίφωνα,\n' +
            'ανεμοδείκτη , καυστήρα ρολών, Γκαραζόπορτας παροχής ΔΕΗ και γενικά\n' +
            'συσκευών ρεύματος\n' +
            'Αγόρασε το sensorbase hubzero. και δημιούργησε σενάρια κάνε τους\n' +
            'δικούς σου αυτοματισμούς με τον πιο απλό τρόπο άμεσα και εύκολα με την\n' +
            'πλατφόρμα sensorbase.io\n' +
            'Για την εγκατάσταση της συσκευής απαιτείται επίσκεψη τεχνικού\n' +
            '(αδειοδοτημένου) ηλεκτρολόγου εγκαταστάτη\n' +
            'Η συσκευή τοποθετείται είτε σε ράγα ηλεκτρικού πινάκα , ειτε με επιτιχεια\n' +
            'τροφοδοτείται με 5-35 v dc',
        'icon': 'published_with_changes',
        'image': '<img class="image" src="assets/images/images/hubzero4.jpg">' +
            '<img class="image" src="assets/images/images/temp.jpg">' +
            '<img class="image" src="assets/images/images/door.jpg">'
    },
    {
        'id': 'Επεξεργασία Προφίλ',
        'title': 'Βασικές έννοιες για το Θερμοκήπειο',
        'content': 'Έτσι με το hubzero μέσο της πλατφόρμας sensorbase.io  και την\n' +
            'αμφίδρομη επικοινωνία η συσκευή ενημερώνει την κατάστασή σε πραγματικό\n' +
            'χρόνο για ην θερμοκρασία , υγρασία ,ενεργοποίηση – απενεργοποίηση\n' +
            'θέρμανσης, κλιματισμού, ποτίσματος, Φωτισμού ,Πισίνας, θερμοσίφωνα,\n' +
            'ανεμοδείκτη , καυστήρα ρολών, Γκαραζόπορτας παροχής ΔΕΗ και γενικά\n' +
            'συσκευών ρεύματος\n' +
            'Αγόρασε το sensorbase hubzero. και δημιούργησε σενάρια κάνε τους\n' +
            'δικούς σου αυτοματισμούς με τον πιο απλό τρόπο άμεσα και εύκολα με την\n' +
            'πλατφόρμα sensorbase.io\n' +
            'Για την εγκατάσταση της συσκευής απαιτείται επίσκεψη τεχνικού\n' +
            '(αδειοδοτημένου) ηλεκτρολόγου εγκαταστάτη\n' +
            'Η συσκευή τοποθετείται είτε σε ράγα ηλεκτρικού πινάκα , ειτε με επιτιχεια\n' +
            'τροφοδοτείται με 5-35 v dc',
        'icon': 'published_with_changes',
        'image': '0'
    },
    {
        'id': 'Ρυθμίσεις Συσκευών & Αισθητήρων',
        'title': 'Βασικές έννοιες για τους Ανεμομείκτες',
        'content': 'Έτσι με το hubzero μέσο της πλατφόρμας sensorbase.io  και την\n' +
            'αμφίδρομη επικοινωνία η συσκευή ενημερώνει την κατάστασή σε πραγματικό\n' +
            'χρόνο για ην θερμοκρασία , υγρασία ,ενεργοποίηση – απενεργοποίηση\n' +
            'θέρμανσης, κλιματισμού, ποτίσματος, Φωτισμού ,Πισίνας, θερμοσίφωνα,\n' +
            'ανεμοδείκτη , καυστήρα ρολών, Γκαραζόπορτας παροχής ΔΕΗ και γενικά\n' +
            'συσκευών ρεύματος\n' +
            'Αγόρασε το sensorbase hubzero. και δημιούργησε σενάρια κάνε τους\n' +
            'δικούς σου αυτοματισμούς με τον πιο απλό τρόπο άμεσα και εύκολα με την\n' +
            'πλατφόρμα sensorbase.io\n' +
            'Για την εγκατάσταση της συσκευής απαιτείται επίσκεψη τεχνικού\n' +
            '(αδειοδοτημένου) ηλεκτρολόγου εγκαταστάτη\n' +
            'Η συσκευή τοποθετείται είτε σε ράγα ηλεκτρικού πινάκα , ειτε με επιτιχεια\n' +
            'τροφοδοτείται με 5-35 v dc',
        'icon': 'published_with_changes',
        'image': '0'
    }
];


