import {Component, OnDestroy, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {fuseAnimations} from '@fuse/animations';

import {IMqttMessage} from 'ngx-mqtt';
import {MatDialogRef, MatDialog, MatDialogConfig} from '@angular/material/dialog';

// import { SensorbaseConfirmDialog, SensorSettingsListDialog, DeviceSettingsDialog } from '@sensorbase/components';
import {Device} from '@sensorbase/models';
import {SensorsService, DevicesService} from '@sensorbase/services';

import {Sensor} from '@sensorbase/models';
import {FuseConfirmationConfig, FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {sbAnimations} from '../../../animations';
import {SensorSettingsListDialog} from '../../widgets';
import {DeviceSettingsDialog, HttpProgressDialog, SensorbaseConfirmDialog} from '../../dialogs';
import {SbSensorUtils} from '../../../utils';

@Component({
    selector: 'device-details-sidebar',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    animations: [fuseAnimations, sbAnimations]
})
export class DeviceDetailsSidebarComponent implements OnInit, OnDestroy {

    @Input() showClose: boolean = false;

    @Output()
    closed: EventEmitter<any> = new EventEmitter();

    selected: any;
    selectedTmp: any;
    sensors: any[] = [];
    rssi: number;
    state = 'view';

    rssiIcon = 'fa_duotone:signal-alt-slash';

    // Private
    private _unsubscribeAll: Subject<any>;
    private _unsubscribeSensorSubs: Subject<any>;

    /**
     * Constructor
     *
     * @param _devicesService
     * @param _sensorsService
     * @param _matDialog
     * @param _fuseConfirmationService
     * @param _translateService
     */
    constructor(
        private _devicesService: DevicesService,
        private _sensorsService: SensorsService,
        public _matDialog: MatDialog,
        private _fuseConfirmationService: FuseConfirmationService,
        private _translateService: TranslocoService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._unsubscribeSensorSubs = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._devicesService.device$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                console.log('Device select received: ', selected);
                if (!selected) {
                    return;
                }
                this.unsubscribeSubs();
                this.selected = new Device(selected);
                this.rssi = NaN;
                this._sensorsService.getSensorsForDevice(this.selected.deviceId)
                    .subscribe(sensors => {
                        console.log(sensors);
                        this.sensors = sensors;
                        this.sensors.forEach((sensor, index) => {
                            this._sensorsService.subSensorData(sensor.sensorId)
                                .pipe(takeUntil(this._unsubscribeSensorSubs))
                                .subscribe((message: IMqttMessage) => {});
                        });
                    });

                this._sensorsService.subSensorData(this.selected.deviceId + ':RSSI')
                    .pipe(takeUntil(this._unsubscribeSensorSubs))
                    .subscribe((message: IMqttMessage) => {
                        // console.log('Data Received: '+this.sensor.sensorId , message.payload.toString());
                        this.rssi = parseFloat(message.payload.toString());
                        if (!this.rssi) {
                            this.rssiIcon = 'fa_duotone:signal-alt-slash';
                        } else if (this.rssi < -90) {
                            this.rssiIcon = 'fa_duotone:signal-alt-1';
                        } else if (this.rssi < -80) {
                            this.rssiIcon = 'fa_duotone:signal-alt-3';
                        } else if (this.rssi < -70) {
                            this.rssiIcon = 'fa_duotone:signal-alt-3';
                        } else {
                            this.rssiIcon = 'fa_duotone:signal-alt';
                        }
                        console.log(this.rssi);
                    });
                console.log(this.selected);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeSensorSubs.next(null);
        this._unsubscribeSensorSubs.complete();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._devicesService.selectDevice(null);
    }

    unsubscribeSubs(): void {
        this._unsubscribeSensorSubs.next(null);
    }

    toggleNotifications(): void {
        this.selected.notifications = !this.selected.notifications;
        this._devicesService.updateDevice(this.selected);
    }

    onLocationChanged(device: any): void {
        this.selected = device;
        this._devicesService.updateDevice(this.selected).subscribe();
    }

    onEditButtonClicked(): void {
        this.state = 'edit';
        this.selectedTmp = new Device(this.selected);
    }

    onSave(): void {
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });

        this.selected = this.selectedTmp;
        this._devicesService.updateDevice(this.selected)
            .subscribe({
                next: () => {
                    httpProgressDialog.componentInstance.state = 'success';
                    httpProgressDialog.afterClosed().subscribe(() => {});
                },
                error: (err) => {
                    httpProgressDialog.componentInstance.state = 'error';
                }
            });
        this.state = 'view';
        // this.state =
    }

    onCancel(): void {
        this.state = 'view';
    }

    openSensorSettings(sensor: Sensor): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'sensor-settings-list-dialog';

        let dialogRef;
        this._devicesService.getDevice(sensor.deviceId)
            .subscribe((response) => {
                const device = new Device(response);
                dialogConfig.data = {
                    sensor: sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorSettingsListDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        sensor = result;
                        this._sensorsService.updateSensor(sensor);
                    }
                });
            });
    }

    onRestartButtonClicked(): void {
        this._devicesService.restartDevice(this.selected.deviceId);
    }

    onUpdateButtonClicked(): void {
        const devDeleteDialogRef = this._matDialog.open(SensorbaseConfirmDialog, {
            disableClose: false,
            autoFocus: false,
            panelClass: 'confirm-dialog',
            data: {
                translateParams: this.selected,
                warn: true
            }
        });
        devDeleteDialogRef.componentInstance.title = 'DEVICES.UPDATE_DEVICE_DIALOG.TITLE';
        devDeleteDialogRef.componentInstance.message = 'DEVICES.UPDATE_DEVICE_DIALOG.MESSAGE';
        devDeleteDialogRef.componentInstance.confirmText = 'CONFIRM_DIALOG.BUTTONS.CONFIRM';
        devDeleteDialogRef.componentInstance.cancelText = 'CONFIRM_DIALOG.BUTTONS.CANCEL';

        devDeleteDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._devicesService.getLatestFirmware(this.selected.deviceId)
                    .subscribe(response => {
                        let version;
                        try {
                            version = {
                                MODEL: response.model,
                                HW_VERSION: response.hwVersion,
                                FW_VERSION: response.fwVersionLatest
                            };
                        } catch (e) {
                            version = {};
                        }
                        this._devicesService.updateDeviceFirware(this.selected.deviceId, JSON.stringify(version));
                    });
            }
        });
    }

    onDeleteButtonClicked(): void {
        const dialogConfig: FuseConfirmationConfig = {
            title: this._translateService.translate('DEVICES.DELETE_DEVICE_DIALOG.TITLE'),
            message: this._translateService.translate('DEVICES.DELETE_DEVICE_DIALOG.MESSAGE', this.selected),
            icon: {
                name: 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CONFIRM'),
                    color: 'warn'
                },
                cancel: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CANCEL')
                }
            },
            dismissible: true
        };

        const dialogRef = this._fuseConfirmationService.open(dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
                if (result === 'confirmed') {
                    this._devicesService.deleteDevice(this.selected.deviceId).subscribe({
                            next: (res) => {
                                console.log('Device deleted');
                            },
                            error: (error) => {
                                console.log('Error deleting device', error);
                            }
                        }
                    );
                }
            }
        );
    }

    onSettingsButtonClicked(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'device-settings-dialog';

        dialogConfig.data = this.selected;

        const dialogRef = this._matDialog.open(DeviceSettingsDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.selected = result;
            }
        });
    }

    onClose(): void {
        this._devicesService.selectDevice(null);
        this.closed.emit(null);
    }

    getSensorValue(sensor: Sensor): string {
        return SbSensorUtils.getSensorValue(sensor);
    }
}
