<mat-card>
    <div class='days-container' [ngClass]='{"disabled": ngDisabled===true}' fxLayout="column">

        <div fxFlex="1 0 auto" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
                <div *ngFor="let i of numbers"
                     class='day-circle' [ngClass]='{"is-selected": days[i].isSelected}'
                     (click)="onDayClicked(i)">
                    <span [transloco]="days[i].name"></span>
                </div>
            </div>
            <mat-slide-toggle class="ml-6" aria-label="All day" color="primary"
                              [(ngModel)]="allSelected" (change)="toggleAll($event)">
                {{'WEEKDAY.ALL_DAYS' | transloco}}
            </mat-slide-toggle>
        </div>

    </div>


</mat-card>
