import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {APIV2_BASE_URL} from 'environments/environment';
import {AuthenticationService} from '@sensorbase/services';
import {Browser} from 'leaflet';
import {FormGroup, FormArray, Validators, FormControl} from '@angular/forms';
import {DateTime} from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class SensorAutomationService {

    functions: any[];
    onFunctionsChanged: BehaviorSubject<any>;

    constructor(private _httpClient: HttpClient,
                private _authService: AuthenticationService) {
        this.onFunctionsChanged = new BehaviorSubject([]);
    }

    _getSensorFunctions(sensorId): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'userFunctions?sensorId=' + sensorId);
    }

    _getEventGroups(functionId): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'eventGroups?functionId=' + functionId);
    }

    _getSensorActions(sensorId): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'userActions?sensor=' + sensorId);
    }

    addFunction(func): Observable<any> {
        const funcApi = func.toApi();
        const userId = this._authService.getUserId();
        funcApi.userId = userId;
        return this._httpClient.post(APIV2_BASE_URL + 'userFunctions', funcApi);
    }

    updateFunction(func): Observable<any> {
        const funcApi = func.toApi();
        const userId = this._authService.getUserId();
        funcApi.userId = userId;
        return this._httpClient.post(APIV2_BASE_URL + 'userFunctions', funcApi);
    }

    addSchedule(schedule): Observable<any> {
        const scheduleApi = schedule.toApi();
        const userId = this._authService.getUserId();
        scheduleApi.userId = userId;
        return this._httpClient.post(APIV2_BASE_URL + 'schedules', scheduleApi);
    }

    addEvent(event: SensorEvent, schedules): Observable<any> {
        const eventApi = event.toApi();
        const userId = this._authService.getUserId();
        eventApi.userId = userId;
        return this._httpClient.post(APIV2_BASE_URL + 'userEvents', eventApi);
    }

    addEventGroup(eventGroup: EventGroup, functionId): Observable<any> {
        const eventGroupApi = eventGroup.toApi(functionId);
        const userId = this._authService.getUserId();
        eventGroupApi.userId = userId;
        return this._httpClient.post(APIV2_BASE_URL + 'eventGroups', eventGroupApi);
    }

    updateSchedule(schedule): Observable<any> {
        const scheduleApi = schedule.toApi();
        const userId = this._authService.getUserId();
        scheduleApi.userId = userId;
        return this._httpClient.put(APIV2_BASE_URL + 'schedules/' + schedule.id, scheduleApi);
    }

    saveFunction(func): Promise<any> {
        if (func.apiAction === 'add') {
            return this.addFunction(func).toPromise();
        } else {
            // if (func.apiAction === 'update') {
            return this.updateFunction(func).toPromise();
        }
        // return new Promise((resolve, reject) => {
        //     console.log(func);
        //     if (func.apiAction === 'add') {
        //         this.addFunction(func).toPromise().then(response => {
        //             resolve(response);
        //         });
        //         // this.addSchedule(func.schedule).toPromise()
        //         //     .then(response => {
        //         //         func.schedule = Schedule.fromApi(response);
        //         //
        //         //     })
        //     }
        //     else {
        //         // if (func.apiAction === 'update') {
        //         this.updateFunction(func).toPromise().then(response => {
        //             resolve(response);
        //         });
        //     }
        // })
    }

}

export interface ISensorAction {
    id?: number;
    userId?: string;
    name?: string;
    type?: string;
    params?: string;
    internalActionSensor?: string;
    internal?: string;
    apiAction?: string;
}

export class SensorAction implements ISensorAction {
    id: number;
    userId: string;
    name: string;
    type: string;
    params: string;
    internalActionSensor: string;
    internal: string;
    apiAction: string;

    constructor(data: ISensorAction) {
        this.id = data.id || 0;
        this.userId = data.userId || '';
        this.name = data.name || '';
        this.type = data.type || null;
        this.params = data.params || null;
        this.internalActionSensor = data.internalActionSensor || null;
        this.internal = data.internal || null;
        this.apiAction = data.apiAction || '';
    }
}

export interface ISchedule {
    id?: number;
    userId?: string;
    type?: string;
    name?: string;
    months?: any[];
    dayOfWeek?: any[];
    repeatStart?: DateTime;
    repeatNo?: number;
    startTime?: string;
    endTime?: string;
    timezone?: string;
    apiAction?: string;
}

export class Schedule implements ISchedule {
    id: number;
    userId: string;
    type: string;
    name: string;
    months: any[];
    dayOfWeek: any[];
    repeatStart: DateTime;
    repeatNo: number;
    startTime: string;
    endTime: string;
    timezone: string;
    apiAction: string;

    constructor(data: ISchedule) {
        this.id = data.id || 0;
        this.userId = data.userId || '';
        this.type = data.type || 'FULL';
        this.name = data.name || '';
        this.months = data.months || null;
        this.dayOfWeek = data.dayOfWeek || null;
        this.repeatStart = data.repeatStart || null;
        this.repeatNo = data.repeatNo || null;
        this.startTime = data.startTime || null;
        this.endTime = data.endTime || null;
        this.timezone = data.timezone || 'Europe/Athens';
        this.apiAction = data.apiAction || null;
    }

    static fromApi(dataApi): Schedule {
        const data: ISchedule = {
            id: dataApi.id,
            type: dataApi.type,
            name: dataApi.name,
            months: dataApi.months || [],
            dayOfWeek: dataApi.dayOfWeek || [],
            repeatStart: dataApi.repeatStart ? DateTime.fromISO(dataApi.repeatStart) : null,
            repeatNo: dataApi.repeatNo || null,
            startTime: dataApi.startTime ? dataApi.startTime.substring(0, 5) : null,
            endTime: dataApi.endTime ? dataApi.endTime.substring(0, 5) : null,
            timezone: dataApi.timezone,
            apiAction: dataApi.apiAction || null
        };
        return new Schedule(data);
    }

    toApi(): any {
        let repeatStart = null;
        if (this.repeatStart) {
            repeatStart = this.repeatStart.toFormat('yyyy-MM-dd');
        }
        return {
            id: this.id,
            userId: this.userId,
            type: this.type,
            name: this.name,
            months: this.months,
            dayOfWeek: this.dayOfWeek,
            repeatStart: repeatStart,
            repeatNo: this.repeatNo,
            startTime: this.startTime,
            timezone: this.timezone,
            endTime: this.endTime,
            apiAction: this.apiAction
        };
    }

    toFormGroup(): FormGroup {
        const controls = {};
        Object.entries(this).forEach(([key, value]) => {
            controls[key] = new FormControl(value);
        });
        return new FormGroup(controls);
    }

}

export interface ISensorEvent {
    id?: number;
    userId?: string;
    sensorId?: string;
    operator?: string;
    objectType?: string;
    value?: number;
    delay?: number;
    apiAction?: string;
}

export class SensorEvent implements ISensorEvent {
    id: number;
    userId: string;
    sensorId: string;
    objectType: string;
    operator: string;
    value: number;
    delay: number;
    apiAction: string;

    constructor(data: ISensorEvent) {
        this.id = data.id || 0;
        this.userId = data.userId || '';
        this.sensorId = data.sensorId || null;
        this.objectType = data.objectType || 'SENSOR';
        this.operator = data.operator || null;
        this.value = (data.value == null) ? null : data.value;
        this.delay = data.delay || 0;
        this.apiAction = data.apiAction || null;
        if (this.objectType === 'DUMMY') {
            this.operator = '==';
        }
    }

    // a
    // b
    // c
    // d
    //
    // ((a & b) | (c | d))
    static fromApi(dataApi): SensorEvent {
        const logic = JSON.parse(dataApi.logic);
        const operator = Object.keys(logic)[0];
        const data: ISensorEvent = {
            id: dataApi.id,
            userId: dataApi.userId,
            sensorId: dataApi.objectId,
            objectType: dataApi.objectType,
            operator: operator,
            value: logic[operator][1],
            delay: dataApi.triggerDelay
        };
        return new SensorEvent(data);
    }

    toApi(): any {
        const operator = this.operator;
        const logic = {};
        if (this.objectType === 'DUMMY') {
            logic[operator] = [1, 1];
        } else {
            logic[operator] = [{'var': 'data.data.current'}, this.value];
        }
        return {
            id: this.id,
            userId: this.userId,
            objectType: this.objectType,
            logic: JSON.stringify(logic),
            objectId: this.sensorId,
            triggerDelay: this.delay,
            name: '',
            schedules: [],
            apiAction: this.apiAction
        };
    }

    toFormGroup(): FormGroup {
        if (this.objectType === 'SENSOR'){
            return new FormGroup({
                id: new FormControl(this.id),
                sensorId: new FormControl(this.sensorId, Validators.required),
                objectType: new FormControl(this.objectType),
                operator: new FormControl(this.operator, Validators.required),
                value: new FormControl(this.value, Validators.required),
                delay: new FormControl(this.delay),
                apiAction: new FormControl(this.apiAction)
            });
        } else {
            return new FormGroup({
                id: new FormControl(this.id),
                sensorId: new FormControl(this.sensorId),
                objectType: new FormControl(this.objectType),
                operator: new FormControl(this.operator, Validators.required),
                value: new FormControl(this.value),
                delay: new FormControl(this.delay),
                apiAction: new FormControl(this.apiAction)
            });
        }

    }
}

export interface IEventGroup {
    id?: number;
    name?: string;
    userId?: string;
    functionId?: number;
    events?: SensorEvent[];
    operator?: string;
    schedules?: Schedule[];
    apiAction?: string;
}

export class EventGroup implements IEventGroup {
    id: number;
    userId: string;
    functionId?: number;
    name: string;
    events: SensorEvent[];
    operator: string;
    schedules: Schedule[];
    apiAction: string;

    constructor(data: IEventGroup) {
        this.id = data.id || 0;
        this.userId = data.userId || '';
        this.functionId = data.functionId || null;
        this.name = data.name || '';
        this.events = data.events || [];
        this.operator = data.operator || 'and';
        this.schedules = data.schedules || [];
        this.apiAction = data.apiAction || null;
    }

    static fromApi(dataApi): EventGroup {
        const data = {
            id: dataApi.id,
            userId: dataApi.userId,
            functionId: dataApi.userId,
            name: dataApi.name,
            events: dataApi.events.map(x => SensorEvent.fromApi(x)),
            operator: dataApi.operator,
            schedules: dataApi.schedules.map(x => Schedule.fromApi(x))
        };
        return new EventGroup(data);
    }


    toApi(functionId): any {
        return {
            id: this.id,
            functionId: functionId,
            userId: this.userId,
            name: this.name,
            events: this.events.map(e => e.toApi()),
            operator: this.operator,
            apiAction: this.apiAction,
            schedules: this.schedules.map(x => x.toApi())
        };
    }

    toFormGroup(): FormGroup {
        const eventsFormArray = new FormArray([]);
        this.events.forEach(event => {
            eventsFormArray.push(event.toFormGroup());
        });
        const schedulesFormArray = new FormArray([]);
        this.schedules.forEach(schedule => {
            schedulesFormArray.push(schedule.toFormGroup());
        });
        let type = null;
        if (this.events.length && this.schedules.length){
            type = 'both';
        }
        else if (this.events.length){
            type = 'sensor';
        }
        else if (this.schedules.length){
            type = 'schedule';
        }
        return new FormGroup({
            id: new FormControl(this.id),
            name: new FormControl(this.name, Validators.required),
            operator: new FormControl(this.operator, Validators.required),
            events: eventsFormArray,
            schedules: schedulesFormArray,
            apiAction: new FormControl(this.apiAction),
            type: new FormControl(type, Validators.required)
        });
    }
}

export interface ISensorFunction {
    id?: number;
    userId?: string;
    name?: string;
    sensorId?: string;
    actions?: SensorAction[];
    inactiveActions?: SensorAction[];
    active?: boolean;
    enabled?: boolean;
    schedule?: Schedule;
    eventGroups?: EventGroup[];
    apiAction?: string;
    notes?: string;
}

export class SensorFunction implements ISensorFunction {
    id: number;
    userId: string;
    name: string;
    sensorId: string;
    actions: SensorAction[];
    inactiveActions: SensorAction[];
    active: boolean;
    enabled: boolean;
    schedule: Schedule;
    eventGroups: EventGroup[];
    apiAction?: string;
    notes?: string;

    constructor(data: ISensorFunction) {
        this.id = data.id || 0;
        this.userId = data.userId || '';
        this.sensorId = data.sensorId || null;
        this.name = data.name || '';
        this.schedule = data.schedule || null;
        this.eventGroups = data.eventGroups || [];
        this.actions = data.actions || [];
        this.inactiveActions = data.inactiveActions || [];
        this.active = data.active || false;
        this.enabled = data.enabled || false;
        this.apiAction = data.apiAction || null;
        this.notes = data.notes || '';
    }

    static fromApi(dataApi): SensorFunction {
        const data = {
            id: dataApi.id,
            userId: dataApi.userId,
            sensorId: dataApi.sensorId,
            name: dataApi.name,
            schedule: Schedule.fromApi(dataApi.schedule),
            eventGroups: dataApi.eventGroups.map(x => EventGroup.fromApi(x)),
            actions: dataApi.actions.map(x => new SensorAction(x)),
            inactiveActions: dataApi.inactiveActions.map(x => new SensorAction(x)),
            active: dataApi.active,
            enabled: dataApi.enabled,
            apiAction: dataApi.apiAction,
            notes: dataApi.notes
        };
        // data.schedule = Schedule.fromApi(dataApi.schedule);
        // data.actions = dataApi.actions.map(x=>new SensorAction(x));
        // data.inactiveActions = dataApi.inactiveActions.map(x=>new SensorAction(x));
        // data.eventGroups = dataApi.eventGroups.map(x=>EventGroup.fromApi(x, sensor));
        return new SensorFunction(data);
    }

    toApi(): any {
        return {
            id: this.id,
            userId: this.userId,
            name: this.name,
            sensorId: this.sensorId,
            schedule: this.schedule.toApi(),
            actions: this.actions,
            inactiveActions: this.inactiveActions,
            eventGroups: this.eventGroups.map(x => x.toApi(this.id)),
            enabled: this.enabled,
            apiAction: this.apiAction,
            notes: this.notes
        };
    }

    toFormGroup(): FormGroup {
        const controls = [];
        this.eventGroups.forEach(eventGroup => {
            controls.push(eventGroup.toFormGroup());
        });
        return new FormGroup({
            id: new FormControl(this.id),
            name: new FormControl(this.name, Validators.required),
            notes: new FormControl(this.notes),
            eventGroups: new FormArray(controls),
            apiAction: new FormControl(this.apiAction)
        });
    }
}
