import { Pipe, PipeTransform } from '@angular/core';
import {UpperCasePipe} from '@angular/common';

@Pipe({
    name: 'sbuppercase',
    pure: false
})
export class SbUpperCasePipe extends UpperCasePipe implements PipeTransform  {

    transform(text: string): any {
        let result = super.transform(text);
        const search = ['Ά', 'Έ', 'Ί', 'Ή', 'Ύ', 'Ό', 'Ώ'];
        const replace = ['Α', 'Ε', 'Ι', 'Η', 'Υ', 'Ο', 'Ω'];

        search.forEach( (c, i) => {
           result = result.replace(c, replace[i]);
        });

        return result;
    }

}