<!-- No sensors found message -->
<ng-container *ngIf="sensors.length === 0 && gridInitialised">
    <div class="pt-2 pb-2 h-2/3" fxLayout="column" fxLayoutAlign="center center">
        <div class="mat-display-1">
            <strong class="text-warn">
                {{'GENERIC.NO_SENSORS_FOUND'  | transloco}}
            </strong>
        </div>
    </div>
</ng-container>
<!-- / No sensors found message -->

<div class="example-loading-shade"
     *ngIf="!gridInitialised && sensors.length > 0">
    <mat-spinner color="accent"></mat-spinner>
</div>

<!-- Muuri -->
<div class="grid sm:ml-0" #grid>
    <div class="item" [ngClass.lt-sm]="'w-full max-w-full'" *ngFor="let sensor of sensors">
        <sensor-widget class="item-content" [ngClass.lt-sm]="'w-full max-w-full'" [sensor]="sensor" (ready)="onChildReady()"
                       [size]="widgetSize"
                       (favouriteToggled)="onChildFavouriteToggled()" (deleted)="onSensorDeleted(sensor)">
        </sensor-widget>
    </div>
</div>

