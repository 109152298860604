import {Component, OnInit, Inject, ViewEncapsulation, OnDestroy} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';

import {Sensor, Device} from '@sensorbase/models';
import {DevicesService, SensorsService} from '@sensorbase/services';
import {
    SensorGeneralSettingsDialog,
    SensorAutomationsSettingDialog,
    SensorNotificationSettingsDialog,
    SensorTimerSettingsDialog
} from '@sensorbase/components';
import {Subject, take, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '../../../../../../../@fuse/services/media-watcher';


@Component({
    selector: 'sensor-settings-list-dialog',
    templateUrl: './sensor-settings-list-dialog.component.html',
    styleUrls: ['./sensor-settings-list-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SensorSettingsListDialog implements OnInit, OnDestroy {

    device: Device;
    sensor: Sensor;
    isScreenSmall = false;

    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _devicesService: DevicesService,
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<SensorSettingsListDialog>,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _sensorsService: SensorsService,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.sensor = data.sensor;
        this.device = data.device;

        console.log(this.sensor);
        console.log(this.device);

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode and drawerOpened
                this.isScreenSmall = !matchingAliases.includes('sm');
            });
    }

    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    openGeneralSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = ['sensor-general-settings-dialog'];
        if (this.isScreenSmall){
            dialogConfig.height = '100%';
            dialogConfig.width = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.panelClass.push('small-screen');
        }
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((response) => {
                const device = new Device(response);
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorGeneralSettingsDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openNotificationSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = ['sensor-notification-settings-dialog'];
        if (this.isScreenSmall){
            dialogConfig.height = '100%';
            dialogConfig.width = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.panelClass.push('small-screen');
        }

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((response) => {
                const device = new Device(response);
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorNotificationSettingsDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        this.sensor = result;
                        this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openTimerSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = ['sensor-timer-settings-dialog'];
        if (this.isScreenSmall){
            dialogConfig.height = '100%';
            dialogConfig.width = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.panelClass.push('small-screen');
        }

        let dialogRef;
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((response) => {
                const device = new Device(response);
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device
                };
                dialogRef = this._matDialog.open(SensorTimerSettingsDialog, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // console.log('The dialog was closed');
                        // this.sensor = result;
                        // this._sensorsService.updateSensor(this.sensor);
                    }
                });
            });
    }

    openAutomationsSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = ['sensor-automations-settings-dialog'];
        if (this.isScreenSmall){
            dialogConfig.height = '100%';
            dialogConfig.width = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.panelClass.push('small-screen');
        }

        let dialogRef;
        this._devicesService.devices$.pipe(take(1)).subscribe(devices => {
            const device = devices.find(x => x.deviceId === this.sensor.deviceId);
            dialogConfig.data = {
                sensor: this.sensor,
                device: device
            };
            dialogRef = this._matDialog.open(SensorAutomationsSettingDialog, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    // console.log('The dialog was closed');
                    this.sensor = result;
                    this._sensorsService.updateSensor(this.sensor);
                }
            });
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
