import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    filter,
    map,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    throwError
} from 'rxjs';
import { UserService } from 'app/core/user/user.service';

import {API_BASE_URL, AUTH_API_BASE_URL} from 'environments/environment';
import {IAuthResponse, IAuthUser, IRegisterUser} from '@sensorbase/models/authentication.model';
import { AuthUtils } from '@sensorbase/utils';

@Injectable()
export class AuthenticationService
{
    private _authenticated: boolean = false;
    private _onAuthenticationEvent: BehaviorSubject<any> = new BehaviorSubject([]);
    onAuthenticated = this._onAuthenticationEvent.pipe(filter(event => event === true), shareReplay(1));
    onAuthenticationEvent = this._onAuthenticationEvent.asObservable().pipe(shareReplay(1));
    private authFail = false;
    private refreshInProgress = false;
    private _refreshEvent: Subject<any> = new Subject();
    private onRefreshToken = this._refreshEvent.asObservable();

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    get accessToken(): string
    {
        if (!localStorage.getItem('sbSession')) {
            return '';
        }
        return JSON.parse(localStorage.getItem('sbSession')).token;
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public static getMqttToken(): string {
        if (!localStorage.getItem('sbSession')) {
            return localStorage.getItem('sbSession');
        }
        return JSON.parse(localStorage.getItem('sbSession')).token;
    }

    public static getTokenTimeStamp(): number {
        if (!localStorage.getItem('sbSession')) {
            return Number(localStorage.getItem('sbSession'));
        }
        return JSON.parse(localStorage.getItem('sbSession')).timestamp;
    }

    public static getUserId(): string {
        if (sessionStorage.getItem('ImpersonationUserId')) {
            return sessionStorage.getItem('ImpersonationUserId');
        } else {
            return JSON.parse(localStorage.getItem('sbSession')).userId;
        }
    }

    getUserId(): string {
        return AuthenticationService.getUserId();
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: IAuthUser): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError(() => 'User is already logged in.');
        }

        return this._httpClient.post(AUTH_API_BASE_URL + 'auth/authenticate', credentials).pipe(
            switchMap((response: IAuthResponse) => {

                // Store the access token in the local storage
                this.authFail = false;
                response.timestamp = Date.now();
                localStorage.setItem('sbSession', JSON.stringify(response));

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: IAuthResponse) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.token )
                {
                    response.timestamp = Date.now();
                    localStorage.setItem('sbSession', JSON.stringify(response));
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                // this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('sbSession');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: IRegisterUser): Observable<any>
    {
        return this._httpClient.post(API_BASE_URL + 'users/register', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    loggedIn(): boolean {
        const result = (localStorage.getItem('sbSession') !== null
            && (!AuthUtils.isTokenExpired(this.accessToken) ||
                AuthenticationService.getTokenTimeStamp() + (24 * 60 * 60000) > Date.now()) && !this.authFail);
        if (result) {
            this._onAuthenticationEvent.next(true);
        }
        return result;
    }

    tokenRefresher(delay: number): void {
        setTimeout(() => {
            this.tokenRefresher(15 * 60000);
            try {
                this.refreshToken().subscribe();
            } catch (err) {

            }
        }, delay);
    }

    public refreshToken(force?: boolean): Observable<any> {
        if (localStorage.getItem('sbSession') && !this.authFail) {
            console.log('Check Token Expiry');
            const storage = JSON.parse(localStorage.getItem('sbSession'));
            console.log('token age ', (Date.now() - storage.timestamp) / 1000);
            console.log('seconds till expiry', ((storage.timestamp + storage.expires) - Date.now()) / 1000);
            if (((storage.timestamp + storage.expires - 60 * 1000) < Date.now() || AuthUtils.isTokenExpired(storage.token)) || force) {
                console.log('Refreshing Token');
                return this.refreshTokenRequest();
            } else {
                throw throwError(() => 'Not time to refresh');
            }
        } else {
            throw throwError(() => 'no token stored');
        }
    }

    setAuthFailed(): void {
        this.authFail = true;
        localStorage.removeItem('sbSession');
        this._onAuthenticationEvent.next(false);
    }

    testAuth(): Observable<boolean> {
        return new Observable((observer) => {
            try {
                console.log('Testing Auth');
                if (this.accessToken) {
                    const sbSession = JSON.parse(localStorage.getItem('sbSession'));
                    if (AuthUtils.isTokenExpired(sbSession.token)) {
                        console.log('Try Refresh');
                        this.refreshToken(true).subscribe((authResponse) => {
                            this._onAuthenticationEvent.next(true);
                            observer.next(true);
                            observer.complete();
                        }, (error) => {
                            this.setAuthFailed();
                            observer.next(false);
                            observer.complete();
                        });
                    } else {
                        this._onAuthenticationEvent.next(true);
                        observer.next(true);
                        observer.complete();
                    }
                } else {
                    this.setAuthFailed();
                    observer.next(false);
                    observer.complete();
                }
            } catch (e) {
                this.setAuthFailed();
                console.log('Auth Failed');
                observer.next(false);
                observer.complete();
            }
        });


    }

    impersonateUser(userId): void {
        sessionStorage.setItem('ImpersonationUserId', userId);
    }

    stopImpersonation(): void {
        sessionStorage.removeItem('ImpersonationUserId');
    }

    private refreshTokenRequest(): Observable<any> {
        const storage = JSON.parse(localStorage.getItem('sbSession'));
        if (this.refreshInProgress === false) {
            this.refreshInProgress = true;
            return this._httpClient.post<any>(AUTH_API_BASE_URL + 'auth/refreshToken', storage, {headers: {'Authorization': 'Bearer ' + storage.token}})
                .pipe(map((authResponse: IAuthResponse) => {
                    // login successful if there's a jwt token in the response
                    if (authResponse && !AuthUtils.isTokenExpired(authResponse.token)) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        console.log('Token Refreshed');
                        authResponse.timestamp = Date.now();
                        localStorage.setItem('sbSession', JSON.stringify(authResponse));

                        this._onAuthenticationEvent.next(true);
                        this.refreshInProgress = false;
                        this._refreshEvent.next(authResponse);
                        this._refreshEvent.complete();
                        return authResponse;
                    } else {
                        return throwError(() => new Error('Invalid token'));
                    }

                }), catchError((error) => {
                    this._refreshEvent.error('Refresh token Failed');
                    this.refreshInProgress = false;
                    this.setAuthFailed();
                    console.log('Refresh token Failed ', error);
                    return throwError(() => error);
                }));
        } else {
            return this.onRefreshToken;
        }
    }

    verify(key): Observable<any> {
        return this._httpClient.post<any>(API_BASE_URL + 'users/verify', {'key': key});
    }
}
