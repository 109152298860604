import {BaseModel} from './base.model';
import {SensorType} from '../config/sensors.config';
import {SbSensorUtils} from '../utils/sensor.utils';

export interface ISensor {
    sensorId: string;
    sensorName: string;
    deviceId: string;
    type: SensorType;
    notifications: boolean;
    status: number;
    favourite: string;
    data: object;
    displayData: any;
    tags: string[];
    options: any;
}

export class Sensor extends BaseModel implements ISensor {
    public sensorId: string;
    public sensorName: string;
    public deviceId: string;
    public type: SensorType;
    public notifications: boolean;
    public status: number;
    public favourite: string;
    public data: object;
    public tags: string[];
    public options: any;

    constructor(data) {
        super();
        this.sensorId = data.sensorId;
        this.sensorName = data.sensorName;
        this.deviceId = data.deviceId;
        this.type = data.type;
        this.notifications = data.notifications;
        this.status = data.status;
        this.favourite = data.favourite;
        this.data = data.data;
        this.tags = data.tags;
        this.options = data.options || {};
    }

    static fromApi(dataApi): Sensor {
        const data = dataApi;
        data['type'] = SbSensorUtils.getSensorTypeFromLabel(data['type']);
        if (typeof data.options === 'string'){
            data.options = JSON.parse(data.options);
            if (data.options['offset'] == null){
                data.options['offset'] = 0;
            }
        }
        return new this(data);
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            if (key === 'options') {
                dataApi['options'] = JSON.stringify(value);
            } else {
                dataApi[key] = value;
            }
        });
        return dataApi;
    }

    getData(): number {
        return Math.round(this.data['current'] * 10) / 10;
    }

    getStatus(): number {
        return this.status;
    }

    getStatusStr(lang = 'el'): string {
        let status = '';
        const newSensor = (lang === 'el') ? 'Νέος Αισθητήρας' : 'New Sensor';
        const lostCon = (lang === 'el') ? 'Offline' : 'Lost Connection';
        switch (this.status) {
            case 1: {
                status = 'Online';
                break;
            }
            case 2: {
                status = newSensor;
                break;

            }
            case 0: {
                status = 'Offline';
                break;

            }
            case -1: {
                status = lostCon;
                break;
            }
            default: {
                status = 'Device Offline';
                break;
            }
        }
        return status;
    }

    getLastSeen(): Date {
        return new Date(this.data['timestamp']);
    }

    getLastSeenStr(): string {
        const datetime = new Date(this.data['timestamp']);
        return datetime.getDate() + '/' + datetime.getMonth() + '/' + datetime.getFullYear() + ' '
            + datetime.getHours() + ':' + datetime.getMinutes();
    }

    get displayData(): any {
        switch (this.type) {
            case SensorType.Relay: {
                if (this.data['status'] === 'PENDING') {
                    return this.data['target'] === 1;
                } else {
                    return this.data['current'] === 1;
                }
            }
            case SensorType.RSSI: {
                return this.status > 0 ? Math.round(this.data['current']).toString() : 'No Signal';
            }
            case SensorType.Switch: {
                let text = '';
                if (this.data['current'] === 1) {
                    text = this.options.openText || 'Επαφή Ανοιχτή';
                } else {
                    text = this.options.closedText || 'Επαφή Κλειστή';
                }
                return text;
            }
            default: {
                return Math.round(this.data['current'] * 10) / 10;
            }
        }
    }



}
