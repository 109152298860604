<mat-form-field [appearance]="appearance">
  <mat-label>{{_label}}</mat-label>
  <input matInput type="number" lang="en" [(ngModel)]="value" (focus)="setColor('primary')" (blur)="setColor('default')"
         matTooltip="{{_tooltip}}" (focusout)="onFocusOut()">
  <button mat-button
          mat-icon-button
          matSuffix
          longPress
          aria-label="Remove"
          type="button"
          class="custom-button"
          [disabled]="shouldDisableDecrement(_value)"
          [color]="getColor()"
          (click)="incrementValue(-_step)"
          (onLongPressing)="incrementValue(-_step)">
    <mat-icon svgIcon="remove"></mat-icon>
  </button>
  <button mat-button
          mat-icon-button
          matSuffix
          longPress
          aria-label="Add"
          type="button"
          class="custom-button"
          [color]="getColor()"
          [disabled]="shouldDisableIncrement(_value)"
          (click)="incrementValue(_step)"
          (onLongPressing)="incrementValue(_step)">
    <mat-icon svgIcon="add"></mat-icon>
  </button>
</mat-form-field>