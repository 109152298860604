<ng-container *ngIf="loaded">

    <div *ngIf="type==='eCharts'" class="gauge-wrapper">
        <div echarts class="gauge" [initOpts]="eChartsGauseInitOpts" [options]="eChartsGaugeOptions"
             (chartInit)="onChartInit($event)" #gauge>
        </div>
    </div>

    <div *ngIf="type==='ngx-gauge'" fxLayout="row" fxLayoutAlign="center center">
        <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="ngxGaugeOptions" thick="8"
                   [min]="min" [max]="max" size="130" cap="round" label="ένταση ρεύματος" append="Α">
        </ngx-gauge>
    </div>
</ng-container>

