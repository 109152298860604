import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';
import {Observable, Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


import {Contact, ContactLink, ContactLinkDetail, Device, Sensor} from '../../../models';
import {ContactLinksService, ContactsService, SensorsService} from '../../../services';
import {takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {
    ContactVerificationDialog,
    // ContactVerificationDialog,
    MultiSelectionDialog
} from '../../dialogs';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ContactLinksListMiniDialogComponent} from './contact-links-list-mini-dialog/contact-links-list-mini-dialog.component';
import {sbAnimations} from '../../../animations';
import {SbSensorUtils, SbNotificationUtils} from '@sensorbase/utils';

@Component({
    selector: 'contact-links-list',
    templateUrl: './contact-links-list.component.html',
    styleUrls: ['./contact-links-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fuseAnimations, sbAnimations]
})
export class ContactLinksListComponent implements OnInit, OnDestroy {

    @Input() object: Device | Sensor;
    @Input() onSave: Observable<any>;

    @ViewChild(MatPaginator, {static: false})
    paginator: MatPaginator;

    contacts: Contact[];
    contactLinks: ContactLink[];
    dataSource: any;
    displayedColumns = ['checkbox', 'name', 'sms', 'email', 'delay', 'more'];

    notificationTypes: any;

    // Checkbox states
    mainChecked = true;
    emailChecked = false;
    smsChecked = false;
    mainIndeterminate = false;
    emailIndeterminate = false;
    smsIndeterminate = false;

    sensorConditions: any;
    notifyDelay = 0;

    show = false;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _matDialog: MatDialog,
        private _sensorsService: SensorsService,
        private _coreContactsService: ContactsService,
        private _contactLinksService: ContactLinksService,
        private _changeDetectorRefs: ChangeDetectorRef
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    get sensor(): Sensor | null {
        if (this.object instanceof Sensor){
            return this.object;
        } else {
            return null;
        }
    }

    get device(): Device | null {
        if (this.object instanceof Device){
            return this.object;
        } else {
            return null;
        }
    }

    ngOnInit(): void {
        this.setupNotificationTypes();

        if (this.sensor) {
            this._sensorsService.getSensorConditions(this.sensor.sensorId)
                .subscribe(sensorConditions => {
                    console.log(sensorConditions);
                    this.sensorConditions = sensorConditions;
                    if (SbSensorUtils.isMinMaxSensor(this.sensor)){
                        const minMaxConditions = sensorConditions.find(
                                (element) => element.conditionType === 'MIN_MAX'
                            ).condition;
                            this.notifyDelay = minMaxConditions.notify_delay;
                    }
                    else {
                        const onOffConditions = sensorConditions.find(
                                (element) => element.conditionType === 'ON_OFF'
                            ).condition;
                            this.notifyDelay = onOffConditions.notify_delay;
                    }
                });

            this._contactLinksService.getContactLinks(this.sensor.sensorId);
            this._contactLinksService.onContactLinksChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(contactLinks => {
                    console.log(this.contactLinks);
                    this.contactLinks = contactLinks;
                    this.dataSource = new MatTableDataSource(this.contactLinks);
                    this.dataSource.paginator = this.paginator;
                    // console.log(this.contacts)
                    this.processCheckbox('MAIN');
                    this.processCheckbox('EMAIL');
                    this.processCheckbox('SMS');
                });
        } else {
            this._contactLinksService.getContactLinks(this.device.deviceId);
            this._contactLinksService.onContactLinksChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(contactLinks => {
                    console.log(this.contactLinks);
                    this.contactLinks = contactLinks;
                    this.dataSource = new MatTableDataSource(this.contactLinks);
                    this.dataSource.paginator = this.paginator;
                    // console.log(this.contacts)
                    this.processCheckbox('MAIN');
                    this.processCheckbox('EMAIL');
                    this.processCheckbox('SMS');
                });
        }

        this._coreContactsService.getContacts();
        this._coreContactsService.allContacts$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contacts => {
                this.contacts = contacts;
            });

        if (this.onSave) {
            this.onSave.subscribe(() => {
                this._contactLinksService.saveStuff();
            });
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    editMultiSelection(contactLink: ContactLink, notificationMedium: string): void {
        const details = this.getActiveDetails(contactLink, notificationMedium);
        console.log(details);
        const selectedItems = [];
        details.forEach(detail => {
            // console.log(this.getKeyByValue(NOTIFICATION_TYPES.sensor[this.sensor.type], detail.notification_type));
            let notificationTypes = [];
            if (this.sensor) {
                notificationTypes = SbNotificationUtils.getSensorNotificationTypes(this.sensor);
            } else {
                notificationTypes = SbNotificationUtils.getDeviceNotificationTypes();
            }
            selectedItems.push(this.getKeyByValue(notificationTypes, detail.notificationType));
        });
        console.log(selectedItems);
        const multiSelectionDialogRef = this._matDialog.open(MultiSelectionDialog, {
            panelClass: 'multi-selection-dialog',
            autoFocus: false,
            restoreFocus: false,
            // maxWidth: '30%',
            data: {
                selectionItems: this.notificationTypes,
                selectedItems: selectedItems
            }
        });

        multiSelectionDialogRef.afterClosed()
            .subscribe(newSelectedItems => {
                console.log(selectedItems);
                console.log(newSelectedItems);
                if (newSelectedItems) {
                    let objectType = '';
                    if (this.sensor) {
                        objectType = this.sensor.type;
                    } else {
                        objectType = 'device';
                    }
                    this._contactLinksService.updateLocalContactLinkDetails(newSelectedItems, selectedItems,
                                                                            contactLink, notificationMedium, objectType);
                }
                console.log(this.dataSource.data);
            });
    }

    getDetails(contactLink: ContactLink, notificationMedium: string): any[] {
        return contactLink.details.filter((detail) => detail.notificationMedium === notificationMedium);
    }

    getActiveDetails(contactLink: ContactLink, notificationMedium: string): ContactLinkDetail[] {
        return contactLink.details.filter((detail) => {
            return detail.notificationMedium === notificationMedium && (!detail.apiAction || detail.apiAction !== 'remove');
        });
    }

    getKeyByValue(object, value): string {
        return object.find((element) => element.value === value).key;
    }

    openContactVerifyDialog(contactLink: ContactLink, type): void {
        this._coreContactsService.getContact(contactLink.contactId)
            .subscribe((coreContact: Contact) => {

                const contactDetail = coreContact.contactDetails.find(detail => detail.type === type);

                const dialogRef = this._matDialog.open(ContactVerificationDialog, {
                    panelClass: 'contact-verification-dialog',
                    autoFocus: false,
                    restoreFocus: false,
                    data: {
                        contactDetail: contactDetail,
                        type: contactDetail.type
                    }
                });

            });
    }

    processCheckbox(type): void {
        switch (type) {
            case ('MAIN'): {
                const states = [];
                this.dataSource.data.forEach((contactLink: ContactLink) => {
                    states.push(contactLink.contactEnabled);
                });
                this.mainChecked = states.length > 0 && states.every(state => state === true);
                this.mainIndeterminate = !states.every(state => state === true) && !states.every(state => state === false);
                break;
                // this.mainChecked = this.checkChecked(type);
                // this.mainIndeterminate = this.checkIntermediate(type);
            }
            case ('EMAIL'): {
                const states = [];
                this.dataSource.data.forEach((contactLink: ContactLink) => {
                    if (contactLink.contactEnabled && contactLink.emailVerified) {
                        states.push(contactLink.emailEnabled);
                    }
                });
                this.emailChecked = states.length > 0 && states.every(state => state === true);
                this.emailIndeterminate = !states.every(state => state === true) && !states.every(state => state === false);
                // this.emailChecked = this.checkChecked(type);
                // this.emailIndeterminate = this.checkIntermediate(type);
                break;
            }
            case ('SMS'): {
                const states = [];
                this.dataSource.data.forEach((contactLink: ContactLink) => {
                    if (contactLink.contactEnabled && contactLink.smsVerified) {
                        states.push(contactLink.smsEnabled);
                    }
                });
                this.smsChecked = states.length > 0 && states.every(state => state === true);
                this.smsIndeterminate = !states.every(state => state === true) && !states.every(state => state === false);
                // this.smsChecked = this.checkChecked(type);
                // this.smsIndeterminate = this.checkIntermediate(type);
                break;
            }
        }
    }

    getContactEmail(contactLink: ContactLink): string {
        const coreContact = this.contacts.filter(ccontact => ccontact.id === contactLink.contactId)[0];
        return 'Email: ' + coreContact.getEmail();
        // return "Info about&#13;the action"
    }

    getContactPhone(contactLink: ContactLink): string {
        const coreContact = this.contacts.filter(ccontact => ccontact.id === contactLink.contactId)[0];
        return 'Mobile: ' + coreContact.getMobile();
    }

    processToggle(type, contactLink: ContactLink): void {
        contactLink.changed = true;
        this.processCheckbox(type);
        if (type === 'MAIN') {
            this.processCheckbox('EMAIL');
            this.processCheckbox('SMS');
        }
    }

    onCheckboxChange(type, event: MatCheckboxChange): void {

        for (const contactLink of this.dataSource.data) {
            if (type === 'MAIN') {
                contactLink.contactEnabled = event.checked;
                contactLink.changed = true;
            } else if (contactLink.contactEnabled) {
                if (type === 'SMS') {
                    contactLink.smsEnabled = event.checked;
                    contactLink.changed = true;
                } else if (type === 'EMAIL') {
                    contactLink.emailEnabled = event.checked;
                    contactLink.changed = true;
                }
            }
        }
        if (type === 'MAIN') {
            this.processCheckbox('MAIN');
            this.processCheckbox('EMAIL');
            this.processCheckbox('SMS');
        } else {
            this.processCheckbox(type);
        }
        console.log(this.mainChecked);
    }

    openMiniSelectionDialog(contactLink: ContactLink): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'contact-links-list-mini-dialog';
        console.log(contactLink);
        dialogConfig.data = {
            sensor: this.sensor,
            contactLink: contactLink
        };
        const dialogRef = this._matDialog.open(ContactLinksListMiniDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log('The dialog was closed');
                console.log('Contact', contactLink);
                contactLink = result;
                this.contactLinks.forEach((contactLinkI, i) => {
                    if (contactLinkI.contactId === contactLink.contactId) {
                        console.log('Contact', contactLink);
                        this.contactLinks[i] = contactLink;
                    }
                });
                this.dataSource = new MatTableDataSource(this.contactLinks);
                this.dataSource.paginator = this.paginator;
                this._changeDetectorRefs.detectChanges();
            }
        });
    }

    onContactLinkChanged(contactLink: ContactLink): void {
        contactLink.changed = true;
    }

    setupNotificationTypes(): void {
        if (this.sensor){
            // Read the notification types from the environment file and add them to the notificationTypes array
            // with the correct translation value.
            this.notificationTypes = [];
            SbNotificationUtils.getSensorNotificationTypes(this.sensor).forEach(
                (notificationType) => {
                    const key = notificationType.key;
                    let value = notificationType.value;
                    if (key !== 1 && key !== 2) {
                        value = 'NOTIFICATIONS.DESCRIPTIONS.' + this.sensor.type.toUpperCase() +'.'+ value;
                    }
                    else {
                        value = 'NOTIFICATIONS.DESCRIPTIONS.SENSOR.' + value;
                    }
                    this.notificationTypes.push({
                        key: key,
                        value: value
                    });
                }
            );
        } else {
            // Device
            this.notificationTypes = [];
            SbNotificationUtils.getDeviceNotificationTypes().forEach((notificationType) => {
                this.notificationTypes.push({
                    key: notificationType.key,
                    value: 'NOTIFICATIONS.DESCRIPTIONS.DEVICE.' + notificationType.value
                });
            });

        }
    }
}
