<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden group"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
    (mouseenter) ="navHover = true"  (mouseleave) ="navHover = false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-start w-full">
                <img
                    class="w-8"
                    src="assets/images/logo/logo.svg">
                <span *ngIf="navigationAppearance === 'default' || (navigationAppearance === 'dense' && navHover)"
                class="ml-4">
                    SENSORBASE
                </span>

                <!-- Navigation appearance toggle button -->
                <button
                    *ngIf="navigationAppearance === 'dense' && navHover"
                    class="hidden md:inline-flex md:ml-auto"
                    mat-icon-button
                    (click)="toggleNavigationAppearance()">
                    <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
                </button>
            </div>
        </div>
        <!-- User -->
        <div class="flex-col items-center w-full p-4"
             [ngClass]="navigationAppearance === 'dense' ? 'invisible hidden group-hover:flex group-hover:visible' : 'flex' "
             @zoomIn>
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full object-cover"
                    *ngIf="avatar"
                    [src]="avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{contact.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Navigation appearance toggle button -->
        <button
            class="hidden md:inline-flex"
            mat-icon-button
            (click)="toggleNavigationAppearance()">
            <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <mqtt-indicator></mqtt-indicator>
            <impersonate-user></impersonate-user>
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <shortcuts></shortcuts>
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Sensorbase &copy; {{currentYear}}</span>
    </div>-->

    <!-- Toast Notifications -->
    <toast></toast>

</div>

