<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'EXPORT.DOWNSAMPLING.TITLE' | transloco }}</div>
        <button
            mat-icon-button
            (click)="dialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <div class="p-4 pb-0 m-0">
            <div class="text-secondary" [transloco]="'EXPORT.DOWNSAMPLING.DESCRIPTION'"></div>
            <form [formGroup]="form">
                <div class="grid sm:grid-cols-3 gap-6 w-full p-6">
                    <div class="sm:col-span-1">
                        <mat-form-field class="fuse-mat-no-subscript w-full">
                            <mat-label [transloco]="'GENERIC.Days'"></mat-label>
                            <mat-select formControlName="days"
                                        [disabled]="form.value.selectAll">
                                <mat-option *ngFor="let day of days" [value]="day">
                                    {{day}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-1">
                        <mat-form-field class="fuse-mat-no-subscript w-full">
                            <mat-label [transloco]="'GENERIC.Hours'"></mat-label>
                            <mat-select formControlName="hours"
                                        [disabled]="form.value.selectAll">
                                <mat-option *ngFor="let hour of hours" [value]="hour">
                                    {{hour}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-1">
                        <mat-form-field class="fuse-mat-no-subscript w-full">
                            <mat-label [transloco]="'GENERIC.Minutes'"></mat-label>
                            <mat-select formControlName="minutes"
                                        [disabled]="form.value.selectAll">
                                <mat-option *ngFor="let minute of minutes" [value]="minute">
                                    {{minute}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sm:col-span-3 flex justify-center">
                        <mat-checkbox color="primary" formControlName="selectAll">
                            {{'EXPORT.DOWNSAMPLING.EXPORT_ALL' | transloco}}
                        </mat-checkbox>
                    </div>
                </div>
            </form>
        </div>

        <div class="flex flex-row items-center justify-center mb-4">
            <button mat-flat-button color="primary" (click)="onSubmit()">Download</button>
        </div>
    </div>

</div>
