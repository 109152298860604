import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User, Sensor} from '@sensorbase/models';
import {BehaviorSubject, Observable} from 'rxjs';

import {APIV2_BASE_URL} from 'environments/environment';
import {map} from 'rxjs/operators';


export class Timer {
    id: number;
    name: string;
    userId: string;
    duration: any;
    runAt: Date;
    repeating: boolean;
    repeatAfter: boolean;
    objectId: string;
    active: boolean;
    action: any;
    value: boolean;
    secondsRemaining: number;
    timeCreated: Date;

    private _lastUpdate: Date;

    get endTime(): Date {
        return new Date(this.timeCreated.getTime() + this.secondsRemaining * 1000);
    }

    get runAtLocal(): Date {
        return new Date(this.timeCreated.getTime() - (Timer.durationToSeconds(this.duration) + this.secondsRemaining) * 1000);
    }

    get lastUpdate(): Date {
        return this._lastUpdate;
    }

    get completed(): boolean {
        return this.completionPercentage <= 0;
    }

    get timeRemaining(): string {
        if (this.active) {
            const now = this.lastUpdate;
            const endTime = this.endTime;
            const time = (endTime.getTime() - now.getTime()) / 1000;
            return Timer.secondsToHms(time);
        } else {
            const hours = +this.duration.substring(0, 2);
            const minutes = +this.duration.substring(3, 5);
            const totalTime = (hours * 60 * 60) + minutes * 60;
            return Timer.secondsToHms(totalTime);
        }
    }

    get completionPercentage(): number {
        const now = this.lastUpdate;
        const endTime = this.endTime;
        const time = (endTime.getTime() - now.getTime()) / 1000;
        const hours = +this.duration.substring(0, 2);
        const minutes = +this.duration.substring(3, 5);
        const totalTime = (hours * 60 * 60) + minutes * 60;
        return time / totalTime * 100;
    }

    constructor(timer) {
        this.id = timer.id || 0;
        this.name = timer.name || '';
        this.userId = timer.userId || '';
        this.runAt = timer.runAt || null;
        this.value = timer.value === null ? true : timer.value;
        this.active = timer.active || false;
        this.repeating = timer.repeating || false;
        this.repeatAfter = timer.repeatAfter || null;
        this.duration = '00:01';
        if (timer.duration) {
            this.duration = timer.duration.substring(0, 5);
        }
        this.objectId = timer.objectId || '';
        this.action = timer.action || null;
        this.secondsRemaining = timer.secondsRemaining;
        this.timeCreated = timer.timeCreated || new Date();
        this._lastUpdate = timer.lastUpdate || new Date();
    }

    static durationToSeconds(duration): number {
        return duration.substring(0, 2) * 3600 + duration.substring(3, 5) * 60;
    }

    static secondsToHms(d): string {
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
        const sDisplay = s >= 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
        return hDisplay + mDisplay + sDisplay;
    }

    static fromApi(dataApi): Timer {
        let duration = '00:01';
        if (dataApi.duration) {
            duration = dataApi.duration.substring(0, 5);
        }
        const data = {
            id: dataApi.id,
            name: dataApi.name,
            userId: dataApi.userId,
            runAt: new Date(dataApi.runAt),
            value: dataApi.value === null ? true : dataApi.value,
            active: dataApi.active,
            repeating: dataApi.repeating,
            repeatAfter: dataApi.repeatAfter,
            duration: duration,
            objectId: dataApi.objectId || '',
            action: dataApi.action || null,
            secondsRemaining: dataApi.secondsRemaining,
            timeCreated: new Date(),
            lastUpdate: new Date()
        };
        return new Timer(data);
    }

    toApi(): object {
        let duration: string;
        if (this.duration.length < 8) {
            duration = this.duration + ':00';
        } else {
            duration = this.duration;
        }
        let id = this.id;
        if (this.action === 'add') {
            id = 0;
        }
        return {
            id: id,
            name: this.name,
            userId: this.userId,
            duration: duration,
            value: this.value,
            active: this.active,
            repeating: this.repeating,
            repeatAfter: this.repeatAfter,
            objectId: this.objectId,
        };
    }

    update(): void {
        this._lastUpdate = new Date();
    }
}

@Injectable({
    providedIn: 'root'
})
export class SensorTimersService {

    timers: any = {};
    user: User[];
    intervalHandle = null;

    private _timersBackup: any = {};

    onTimersChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient
    ) {
        this.onTimersChanged = new BehaviorSubject([]);

        this.intervalHandle = setInterval(() => {
            this.updateTimersLastUpdate();
        }, 1000);
    }

    private _clearSensorTimers(sensor: Sensor): void {
        this.timers[sensor.sensorId] = [];
    }

    _getSensorTimers(sensor): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'userTimers?sensor=' + sensor.sensorId);
    }

    getSensorTimers(sensor): Promise<any> {
        return new Promise((resolve, reject) => {
            this._getSensorTimers(sensor).subscribe((timers: any) => {
                    this._clearSensorTimers(sensor);
                    this._timersBackup[sensor.sensorId] = [];
                    timers.forEach(timer => {
                        this.timers[sensor.sensorId].push(Timer.fromApi(timer));
                        this._timersBackup[sensor.sensorId].push(Timer.fromApi(timer));
                    });
                    this.onTimersChanged.next(this.timers);
                    resolve(this.timers[sensor.sensorId]);
                },
                err => reject(err));
        });
    }

    startTimer(sensor, timer): Observable<any> {
        return this._httpClient.post(APIV2_BASE_URL + 'userTimers/' + timer.id + '/start', timer)
            .pipe(map((result) => {
                console.log(this.timers);
                let index = this.timers[sensor.sensorId].findIndex(t => t.id === timer.id);
                if (index >= 0) {
                    this.timers[sensor.sensorId][index] = Timer.fromApi(result);
                    //     this.timers[sensor.sensorId][index].active = true;
                    //     this.timers[sensor.sensorId][index].runAt = new Date(result['runAt']);
                }
                index = this._timersBackup[sensor.sensorId].findIndex(t => t.id === timer.id);
                if (index >= 0) {
                    this._timersBackup[sensor.sensorId][index] = Timer.fromApi(result);
                    //     this._timersBackup[sensor.sensorId][index].active = true;
                    //     this._timersBackup[sensor.sensorId][index].runAt = new Date(result['runAt']);
                }
                this.onTimersChanged.next(this.timers);
                return result;
            }));
    }

    stopTimer(sensor, timer): Observable<any> {
        return this._httpClient.post(APIV2_BASE_URL + 'userTimers/' + timer.id + '/stop', timer)
            .pipe(map((result) => {
                let index = this.timers[sensor.sensorId].findIndex(t => t.id === timer.id);
                if (index >= 0) {
                    this.timers[sensor.sensorId][index] = Timer.fromApi(result);
                    // this.timers[sensor.sensorId][index].active = false;
                    // this.timers[sensor.sensorId][index].runAt = null;
                }
                index = this._timersBackup[sensor.sensorId].findIndex(t => t.id === timer.id);
                if (index >= 0) {
                    this._timersBackup[sensor.sensorId][index] = Timer.fromApi(result);
                    // this._timersBackup[sensor.sensorId][index].active = false;
                    // this._timersBackup[sensor.sensorId][index].runAt = null;
                }
                this.onTimersChanged.next(this.timers);
                // console.log(index);
                // console.log(this._timersBackup);
                return result;
            }));
    }

    addTimer(timer): Observable<any> {
        return this._httpClient.post(APIV2_BASE_URL + 'userTimers', timer.toApi());
    }

    deleteTimer(timer): Observable<any> {
        return this._httpClient.delete(APIV2_BASE_URL + 'userTimers/' + timer.id);
    }

    updateTimer(timer): Observable<any> {
        return this._httpClient.put(APIV2_BASE_URL + 'userTimers/' + timer.id, timer.toApi());
    }

    saveSensorTimers(sensor): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(this.timers[sensor.sensorId]);
            const promises: Promise<any>[] = [];
            for (const timer of this.timers[sensor.sensorId]) {
                if (timer.action === 'add') {
                    promises.push(this.addTimer(timer).toPromise());
                } else if (timer.action === 'remove') {
                    promises.push(this.deleteTimer(timer).toPromise());
                } else if (timer.action === 'update') {
                    console.log('Update timer', timer);
                    promises.push(this.updateTimer(timer).toPromise());
                }
            }
            Promise.all(promises).then(() => {
                this.getSensorTimers(sensor);
                resolve(null);
            }, err => reject(err));
        });
    }

    resetSensorTimers(sensor): void {
        console.log(this._timersBackup);
        this._clearSensorTimers(sensor);
        this._timersBackup[sensor.sensorId].forEach(timer => {
            this.timers[sensor.sensorId].push(timer);
        });
        this.onTimersChanged.next(this.timers);
    }

    updateTimersLastUpdate(): void {
        // tslint:disable-next-line:forin
        for (const i in this.timers) {
            for (const j in this.timers[i]) {
                if (this.timers[i][j].active) {
                    this.timers[i][j].update();
                }
            }
        }
    }
}
