<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{contactLink.name}}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <mat-list>
            <mat-list-item>
                <div class="mat-list-row" fxLayout="row" fxLayoutAlign="space-between center">
                    Email
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <ng-container *ngIf="contactLink.emailVerified">
                            <mat-slide-toggle color="primary" [(ngModel)]="contactLink.emailEnabled"
                                              [disabled]="!contactLink.contactEnabled" (click)="contactLink.changed=true">
                            </mat-slide-toggle>
                            <button type="button" mat-button mat-icon-button matTooltip="Ρύθμιση"
                                    (click)="editMultiSelection(contactLink, 'EMAIL')">
                                <mat-icon matBadgeHidden="{{getActiveDetails(contactLink,'EMAIL').length==0}}"
                                          matBadge="{{getActiveDetails(contactLink,'EMAIL').length}}"
                                          matBadgeColor="info" svgIcon="more_vert">
                                </mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!contactLink.emailVerified">
                            <button mat-icon-button type="button" color="warn"
                                    (click)="openContactVerifyDialog(contactLink, 'EMAIL')">
                                <mat-icon svgIcon="warning"></mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div class="mat-list-row" fxLayout="row" fxLayoutAlign="space-between center">
                    SMS
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <ng-container *ngIf="contactLink.smsVerified">
                            <mat-slide-toggle color="primary" [(ngModel)]="contactLink.smsEnabled"
                                              [disabled]="!contactLink.contactEnabled" (click)="contactLink.changed=true">
                            </mat-slide-toggle>
                            <button type="button" mat-button mat-icon-button matTooltip="Ρύθμιση"
                                    (click)="editMultiSelection(contactLink, 'SMS')">
                                <mat-icon matBadgeHidden="{{getActiveDetails(contactLink,'SMS').length==0}}"
                                          matBadge="{{getActiveDetails(contactLink,'SMS').length}}"
                                          matBadgeColor="info" svgIcon="more_vert">
                                </mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!contactLink.smsVerified">
                            <button mat-icon-button type="button" color="warn"
                                    (click)="openContactVerifyDialog(contactLink, 'MOBILE')">
                                <mat-icon svgIcon="warning"></mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <div class="mat-list-row" fxLayout="row" fxLayoutAlign="space-between center">
                    {{'NOTIFICATIONS.SETTINGS.DELAY_MINI' | transloco}}
                    <increment-input class="increment-input" [(ngModel)]="contactLink.delay" [step]="1" [min]="0" [max]="10"
                                     [wrap]="true" [label]="'NOTIFICATIONS.SETTINGS.MINUTES'| transloco"
                                     [tooltip]="'TOOLTIPS.NOTIFICATION_SETTINGS.DELAY'| transloco"
                                     (change1)="contactLink.changed=true">
                    </increment-input>
                </div>
            </mat-list-item>
        </mat-list>

        <div class="w-full mb-3 mt-2" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" (click)="onCancel()">OK</button>
        </div>
    </div>
</div>
