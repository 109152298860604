import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';


export interface SelectionElement {
    key: string;
    value: number;
}

@Component({
    selector: 'multi-selection-dialog',
    templateUrl: './multi-selection-dialog.component.html',
    styleUrls: ['./multi-selection-dialog.component.scss']

})
export class MultiSelectionDialog {

    selectionItems: any = [];
    displayedColumns = ['checked', 'value'];
    selection = new SelectionModel<SelectionElement>(true, []);
    dataSource: any;

    public show = false;

    constructor(
        public dialogRef: MatDialogRef<MultiSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // console.log(data);
        const selectionItems = data.selectionItems;
        const selectedItems = data.selectedItems;
        this.dataSource = [];
        selectionItems.forEach(element => {
            if (selectedItems.includes(element.key)) {
                element.checked = true;
                this.selection.select(element);
            } else {
                element.checked = false;
            }
            this.selectionItems.push(element);
        });
        this.dataSource = new MatTableDataSource<SelectionElement>(this.selectionItems);
    }

    onSave(): void {
        // console.log("Saving......");
        // console.log(this.selection);
        const selected = [];
        this.selection.selected.forEach(element => {
            // console.log(element);
            selected.push(element.key);
        });
        this.dialogRef.close(selected);
    }

    onCancel(): void {
        // console.log(this.selection);
        this.dialogRef.close();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SelectionElement): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
    }

    processChange(element): void {
        this.selection.toggle(element);
    }

    toogle(): void {
        this.show = !this.show;
    }
}
