import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, switchMap, take, map, tap} from 'rxjs';
import {IMqttMessage} from 'ngx-mqtt';

import {API_BASE_URL, APIV2_BASE_URL} from 'environments/environment';


import {SensorbaseMqttService, AuthenticationService} from '@sensorbase/services';
import {Device, Sensor} from '@sensorbase/models';
import {first} from 'rxjs/operators';


@Injectable()
export class SensorsService {
    user: any;

    public onMqttConnect: Observable<boolean>;

    onSensorUpdated: Subject<Sensor>;
    onSensorDeleted: Subject<Sensor>;
    onSensorConditionsChanged: Subject<any>;

    // Private
    private _sensors: BehaviorSubject<Sensor[]>;

    constructor(
        private _httpClient: HttpClient,
        private _mqttService: SensorbaseMqttService,
        private _authService: AuthenticationService
    ) {
        this.onMqttConnect = _mqttService.onConnect;
        this._sensors = new BehaviorSubject<Sensor[]>([]);
        this.onSensorUpdated = new Subject<Sensor>();
        this.onSensorDeleted = new Subject<Sensor>();
        this.onSensorConditionsChanged = new Subject<Sensor>();
    }

    get sensors$(): Observable<Sensor[]> {
        return this._sensors.asObservable();
    }


    // Depricated use onMqttConnect instead to resub on reconnect events
    onMqttReady(callback): void {
        if (this._mqttService.connected) {
            callback();
        } else {
            this._mqttService.onConnect.pipe(first())
                .subscribe(() => {
                    callback();
                });
        }
    }

    subSensorDisconnect(sensor: string): Observable<IMqttMessage> {
        const userID = this._authService.getUserId();
        const mac = sensor.substr(0, 17);
        const sensorId = sensor.substr(18, sensor.length);
        return this._mqttService.observe('/' + userID + '/devices//' + mac + '/sensors/' + sensorId + '/disconnect');
    }

    subDeviceDisconnect(sensor): Observable<IMqttMessage> {
        const userID = this._authService.getUserId();
        const mac = sensor.substr(0, 17);
        return this._mqttService.observe('/' + userID + '/devices//' + mac + '/disconnect');
    }

    subSensorData(sensorId: string): Observable<IMqttMessage> {
        const userID = this._authService.getUserId();
        const mac = sensorId.substr(0, 17).replace(new RegExp(':', 'g'), '-').toUpperCase();
        const localsensorId = sensorId.substr(18, sensorId.length);
        const topic = '/' + userID + '/devices//' + mac + '/sensors/' + localsensorId + '/output/value';
        // console.log("/" + userID + "/devices//" + mac + "/sensors/" + sensorId + "/output/value");
        return this.sensors$.pipe(
            take(1),
            switchMap((sensors: Sensor[]) => {
                const idx = sensors.findIndex(x => x.sensorId === sensorId);
                return this._mqttService.observe(topic).pipe(
                    tap((message: IMqttMessage) => {
                        if (idx >= 0) {
                            const sensor = sensors[idx];
                            // Update the sensor data and status
                            sensor.data['current'] = +message.payload.toString();
                            if(sensor.options['offset']){
                                sensor.data['current'] += sensor.options['offset'];
                            }
                            sensor.status = 1;
                            // If the sensor is a relay, check if the target has been reached
                            // and update the status accordingly
                            if (sensor.type === 'relay') {
                                if (sensor.data['status'] === 'COMPLETE' && sensor.data['target'] !== sensor.data['current']) {
                                    this.getSensor(sensor.sensorId).subscribe((newSensor) => {
                                    });
                                } else {
                                    if (sensor.data['status'] === 'PENDING' && sensor.data['target'] === sensor.data['current']) {
                                        sensor.data['status'] = 'COMPLETE';
                                    }
                                }
                            }
                        }
                    }));
            })
        );
    }

    getSensor(sensorId: string): Observable<Sensor> {
        return this.sensors$.pipe(
            take(1),
            switchMap((sensors: Sensor[]) => {
                return this._httpClient.get(APIV2_BASE_URL + 'sensors/' + sensorId).pipe(
                    map(response => Sensor.fromApi(response)),
                    tap(sensor => {
                        const idx = sensors.findIndex(x => x.sensorId === sensor.sensorId);
                        if (idx >= 0) {
                            sensors[idx] = sensor;
                            this.onSensorUpdated.next(sensor);
                        } else {
                            sensors.push(sensor);
                        }
                        this._sensors.next(sensors);
                    })
                );
            })
        );
    }

    getSensorsForDevice(deviceId: string): Observable<Sensor[]> {
        return this.sensors$.pipe(
            take(1),
            switchMap((thissensors: Sensor[]) => {
                return this._httpClient.get(APIV2_BASE_URL + 'sensors?deviceId=' + deviceId).pipe(
                    map((response: any[]) => response.map(item => Sensor.fromApi(item))),
                    tap(sensors => {
                        sensors.forEach(sensor => {
                            const idx = thissensors.findIndex(x => x.sensorId === sensor.sensorId);
                            if (idx >= 0) {
                                thissensors[idx] = sensor;
                                this.onSensorUpdated.next(sensor);
                            } else {
                                thissensors.push(sensor);
                            }
                        });
                        this._sensors.next(thissensors);
                    })
                );
            })
        );
    }

    getSensors(ids: string[] = []): Observable<Sensor[]> {
        const options = ids.length ? {'params': {'sensorIds': ids}} : {};
        return this.sensors$.pipe(
            take(1),
            switchMap((thissensors: Sensor[]) => {
                return this._httpClient.get(APIV2_BASE_URL + 'sensors', options).pipe(
                    map((response: any[]) => response.map(item => Sensor.fromApi(item))),
                    tap(sensors => {
                        sensors.forEach(sensor => {
                            const idx = thissensors.findIndex(x => x.sensorId === sensor.sensorId);
                            if (idx >= 0) {
                                thissensors[idx] = sensor;
                                this.onSensorUpdated.next(sensor);
                            } else {
                                thissensors.push(sensor);
                            }
                        });
                        this._sensors.next(thissensors);
                    })
                );
            })
        );
    }

    updateSensor(sensor: Sensor): Observable<any> {
        const url = APIV2_BASE_URL + 'sensors/' + sensor.sensorId;
        return this.sensors$.pipe(
            take(1),
            switchMap((thissensors: Sensor[]) => {
                return this._httpClient.put(url, sensor.toApi()).pipe(
                    map(response => {
                        if (response === null) {
                            return sensor;
                        }
                        return Sensor.fromApi(response);
                    }),
                    tap(updatedSensor => {
                        const idx = thissensors.findIndex(x => x.sensorId === updatedSensor.sensorId);
                        thissensors[idx] = updatedSensor;
                        this.onSensorUpdated.next(updatedSensor);
                        this._sensors.next(thissensors);
                    })
                );
            })
        );
    }

    deleteSensor(sensorId: string): Observable<any> {
        return this.sensors$.pipe(
            take(1),
            switchMap((thissensors: Sensor[]) => {
                return this._httpClient.delete(APIV2_BASE_URL + 'sensors/' + sensorId).pipe(
                    tap(() => {
                        const idx = thissensors.findIndex(x => x.sensorId === sensorId);
                        if (idx >= 0){
                            thissensors.splice(idx, 1);
                            this._sensors.next(thissensors);
                        }
                    })
                );
            })
        );
    }

    getSensorData(sensorId, fromDate, toDate): Observable<any> {
        const userID = this._authService.getUserId();
        const url = API_BASE_URL + 'users/' + userID + '/sensors/' + sensorId + '/datav2?fromDate=' + fromDate.toUTCString() + '&toDate=' + toDate.toUTCString();
        return this._httpClient.get(url);
    }

    getSensorDataMonth(sensorId, month = null, year = null): Observable<any> {
        const userID = this._authService.getUserId();
        const now = new Date();
        let monthStr: string;
        if (month === null) {
            monthStr = (now.getMonth() + 1).toString().padStart(2, '0');
        } else {
            monthStr = (month + 1).toString().padStart(2, '0');
        }

        let yearStr = '';
        if (year) {
            yearStr = '&year=' + year.toString();
        }
        const url = API_BASE_URL + 'users/' + userID + '/sensors/' + sensorId + '/datav2?month=' + monthStr + yearStr;
        return this._httpClient.get(url);
    }

    getSensorDataToday(sensorId): Observable<any> {
        const userID = this._authService.getUserId();
        const url = API_BASE_URL + 'users/' + userID + '/sensors/' + sensorId + '/datav2';
        return this._httpClient.get(url);
    }

    setRelaySate(sensorId, state): void {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            this._mqttService.setControl(userID, sensorId, state);
        }
    }

    toggleButton(sensorId): void {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            this._mqttService.setControl(userID, sensorId, 1, false);
        }
    }

    getSensorConditions(sensorId): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'sensorConditions?sensorId=' + sensorId).pipe(
            map((response: any[]) => {
                response = response.map(x => {
                    x.condition = JSON.parse(x.condition);
                    return x;
                });
                return response;
            }),
            tap(conditions => {
                this.onSensorConditionsChanged.next({
                    sensorId: sensorId,
                    conditions: conditions
                });
            })
        );
    }

    updateSensorConditions(sensorId, conditions): Observable<any> {
        const conditionsApi = Object.assign({}, conditions);
        const url = APIV2_BASE_URL + 'sensorConditions/' + conditionsApi.conditionId;
        conditionsApi.condition = JSON.stringify(conditionsApi.condition);
        return this._httpClient.put(url, conditionsApi).pipe(
            tap(() => {
                this.onSensorConditionsChanged.next({
                    sensorId: sensorId,
                    conditions: [conditions]
                });
            })
        );
    }
}
