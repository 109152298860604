import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Alert, IAlert, IAlertData} from '../../models/alert.model';
import {Sensor} from '../../models';

@Injectable()
export class AlertService {
    baseDelay = 3000;
    // Private
    private _alert: Subject<Alert> = new Subject<Alert>();

    constructor(private router: Router) {
        // clear alert message on route change
        // router.events.subscribe(event => {
        // 	if (event instanceof NavigationStart) {
        // 		if (this.keepAfterNavigationChange) {
        // 			// only keep for a single location change
        // 			this.keepAfterNavigationChange = false;
        // 		} else {
        // 			// clear alert
        // 			this.subject.next();
        // 		}
        // 	}
        // });
    }

    get alert$(): Observable<Alert> {
        return this._alert.asObservable();
    }

    newAlert(alert: Alert): void {
        // TODO: add device toast here
        this._alert.next(alert);
    }

    info(data: IAlertData, delay: number = this.baseDelay): void {
        const alert = new Alert({type: 'info', title: data.title, body: data.body, delay: delay});
        this.newAlert(alert);
    }

    success(data: IAlertData, delay: number = this.baseDelay): void {
        const alert = new Alert({type: 'success', title: data.title, body: data.body, delay: delay});
        this.newAlert(alert);
    }

    warning(data: IAlertData, delay: number = this.baseDelay): void {
        const alert = new Alert({type: 'warning', title: data.title, body: data.body, delay: delay});
        this.newAlert(alert);
    }

    error(data: IAlertData, delay: number = this.baseDelay): void {
        const alert = new Alert({type: 'error', title: data.title, body: data.body, delay: delay});
        this.newAlert(alert);
    }

    alert(data: IAlert): void {
        data.delay = data.delay || this.baseDelay;
        const alert = new Alert(data);
        this.newAlert(alert);
    }
}
