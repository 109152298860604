<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SETTINGS.NOTIFICATION' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
        'success': sensor.status>0,
        'error': sensor.status<=0
         }" fxLayoutAlign="center">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <fuse-card class="flex flex-col justify-center sm:flex-row sm:flex-wrap sm:justify-between items-center p-4 m-2.5">
            <increment-input [step]="1" [min]="0"
                             [label]="'NOTIFICATIONS.SETTINGS.DELAY'| transloco"
                             [tooltip]="'TOOLTIPS.NOTIFICATION_SETTINGS.DELAY'| transloco" [(ngModel)]="notifyDelay">
            </increment-input>
            <div class="p-6">
                    <mat-slide-toggle color="primary" [checked]="sensor.notifications" (change)="toggleNotifications()"
                                      labelPosition="before">
                        {{'GENERIC.Notifications' | transloco}}
                    </mat-slide-toggle>
            </div>
        </fuse-card>
        <div class="contact-list">
            <contact-links-list [object]="sensor"></contact-links-list>
        </div>

        <div *ngIf="show" class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    {{'HELP.SENSOR.NOTIFICATIONS_DIALOG.INFO' | transloco}}
                </div>
            </div>
        </div>

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button class="mr-2" type="submit" mat-flat-button color="primary" (click)="onSave()">
                {{'GENERIC.Save' | transloco }}
            </button>
            <button class="ml-2" type="button" mat-stroked-button color="warn" (click)="onCancel()">
                {{'GENERIC.Cancel' | transloco }}
            </button>
        </div>

        <div class="relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>
    </div>
</div>
