<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SETTINGS.GENERAL' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
            'success': sensor.status>0,
            'error': sensor.status<=0
             }">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <div class="p-4 m-0">
            <form [formGroup]="settingsForm" (ngSubmit)="onSave()">
<!--                <div class="pb-3" fxLayout="row wrap" fxLayout.lt-lg="column" fxLayoutAlign="space-between start"-->
<!--                     fxLayoutAlign.lt-lg="center center">-->
                <div class="grid sm:grid-cols-4">
                    <div class="col-span-4 md:col-span-3">
                        <mat-tab-group color="accent" #tabRef class="h-full" headerPosition="above"
                                       mat-align-tabs="center"
                                       (selectedTabChange)="logChange($event, tabRef.selectedIndex)">

                            <!-- Sensor Display -->
                            <mat-tab [ngSwitch]="sensor.type">
                                <ng-template mat-tab-label>
                                    {{'GENERIC.State' | transloco}}
                                </ng-template>
                                <div class="flex flex-col justify-center items-center h-full w-full">
                                    <div class="w-64 h-36 flex flex-col justify-center items-center">
                                        <!-- Relay -->
                                        <ng-container *ngSwitchCase="'relay'">
                                            <sb-relay-display [sensor]="sensor"></sb-relay-display>
                                        </ng-container>
                                        <!-- Temperature -->
                                        <ng-container *ngSwitchCase="'temp'">
                                            <sb-temperature-display  [sensor]="sensor" type="eCharts">
                                            </sb-temperature-display>
                                        </ng-container>
                                        <!-- Humidity -->
                                        <ng-container *ngSwitchCase="'humid'">
                                            <sb-humidity-display [sensor]="sensor">
                                            </sb-humidity-display>
                                        </ng-container>
                                        <!-- Current -->
                                        <ng-container *ngSwitchCase="'current'">
                                            <sb-current-display [sensor]="sensor" type="eCharts"></sb-current-display>
                                        </ng-container>
                                        <!-- Voltage -->
                                        <ng-container *ngSwitchCase="'volt'">
                                            <sb-voltage-display [sensor]="sensor" type="eCharts"></sb-voltage-display>
                                        </ng-container>
                                        <!-- Power -->
                                        <ng-container *ngSwitchCase="'power'">
                                            <sb-power-display [sensor]="sensor" type="eCharts"></sb-power-display>
                                        </ng-container>
                                        <!-- Door -->
                                        <ng-container *ngSwitchCase="'door'">
                                            <sb-door-display [sensor]="sensor"></sb-door-display>
                                        </ng-container>
                                        <!-- PWM -->
                                        <ng-container *ngSwitchCase="'pwm'">
                                            <ngx-gauge type="arch" [value]="sensor.displayData"
                                                       [thresholds]="humidThresholdConfig" thick="8" min=0 max=100 size="250"
                                                       cap="round"
                                                       label="Ταχύτητα" append="%">
                                            </ngx-gauge>
                                            <mat-slider class="m-10">
                                            </mat-slider>
                                        </ng-container>
                                        <!-- Button -->
                                        <ng-container *ngSwitchCase="'button'">
                                            <mat-icon [ngClass]="{'warn-500-fg':sensor.displayData === 1}"
                                                      [svgIcon]="sensor.displayData === 1?'mat_solid:volume_up':'mat_solid:volume_off'">
                                            </mat-icon>
                                        </ng-container>
                                        <!-- RSSI -->
                                        <ng-container *ngSwitchCase="'rssi'">
                                            <sb-rssi-display [sensor]="sensor" type="eCharts">
                                            </sb-rssi-display>
                                        </ng-container>
                                    </div>
                                </div>

                            </mat-tab>

                            <!-- Sensor Graph -->
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    {{'GENERIC.Chart' | transloco}}
                                </ng-template>
                                <sb-sensor-chart *ngIf="selectedTabIndex === 1" [sensor]="sensor"></sb-sensor-chart>
                            </mat-tab>

                        </mat-tab-group>
                    </div>

                    <!-- Sensor Settings -->
                    <div class="col-span-4 md:col-span-1">
                        <fuse-card [ngSwitch]="sensor.type" class="flex flex-col w-full p-4 mt-4">

                            <mat-form-field appearance="outline">
                                <mat-label transloco="GENERIC.SENSOR.Name"></mat-label>
                                <input formControlName="sensorName" matInput maxlength="20"
                                       [matTooltip]="'TOOLTIPS.SENSOR_Name' | transloco" required>
                                <mat-error *ngIf="settingsForm.get('sensorName').hasError('required')"
                                           transloco="ERROR.SENSOR.NAME.REQUIRED">
                                </mat-error>
                            </mat-form-field>

                            <!-- Relay -->
                            <ng-container *ngSwitchCase="'relay'">
                                <div class="tab-content pl-6 pr-6 pb-0">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div transloco="SENSOR.RELAY.INIT_STATE"></div>
                                        <ui-switch size="small" checkedLabel="ON " uncheckedLabel="OFF"
                                                   defaultBgColor="#c5c6cb7"
                                                   [matTooltip]="'TOOLTIPS.INIT_STATE' | transloco"
                                                   formControlName="default_value">
                                        </ui-switch>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Temperature -->
                            <ng-container *ngSwitchCase="'temp'">
                                <increment-input [step]="0.1" [min]="-50" [max]="100" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_TEMP'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_TEMP'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="100" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_TEMP'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_TEMP'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMin">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="50" [wrap]="true"
                                                 [label]="'SENSOR.ALARM_SETTINGS.CALIBRATION_TEMP'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.CALIBRATION_TEMP'| transloco"
                                                 appearance="outline"
                                                 formControlName="offset">
                                </increment-input>
                            </ng-container>

                            <!-- Humidity -->
                            <ng-container *ngSwitchCase="'humid'">
                                <increment-input [step]="0.1" [min]="0" [max]="100" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_HUMID'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_HUMID'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="0" [max]="100" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_HUMID'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_HUMID'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMin">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="50" [wrap]="true"
                                                 [label]="'SENSOR.ALARM_SETTINGS.CALIBRATION_HUMID'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.CALIBRATION_HUMID'| transloco"
                                                 appearance="outline"
                                                 formControlName="offset">
                                </increment-input>
                            </ng-container>

                            <!-- Current -->
                            <ng-container *ngSwitchCase="'current'">
                                <increment-input [step]="0.1" [min]="-50" [max]="1000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_CURR'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_CURR'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="1000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_CURR'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_CURR'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMin">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="50" [wrap]="true"
                                                 [label]="'SENSOR.ALARM_SETTINGS.CALIBRATION_CURR'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.CALIBRATION_CURR'| transloco"
                                                 appearance="outline"
                                                 formControlName="offset">
                                </increment-input>
                            </ng-container>

                            <!-- Voltage -->
                            <ng-container *ngSwitchCase="'volt'">
                                <increment-input [step]="0.1" [min]="-50" [max]="1000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_VOLT'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_VOLT'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="1000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_VOLT'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_VOLT'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMin">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="50" [wrap]="true"
                                                 [label]="'SENSOR.ALARM_SETTINGS.CALIBRATION_VOLT'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.CALIBRATION_VOLT'| transloco"
                                                 appearance="outline"
                                                 formControlName="offset">
                                </increment-input>
                            </ng-container>

                            <!-- Power -->
                            <ng-container *ngSwitchCase="'power'">
                                <increment-input [step]="1" [min]="0" [max]="1000000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_POWER'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_POWER'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="1" [min]="0" [max]="1000000" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_POWER'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_POWER'| transloco"
                                                 appearance="outline"
                                                 formControlName="alarmMin">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-2000" [max]="2000" [wrap]="true"
                                                 [label]="'SENSOR.ALARM_SETTINGS.CALIBRATION_POWER'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.CALIBRATION_POWER'| transloco"
                                                 appearance="outline"
                                                 formControlName="offset">
                                </increment-input>
                            </ng-container>

                            <!-- Door -->
                            <ng-container *ngSwitchCase="'door'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'SENSOR.SWITCH.TEXT_OPEN' | transloco}}</mat-label>
                                    <input formControlName="openText" matInput placeholder="πχ. Επαφή Ανοιχτή" maxlength="30">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'SENSOR.SWITCH.TEXT_CLOSED' | transloco}}</mat-label>
                                    <input formControlName="closedText" matInput placeholder="πχ. Επαφή Κλειστή" maxlength="30">
                                </mat-form-field>
                                <div class="group">
                                    <h5 class="text-secondary mt-0 mb-4">{{'SENSOR.SWITCH.ICON_OPEN' | transloco}}</h5>
                                    <div class="text-secondary w-100p mb-8" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-icon style="width: 50px; height: 50px" [svgIcon]="getDoorIcon('open')"></mat-icon>
                                        <button type="button" mat-button (click)="openIconSelectionDialog('open')">
                                            {{'SENSOR.SWITCH.CHANGE_BUTTON' | transloco}}
                                        </button>
                                    </div>
                                </div>
                                <div class="group">
                                    <h5 class="text-secondary mt-0 mb-4">{{'SENSOR.SWITCH.ICON_CLOSED' | transloco}}</h5>
                                    <div class="text-secondary w-100p mb-8" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-icon style="width: 50px; height: 50px" [svgIcon]="getDoorIcon('closed')"></mat-icon>
                                        <button type="button" mat-button (click)="openIconSelectionDialog('closed')">
                                            {{'SENSOR.SWITCH.CHANGE_BUTTON' | transloco}}
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- PWM -->
                            <ng-container *ngSwitchCase="'pwm'">
                                <increment-input [step]="0.1" [min]="0" [max]="100" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MAX_PWM'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MAX_PWM'| transloco"
                                                 formControlName="alarmMax">
                                </increment-input>
                                <increment-input [step]="0.1" [min]="-50" [max]="50" [wrap]="false"
                                                 [label]="'SENSOR.ALARM_SETTINGS.MIN_PWM'| transloco"
                                                 [tooltip]="'TOOLTIPS.ALARM_SETTINGS.MIN_PWM'| transloco"
                                                 formControlName="alarmMin">
                                </increment-input>
                            </ng-container>

                            <!-- Button -->
                            <ng-container *ngSwitchCase="'button'">
                            </ng-container>
                        </fuse-card>
                    </div>
                </div>

                 <!-- Sensor Info -->
                <div [ngSwitch]="manualCatergory" *ngIf="show"
                     class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
                    <div class="flex flex-row justify-between items-center">
                        <mat-icon svgIcon="info"></mat-icon>

                        <!-- Settings info -->
                        <div *ngSwitchCase="0">
                            <div class="pl-4">
                                {{'HELP.SENSOR.SETTINGS_DIALOG.INFO_ΤΑΒ_SETTINGS' | transloco }}
                            </div>
                        </div>

                        <!-- Graph info -->
                        <div *ngSwitchCase="1">
                            <div [ngSwitch]="sensor.type" class="pl-4">
                                {{'HELP.SENSOR.CHART_DIALOG.INFO' | transloco }}
                                <ng-container *ngSwitchCase="'temp'">
                                    {{'HELP.SENSOR.CHART_DIALOG.INFO_TEMP' | transloco }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'humid'">
                                    {{'HELP.SENSOR.CHART_DIALOG.INFO_HUMID' | transloco }}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DIALOG BUTONS -->
                <div class="flex flex-row items-center justify-center m-4">
                    <button class="mr-2" type="submit" [disabled]="!settingsForm.valid" mat-flat-button color="primary">
                        {{'GENERIC.Save' | transloco }}
                    </button>
                    <button class="ml-2" type="button" mat-stroked-button color="warn" (click)="onCancel()">
                        {{'GENERIC.Cancel' | transloco }}
                    </button>
                </div>

                <div class="relative flex flex-row justify-end items-end">
                    <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                        <mat-icon svgIcon="help_outline"></mat-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
