import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil, take} from 'rxjs/operators';

import {fuseAnimations} from '@fuse/animations';

import {SensorsService, DevicesService} from '@sensorbase/services';
import {IMqttMessage} from 'ngx-mqtt';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Sensor, Device} from '@sensorbase/models';
import {MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {sbAnimations} from '../../../animations';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';
// import {SensorSettingsListDialog} from '@sensorbase/components';


@Component({
    selector: 'device-list',
    templateUrl: './device-list.component.html',
    styleUrls: ['./device-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        sbAnimations,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
            state('expanded', style({height: '*', visibility: 'visible'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class DeviceManagerDeviceListComponent implements OnInit, OnDestroy {
    private _deviceIds: string[];
    private _search: string;
    @Input('deviceIds')
    get deviceIds(): string[] {
        return this._deviceIds;
    }
    set deviceIds(val) {
        if (this._deviceIds !== val) {
            this._deviceIds = val;
            this._devicesService.devices$.pipe(take(1)).subscribe(devices => {
                this.update(devices);
            });
            // this.devices = this._devicesService.devices.filter(device => this._deviceIds.includes(device.deviceId))
        }
    }

    @Input('search')
    get search(): string {
        return this._search;
    }
    set search(val) {
        this._search = val;
        this.dataSource.filter = val.trim().toLowerCase();
    }

    devices: any[];
    updated = true;
    dataSource: any;
    displayedColumns = ['icon', 'deviceName', 'deviceId', 'model', 'hwVersion', 'localIp', 'rssi', 'info'];
    selected: any;
    expandedElement: any;
    rssis: any = {};
    detailLoaded = false;

    sensors: any;
    rssi: number;

    isSmallScreen: boolean;

    @ViewChild(MatSort, {static: false}) sort: MatSort;

    tempThresholdConfig = {
        '-50': {color: 'blue'},
        '-10': {color: 'lightskyblue'},
        '5': {color: 'green'},
        '20': {color: 'orange'},
        '50': {color: 'red'}
    };
    humidThresholdConfig = {
        '0': {color: 'blue'},
        '20': {color: 'lightskyblue'},
        '40': {color: 'green'},
        '60': {color: 'orange'},
        '80': {color: 'red'}
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    private _unsubscribeSensorSubs: Subject<any>;
    private _unsubscribeDeviceSubs: Subject<any>;

    isExpansionDetailRow = (i: number, row: object): boolean => row.hasOwnProperty('detailRow');

    /**
     * Constructor
     *
     * @param _fuseMediaWatcherService
     * @param _devicesService
     * @param _sensorsService
     * @param _matDialog
     */
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _devicesService: DevicesService,
        private _sensorsService: SensorsService,
        public _matDialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._unsubscribeSensorSubs = new Subject();
        this._unsubscribeDeviceSubs = new Subject();

        this.dataSource = new MatTableDataSource<Device[]>([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.dataSource = new FilesDataSource(this._devicesService);

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                this.isSmallScreen = !matchingAliases.includes('sm');
            });

        this._devicesService.devices$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((devices: any[]) => {
                this.update(devices);
                // console.log(this.devices);
            });

        this._devicesService.device$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(selected => {
                this.selected = selected;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._unsubscribeSensorSubs.next(null);
        this._unsubscribeSensorSubs.complete();
        this._devicesService.selectDevice(null);
    }

    unsubscribeSubs(): void {
        this._unsubscribeSensorSubs.next(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On select
     *
     * @param selected
     */
    onSelect(selected): void {
        console.log(this.dataSource.data);
        this._devicesService.selectDevice(selected);
    }

    update(devices): void {
        this._unsubscribeDeviceSubs.next(null);
        if (this.deviceIds) {
            this.devices = devices.filter(device => this.deviceIds.includes(device.deviceId));
        } else {
            this.devices = devices;
        }
        this.updated = false;
        this.dataSource = new MatTableDataSource(this.devices);
        this.updated = true;
        this.dataSource.sort = this.sort;

        this.devices.forEach(device => {
            // TODO: Add this when RSSI is added to devices table in Postgres
            // ==============================================================
            // this._sensorsService.getSensor(device.deviceId + ":RSSI")
            //     .pipe(
            //         take(1)
            //     )
            //     .subscribe(sensor => {
            //         this.rssis[device.deviceId] = sensor.data.data;

            //     });
            this._sensorsService.onMqttConnect.subscribe(() => {
                this._sensorsService.subSensorData(device.deviceId + ':RSSI')
                    .pipe(takeUntil(this._unsubscribeDeviceSubs))
                    .subscribe((message: IMqttMessage) => {
                        this.rssis[device.deviceId] = message.payload.toString();
                    });

                this._devicesService.subDeviceDisconnect(device)
                    .pipe(takeUntil(this._unsubscribeDeviceSubs))
                    .subscribe((message: IMqttMessage) => {
                        // console.log("Data Received: " + message.topic, message.payload.toString());
                        const updateItem = this.devices.find(dev => dev.deviceId === device.deviceId);
                        if (updateItem) {
                            if (updateItem && message.payload && !message.retain) {
                                const payload = message.payload.toString();
                                if (payload === '0') {
                                    updateItem.status = 1;
                                } else if (payload === '1') {
                                    updateItem.status = 0;
                                }
                                const index = this.devices.indexOf(updateItem);
                                this.devices[index] = device;
                            }
                        }
                        // console.log(this.rssis);
                    });
            });
        });
        // if (this.devices.length > 0) {
        //     this._devicesService.selectDevice(this.devices[0]);
        // } else {
        //     this._devicesService.selectDevice(null);
        // }
    }

    isRowExpanded(row): boolean {
        return this.expandedElement === row;
    }

    toggleExpanded(row, event): void {
        event.stopPropagation();
        console.log(this.expandedElement);
        if (this.isRowExpanded(row)) {
            this.expandedElement = null;
        } else {
            this.expandedElement = row;
            this.sensors = [];
            this.detailLoaded = false;
            if (!this.isSmallScreen){
                this._devicesService.selectDevice(row);
            }
            this._sensorsService.getSensorsForDevice(this.expandedElement.deviceId)
                .subscribe(sensors => {
                    this.unsubscribeSubs();
                    console.log(sensors);

                    this.sensors = sensors.sort((sensor1: Sensor, sensor2: Sensor) => {
                        const port1 = sensor1.type !== 'rssi' ? +sensor1.sensorId[sensor1.sensorId.length - 1] : 0;
                        const port2 = sensor2.type !== 'rssi' ? +sensor2.sensorId[sensor2.sensorId.length - 1] : 0;
                        return port1 - port2;
                    });
                    // this.sensors =
                    this.sensors.forEach((sensor, index) => {
                        if (sensor.type === 'relay') {
                            this.sensors[index] = this.fixRelaySensor(sensor);
                        }
                        this._sensorsService.onMqttConnect.subscribe(() => {
                            this._sensorsService.subSensorData(sensor.sensorId)
                                .pipe(takeUntil(this._unsubscribeSensorSubs))
                                .subscribe((message: IMqttMessage) => {});
                        });
                    });
                    this.detailLoaded = true;
                });
        }
    }

    openSensorSettings(sensor: Sensor): void {
        // const dialogConfig = new MatDialogConfig();
        // // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = false;
        // // dialogConfig.minHeight = '50%';
        // // dialogConfig.minWidth = '80%';
        // dialogConfig.panelClass = 'sensor-settings-list-dialog';
        //
        // let dialogRef;
        // this._devicesService.getDevice(sensor.deviceId)
        //     .then((response) => {
        //         const device = new Device(response);
        //         dialogConfig.data = {
        //             sensor: sensor,
        //             device: device
        //         };
        //         dialogRef = this._matDialog.open(SensorSettingsListDialog, dialogConfig);
        //
        //         dialogRef.afterClosed().subscribe(result => {
        //             if (result) {
        //                 // console.log('The dialog was closed');
        //                 sensor = result;
        //                 this._sensorsService.updateSensor(sensor);
        //             }
        //         });
        //     });
    }

    fixRelaySensor(sensor: Sensor): Sensor {
        if (sensor.data['status'] === 'COMPLETE' && sensor.data['target'] !== sensor.data['current']) {
            // console.log("Out of Sync - Refreshing Sensor");
            this._sensorsService.getSensor(sensor.sensorId).subscribe((newSensor) => {
                // console.log("data: ", data);
                sensor = newSensor;
            });
        } else {
            if (sensor.data['status'] === 'PENDING' && sensor.data['target'] === sensor.data['current']) {
                sensor.data['status'] = 'COMPLETE';
            }
        }
        return sensor;
    }
}
