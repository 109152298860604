import {Pipe, PipeTransform} from '@angular/core';

import {Sensor} from '@sensorbase/models';

@Pipe({name: 'favouriteSensors'})
export class FavouriteSensors implements PipeTransform {

    transform(allSensors: Sensor[], favourites: boolean): Sensor[] {
        // console.log("favourites: " + favourites);
        if (favourites) {
            // console.log("favourite: " + allSensors.filter(sens => (sens.favourite == "Y")));
            return allSensors.filter(sens => (sens.favourite === 'Y'));
        } else {
            return allSensors;
        }
    }
}
