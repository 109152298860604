<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{title | transloco }}</div>
        <button
            mat-icon-button
            (click)="dialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">

        <mat-action-list>
            <!-- <mat-list-item> -->
            <button mat-list-item (click)="select('image')">
                <mat-icon class="mx-2" svgIcon="image">
                </mat-icon>
                {{'SENSOR_CHART.EXPORT_SELECTOR.IMAGE' | transloco}}
            </button>
            <!-- </mat-list-item> -->
            <mat-divider></mat-divider>
            <!-- <mat-list-item> -->
            <button mat-list-item (click)="select('excel')">
                <mat-icon class="mx-2" svgIcon="table_chart">
                </mat-icon>
                {{'SENSOR_CHART.EXPORT_SELECTOR.SPREADSHEET' | transloco}}
            </button>

        </mat-action-list>

    </div>
</div>
