<mat-table *ngIf="updated" class="bg-card dark:bg-transparent" [dataSource]="dataSource" matSort>

    <!-- Type Column -->
    <ng-container matColumnDef="icon">
        <mat-header-cell fxFlex="64px" *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="64px">
            <mat-icon class="device type-icon" [ngClass]="{'text-warn': row.status<=0, 'text-green-600': row.status>0}"></mat-icon>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="deviceName">
        <mat-header-cell mat-sort-header *matHeaderCellDef>{{'GENERIC.DEVICE.Name' | transloco }}</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.deviceName}}</mat-cell>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="deviceId">
        <mat-header-cell mat-sort-header *matHeaderCellDef fxHide.xs>ID</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide.xs> {{row.deviceId}}</mat-cell>
    </ng-container>

    <!-- Model Column -->
    <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef fxHide.xs>{{'GENERIC.Model' | transloco }}</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide.xs> {{row.model}}</mat-cell>
    </ng-container>

    <!-- HW Version Column -->
    <ng-container matColumnDef="hwVersion">
        <mat-header-cell *matHeaderCellDef fxHide.xs>{{'GENERIC.Hardware_version' | transloco }}</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide.xs>{{row.hwVersion}}</mat-cell>
    </ng-container>

    <!-- Local IP Column -->
    <ng-container matColumnDef="localIp">
        <mat-header-cell *matHeaderCellDef fxHide.xs>{{'GENERIC.Local IP' | transloco }}</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide.xs>{{row.localIp}}</mat-cell>
    </ng-container>

    <!-- RSSI Column -->
    <ng-container matColumnDef="rssi">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>{{'GENERIC.Signal_strength' | transloco }}</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide fxShow.gt-md>{{rssis[row.deviceId]?rssis[row.deviceId]:'--'}} dB
        </mat-cell>
    </ng-container>

    <!-- Info Column -->
    <ng-container matColumnDef="info">
        <mat-header-cell *matHeaderCellDef fxFlex="64px"></mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="64px">
            <button mat-stroked-button type="button" class="min-w-10 min-h-7 h-7 px-2 leading-6"
                    [matTooltip]="'GENERIC.Info' | transloco" (click)="toggleExpanded(row, $event)">
                <mat-icon *ngIf="!isRowExpanded(row)" svgIcon="keyboard_arrow_down"></mat-icon>
                <mat-icon *ngIf="isRowExpanded(row)" svgIcon="keyboard_arrow_up"></mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="bg-gray-50 dark:bg-black dark:bg-opacity-5 shadow"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple
             class="element-row relative cursor-pointer min-h-16 hover:bg-gray-100 dark:hover:bg-hover"
             [ngClass]="{'border-b-transparent': isRowExpanded(row), 'bg-primary-50 dark:bg-hover': row===selected}" [class.selected]="row===selected"
             [@animate]="{value:'*',params:{y:'100%'}}" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"
             [cdkDetailRowIsExpanded]="isRowExpanded(row)"
             (click)="onSelect(row)">
    </mat-row>
</mat-table>

<!-- No devices found message -->
<ng-container *ngIf="devices.length === 0">
    <div class="flex flex-col items-center justify-center h-96">
        <div class="mat-display-1">
            <strong class="text-warn">
                {{'GENERIC.NO_DEVICES_FOUND'  | transloco}}
            </strong>
        </div>
    </div>
</ng-container>
<!-- / No devices found message -->

<!-- Expanded detail template -->
<ng-template #tpl let-detail>
    <div class="min-h-50 relative overflow-hidden" [@detailExpand]>

        <!-- Loading animation -->
        <div *ngIf="!detailLoaded" class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center">
            <mat-spinner color="accent"></mat-spinner>
        </div>

        <!-- Content -->
        <div *ngIf="detailLoaded" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center start"
             fxLayoutGap="gappx">

            <!-- Sensor card -->
            <fuse-card *ngFor="let sensor of sensors" class="border-2 h-48 w-52 p-0 m-2.5 hover:shadow"
                      matRipple
                      fxLayout="column" fxLayoutAlign="space-between center"
                      (click)="openSensorSettings(sensor)">

                <!-- Sensor name -->
                <div class="p-1 text-truncate">{{sensor.sensorName}}</div>

                <!-- Sensor display -->
                <div class="w-full h-32" [ngSwitch]="sensor.type" fxLayout="column" fxLayoutAlign="center space-around">
                    <!-- Relay -->
                    <div *ngSwitchCase="'relay'" class="p-7 relay-display" fxLayout="column" fxLayoutAlign="center center">
                        <sb-relay-display [sensor]="sensor"></sb-relay-display>
                    </div>

                    <!-- Temperature -->
                    <div *ngSwitchCase="'temp'" class="p-0 w-full h-full"
                         fxLayout="column" fxLayoutAlign="center center">
                        <sb-temperature-display [sensor]="sensor" type="eCharts">
                        </sb-temperature-display>
                    </div>

                    <!-- Humidity -->
                    <div *ngSwitchCase="'humid'" class="p-0 w-full h-full"
                         fxLayout="column" fxLayoutAlign="center center">
                        <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="humidThresholdConfig"
                                   thick="4" min=0 max=100 size="120" cap="round" label="Υγρασία" append="%">
                        </ngx-gauge>
                    </div>

                    <!-- Voltage -->
                    <div *ngSwitchCase="'volt'" class="p-0 w-full h-full"
                         fxLayout="column" fxLayoutAlign="center center">
                        <sb-voltage-display [sensor]="sensor" type="eCharts">
                        </sb-voltage-display>
                    </div>

                    <!-- Current -->
                    <div *ngSwitchCase="'current'" class="p-0 w-full h-full"
                         fxLayout="column" fxLayoutAlign="center center">
                        <sb-current-display [sensor]="sensor" type="eCharts">
                        </sb-current-display>
                    </div>

                    <!-- Power -->
                    <div *ngSwitchCase="'power'" class="p-0 w-full h-full"
                         fxLayout="column" fxLayoutAlign="center center">
                        <sb-power-display [sensor]="sensor" type="eCharts">
                        </sb-power-display>
                    </div>

                    <!-- Fan -->
                    <div *ngSwitchCase="'pwm'" class="fan-display" fxLayout="column" fxLayoutAlign="center center">
                        <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="humidThresholdConfig"
                                   thick="8" min=0 max=100 size="70" cap="round" label="Ταχύτητα" append="%">
                        </ngx-gauge>
                        <mat-slider></mat-slider>
                    </div>

                    <!-- Door -->
                    <div *ngSwitchCase="'door'" class="text-secondary" fxLayout="column" fxLayoutAlign="center center">
                        <sb-door-display [sensor]="sensor"></sb-door-display>
                    </div>

                    <!-- Alarm-->
                    <div *ngSwitchCase="'button'" class="text-secondary pb-1.5">
                        <mat-icon [ngClass]="{'warn-500-fg':sensor.displayData===1}"
                                  [svgIcon]="sensor.displayData===1?'mat_solid:volume_up':'mat_solid:volume_off'">
                        </mat-icon>
                    </div>

                    <!-- RSSI -->
                    <div *ngSwitchCase="'rssi'" class="rssi-display text-secondary">
                        <sb-rssi-display [sensor]="sensor"></sb-rssi-display>
                    </div>
                </div>

                <!-- Device port -->
                <div class="w-full py-0.5 title text-center text-truncate text-white" [ngClass]="sensor.status > 0 ? 'bg-green-600': 'bg-warn'">
                    {{'GENERIC.DEVICE.Port' | transloco }} : {{sensor.type !== 'rssi' ? sensor.sensorId[sensor.sensorId.length-1] : 0}}
                </div>

            </fuse-card>
        </div>
    </div>
</ng-template>
