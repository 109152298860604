<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full object-cover"
            *ngIf="showAvatar && avatar"
            [src]="avatar">
        <mat-icon
            *ngIf="!showAvatar || !avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'GENERIC.Signed_in_as' | transloco}}</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
<!--    <button mat-menu-item>-->
<!--        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>-->
<!--        <span>Profile</span>-->
<!--    </button>-->
    <button mat-menu-item [routerLink]="'/admin/settings'">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{'GENERIC.Settings' | transloco}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{'GENERIC.Sign_out' | transloco}}</span>
    </button>
</mat-menu>
