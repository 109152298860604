<div matDialogTitle class="flex flex-row justify-between items-center">
    <span class="title dialog-title" [transloco]="'DASHBOARDS.NEW_BOARD_DIALOG.TITLE'">
    </span>
</div>

<div mat-dialog-content>
    <p [transloco]="'DASHBOARDS.NEW_BOARD_DIALOG.INFO.NAME'"></p>
    <form [formGroup]="dashboardForm">
        <div class="flex flex-row justify-start items-start">
            <mat-form-field class="flex-auto">
                <mat-label [transloco]="'DASHBOARDS.NEW_BOARD_DIALOG.FORM.NAME'"></mat-label>
                <!-- <mat-icon matSuffix class="text-secondary">account_circle</mat-icon> -->
                <input name="name" formControlName="name" matInput required>
                <mat-error *ngIf="dashboardForm.get('name').hasError('required')"
                           [transloco]="'ERROR.DASHBOARD.NAME.REQUIRED'">
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions class="flex flex-row justify-center items-end">
    <button mat-stroked-button (click)="matDialogRef.close()" aria-label="Close dialog">
        {{'DASHBOARDS.NEW_BOARD_DIALOG.MENU.CANCEL' | transloco}}
    </button>
    <button mat-flat-button color="primary" (click)="onAdd()" [disabled]="dashboardForm.invalid" aria-label="ADD">
        {{'DASHBOARDS.NEW_BOARD_DIALOG.MENU.ADD' | transloco}}
    </button>
</div>

<!-- <div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                {{'DASHBOARDS.NEW_BOARD_DIALOG.TITLE' | transloco}}
            </span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="pt-12 m-0" fusePerfectScrollbar>
        <form [formGroup]="dashboardForm">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <mat-label>{{'DASHBOARDS.NEW_BOARD_DIALOG.NAME' | transloco}}</mat-label>
                    <mat-icon matSuffix class="text-secondary">account_circle</mat-icon>
                    <input name="name" formControlName="name" matInput required>
                    <mat-error *ngIf="dashboardForm.get('name').hasError('required')">
                        {{'ERROR.DASHBOARD.NAME.REQUIRED'|transloco}}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0" fxLayout="row" fxLayoutAlign="end center">

        <button mat-button class="save-button" (click)="onAdd()" [disabled]="dashboardForm.invalid" aria-label="ADD">
            {{'DASHBOARDS.NEW_BOARD_DIALOG.MENU.ADD' | transloco}}
        </button>

    </div>
</div> -->
