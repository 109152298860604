<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{ 'Ρυθμίσεις Συνάρτησης' | transloco }}</div>
        <button
            mat-icon-button
            (click)="matDialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <form [formGroup]="form" class="flex flex-col justify-start items-center w-full">
            <!-- Function Name -->
            <div class="w-full p-6 bg-gray-50 dark:bg-gray-700 p-8 border-b">
                <div class="text-xl" [transloco]="'FUNCTIONS.FUNCTION.NAME'"></div>
                <div class="text-secondary" [transloco]="'EXPORT.SENSOR_SELECT.DESCRIPTION'"></div>
            </div>
            <div class="w-full mt-4 p-6">
                <mat-form-field class="w-full sm:w-80">
                    <mat-label>{{ 'FUNCTIONS.FUNCTION.NAME' | transloco }}</mat-label>
                    <input matInput placeholder="Εισάγετε όνομα" formControlName="name"
                           [errorStateMatcher]="matcher" (change)="onFunctionUpdated(form)">
                    <mat-error *ngIf="form.get('name').invalid">
                        {{ 'FUNCTIONS.FUNCTION.ERRORS.NAME_EMPTY' | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Base Schedule -->
            <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
                <div class="text-xl" [transloco]="'FUNCTIONS.SCHEDULE.BASE.TITLE'"></div>
                <div class="text-secondary"
                     [transloco]="'FUNCTIONS.SCHEDULE.BASE.DESCRIPTION'"></div>
            </div>

            <div class="flex flex-row flex-wrap justify-between items-center w-full p-6">
                <div class="flex flex-col flex-auto basis-3/4 m-2 min-h-40"
                     [ngClass]="{'disabled-area': function.schedule.type==='REPEAT' , 'enable-area': function.schedule.type==='FULL'}"
                     (click)="useSchedule('FULL')" matTooltip="Ορίστε Ημερολογιακό Χρονοδιάγραμμα"
                     [matTooltipDisabled]="function.schedule.type==='FULL'">
                    <!-- Month Picker -->
                    <month-picker [monthOfyear]="function.schedule.months" class="m-3"
                                  [ngClass]="{'disabled-area-noClick': function.schedule.type==='REPEAT' }"
                                  (updated)="onScheduleUpdated(function.schedule)">
                    </month-picker>
                    <!-- WeekDay Picker -->
                    <weekday-picker [daysOfWeek]="function.schedule.dayOfWeek" class="m-3"
                                    [ngClass]="{'disabled-area-noClick': function.schedule.type==='REPEAT'}"
                                    (updated)="onScheduleUpdated(function.schedule)">
                    </weekday-picker>
                </div>

                <div class="flex flex-col justify-center flex-auto basis-1/5 m-2 self-stretch"
                     [ngClass]="{'disabled-area': function.schedule.type==='FULL' , 'enable-area': function.schedule.type==='REPEAT'}"
                     (click)="useSchedule('REPEAT')"
                     matTooltip="Ορίστε Περιοδικό Χρονοδιάγραμμα"
                     [matTooltipDisabled]="function.schedule.type==='REPEAT'">
                    <mat-form-field class="mx-3 mt-4"
                                    [ngClass]="{'disabled-area-noClick': function.schedule.type==='FULL'}">
                        <input matInput [matDatepicker]="picker"
                               [(ngModel)]="function.schedule.repeatStart"
                               [ngModelOptions]="{standalone: true}"
                               placeholder="Ημερομηνία Έναρξης"
                               (dateInput)="onScheduleUpdated(function.schedule)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="mx-3"
                                    [ngClass]="{'disabled-area-noClick': function.schedule.type==='FULL'}">
                        <input matInput [matMenuTriggerFor]="DaysMenu"
                               placeholder="Ημέρες Επανάληψης"
                               [(ngModel)]="function.schedule.repeatNo"
                               [ngModelOptions]="{standalone: true}"
                               (change)="onScheduleUpdated(function.schedule)">
                        <mat-icon matSuffix svgIcon="360"></mat-icon>
                    </mat-form-field>

                    <mat-menu #DaysMenu="matMenu" style="width: 193px;">
                        <mat-slider (click)="$event.stopPropagation();"
                                    [(ngModel)]="function.schedule.repeatNo"
                                    [ngModelOptions]="{standalone: true}"
                                    max="30" min="1" type="MinRange" [thumbLabel]=""
                                    [vertical]="false"
                                    (change)="onScheduleUpdated(function.schedule)">
                        </mat-slider>
                    </mat-menu>
                </div>
            </div>

            <!-- Event Groups -->
            <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
                <div class="text-xl" [transloco]="'FUNCTIONS.EVENT_GROUPS.TITLE'"></div>
                <div class="text-secondary"
                     [transloco]="'FUNCTIONS.EVENT_GROUPS.DESCRIPTION'"></div>
            </div>

            <div class="flex flex-col w-full p-6 pl-2">
                <div
                    *ngIf="(eventGroupsFormArray | filterFormArrayByPropertyNeq:'apiAction':'delete').length===0"
                    fxLayout="column" fxLayoutAlign="center center">
                    <p class="mt-0">Δεν υπάρχουν Σενάρια</p>
                    <button type="button" mat-raised-button color="warn" (click)="addEventGroup()">
                        <mat-icon svgIcon="add"></mat-icon>
                        Προσθήκη Σεναρίου
                    </button>
                </div>
                <div
                    *ngIf="sensorsLoaded && (eventGroupsFormArray | filterFormArrayByPropertyNeq:'apiAction':'delete').length>0"
                    class="event-groups-list" fxLayout="column" fxLayoutAlign="start start">
                    <div
                        *ngIf="sensorsLoaded && (eventGroupsFormArray | filterFormArrayByPropertyNeq:'apiAction':'delete').length>1"
                        class="flex flex-row justify-start items-start w-full mt-2">

                        <div
                            class="flex flex-col items-center justify-center border-2 rounded-r rounded-tl p-1">
                            <label>
                                Λογική διασυνδέσης: H'
                            </label>
                        </div>

                    </div>
                    <ng-container
                        *ngFor="let eventGroupForm of eventGroupsFormArray| filterFormArrayByPropertyNeq:'apiAction':'delete'; let i = index; let first1 = first; let last1 = last">
                        <div class="event-group-container w-full"
                             [ngClass]="{'connected': !(first1&&last1), 'pt-2': first1}">
                            <fuse-card
                                class="event-group flex flex-col w-full p-0 bg-gray-100 border border-gray-300 mb-2.5 overflow-auto">
                                <mat-toolbar class="mat-primary m-0">
                                    <mat-toolbar-row class="flex flex-row justify-between items-center">
                                        <inline-editable-input required="true"
                                                               [formControl]="eventGroupForm.get('name')"></inline-editable-input>
                                        <button type="button" mat-icon-button
                                                matTooltip="Διαγραφή Σεναρίου"
                                                (click)="deleteEventGroup(eventGroupForm, i)">
                                            <mat-icon svgIcon="delete"></mat-icon>
                                        </button>
                                    </mat-toolbar-row>
                                </mat-toolbar>

                                <div class="flex flex-col justify-center items-center w-full p-2">

                                    <!-- Category -->
                                    <div *ngIf="(eventGroupForm.get('events')| filterFormArrayByPropertyNeq:'apiAction':'delete').length===0
                                                    && (eventGroupForm.get('schedules')| filterFormArrayByPropertyNeq:'apiAction':'delete').length===0">
                                        <mat-form-field>
                                            <mat-select placeholder="Επιλογή Κατηγορίας"
                                                        [formControl]="eventGroupForm.get('type')"
                                                        (selectionChange)="onCategorySelected(eventGroupForm, $event);">
                                                <mat-option *ngFor="let category of categories"
                                                            [value]="category.value">
                                                    {{ category.viewValue }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="eventGroupForm.get('type').invalid">
                                                {{ 'FUNCTIONS.EVENT_GROUP.ERRORS.TYPE_EMPTY' | transloco }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- /Category -->

                                    <fuse-card
                                        *ngIf="(eventGroupForm.get('events')| filterFormArrayByPropertyNeq:['apiAction', 'objectType']:['delete', 'DUMMY']).length>0"
                                        class="event-group-sensor-events flex flex-col w-full p-0">
                                        <mat-toolbar class="mat-primary m-0">
                                            <mat-toolbar-row fxFlex fxLayout="row"
                                                             fxLayoutAlign="space-between center">
                                                {{ 'FUNCTIONS.EVENTS.TITLE' | transloco }}
                                            </mat-toolbar-row>
                                        </mat-toolbar>

                                        <div
                                            class="flex flex-col justify-center items-center relative w-full p-2">
                                            <ng-container
                                                *ngFor="let eventForm of eventGroupForm.get('events')| filterFormArrayByPropertyNeq:'apiAction':'delete'; let i = index; let first = first; let last = last">
                                                <div class="sensor-event w-full"
                                                     [ngClass]="{'connected': !(first&&last)}">
                                                    <div
                                                        class="group w-full py-1.5 px-4 border-2 rounded-sm my-2"
                                                        [ngClass]="{'bg-red-100 border-red-500': submitted && eventForm.invalid}"
                                                        fxLayout="row"
                                                        fxLayoutAlign="space-between center">
                                                        <div fxLayout="row wrap"
                                                             fxLayout.lt-sm="column">
                                                            <!-- Sensor -->
                                                            <mat-form-field class="mr-2">
                                                                <mat-label>Αισθητήρας</mat-label>
                                                                <mat-select
                                                                    placeholder="Αισθητήρας"
                                                                    [formControl]="eventForm.get('sensorId')"
                                                                    (selectionChange)="onSensorSelected($event, eventForm);">
                                                                    <mat-optgroup
                                                                        *ngFor="let group of sensorGroups"
                                                                        [label]="group.name | transloco"
                                                                        [disabled]="group.disabled">
                                                                        <mat-option
                                                                            *ngFor="let sensorOpt of group.sensors"
                                                                            [value]="sensorOpt.value">
                                                                            {{ sensorOpt.viewValue }}
                                                                        </mat-option>
                                                                    </mat-optgroup>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <!-- Critereon -->
                                                            <mat-form-field class="mr-2"
                                                                            style="min-width: 55px;"
                                                                            *ngIf="eventForm.get('sensorId').value">
                                                                <mat-label>Κριτήριο</mat-label>
                                                                <mat-select placeholder="Κριτήριο"
                                                                            [formControl]="eventForm.get('operator')"
                                                                            [errorStateMatcher]="matcher"
                                                                            [disabled]="(getSensorTypeFromId(eventForm.get('sensorId').value) === 'door' || getSensorTypeFromId(eventForm.get('sensorId').value) === 'relay')"
                                                                            (selectionChange)="onEventUpdated(eventForm)">
                                                                    <mat-option
                                                                        *ngFor="let criterion of criterions"
                                                                        [value]="criterion.value">
                                                                        {{ criterion.viewValue }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>


                                                            <!-- Temp/Humid Value -->
                                                            <mat-form-field
                                                                class="input-value mr-2"
                                                                *ngIf="eventForm.get('sensorId').value
                                                                                    && hasSensorValue(eventForm.get('sensorId').value)">
                                                                <mat-label>Τιμή</mat-label>
                                                                <input matInput placeholder="Τιμή"
                                                                       type="number"
                                                                       [formControl]="eventForm.get('value')"
                                                                       [errorStateMatcher]="matcher"
                                                                       (change)="onEventUpdated(eventForm)">
                                                                <span matSuffix
                                                                      *ngIf="getSensorTypeFromId(eventForm.get('sensorId').value) === 'temp'">
                                                                    °C
                                                                </span>
                                                                <span matSuffix
                                                                      *ngIf="getSensorTypeFromId(eventForm.get('sensorId').value) === 'humid'">
                                                                    %
                                                                </span>
                                                                <span matSuffix
                                                                      *ngIf="getSensorTypeFromId(eventForm.get('sensorId').value) === 'volt'">
                                                                    V
                                                                </span>
                                                                <span matSuffix
                                                                      *ngIf="getSensorTypeFromId(eventForm.get('sensorId').value) === 'current'">
                                                                    A
                                                                </span>
                                                                <span matSuffix
                                                                      *ngIf="getSensorTypeFromId(eventForm.get('sensorId').value) === 'rssi'">
                                                                    dB
                                                                </span>
                                                            </mat-form-field>

                                                            <!-- Door Value -->
                                                            <mat-form-field class="mr-2"
                                                                            *ngIf="eventForm.get('sensorId').value
                                                                                    && getSensorTypeFromId(eventForm.get('sensorId').value) === 'door'">
                                                                <mat-label>Τιμή</mat-label>
                                                                <mat-select placeholder="Τιμή"
                                                                            [formControl]="eventForm.get('value')"
                                                                            [errorStateMatcher]="matcher"
                                                                            (selectionChange)="onEventUpdated(eventForm)">
                                                                    <mat-option
                                                                        *ngFor="let value of doorValues"
                                                                        [value]="value.value">
                                                                        {{ value.viewValue }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <!-- Relay Value -->
                                                            <mat-form-field class="mr-2"
                                                                            *ngIf="eventForm.get('sensorId').value
                                                                                    &&  getSensorTypeFromId(eventForm.get('sensorId').value) === 'relay'">
                                                                <mat-label>Τιμή</mat-label>
                                                                <mat-select placeholder="Τιμή"
                                                                            [formControl]="eventForm.get('value')"
                                                                            [errorStateMatcher]="matcher"
                                                                            (selectionChange)="onEventUpdated(eventForm)">
                                                                    <mat-option
                                                                        *ngFor="let value of relayValues"
                                                                        [value]="value.value">
                                                                        {{ value.viewValue }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <!-- Delay -->
                                                            <mat-form-field
                                                                class="time-input-value mr-2"
                                                                *ngIf="eventForm.get('sensorId').value">
                                                                <mat-label>Καθυστέρηση</mat-label>
                                                                <input matInput
                                                                       placeholder="Χρονοκαθυστέρηση"
                                                                       [formControl]="eventForm.get('delay')"
                                                                       [errorStateMatcher]="matcher"
                                                                       (change)="onEventUpdated(eventForm)">
                                                                <span matSuffix>Λεπτά</span>
                                                            </mat-form-field>

                                                        </div>
                                                        <button type="button" mat-icon-button
                                                                matTooltip="Διαγραφή Γεγονότος"
                                                                (click)="deleteSensorEvent(eventGroupForm, eventForm, i)">
                                                            <mat-icon svgIcon="delete">
                                                            </mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div *ngIf="submitted && eventForm.invalid"
                                                     class="message-box error w-full"
                                                     fxLayout="column">
                                                    <ul class="list-disc px-4">
                                                        <ng-container
                                                            *ngIf="eventForm.get('sensorId').invalid">
                                                            <li> {{ 'FUNCTIONS.EVENT.ERRORS.SENSOR_EMPTY' | transloco }}</li>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="eventForm.get('operator').invalid">
                                                            <li> {{ 'FUNCTIONS.EVENT.ERRORS.OPERATOR_EMPTY' | transloco }}</li>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="eventForm.get('value').invalid">
                                                            <li> {{ 'FUNCTIONS.EVENT.ERRORS.VALUE_EMPTY' | transloco }}</li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </ng-container>
                                            <div
                                                *ngIf="(eventGroupForm.get('events')| filterFormArrayByPropertyNeq:'apiAction':'delete').length>1"
                                                class="flex flex-row justify-start items-start w-full mb-2">

                                                <div
                                                    class="flex flex-col items-center justify-center border-2 rounded-r rounded-bl p-1">
                                                    <label>
                                                        Λογική διασυνδέσης:
                                                    </label>
                                                    <mat-radio-group
                                                        [formControl]="eventGroupForm.get('operator')"
                                                        (change)="onEventGroupUpdated(eventGroupForm)">
                                                        <mat-radio-button
                                                            style="margin-left: 10px;"
                                                            *ngFor="let linkType of linkTypes"
                                                            [value]="linkType.value">
                                                            {{ linkType.viewValue }}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>

                                            </div>
                                            <div class="flex flex-row items-center justify-center">
                                                <button type="button" mat-raised-button
                                                        color="primary"
                                                        (click)="addSensorEvent(eventGroupForm)">
                                                    Προσθήκη Γεγονότος
                                                </button>
                                            </div>
                                        </div>
                                    </fuse-card>

                                    <fuse-card
                                        class="event-schedule-settings flex flex-col w-full p-0 mt-2"
                                        *ngIf="(eventGroupForm.get('schedules') | filterFormArrayByPropertyNeq:'apiAction':'delete').length>0">
                                        <mat-toolbar class="mat-primary m-0">
                                            <mat-toolbar-row fxFlex fxLayout="row"
                                                             fxLayoutAlign="space-between center">
                                                Ώρες Λειτουργείας
                                            </mat-toolbar-row>
                                        </mat-toolbar>
                                        <!-- <mat-card-header>
                                            <mat-card-title> Ώρες Λειτουργείας
                                            </mat-card-title>
                                        </mat-card-header> -->
                                        <div
                                            class="flex flex-col justify-center items-center relative w-full p-2">
                                            <ng-container
                                                *ngFor="let scheduleForm of eventGroupForm.get('schedules') | filterFormArrayByPropertyNeq:'apiAction':'delete'; let i = index">
                                                <div [formGroup]="scheduleForm"
                                                     class="flex flex-row justify-between items-center my-2 p-2 border-2 rounded-sm w-full">
                                                    <div
                                                        class="flex flex-row flex-wrap items-center justify-start">
                                                        <div
                                                            class="flex flex-row items-center my-1">
                                                            <div>Από:</div>
                                                            <ngx-timepicker-field [format]="24"
                                                                                  [defaultTime]="scheduleForm.get('startTime').value"
                                                                                  (timeChanged)="onScheduleTimeUpdated($event, scheduleForm, 'start')">
                                                            </ngx-timepicker-field>
                                                        </div>
                                                        <div
                                                            class="flex flex-row items-center my-1">
                                                            <div class="sm:ml-4">Εώς:</div>
                                                            <ngx-timepicker-field [format]="24"
                                                                                  [defaultTime]="scheduleForm.get('endTime').value"
                                                                                  (timeChanged)="onScheduleTimeUpdated($event, scheduleForm, 'end')">
                                                            </ngx-timepicker-field>
                                                        </div>
                                                    </div>
                                                    <button type="button" mat-icon-button
                                                            (click)="deleteSchedule(eventGroupForm, scheduleForm, i)">
                                                        <mat-icon svgIcon="delete">
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </ng-container>
                                            <div class="mt-1" fxLayout="row"
                                                 fxLayoutAlign="end center">
                                                <button type="button" mat-raised-button
                                                        color="primary" type="button"
                                                        (click)="addSchedule(eventGroupForm)">
                                                    Προσθήκη Ώρας
                                                </button>
                                            </div>
                                        </div>
                                    </fuse-card>
                                </div>

                            </fuse-card>
                        </div>
                    </ng-container>

                </div>

                <div
                    *ngIf="sensorsLoaded && (eventGroupsFormArray | filterFormArrayByPropertyNeq:'apiAction':'delete').length>0"
                    class="w-full" fxLayout="row" fxLayoutAlign="center center">
                    <button type="button" mat-raised-button color="warn" (click)="addEventGroup()">
                        <mat-icon svgIcon="add"></mat-icon>
                        Προσθήκη Σεναρίου
                    </button>
                </div>
            </div>

            <!-- Actions -->
            <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
                <div class="text-xl" [transloco]="'FUNCTIONS.ACTIONS.TITLE'"></div>
                <div class="text-secondary" [transloco]="'FUNCTIONS.ACTIONS.DESCRIPTION'"></div>
            </div>

            <div class="flex flex-col w-full p-6">
                <div class="grid sm:grid-cols-2 gap-6 w-full">
                    <div class="sm:col-span-1 flex flex-col items-start justify-center rounded-b p-5 relative"
                         [ngClass]="{'disabled-area': !actionsForm.get('action').get('enabled').value,
                                     'enable-area': actionsForm.get('action').get('enabled').value}">
                        <button type="button" mat-icon-button
                                class="absolute top-0 right-0"
                                (click)="toggleAction('active')">
                            <mat-icon svgIcon="power_settings_new"
                                      [ngClass]="{'text-warn': !actionsForm.get('action').get('enabled').value,
                                                  'text-green-600':actionsForm.get('action').get('enabled').value}"></mat-icon>
                        </button>
                        <div class="p-2"> Ενεργοποίηση Συνάρτησης </div>
                        <div class="w-full h-full border p-5">
                            <div class="flex flex-row justify-between items-center">
                                <div class="p-2">Ο ρελές θα γίνει: </div>
                                <ui-switch class="pl-1" size="medium"
                                           [formControl]="actionsForm.get('action').get('relay_state')"
                                           checkedLabel="ON"
                                           uncheckedLabel="OFF"
                                           defaultBgColor="#c5c6cb7">
                                </ui-switch>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-1 flex flex-col items-start justify-startr rounded-b p-5 relative"
                         [ngClass]="{'disabled-area': !actionsForm.get('inactiveAction').get('enabled').value,
                                     'enable-area': actionsForm.get('inactiveAction').get('enabled').value}">
                        <button type="button" mat-icon-button
                                class="absolute top-0 right-0"
                                (click)="toggleAction('inactive')">
                            <mat-icon svgIcon="power_settings_new"
                                      [ngClass]="{'text-warn': !actionsForm.get('inactiveAction').get('enabled').value,
                                                  'text-green-600':actionsForm.get('inactiveAction').get('enabled').value}"></mat-icon>
                        </button>
                        <div class="p-2"> Απενεργοποίηση Συνάρτησης </div>
                        <div class="w-full h-full border p-5">
                            <div class="flex flex-row justify-between items-center">
                                <div class="p-2">Ο ρελές θα γίνει: </div>
                                <ui-switch class="pl-1" size="medium"
                                           [formControl]="actionsForm.get('inactiveAction').get('relay_state')"
                                           checkedLabel="ON"
                                           uncheckedLabel="OFF"
                                           defaultBgColor="#c5c6cb7">
                                </ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Notes -->
            <div class="w-full mt-8 p-6 bg-gray-50 dark:bg-gray-700 p-8 border-t border-b">
                <div class="text-xl" [transloco]="'FUNCTIONS.NOTES.TITLE'"></div>
                <div class="text-secondary" [transloco]="'FUNCTIONS.NOTES.DESCRIPTION'"></div>
            </div>

            <div class="flex flex-col w-full p-6">
                <mat-form-field class="w-full fuse-mat-no-subscript">
                    <textarea [formControl]="form.get('notes')" matInput placeholder="Σημειώσεις"
                              (change)="onFunctionUpdated(form)"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="border-t">
        <div *ngIf="showHelp"
             class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    Στο επάνω μέρος δίνετε το όνομα της σύναρτησης.
                    Ακριβώς από κάτω συναντάτε το Βασικό Χρονοδιάγραμμα.
                    Μπορείτε με δυο τρόπους να κάνετε ρυθμίσεις.
                    Είτε επιλέγοντας συγκεκριμένους μήνες και μέρες για να λειτουργεί η συνάρτηση,
                    είτε με την μορφή επανάληψης.
                    Αυτό σημαίνει ότι επιλέγετε μια ημερολογιακή ημερομηνία έναρξης και στην
                    συνέχεια ανα πόσες μέρες θέλετε να επαναλαμβάνετε αυτή η συνάρτηση.
                    Πιο κάτω θα δείτε το κουμπί Προσθήκη Σεναρίου.
                    Πατώντας αυτό το κουμπι Προσθήκη Σεναρίου θα δημιουργηθεί ένας πίνακας όπου θα
                    έχετε τρείς διαθέσιμες επιλογές.
                    Και οι τρείς αυτές επιλογές θα έχουν άμεση σχέση με το Βασικό Χρονοδιάγραμμα.
                    Δήλαδη τα σενάρια θα λειτουργήσουν μόνο τους Μήνες-Ημέρες που επιλέξατε.
                    <p>
                        1) Απλό Χρονοδιάγραμμα : Ορίζετε το διάστημα σε ώρες που θα ισχύει η
                        Συνάρτηση σας.
                    </p>
                    <p>
                        2) Μόνο Γεγονότα : Με βασή κάποιον άλλον αισθητήρα που ανήκει στον
                        λογαριασμό σας, θα επηρεαστεί ο διακόπτη σας.
                        Ρυθμίζοντας με βάση την κατάσταση ενός αισθητήρα (Επαφή Ανοιχτή-Επαγή
                        Κλειστή), με την επιλογή της τιμής ενός αισθητήρα αν θα είναι μικρότερη,
                        μεγαλύτερη ή ακόμα και ίση.
                        Μπορείτε να προσθέσετε επιπλέον αισθητήρες, επιλέγοντας το πως θα
                        λειτουργήσει το Σενάριο.
                        Είτε όταν θα ισχύουν και τα δύο γεγονότα των αισθητήρων να πραγματοποιηθεί
                        η Συνάρτηση σας, είτε όταν θα ισχυεί το ένα από τα δύο γεγονότα να
                        πραγματοποιηθεί.
                    </p>
                    <p>
                        3) Και με τα Δύο : Μπορεί το Σενάριο σας να λειτουργήσει και με τους δύο
                        παραπάνω τρόπους. Όταν θα έχει προκύψει ένα η ακόμα και δύο γεγονότα τις
                        συγκεκριμένες ώρες που έχτε επιλέξει.
                    </p>
                    Τέλος μπορέιτε να δημιουργήσετε όσα Σενάρια θέλετε.
                    Έαν κάποιο από τα Σενάρια σας προκύψει, θα λειτουργήσει και η Συνάρτηση σας.
                </div>
            </div>
        </div>

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button class="mr-2" (click)="onSave()" mat-flat-button color="primary"
                    [disabled]="submitted && form.invalid">
                {{ 'GENERIC.Save' | transloco }}
            </button>
            <button class="ml-2" type="button" mat-stroked-button color="warn"
                    (click)="matDialogRef.close()">
                {{ 'GENERIC.Cancel' | transloco }}
            </button>
        </div>

        <div class="absolute bottom-0 right-0 sm:relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5"
                    color="primary" (click)="toggleHelp()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>
    </div>
</div>
