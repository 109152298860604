import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {filter, pluck, retry, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as XLSX from 'xlsx-republish';

import {Sensor, SensorChartOptions} from '@sensorbase/models';
import {ExcelExportService, SensorbaseMqttService, SensorsService} from '@sensorbase/services';
import {DownsamplingSelectionDialog} from '@sensorbase/components';
import {ExportSelectionDialog} from './export-selection-dialog/export-selection-dialog.component';
import {TranslocoService} from '@ngneat/transloco';
import {AppConfig} from '../../../../app/core/config/app.config';
import {FuseConfigService} from '../../../../@fuse/services/config';
import {IMqttMessage} from 'ngx-mqtt';
import {EChartsOption} from 'echarts';
import {SbSensorUtils} from '../../../utils/sensor.utils';
import {SensorType} from '../../../config';

@Component({
    selector: 'sb-sensor-chart',
    templateUrl: './sensor-chart.component.html',
    styleUrls: ['./sensor-chart.component.scss']
})
export class SensorChartComponent implements OnInit, OnDestroy {

    private _sensor: Sensor;
    private _options: SensorChartOptions;
    private _data: any[];

    @Input() set sensor(value: Sensor) {
        this._sensor = value;
        this._data = [];
        this._unsubscribeMqtt.next(1);
        if (this.initialised) {
            this.init();
        }
        this._sensorsService.onMqttConnect
            .subscribe(() => {
                this._sensorsService.subSensorData(this.sensor.sensorId)
                    .pipe(takeUntil(this._unsubscribeMqtt))
                    .subscribe((message: IMqttMessage) => {
                        const date = new Date();
                        console.log('[' + date + '] Message received: \n' +
                            '  - Topic: ' + message.topic + '\n' +
                            '  - Payload: ' + message.payload.toString()
                        );
                        if (this.endDate.getTime() === this.today.getTime() && this.initialised) {

                            const lastDate = this._data[this._data.length - 1][0];
                            if (date.getTime() - lastDate.getTime() > 1000) {
                                this._data.push([new Date(), JSON.parse(message.payload.toString())]);
                                this.updateOptions = {
                                    series: [
                                        {
                                            data: this._data
                                        },
                                    ],
                                };
                            }
                        }
                    });
            });
    }

    get sensor(): Sensor {
        return this._sensor;
    }

    @Input() set dateRange(value: Date[]) {
        this.startDate = value[0];
        this.endDate = value[1];
        if (this.initialised) {
            this.init();
        }
    }

    @Input() set options(opts: SensorChartOptions) {
        this._options = new SensorChartOptions(opts);
        this.chartOption.grid.left = this._options.leftMargin || this.chartOption.grid.left;
        this.chartOption.grid.bottom = this._options.bottomMargin || this.chartOption.grid.bottom;
    }

    get options(): SensorChartOptions {
        return this._options;
    }

    initialised = false;
    loadingText = 'Loading...';
    loaded = true;
    progress = 0;
    year = new Date().getFullYear();
    sensorConditions: any;
    isMobile = false;

    theme = 'light';
    eChartData: any;
    initOpts: any = {
        renderer: 'svg'
    };
    chartOption: EChartsOption = {
        backgroundColor: 'transparent',
        title: {
            left: 'center',
            text: 'Test Chart',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'line',
                axis: 'x'
            },
            valueFormatter: (val) => {
                if (Array.isArray(val)) {
                    return val[1];
                }
                return val;
            }
        },
        grid: {
            left: '5',
            top: '75px',
            bottom: 60,
            right: '6%',
            containLabel: true
        },
        toolbox: {
            // top: '25px',
            left: 'center',
            feature: {
                dataZoom: {
                    // yAxisIndex: 'none',
                    title: {
                        zoom: 'Zoom Tool',
                        back: 'Reset Zoom'
                    }
                },
                restore: {
                    title: 'Reload'
                },
            }
        },
        xAxis: {
            type: 'time',
            axisLabel: {
                // formatter: '{HH}:{mm} {dd}/{MM}/{yyyy}'
                formatter: {
                    day: '{d} {MMM}', // 1, 2, ...
                    year: '{yyyy}'
                },
                rich: {
                    monthStyle: {
                        fontWeight: 'bold',
                    }
                },
                hideOverlap: true
            },
            axisLine: {
                onZero: false
            }
        },
        yAxis: {},
        dataZoom: [],
        series: []
    };
    updateOptions: any;

    datePickerOpen = false;
    startDate: Date;
    endDate: Date;
    today: Date;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _unsubscribeMqtt: Subject<any> = new Subject();

    constructor(
        private _sensorsService: SensorsService,
        private _mqttService: SensorbaseMqttService,
        private _excelExportService: ExcelExportService,
        private _translateService: TranslocoService,
        private _fuseConfigService: FuseConfigService,
        private _matDialog: MatDialog,
        private _platform: Platform) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        if (this.options === undefined) {
            this.options = new SensorChartOptions();
        }

        this.today = new Date();
        this.today.setHours(23, 59, 59, 0);
        // End date is today
        if (!this.endDate) {
            this.endDate = new Date();
            // Set to beginning of the day
            this.endDate.setHours(23, 59, 59, 0);
        }

        // Start date is yesterday
        if (!this.startDate) {
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - 1);
            this.startDate.setHours(0, 0, 0, 0);
        }

        this._translateService.events$.pipe(
            takeUntil(this._unsubscribeAll),
            filter(e => e.type === 'langChanged'),
            pluck('payload')
        ).subscribe(() => {
            this.init();
        });

        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
            this.chartOption.grid.right = '15%';
        }

        this.init();
        this.initialised = true;

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                this.theme = config.scheme;
            });

        this._unsubscribeMqtt.subscribe(() => {
            console.log('Unsubscribing from MQTT');

        });
        console.log('ngOnInit');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    init(): void {
        // Setup yAxis options
        this.chartOption.yAxis = [];
        this.chartOption.yAxis = this.getYAxisOption();

        // Set up datazoom tool
        this.setupDatazoom(this.sensor.type);

        this.setupSeries();
    }

    onChartInit(chartInstance): void {
        console.log('Chart initialised');
        this.eChartData = chartInstance;
        // this.updateChart();
    }

    updateChart(): void {
        if (this.eChartData) {
            // this.eChartData.setOption(this.chartOption, true);
            try {
                this.eChartData.setOption(this.chartOption, {
                    replaceMerge: ['series', 'visualMap', 'dataZoom']
                });
                this.eChartData.hideLoading();
            } catch (e) {
                this.getDateRange(this.startDate, this.endDate);
            }
        }
    }

    setupSeries(): void {
        let chartTitle = 'Ιστορικό';
        let tooltipLabel = '';
        let sampling: any = 'lttb';
        let step: any = 'start';
        this.chartOption.tooltip.formatter = null;
        const series = {
            id: 'sensorData',
            name: '',
            type: 'line',
            sampling: '',
            smooth: true,
            showSymbol: false,
            itemStyle: {
                color: 'rgb(70, 172, 255)'
            }
        };

        const typeLabel = SbSensorUtils.getSensorTypeConfig(this.sensor.type).name.toUpperCase();
        chartTitle = this._translateService.translate('SENSOR_CHART.TITLE.' + typeLabel);
        if (SbSensorUtils.isMinMaxSensor(this.sensor)) {
            tooltipLabel = this._translateService.translate('CHARTS.AXIS_LABELS.' + this.sensor.type);
            tooltipLabel += ' (' + SbSensorUtils.getSensorTypeDefaultUnit(this.sensor.type) + ')';
        } else {
            step = 'start';
            sampling = false;
        }
        if (this.options.showTitle) {
            this.chartOption.title['text'] = chartTitle;
        } else {
            delete this.chartOption.title;
        }

        if (this.startDate.getFullYear() === this.endDate.getFullYear() &&
            this.startDate.getMonth() === this.endDate.getMonth() &&
            this.startDate.getDate() === this.endDate.getDate()) {
            sampling = false;
        }

        series.name = tooltipLabel;
        series.sampling = sampling;
        this.chartOption.series = [series];

        if (SbSensorUtils.isMinMaxSensorType(this.sensor.type)) {
            // Using setTimeout prevents angular change detection error in fuse loading bar
            setTimeout(() => {
                this._sensorsService.getSensorConditions(this.sensor.sensorId)
                    .subscribe(response => {
                        this.sensorConditions = response;
                        if (this.sensorConditions.length) {
                            this.setupChartMinMaxConditions();
                        } else {
                            this.resetChartMinMaxConditions();
                        }
                        this.setupMargins();
                        this.getDateRange(this.startDate, this.endDate);
                    });
            });
        } else {
            this.resetChartMinMaxConditions();
            setTimeout(() => {
                this.setupMargins();
                // Using setTimeout prevents angular change detection error in fuse loading bar
                this.getDateRange(this.startDate, this.endDate);
            });
        }
    }

    setupMargins(): void {
        if (this.options.showTitle) {
            this.chartOption.grid.top = '80px';
            this.chartOption.toolbox.top = '20px';
            if (this.chartOption.visualMap) {
                this.chartOption.visualMap.top = '45px';
            }
        } else {
            this.chartOption.grid.top = '60px';
            this.chartOption.toolbox.top = '0px';
            if (this.chartOption.visualMap) {
                this.chartOption.visualMap.top = '30px';
            }
        }
    }

    getDateRange(fromDate: Date, toDate: Date): void {
        if (this.eChartData){
            this.eChartData.showLoading();
        }
        this._sensorsService.getSensorData(this.sensor.sensorId, fromDate, toDate)
            .pipe(
                 // Add retry logic to handle failed requests
                retry(3),
            )
            .subscribe({
                next: (data: Array<any>) => {
                    switch (this.sensor.type) {
                        case ('relay'): {
                            data = data.map(x => {
                                const val = x[1] === 0 ? 'OFF' : 'ON';
                                return [x[0], val];
                            });
                            break;
                        }
                        case ('door'): {
                            const openText = this.sensor.options.openText || 'OPEN';
                            const closedText = this.sensor.options.closedText || 'CLOSED';
                            data = data.map(x => {
                                const val = x[1] === 0 ? closedText : openText;
                                return [x[0], val];
                            });
                            break;
                        }
                        case ('rssi'): {
                            break;
                        }
                    }
                    data = data.map(x => [new Date(x[0]), x[1]]);
                    data.sort((a, b) => {
                        return a[0].getTime() - b[0].getTime();
                    });
                    const nullData = [];
                    data.forEach((current, i) => {
                        if (i === data.length - 1) {
                            return;
                        }
                        const next = data[i + 1];
                        if ((next[0].getTime() - current[0].getTime()) / 1000 > 300) {
                            nullData.push([new Date(current[0].getTime() + 1), null]);
                        }
                    });
                    if (nullData.length) {
                        data = data.concat(nullData);
                        data.sort((a, b) => {
                            return a[0].getTime() - b[0].getTime();
                        });
                    }
                    this._data = data;
                    this.chartOption.series[0]['data'] = data;
                    this.updateOptions = {
                        series: [
                            {
                                id: 'sensorData',
                                data: this._data
                            },
                        ],
                    };
                    // this.loaded = true;
                    setTimeout(() => {
                        this.updateChart();
                    }, 1000);
                },
                error: (error) => {
                    this.eChartData.showLoading({
                        text: 'Oops! Something went wrong...\nPlease try again',
                        textColor: 'red',
                        showSpinner: false,
                        zlevel: 0
                    });
                    console.log(error);
                }
            });
    }

    onDateChanged(type, event): void {
        if (event.value) {
            if (type === 'start') {
                this.startDate = event.value.toJSDate();
            } else {
                this.endDate = event.value.toJSDate();
                this.endDate.setHours(23, 59, 59, 0);
            }
            if (!this.datePickerOpen) {
                console.log(event.value);
            }
        }
    }

    onDatePickerClosed(event): void {
        this.datePickerOpen = false;
        this.getDateRange(this.startDate, this.endDate);
        console.log(this.startDate);
        console.log(this.endDate);
    }

    getYAxisOption(): any {
        const type = this.sensor.type;
        const option = {
            type: 'value',
            name: '',
            nameTextStyle: {
                align: 'left'
            },
            boundaryGap: ['20%', '20%'],
            minInterval: 1,
            axisLine: {
                show: true,
            },
            axisLabel: {
                overflow: 'truncate'
            },
            axisTick: {
                alignWithLabel: true
            },
            axisPointer: {
                label: {
                    precision: 1
                }
            },
            alignTicks: true
        };

        option.name = this._translateService.translate('CHARTS.AXIS_LABELS.' + type);
        if (SbSensorUtils.isMinMaxSensorType(type)) {
            option.name += ' (' + SbSensorUtils.getSensorTypeDefaultUnit(type) + ')';
            option.axisLabel['formatter'] = (value): string => {
                return value.toFixed(1) + SbSensorUtils.getSensorTypeDefaultUnit(type);
            };
        } else {
            option.type = 'category';
            switch (type) {
                case (SensorType.Relay): {
                    option['data'] = ['OFF', 'ON'];
                    break;
                }
                case (SensorType.Switch): {
                    const openText = this.sensor.options.openText || 'OPEN';
                    const closedText = this.sensor.options.closedText || 'CLOSED';
                    option['data'] = [closedText, openText];
                    break;
                }
            }
        }
        return option;
    }

    setupDatazoom(type): void {
        if (SbSensorUtils.isMinMaxSensorType(type)) {
            const startX = this.options.showDataZoom ? 80 : 0;
            this.chartOption.dataZoom = [
                {
                    id: 'dataZoomX',
                    type: 'inside',
                    start: startX,
                    end: 100,
                    xAxisIndex: [0],
                    filterMode: 'none'
                },
                {
                    id: 'dataZoomY',
                    type: 'inside',
                    start: 0,
                    end: 100,
                    yAxisIndex: [0],
                    filterMode: 'none'
                }
            ];
            if (this.options.showDataZoom) {
                this.chartOption.dataZoom.push({
                    id: 'dataZoomXSlider',
                    type: 'slider',
                    start: 80,
                    end: 100,
                    // eslint-disable-next-line max-len
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        // shadowBlur: 3,
                        // shadowColor: 'rgba(0, 0, 0, 0.6)',
                        // shadowOffsetX: 2,
                        // shadowOffsetY: 2
                    },
                    xAxisIndex: [0],
                    filterMode: 'none'
                });
                this.chartOption.dataZoom.push(
                    {
                        id: 'dataZoomYSlider',
                        type: 'slider',
                        start: 0,
                        end: 100,
                        width: 20,
                        // eslint-disable-next-line max-len
                        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        handleSize: '80%',
                        handleStyle: {
                            color: '#fff',
                            // shadowBlur: 3,
                            // shadowColor: 'rgba(0, 0, 0, 0.6)',
                            // shadowOffsetX: 2,
                            // shadowOffsetY: 2
                        },
                        yAxisIndex: [0],
                        filterMode: 'none'
                    });
            }
        } else {
            this.chartOption.dataZoom = [
                {
                    id: 'dataZoomX',
                    type: 'inside',
                    start: 80,
                    end: 100,
                    xAxisIndex: [0],
                    filterMode: 'empty'
                },
                {
                    start: 80,
                    end: 100,
                    // eslint-disable-next-line max-len
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    xAxisIndex: [0],
                    filterMode: 'empty'
                }
            ];
            if (this.options.showDataZoom) {
                this.chartOption.dataZoom.push({
                    id: 'dataZoomXSlider',
                    type: 'slider',
                    start: 80,
                    end: 100,
                    // eslint-disable-next-line max-len
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    xAxisIndex: [0],
                    filterMode: 'none'
                });
            }
        }
    }

    resetChartMinMaxConditions(): void {
        this.chartOption.series[0]['markLine'] = {
            id: 'minMax',
            data: []
        };
        delete this.chartOption['visualMap'];
        // this.chartOption.series[0]['markLine'] = {
        //     id: 'minMax',
        //     silent: true,
        //     symbol: 'none',
        //     animation: false,
        //     label: {},
        //     data: []
        // };
        // this.chartOption['visualMap'] = {
        //     id: 'visualMap',
        //     top: 0,
        //     right: 0,
        //     orient: 'horizontal',
        //     pieces: [
        //         {
        //             lte: 0,
        //             color: '#42a7f5'
        //         },
        //         {
        //             gt: 0,
        //             color: '#42a7f5'
        //         }
        //     ],
        //     outOfRange: {
        //         color: '#42a7f5'
        //     }
        //
        // };
    }

    setupChartMinMaxConditions(): void {
        const minMaxConditions = this.sensorConditions.find((element) => element.conditionType === 'MIN_MAX').condition;
        this.chartOption.series[0]['markLine'] = {
            id: 'minMax',
            silent: true,
            symbol: 'none',
            animation: false,
            label: {},
            data: [
                {
                    yAxis: minMaxConditions.min,
                    lineStyle: {
                        color: '#100cf2'
                    }
                },
                {
                    yAxis: minMaxConditions.max,
                    lineStyle: {
                        color: '#f20c0c'
                    }
                },
            ]
        };
        this.chartOption.visualMap = {
            // id: 'visualMap',
            top: 0,
            right: 0,
            orient: 'horizontal',
            pieces: [
                {
                    lte: minMaxConditions.min,
                    color: '#100cf2'
                },
                {
                    gt: minMaxConditions.max,
                    color: '#f20c0c'
                }
            ],
            outOfRange: {
                color: '#42a7f5'
            }
        };
    }

    downloadImage(): void {
        const data = this.eChartData.getDataURL();
        this.downloadURI(data, this.chartOption.title.text + '.png');

        this.eChartData.dispatchAction({
            type: 'saveAsImage',
        });
    }

    downloadURI(uri, name): void {
        const link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    downloadCsv(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'downsampling-selection-dialog';

        const dialogRef = this._matDialog.open(DownsamplingSelectionDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            const data = this.chartOption.series[0].data;
            if (result) {
                const millis = this.toMillis(result['days'], result['hours'], result['minutes']);
                if (result['allData'] || !data.length || millis === 0) {
                    this.downloadCsvFromData(data);
                } else {
                    let startTime = data[0][0];
                    const sampledData = [data[0]];
                    data.forEach(value => {
                        if (value[0].getTime() >= startTime.getTime() + millis && value[1] != null) {
                            sampledData.push(value);
                            startTime = value[0];
                        }
                    });
                    this.downloadCsvFromData(sampledData);
                }
            }
        });
    }

    dataToXlsx(data): XLSX.WorkBook {
        data = data.map(x => [new Date(x[0]).toLocaleString().replace(',', ''), x[1]]);

        const headers = [this._translateService.translate('GENERIC.Datetime'),
            this._translateService.translate('GENERIC.SENSOR.VALUE_TYPES.' + this.sensor.type)];

        switch (this.sensor.type) {
            case 'temp':
                headers[1] += ' (C)';
                break;
            case 'humid':
                headers[1] += ' (%)';
                break;
            case 'volt':
                headers[1] += ' (V)';
                break;
            case 'current':
                headers[1] += ' (A)';
                break;
            case 'power':
                headers[1] += ' (W)';
                break;
            case 'door':
                data = data.map(x => {
                    const val = x[1] ? 'GENERIC.SENSOR.STATE.DOOR.OPEN' : 'GENERIC.SENSOR.STATE.DOOR.CLOSED';
                    const translatedVal = this._translateService.translate(val);
                    return [x[0], translatedVal];
                });
                break;
            case 'relay':
                data = data.map(x => {
                    const val = x[1] ? 'GENERIC.SENSOR.STATE.RELAY.CLOSED' : 'GENERIC.SENSOR.STATE.RELAY.OPEN';
                    const translatedVal = this._translateService.translate(val);
                    return [x[0], translatedVal];
                });
                break;
            case 'rssi':
                headers[1] += ' (dB)';
                break;
        }

        // const str = this._excelExportService.converToCsv(data, headers);
        // this._excelExportService.downloadFile(str, filename);

        data.unshift(headers);
        data.unshift([this.sensor.sensorName]);
        const wb = this._excelExportService.converToXlsx(data);
        const ws = wb.Sheets['Sheet1'];
        const merge = [
            {s: {r: 0, c: 0}, e: {r: 0, c: 1}},
        ];
        ws['!merges'] = merge;
        return wb;
    }

    downloadCsvFromData(data): void {
        let filename = this._translateService.translate('CHARTS.HISTORY.' + this.sensor.type);
        filename += ' ' + this.sensor.sensorName;
        const wb = this.dataToXlsx(data);
        this._excelExportService.downloadXlsx(wb, filename);
    }

    toMillis(days, hours, minutes): number {
        return (days * 24 * 60 + hours * 60 + minutes) * 60 * 1000;
    }

    print(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        // dialogConfig.minHeight = '50%';
        // dialogConfig.minWidth = '80%';
        dialogConfig.panelClass = 'export-selection-dialog';
        dialogConfig.data = {
            title: 'SENSOR_CHART.EXPORT_SELECTOR.TITLE.PRINT'
        };
        const dialogRef = this._matDialog.open(ExportSelectionDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                const type = response.type;
                if (type === 'image') {
                    this.printImage();
                } else {
                    const dialogConfig2 = new MatDialogConfig();
                    dialogConfig2.disableClose = false;
                    dialogConfig2.autoFocus = false;
                    // dialogConfig.minHeight = '50%';
                    // dialogConfig.minWidth = '80%';
                    dialogConfig2.panelClass = 'downsampling-selection-dialog';

                    const dialogRef2 = this._matDialog.open(DownsamplingSelectionDialog, dialogConfig2);

                    dialogRef2.afterClosed().subscribe(result => {
                        if (result) {
                            const data = this.chartOption.series[0].data;
                            const millis = this.toMillis(result['days'], result['hours'], result['minutes']);
                            if (result['allData'] || !data.length || millis === 0) {
                                this.printExcel(data);
                            } else {
                                let startTime = data[0][0];
                                const sampledData = [data[0]];
                                data.forEach(value => {
                                    if (value[0].getTime() >= startTime.getTime() + millis && value[1] != null) {
                                        sampledData.push(value);
                                        startTime = value[0];
                                    }
                                });
                                this.printExcel(sampledData);
                            }
                        }
                    });
                }
            }
        });

    }

    printImage(): void {
        const img = this.eChartData.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff',
            excludeComponents: ['toolbox', 'dataZoom']
        });
        let windowContent = '<!DOCTYPE html>';
        windowContent += '<html>' +
            '<head>' +
            '<meta charset="utf-8">' +
            '<meta http-equiv="X-UA-Compatible" content="IE=edge">' +
            '<meta name="viewport" content="width=device-width, initial-scale=1">' +
            '<meta name="description" content="">' +
            '<meta name="author" content="">' +
            '<title>' + ' πηγή: Sensorbase.io</title></head>' +
            '<body onload="window.print()">' +
            '<img style="width: 100%" src="' + img + '">' +
            '</body>' +
            '</html>';
        const printWin = window.open('', '', 'width=1500,height=700');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
    }

    printExcel(data): void {
        const wb = this.dataToXlsx(data);
        const ws = wb.Sheets['Sheet1'];
        this._excelExportService.printSheet(ws);
    }

}
