import {BaseModel} from './base.model';
import { USER_CONTENT_URL } from 'environments/environment';

export interface IContactDetail {
    id: string;
    value: string;
    type: string;
    verified: boolean;
    parent_id: string;
    notifications: boolean;
}

export interface IContact {
    id: string;
    name: string;
    nickname: string;
    type: string;
    company: string;
    occupation: string;
    city: string;
    street: string;
    zip: string;
    vatNum: string;
    doy: string;
    languageId: string;
    contactDetails: IContactDetail[];
    favouriteIds: string[];
}

export class Contact extends BaseModel implements IContact {
    id: string;
    avatar: string;
    name: string;
    nickname: string;
    type: string;
    company: string;
    occupation: string;
    city: string;
    street: string;
    zip: string;
    vatNum: string;
    doy: string;
    languageId: string;
    contactDetails: IContactDetail[];
    favouriteIds: string[];

    /**
     * Constructor
     *
     * @param contact
     */
    constructor(contact) {
        super();
        this.avatar = contact.avatar || '';
        this.name = contact.name || '';
        this.nickname = contact.nickname || '';
        this.id = contact.id || '';
        this.type = contact.type || '';
        this.company = contact.company || '';
        this.occupation = contact.occupation || '';
        this.city = contact.city || '';
        this.street = contact.street || '';
        this.zip = contact.zip || '';
        this.vatNum = contact.vatNum || '';
        this.doy = contact.doy || '';
        this.languageId = contact.languageId || '';
        this.contactDetails = contact.contactDetails || [];
        this.favouriteIds = contact.favouriteIds || [];
    }

    getEmail(): string {
        const emailCd = this.contactDetails.filter(cd => {
            return cd.type === 'EMAIL';
        });
        if (emailCd.length > 0) {
            return emailCd[0].value;
        }
        return '';
    }

    getMobile(): string {
        const phoneCd = this.contactDetails.filter(cd => {
            return cd.type === 'MOBILE';
        });
        if (phoneCd.length > 0) {
            return phoneCd[0].value;
        }
        return '';
    }

    getAvatarUrl(): string {
        let avatar = 'assets/images/avatars/profile.jpg';
        if (this.avatar && this.avatar !== ''){
            avatar = USER_CONTENT_URL + this.avatar;
        }
        return avatar;
    }

    public equals(other: Contact): boolean {

        return (
            this.id === other.id &&
            this.avatar === other.avatar &&
            this.name === other.name &&
            this.nickname === other.nickname &&
            this.type === other.type &&
            this.company === other.company &&
            this.occupation === other.occupation &&
            this.city === other.city &&
            this.street === other.street &&
            this.zip === other.zip &&
            this.vatNum === other.vatNum &&
            this.doy === other.doy &&
            this.languageId === other.languageId &&
            this.contactDetails === other.contactDetails
        );
    }
}

