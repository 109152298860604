import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import localeEl from '@angular/common/locales/el';
import {DatePipe, registerLocaleData} from '@angular/common';

import {MqttModule} from 'ngx-mqtt';
import {SensorbaseModule} from '@sensorbase/sensorbase.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../@sensorbase/helpers';

import {MQTT_SERVICE_OPTIONS} from 'environments/environment';

// Services
import {
    AlertService,
    AuthenticationService,
    ContactsService,
    DashboardsService,
    DevicesService,
    NotificationsService,
    SensorsService,
    SensorbaseMqttService,
    UsersService,
    MatPaginationIntlService,
    LanguageService
} from '@sensorbase/services';

import {ImpersonationInterceptor} from '../@sensorbase/helpers/impersonation.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicModule } from '@ionic/angular';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatLuxonDateModule} from '@angular/material-luxon-adapter';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

registerLocaleData(localeEl, 'el-GR');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Material luxon date module
        MatLuxonDateModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),

        SensorbaseModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        IonicModule.forRoot()
    ],
    providers: [
        AlertService,
        AuthenticationService,
        ContactsService,
        DashboardsService,
        DevicesService,
        NotificationsService,
        SensorsService,
        SensorbaseMqttService,
        UsersService,
        LanguageService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ImpersonationInterceptor,
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginationIntlService,
        },
        DatePipe,
        {provide: LOCALE_ID, useValue: 'en-GB'},
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
