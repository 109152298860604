import { Component, ViewEncapsulation, Input, ViewChild, forwardRef } from '@angular/core';
import {FormGroup, FormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor, Validators} from '@angular/forms';

const noop = (): void => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InlineEditableInputComponent),
    multi: true
};

@Component({
    selector: 'inline-editable-input',
    templateUrl: './inline-editable-input.component.html',
    styleUrls: ['./inline-editable-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InlineEditableInputComponent implements ControlValueAccessor {
    @Input()
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    // get accessor
    get value(): any {
        return this.innerValue;
    }

    @Input() required = 'false';

    @ViewChild('inputField', { static: false })
    inputField;

    // Private
    private innerValue: any = ''; // The internal data model
    // Placeholders for the callbacks which are later provided
    // by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    formActive: boolean;
    form: FormGroup;

    constructor(private formBuilder: FormBuilder
    ) {
        // Set the defaults
        this.formActive = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open form
     */
    openForm(): void {
        if (this.required){
            this.form = this.formBuilder.group({
                inputValue: [this.value, Validators.required]
            });
        } else {
            this.form = this.formBuilder.group({
                inputValue: [this.value]
            });
        }
        this.formActive = true;
        this.focusNameField();
        this.onTouchedCallback();
    }

    /**
     * Close form
     */
    closeForm(): void {
        this.formActive = false;
    }

    /**
     * Focus to the name field
     */
    focusNameField(): void {
        setTimeout(() => {
            this.inputField.nativeElement.focus();
        });
    }

    /**
     * On form submit
     */
    onFormSubmit(): void {
        if (this.form.valid) {
            this.value = this.form.getRawValue().inputValue;
            this.formActive = false;
        }
    }

    // From ControlValueAccessor interface
    writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

}
