import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'app-downsampling-selection-dialog',
    templateUrl: './downsampling-selection-dialog.component.html',
    styleUrls: ['./downsampling-selection-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DownsamplingSelectionDialog implements OnInit {

    form: FormGroup;
    days: number[] = [];
    hours: number[] = [];
    minutes: number[] = [];

    constructor(
        public dialogRef: MatDialogRef<DownsamplingSelectionDialog>,
        public translate: TranslocoService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.form = this._formBuilder.group({
            days: [0],
            hours: [0],
            minutes: [0],
            selectAll: [false]
        });

        for (let i = 0; i < 32; i++) {
            this.days.push(i);
        }

        for (let i = 0; i < 25; i++) {
            this.hours.push(i);
        }

        for (let i = 0; i < 61; i++) {
            this.minutes.push(i);
        }
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.dialogRef.close({
            allData: this.form.value.selectAll,
            days: this.form.value.days,
            hours: this.form.value.hours,
            minutes: this.form.value.minutes
        });
    }

}
