<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{title | transloco }}</div>
        <button
            mat-icon-button
            (click)="dialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <div class="p-4 pb-0 m-0">
            <ng-container *ngIf="type==='PHONE' || type==='MOBILE'">
                <div *ngIf="step === 1" class="flex flex-row flex-wrap px-4 items-baseline">
                    <span class="sm:mr-2"> {{step1Text | transloco}} </span>
                    <div class="prose mt-2">{{contactDetail.value}} </div>
                </div>
                <div *ngIf="step === 1" class="w-full p-4">
                    <button mat-flat-button class="w-full" color="primary"
                            (click)="sendVerificationv2()">
                        {{"CONTACT_VERIFICATION_DIALOG.PHONE.STEP1.SEND" | transloco}}
                    </button>
                </div>
                <div *ngIf="step === 2" class="px-4 whitespace-pre-line">
                    {{step2Text | transloco}}
                </div>
                <form *ngIf="step === 2" [formGroup]="formGroup" class="flex flex-row flex-wrap items-baseline p-4 overflow-hidden">
                    <mat-form-field
                        class="flex-auto">
                        <mat-label>PIN</mat-label>
                        <input matInput placeholder="PIN" formControlName="pin" required>
                    </mat-form-field>

                    <button *ngIf="step === 2" mat-flat-button class="ml-2" color="primary"
                            (click)="sendVerificationPin()">
                        Verify
                    </button>
                </form>
            </ng-container>

            <ng-container *ngIf="type==='EMAIL'">
                <div *ngIf="step === 1" class="flex flex-row flex-wrap px-4 items-baseline">
                    <span class="sm:mr-2"> {{step1Text | transloco}} </span>
                    <div class="prose mt-2">{{contactDetail.value}} </div>
                </div>
                <div *ngIf="step === 1" class="w-full p-4">
                    <button mat-flat-button class="w-full" color="primary"
                            (click)="sendVerification()">
                        {{"CONTACT_VERIFICATION_DIALOG.EMAIL.STEP1.SEND" | transloco}}
                    </button>
                </div>
                <div *ngIf="step === 2" class="flex flex-row flex-wrap px-4 items-baseline">
                    <span class="sm:mr-2"> {{step2Text | transloco}} </span>
                </div>
                <div *ngIf="step === 2" class="w-full p-4">
                    <button mat-flat-button class="w-full" color="primary"
                            (click)="onCancel()">
                        {{"CONTACT_VERIFICATION_DIALOG.EMAIL.STEP2.SUCCESS" | transloco}}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

</div>
