<ng-container *ngIf="loaded">
<!-- Commented out in case we need it later! -->
<!-- mat-icon *ngIf="minMaxConditions && minMaxConditions.max < sensor.displayData"-->
<!--              class="warning-icon text-amber-400" svgIcon="error"-->
<!--              matTooltip="Υψηλή ένταση" matTooltipClass="bg-amber-400 text-white"></mat-icon>-->
<!--    <mat-icon *ngIf="minMaxConditions && minMaxConditions.min > sensor.displayData"-->
<!--              class="warning-icon text-amber-400" svgIcon="error"-->
<!--              matTooltip="Χαμηλή ένταση" matTooltipClass="bg-amber-400 text-white"></mat-icon>-->

    <div *ngIf="type==='eCharts'" class="gauge-wrapper">
        <div echarts class="gauge" [initOpts]="eChartsGauseInitOpts" [options]="eChartsGaugeOptions"
             (chartInit)="onChartInit($event)" #gauge>
        </div>
    </div>

    <div *ngIf="type==='ngx-gauge'" fxLayout="row" fxLayoutAlign="center center">
        <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="ngxGaugeOptions" thick="8"
                   [min]="min" [max]="max" size="130" cap="round" label="ένταση ρεύματος" append="Α">
        </ngx-gauge>
    </div>
</ng-container>

