import {IMqttServiceOptions} from "ngx-mqtt";

export {
    USER_CONTENT_URL,
    COUNTRY_CODES
} from './environment.base';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'devapp.sensorbase.io',
    port: 443,
    path: '/mqtt',
    protocol: 'wss',
    clean: false,
    connectOnCreate: false
};

// Remote API
// ==========
export const API_BASE_URL = 'https://apidev.sensorbase.io/v1/';
export const APIV2_BASE_URL = 'https://apidev.sensorbase.io/v2/';
export const AUTH_API_BASE_URL = 'https://authdev.sensorbase.io/v2/';

export const environment = {
    production: true,
    capacitor: false,
    platform: 'web',
    hmr: false
};
