import {
    Component,
    OnInit,
    Inject,
    ViewEncapsulation,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {forkJoin, from, Subject, take} from 'rxjs';
import {FormBuilder} from '@angular/forms';


import {Sensor, Device} from '@sensorbase/models';
import {DevicesService, SensorsService, ContactsService, ContactLinksService} from '@sensorbase/services';

import {fuseAnimations} from '@fuse/animations';
import {HttpProgressDialog} from '@sensorbase/components';
import {SbSensorUtils} from '@sensorbase/utils';


@Component({
    selector: 'sensor-notification-settings-dialog',
    templateUrl: './sensor-notification-settings-dialog.component.html',
    styleUrls: ['./sensor-notification-settings-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SensorNotificationSettingsDialog implements OnInit, OnDestroy {

    device: Device;
    sensor: Sensor;

    sensorConditions: any;
    notifyDelay = 0;

    public show = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _devicesService: DevicesService,
        public dialogRef: MatDialogRef<SensorNotificationSettingsDialog>,
        private _sensorsService: SensorsService,
        private _formBuilder: FormBuilder,
        private _contactLinksService: ContactLinksService,
        private changeDetectorRefs: ChangeDetectorRef,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {

        this.sensor = data.sensor;
        this.device = data.device;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this._sensorsService.getSensorConditions(this.sensor.sensorId)
            .subscribe(sensorConditions => {
                console.log(sensorConditions);
                this.sensorConditions = sensorConditions;
                if (SbSensorUtils.isMinMaxSensor(this.sensor)) {
                    const minMaxConditions = sensorConditions.find((element) => element.conditionType === 'MIN_MAX').condition;
                    this.notifyDelay = minMaxConditions.notify_delay;
                }
                else {
                    const onOffConditions = sensorConditions.find((element) => element.conditionType === 'ON_OFF').condition;
                    this.notifyDelay = onOffConditions.notify_delay;
                }
            });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    toggleNotifications(): void {
        this.sensor.notifications = !this.sensor.notifications;
    }

    onSave(): void {
        console.log('Saving...');

        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            data: {
                state: 'loading',
            }
        });

        let conditionType = '';
        // Update notification delay
        if (SbSensorUtils.isMinMaxSensor(this.sensor)) {
            conditionType = 'MIN_MAX';
        }
        else {
            conditionType = 'ON_OFF';
        }
        const sensorCondition = this.sensorConditions.find((element) => element.conditionType === conditionType);
        sensorCondition.condition.notify_delay = this.notifyDelay;
        this._contactLinksService.saveStuff();
        forkJoin([
            this._sensorsService.updateSensorConditions(this.sensor.sensorId, sensorCondition),
            this._sensorsService.updateSensor(this.sensor)
        ]).subscribe({
            next: () => {
                httpProgressDialog.componentInstance.state = 'success';
                httpProgressDialog.afterClosed().subscribe(() => {
                    this.dialogRef.close();
                });
            },
            error: (err) => {
                httpProgressDialog.componentInstance.state = 'error';
            }
        });
    }

    onCancel(): void {
        console.log('Cancelling');
        this.dialogRef.close();
    }

    toogle(): void {
        this.show = !this.show;
    }
}
