import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, BehaviorSubject, take, switchMap, map, tap, forkJoin} from 'rxjs';

import {AuthenticationService, SensorbaseMqttService} from '@sensorbase/services';

import {APIV2_BASE_URL} from 'environments/environment';
import {Device} from '@sensorbase/models';
import {IMqttMessage} from 'ngx-mqtt';

@Injectable()
export class DevicesService implements Resolve<any> {
    user: any;

    // Private
    private _device: BehaviorSubject<Device | null>;
    private _devices: BehaviorSubject<Device[]>;

    /**
     * Constructor
     *
     * @param _httpClient
     * @param _authService
     * @param _mqttService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService,
        private _mqttService: SensorbaseMqttService
    ) {
        // Set the defaults
        this._device = new BehaviorSubject(null);
        this._devices = new BehaviorSubject([]);
    }

    /**
     * Resolver
     *
     * @param route
     * @param state
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Device[]> {
        return this.getDevices();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Getter for selected board
     */
    get device$(): Observable<Device> {
        return this._device.asObservable();
    }

    /**
     * Getter for boards
     */
    get devices$(): Observable<Device[]> {
        return this._devices.asObservable();
    }

    /**
     * Get devices
     *
     * @returns
     */
    getDevices(): Observable<Device[]> {
        return this._httpClient.get(APIV2_BASE_URL + 'devices').pipe(
            map((response: any[]) => response.map(item => Device.fromApi(item))),
            tap((devices: Device[]) => {
                this._devices.next(devices);
            })
        );
    }

    /**
     * Get device
     *
     * @param deviceId
     * @returns
     */
    getDevice(deviceId: string): Observable<Device> {
        const obs = [this.device$.pipe(take(1)), this.devices$.pipe(take(1))];
        return forkJoin(obs).pipe(
            switchMap((response: [Device, Device[]]) => {
                const thisdevice = response[0];
                const thisdevices = response[1];
                return this._httpClient.get(APIV2_BASE_URL + 'devices/' + deviceId).pipe(
                    map(getResponse => Device.fromApi(getResponse)),
                    tap((device: Device) => {
                        if (thisdevice && thisdevice.deviceId === device.deviceId) {
                            this._device.next(device);
                        }
                        const idx = thisdevices.findIndex(d => d.deviceId === device.deviceId);
                        if (idx >= 0) {
                            thisdevices[idx] = device;
                        } else {
                            thisdevices.push(device);
                        }
                        this._devices.next(thisdevices);
                    })
                );
            })
        );
    }

    /**
     * Update device
     *
     * @returns
     */
    updateDevice(device: any): Observable<Device> {
        const obs = [this.device$.pipe(take(1)), this.devices$.pipe(take(1))];
        return forkJoin(obs).pipe(
            switchMap((response: [Device, Device[]]) => {
                const thisdevice = response[0];
                const thisdevices = response[1];
                return this._httpClient.put<Device>(APIV2_BASE_URL + 'devices/' + device.deviceId, device.toApi()).pipe(
                    map(putResponse => {
                        if (putResponse === null) {
                            return device;
                        }
                        return Device.fromApi(putResponse);
                    }),
                    tap((updatedDevice: Device) => {
                        if (thisdevice && thisdevice.deviceId === updatedDevice.deviceId) {
                            this._device.next(updatedDevice);
                        }
                        const idx = thisdevices.findIndex(d => d.deviceId === updatedDevice.deviceId);
                        if (idx >= 0) {
                            thisdevices[idx] = updatedDevice;
                        } else {
                            thisdevices.push(updatedDevice);
                        }
                        this._devices.next(thisdevices);
                    })
                );
            })
        );
    }

    getLatestFirmware(deviceId: string): Observable<any> {
        return this._httpClient.get(APIV2_BASE_URL + 'devices/' + deviceId + '/latestFwVersion');
    }

    updateDeviceFirware(deviceId: string, payload = '1'): void {
        const userID = AuthenticationService.getUserId();
        this._mqttService.unsafePublish('/' + userID + '/devices//' + deviceId + '/fw_update', payload, 1, false);
    }

    restartDevice(deviceId: string, payload = '1'): void {
        const userID = AuthenticationService.getUserId();
        this._mqttService.unsafePublish('/' + userID + '/devices//' + deviceId + '/restart', payload, 1, false);
    }

    deleteDevice(deviceId: string): Observable<any> {
        const userID = AuthenticationService.getUserId();
        return this.devices$.pipe(
            take(1),
            switchMap((thisdevices: Device[]) => {
                return this._httpClient.delete(APIV2_BASE_URL + 'devices/' + deviceId).pipe(
                    tap(() => {
                        this._mqttService.unsafePublish('/' + userID + '/devices//' + deviceId + '/delete', '1', 1, false);
                        thisdevices = thisdevices.filter(d => d.deviceId !== deviceId);
                        this._devices.next(thisdevices);
                    })
                );
            })
        );
    }

    addDevice(): Observable<any> {
        return this._httpClient.post(APIV2_BASE_URL + 'devices', {});
    }

    subDeviceDisconnect(device): Observable<IMqttMessage> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            const mac = device.deviceId;
            return this._mqttService.observe('/' + userID + '/devices//' + mac + '/disconnect');
        }
    }

    selectDevice(device): void {
        if (device) {
            this.devices$.pipe(take(1)).subscribe((devices: Device[]) => {
                this._device.next(devices.find(d => d.deviceId === device.deviceId));
            });
        } else {
            // Reset selection
            this._device.next(null);
        }
    }

}
