import { Pipe, PipeTransform } from '@angular/core';
import {FormArray} from '@angular/forms';

@Pipe({
    name: 'filterFormArrayByPropertyNeq',
    pure: false
})
export class FilterFormArrayByPropertyNeqPipe implements PipeTransform {

    transform(formArray: FormArray, property: any, propertyValue: any): any {
        if (!formArray || !property) {
            return formArray;
        }
        if (Array.isArray(property)){
            return formArray.controls.filter(f => {
                let isValid = true;
                property.forEach((p, i) => {
                    if (f.value[p] === propertyValue[i]) {
                        isValid = false;
                    }
                });
                return isValid;
            });
        }
        return formArray.controls.filter(i => i.get(property).value !== propertyValue);
    }

}
