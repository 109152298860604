import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    ViewEncapsulation
} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import Muuri from 'muuri';

import {fuseAnimations} from '@fuse/animations';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';

import {SensorsService} from '@sensorbase/services';
import {Sensor, ISensor} from '@sensorbase//models';

@Component({
    selector: 'widgets-container',
    templateUrl: './widgets-container.component.html',
    styleUrls: ['./widgets-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class WidgetsContainerComponent implements OnInit, OnDestroy {

    @Input('members')
    get members(): any[] {
        return this._pmembers;
    }
    set members(val) {
        this._pmembers = val;

        // this._pmembers.push({type: 'chart', id: 'BC-DD-C2-E4-EA-5D:T1'});
        if (JSON.stringify(this._members) !== JSON.stringify(this.members)) {
            // Get removed members
            const removedMembers = this._members.filter(member => {
                return !this.members.some(other => member.id === other.id);
            });
            // Get added members
            const newMembers = this.members.filter(member => {
                return !this._members.some(other => member.id === other.id);
            });

            if (removedMembers.length !== 0 || newMembers.length !== 0) {
                this._members = Object.assign([], this.members);
                this.getWidgets(removedMembers, newMembers);
            } else {
                if (!this._arrangingMembers) {
                    this._members = Object.assign([], this.members);
                    this.widgets = this.sortWidgets(this._members);
                }
            }
        }
    }
    @Input() scrollContainer: HTMLElement;
    @Output() ready: EventEmitter<any> = new EventEmitter();
    @Output() membersUpdated: EventEmitter<any> = new EventEmitter();

    temperature = 30;
    public widgets = [];
    public boardGrid: any;
    public loading = true;
    public widgetSize = null;
    public muuriOptions: Muuri.GridOptions = {
        layoutDuration: 200,
        layoutEasing: 'ease',
        dragEnabled: true,
        dragSortHeuristics: {
            sortInterval: 0
        },
        dragHandle: '.widget-header-content',
        dragPlaceholder: {
            enabled: true
        },
        dragRelease: {
            duration: 200,
            easing: 'ease',
        },
        layout: {
            rounding: false
        },
        dragAutoScroll: {
            targets: []
        }
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    private _pmembers: any[] = [];
    private _members = [];
    private _arrangingMembers = false;

    constructor(
        private _sensorsService: SensorsService,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if (this.scrollContainer) {
            this.muuriOptions.dragAutoScroll.targets = [{
                element: this.scrollContainer,
                axis: Muuri.AutoScroller.AXIS_Y
            }];
        }

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                let shouldResize = false;
                if(!matchingAliases.includes('sm')) {
                    shouldResize = true;
                    this.widgetSize = 'xs';
                } else {
                    if (this.widgetSize === 'xs') {
                        shouldResize = true;
                    }
                    this.widgetSize = null;
                }
                if(this.boardGrid && shouldResize){
                    setTimeout(() => {
                        console.log('Resizing');
                        this.boardGrid.destroy();
                        this.initialiseGrid();
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.destroyGrid();
    }

    isReady(): boolean {
        return !this.loading;
    }

    destroyGrid(): void {
        console.log('Destroying grid....');
        try {
            this.boardGrid.destroy();
            // this.folderChangedSub.unsubscribe();
        } catch (e) {
            console.log('No form to destroy', e);
        }
    }

    initialiseGrid(): void {
        // Instantiate the board grid so we can drag those
        // columns around.
        if (this.widgets.length > 0) {
            this.destroyGrid();
            console.log('Initialising grid...');
            this.boardGrid = new Muuri('.grid', this.muuriOptions);
            this.boardGrid.on('move', (data) => {
                this.arrangeMembers(data);
            });
            this.boardGrid.on('dragEnd', (item, event) => {
                console.log(event);
                console.log(item);
                const members = Object.assign([], this._members);
                this.membersUpdated.emit(members);
                this._arrangingMembers = false;
            });
            this.boardGrid.on('layoutEnd',() => {
                this.loading = false;
            });
            console.log(this.boardGrid);
        }
    }

    getWidgets(removedMembers, newMembers): void {
        const widgets = this.filterWidgets(removedMembers);
        const promises = [];
        this.loading = true;
        const newMembersIds: Set<string> = new Set(newMembers.map(x => x.id));
        this._sensorsService.getSensors(Array.from(newMembersIds)).subscribe((sensors: ISensor[]) => {
            newMembers.forEach(member => {
                const sensor = sensors.find(x => x.sensorId === member.id);
                widgets.push({
                    type: member.type,
                    id: member.id,
                    sensor: new Sensor(sensor)
                });
            });
            // sensors.forEach(sensor => {
            //     if (sensor) {
            //         widgets.push({
            //             type: 'sensor',
            //             id: sensor.sensorId,
            //             sensor: new Sensor(sensor)
            //         });
            //     }
            // });
            this.widgets = this.sortWidgets(widgets);
            setTimeout(() => this.initialiseGrid());
        });
    }


    filterWidgets(removedMembers): any[] {
        return this.widgets.filter(widget => {
            return !removedMembers.some(other => widget.id === other.id);
        });
    }

    sortWidgets(widgets): any[] {
        const sortedWidgets = [];
        this.members.forEach(member => {
            const widget = widgets.find(w => (w.id === member.id && w.type === member.type));
            if (widget) {
                sortedWidgets.push(widget);
            }
        });
        return sortedWidgets;
    }

    arrangeMembers(data): void {
        this._arrangingMembers = true;
        console.log('Arranging members!');
        console.log(data);
        if (data.toIndex >= this._members.length) {
            console.log('Here');
            let k = data.toIndex - this._members.length + 1;
            while (k--) {
                this._members.push(undefined);
            }
        }
        this._members.splice(data.toIndex, 0, this._members.splice(data.fromIndex, 1)[0]);
    }

    onWidgetDeleted(type, widget): void {
        console.log(this.widgets);
        const members = this._members.filter(member => member.id !== widget.id);
        this.membersUpdated.emit(members);
    }

    onWidgetResized(): void {
        setTimeout(() => {
            this.boardGrid.refreshItems();
            this.boardGrid.layout();
        });
    }
}
