<!-- Notifications toggle -->
<button *ngIf="(users.length>0) && !impersonating"
        mat-icon-button
        (click)="openPanel()"
        [matTooltip]="'GENERIC.Impersonate User' | transloco"
        #impersonationOrigin>
    <mat-icon [svgIcon]="'screen_share'"></mat-icon>
</button>

<button *ngIf="impersonating"
        mat-icon-button
        color="warn"
        (click)="onStopImpersonation()"
        [matTooltip]="'GENERIC.Stop Impersonation' | transloco">
    <mat-icon [svgIcon]="'stop_screen_share'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #impersonationPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10" [transloco]="'GENERIC.Impersonate User'">
            </div>
            <div class="ml-auto">
                <button
                    mat-icon-button
                    [matTooltip]="showSearch ? 'Close Search' : 'Search'"
                    (click)="toggleSearch()">
                    <mat-icon
                        class="icon-size-6 text-current"
                        [svgIcon]="showSearch ? 'search_off' : 'search'"></mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="showSearch" class="flex shrink-0 items-center pb-4 pr-4 pl-6 bg-primary">
            <mat-form-field @expandCollapse class="flex-auto fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-42">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input #searchInputField
                    matInput
                    [formControl]="searchInput"
                    [autocomplete]="'off'"
                    [placeholder]="'USERS.SEARCH' | transloco">
            </mat-form-field>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let user of filteredUsers">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 cursor-pointer"
                     (click)="onSelectUser(user)">
                    <div class="flex flex-auto py-5 pl-6">
                        {{user.email}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
