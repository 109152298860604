import {BaseModel} from './base.model';

export interface IAlertData {
    title?: string;
    body: string;
}

export interface IAlert {
    type?: string;
    title?: string;
    body: string;
    delay?: number;
}

export class Alert extends BaseModel implements IAlert {
    type: string;
    title: string;
    body: string;
    delay: number;
    guid: string;

    constructor(data: IAlert) {
        super();
        this.type = data.type || 'info';
        this.title = data.title || '';
        this.body = data.body;
        this.delay = data.delay ;
    }
}
