import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../auth';

import {APIV2_BASE_URL} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AllowancesService {

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService,
    ) {
    }

    getAllowances(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(APIV2_BASE_URL + 'userAllowances')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
