import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {shareReplay, first} from 'rxjs/operators';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import {APIV2_BASE_URL, API_BASE_URL} from 'environments/environment';

import {AuthenticationService} from '@sensorbase/services';
import {User} from '@sensorbase/models';

@Injectable()
export class UsersService {

    user: any = null;
    pending = false;
    lastUpdated: Date = null;
    private _onUserDataChanged: Subject<any> = new Subject();
    onUserDataChanged = this._onUserDataChanged.pipe(shareReplay(1));

    _users: any[] = [];

    private _usersSub: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

    constructor(private _httpClient: HttpClient,
                private _authService: AuthenticationService) {
        this.onUserDataChanged.subscribe(() => {
            if (this.user.accountType === 99 && this._users.length === 0) {
                // console.log("Authenticated gitlab");
                this._httpClient.get(APIV2_BASE_URL + 'users/').subscribe((users: any[]) => {
                    this._users = users.filter((user) => user.userId !== this.user.userId);
                    this._usersSub.next(this._users);
                });
            }
        });
    }

    get users$(): Observable<User[]>{
        return this._usersSub.asObservable();
    }

    async getUser(): Promise<any> {
        if (this._authService.loggedIn()) {
            if (this.user == null && !this.pending) {
                this.pending = true;
                // const userID = this._authService.getUserId();
                this._httpClient.get(APIV2_BASE_URL + 'users/self').subscribe((response: any) => {
                    // console.log("Got User", response);
                    this.user = User.fromApi(response);
                    this._onUserDataChanged.next(this.user);
                    this.lastUpdated = new Date();
                    this.pending = false;
                }, (error) => {
                    console.log('error getting user', error);
                    this.pending = false;
                });
            }
        } else {
            throw new Error('Not authenticated');
        }
        return this.onUserDataChanged.pipe(first()).toPromise();
    }

    changeWebappSettings(webappSettings: object): void {

        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            if (this.user) {
                this._httpClient.put(APIV2_BASE_URL + 'users/webappSettings', {webappSettings: JSON.stringify(webappSettings)}).subscribe(() => {
                    console.log('user update complete', webappSettings);
                    this.user.webappSettings = webappSettings;
                    this._onUserDataChanged.next(this.user);
                }, (error) => {
                    console.error('Error updating user: ' + error);
                });
            }
        }
    }

    changePassword(password: string): Observable<any> {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            return this._httpClient.put(APIV2_BASE_URL + 'users/changePassword', {newPassword: password});
        } else {
            return throwError(() => 'Not Authenticated');
        }
    }

    changeLanguage(languageId): void {
        if (this._authService.loggedIn()) {
            const userID = this._authService.getUserId();
            this._httpClient.put(API_BASE_URL + 'users/' + userID, {languageId: languageId}).subscribe(() => {
                // eslint-disable-next-line no-console
                console.debug('user update complete');
                this.user.languageId = languageId;
                this._onUserDataChanged.next(this.user);
            }, (error) => {
                console.error('Error updating user: ' + error);
            });
        }
    }

    setAlexaCredentials(user): void {
        if (this._authService.loggedIn()) {
            this._httpClient.post(APIV2_BASE_URL + 'users/alexaCredentials', {
                userId: user.userId,
                alexaCode: user.alexaCode,
                alexaScope: user.alexaScope
            }).subscribe({
                next: (res): void => {
                    // eslint-disable-next-line no-console
                    console.debug('user update complete');
                    this.user = res;
                    this._onUserDataChanged.next(this.user);
                },
                error: (error): void => {
                    console.error('Error updating user: ' + error);
                }
            });
        }
    }
}
