import {BaseModel} from './base.model';
import {SbUtils as utils} from '../utils';
import {TranslocoService} from '@ngneat/transloco';
import {Sensor} from './sensor.model';
import {LocalNotificationSchema} from '@capacitor/local-notifications';
import {IAlert} from './alert.model';

export interface INotification {
    objectName: string;
    id: number;
    objectId: string;
    objectType: string;
    timestamp: Date;
    type: string;
    typeDescription: string;
    viewed: boolean;
    description: string;
    icon: string;
    cssClass: string;
}

export class Notification extends BaseModel implements INotification {
    objectName: string;
    id: number;
    objectId: string;
    objectType: string;
    timestamp: Date;
    type: string;
    typeDescription: string;
    viewed: boolean;
    description: string;
    icon: string;
    cssClass: string;

    constructor(data: INotification) {
        super();
        this.objectName = data.objectName;
        this.id = data.id;
        this.objectId = data.objectId;
        this.objectType = data.objectType;
        this.timestamp = new Date(data.timestamp);
        this.type = data.type;
        this.typeDescription = data.typeDescription;
        this.viewed = data.viewed;
        this.description = data.description || '';
        this.icon = data.icon || '';
        this.cssClass = data.cssClass || '';
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            const exclude = ['description', 'icon', 'cssClass'];
            if (!exclude.includes(key)) {
                dataApi[utils.snakeCase(key)] = value;
            }
        });
        return dataApi;
    }

    toNativeNotification(translateService: TranslocoService): LocalNotificationSchema {
        const notification = new Notification(this);
        notification.description = notification.getDescription();
        console.log(notification.description);
        return {
            title: translateService.translate(notification.description),
            body: this.objectName,
            id: this.id,
        };
    }

    toAlert(translateService?: TranslocoService): IAlert {
        const goodNotifications = ['IN_RANGE', 'STATUS_ON', 'STATUS_NEW', 'CLOSED'];
        const badNotifications = ['OUT_OF_RANGE', 'STATUS_OFF', 'OPEN', 'MAX', 'MIN'];
        let type = 'info';
        if (goodNotifications.includes(this.typeDescription)) {
            type = 'success';
        } else if (badNotifications.includes(this.typeDescription)) {
            type = 'warning';
        }
        let title = this.getDescription();
        if (translateService) {
            title = translateService.translate(title);
        }
        const text = this.objectName;
        return {type: type, title: title, body: text};
    }

    getDescription(sensors?: Sensor[]): string {
        let text = 'NOTIFICATIONS.DESCRIPTIONS.' +
            (this.objectType ? this.objectType.toUpperCase() : '') + '.' +
            (this.typeDescription ? this.typeDescription.toUpperCase() : '');
        if (this.objectType === 'door' && sensors !== undefined){
            const sensor = sensors.find(x => x.sensorId === this.objectId);
            if (sensor) {
                const typeDescription = this.typeDescription;
                switch (typeDescription){
                    case 'OPEN':
                        if (sensor.options['openText'] ) {
                            text = sensor.options['openText'];
                        }
                        break;
                    case 'CLOSED':
                        if (sensor.options['closedText']) {
                            text = sensor.options['closedText'];
                        }
                        break;
                }
            }
        }
        return text;
    }

}
