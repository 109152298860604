import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';

import {Sensor} from '@sensorbase/models';
import {SensorsService} from '@sensorbase/services';

@Component({
    selector: 'sb-rssi-display',
    templateUrl: './rssi-display.component.html'
})
export class RssiDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;
    @Input() type = 'ngx-gauge';
    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        this._size = val;
    }

    rssiIcon = 'fa_duotone:signal-slash';

    iconClass = 'text-red-500';
    infoText = 'No Signal';

    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    constructor(private _sensorsService: SensorsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.processRssiIcon();
        this.processInfoText();
        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.processRssiIcon();
                    this.processInfoText();
                });

            this._sensorsService.subSensorDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    // console.log("Data Received: "+this.sensor.sensorId , message.payload.toString());
                    if (message.payload.toString() === '1'){
                        this.sensor.status = 0;
                    }
                    this.processRssiIcon();
                    this.processInfoText();
                });
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    processRssiIcon(): void {
        if (this.sensor.status <= 0) {
            this.rssiIcon =  'fa_duotone:signal-slash';
            this.iconClass = 'text-red-500';
            return;
        }
        if (this.sensor.data['current'] < -90) {
            this.rssiIcon = 'fa_duotone:signal-1';
            this.iconClass = 'text-red-500';
        } else if (this.sensor.data['current'] < -80) {
            this.rssiIcon = 'fa_duotone:signal-2';
            this.iconClass = 'text-orange-900';
        } else if (this.sensor.data['current'] < -70) {
            this.rssiIcon = 'fa_duotone:signal-3';
            this.iconClass = 'text-amber-500';
        } else if (this.sensor.data['current'] < -60) {
            this.rssiIcon = 'fa_duotone:signal-4';
            this.iconClass = 'text-lime-400';
        } else {
            this.rssiIcon = 'fa_duotone:signal';
            this.iconClass = 'text-green-500';
        }
    }

    processInfoText(): void {
        if (this.sensor.status <= 0) {
            this.infoText = 'No Signal';
            return;
        }
        this.infoText = Math.round(this.sensor.data['current']).toString() + ' dB';
    }
}
