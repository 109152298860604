import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MqttIndicatorComponent} from './mqtt-indicator.component';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
    declarations: [
        MqttIndicatorComponent
    ],
    imports: [
        CommonModule,

        MatTooltipModule
    ],
    exports: [
        MqttIndicatorComponent
    ]
})
export class MqttIndicatorModule {
}
