import * as _ from 'lodash';
import {AbstractControl, ValidationErrors, ValidatorFn, Validators, FormControl} from '@angular/forms';
import {PhoneValidators} from 'ngx-phone-validators';
import validator from 'validator';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

import {COUNTRY_CODES} from 'environments/environment';

export class SbUtils{

    public static snakeCase(str): string
    {
        return _.snakeCase(str);
    }

    public static camelCase(str): string
    {
        return _.camelCase(str);
    }

    public static validateMobilePhone(c: FormControl): object {
        console.log(c.value);
        if (validator.isMobilePhone(c.value + '', ['el-GR', 'en-GB'], {strictMode: true}) || !c.value) {
            return null;
        } else {
            return {invalidPhone: true};
        }
    }

    public static validateLandlinePhone(c: FormControl): object {
        console.log(c.value);
        if (c.value) {
            let errors0 = null;
            if (c.value.substring(0, 1) !== '+') {
                errors0 = {missingRegionCode: true};
            }
            const errors1 = Validators.compose([PhoneValidators.isPossibleNumberWithReason('GR')])(c);
            const errors2 = Validators.compose([PhoneValidators.isPossibleNumberWithReason('GB')])(c);
            console.log(errors0);
            console.log(errors1);
            console.log(errors2);
            if (errors0) {
                return errors0;
            } else if (errors1 && errors2) {
                return Object.assign(errors1, errors2);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public static validateContactPhone(control: FormControl): object {

        if (!control.parent || !control) {
            return null;
        }

        if (control.value) {
            try{
                const countryIso = control.parent.get('country').value;
                const countryCode = COUNTRY_CODES.find(c => c.iso === countryIso).code;
                const phoneNo = countryCode + control.parent.get('value').value;
                const gPhoneNo = phoneNumberUtil.parse(phoneNo);
                if (!phoneNumberUtil.isValidNumberForRegion(gPhoneNo, countryIso.toUpperCase())) {
                    control.parent.get('country').setErrors({invalidNumber: true});
                    control.parent.get('value').setErrors({invalidNumber: true});
                    return {invalidNumber: true};
                }
                control.parent.get('value').setErrors(null);
                control.parent.get('country').setErrors(null);
                return null;
            } catch (e) {
                return {invalidNumber: true};
            }


            // const newControl = new FormControl([phoneNo]);
            // const errors = Validators.compose([PhoneValidators.isPossibleNumberWithReason(countryIso.toUpperCase())])(newControl);
            // if (errors) {
            //     return errors;
            // } else {
            //     return null;
            // }
        } else {
            control.parent.get('country').setErrors({invalidNumber: true});
            control.parent.get('value').setErrors({invalidNumber: true});
            return {invalidNumber: true};
        }
    }

    /**
     * Confirm password validator
     *
     * @param control
     * @returns
     */
    public static confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control.parent || !control) {
            return null;
        }

        const password = control.parent.get('password');
        const passwordConfirm = control.parent.get('passwordConfirm');

        if (!password || !passwordConfirm) {
            return null;
        }

        if (passwordConfirm.value === '') {
            return null;
        }

        if (password.value === passwordConfirm.value) {
            return null;
        }

        return {'passwordsNotMatching': true};
    };

    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns
     */
    public static filterArrayByString(mainArr, searchText): any
    {
        if ( searchText === '' )
        {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => this.searchInObj(itemObj, searchText));
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns
     */
    public static searchInObj(itemObj, searchText): boolean
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns
     */
    public static searchInArray(arr, searchText): boolean
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns
     */
    public static searchInString(value, searchText): any
    {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns
     */
    public static generateGUID(): string
    {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions,@typescript-eslint/naming-convention
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns
     */
    public static handleize(text): string
    {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }
}
