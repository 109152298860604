import {Injectable} from '@angular/core';
import {getBrowserLang, TranslocoService} from '@ngneat/transloco';
import {DateAdapter} from '@angular/material/core';

@Injectable()
export class LanguageService {
    constructor(
        private _translateService: TranslocoService,
        private _dateAdapter: DateAdapter<any>,
    ) {
        if (localStorage.getItem('languageId') === null) {
            if (localStorage.getItem('sbSession') === null) {
                console.log('Using browser language');
                let browserLang = getBrowserLang();
                if (browserLang !== 'en-GB' && browserLang !== 'el-GR') {
                    browserLang = 'en-GB';
                }
                localStorage.setItem('languageId', browserLang);
            } else {
                console.log('Using user\'s language');
                localStorage.setItem(
                    'languageId',
                    JSON.parse(localStorage.getItem('sbSession')).language
                );
            }
        } else {
            console.log('Using locally stored language');
        }
    }

    getLanguage(): string {
        return localStorage.getItem('languageId');
    }

    setLanguage(languageId: string): void {
        localStorage.setItem('languageId', languageId);
        this._dateAdapter.setLocale(languageId);
        this._translateService.setActiveLang(languageId);
    }
}
