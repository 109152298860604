<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'ICON.SELECTION_DIALOG.TITLE' | transloco }}</div>
        <button
            mat-icon-button
            (click)="dialogRef.close()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <div class="px-4 mt-2">
            <form [formGroup]="searchForm" (ngSubmit)="filterIcons()">
                <mat-form-field class="w-full">
                    <input matInput formControlName="filter" placeholder="{{'ICON.SELECTION_DIALOG.SEARCH' | transloco}}">
                    <button mat-icon-button matSuffix matTooltip="Apply">
                        <mat-icon  svgIcon="done"></mat-icon>
                    </button>
                </mat-form-field>
            </form>
        </div>

        <div mat-dialog-content class="p-4 pt-0 m-0">

            <div fxLayout="column">

                <div fxLayout="row wrap">

                    <div (click)="setIcon(icon.name);" class="icon-holder m-4" fxLayout="column" fxLayoutAlign="center center"
                         *ngFor="let icon of filteredIconsLazy" [matTooltip]="icon.name">
                        <button mat-icon-button>
                            <mat-icon class="text-secondary s-24" [svgIcon]="icon.name"></mat-icon>
                        </button>
                        <!-- <div class="icon-name text-secondary mt-8">{{icon}}</div> -->
                    </div>

                </div>
                <button mat-raised-button type="button" color="primary" *ngIf="filteredIconsLazy.length<filteredIcons.length"
                        (click)="loadMore()" transloco="ICON.SELECTION_DIALOG.LOAD_MORE">
                </button>

            </div>
        </div>

<!--    <div class="btn-group" fxLayout="row" fxLayoutAlign="end center">-->
<!--        <button mat-button (click)="dialogRef.close(selectedIcon)" aria-label="Confirm">-->
<!--            CONFIRM-->
<!--        </button>-->
<!--        <button mat-button (click)="dialogRef.close()" aria-label="Close dialog">-->
<!--            CLOSE-->
<!--        </button>-->
<!--    </div>-->
    </div>
</div>
