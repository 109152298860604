import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {uiIcons} from '../../../../utils';

@Component({
    selector: 'app-icon-selection-dialog',
    templateUrl: './icon-selection-dialog.component.html',
    styleUrls: ['./icon-selection-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IconSelectionDialogComponent implements OnInit {
    icons: any[];
    filter = '';
    filteredIcons: any[];
    filteredIconsLazy: any[];

    scrollEnd = 100;

    selectedIcon: string;

    searchForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<IconSelectionDialogComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.searchForm = this._formBuilder.group({
            filter: ['']
        });
    }

    ngOnInit(): void {
        this.icons = uiIcons;
        this.filteredIcons = this.icons;
        this.filteredIconsLazy = this.filteredIcons.slice(1, this.scrollEnd);
    }

    /**
     * Set the color
     *
     * @param icon
     */
    setIcon(icon): void {
        this.selectedIcon = icon;
        this.dialogRef.close(this.selectedIcon);
    }

    /**
     * Filter icons
     *
     * @param event
     */
    filterIcons(): void {
        this.scrollEnd = 90;
        const value = this.searchForm.value.filter;
        // console.log(this.icons);
        this.filteredIcons = this.icons.filter(icon => {
            return icon.name.includes(value);  // || icon.tags.includes(value);
        });
        this.filteredIconsLazy = this.filteredIcons.slice(0, this.scrollEnd);
        console.log(this.scrollEnd);
        console.log(this.filteredIconsLazy);
    }

    loadMore(): void {
        this.scrollEnd += 90;
        this.filteredIconsLazy = this.filteredIcons.slice(0, this.scrollEnd);
    }


}
