import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {AuthenticationService} from '@sensorbase/services';

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {
    private authenticated: boolean;

    constructor(
        private _router: Router
    ) {
    }

    addImpersonationHeader(request): any {
        if (sessionStorage.getItem('ImpersonationUserId')){
            request = request.clone({
                setHeaders: {
                    ImpersonationUserId: sessionStorage.getItem('ImpersonationUserId')
                }
            });
        }
        
        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addImpersonationHeader(request));
    }
}
