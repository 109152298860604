import {Component, OnInit, Inject, ViewChild, ViewEncapsulation, TemplateRef, OnDestroy} from '@angular/core';
import {Sensor, Device} from '@sensorbase/models';
import {DevicesService, SensorsService} from '@sensorbase/services';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Subject, takeUntil} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {HttpProgressDialog, RelaySchedulesDialogComponent} from '@sensorbase/components';
import {Schedule, SensorFunction, SensorAutomationService} from './sensor-automation-service.service';
import {FuseMediaWatcherService} from '../../../../../../../@fuse/services/media-watcher';
import {settings} from '../../../../../../../app/mock-api/apps/mailbox/data';
import {
    FuseConfirmationConfig,
    FuseConfirmationService
} from '../../../../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'sensor-automations-setting-dialog',
    templateUrl: './sensor-automations-setting-dialog.component.html',
    styleUrls: ['./sensor-automations-setting-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SensorAutomationsSettingDialog implements OnInit, OnDestroy {

    @ViewChild('dialogContent', {static: false})
    dialogContent: TemplateRef<any>;

    @ViewChild(MatPaginator, {static: false})
    paginator: MatPaginator;

    sensor: Sensor;
    device: Device;
    functions: SensorFunction[];
    defaultPosition: boolean;

    displayedColumns = ['checkbox', 'description', 'settings'];
    dataSource: MatTableDataSource<SensorFunction>;

    showHelp = false;
    isScreenSmall = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _devicesService: DevicesService,
        public _matDialog: MatDialog,
        public dialogRef: MatDialogRef<SensorAutomationsSettingDialog>,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _automationService: SensorAutomationService,
        private _translateService: TranslocoService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _sensorsService: SensorsService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {

        this.sensor = data.sensor;
        this.device = data.device;

        this._unsubscribeAll = new Subject();

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode and drawerOpened
                this.isScreenSmall = !matchingAliases.includes('sm');
            });
    }

    ngOnInit(): void {
        this.defaultPosition = this.sensor.options.default_value !== 'OFF';

        this._automationService._getSensorFunctions(this.sensor.sensorId).subscribe(response => {
                this.functions = [];
                response.forEach(item => {
                    console.log(item);
                    const funct = SensorFunction.fromApi(item);
                    this.functions.push(funct);
                });
                // Assign the data to the data source for the table to render.
                this.dataSource = new MatTableDataSource(this.functions);
            });
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    openFunctionDialog(funct): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.data = this.sensor;
        dialogConfig.panelClass = ['relay-schedules-dialog'];
        if (this.isScreenSmall){
            dialogConfig.height = '100%';
            dialogConfig.width = '100%';
            dialogConfig.maxWidth = '100%';
            dialogConfig.panelClass.push('small-screen');
        }


        dialogConfig.data = {
            sensor: this.sensor,
            device: this.device,
            function: funct
        };
        const dialogRef = this._matDialog.open(RelaySchedulesDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result) {
                const idx = this.functions.findIndex(x => x.id === result.id);
                this.functions[idx] = result;
                this.dataSource = new MatTableDataSource(this.functions);
            }
        });
    }

    // Creates new user.
    createNewFunction(id: number): SensorFunction {
        const schedule = new Schedule({
            type: 'FULL',
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            dayOfWeek: [1, 2, 3, 4, 5, 6, 7],
            apiAction: 'add'
        });
        console.log(schedule);
        return new SensorFunction({
            id: 0,
            name: 'Sample SensorFunction',
            sensorId: this.sensor.sensorId,
            schedule: schedule,
            apiAction: 'add'
        });
    }

    onFunctionEnableToggled(event, funct): void {
        console.log(funct);
        funct.apiAction = 'update';
        const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
            disableClose: true,
            data: {
                state: 'loading',
            }
        });
        this._automationService.saveFunction(funct).then(
            response => {
                httpProgressDialog.componentInstance.state = 'success';
                funct.apiAction = null;
            },
            error => {
                httpProgressDialog.componentInstance.state = 'error';
                funct.enabled = !funct.enabled;
                funct.apiAction = null;
            }
        );
    }

    // Adds new user.
    addRow(): void {
        const funct = this.createNewFunction(this.dataSource.data.length + 1);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.data = this.sensor;
        dialogConfig.panelClass = 'relay-schedules-dialog';
        this._devicesService.getDevice(this.sensor.deviceId)
            .subscribe((response) => {
                const device = new Device(response);
                dialogConfig.data = {
                    sensor: this.sensor,
                    device: device,
                    function: funct
                };
                const dialogRef = this._matDialog.open(RelaySchedulesDialogComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.functions.push(result);
                        this.dataSource = new MatTableDataSource(this.functions);
                    }
                });
            });
    }


    deleteRow(event, row, index): void {
         const dialogConfig: FuseConfirmationConfig = {
            title: this._translateService.translate('FUNCTIONS.FUNCTION.DELETE_FUNCTION_DIALOG.TITLE'),
            message: this._translateService.translate('FUNCTIONS.FUNCTION.DELETE_FUNCTION_DIALOG.MESSAGE', row),
            icon: {
                name: 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions: {
                confirm: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CONFIRM'),
                    color: 'warn'
                },
                cancel: {
                    label: this._translateService.translate('CONFIRM_DIALOG.BUTTONS.CANCEL')
                }
            }
        };

        const dialogRef = this._fuseConfirmationService.open(dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirmed') {
                row.apiAction = 'delete';
                const httpProgressDialog = this._matDialog.open(HttpProgressDialog, {
                    disableClose: true,
                    data: {
                        state: 'loading',
                    }
                });
                this._automationService.updateFunction(row).subscribe({
                    next: response => {
                        this.functions.splice(index, 1);
                        this.dataSource = new MatTableDataSource(this.functions);
                        httpProgressDialog.componentInstance.state = 'success';
                    },
                    error: error => {
                        httpProgressDialog.componentInstance.state = 'error';
                        row.apiAction = null;
                    }
                });
            }
        });
        event.stopPropagation();
    }

    onCancel(): void {
        console.log('Cancelling');
        this.dialogRef.close();
    }

    toggleHelp(): void {
        this.showHelp = !this.showHelp;
    }

}
