<div class="flex flex-col justify-between items-center relative h-full w-full">
    <div *ngIf="sensors.length>0 && loaded" class="w-full h-full">
        <button mat-stroked-button type="button" class="absolute top-0 right-0 p-2 min-w-0" [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="cloud_download"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="downloadImage()">
                <mat-icon svgIcon="mat_outline:image"></mat-icon>
                <span>{{'SENSOR_CHART.EXPORT_SELECTOR.IMAGE' | transloco}}</span>
            </button>
            <button mat-menu-item (click)="onExport()">
                <mat-icon svgIcon="fa_regular:file-spreadsheet"></mat-icon>
                <span>{{'SENSOR_CHART.EXPORT_SELECTOR.SPREADSHEET' | transloco}}</span>
            </button>
            <button mat-menu-item (click)="onPrint()">
                <mat-icon svgIcon="mat_outline:print"></mat-icon>
                <span>{{'SENSOR_CHART.EXPORT_SELECTOR.PRINT' | transloco}}</span>
            </button>
        </mat-menu>
        <div echarts [options]="chartOption" [merge]="updateOptions" (chartInit)="onChartInit($event)" class="demo-chart w-full min-h-90 sm:h-full"
             [style.height]="options.height" [theme]="theme"></div>
    </div>
    <ng-container *ngIf="sensors.length === 0 && loaded">
        <div class="flex flex-col flex-auto overflow-y-auto lg:overflow-hidden p-4 w-full min-h-90 sm:h-full">
            <div class="flex flex-col flex-auto items-center justify-center border-2 border-dashed border-gray-300 rounded-2xl">
                <mat-icon
                    class="icon-size-24 animate-bounce"
                    [svgIcon]="'heroicons_outline:chevron-double-down'"></mat-icon>
                <div class="mt-4 px-4 text-2xl font-semibold tracking-tight text-secondary text-center"
                     [transloco]="'SENSOR_CHART.MULTI.SELECT_SENSORS'"></div>
            </div>
        </div>
    </ng-container>
    <div *ngIf="!loaded" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-spinner class="mb-20" [diameter]="50"></mat-spinner>
        {{loadingText}}
        <!-- <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar> -->
    </div>
    <div *ngIf="options.showDatePicker" class="flex flex-row justify-center items-center mt-2">
        <mat-form-field class="w-64">
            <mat-label [transloco]="'GENERIC.Date range'"></mat-label>
            <mat-date-range-input [max]="today" [rangePicker]="picker">
                <input matStartDate [value]="startDate" (dateChange)="onDateChanged('start', $event)"
                       placeholder="Start date">
                <input matEndDate [value]="endDate" (dateChange)="onDateChanged('end', $event)" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (opened)="datePickerOpen=true"
                                   (closed)="onDatePickerClosed($event);">
            </mat-date-range-picker>
        </mat-form-field>
    </div>
</div>
