
export enum SensorType {
    Temperature = 'temp',
    Humidity = 'humid',
    Switch = 'door',
    Relay = 'relay',
    Current = 'current',
    Voltage = 'volt',
    Power = 'power',
    Energy = 'energy',
    RSSI = 'rssi',
    PWM = 'pwm',
    WaterFlow = 'waterflow',
    Button = 'button',
}

export enum SensorTypeGroup {
    MIN_MAX = 'minMax',
    ON_OFF = 'onOff',
}

export interface SensorConfig {
    name: string;
    type: SensorType;
    minMax: boolean;
    units: string[];
}

type SensorConfigMap = {
    [key in SensorType]: SensorConfig;
};

export const sensorsConfig: SensorConfig[] = [
    {
        name: 'Temperature',
        type: SensorType.Temperature,
        minMax: true,
        units: ['°C', '°F'],
    },
    {
        name: 'Humidity',
        type: SensorType.Humidity,
        minMax: true,
        units: ['%'],
    },
    {
        name: 'Door',
        type: SensorType.Switch,
        minMax: false,
        units: [''],
    },
    {
        name: 'Relay',
        type: SensorType.Relay,
        minMax: false,
        units: [''],
    },
    {
        name: 'Current',
        type: SensorType.Current,
        minMax: true,
        units: ['A'],
    },
    {
        name: 'Voltage',
        type: SensorType.Voltage,
        minMax: true,
        units: ['V'],
    },
    {
        name: 'Power',
        type: SensorType.Power,
        minMax: true,
        units: ['W'],
    },
    {
        name: 'Energy',
        type: SensorType.Energy,
        minMax: true,
        units: ['Wh'],
    },
    {
        name: 'RSSI',
        type: SensorType.RSSI,
        minMax: true,
        units: ['dB'],
    },
    {
        name: 'PWM',
        type: SensorType.PWM,
        minMax: true,
        units: ['%'],
    },
    {
        name: 'WaterFlow',
        type: SensorType.WaterFlow,
        minMax: true,
        units: ['L/min'],
    },
    {
        name: 'Button',
        type: SensorType.Button,
        minMax: false,
        units: [''],
    }
];

export const sensorsConfigMap: SensorConfigMap = sensorsConfig.reduce((acc, sensor) => {
    acc[sensor.type] = sensor;
    return acc;
}, {} as SensorConfigMap);




