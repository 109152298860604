import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';

import {Sensor} from '@sensorbase/models';
import {IMqttMessage} from 'ngx-mqtt';
import {takeUntil} from 'rxjs/operators';
import {SensorsService} from '@sensorbase/services';

@Component({
    selector: 'sb-humidity-display',
    templateUrl: './humidity-display.component.html',
    styleUrls: ['./humidity-display.component.scss']
})
export class HumidityDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;

    @Input('size')
    get size(): string {
        return this._size;
    }

    set size(val) {
        this._size = val;
    }

    @Output() hasWarning: EventEmitter<any> = new EventEmitter();

    conditions: any;
    minMaxConditions: any;
    ngxGaugeOptions = {
        '0': {color: 'blue'},
        '20': {color: 'lightskyblue'},
        '40': {color: 'green'},
        '60': {color: 'orange'},
        '80': {color: 'red'}
    };

    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    constructor(private _sensorsService: SensorsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._sensorsService.onMqttConnect.subscribe(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.checkConditions(this.sensor.displayData);
                });
        });
        this._sensorsService.getSensorConditions(this.sensor.sensorId).subscribe();
        this._sensorsService.onSensorConditionsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                const sensorId = data.sensorId;
                const conditions = data.conditions;
                if (sensorId === this.sensor.sensorId){
                    this.conditions = conditions;
                    const minMaxConditions = this.conditions.find(
                        (element) => element.conditionType === 'MIN_MAX'
                    ).condition;
                    this.minMaxConditions = minMaxConditions;
                    const diff = minMaxConditions.max - minMaxConditions.min;
                    this.checkConditions(this.sensor.displayData);
                }
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    checkConditions(value: any): void {
        if (this.minMaxConditions){
            if (value > this.minMaxConditions.max){
                this.hasWarning.next({
                    type: 'max',
                    message: 'Υψηλή υγρασία'
                });
            } else if (value < this.minMaxConditions.min){
                this.hasWarning.next({
                    type: 'min',
                    message: 'Χαμηλή υγρασία'
                });
            } else {
                this.hasWarning.next(null);
            }
        }
    }
}
