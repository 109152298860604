import {Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';

import {trigger, animate, style, transition, state} from '@angular/animations';

import {AlertService} from '@sensorbase/services';

@Component({
    selector: 'weekday-picker',
    templateUrl: './weekday-picker.component.html',
    styleUrls: ['weekday-picker.component.scss']
})

export class WeekDayPickerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() daysOfWeek: number[] = [];
    @Output() updated = new EventEmitter<boolean>();

    ngDisabled = false;
    displayStartIndex = 0;
    arrayStartIndex = 1;
    days = [{
        name: 'WEEKDAY.MON',
        isSelected: false
    }, {
        name: 'WEEKDAY.TUE',
        isSelected: false
    }, {
        name: 'WEEKDAY.WED',
        isSelected: false
    }, {
        name: 'WEEKDAY.THU',
        isSelected: false
    }, {
        name: 'WEEKDAY.FRI',
        isSelected: false
    }, {
        name: 'WEEKDAY.SAT',
        isSelected: false
    },
        {
            name: 'WEEKDAY.SUN',
            isSelected: false
        }
    ];
    allSelected = false;
    numbers = [0, 1, 2, 3, 4, 5, 6];

    constructor(
    ) {
    }

    ngOnInit(): void {
        this.daysOfWeek.forEach((day) => {
            this.days[(day - this.arrayStartIndex) % 7].isSelected = true;
        });
        this.areAllSelected();
    }

    ngOnDestroy(): void {

    }

    ngOnChanges(): void {
        this.ngOnInit();
    }

    onDayClicked(day): void {
        const tmpDay = this.days[day];
        if (!tmpDay.isSelected) {
            this.selectDay(day);
        } else {
            this.unselectDay(day);
        }
        this.areAllSelected();
        this.updated.emit(true);
    }

    selectDay(day): void {
        if (this.daysOfWeek.indexOf(day + this.arrayStartIndex) < 0) {
            this.daysOfWeek.push(day + this.arrayStartIndex);
            this.days[day].isSelected = true;
        }
    }

    unselectDay(day): void {
        if (this.daysOfWeek.indexOf(day + this.arrayStartIndex) >= 0) {
            this.daysOfWeek.splice(this.daysOfWeek.indexOf(day + this.arrayStartIndex), 1);
            this.days[day].isSelected = false;
        }
    }

    toggleAll(event): void {
        for (const i of this.numbers) {
            if (event.checked) {
                this.selectDay(i);
            } else {
                this.unselectDay(i);
            }
        }
        this.updated.emit(true);
    }

    areAllSelected(): void {
        this.allSelected = (this.daysOfWeek.length === this.numbers.length);
    }
}
