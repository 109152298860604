import {Component, Input, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMqttMessage} from 'ngx-mqtt';

import {Sensor} from '@sensorbase/models';
import {SensorsService} from '@sensorbase/services';
import {
    Timer
} from '@sensorbase/services';

@Component({
    selector: 'sb-relay-display',
    templateUrl: './relay-display.component.html',
    styleUrls: ['./relay-display.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RelayDisplayComponent implements OnInit, OnDestroy {
    @Input() sensor: Sensor;
    @Input() timers: Timer[];
    @Input() activeTimer: Timer;
    @Input('size')
    get size(): string {
        return this._size;
    }
    set size(val) {
        this._size = val;
    }


    // Private
    private _unsubscribeAll: Subject<any>;
    private _size = 'sm';

    is_pending = false;

    constructor(private _sensorsService: SensorsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();


    }

    ngOnInit(): void {
        this.is_pending = this.checkPending(this.sensor);
        // this.processDoorIcon();
        this._sensorsService.onSensorUpdated.subscribe(sensor => {
            if (sensor.sensorId === this.sensor.sensorId){
                this.sensor = sensor;
                this.is_pending = this.checkPending(this.sensor);
                // this.processDoorIcon();
            }
        });
        this._sensorsService.onMqttReady(() => {
            this._sensorsService.subSensorData(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    this.is_pending = this.checkPending(this.sensor);
                });

            this._sensorsService.subSensorDisconnect(this.sensor.sensorId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((message: IMqttMessage) => {
                    // console.log("Data Received: "+this.sensor.sensorId , message.payload.toString());
                    if (message.payload.toString() === '1'){
                        this.sensor.status = 0;
                    }
                });
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getRelayDisplayInfo(): string {
        if (this.sensor.displayData) {
            return 'Διακόπτης ανοιχτός';
        }
        return 'Διακόπτης κλειστός';
    }

    updateControl(state): void {
        try {
            if (this.sensor.data['current'] !== +state) {
                // console.log("Update control " + this.sensor.sensorId + " " + state);
                // console.log("target " + this.sensor.data["target"]);
                this.sensor.data['target'] = +state;
                this.sensor.data['status'] = 'PENDING';
                this.is_pending = this.checkPending(this.sensor);
                this._sensorsService.setRelaySate(this.sensor.sensorId, +state);
            }
        } catch (ex) {
            // console.log("Error updating control: " + ex);
        }
    }

    getTimerTooltip(timer: Timer): string {
        let text = '';
        if (timer.value) {
            text += 'ON: ';
        } else {
            text += 'OFF: ';
        }
        text += timer.timeRemaining;
        return text;
    }

    checkPending(sensor): boolean {
        return sensor.data['status'] === 'PENDING' && sensor.data['target'] !== sensor.data['current'];
    }
}
