import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject, throwError, map, tap, take, forkJoin, switchMap} from 'rxjs';

import {Dashboard} from '@sensorbase/models';
import {AuthenticationService} from '@sensorbase/services';
import {APIV2_BASE_URL} from 'environments/environment';

@Injectable()
export class DashboardsService {


    // Private
    private _dashboard: BehaviorSubject<Dashboard | null>;
    private _dashboards: BehaviorSubject<Dashboard[]>;

    /**
     * Constructor
     *
     * @param _httpClient
     * @param _authService
     * @param _router
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService,
        private _router: Router
    ) {
        // Set the defaults
        this._dashboard = new BehaviorSubject<Dashboard | null>(null);
        this._dashboards = new BehaviorSubject<Dashboard[]>([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Getter for selected board
     */
    get dashboard$(): Observable<Dashboard> {
        return this._dashboard.asObservable();
    }

    /**
     * Getter for boards
     */
    get dashboards$(): Observable<Dashboard[]> {
        return this._dashboards.asObservable();
    }

    /**
     * Get dashboards
     *
     * @returns
     */
    getBoards(): Observable<Dashboard[]> {
        try {
            return this._httpClient.get<Dashboard[]>(APIV2_BASE_URL + 'dashboards').pipe(
                map(response => response.map(item => Dashboard.fromApi(item))),
                tap(boards => {
                    this._dashboards.next(boards);
                }));
        } catch (e) {
            const response = {error: 'Not authenticated'};
            this._authService.setAuthFailed();
            return throwError(() => response);
        }
    }

    /**
     * Get dashboard
     *
     * @param dashboardId
     * @returns
     */
    getBoard(dashboardId: string): Observable<Dashboard> {
        const obs = [this.dashboard$.pipe(take(1)), this.dashboards$.pipe(take(1))];
        return forkJoin(obs).pipe(
            take(1),
            switchMap((response: [Dashboard, Dashboard[]]) => {
                const thisdashboard = response[0];
                const thisdashboards = response[1];
                return this._httpClient.get<Dashboard>(APIV2_BASE_URL + 'dashboards/' + dashboardId).pipe(
                    map(getResponse => Dashboard.fromApi(getResponse)),
                    tap(board => {
                        if (thisdashboard && thisdashboard.id === board.id) {
                            this._dashboard.next(board);
                        }
                        const idx = thisdashboards.findIndex(dashboard => dashboard.id === board.id);
                        if (idx >= 0) {
                            thisdashboards[idx] = board;
                        } else {
                            thisdashboards.push(board);
                        }
                        this._dashboards.next(thisdashboards);
                    })
                );
            })
        );
    }

    /**
     * Update dashboard
     *
     * @returns
     */
    updateBoard(dashboard: Dashboard): Observable<Dashboard> {
        return this._httpClient.put<Dashboard>(APIV2_BASE_URL + 'dashboards/' + dashboard.id, dashboard.toApi())
            .pipe(
                switchMap(() => this.getBoard(dashboard.id))
            );
    }

    /**
     * Create new dashboard
     *
     * @param dashboard
     * @returns
     */
    createNewBoard(dashboard): Observable<Dashboard> {
        const userID = AuthenticationService.getUserId();
        return this.dashboards$.pipe(
            take(1),
            switchMap((thisdashboards: Dashboard[]) => {
                dashboard.position = thisdashboards.length + 1;
                dashboard.userId = userID;
                return this._httpClient.post(APIV2_BASE_URL + 'dashboards/', dashboard.toApi())
                    .pipe(
                        map((postResponse) => Dashboard.fromApi(postResponse)),
                        tap((newBoard: Dashboard) => {
                            thisdashboards.push(newBoard);
                            this._dashboards.next(thisdashboards);
                        })
                    );
            })
        );
    }

    deleteBoard(dashboard): Observable<any> {
        return this.dashboards$.pipe(
            take(1),
            switchMap((thisdashboards: Dashboard[]) => {
                return this._httpClient.delete(APIV2_BASE_URL + 'dashboards/' + dashboard.id).pipe(
                    tap(() => {
                            const dashboardIndex = thisdashboards.indexOf(dashboard);
                            thisdashboards.splice(dashboardIndex, 1);
                            this._dashboards.next(thisdashboards);
                        }
                    )
                );
            })
        );
    }

    resolveBoard(dashboardId): Observable<Dashboard> {
        return this.dashboards$.pipe(
            take(1),
            switchMap((thisdashboards) => {
                return this._httpClient.get(APIV2_BASE_URL + 'dashboards/' + dashboardId)
                    .pipe(
                        map((response: any) => {
                            if (response.length === 0) {
                                this._router.navigate(['errors/error-404']);
                                return throwError(() => 'Dasboard not found');
                            } else {
                                return Dashboard.fromApi(response);
                            }
                        }),
                        tap((dashboard) => {
                            this._dashboard.next(dashboard);
                            const idx = thisdashboards.findIndex(db => db.id === dashboard.id);
                            if (idx >= 0) {
                                thisdashboards[idx] = dashboard;
                            } else {
                                thisdashboards.push(dashboard);
                            }
                            this._dashboards.next(thisdashboards);
                        })
                    );
            })
        );
    }
}
