<div class="flex flex-col max-h-screen max-w-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'GENERIC.Chart' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
            'success': sensor.status>0,
            'error': sensor.status<=0
             }" fxLayoutAlign="center">
            <div><b>ID:</b> &nbsp; {{sensor.sensorId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Sensor' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.DEVICE.Port' | transloco | sbuppercase}}: </b> &nbsp; {{sensor.sensorId[sensor.sensorId.length - 1]}}</div>
        </h4>

        <div class="px-4 pt-4">
            <sb-sensor-chart [sensor]="sensor"></sb-sensor-chart>
        </div>

        <!-- /DIALOG CONTENT -->

        <div *ngIf="show" class="message-box info flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div [ngSwitch]="sensor.type" class="flex flex-row justify-between items-center">
                <mat-icon svgIcon="info"></mat-icon>
                <div class="pl-4">
                    {{'HELP.SENSOR.CHART_DIALOG.INFO' | transloco }}
                    <ng-container *ngSwitchCase="'temp'">
                        {{'HELP.SENSOR.CHART_DIALOG.INFO_TEMP' | transloco }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'humid'">
                        {{'HELP.SENSOR.CHART_DIALOG.INFO_HUMID' | transloco }}
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- DIALOG BUTONS -->
        <div class="flex flex-row items-center justify-center m-4">
            <button type="button" mat-stroked-button color="warn" (click)="onCancel()">
                {{'GENERIC.Close' | transloco }}
            </button>
        </div>
        <!-- /DIALOG BUTONS -->

        <div class="relative flex flex-row justify-end items-end">
            <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                <mat-icon svgIcon="help_outline"></mat-icon>
            </button>
        </div>
    </div>

</div>
