<div class="flex flex-col max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'DEVICES.SETTINGS_DIALOG.TITLE' | transloco }}</div>
        <button
            mat-icon-button
            (click)="onCancel()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <!-- DIALOG CONTENT -->
    <div class="overflow-y-auto">
        <h4 class="message-box flex justify-center items-center w-full flex-wrap py-5 mt-0" [ngClass]="{
        'success': device.status>0,
        'error': device.status<=0
         }">
            <div><b>ID:</b> {{device.deviceId}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Device' | transloco | sbuppercase}}: </b> {{device.deviceName}} &nbsp; <b>| &nbsp;</b></div>
            <div><b>{{'GENERIC.Model' | transloco | sbuppercase}}: </b> {{device.model}} <b> &nbsp; | &nbsp;</b></div>
            <div><b>{{'GENERIC.Hardware_version' | transloco | sbuppercase}}: </b> {{device.hwVersion}} <b *ngIf="device.localIp"> &nbsp; | &nbsp;</b></div>
            <div *ngIf="device.localIp"><b>IP:</b> {{device.localIp}}</div>
        </h4>

        <div class="p-4 m-0">
            <form [formGroup]="settingsForm" (ngSubmit)="onSave()">
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon" svgIcon="settings">
                            </mat-icon>
                            {{'GENERIC.GENERAL_SETTINGS' | transloco}}
                        </ng-template>

                        <div class="grid sm:grid-cols-4">
                            <div class="col-span-4 md:col-span-3">
                                <mat-tab-group color="accent" #tabRef class="custom-tabs" headerPosition="below"
                                           mat-align-tabs="center" (selectedTabChange)="logChange(tabRef.selectedIndex)"
                                           fxHide
                                           fxShow.gt-xs>

                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        {{'GENERIC.Sensors' | transloco}}
                                    </ng-template>


                                    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center start"
                                         fxLayoutGap="gappx">

                                        <!-- Sensor card -->
                                        <fuse-card *ngFor="let sensor of sensors" class="border-2 h-48 w-52 p-0 m-2.5 hover:shadow"
                                                   matRipple
                                                   fxLayout="column" fxLayoutAlign="space-between center">

                                            <!-- Sensor name -->
                                            <div class="p-1 text-truncate">{{sensor.sensorName}}</div>

                                            <!-- Sensor display -->
                                            <div class="w-full h-32" [ngSwitch]="sensor.type" fxLayout="column" fxLayoutAlign="center space-around">
                                                <!-- Relay -->
                                                <div *ngSwitchCase="'relay'" class="p-7 relay-display" fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-relay-display [sensor]="sensor"></sb-relay-display>
                                                </div>

                                                <!-- Temperature -->
                                                <div *ngSwitchCase="'temp'" class="p-0 w-full h-full"
                                                     fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-temperature-display [sensor]="sensor" type="eCharts">
                                                    </sb-temperature-display>
                                                </div>

                                                <!-- Humidity -->
                                                <div *ngSwitchCase="'humid'" class="p-0 w-full h-full"
                                                     fxLayout="column" fxLayoutAlign="center center">
                                                    <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="humidThresholdConfig"
                                                               thick="4" min=0 max=100 size="120" cap="round" label="Υγρασία" append="%">
                                                    </ngx-gauge>
                                                </div>

                                                <!-- Voltage -->
                                                <div *ngSwitchCase="'volt'" class="p-0 w-full h-full"
                                                     fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-voltage-display [sensor]="sensor" type="eCharts">
                                                    </sb-voltage-display>
                                                </div>

                                                <!-- Current -->
                                                <div *ngSwitchCase="'current'" class="p-0 w-full h-full"
                                                     fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-current-display [sensor]="sensor" type="eCharts">
                                                    </sb-current-display>
                                                </div>

                                                <!-- Power -->
                                                <div *ngSwitchCase="'power'" class="p-0 w-full h-full"
                                                     fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-power-display [sensor]="sensor" type="eCharts">
                                                    </sb-power-display>
                                                </div>

                                                <!-- Fan -->
                                                <div *ngSwitchCase="'pwm'" class="fan-display" fxLayout="column" fxLayoutAlign="center center">
                                                    <ngx-gauge type="arch" [value]="sensor.displayData" [thresholds]="humidThresholdConfig"
                                                               thick="8" min=0 max=100 size="70" cap="round" label="Ταχύτητα" append="%">
                                                    </ngx-gauge>
                                                    <mat-slider></mat-slider>
                                                </div>

                                                <!-- Door -->
                                                <div *ngSwitchCase="'door'" class="text-secondary" fxLayout="column" fxLayoutAlign="center center">
                                                    <sb-door-display [sensor]="sensor"></sb-door-display>
                                                </div>

                                                <!-- Alarm-->
                                                <div *ngSwitchCase="'button'" class="text-secondary pb-1.5">
                                                    <mat-icon [ngClass]="{'warn-500-fg':sensor.displayData===1}"
                                                              [svgIcon]="sensor.displayData===1?'mat_solid:volume_up':'mat_solid:volume_off'">
                                                    </mat-icon>
                                                </div>

                                                <!-- RSSI -->
                                                <div *ngSwitchCase="'rssi'" class="rssi-display text-secondary">
                                                    <sb-rssi-display [sensor]="sensor"></sb-rssi-display>
                                                </div>

                                            </div>

                                            <!-- Device port -->
                                            <div class="w-full py-0.5 title text-center text-truncate text-white" [ngClass]="sensor.status > 0 ? 'bg-green-600': 'bg-warn'">
                                                {{'GENERIC.DEVICE.Port' | transloco }} : {{sensor.type !== 'rssi' ? sensor.sensorId[sensor.sensorId.length-1] : 0}}
                                            </div>

                                        </fuse-card>
                                    </div>
                                </mat-tab>

                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        {{'GENERIC.Chart' | transloco}}
                                    </ng-template>
                                    <sb-sensor-chart *ngIf="sensorsLoaded" [sensor]="rssiSensor"
                                                     [options]="chartOptions">
                                    </sb-sensor-chart>
                                </mat-tab>
                            </mat-tab-group>

                            </div>

                            <div class="col-span-4 md:col-span-1">
                                <fuse-card class="flex flex-col w-full p-4">

                                    <mat-form-field>
                                        <mat-label>{{'GENERIC.DEVICE.Name' | transloco}}</mat-label>
                                        <input formControlName="deviceName" matInput placeholder="Όνομα Συσκευής"
                                               [matTooltip]="'TOOLTIPS.DEVICE_NAME' | transloco" required>
                                        <mat-error *ngIf="settingsForm.get('deviceName').hasError('required')"
                                        transloco="ERROR.DEVICE.NAME.REQUIRED">
                                        </mat-error>
                                    </mat-form-field>

                                    <increment-input [step]="1" [min]="0" [max]="10" [wrap]="true"
                                                     [label]="'NOTIFICATIONS.SETTINGS.DELAY'| transloco"
                                                     [tooltip]="'TOOLTIPS.NOTIFICATION_SETTINGS.DELAY'| transloco"
                                                     formControlName="alarm_delay">
                                    </increment-input>

                                    <div class="tab-content p-6">
                                        <div fxLayout="row" fxLayoutAlign="space-between start">
                                            <div [transloco]="'GENERIC.Notifications'"></div>
                                            <mat-slide-toggle formControlName="notifications"></mat-slide-toggle>
                                        </div>
                                    </div>

                                </fuse-card>

                            </div>
                        </div>

                         <!-- Device Info -->
                           <div [ngSwitch]="manualCatergory" *ngIf="show"
                                 class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
                               <div class="flex flex-row justify-between items-center">
                                    <mat-icon svgIcon="info"></mat-icon>

                                        <div *ngSwitchCase="0">
                                            <div class="pl-4">
                                               {{'HELP.DEVICE.SETTINGS_DIALOG.INFO_ΤΑΒ_SETTINGS' | transloco }}
                                            </div>
                                        </div>

                                        <!-- Graph info -->
                                        <div *ngSwitchCase="1">
                                            <div class="pl-4">
                                                {{'HELP.DEVICE.SETTINGS_DIALOG.INFO_ΤΑΒ_CHART' | transloco }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon" svgIcon="notifications">
                            </mat-icon>
                            {{'GENERIC.Notifications' | transloco}}
                        </ng-template>

                        <div class="tab-content">
                            <div fxLayoutAlign="space-between start">
                                <contact-links-list [object]="device" [onSave]="onSaveObservable">
                                </contact-links-list>
                            </div>
                        </div>
                        <div *ngIf="show" class="message-box info w-full mt-4 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
                               <div class="flex flex-row justify-between items-center">
                                    <mat-icon svgIcon="info"></mat-icon>

                                <div class="pl-4">
                                   {{'HELP.DEVICE.SETTINGS_DIALOG.INFO_ΤΑΒ_NOTIFICATIONS' | transloco }}
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <!-- DIALOG BUTONS -->
                <div class="flex flex-row items-center justify-center m-4">
                    <button class="mr-2" type="submit" [disabled]="!settingsForm.valid" mat-flat-button color="primary">
                        {{'GENERIC.Save' | transloco }}
                    </button>
                    <button class="ml-2" type="button" mat-stroked-button color="warn" (click)="onCancel()">
                        {{'GENERIC.Cancel' | transloco }}
                    </button>
                </div>

                <div class="relative flex flex-row justify-end items-end">
                    <button mat-icon-button type="button" class="sm:absolute sm:bottom-4 sm:right-5" color="primary" (click)="toogle()">
                        <mat-icon svgIcon="help_outline"></mat-icon>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>
