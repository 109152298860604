import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MapComponent } from '../map';

import 'leaflet-easybutton';
import 'leaflet-extra-markers';
import { Map, icon, marker, easyButton, ExtraMarkers, popup } from 'leaflet';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'device-location-map',
    templateUrl: '../map/map.component.html',
    styleUrls: ['../map/map.component.scss']
})
export class DeviceLocationMapComponent
    extends MapComponent
    implements OnInit, OnChanges {
    @Input() device: any;
    @Output() locationChanged: EventEmitter<any> = new EventEmitter();

    map: Map;

    marker = marker(
        [0, 0],
        {
            draggable: false,
            icon: ExtraMarkers.icon({
                icon: 'fa-wifi',
                markerColor: 'orange-dark',
                shape: 'penta',
                prefix: 'fas'
            })
        }
    );

    button = easyButton({
        states: [{
            stateName: 'edit-location',        // name the state
            icon: 'far fa-edit',               // and define its properties
            title: 'Edit Location',      // like its title
            onClick: (btn, map): void => {
                this.marker.dragging.enable();
                btn.state('save-location');
            },

        },
        {
            stateName: 'save-location',        // name the state
            icon: 'far fa-save',               // and define its properties
            title: 'Save Location',      // like its title
            onClick: (btn, map): void => {
                this.marker.dragging.disable();
                const latLng = this.marker.getLatLng();
                this.device.location = {
                    'latitude': latLng.lat,
                    'longitude': latLng.lng
                };
                this.locationChanged.emit(this.device);
                btn.state('edit-location');
            }
        },
        {
            stateName: 'add-location',        // name the state
            icon: 'far fa-map-marker-plus',               // and define its properties
            title: 'Add Location',      // like its title
            onClick: (btn, map): void => {
                const latLng = this.map.getCenter();
                this.textbox.remove();
                this.marker.setLatLng(latLng);
                this.marker.addTo(map);
                this.marker.dragging.enable();
                btn.state('save-location');
            }
        }]
    });

    textbox = popup({
        closeButton: false,
        autoClose: false,
        className: 'custom-popup'
    });


    constructor(
        public translateService: TranslocoService
    ) {
        super(translateService);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.map) {
            if (this.device.location.latitude) {
                this.textbox.removeFrom(this.map);
                this.marker.removeFrom(this.map);
                this.marker.setLatLng([this.device.location.latitude, this.device.location.longitude]);
                this.marker.addTo(this.map);
                this.map.setView(this.marker.getLatLng(), 6);
                this.button.state('edit-location');
            }
            else {
                this.marker.removeFrom(this.map);
                this.button.state('add-location');
                this.textbox.setContent('<p><i class="fas fa-arrow-left"></i> Click to add location</p>')
                    .setLatLng(this.map.getCenter())
                    .openOn(this.map);
            }
            console.log(changes);
        }
    }

    onMapReady(map: Map): void {
        this.map = map;
        // this.drawLocation(this.location);
        this.button.addTo(map);
        if (this.device.location.latitude) {
            this.marker.setLatLng([this.device.location.latitude, this.device.location.longitude]);
            this.marker.addTo(map);
            this.map.setView(this.marker.getLatLng(), 6);
        }
        else {
            this.button.state('add-location');
            this.textbox.setContent('<p><i class="fas fa-arrow-left"></i> Click to add location</p>')
                .setLatLng(map.getCenter())
                .openOn(map);
        }

        // map.addLayer
    }

    drawLocation(location): void {
        if (location.latitude) {
            const polylinePoints = [];
            polylinePoints.push([location.latitude, location.longitude]);
            console.log(polylinePoints.slice(-1));
            this.layersControl.overlays['Last Location'] = marker(polylinePoints.slice(-1)[0], {
                icon: icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: 'leaflet/marker-icon.png',
                    shadowUrl: 'leaflet/marker-shadow.png'
                })
            });
            // this.layersControl.overlays['Last Location'].bindPopup("<b>Last Update:</b><br>" + ts.toLocaleString('en-GB', { timeZone: 'UTC' })).openPopup();
        }
    }
}
