import {SbUtils as utils} from '../utils';

export class BaseModel {

    static fromApi(dataApi): any {
        const data = {};
        Object.entries(dataApi).forEach(([key, value]) => {
            data[utils.camelCase(key)] = value;
        });
        // @ts-ignore
        return new this(data);
    }

    toApi(): object {
        const dataApi = {};
        Object.entries(this).forEach(([key, value]) => {
            dataApi[utils.snakeCase(key)] = value;
        });
        return dataApi;
    }

}
