import {Component, OnInit, ViewChild, Inject, ViewEncapsulation} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {EChartOption} from 'echarts';
import {SensorsService, AuthenticationService, DevicesService} from '@sensorbase/services';
import {from} from 'rxjs';
import {formatDate} from '@angular/common';
import {Sensor, Device} from '@sensorbase/models';

@Component({
    selector: 'sb-sensor-chart-dialog',
    templateUrl: './sensor-chart-dialog.component.html',
    styleUrls: ['./sensor-chart-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class SensorChartDialog implements OnInit {
    @ViewChild('cchart', {static: false}) cchart;

    device: Device;
    sensor: Sensor;
    show = false;

    constructor(public dialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) data: any) {

        this.sensor = data.sensor;
        this.device = data.device;
    }

    ngOnInit(): void {
    }


    onCancel(): void {
        this.dialogRef.close();
    }

    toogle(): void {
        this.show = !this.show;
    }
}
